import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/admin/services/notification/notification-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss'],
})
export class ViewNotificationComponent implements OnInit {
  currentPage = ''
  BaseUrl: any;
  constructor(
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private router:ActivatedRoute,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl
      this.currentPage =this. router.snapshot.url[0].path
  }
  title = 'angular-datatables';

  dataList: any = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  page = 1;
  pageSize = 10;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.listNotification();
    this.removeSearch();
  }
  searchKey=''
  removeSearch() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key.includes('noti_search_')) {
        if (key != `noti_search_${this.currentPage}`) {
          localStorage.removeItem(key);
        } else {
          this.searchKey = localStorage.getItem(key) || '';
        }
      }
    }
  }
  /* ***********
  list Call Orders
  *********** */

  listNotification() {
    //this.spinner.show()
    this.notificationService
      .listNotification({ is_portal_notification: true })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.dataList = res.data;
          this.originalList = res.data;
          if(this.searchKey.length > 0) {
            this.searchByKey(this.searchKey)
          }
          let offset = localStorage.getItem(`noti_offset_${this.currentPage}`)||0
          if(offset > 0) {
            this.myFilterTable.offset = offset
          }
        },
        (err) => {
          this.spinner.hide();
          //console.log(err);
        }
      );
  }

  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
    this.myFilterTable.offset = 0;
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.dataList = this.originalList;
      localStorage.removeItem(`noti_search_${this.currentPage}`);
    } else {
      this.dataList = this.originalList.filter(function (d: any) {
        return (
          d?.notification_title.toLowerCase().includes(key)||
          d?.notification_description.toLowerCase().includes(key)||
          d?.notification_link.toLowerCase().includes(key)||
          d?.customerId?.name.toLowerCase().includes(key)||
          d?.astroId?.displayname.toLowerCase().includes(key)||
          d?.astroId?.name.toLowerCase().includes(key)
        );
      });
      localStorage.setItem(`noti_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`noti_offset_${this.currentPage}`, event.offset);
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(url)
  }
}
