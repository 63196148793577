<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Customizations</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Customizations</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="contactsForm" (submit)="saveAddMoreContacts()">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="key_name">Title</label>
                  <input type="text" class="form-control " formControlName="key_name"
                    placeholder="Title" required>
                  <div *ngIf="c.key_name.invalid && (c.key_name.dirty || c.key_name.touched)" class="alert alert-danger mt-1">
                    Required
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="key_value">Enter Value</label>
                  <input type="text" class="form-control " formControlName="key_value"
                    placeholder="Enter Value" required>
                  <div *ngIf="c.key_value.invalid && (c.key_value.dirty || c.key_value.touched)" class="alert alert-danger mt-1">
                    Required
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
          <button type="submit" class="btn btn-outline-dark" (click)="saveAddMoreContacts()">Save</button>
        </div>
      </ng-template>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <table class="table table-bordered">
                        <tbody>
                            <!-- <tr>
                                <th>Company Calling Number</th>
                                <td>
                                    <form [formGroup]="callForm"><input class="form-control"
                                            formControlName="customization_value" /></form>
                                </td>
                                <td><button class="btn btn-primary" (click)="onSubmit('call')" [disabled]="!callForm.valid">Save</button></td>
                            </tr> -->
                            <tr>
                                <th>Astro River Email</th>
                                <td>
                                    <form [formGroup]="emailForm"><input class="form-control"
                                            formControlName="customization_value" /></form>
                                </td>
                                <td><button class="btn btn-primary" (click)="onSubmit('email')" [disabled]="!emailForm.valid">Save</button></td>
                            </tr>
                            <tr>
                                <th>Terms & Condition Link</th>
                                <td>
                                    <form [formGroup]="termsForm"><input class="form-control"
                                            formControlName="customization_value" /></form>
                                </td>
                                <td><button class="btn btn-primary" (click)="onSubmit('terms')" [disabled]="!termsForm.valid">Save</button></td>
                            </tr>
                            <tr>
                                <th>Minimum Recharge For Promo</th>
                                <td>
                                    <form [formGroup]="miniPromoForm"><input class="form-control"
                                            formControlName="customization_value" /></form>
                                </td>
                                <td><button class="btn btn-primary" (click)="onSubmit('miniPromo')" [disabled]="!miniPromoForm.valid">Save</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div><!-- COL-END -->
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <th colspan="2">Save Contacts</th>
                                <td><button class="btn btn-primary" (click)="addMoreContacts(content)">+Add</button></td>
                            </tr>
                            <ng-container *ngIf="contactData?.customization_object?.length > 0">
                                <tr *ngFor="let data of contactData?.customization_object">
                                    <th>{{data.key_name}}</th>
                                    <td>
                                        {{data.key_value}}
                                    </td>
                                    <td><button class="btn btn-outline-primary" (click)="deleteCustomization(data._id)">Delete</button></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->