<div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
    <!-- <input type="text"  placeholder="Type to filter the wallet ID..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" /> -->
    <h4 class="mt-2">
        Login History
    </h4>
  </div>
  <br>
  <ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <!-- <ngx-datatable-column [flexGrow]="1" name="IP Address" prop="ip">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.ip}}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="1" name="Device Name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.device_name == '' ? 'Web Login' : row.device_name}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Device Model">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.device_model}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Device Version">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.device_ios_version == '' ? row.device_android_version : row.device_ios_version}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="IP">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.ip}}
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column [flexGrow]="1" name="User Agent">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.user_agent}}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="1" name="Date" prop="login_time">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.login_time | date:'medium'}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
