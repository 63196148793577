import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class CustomizeServiceService {

  BaseUrl: string = ''  
  BaseApiUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService,@Inject('BASE_API_URL') _baseApi: string,
  ) {
    this.BaseUrl = _base
    this.BaseApiUrl = _baseApi
    this.Token = userdata.getData()
   }

  public addCustomization(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addcustomization", data,{ headers: header_object});
  }

  public addObjectCustomization(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addobjectcustomization", data,{ headers: header_object});
  }

  public getCustomization(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/showallcustomization", data,{ headers: header_object});
  }

  public singleCustomizationByType(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/showsinglcustomization", data,{ headers: header_object});
  }

  public addCustomizationArray(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addarraycustomization", data,{ headers: header_object});
  }

  public deleteCustomizationArrayById(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deletecustomizationvalue", data,{ headers: header_object});
  }

  public deleteObjectCustomization(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deleteobjectcustomization", data,{ headers: header_object});
  }

  public updateObjectCustomization(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updatearraycustomization", data,{ headers: header_object});
  }

  getCountryList() {
    return this.http.get<any>(this.BaseApiUrl + '/country');
  }
}
