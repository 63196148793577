import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-debit-astro-wallet-amount',
  templateUrl: './debit-astro-wallet-amount.component.html',
  styleUrls: ['./debit-astro-wallet-amount.component.scss']
})
export class DebitAstroWalletAmountComponent implements OnInit {

  id: any = ''

  CreditDebitWalletForm: any = new FormGroup({
    astroId: new FormControl(''),
    txn_type: new FormControl(2),
    amount: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    entry_by: new FormControl(''),
    userId: new FormControl(''),
    txnId: new FormControl('', [Validators.required]),
    wallet_comments: new FormControl('', [Validators.required]),
    is_admin_generate: new FormControl(true),
  })

  constructor(
    private spinner: NgxSpinnerService,
    private astroService: AstroServiceService,
    private toast: ToastrService,
    private route: Router,
    private userdata: UserdataService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.CreditDebitWalletForm.patchValue({ entry_by: this.userdata.getName() })
    this.CreditDebitWalletForm.patchValue({
      userId: this.userdata.getId()
    })
    this.CreditDebitWalletForm.patchValue({ is_admin_generate: true })
    this.CreditDebitWalletForm.patchValue({ astroId: this.id })
  }

  get f() {
    return this.CreditDebitWalletForm.controls
  }

  public onDebitSubmit() {
    this.spinner.show()
    this.astroService.CreditDebitAstroWalletByAdmin(this.CreditDebitWalletForm.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success("Wallet Debited!", 'Sucess')
        this.reloadCurrentRoute()
        //console.log(res)
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Something went wrong!', 'Error')
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      } 
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

}
