import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-remedy-product',
  templateUrl: './list-remedy-product.component.html',
  styleUrls: ['./list-remedy-product.component.scss']
})
export class ListRemedyProductComponent implements OnInit {

  constructor(
    private productMallService: ProductMallService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getProductList()
  }

  /*
  Get Astro remedy product
   */

  getProductList() {
    this.spinner.show()
    this.productMallService.listMallProduct({isCreatedByAstroRemedy:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }
  
  /*
  list products with features
  */
  getFeturedProduct(data:any) {
   
    this.spinner.show()
    this.productMallService.listMallProduct(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Set Featured Product
   */
   featuredProduct(data:any){
    this.spinner.show()
    this.productMallService.setFeaturedProduct(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.getProductList()
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.productId.toString().indexOf(val) !== -1 || !val || d.productname.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /** Enable/disable product*/
  public toggle($event: any, data: any) {
    this.productMallService.updateMallProduct({ '_id': data._id, 'status': !data.status, updatedId: this.userdata.getId(), updatedBy: this.userdata.getName() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.getProductList()
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
        this.toast.error(err.error.message, 'Error')
      }
    );
  }

}
