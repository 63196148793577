<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Call</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/order/call']">Calls</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-8 col-md-4">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Call Details <span *ngIf="data.is_first_order"
                                                                class="badge badge-success mr-2">FIRST ORDER</span><span
                                                                *ngIf="data.is_promotional_call"
                                                                class="badge badge-success mr-2">PROMOTIONAL CALL</span>
                                                            <span *ngIf="data.is_report_promo_call"
                                                                class="badge badge-success">REPORT PROMO CALL</span>
                                                        </h4>
                                                        <div>
                                                            <button class="btn btn-primary btn-sm"
                                                                *ngIf="!data.orderserviceId?.is_remedy_suggested"
                                                                [routerLink]="['/admin/remedy/suggest',data.orderserviceId?._id]">Suggest
                                                                Remedy</button>
                                                            <button class="btn btn-primary btn-sm"
                                                                *ngIf="data.orderserviceId?.is_remedy_suggested"
                                                                [routerLink]="['/admin/remedy/suggest',data.orderserviceId?._id]">Check
                                                                Suggested Remedy</button>
                                                        </div>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="table row table-borderless">
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Call Id :</strong> #{{data.ocallId}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Astrologer Contact :</strong>
                                                                        {{data.astrologerContact}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Customer Contact :</strong>
                                                                        {{data.customerContact}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Astrologer Share :</strong>
                                                                        {{data.astro_amount |
                                                                        currency:'INR':'symbol-narrow'}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-uppercase"><strong
                                                                            class="text-capitalize">Status : </strong>
                                                                        <span
                                                                            class="badge badge-info">{{data.order_current_status}}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Astrologer Charges :</strong>
                                                                        {{data.astroCostPerMinute}}/min.</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Call Duration :</strong>
                                                                        {{convertSecondstoTime(data.callDuration)}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Customer Paid :</strong>
                                                                        {{data.orderserviceId?.customerCurrencySymbol}}{{data.customer_amount}}
                                                                        ({{data.orderserviceId?.customerCurrencyCode}})
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Company Share :</strong>
                                                                        {{data.company_amount |
                                                                        currency:'INR':'symbol-narrow'}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Order On :</strong> {{data.created_at |
                                                                        date:'medium'}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.is_feedback_given">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Feedback</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="table row table-borderless">
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong>Feedback :</strong>
                                                                        {{data.feedback_value}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0"
                                                                *ngIf="data.is_astro_replied_feedback">
                                                                <tr>
                                                                    <td><strong>Astrologer Feedback Reply:</strong>
                                                                        {{data.astro_feedback_reply}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Feedback On :</strong>
                                                                        {{data.feedback_date | date:'medium'}}</td>
                                                                </tr>
                                                                <tr *ngIf="data.is_astro_replied_feedback">
                                                                    <td><strong>Review Reply :</strong>
                                                                        {{data.astro_feedback_date | date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.is_order_reviewed">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Review</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="table row table-borderless">
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong>Review :</strong>
                                                                        {{data.customer_review}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong>Stars :</strong>
                                                                        <ng-template #t let-fill="fill">
                                                                            <span class="star"
                                                                                [class.full]="fill === 100">
                                                                                <span class="half"
                                                                                    [style.width.%]="fill">&#x2605;</span>&#x2605;
                                                                            </span>
                                                                        </ng-template>

                                                                        <ngb-rating
                                                                            [(rate)]="data.customer_review_stars"
                                                                            [starTemplate]="t" [readonly]="true"
                                                                            [max]="5"></ngb-rating>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0"
                                                                *ngIf="data.is_astro_replied_review">
                                                                <tr>
                                                                    <td><strong>Astrologer Review Reply:</strong>
                                                                        {{data.astro_review_reply}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Reviewed On :</strong>
                                                                        {{data.review_given_date | date:'medium'}}</td>
                                                                </tr>
                                                                <tr *ngIf="data.is_astro_replied_review">
                                                                    <td><strong>Review Reply :</strong>
                                                                        {{data.astro_review_date | date:'medium'}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- customer revirew history -->
            <div class="card" *ngIf="data.review_logs?.length > 0">
                <!-- ACCORDION BEGIN -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <!-- <div class="card-header"> -->
                            <h4 class="m-0 p-0">Customer Review History</h4>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="card-body p-2">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th class="bg-primary text-white">#</th>
                                            <th class="bg-primary text-white w-50">Review</th>
                                            <th class="bg-primary text-white">Review Stars</th>
                                            <th class="bg-primary text-white">Update Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let review of data.review_logs;let i = index">
                                            <td>{{i+1}}</td>
                                            <td class="w-50">{{review.review}}</td>
                                            <td>{{review.review_stars}}</td>
                                            <td>{{review.reviewDate | date:'medium'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
                <!-- //ACCORDION BEGIN -->
            </div>
            <!-- customer review history -->
            <!-- astrologer revirew history -->
            <div class="card" *ngIf="data.review_reply_logs?.length > 0">
                <!-- ACCORDION BEGIN -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <!-- <div class="card-header"> -->
                            <h4 class="m-0 p-0">Astrologer Review History</h4>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="card-body p-2">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th class="bg-primary text-white">#</th>
                                            <th class="bg-primary text-white w-75">Reply</th>
                                            <th class="bg-primary text-white">Update Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let reply of data.review_reply_logs;let i = index">
                                            <td>{{i+1}}</td>
                                            <td class="w-75">{{reply.reply}}</td>
                                            <td>{{reply.replyDate | date:'medium'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
                <!-- //ACCORDION BEGIN -->
            </div>
            <!-- astrologer review history -->
            <!-- Customer Feedback history -->
            <div class="card" *ngIf="data.feedback_logs?.length > 0">
                <!-- ACCORDION BEGIN -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <!-- <div class="card-header"> -->
                            <h4 class="m-0 p-0">Customer Feedback History</h4>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="card-body p-2">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th class="bg-primary text-white">#</th>
                                            <th class="bg-primary text-white w-75">Feedback</th>
                                            <th class="bg-primary text-white">Update Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let feedback of data.feedback_logs;let i = index">
                                            <td>{{i+1}}</td>
                                            <td class="w-75">{{feedback.feedback}}</td>
                                            <td>{{feedback.feedbackDate | date:'medium'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
                <!-- //ACCORDION BEGIN -->
            </div>
            <!-- customer feedback history -->
            <!-- astrologer feedback history -->
            <div class="card" *ngIf="data.feedback_reply_logs?.length > 0">
                <!-- ACCORDION BEGIN -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <!-- <div class="card-header"> -->
                            <h4 class="m-0 p-0">Astrologer Feedback History</h4>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="card-body p-2">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th class="bg-primary text-white">#</th>
                                            <th class="bg-primary text-white w-75">Reply</th>
                                            <th class="bg-primary text-white">Update Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let reply of data.feedback_reply_logs;let i = index">
                                            <td>{{i+1}}</td>
                                            <td class="w-75">{{reply.reply}}</td>
                                            <td>{{reply.replyDate | date:'medium'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
                <!-- //ACCORDION BEGIN -->
            </div>
            <!-- astrologer feedback history -->
        </div>
        <!-- COL-END -->
        <div class="col-lg-4 col-md-4">
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="javascript:void(0)" class="active"
                                            data-toggle="tab">Person Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Gender</div>
                                            <div class="col-sm-8">{{data.personId?.gender}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Relationship Status</div>
                                            <div class="col-sm-8">{{data.personId?.marital_status}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.dob}} {{data.personId?.tob}},
                                                {{data.personId?.city}}, {{data.personId?.state}},
                                                {{data.personId?.country}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Occupation</div>
                                            <div class="col-sm-8">{{data.personId?.employed_in}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Note</div>
                                            <div class="col-sm-8">{{data.personId?.comments}}</div>
                                        </li>
                                    </ul>
                                    <h4 class="mb-5 mt-3" *ngIf="data.personId?.is_partner_details">Partner Details</h4>
                                    <ul class="list-unstyled mb-0" *ngIf="data.personId?.is_partner_details">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.partner_name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.partner_dob}}
                                                {{data.personId?.partner_tob}}, {{data.personId?.partner_city}},
                                                {{data.personId?.partner_state}}, {{data.personId?.partner_country}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu"
                                [routerLink]="['/admin/astrologers/viewAstrologer',data.astroId?._id]">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="javascript:void(0)" class="active"
                                            data-toggle="tab">Astrologer
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">ID</div>
                                            <div class="col-sm-9"><a
                                                    [routerLink]="['/admin/astrologers/viewAstrologer',data.astroId?._id]">#{{data.astroId?.astroAutoId}}</a>
                                            </div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Display Name</div>
                                            <div class="col-sm-9">{{data.astroId?.displayname}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Name</div>
                                            <div class="col-sm-9">{{data.astroId?.name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Email</div>
                                            <div class="col-sm-9">{{data.astroId?.email}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Status</div>
                                            <div class="col-sm-9"><span *ngIf="data.astroId?.is_current_active"
                                                    class="badge badge-success">Online</span><span
                                                    *ngIf="!data.astroId?.is_current_active"
                                                    class="badge badge-danger">Offline</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu"
                                [routerLink]="['/admin/customers/viewCustomer/',data.customerId?._id,'detail']">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="javascript:void(0)" class="active"
                                            data-toggle="tab">Customer
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Customer ID</div>
                                            <div class="col-sm-9"><a
                                                    [routerLink]="['/admin/customers/viewCustomer/',data.customerId?._id,'detail']">#{{data.customerId?.customerAutoId}}</a>
                                            </div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Name</div>
                                            <div class="col-sm-9">{{data.customerId?.name}}</div>
                                        </li>

                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Email</div>
                                            <div class="col-sm-9">{{data.customerId?.email}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Phone</div>
                                            <div class="col-sm-9">{{data.customerId?.phone}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-3 text-muted">Country</div>
                                            <div class="col-sm-9">{{data.customerId?.regCountryName}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->