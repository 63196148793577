import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  constructor() { }

  public setData(userdata: any){
    
    var enc = userdata.token;
    var name = userdata.data.name;
    var email = userdata.data.email;
    var id = userdata.data._id;
    var permissions = userdata.data.roleId.permissions.join()
    sessionStorage.setItem("token",enc)
    sessionStorage.setItem("email",email)
    sessionStorage.setItem("name",name)
    sessionStorage.setItem("id",id)
    sessionStorage.setItem("permission",permissions)
    sessionStorage.setItem("isadmin",userdata.data.is_admin)
    if(!userdata.data.is_admin){
      sessionStorage.setItem("profile",userdata.managerdetail.profile_image)
    }
  }

  public getData(){
    return sessionStorage.getItem("token");
  }

  public getName() {
    return sessionStorage.getItem("name");
  }

  public getId() {
    return sessionStorage.getItem("id");
  }

  public getEmail(){
    return sessionStorage.getItem("email");
  }

  public getIsAdmin(){
    return sessionStorage.getItem("isadmin");
  }

  public getToken(){
    return sessionStorage.getItem("token");
  }

  public getProfileImage(){
    return sessionStorage.getItem("profile");
  }

  // public removeName(){
  //   sessionStorage.removeItem("name")
  // }

  // public removeEmail(){
  //   sessionStorage.removeItem("email")
  // }

  // public removeId(){
  //   sessionStorage.removeItem("id")
  // }

  public removeData(){
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("name")
    sessionStorage.removeItem("email")
    sessionStorage.removeItem("id")
    sessionStorage.removeItem("permission")
    sessionStorage.removeItem("isadmin")
    sessionStorage.removeItem("profile")
    sessionStorage.removeItem("fcm")
  }

  /**
   * Roles and permission data
   */
  public getPermissions() {
    return sessionStorage.getItem("permission")
    //return "['Category','Subcategory','User','Products',]"
  }

  public roleMatch(allowedRoles:any): boolean {
    let isMatch = false;
    const userRoles = this.getPermissions()
    
    allowedRoles.forEach((element: any) => {
      if (userRoles?.includes(element)) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }

  /**
   * FCM
   */
   public setFcm(fcm:any) {
    localStorage.setItem("fcm", fcm)
  }

  public getFcm() {
    return localStorage.getItem("fcm")
  }

}
