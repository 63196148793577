<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Search Order ID, Astro Name, Customer Name..."  class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
</div>
<div class="p-2 d-flex justify-content-between mt-1">
    <button class="btn btn-danger mr-2" (click)="getChatOrderList()">All Orders</button>
    <button class="btn btn-success mr-2" (click)="completedChatOrders()">Completed Orders</button>
    <button class="btn btn-primary mr-2" (click)="OnGoingChatOrders()">On-Going Orders</button>
    <button class="btn btn-warning mr-2" (click)="noCustomerReview()">No Customer Review</button>
    <button class="btn btn-info mr-2" (click)="noAstrologerReviewReply()">No Review Reply</button>
    <button class="btn btn-danger mr-2" (click)="promotionalChat()">Promotional Chat</button>
</div>
<br>
<ngx-datatable class="material" [rows]="chatList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Chat ID" prop="ochatId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/chat/', row._id]">#{{row.ochatId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/astrologers/viewAstrologer',row.astroId?._id]"><span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/customers/viewCustomer',row.customerId?._id,'detail']">#{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- {{row.cost | currency :'INR':'symbol-narrow'}} -->
            {{row.orderserviceId?.customerCurrencySymbol}}{{row.customer_amount | number : '.2-2'}} 
            <!-- <span class="badge badge-pill badge-primary" *ngIf="row.is_foreign_order">{{row?.customerId?.regCountryName}}</span> -->
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="International?" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.is_foreign_order ? 'Yes' : 'No'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Duration" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{convertSecondstoTime(row.totalchatDuration)}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Is Promo Chat ?">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_promotional_chat && !row.is_report_promo_chat && !row.is_direct_promo_chat"
            class="badge badge-success mr-2">FIRST PROMO</span>
        <span *ngIf="row.is_promotional_chat && row.is_report_promo_chat" class="badge badge-danger">REPORT
            PROMO</span>
        <span *ngIf="row.is_promotional_chat && row.is_direct_promo_chat && !row.is_paid_promotional_chat"
            class="badge badge-warning">FREE
            PROMO</span>
        <span *ngIf="row.is_promotional_chat && row.is_direct_promo_chat && row.is_paid_promotional_chat"
            class="badge badge-success">PAID
            PROMO</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.order_current_status}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/chat/', row._id]"><i class="fa fa-eye"></i></a>
            <!-- <button class="ml-2 btn btn-primary btn-sm" (click)="markAsComplete(row._id)" *ngIf="!row.is_order_completed">Mark Complete</button> -->
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
