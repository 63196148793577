import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-common-blog',
  templateUrl: './list-common-blog.component.html',
  styleUrls: ['./list-common-blog.component.scss']
})
export class ListCommonBlogComponent implements OnInit {

  constructor(
    private blogService: BlogServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getBlogList()
  }

  /* ***********
  list Blog
  *********** */

  getBlogList() {
    this.spinner.show()
    this.blogService.getBlog({isApproved:true,is_used:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(res)
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /*
  list Blogs with features
  */
  getFeturedBlog(data:any) {
   
    this.spinner.show()
    this.blogService.getBlog(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }
  
  /**
   * Set Featured Blog
   */
  featuredBlog(data:any){
    this.spinner.show()
    this.blogService.setFeaturedBlog(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.getBlogList()
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    )
  }
  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.blogTitle.toLowerCase().indexOf(val) !== -1 ||
      d.categoryId?.categoryname.toLowerCase().indexOf(val) !== -1 ||
      d.userName.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
