import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: ['./call-details.component.scss']
})
export class CallDetailsComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata : UserdataService,
    private toast : ToastrService
  ) { }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.orderService.getCallOrderDetail({_id: this.id,userId:this.userdata.getId()}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
  
    var timeString = hours.toString().padStart(2, '0') 
                + ':' + minutes.toString().padStart(2, '0') 
                + ':' + seconds.toString().padStart(2, '0'); 
    return timeString
  } 
}
