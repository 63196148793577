<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Type to filter the name column..."  class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
    <div>
        <button class="btn btn-primary btn-sm mr-2" (click)="walletIssue()">Wallet Issues</button>
        <button class="btn btn-info btn-sm mr-2" (click)="serviceIssue()">Service Issues</button>
        <button class="btn btn-warning btn-sm mr-2" (click)="pendingIssue()">Pending Issues</button>
        <button class="btn btn-success btn-sm mr-2" (click)="solvedIssue()">Solved Issues</button>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="issueList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column name="Issue ID" prop="issueId" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.issueId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Type" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-primary" *ngIf="row.is_wallet_issue">WALLET</span>
            <span class="badge badge-info" *ngIf="row.is_service_issue">SERVICE</span>
            <span class="badge badge-warning" *ngIf="row.is_order_issue">ORDER</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Customer" prop="customerId.name" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Amount" prop="amount" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.customerId?.currencySymbol}}{{row.amount | number: '.2-2'}}({{row.customerId?.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Issue" prop="issue_title" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.issue_title}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-success" *ngIf="row.is_issue_solved">SOLVED</span>
            <span class="badge badge-danger" *ngIf="!row.is_issue_solved">PENDING</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Actions" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/issues/customer/', row._id]" title="View Details" class="mr-2"><i class="fa fa-eye"></i></a>
            <button class="btn btn-primary btn-sm" [routerLink]="['/admin/issues/customer/reply',row._id]" *ngIf="!row.is_issue_solved">Reply</button>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Date" [flexGrow]="2" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
