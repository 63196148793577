<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Support Messages</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/support/customer']">Support Chat</a></li>
      <li class="breadcrumb-item active" aria-current="page">Chat</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- MODAL VIEW ISSUE-->
  <ng-template #viewCustomerDetails let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Customer Details</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body row">
      <div [innerHTML]="" class="m-auto"></div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        Close
      </button>
    </div>
  </ng-template>
  <!-- MODAL VIEW ISSUE END-->
  <!-- ROW-1 OPEN -->
  <div class="row chatbox">
    <div class="col-md-12 col-lg-12 col-xl-12 chat">
      <div class="card">
        <!-- ACTION HEDAER OPEN -->
        <div class="action-header clearfix">
          <div class="float-left hidden-xs d-flex ml-2">
            <div class="align-items-center mt-2">
              <h4 class="mb-0 font-weight-semibold">Support Chat</h4>
              <h4 class="mb-0 font-weight-semibold">Customer Details - {{data?.customerId?.name}}(#{{data?.customerId?.customerAutoId}})</h4>
            </div>
          </div>
          <ul class="ah-actions actions align-items-center">
            <li class="call-icon">
              <a class="fe fe-help-circle text-primary"></a>
            </li>
          </ul>
        </div>
        <!-- ACTION HEADER END -->

        <!-- MSG CARD-BODY OPEN -->
        <div
          class="card-body msg_card_body"
          style="overflow-y: scroll !important; height: 400px !important"
          #scrollMe
          [scrollTop]="chatList.length === 0 ? 0 : scrollMe.scrollHeight"
        >
        <ng-container *ngFor="let chat of chatList">
          <div *ngIf="  chat.isCustomerReply || chat.isAstroReply" class="media media-chat"> <img         style="border-radius: 50%;" class="avatar" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
              <div class="media-body admin">
                  <p class="inline-block">{{chat.new_reply}}</p>
                  <p class="meta"><time datetime="2018">{{chat.replyDate | date:'medium'}}</time></p>
              </div>
          </div>
          <div *ngIf="chat.isSystemReply " class="media media-chat media-chat-reverse">
              <div class="media-body reply">
                  <p class="float-right">{{chat.new_reply}}</p><br>
                  <p class="meta float-right"><time datetime="2018">{{chat.replyDate | date:'medium'}}</time></p>
              </div>
          </div>
      </ng-container>
        </div>
        <!-- MSG CARD-BODY END -->

        <!-- CARD-FOOTER OPEN -->
        <div class="card-footer">
          <div class="msb-reply d-flex">
            <form
              class="w-100"
              [formGroup]="formGroup"
              (submit)="sendMsg()"
              (keydown)="checkEnterPressed($event)"
            >
              <div class="row">
                <div class="form-group col-2">
                  <label for="reply">Issue Status</label><br />
                  <input
                    type="checkbox"
                    name="issue_solved"
                    (change)="isIssueSolved($event)"
                  />
                  <label class="ml-2">Is Issue Solved?</label>
                </div>
                <textarea
                  style="margin-left: -50px !important"
                  class="form-control col-10"
                  name="reply_by_system"
                  formControlName="reply_by_system"
                  placeholder="Reply"
                ></textarea>
                <div
                  *ngIf="
                    f.reply_by_system.invalid &&
                    (f.reply_by_system.dirty || f.reply_by_system.touched)
                  "
                  class="alert alert-danger mt-1"
                >
                  Required
                </div>

                <button
                  [disabled]="!formGroup.valid"
                  type="submit"
                  title="Send Message"
                >
                  <i class="fa fa-paper-plane-o"></i>
                </button>
              </div>
            </form>
          </div>
          <div class="msb-reply d-flex" *ngIf="">
            <div class="w-100 text-center alert alert-danger">
              Order Has Been Completed
            </div>
          </div>
        </div>
        <!-- CARD FOOTER END -->
      </div>
    </div>
    <!-- COL END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
