import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminWalletService } from 'src/app/admin/services/admin-wallet/admin-wallet.service';
import { ExcelServiceService } from 'src/app/admin/services/excel-service/excel-service.service';

@Component({
  selector: 'app-list-admin-wallet',
  templateUrl: './list-admin-wallet.component.html',
  styleUrls: ['./list-admin-wallet.component.scss']
})
export class ListAdminWalletComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private walletService: AdminWalletService,
    private toast: ToastrService,
    private excelService: ExcelServiceService,
    private datePipe: DatePipe
  ) { }

  data: any = '';
  temp: any = '';
  id: any = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getAdminWalletHistory()
  }

  getAdminWalletHistory() {
    this.spinner.show()
    this.walletService.getAdminWalletHistory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  getForeignTransaction() {
    this.spinner.show()
    this.walletService.getAdminWalletHistory({isForeignOrder:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroCreditedWalletHistory() {
    this.spinner.show()
    this.walletService.adminWalletTransactionHistoryByOperation({txn_type: 1 }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroDebitedWalletHistory() {
    this.spinner.show()
    this.walletService.adminWalletTransactionHistoryByOperation({txn_type: 2 }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroWalletTransactionsByAdmin() {
    this.spinner.show()
    this.walletService.adminWalletTransactionHistoryByOperation({  is_admin_generate: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.adminwalletId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  showDate(){
    if(this.range.get('start')?.value == null || this.range.get('start')?.value == '' || this.range.get('start')?.value == undefined || this.range.get('end')?.value == null || this.range.get('end')?.value == '' || this.range.get('end')?.value == undefined){
      this.toast.error('Please Select Start and End Date','Error')
      return
    }
    this.spinner.show()
    var start = moment(this.range.get('start')?.value).format('YYYY-MM-DD')
    var end = moment(this.range.get('end')?.value).format('YYYY-MM-DD')
    this.walletService.getAdminWalletHistory({startdate:start,enddate:end}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /** Generate Excel */
  transformDate(date:any){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  generateExcel() {
    if(this.data.length == 0){
      return
    }
    const header = ['ID','Type','Amount','Balance','Wallet Remarks','Txn ID','Date']
    const excelData:any = []
    this.data.forEach((row:any)=>{
      var obj = []
      obj.push('#'+row.adminwalletId)
      if(row.txn_type == 1){
        obj.push('Recharged')
      }else{
        obj.push('Deducted')
      }
      obj.push(row.currencySymbol+' '+Number(row.amount).toFixed(2)+' ('+row.currencyCode+')')
      if(row.isForeignOrder){
        obj.push(row.currencySymbol+' '+Number(row.foreignBalance).toFixed(2))
      }else{
        obj.push(row.currencySymbol+' '+Number(row.balance).toFixed(2))
      }
      obj.push(row.wallet_comments)
      obj.push(row.txnId)
      obj.push(this.transformDate(row.created_at))
      excelData.push(obj)
    })
    this.excelService.generateExcel(excelData,'Admin-Wallet',header);
  }
}
