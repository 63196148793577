import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BannerService } from 'src/app/admin/services/banner/banner.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-banner',
  templateUrl: './list-banner.component.html',
  styleUrls: ['./list-banner.component.scss']
})
export class ListBannerComponent implements OnInit {

  BaseUrl = '';
  data: any = '';
  id: any = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private banner: BannerService,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }
     
  ngOnInit(): void {
    this.getBannerList();
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  getBannerList() {
    this.spinner.show()
    this.banner.getBannerList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data;
        //console.log(this.data)
      },err=>{
        this.spinner.hide()
      }
    );
  }

  /** Enable/Disable Banner*/
  public toggle($event: any, id: any, isBlocked: any) {
    if(isBlocked == true){
      this.banner.updateBanner({'_id': id, 'banner_status':false}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Banner Disabled','Success')
          this.getBannerList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      );
    } else {
      this.banner.updateBanner({'_id': id, 'banner_status':true}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Banner Enabled','Success')
          this.getBannerList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      );
    }
  }

  /**
   * 
   * Delete Banner
   */
  deleteBanner($event: any, id: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.banner.updateBanner({'_id': id, 'is_delete':true}).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success('Banner Deleted','Success')
            this.getBannerList()
          },
          (err) => {
            this.spinner.hide()
            //console.log(err);
            this.toast.error('Something went wrong','Error')
          }
        );
      }
    })
  }

  /**
   * Filter Astrologer Banner
   */
  astrologerBannerMobile(){
    this.spinner.show()
    this.banner.filterBanner({banner_status:true,is_astro_banner:true,is_mobile_banner:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data;
        //console.log(this.data)
      },err=>{
        this.spinner.hide()
      }
    );
  }

  astrologerBannerWeb(){
    this.spinner.show()
    this.banner.filterBanner({banner_status:true,is_astro_banner:true,is_desktop_banner:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data;
        //console.log(this.data)
      },err=>{
        this.spinner.hide()
      }
    );
  }

  /**
   * Filter Customer Banner
   */
  customerBannerMobile(){
    this.spinner.show()
    this.banner.filterBanner({banner_status:true,is_astro_banner:false,is_mobile_banner:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data;
        //console.log(this.data)
      },err=>{
        this.spinner.hide()
      }
    );
  }

  customerBannerWeb(){
    this.spinner.show()
    this.banner.filterBanner({banner_status:true,is_astro_banner:false,is_desktop_banner:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data;
        //console.log(this.data)
      },err=>{
        this.spinner.hide()
      }
    );
  }

}
