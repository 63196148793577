import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class CouponServiceService {

  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }

  public addCoupon(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addcoupon", data,{ headers: header_object});
  }

  public allCoupon(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allcoupon", data,{ headers: header_object});
  }

  public getCouponById(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singleCouponbyid", data,{ headers: header_object});
  }

  public updateCoupon(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updatecoupon", data,{ headers: header_object});
  }

  public deleteCoupon(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/couponCode/delete", data,{ headers: header_object});
  }
}
