<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Charges</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Charges</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>Charges List</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div style="display: flex; justify-content: space-between;"
                                                class="pr-2 pl-2">
                                                <input type="text"  placeholder="Search Astrologer, Service, Charges, Update Charges..." class="form-control
                                                    col-lg-4 mt-1" (keyup)="updateFilter($event)" />

                                            </div>
                                            <br>
                                            <ngx-datatable class="material" [rows]="chargeList" [columnMode]="'force'"
                                                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                                [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                                                <ngx-datatable-column [flexGrow]="1" name="Astrologer" prop="name">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <span [title]="row.name">#{{row?.astroAutoId}}-{{(row.displayname == '' || row.displayname == undefined || row.displayname == null) ? row.astroId?.name : row.displayname}}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Service" prop="charges_logs.charge_type">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <span class="text-uppercase badge badge-primary">{{row.charges_logs.charge_type}}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Charges" prop="charges_logs.last_charge">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                       {{row.charges_logs.last_charge | currency:'INR':'symbol-narrow'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Updated Charges" prop="charges_logs.new_charge">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.charges_logs.new_charge | currency:'INR':'symbol-narrow'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="3" name="Comment" prop="charges_logs.change_comment">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <span [title]="row.charges_logs.change_comment">{{row.charges_logs.change_comment}}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Action">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <a class="btn btn-primary btn-sm text-white" [routerLink]="['/admin/update/charges',row.charges_logs._id]">Action</a>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="2" name="Date" prop="charges_logs.updatedDate">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.charges_logs.updatedDate | date:'medium'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
