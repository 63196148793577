import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-blog-category',
  templateUrl: './add-blog-category.component.html',
  styleUrls: ['./add-blog-category.component.scss']
})
export class AddBlogCategoryComponent implements OnInit {

  isUnique = true
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private blogService: BlogServiceService,
    private route: Router
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    categoryname: new FormControl('', Validators.required),
    categorydescription: new FormControl('', Validators.required),
    category_slug: new FormControl('', [Validators.required,Validators.pattern('^[a-z0-9A-Z]+(?:-[a-z0-9A-Z]+)*$')]),
    addedBy: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    category_keyword: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
  }

  get f() {
    return this.formGroup.controls
  }

  addBlogSubmit(){
    this.blogService.addBlogCategory(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/blog-category/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  checkSlugUnique(event:any){
    this.blogService.checkUniqueBlogSlug({slug:event.target.value}).subscribe(
      (res:any)=>{
        if(res.success){
          this.isUnique = true
        }else{
          this.isUnique = false
        }
      }
    )
  }

}
