import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.scss']
})
export class ViewCustomerComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private customerService: CustomerService,
    private userdata: UserdataService,
    private route: Router
  ) { }

  data: any = '';
  id: any = '';

  ngOnInit(): void {
    this.router.parent?.params.subscribe(
      (params) => {
        this.id = params.data
      });
    this.getDataById()
  }

  getDataById() {
    this.spinner.show()
    this.customerService.getCustomerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
      },
      err => {
        this.spinner.hide()
        // console.log(err)
      }
    )
  }

  hideCustomerProfile(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.customerService.hideCustomerProfile({ _id: id, userId: this.userdata.getId() }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.getDataById()
          },
          err => {
            this.spinner.hide()
            //console.log(err)
          }
        )
      }
    })
  }
}
