import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-astro-users',
  templateUrl: './list-astro-users.component.html',
  styleUrls: ['./list-astro-users.component.scss']
})
export class ListAstroUsersComponent implements OnInit {

  constructor(
    private astrologerService: AstroServiceService,
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private route: Router
  ) {}
  title = 'angular-datatables';

  astrologersList: any = [];
  id: any ='';
  temp:any = [];
  getAstroData: any = [];
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getAstrologersList()
  }

  getAstrologersList() {
    this.spinner.show()
    this.astrologerService.getAstrologerList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.astrologersList = res.data;
        //console.log(this.astrologersList)
        this.temp = this.astrologersList;
        this.getAstroData = this.astrologersList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

   /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      
      return d.displayname.toLowerCase().indexOf(val) !== -1 || !val || 
              d.phone.toString().indexOf(val) !== -1 || !val ||
              d.consultant_type.toLowerCase().indexOf(val) !== -1 || !val ||
              d.experience.toString().indexOf(val) !== -1 || !val 
    });

    // update the rows
    this.astrologersList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /** Block/unblock astrologer*/
  public toggle($event: any, id: any, isBlocked: any) {
    if(isBlocked == true){
      this.astrologerService.blockAstrologer({'_id': id, 'block':false}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Asrologer Unblocked','Success')
          this.getAstrologersList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      );
    } else {
      this.astrologerService.blockAstrologer({'_id': id, 'block':true}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Asrologer blocked','Success')
          this.getAstrologersList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      );
    }
  }

}
