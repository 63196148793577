import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { WalletRechargeService } from 'src/app/admin/services/wallet-recharge/wallet-recharge.service';

@Component({
  selector: 'app-list-recharge',
  templateUrl: './list-recharge.component.html',
  styleUrls: ['./list-recharge.component.scss']
})
export class ListRechargeComponent implements OnInit {

  constructor(
    private rechargeService: WalletRechargeService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,    
    private internationalService: InternationalServiceService,
  ) { }

  rechargeList = [];
  temp: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getCustomerRechargeAmount()    
    this.getInternationalPricing()
  }

  getCustomerRechargeAmount() {
    this.spinner.show()
    this.rechargeService.getWalletRecharge({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.rechargeList = res.data;
        this.temp = this.rechargeList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**Block/unblock Recharge Amount */

  public toggle(id: any, status: any) {
    this.rechargeService
      .updateWalletRecharge({
        _id: id,
        status: !status
      })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toast.success('Wallet Amount Disabled', 'Success');
          this.getCustomerRechargeAmount();
        },
        err => {
          this.spinner.hide();
          //console.log(err);
          this.toast.error('Something went wrong', 'Error');
        }
      );
  }

  usdPrice: any;
  getInternationalPricing() {
    this.spinner.show();
    this.internationalService.singleInternationalPrice({ countryCode: 'US' }).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.usdPrice = res.data;
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  getInternationalPrice(amount:any){
    return Math.round(amount/this.usdPrice?.currencyUnitInRupees)
  }
}
