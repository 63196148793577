
<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text" placeholder="Search AstroID, Name..." class="form-control col-lg-4 mt-1"
      (keyup)="updateFilter($event)" />
      <button class="btn btn-primary btn-sm" (click)="generateExcel()"> Generate Excel</button>
    <div>
        <button class="btn btn-warning mr-2" (click)="searchPayout({isZeroPayouts:false})">Non-Zero Payout </button>
        <button class="btn btn-info mr-2" (click)="searchPayout({isZeroPayouts:true})" >Zero Payout </button>
    </div>
    <div class="col-4 mr-2">
      <div class="row">
        <select class="form-control col-3" [(ngModel)]="year">
            <option *ngFor="let year of yearList">{{year}}</option>
          </select>
    
          <select class="form-control col-4" [(ngModel)]="month">
            <option *ngFor="let month of monthList" [value]="month.id">{{month.name}}</option>
          </select>
          <button class="col-3 btn btn-sm btn-primary" (click)="search()">Search</button>
      </div>
    </div>
  </div>
  <br>
  <ngx-datatable class="material" [rows]="payoutList" [columnMode]="'force'"
  [headerHeight]="50" [footerHeight]="50" [rowHeight]="60"
  [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
  <ngx-datatable-column name="ID" prop="payoutId">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{row?.payoutId}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Display Name"
      prop="astroId.displayname">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <span [title]="row.astroId.name">#{{row.astroId.astroAutoId}} - {{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}} </span>
      </ng-template>
  </ngx-datatable-column>
  
  <ngx-datatable-column name="Month/Year"
      prop="payoutmonth">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.payoutmonth == 1 || row.payoutmonth == 01">January</span>
          <span *ngIf="row.payoutmonth == 2 || row.payoutmonth == 02">February</span>
          <span *ngIf="row.payoutmonth == 3 || row.payoutmonth == 03">March</span>
          <span *ngIf="row.payoutmonth == 4 || row.payoutmonth == 04">April</span>
          <span *ngIf="row.payoutmonth == 5 || row.payoutmonth == 05">May</span>
          <span *ngIf="row.payoutmonth == 6 || row.payoutmonth == 06">June</span>
          <span *ngIf="row.payoutmonth == 7 || row.payoutmonth == 07">July</span>
          <span *ngIf="row.payoutmonth == 8 || row.payoutmonth == 08">August</span>
          <span *ngIf="row.payoutmonth == 9 || row.payoutmonth == 09">September</span>
          <span *ngIf="row.payoutmonth == 10">October</span>
          <span *ngIf="row.payoutmonth == 11">November</span>
          <span *ngIf="row.payoutmonth == 12">December</span>
          
          {{row.payoutyear}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Total Payout" prop="finalpayout">
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.totalpayout | currency:'INR' : 'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
  
  <ngx-datatable-column name="TDS Amount"
      prop="tds">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.tdsamount == 0">₹{{checkTdsAmount(row)}}</span>
        <span *ngIf="row.tdsamount > 0">{{row.tdsamount | currency:'INR' : 'symbol-narrow'}}</span>
        ({{row.tdspercentage}}%)
      </ng-template>
  </ngx-datatable-column>
  
  <ngx-datatable-column name="PG Amount" prop="pgamount">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.pgamount | currency:'INR' : 'symbol-narrow'}} (- {{row.pgchargeper}}%)
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Payout" prop="finalpayout">
    <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.tdsamount == 0">₹{{checkFinalWithTds(row)}}</span>
        <span *ngIf="row.tdsamount > 0">{{row.finalpayout | currency:'INR' : 'symbol-narrow'}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Payment Status">
    <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="badge badge-success" *ngIf="row.is_paid">PAID</span>
        <span class="badge badge-danger" *ngIf="!row.is_paid">PENDING</span>
    </ng-template>
</ngx-datatable-column>
</ngx-datatable>
