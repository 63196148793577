import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class OrderServiceService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
  }
  /**
   * Wallet Recharge Orders
   */
  public getAllWalletRechargeOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allorders", data, { headers: header_object });
  }

  public getSingleWalletRechargeOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchorderbyid", data, { headers: header_object });
  }
  /**
   * Service Orders
   */
  public getAllOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allserviceorder", data, { headers: header_object });
  }

  public getOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singleserviceorder", data, { headers: header_object });
  }

  /**
   *
   * Query Orders
   */
  public getQueryOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allquestionorder", data, { headers: header_object });
  }

  public getQueryOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlequestionorder", data, { headers: header_object });
  }

  public updateQueryStatus(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/questionstatusupdate", data, { headers: header_object });
  }

  public queryCancelAndReassign(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/querycancelandreassign", data, { headers: header_object });
  }

   /* not Replied After A day ----query---*/
   public queriesNotRepliedAfterAday(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/queriesNotRepliedAfterAday", data, { headers: header_object });
  }

  /**
   * Report Orders
   */
  public getReportOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allreportorders", data, { headers: header_object });
  }

  public getReportOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlereportorder", data, { headers: header_object });
  }

  public updateReportStatus(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/reportstatusupdate", data, { headers: header_object });
  }

  public reportCancelAndReassign(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/cancelandreassign", data, { headers: header_object });
  }

    /* not Replied After A day ----report---*/
    public notRepliedAfterAday(data: any) {
      var header_object = new HttpHeaders().set("Authorization", this.Token);
      return this.http.post(this.BaseUrl + "/notRepliedAfterAday", data, { headers: header_object });
    }

  /**
   * Call orders
   */
  public getCallOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/mycallorder", data, { headers: header_object });
  }

  public getOngoingCallOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/ongoingcalls ", data, { headers: header_object });
  }

  public getCallOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlecallorder", data, { headers: header_object });
  }

  /**
   * Assign Promo
   */
  public assignPromo(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addpromo", data, { headers: header_object });
  }

  /**
   * List Promo Orders
   */
  public listPromoOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/alldirectpromocalls", data, { headers: header_object });
  }

  /**
   * Update Promo Order
   */
  public updatePromoOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updatepromocall", data, { headers: header_object});
  }
  
  /**
   * Connect Promo Order
   */
  public connectPromoOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/connectAssignedPromo ", data, { headers: header_object});
  }

  /**
   * Chat orders
   */
   public getChatOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/mychatorder", data, { headers: header_object });
  }

  public getChatOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlechatorder", data, { headers: header_object });
  }

  /**
   * Milestone - 3
   */
  /**
   * Store Orders
   */
   public getStoreOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getAllOrders", data, { headers: header_object });
  }

  public getStoreOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getOrdersById", data, { headers: header_object });
  }

  public updateStoreOrder(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateOrder", data, { headers: header_object });
  }

  public answerStoreReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/replyOrderReview", data, { headers: header_object });
  }

  /**
   * Group Chat
   */
   public detailGroupChat(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlegroupchat", data, { headers: header_object });
  }

  public sendGroupChatMsg(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/sendmsgingroup", data, { headers: header_object });
  }

  /**
   * Add On
   */
  answerReportAdmin(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/savereportansweradmin", data, { headers: header_object });
  }

  answerQueryAdmin(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/savequestionansweradmin", data, { headers: header_object });
  }
}
