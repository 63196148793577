import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent implements OnInit {

  callData:any = []
  termsData:any = []
  emailData:any = []

  callForm: FormGroup = new FormGroup({
    customization_name: new FormControl('Company Calling Number', Validators.required),
    customization_type: new FormControl(2, Validators.required),
    addedBy: new FormControl(''),
    userId: new FormControl(''),
    customization_value: new FormControl('', Validators.required),
  });

  emailForm: FormGroup = new FormGroup({
    customization_name: new FormControl('Astroriver Email', Validators.required),
    customization_type: new FormControl(3, Validators.required),
    addedBy: new FormControl(''),
    userId: new FormControl(''),
    customization_value: new FormControl('', Validators.required),
  });

  termsForm: FormGroup = new FormGroup({
    customization_name: new FormControl('Terms and Conditions', Validators.required),
    customization_type: new FormControl(5, Validators.required),
    addedBy: new FormControl(''),
    userId: new FormControl(''),
    customization_value: new FormControl('', Validators.required),
  });

  miniPromoForm: FormGroup = new FormGroup({
    customization_name: new FormControl('Minimum Recharge For Promo', Validators.required),
    customization_type: new FormControl(2, Validators.required),
    addedBy: new FormControl(''),
    userId: new FormControl(''),
    customization_value: new FormControl('', Validators.required),
  });

  contactsForm: FormGroup = new FormGroup({
    key_name: new FormControl('', Validators.required),
    key_value: new FormControl('', Validators.required),
  });

  get c(){
    return this.contactsForm.controls
  }

  constructor(
    private customizeService: CustomizeServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getCallCustomization()
    this.getEmailCustomization()
    this.getTermsCustomization()
    this.getContactsCustomization()
    this.getMinimumPromoCustomization()
  }

  getCallCustomization(){
    this.customizeService.singleCustomizationByType({customization_type:2}).subscribe(
      (res:any)=>{
        // console.log(res)
        this.callData = res.data
        this.callForm.patchValue({customization_value:this.callData.customization_value})
      },err=>{
        //console.log((err))
      }
    )
  }

  getEmailCustomization(){
    this.customizeService.singleCustomizationByType({customization_type:3}).subscribe(
      (res:any)=>{
        this.emailData = res.data
        this.emailForm.patchValue({customization_value:this.emailData.customization_value})
      },err=>{
        //console.log((err))
      }
    )
  }

  getTermsCustomization(){
    this.customizeService.singleCustomizationByType({customization_type:5}).subscribe(
      (res:any)=>{
        this.termsData = res.data
        this.termsForm.patchValue({customization_value:this.termsData.customization_value})
      },err=>{
        //console.log((err))
      }
    )
  }

  contactData:any = ''
  getContactsCustomization(){
    this.customizeService.singleCustomizationByType({customization_type:7}).subscribe(
      (res:any)=>{
        this.contactData = res.data
        //console.log(this.contactData.customization_object, this.contactData.customization_object.length, typeof this.contactData.customization_object)
      },err=>{
        //console.log((err))
      }
    )
  }
  
  miniPromo:any = ''
  getMinimumPromoCustomization(){
    this.customizeService.singleCustomizationByType({customization_type:2}).subscribe(
      (res:any)=>{
        this.miniPromo = res.data
        // console.log(res)
        this.miniPromoForm.patchValue({customization_value:this.miniPromo.customization_value})
      },err=>{
        //console.log((err))
      }
    )
  }

  onSubmit(val:any){
    var data
    // console.log(val)
    // return
    if(val == 'call'){
      this.callForm.patchValue({userId:this.userdata.getId()})
      this.callForm.patchValue({addedBy:this.userdata.getName()})
      data = this.callForm.value
    }
    if(val == 'email'){
      this.emailForm.patchValue({userId:this.userdata.getId()})
      this.emailForm.patchValue({addedBy:this.userdata.getName()})
      data = this.emailForm.value
    }
    if(val == 'terms'){
      this.termsForm.patchValue({userId:this.userdata.getId()})
      this.termsForm.patchValue({addedBy:this.userdata.getName()})
      data = this.termsForm.value
    }
    if(val == 'miniPromo'){
      this.miniPromoForm.patchValue({userId:this.userdata.getId()})
      this.miniPromoForm.patchValue({addedBy:this.userdata.getName()})
      data = this.miniPromoForm.value
    }
    this.customizeService.addCustomization(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.reloadCurrentRoute()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

  addMoreContacts(content:any){
    this.modalService.open(content)
  }

  saveAddMoreContacts(){
    this.spinner.show()
    var data ={
      customization_name:'Save Contacts',
      customization_type:7,
      addedBy: this.userdata.getName(),
      userId: this.userdata.getId(),
      customization_detail: JSON.stringify([
        { key_name:this.contactsForm.get('key_name')?.value, key_value:this.contactsForm.get('key_value')?.value }
      ])
    }
    this.customizeService.addObjectCustomization(data).subscribe(
      (res:any) =>{
        this.spinner.hide()
        this.modalService.dismissAll()
        this.toast.success(res.message,'Success')
        this.reloadCurrentRoute()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  deleteCustomization(data:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        var dataId = {
          customization_type: 7,
          _id: data,
          userId: this.userdata.getId()
        }
        this.customizeService.deleteObjectCustomization(dataId).subscribe(
          (res:any)=>{
            this.spinner.hide()
            this.toast.success(res.message,'Success')
            this.reloadCurrentRoute()
          },err=>{
            this.spinner.hide()
            this.toast.error(err.error.message,'Error')
          }
        )
      }
    })
  }

}
