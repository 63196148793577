<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Customer</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Customer</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <ul class="nav-tabs nav mb-4">
                  <li class="nav-item">
                    <a [routerLink]="['/admin/customer/list']" class="nav-link" routerLinkActive="active">List</a>
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/customer/new']" class="nav-link" routerLinkActive="active">Add New </a>
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/customer/online']" class="nav-link" routerLinkActive="active">Online </a>
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/customer/active']" class="nav-link" routerLinkActive="active">Active </a>
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/customer/blocked']" class="nav-link" routerLinkActive="active">Blocked</a>
                  </li>
                </ul>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->