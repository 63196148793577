import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewServiceService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
  }

  public addDirectReview(id: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addDirectReview", id, { headers: header_object });
  }

  public getAllReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allreviews", data, { headers: header_object });
  }

  public getAutoFlaggedReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/autoflagged", data, { headers: header_object });
  }

  public deleteReview(id: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deletereview", id, { headers: header_object });
  }

}
