<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Customer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit Customer</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div>
        <form [formGroup]="editCustomerForm" (submit)="onEditCustomerSubmit()" class="row" id="user-profile">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputname">Customer ID<strong class="required-text">*</strong></label>
                                    <input type="text" class="form-control" form placeholder="Customer ID" formControlName="customer_id" readonly>
                                    
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputname">Full Name <strong class="required-text">*</strong></label>
                                    <input type="text" class="form-control" form placeholder="Full Name" formControlName="name">
                                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="row">
                                    <div class="col-md-3 form-group">
                                        <label for="country_code">Country Code *</label>
                                        <input type="text" class="form-control" formControlName="country_code" placeholder="Country Code" />
                                        <div *ngIf="f.country_code.invalid && (f.country_code.dirty || f.country_code.touched)" class="alert alert-danger mt-1">
                                            Valid Country Code Number Required
                                        </div>
                                    </div>
                                    <div class=" col-md-9 form-group">
                                        <label for="exampleInputnumber">Contact Number <strong class="required-text">*</strong></label>
                                        <input type="text" class="form-control" id="exampleInputnumber" placeholder="Phone number" formControlName="phone">
                                        <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="alert alert-danger mt-1">
                                            Valid Phone Number Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputname">Email <strong class="required-text">*</strong></label>
                                    <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                    <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger mt-1">
                                        Valid Phone Number Required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="btn btn-success mt-1 col-4" [disabled]="!editCustomerForm.valid">Save</button>
                        <p class="text-danger" *ngIf="!editCustomerForm.valid">Fields with * are Required</p>
                    </div>
                </div>
            </div>
            <!-- COL-END -->
        </form>
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
