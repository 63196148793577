<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Notes</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Notes</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>Notes</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <div class="row pr-2 pl-2">
                                                    <div class="col-md-4 pt-4">
                                                        <input type="text"  placeholder="Search ID, Astrologer Name, Customer Name, Person Name..." class="form-control mt-4"
                                                        (keyup)="updateFilter($event)"   />
                                                    </div>
                                                    <div class="col-md-6 offset-md-2">
                                                        <div class="row mr-2">
                                                            <div class="col-5">
                                                                <label for="">Astrologer</label>
                                                                <select class="form-control" [(ngModel)]="astro">
                                                                    <option value="all">All</option>
                                                                    <option *ngFor="let astro of astroList" [value]="astro['_id']">{{astro['name']}}</option>
                                                                </select>
                                                            </div>

                                                            <div class="col-5">
                                                                <label for="">Customer</label>
                                                                <select class="form-control" [(ngModel)]="customer">
                                                                    <option value="all">All</option>
                                                                    <option *ngFor="let customer of customerList" [value]="customer['_id']">{{customer['name']}}</option>
                                                                </select>
                                                            </div>

                                                            <button class="col-2 btn btn-sm btn-primary mt-4" (click)="search()">Search</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <ngx-datatable class="material" [rows]="noteList"
                                                    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                                                    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
                                                    [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                                                    <ngx-datatable-column name="ID" prop="noteId" [flexGrow]="1">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            #{{row.noteId}}
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Description" prop="note_description"
                                                        [flexGrow]="3">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            {{row.note_description}}
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Customer" prop="customerId.name" [flexGrow]="3">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            #{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Astrologer" prop="astroId.name" [flexGrow]="3">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                              <span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Person" prop="personId.name"
                                                        [flexGrow]="3">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            {{row.personId.name}}
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                </ngx-datatable>

                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
