<!-- 
    _id: "615e98a91931067fe611de3f"
​​​​
addedBy: "Admin User"
​​​​
addedId: "600822c08df5f94214bc6bba"
​​​​

​​​​
log_date: "2021-10-07T06:50:17.902Z"

 -->
<form [formGroup]="updateHistoryForm" (submit)="updateHistory()" >
    <div class="row">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Update Astrologer History</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Call Taken <i class="fas fa-phone-alt"></i></label>
                                <input  [attr.disabled]="!isEditing ? '' : null" type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Taken" formControlName="call_taken">
                                <div *ngIf="f.call_taken.invalid && (f.call_taken.dirty || f.call_taken.touched)" class="alert alert-danger mt-1">
                                    Number Only
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Call Duration (mins) <i class="fas fa-phone-alt"></i></label>
                                <input [attr.disabled]="!isEditing ? '' : null" type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Taken" formControlName="call_duration">
                                <div *ngIf="f.call_duration.invalid && (f.call_duration.dirty || f.call_duration.touched)" class="alert alert-danger mt-1">
                                    Number Only
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Chat Taken <i class="fas fa-comment-alt-lines"></i></label>
                                <input [attr.disabled]="!isEditing ? '' : null" type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Taken" formControlName="chat_taken">
                                <div *ngIf="f.chat_taken.invalid && (f.chat_taken.dirty || f.chat_taken.touched)" class="alert alert-danger mt-1">
                                    Number Only
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Chat Duration (mins) <i class="fas fa-comment-alt-lines"></i></label>
                                <input [attr.disabled]="!isEditing ? '' : null" type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Taken" formControlName="chat_duration">
                                <div *ngIf="f.chat_duration.invalid && (f.chat_duration.dirty || f.chat_duration.touched)" class="alert alert-danger mt-1">
                                    Number Only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Query Taken <i class="fas fa-phone-alt"></i></label>
                                <input [attr.disabled]="!isEditing ? '' : null" type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Taken" formControlName="query_taken">
                                <div *ngIf="f.query_taken.invalid && (f.query_taken.dirty || f.query_taken.touched)" class="alert alert-danger mt-1">
                                    Number Only
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Report Taken <i class="fas fa-phone-alt"></i></label>
                                <input [attr.disabled]="!isEditing ? '' : null" type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Taken" formControlName="report_taken">
                                <div *ngIf="f.report_taken.invalid && (f.report_taken.dirty || f.report_taken.touched)" class="alert alert-danger mt-1">
                                    Number Only
                                </div>
                            </div>
                        </div>
                 
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-center">
                    <button *ngIf="isEditing" type="submit" class="col-sm-4 btn btn-success mt-1">Save</button>
                    <button *ngIf="!isEditing" (click)="isEditing=!isEditing" type="button" class="col-sm-4 btn btn-success mt-1">Edit</button>
                </div>
            </div>           
        </div>
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Update Logs</h3>
                </div>
                <div class="card-body">
                    <ngx-datatable class="material" [rows]="logs" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                        <ngx-datatable-column [flexGrow]="1" name="Type" prop="charge_type">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="text-uppercase badge badge-primary">{{getType(row.log_type)}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Value" prop="current_value">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                            {{(row.current_value/60).toFixed(2)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Updated Value" prop="new_value">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{(row.new_value/60).toFixed(2)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Added By">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.addedBy}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Updated At">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.log_date | date:'medium'}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>

    </div>
</form>