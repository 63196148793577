<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text" placeholder="Search Order ID, Astro Name, Customer Name..." class="form-control col-lg-3 mt-1" (keyup)="updateFilter($event)"/>
    <div class="mt-3">
        <button class="btn btn-danger mr-2" (click)="getReportOrderList()">All Orders</button>
        <button class="btn btn-success mr-2" (click)="completedReportOrder()">Completed Orders</button>
        <button class="btn btn-primary mr-2" (click)="notAnswered()">Not Answered</button>
        <button class="btn btn-info mr-2" (click)="noCustomerFeedback()">No Customer Feedback</button>
        <button class="btn btn-warning mr-2" (click)="noAstrologerFeedbackReply()">No Feedback Reply</button>
        <button class="btn btn-success mr-2" (click)="notReviewed()">Not Reviewed</button>
    </div>
</div>
<br>
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Answer Report #{{singleData.oreportId}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formGroup" (submit)="reportAnswer()">
        <div class="mb-3">
          <label for="dateOfBirth">Answer</label>
          <textarea appBlockCopyPaste class="form-control" formControlName="astro_answer" rows="10" (keyup)="countCharacters($event)"></textarea>
          <p class="text-right text-muted">
            {{characterCount}}/2000 Characters</p>
        </div>
        <div class="mb-3"><button type="submit" class="btn btn-outline-primary">Save</button></div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </ng-template>
<ngx-datatable class="material" [rows]="reportList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="oquestionId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/report/', row._id]">#{{row.oreportId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/astrologers/viewAstrologer',row.astroId?._id]"><span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/customers/viewCustomer',row.customerId?._id,'detail']">#{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="customer_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- {{row.customer_amount | currency :'INR':'symbol-narrow'}} -->
            {{row.orderserviceId?.customerCurrencySymbol}}{{row.customer_amount | number : '.2-2'}} 
            <!-- <span class="badge badge-pill badge-primary" *ngIf="row.is_foreign_order">{{row?.customerId?.regCountryName}}</span> -->

        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="International?" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.is_foreign_order ? 'Yes' : 'No'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.isCancelled">
            Cancelled
          </span>
          <span *ngIf="!row.isCancelled">
            {{row.order_current_status}}
          </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/report/', row._id]"><i class="fa fa-eye"></i></a>
            <button class="ml-2 btn btn-primary btn-sm" (click)="open(content,row)" *ngIf="!row.is_astro_answer">Answer Report</button>
            <!-- <button class="ml-2 btn btn-primary btn-sm" (click)="markAsComplete(row._id)" *ngIf="!row.is_order_completed">Mark Complete</button> -->
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
