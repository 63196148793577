import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata : UserdataService,
    private toast : ToastrService,
    private route: Router
  ) { }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.orderService.getStoreOrderDetail({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/order/store')
      }
    )
  }

  /**
   * Review 
   */
   changeReviewReply: boolean = false

   reviewReply: FormGroup = new FormGroup({
    orderId: new FormControl(''),
    reviewReplyBy: new FormControl(''),
    reviewReply: new FormControl('', [Validators.required]),
    updatedById: new FormControl(''),
  });

  updateReviewReply() {
    this.changeReviewReply = !this.changeReviewReply
  }

  get g() {
    return this.reviewReply.controls
  }

  reviewAnswer() {
    this.reviewReply.patchValue({ updatedById: this.userdata.getId() })
    var msg = (this.reviewReply.get('reviewReply')?.value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply',"This message cannot be sent as our policy doesn't allows to share number or email",'error')
      return;
    }
    this.reviewReply.patchValue({ reviewReply: msg })
    this.spinner.show()
    this.reviewReply.patchValue({ orderId: this.data._id })
    this.reviewReply.patchValue({ astroId: this.userdata.getId() })
    this.reviewReply.patchValue({ reviewReplyBy: this.userdata.getName() })
    this.orderService.answerStoreReview(this.reviewReply.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.changeReviewReply = false
        this.getDataById()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Check three days
   */
   checkThreeDays(val:any) {
    var str = val
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    var enddateComponent = moment().utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(enddateComponent, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 3) {
      return true
    } else {
      return false
    }
  }

}
