import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/admin/services/report/report.service';

@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.scss']
})
export class ListReportsComponent implements OnInit {

  BaseUrl = '';
  data: any = '';
  id: any = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private reports: ReportService,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }
     
  ngOnInit(): void {
    this.getReportsList();
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  getReportsList() {
    this.spinner.show()
    this.reports.getReportsList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
