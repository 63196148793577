import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {

  @Input()
  appHasRole: string[] = [];
  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: UserdataService
  ) { }

  ngOnInit() {
    const userRoles = this.authService.getPermissions()
    if (!userRoles) {
      this.viewContainerRef.clear();
    }
    // if user has role needed then render the element
    if (this.authService.roleMatch(this.appHasRole)) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    }
  }

}
