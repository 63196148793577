import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';

@Component({
  selector: 'app-customer-all-order',
  templateUrl: './customer-all-order.component.html',
  styleUrls: ['./customer-all-order.component.scss']
})
export class CustomerAllOrderComponent implements OnInit {

  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) { } 
  title = 'angular-datatables';

  orderList = [];
  id: any = '';
  temp: any = [];
  getOrderData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.router.parent?.params.subscribe(
      (params) => {
        this.id = params.data
      });
    this.getAllOrderList()
    this.orderList = this.getOrderData;
  }

  /* ***********
  list All Orders
  *********** */

  getAllOrderList() {
    this.spinner.show()
    this.order.getAllOrderList({customerId:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderList = res.data;
        // console.log(this.orderList)
        this.temp = this.orderList
        this.getOrderData = this.orderList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 || !val ||
        d.oserviceId.toString().indexOf(val) !== -1 || !val ||
        d.astroId?.name.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.orderList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
