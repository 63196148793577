<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Search Name, Year..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />

</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="2" name="Name" prop="festivalId.festivalName">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.festivalId.festivalName">{{row.festivalId.festivalName}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Year">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.year}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Festival Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.festivalDate | date:'mediumDate'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/festival-yearly/',row._id]" class="btn-table-custom btn btn-sm btn-outline-primary mr-1">Edit</a>
            <a href="javascript:void(0)" class="btn-table-custom btn btn-sm btn-outline-primary" (click)="deleteYearlyFestival(row)">Delete</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
