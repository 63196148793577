
<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header" *ngIf= "id">
        <h4 class="page-title">Edit Reports</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/reports']">Reports</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit Report</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->

    <!-- ROW-1 OPEN -->
    <form [formGroup]="addReportForm" (submit)="onAddReportSubmit()" >
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">{{id? 'Edit Report' : 'Add Report'}}</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2 col-md-6" *ngIf="id">
                                <div class="form-group">
                                    <img class="" height="80px" [src]=getSanitizeUrl(data.report_image) alt="Astro Image">
                                </div>
                            </div>
                            <ng-template #add>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label for="report_image">Report Image (Size Allowed: 200 X 200)</label>
                                        <input type="file" class="form-control" 
                                            placeholder="Report Image" (change)="onReportImageUpload($event)" required>
                                            <div class="alert alert-danger mt-1" *ngIf="imageError != '' && imageError != null">
                                                {{imageError}}
                                            </div>
                                            <div *ngIf="f.report_image.invalid && (f.report_image.dirty || f.report_image.touched)" class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #edit>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="report_image">Report Image (Size Allowed: 200 X 200)</label>
                                        <input type="file" class="form-control" 
                                            placeholder="Report Image" (change)="onReportImageUpload($event)">
                                            <div class="alert alert-danger mt-1" *ngIf="imageError != '' && imageError != null">
                                                {{imageError}}
                                            </div>
                                            <div *ngIf="f.report_image.invalid && (f.report_image.dirty || f.report_image.touched)" class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-container *ngIf="id; then edit; else add">
                            </ng-container>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="">Title</label>
                                    <input type="text" class="form-control" form
                                        placeholder="Report Title" formControlName="reportname">
                                    <div *ngIf="f.reportname.invalid && (f.reportname.dirty || f.reportname.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>    
                                </div>
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label class="">Report Description</label>
                                    <textarea class="form-control" rows="6" formControlName="reportdescription" placeholder="Report Description">Report Descrpition</textarea>
                                    <div *ngIf="f.reportdescription.invalid && (f.reportdescription.dirty || f.reportdescription.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1" [disabled]="!addReportForm.valid">{{id? 'Update': 'Save'}}</button>
                        <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                    </div>
                </div>
               
            </div>

        </div>
    </form>
    <!-- ROW-1 CLOSED -->

</div>
<!--CONTAINER CLOSED -->