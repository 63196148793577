import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reply-customer-issue',
  templateUrl: './reply-customer-issue.component.html',
  styleUrls: ['./reply-customer-issue.component.scss']
})
export class ReplyCustomerIssueComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private issueService: IssueServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
    private astroService: AstroServiceService
  ) { }

  data:any = '';
  id: any = '';
  issueId: any = ''
  title: any = ''
  description: any = ''
  issueSolved:boolean = false

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', Validators.required),
    _id: new FormControl('', Validators.required),
    reply_by_system: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
    is_issue_solved: new FormControl(false)
  });


  getDataById() {
    this.spinner.show()
    this.issueService.getDetailCustomerIssues({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.issueId = res.data.issueId
        this.title = res.data.issue_title
        this.description = res.data.issue_description
      },
      err => {
        this.route.navigateByUrl('/admin/issue/customer')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Reply Issue
 *********** */

  public replyIssue() {
    this.spinner.show();
    this.issueService.replyCustomerIssues(this.formGroup.value,false).subscribe(
      (res: any) => {
        this.spinner.hide()
        //console.log(res)
        this.toast.success('Issue Resolved!', 'Success');
        this.route.navigateByUrl('/admin/issue/customer')
      },
      err => {
        this.spinner.hide()
        //console.log(err)
        this.toast.error('Something went wrong!', 'Error');
      })

  }

  isIssueSolved(event:any){
    if(event.target.checked){
      //console.log('checked')
      this.formGroup.patchValue({is_issue_solved:true})
    }else{
      //console.log('unchecked')
      this.formGroup.patchValue({is_issue_solved:false})
    }
  }

  /****************************
   * Refund Customer
   *****************************/
   refundGroup: FormGroup = new FormGroup({
    userId: new FormControl(''),
    _id: new FormControl(''),
    addedBy: new FormControl(''),
    customerId: new FormControl(''),
    is_issue_refunded: new FormControl(true),
    is_amount_refunded: new FormControl(true),
    amount_refunded: new FormControl('',Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    is_promo_refunded: new FormControl(false),
    astroId: new FormControl(''),
    is_call: new FormControl(''),
    is_chat: new FormControl(''),
    duration: new FormControl('',Validators.pattern('[0-9]{1,}')),
  });
  isAmountRefund = true; isPromoRefund = false;
  isCall = false; isChat = false;

  get r() {
    return this.refundGroup.controls
  }

  refundType(event:any){
    this.isAmountRefund = false; this.isPromoRefund = false;
    if(event.target.value == 'Wallet Recharge'){
      this.isAmountRefund = true;
    }else{
      this.isPromoRefund = true;
      this.getAstrologerList()
    }
  }

  promoType(event:any){
    this.isCall = false; this.isChat = false;
    if(event.target.value == 'call'){
      this.isCall = true;
    }
    if(event.target.value == 'chat'){
      this.isChat = true;
    }
  }

  astroList :any = []
  getAstrologerList(){
    this.astroService.getAstrologerList({is_approved_used:true,is_approved:true}).subscribe(
      (res:any)=>{
        this.astroList = res.data
      },err=>{

      }
    )
  }

  refundIssue(){
    if(this.isAmountRefund){
      if(this.refundGroup.get('amount_refunded')?.value == ''){
        Swal.fire('Invalid Values','Please Enter Amount to be Refunded','error')
        return;
      }
      if(this.refundGroup.get('amount_refunded')?.value > this.data.amount){
        Swal.fire('Invalid Values',"Refund Amount cann't be greater than Issue Amount.",'error')
        return;
      }
    }
    if(this.isPromoRefund){
      if(!this.isCall && !this.isChat){
        Swal.fire('Invalid Values','Please Select Promo Type','error')
        return;
      }
      if(this.refundGroup.get('duration')?.value == ''){
        Swal.fire('Invalid Values','Please Enter duration for Promo','error')
        return;
      }
      if(this.refundGroup.get('astroId')?.value == ''){
        Swal.fire('Invalid Values','Please Select An Astrologer','error')
        return;
      }
    }
    this.spinner.show()
    var minutes = this.refundGroup.get('duration')?.value;
    this.refundGroup.patchValue({ duration: minutes * 60 });
    this.refundGroup.patchValue({userId:this.userdata.getId()})
    this.refundGroup.patchValue({_id:this.id})
    this.refundGroup.patchValue({addedBy:this.userdata.getName()})
    this.refundGroup.patchValue({customerId:this.data.customerId._id})
    //this.refundGroup.patchValue({is_issue_refunded:this.data.customerId})
    this.refundGroup.patchValue({is_amount_refunded:this.isAmountRefund})
    this.refundGroup.patchValue({is_promo_refunded:this.isPromoRefund})
    this.refundGroup.patchValue({is_call:this.isCall})
    this.refundGroup.patchValue({is_chat:this.isChat})
    this.issueService.refundCustomerIssues(this.refundGroup.value).subscribe(
      (res:any)=>{
        this.spinner.hide()
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/issue/customer')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
