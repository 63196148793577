
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { ExcelServiceService } from 'src/app/admin/services/excel-service/excel-service.service';

@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss']
})
export class ListCustomerComponent implements OnInit {
  BaseUrl = ''
  constructor(
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private route: Router, private router: ActivatedRoute,
    private trusturl: DomSanitizer,
    private excelService: ExcelServiceService,
    private datePipe: DatePipe,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }
  getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
  title = 'angular-datatables';
  originalList = new Array();
  customersList = new Array();
  id: any = '';
  temp: any = [];
  searchKey = ''
  getCustomerData: any = [];
  ColumnMode = ColumnMode;
  currentPage = ''
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.currentPage = this.router.snapshot.url[0].path
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getCustomerList()
    this.removeSearch();
    this.customersList = this.getCustomerData;
  }

  removeSearch() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key.includes('cus_search_')) {
        if (key != `cus_search_${this.currentPage}`) {
          localStorage.removeItem(key);
        } else {
          this.searchKey = localStorage.getItem(key) || '';
        }
      }
    }
  }
  /* ***********
  list Customers
  *********** */
  data: any = {}
  getCustomerList() {
    this.data = {}
    if (this.currentPage == 'online') {
      this.data['is_current_active'] = true
    }
    if (this.currentPage == 'blocked') {
      this.data['is_blocked'] = true
    }
    this.spinner.show()
    this.customerService.getCustomersList(this.data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.customersList = res.data;
        this.originalList = res.data
        this.temp = this.customersList;
        this.getCustomerData = this.customersList;
        let offset = localStorage.getItem(`cus_offset_${this.currentPage}`) || 0
        if (offset > 0) {
          this.myFilterTable.offset = offset
        }
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val)
  }
  searchByKey(key: string) {
    //console.log(key.length)
    if (key.length < 1) {
      this.myFilterTable.offset = 0
      localStorage.removeItem(`cus_search_${this.currentPage}`)
      this.customersList = this.originalList
      //console.log(this.customersList.length,this.originalList.length)
    } else {
      this.customersList = this.originalList.filter(function (d: any) {

        return d.name.toLowerCase().indexOf(key) !== -1 || !key ||
          d.phone.toString().indexOf(key) !== -1 || !key ||
          d.customerAutoId.toString().indexOf(key) !== -1 || !key ||
          d.email.toLowerCase().indexOf(key) !== -1 || !key
      });
      localStorage.setItem(`cus_search_${this.currentPage}`, key)
    }
    this.myFilterTable.offset = 0;
  }

  onPageChange(event: any) {
    localStorage.setItem(`cus_offset_${this.currentPage}`, event.offset);
  }

  public toggle($event: any, id: any, isBlocked: any) {
    this.customerService.blockCustomer({ '_id': id, 'block': !isBlocked }).subscribe(
      res => {
        this.spinner.hide()
        this.toast.success('Customer Unblocked', 'Success')
        this.customersList = []
        this.getCustomerList()
      }, err => {
        this.spinner.hide()
        //console.log(err);
        this.toast.error('Something went wrong', 'Error')
      }
    )
  }

  transformDate(date:any){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  generateExcel() {
    if(this.customersList.length == 0){
      return
    }
    const header = ['ID','Name','Email','Phone','Country','Register OTP','Wallet Amount','Registered On','Register Type','Status']
    const excelData:any = []
    this.customersList.forEach((row:any)=>{
      var obj = []
      obj.push(row.customerAutoId)
      obj.push(row.name)
      obj.push(row.email)
      obj.push('+'+row.country_code+'-'+row.phone)
      obj.push(row.regCountryName)
      obj.push(row.register_otp)
      obj.push(row.currencySymbol+row.current_wallet+'('+row.currencyCode+')')
      obj.push(this.transformDate(row.created_at))
      if(row.is_social_login){
        obj.push('Social Login')
      }else{
        obj.push('Email')
      }
      if(row?.userId?.is_blocked){
        obj.push('Blocked')
      }else{
        obj.push('Active')
      }
      // obj.push(row.total_call_taken)
      // obj.push(row.total_chat_taken)
      // obj.push(row.total_query_taken)
      // obj.push(row.total_report_taken)
      excelData.push(obj)
    })
    this.excelService.generateExcel(excelData,'Customer List',header);
  }

}
