import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { WaitingListServiceService } from 'src/app/admin/services/waiting-list/waiting-list-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss'],
})
export class WaitingListComponent implements OnInit {
  currentPage = '';
  constructor(
    private waitlistService: WaitingListServiceService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  waitList = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  searchKey = '';
  ngOnInit() {
    this.getWaitingList({ is_user_notified: false });
    this.searchKey =
      localStorage.getItem(`waiting_search_${this.currentPage}`) || '';
  }

  getWaitingList(data:any) {
    this.spinner.show();
    this.waitlistService
      .getAllWaitingList(data)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.waitList = res.data;
          this.originalList = res.data;
          if (this.searchKey.length > 0) {
            this.searchByKey(this.searchKey);
          }
          let offset = localStorage.getItem(`waiting_offset_${this.currentPage}`)||0
          if(offset > 0) {
            this.myFilterTable.offset = offset
          }
        },
        (err) => {
          this.spinner.hide();
          //console.log(err);
        }
      );
  }

  getCallWaiting() {
    this.getWaitingList({ is_user_notified: false, is_call: true })
  }

  getChatWaiting() {
    this.getWaitingList({ is_user_notified: false, is_chat: true })
  }

  getOnlineWaiting() {
    this.getWaitingList({is_user_notified: false, is_current_active: true })
  }

  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val)
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.waitList = this.originalList;
    } else {
      this.waitList = this.originalList.filter(function (d: any) {
        return (
          d.waitingId.toString().includes(key) ||
          d.astroId?.astroAutoId.toString().includes(key) ||
          d.customerId?.customerAutoId.toString().includes(key) ||
          d.astroId?.name.toLowerCase().includes(key) ||
          d.astroId?.displayname.toLowerCase().includes(key) ||
          d.customerId?.name.toLowerCase().includes(key)
        );
      });
      localStorage.setItem(`waiting_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`waiting_offset_${this.currentPage}`,event.offset)
  }
  /**
   * Remove from waiting List
   */
  removeFromWaitingList(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.waitlistService.removeFromWaitingList({ waitingId: id }).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.getWaitingList({ is_user_notified: false });
          },
          (err) => {
            this.spinner.hide();
            //console.log(err);
          }
        );
      }
    });
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
  
    var timeString = hours.toString().padStart(2, '0') 
                + ':' + minutes.toString().padStart(2, '0') 
                + ':' + seconds.toString().padStart(2, '0'); 
    return timeString
  } 
}
