import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { SubcategoryMallService } from 'src/app/admin/services/subcategory-mall/subcategory-mall.service';

@Component({
  selector: 'app-edit-mall-subcategory',
  templateUrl: './edit-mall-subcategory.component.html',
  styleUrls: ['./edit-mall-subcategory.component.scss']
})
export class EditMallSubcategoryComponent implements OnInit {

  dataList:any = {}
  id:any =''
  isUnique: boolean = true;
  categoryList: any;
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private subcategoryMallService: SubcategoryMallService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private categoryMallService: CategoryMallService,
  ) { }

  getSingleCategoryList() {
    this.spinner.show()
    this.subcategoryMallService.detailMallSubcategory({_id:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.formGroup.patchValue({_id:this.dataList._id})
        this.formGroup.patchValue({categoryId:this.dataList.categoryId._id})
        this.formGroup.patchValue({subcategoryname:this.dataList.subcategoryname})
        this.formGroup.patchValue({subcategorydescription:this.dataList.subcategorydescription})
        this.formGroup.patchValue({updatedBy:this.userdata.getName()})
        this.formGroup.patchValue({subcategory_slug:this.dataList.subcategory_slug})
        this.formGroup.patchValue({category_keyword:this.dataList.category_keyword})
        this.formGroup.patchValue({is_front_subcategory:this.dataList.is_front_subcategory})
        this.formGroup.patchValue({updatedId:this.userdata.getId()})
      },
      (err) => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-subcategory/list')
      }
    );
  }
  
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    categoryId: new FormControl('', Validators.required),
    subcategoryname: new FormControl('', Validators.required),
    subcategorydescription: new FormControl('', Validators.required),
    updatedBy: new FormControl(''),
    subcategory_slug: new FormControl(''),
    subcategory_keyword: new FormControl(''),
    subcategory_image: new FormControl(''),
    updatedId: new FormControl('', Validators.required),
    is_front_subcategory: new FormControl(false, Validators.required),
  });

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getSingleCategoryList()
    this.getCategoryList()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Category Image
   */
   imageError: string = ''
   imageSrc = ''
   public onBannerUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;
 
       reader.readAsDataURL(file);    
 
       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;    
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Image dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              subcategory_image: event.target.files[0]
             })
           }
         }
       };
     }
   }

   /* ***********
  list Category
  *********** */

  getCategoryList() {
    this.spinner.show()
    this.categoryMallService.listMallCategory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.categoryList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

   update(){
    const data = new FormData()
    data.append("categoryId", this.formGroup.get("categoryId")?.value)
    data.append("subcategoryname", this.formGroup.get("subcategoryname")?.value)
    data.append("subcategorydescription", this.formGroup.get("subcategorydescription")?.value)
    data.append("updatedBy", this.formGroup.get("updatedBy")?.value)
    data.append("subcategory_slug", this.formGroup.get("subcategory_slug")?.value)
    data.append("category_keyword", this.formGroup.get("category_keyword")?.value)
    data.append("subcategory_image", this.formGroup.get("subcategory_image")?.value)
    data.append("_id", this.formGroup.get("_id")?.value)
    data.append("updatedId", this.formGroup.get("updatedId")?.value)
    data.append("is_front_subcategory", this.formGroup.get("is_front_subcategory")?.value)
    this.subcategoryMallService.updateMallSubcategory(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/mall-subcategory/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  checkSlugUnique(event:any){
    this.subcategoryMallService.checkMallSubcategoryUnique({slug:event.target.value}).subscribe(
      (res:any)=>{
        if(res.success){
          this.isUnique = true
        }else{
          this.isUnique = false
        }
      }
    )
  }

}
