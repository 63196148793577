import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-mall-category',
  templateUrl: './add-mall-category.component.html',
  styleUrls: ['./add-mall-category.component.scss']
})
export class AddMallCategoryComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private categoryMallService: CategoryMallService,
    private route: Router
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    categoryname: new FormControl('', Validators.required),
    category_gst: new FormControl('', Validators.required),
    categorydescription: new FormControl('', Validators.required),
    addedBy: new FormControl('', [Validators.required]),
    category_slug: new FormControl('',[Validators.required,Validators.pattern('^[a-z0-9A-Z]+(?:-[a-z0-9A-Z]+)*$')]),
    category_keyword: new FormControl(''),
    category_image: new FormControl('', Validators.required),
    is_front_category: new FormControl(false),
  });

  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Category Image
   */
   imageError: string = ''
   imageSrc = ''
   public onBannerUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;
 
       reader.readAsDataURL(file);    
 
       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;    
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Image dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              category_image: event.target.files[0]
             })
           }
         }
       };
     }
   }

   submit(){
    const data = new FormData()
    data.append("userId", this.formGroup.get("userId")?.value)
    data.append("categoryname", this.formGroup.get("categoryname")?.value)
    data.append("category_gst", this.formGroup.get("category_gst")?.value)
    data.append("categorydescription", this.formGroup.get("categorydescription")?.value)
    data.append("addedBy", this.formGroup.get("addedBy")?.value)
    data.append("category_slug", this.formGroup.get("category_slug")?.value)
    data.append("category_keyword", this.formGroup.get("category_keyword")?.value)
    data.append("category_image", this.formGroup.get("category_image")?.value)
    data.append("is_front_category", this.formGroup.get("is_front_category")?.value)
    this.categoryMallService.addMallCategory(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/mall-category/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  isUnique:boolean = true

  checkSlugUnique(event:any){
    this.categoryMallService.checkMallCategoryUnique({slug:event.target.value}).subscribe(
      (res:any)=>{
        if(res.success){
          this.isUnique = true
        }else{
          this.isUnique = false
        }
      }
    )
  }
}
