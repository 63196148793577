<div class="row pr-2 pl-2">
    <div class="col-md-4">
        <input type="text"  placeholder="Search ID, Name, Email..." class="form-control mt-1"
        (keyup)="updateFilter($event)"   />
    </div>
    <div class="col-md-6 offset-md-2">
        <div class="row mr-2">
            <select class="form-control col-3" [(ngModel)]="year">
                <option *ngFor="let year of yearList">{{year}}</option>
            </select>

            <select class="form-control col-3" [(ngModel)]="month">
                <option *ngFor="let month of monthList" [value]="month.id">{{month.name}}</option>
            </select>

            <select class="form-control col-3" [(ngModel)]="type">
                <option value="all">All</option>
                <option value="call">Call</option>
                <option value="chat">Chat</option>
                <option value="query">Query</option>
                <option value="report">Report</option>
            </select>
            <button class="col-3 btn btn-sm btn-primary" (click)="search()">Search</button>
        </div>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="orderList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column name="Astro ID" prop="astroAutoId" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.astroAutoId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="displayname" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span [title]="row.name">{{(row.displayname == '' || row.displayname == undefined || row.displayname == null) ? row.name : row.displayname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Email" prop="email" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.email}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Total Promo Orders" prop="totalPromoOrders" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.totalPromoOrders}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Total Paid Orders" prop="totalpaidorders" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.totalpaidorders}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Total Orders" prop="totalorders" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.totalorders}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
