<div style="display: flex; justify-content: space-between;" class="pr-2 pl-2">
    <input type="text" placeholder="Search Order ID, Astro Name, Customer Name..." class="form-control
        col-lg-4 mt-1" (keyup)="updateFilter($event)" />
    <div>
        <!-- Search By Customer
        <select class="form-control d-inline-block" style="width: 55% !important;" (change)="getOrderByCustomerId($event)">
            <option [selected]="true">All</option>
            <option [value]="customer._id" *ngFor="let customer of customerList">#{{customer.customerAutoId}} - {{customer.name}}</option>
        </select> -->
        <ng-select
                      placeholder="Select Customer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="customerId"
                      (change)="getOrderByCustomerIdV2($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Customer</ng-option
                      >
                      <ng-option
                        [value]="customer._id"
                        *ngFor="let customer of customerList"
                      >
                        #{{ customer.customerAutoId }} - {{ customer.name }}
                      </ng-option>
                    </ng-select>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="orderList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="orderpromoId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.orderpromoId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Order Type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_chat" class="badge badge-primary">Chat</span>
            <span *ngIf="row.is_call" class="badge badge-info">Call</span>
            <span *ngIf="row.is_refunded_promo" class="ml-2 badge badge-danger">REFUND</span>

        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Duration" prop="duration">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.duration/60}} min.
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/astrologers/viewAstrologer',row.astroId?._id]"><span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/customers/viewCustomer',row.customerId?._id,'detail']">#{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Person" prop="personId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.personId?.pId}}-{{row.personId?.name}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Comments" prop="comments">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.comments}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Is Used?" prop="is_used">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_used" class="badge badge-primary">Used</span>
            <span *ngIf="!row.is_used" class="badge badge-info">Pending</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Status" prop="status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.status" class="badge badge-success">Enabled</span>
            <span *ngIf="!row.status" class="badge badge-danger">Disabled</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <i *ngIf="!row.is_used && row.status" class="fa fa-times text-primary fa-2x" (click)="cancelPromo(row._id)" title="Cancel"></i>&nbsp;
            <i *ngIf="!row.is_used && row.status" class="fa fa-link text-success fa-2x" (click)="connectPromo(row._id)" title="Connect"></i>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
