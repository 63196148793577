<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- ROW-1 OPEN -->
  <form [formGroup]="formGroup" (submit)="addNewProduct()">
    <div class="row">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Add Product</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="productcategoryId">Product Category*</label>
                  <ng-select placeholder="Select Category" appendTo="body" [searchable]="true" [clearable]="true"
                    formControlName="productcategoryId" (change)="getSubcategoryList($event)">
                    <ng-option selected disabled value=''>Select Category</ng-option>
                    <ng-option [value]="category._id" *ngFor="let category of categoryList">{{category.categoryname}}
                    </ng-option>
                  </ng-select>
                  <small
                    *ngIf="f.productcategoryId.invalid && (f.productcategoryId.dirty || f.productcategoryId.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isSubcategoryAssociate">
                <div class="form-group">
                  <label for="productsubcategoryId">Product Subcategory*</label>
                  <ng-select placeholder="Select Subcategory" appendTo="body" [searchable]="true" [clearable]="true"
                    formControlName="productsubcategoryId">
                    <ng-option selected disabled value=''>Select Subcategory</ng-option>
                    <ng-option [value]="subcategory._id" *ngFor="let subcategory of subcategoryList">{{subcategory.subcategoryname}}
                    </ng-option>
                  </ng-select>
                  <small
                    *ngIf="f.productsubcategoryId.invalid && (f.productsubcategoryId.dirty || f.productsubcategoryId.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="productname">Name *</label>
                  <input type="text" class="form-control" placeholder="Product Name" formControlName="productname">
                  <small *ngIf="f.productname.invalid && (f.productname.dirty || f.productname.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="product_image">Image * <span class="small-text">(Max 12 images, Image should be 960 X 540)</span></label>
                  <input type="file" class="form-control" placeholder="Festival Image"
                    (change)="onProductImageUpload($event)" multiple accept="image/*">
                  <small *ngIf="f.product_image.invalid && (f.product_image.dirty || f.product_image.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required.
                  </small>
                  <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                    {{imageError}}
                  </small>
                </div>
              </div>

            </div>
            <!-- <div class="row">
              
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="isFeaturedProduct">Is Featured Product?*</label>
                  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="isFeaturedProduct" (change)="checkFeaturedProduct()">
                    <mat-radio-button class="example-radio-button" [value]="true">
                      Yes
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [value]="false">
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div> -->
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="productdescription">Description*</label>
                  <!-- <textarea placeholder="Product Description" class="form-control"
                    formControlName="productdescription"></textarea> -->
                    <editor apiKey="kb1shdcv4s1c8mg7s4jo6ebmrmwqkkkx5hfwwpofj87i778i" [init]="{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists print preview',
                        'searchreplace code',
                        'insertdatetime table paste code help wordcount image'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                        bullist numlist outdent indent | removeformat | help'
                    }" formControlName="productdescription" style="width:100% !important;"></editor>
                  <small
                    *ngIf="f.productdescription.invalid && (f.productdescription.dirty || f.productdescription.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required.
                  </small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="product_keyword">Meta Keywords (Seprate keywords by ,)</label>
                  <textarea placeholder="Product Keywords" class="form-control"
                    formControlName="product_keyword" style="height: 300px;"></textarea>
                  <!-- <small *ngIf="f.product_keyword.invalid && (f.product_keyword.dirty || f.product_keyword.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="product_slug">Product Slug*</label>
                  <input type="text" class="form-control" placeholder="Product Slug" formControlName="product_slug">
                    <!-- (change)="checkSlugUnique($event)"> -->
                  <small *ngIf="f.product_slug.invalid && (f.product_slug.dirty || f.product_slug.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required. Slug can only contain '-' and no space or any special symbol.
                  </small>
                  <small *ngIf="!isUnique" class="d-block alert alert-danger mt-1">
                    Slug should be unique and this slug has been already used.
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="gstPercent">GST % *</label>
                  <input type="text" class="form-control" placeholder="GST %" formControlName="gstPercent">
                  <small *ngIf="f.gstPercent.invalid && (f.gstPercent.dirty || f.gstPercent.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="videolink">Video Link</label>
                  <input type="text" class="form-control" placeholder="Video Link" formControlName="videolink">
                  <!-- <small *ngIf="f.videolink.invalid && (f.videolink.dirty || f.videolink.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small> -->
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="delivery_time">Delivery Time</label>
                  <input type="text" class="form-control" placeholder="Delivery Time" formControlName="delivery_time">
                  <small *ngIf="f.delivery_time.invalid && (f.delivery_time.dirty || f.delivery_time.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="is_addon_associate">Is Addon Associated?*</label>
                  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="is_addon_associate" (change)="checkAddonAssociated()">
                    <mat-radio-button class="example-radio-button" [value]="true">
                      Yes
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [value]="false">
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="is_astrologer_associate">Is Astrologer Associated?*</label>
                  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="is_astrologer_associate" (change)="checkAstrologerAssociated()">
                    <mat-radio-button class="example-radio-button" [value]="true">
                      Yes
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [value]="false">
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isAstro">
                <div class="form-group">
                  <label for="min_price">Minimum Price(INR) * (Display Price)</label>
                  <input type="text" class="form-control" placeholder="Minimum Price" formControlName="min_price">
                  <small *ngIf="f.min_price.invalid && (f.min_price.dirty || f.min_price.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required. Only Numbers Allowed.
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="productCode">Product Code *</label>
                  <input type="text" class="form-control" placeholder="Product Code" formControlName="productCode">
                  <small *ngIf="f.productCode.invalid && (f.productCode.dirty || f.productCode.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2" *ngIf="!isAstro">
                <div class="form-group">
                  <label for="price">INR Price*</label>
                  <input type="text" class="form-control" placeholder="Price" formControlName="price">
                  <small *ngIf="f.price.invalid && (f.price.dirty || f.price.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required. Only Numbers Allowed.
                  </small>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2" *ngIf="!isAstro">
                <div class="form-group">
                  <label for="price">USD Price*</label>
                  <input type="text" class="form-control" placeholder="USD Price" formControlName="usd_price">
                  <small *ngIf="f.usd_price.invalid && (f.usd_price.dirty || f.usd_price.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required. Only Numbers Allowed.
                  </small>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2" *ngIf="!isAstro">
                <div class="form-group">
                  <label for="offer_percentage">Offer % *</label>
                  <input type="text" class="form-control" placeholder="Offer %" formControlName="offer_percentage">
                  <small *ngIf="f.offer_percentage.invalid && (f.offer_percentage.dirty || f.offer_percentage.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label for="offer_percentage">Fields Applied *</label>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isNameOn">Name</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isNameOn($event)" [checked]="false"
                        class="form-control label-warning checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isEmailOn">Email</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isEmailOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isPhoneOn">Phone</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isPhoneOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isAddressOn">Address</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isAddressOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isRingSizeOn">Ring Size</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isRingSizeOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isQuantityOn">Quantitty</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isQuantityOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isMaritalStatusOn">Marital Status</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isMaritalStatusOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isGenderOn">Gender</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isGenderOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isBithDetailOn">Birth Details</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isBirthDetailsOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="isCommentsOn">Comments</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" (change)="isCommentsOn($event)" [checked]="false"
                        class="form-control checkBox-custom">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label for="offer_percentage">Welcome Message For Customer (Initial Message in Group Chat) *</label>
                  <textarea class="form-control" formControlName="welcome_msg" cols="30" rows="10"></textarea>
                  <small *ngIf="f.welcome_msg.invalid && (f.welcome_msg.dirty || f.welcome_msg.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <button type="submit" class="col-sm-4 btn btn-success mt-1"
              [disabled]="!formGroup.valid || !isUnique">Save</button>
            <!-- <button type="submit" class="col-sm-4 btn btn-success mt-1">Save</button> -->
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->
