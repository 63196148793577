<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Astro Shop - Coupons</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/mall-coupon/list']">Coupon List</a></li>
      <li class="breadcrumb-item active" aria-current="page">Details</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <div class="card-body">
                  <div class="border-0">
                    <div class="tab-content">
                      <div class="tab-pane active show" id="tab-51">
                        <div id="profile-log-switch">
                          <div class="media-heading d-flex justify-content-between">
                            <h4>Coupon Details </h4>
                            <a [routerLink]="['/admin/malls-coupon/edit/',data._id]" class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>
                          </div>
                          <hr class="m-0">
                          <div class="table-responsive">
                            <table class="user-table-custom table row table-bordered">
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0">
                                <tr>
                                  <td><strong>ID :</strong> #{{data.couponId}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Coupon Name :</strong> {{data.couponName}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Coupon Type :</strong>
                                    <span *ngIf="data?.couponType == 1">
                                      Fixed
                                    </span>
                                    <span *ngIf="data?.couponType == 2">
                                      Percentage
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0">
                                <tr>
                                  <td>
                                    <strong>Coupon Amount:</strong>
                                    <span *ngIf="data?.couponType == 1">
                                      {{data.couponAmount | currency:'INR':'symbol-narrow'}}
                                    </span>
                                    <span *ngIf="data?.couponType == 2">
                                      {{data.couponAmount}}%
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Minimum Amount :</strong> {{data.minimumOrder | currency:'INR':'symbol-narrow'}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Maximum Discount :</strong>{{data?.maximumDiscount | currency:'INR':'symbol-narrow'}}</td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="data.isApplicableOnForeignOrders" class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0">
                                <tr>
                                  <td>
                                    <strong>Coupon Amount (USD):</strong>
                                    <span *ngIf="data?.couponType == 1">
                                      {{data.couponAmount | currency:'USD':'symbol-narrow'}}
                                    </span>
                                    <span *ngIf="data?.couponType == 2">
                                      {{data.couponAmount}}%
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="data.isApplicableOnForeignOrders" class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Minimum Amount (USD):</strong> {{data.minimumOrder | currency:'USD':'symbol-narrow'}}</td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="data.isApplicableOnForeignOrders" class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Maximum Discount (USD):</strong>{{data?.maximumDiscount | currency:'USD':'symbol-narrow'}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td><strong>Applicable On :</strong>
                                    <span *ngIf="data?.applicableOn == 1">
                                      <div>All</div>
                                    </span>
                                    <span *ngIf="data?.applicableOn == 2">
                                      <div>{{data?.productId?.productname}}</div>
                                    </span>
                                    <span *ngIf="data?.applicableOn == 3">
                                      <div>{{data?.categoryId?.categoryName}}</div>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td><strong>Status :</strong><br/>
                                    <span *ngIf="data?.status">
                                      <div class="badge badge-success badge-small">Active</div>
                                    </span>
                                    <span *ngIf="!data?.status">
                                      <div class="badge badge-primary badge-small">Inactive</div>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td><strong>Coupon Description :</strong>
                                    <div>{{data.couponDesc}}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td><strong>Coupon Code :</strong>
                                    <div>{{data.couponCode | uppercase}}</div></td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td><strong>End Date :</strong>
                                  <div>{{data?.endDate | date:'mediumDate'}}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td><strong>Coupon Usage Limit :</strong>
                                  <div>{{data?.usageLimit}}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                <tr>
                                  <td><strong>Image :</strong> <img [src]="getSanitizeUrl(data.couponImage)"
                                      class="img img-fluid" /></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
