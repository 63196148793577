import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-international',
  templateUrl: './edit-international.component.html',
  styleUrls: ['./edit-international.component.scss']
})
export class EditInternationalComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private internationalService: InternationalServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
    private _location:Location,
  ) { }

  data: any = '';
  id: any = '';
  goBack(){
    this._location.back()
  }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ updatedById: this.userdata.getId() })
    this.formGroup.patchValue({ updatedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    updatedById: new FormControl('', Validators.required),
    updatedBy: new FormControl('', Validators.required),
    countryCode: new FormControl('', Validators.required),
    currencyCode: new FormControl('', Validators.required),
    currencySymbol: new FormControl('', Validators.required),
    currencyUnitInRupees: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    callPricePercent: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    chatPricePercent: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    reportPricePercent: new FormControl('',[Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    queryPricePercent: new FormControl('',[Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
  });


  getDataById() {
    this.spinner.show()
    this.internationalService.singleInternationalPrice({ _id: this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.formGroup.patchValue({ currencyUnitInRupees: res.data.currencyUnitInRupees });
        this.formGroup.patchValue({ queryPricePercent: res.data.queryPricePercent });
        this.formGroup.patchValue({ reportPricePercent: res.data.reportPricePercent });
        this.formGroup.patchValue({ chatPricePercent: res.data.chatPricePercent });
        this.formGroup.patchValue({ callPricePercent: res.data.callPricePercent });

        this.formGroup.patchValue({ countryCode: res.data.countryCode });
        this.formGroup.patchValue({ currencySymbol: res.data.currencySymbol });
        this.formGroup.patchValue({ currencyCode: res.data.currencyCode });

      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/international/list')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Update Price
 *********** */

  public updatePrice() {
    this.spinner.show();
    this.internationalService.updateInternationalPrice(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success');
        this.goBack()
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
      })
  }

}
