import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RolesService } from 'src/app/admin/services/roles/roles.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private roleService: RolesService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: Router
  ) { }

  addRoleForm: any = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    roles_name: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    permission_name: new FormControl(''),
  })
  items = ['Manage-Customer','Manage-Astrologers','Manage-Users','Manage-Managers','Manage-Skills','Manage-Language','Manage-Report','Manage-Banner','Manage-Orders','Manage-Issues','Manage-Remedy','Admin-Wallet','Manage-Roles','Manage-Request','Manage-Customization','Manage-Coupon','Waiting-List','Reviews','Push-Notifications','Payout','Notes','Manage-Offers','Manage-Support','Manage-Blog','Manage-Festivals','Manage-Mall']

  ngOnInit(): void {
    this.addRoleForm.patchValue({'userId':this.userdata.getId()})
    this.addRoleForm.patchValue({'added_by':this.userdata.getName()})
  }

  get f() {
    return this.addRoleForm.controls
  }

  onAddRoleSubmit(){
    if (this.permissions.length <= 0 ) {
      this.toast.error('Please Select atleast one permission', 'Error')
      return
    } else {
    // var data = this.addRoleForm.get('permission_name').value.map(function (data:any) {return data.value});
    this.addRoleForm.patchValue({'permission_name':this.permissions.join()})
    this.spinner.show()
    this.roleService.addRoles(this.addRoleForm.value).subscribe(
      (res:any) =>{
        this.spinner.hide()
        this.toast.success('Role added!', 'Sucess')
        this.reloadCurrentRoute()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  permissions: any = []
  storePermissions(evnt: any) {
    if (evnt.target.checked == true) {
      //console.log('push')
      this.permissions.push(evnt.target.value)
    }
    if (evnt.target.checked == false) {
      //console.log('pop')
      this.permissions.forEach((value: any, index: any) => {
        if (value == evnt.target.value) this.permissions.splice(index, 1);
      });
    }
    this.addRoleForm.patchValue({ 'permission_name': this.permissions })
  }
}
