import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RolesService } from 'src/app/admin/services/roles/roles.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.scss']
})
export class ListRolesComponent implements OnInit {

  constructor(
    private roleService: RolesService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private route: Router 
  ) {}
  
  title = 'angular-datatables';

  roleList = [];
  id: any ='';
  temp:any = [];
  getRoleData: any = [];
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getCustomerlogersList()
    this.roleList =  this.getRoleData;
  }

  /* ***********
  list Customers
  *********** */

  getCustomerlogersList() {
    this.spinner.show()
    this.roleService.listRoles({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.roleList = res.data;        
        this.temp = this.roleList;
        this.getRoleData = this.roleList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      
      return d.name.toLowerCase().indexOf(val) !== -1 || !val || 
              d.phone.toString().indexOf(val) !== -1 || !val ||
              d.email.toLowerCase().indexOf(val) !== -1 || !val 
    });

    // update the rows
    this.roleList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }



}
