import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IssueServiceService } from '../../services/issues/issue-service.service';
import { UserdataService } from '../../services/shared/userdata/userdata.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {Location} from '@angular/common';
@Component({
  selector: 'app-suport-message',
  templateUrl: './suport-message.component.html',
  styleUrls: ['./suport-message.component.scss'],
})
export class SuportMessageComponent implements OnInit {
  data: any;
  constructor(
    private userdata: UserdataService,
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private issueService: IssueServiceService,
    private toast: ToastrService,
    private _location: Location
  ) {}
  id: any;
  chatList = new Array();
  userId: any;
  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.userId = this.userdata.getId();
    this.formGroup.patchValue({ is_reply_by_system: true });
    this.formGroup.patchValue({ userId: this.userId });
    this.formGroup.patchValue({ addedBy: this.userdata.getName() });
    this.formGroup.patchValue({ _id: this.id });
    this.getSupportMessages();
  }
  getSupportMessages() {
    this.issueService
      .getSingleIssues({ _id: this.id, userId: this.userId })
      .subscribe(
        (res: any) => {
          this.data = res.data
          this.chatList = res.data.replylogs;
        },
        (err) => {
          //console.log(err);
          this.toast.error('Server Issue');
        }
      );
  }
  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', Validators.required),
    _id: new FormControl('', Validators.required),
    reply_by_system: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
    is_issue_solved: new FormControl(false),
    is_reply_by_system: new FormControl(true),
  });

  get f() {
    return this.formGroup.controls;
  }

  sendMsg() {
    this.spinner.show();
    const data = this.formGroup.value
    this.issueService.replyToSupport(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status == 200 && res.success) {
          if(data.is_issue_solved){
            this._location.back()
          }else{
           this.getSupportMessages()
           this.formGroup.patchValue({reply_by_system:''})
          }
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }
  checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      this.sendMsg();
    }
  }
  isIssueSolved(event: any) {
    if (event.target.checked) {
      //console.log('checked')
      this.formGroup.patchValue({ is_issue_solved: true });
    } else {
      //console.log('unchecked')
      this.formGroup.patchValue({ is_issue_solved: false });
    }
  }
}
