import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlluserService } from 'src/app/admin/services/alluser/alluser.service';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';

@Component({
  selector: 'app-astro-login',
  templateUrl: './astro-login.component.html',
  styleUrls: ['./astro-login.component.scss']
})
export class AstroLoginComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private astroService: AstroServiceService,
    private userService: AlluserService,
  ) { }

  data: any = '';
  temp: any = '';
  id: any = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getAstrologerDataById()
  }

  getAstrologerDataById() {
    this.spinner.show()
    this.astroService.getAstrologerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        var userId = res.data.userId
        //console.log(userId)
        this.userService.getUserDataById({ _id: userId }).subscribe(
          (res: any) => {
            this.data = res.data.recent_logins.reverse()
            this.temp = this.data
            //console.log(this.data)
          }, err => {
            this.spinner.hide()
          }
        )
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.cwalletId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
