import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserServiceService } from 'src/app/admin/services/user/userService/user-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { MessagingService } from '../../services/messaging/messaging.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private toast: ToastrService, 
    private ngxSpinner: NgxSpinnerService, 
    private userdata: UserdataService,
    private router: Router,
    private userservice: UserServiceService,
    private messagingService: MessagingService
    ) {}

  loginForm = new FormGroup({
    //email: new FormControl('', [Validators.required]),
    //password: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    recaptcha: new FormControl('',[Validators.required]),
    is_social_login: new FormControl(false,[Validators.required]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
  });

  ngOnInit(): void {
    if(this.userdata.getData() != null){
      this.router.navigateByUrl('/admin/dashboard')
    }
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
  }

  get f(){
    return this.loginForm.controls
  }

  public onLogin(){
    this.ngxSpinner.show()
    this.loginForm.patchValue({ fcm_token: this.userdata.getFcm() })
    this.userservice.deleteFcm({ token: this.userdata.getFcm() }).subscribe(
      (res: any) => {
        this.userservice.login(this.loginForm.value).subscribe(
          (res: any) => {
            this.ngxSpinner.hide()
            this.toast.success('Login Successfully','Success')
            this.userdata.setData(res);
            //console.log(res)
            this.router.navigateByUrl('admin/dashboard')
          },
          err => {
            this.ngxSpinner.hide()
            this.toast.error(err.error.message,'Error')
            this.reloadCurrentRoute()
          }
        )
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )    
  }

  handleLoad(){
    //console.log('ok')
  }

  handleSuccess(event:any){
    //console.log(event, this.loginForm.get('recaptcha')?.value)
  }

  siteKey:string = '6Le3m2waAAAAAC1xu4U8248Sw6tWmJwweoyMUzu7'

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
