import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-typebased-order-month-wise',
  templateUrl: './typebased-order-month-wise.component.html',
  styleUrls: ['./typebased-order-month-wise.component.scss']
})
export class TypebasedOrderMonthWiseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
