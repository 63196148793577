import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlluserService } from 'src/app/admin/services/alluser/alluser.service';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  id: any;

  isManager = false
  isAdmin = false
  isAstrologer = false
  isCustomer = false
  userData: any = ''
  customerData: any = ''
  astrologerData: any = ''

  constructor(
    private toast: ToastrService,
    private ngxSpinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private userservice: AlluserService,
    private location: Location,
    private customerService: CustomerService,
    private astrologerService: AstroServiceService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.changePasswordForm.patchValue({ userId: this.id });
    this.getSingleUser()
  }

  changePasswordForm: any = new FormGroup({
    userId: new FormControl(),
    password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
    confirmpassword: new FormControl('', [Validators.required])
  }, { validators: passwordConfirmValidator });

  get f() {
    return this.changePasswordForm.controls
  }

  getSingleUser() {
    this.userservice.getUserDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.userData = res.data
        if (res.data.is_admin) {
          this.isAdmin = true
        }
        if (res.data.is_manager) {
          this.isManager = true
        }
        if (res.data.is_astrologer) {
          this.isAstrologer = true
          this.getSingleAstrologer(res.data.email)
        }
        if (res.data.is_customer) {
          this.isCustomer = true
          this.getSingleCustomer(res.data.email)
        }
      }, err => {
        this.toast.error(err.error.message)
        this.router.navigateByUrl('/admin/userList')
      }
    )
  }

  getSingleAstrologer(email: any) {
    this.astrologerService.getAstrologerDataById({ email: email }).subscribe(
      (res: any) => {
        this.astrologerData = res.data
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  getSingleCustomer(email: any) {
    this.customerService.getCustomerDataById({ email: email }).subscribe(
      (res: any) => {
        this.customerData = res.data
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  public onUpdatePassword() {
    //console.log(this.id)
    this.ngxSpinner.show()
    this.userservice.changeCustomerPassword(this.changePasswordForm.value).subscribe(
      (res: any) => {
        this.ngxSpinner.hide()
        this.toast.success('Password Updated', 'Success')
        //this.router.navigateByUrl('admin/usersList')
        this.location.back();
      },
      err => {
        this.ngxSpinner.hide()
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }


}
const passwordConfirmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmpassword = control.get('confirmpassword');

  return password && confirmpassword && password.value != confirmpassword.value ? { passwordConfirm: true } : null;
};
