import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RolesService } from 'src/app/admin/services/roles/roles.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.scss']
})
export class EditRolesComponent implements OnInit {

  id: any = '';
  data: any = '';
  permission: any = ''
  constructor(
    private spinner: NgxSpinnerService,
    private roleService: RolesService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
  ) { }

  editRoleForm: any = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    roles_name: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    permission_name: new FormControl(''),
    _id: new FormControl('', [Validators.required]),
  })
  items = ['Manage-Customer', 'Manage-Astrologers', 'Manage-Users', 'Manage-Managers', 'Manage-Skills', 'Manage-Language', 'Manage-Report', 'Manage-Banner', 'Manage-Orders', 'Manage-Issues', 'Manage-Remedy', 'Admin-Wallet', 'Manage-Roles', 'Manage-Request', 'Manage-Customization', 'Manage-Coupon', 'Waiting-List', 'Reviews', 'Push-Notifications', 'Payout', 'Notes', 'Manage-Offers', 'Manage-Support', 'Manage-Blog', 'Manage-Festivals', 'Manage-Mall']

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.editRoleForm.patchValue({ 'userId': this.userdata.getId() })
    this.editRoleForm.patchValue({ 'added_by': this.userdata.getName() })
    this.editRoleForm.patchValue({ '_id': this.id })
    this.editRoleForm.patchValue({ 'permission_name': this.permission })
    this.getSingleRoleById()
    // for(var j=0; j<this.permission.lenght; j++) {
    //   if (this.permission[j] == "") {
    // //console.log('pop')
    //     this.permission.forEach((value:any, index:any) => {
    //       if (value == this.permission[j]) this.permission.splice(index, 1);
    //     });
    //   }
    // }

  }

  get f() {
    return this.editRoleForm.controls
  }

  onAddRoleSubmit() {
    if (this.permission.length <= 0 ) {
      this.toast.error('Please Select atleast one permission', 'Error')
      return
    } else {
      //console.log(this.permission, this.permission.join())
      //return
      // var data = ["Manage-Customer"]
      // this.editRoleForm.patchValue({permission_name:"Manage-Customer"})
      //var data = this.editRoleForm.get('permission_name').value.map(function (data:any) {return data.value});
      //this.editRoleForm.patchValue({'permission_name':data.join()})
      this.editRoleForm.patchValue({'permission_name':this.permission.join()})
      //console.log(this.editRoleForm.get('permission_name').value)
      this.spinner.show()
      this.roleService.updateRoles(this.editRoleForm.value).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Role updated!', 'Sucess')
          this.route.navigateByUrl('/admin/roles')
        }, err => {
          this.spinner.hide()
          this.toast.error(err.error.message, 'Error')
        }
      )
    }

  }

  getSingleRoleById() {
    this.roleService.singleRolesById({ userId: this.userdata.getId(), _id: this.id }).subscribe(
      (res: any) => {
        this.data = res.data
        this.permission = res.data.permissions
        this.editRoleForm.patchValue({ 'roles_name': res.data.roles_name })
        //console.log(this.permission)
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  storePermissions(evnt: any) {
    if (evnt.target.checked == true) {
      //console.log('push')
      this.permission.push(evnt.target.value)
    }
    if (evnt.target.checked == false) {
      //console.log('pop')
      this.permission.forEach((value: any, index: any) => {
        if (value == evnt.target.value) this.permission.splice(index, 1);
      });
    }
    this.editRoleForm.patchValue({ 'permission_name': this.permission })
  }

  checkValue(data: any) {
    // this.permissions.push(this.permission)
    for (var i = 0; i < this.permission.length; i++) {
      if (this.permission[i] == data) {
        this.data.checked = true
        return this.data.checked
      }
    }
  }

}
