import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-astrologer-support-list',
  templateUrl: './astrologer-support-list.component.html',
  styleUrls: ['./astrologer-support-list.component.scss']
})
export class AstrologerSupportListComponent implements OnInit {

  constructor(
    private issueService: IssueServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private _route:Router
  ) { } 
  title = 'angular-datatables';

  issueList = [];
  id: any = '';
  temp: any = [];
  getIssueData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getAllAstrologerSupport()
    this.issueList = this.getIssueData;
  }

  /* ***********
  list All Astrologer Support
  *********** */

  getAllAstrologerSupport() {
    this.spinner.show()
    this.issueService.getAllSupport({'userId':this.userdata.getId(),is_astrologer:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList
        this.getIssueData =this.issueList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
updateFilter(event: any) {
  //console.log(this.temp)
  const val = event.target.value.toLowerCase();

  const temp = this.temp.filter(function (d: any) {
    return d.astroId?.name.toLowerCase().indexOf(val) !== -1 ||
    d.astroId?.displayname.toLowerCase().indexOf(val) !== -1 ||
    d.astroId?.astroAutoId.toString().indexOf(val) !== -1 ||
        d.supportId.toString().indexOf(val) !== -1 || !val
  });

  // update the rows
  this.issueList = temp;
  // Whenever the filter changes, always go back to the first page
  this.myFilterTable.offset = 0;
}

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue(){
    this.spinner.show()
    this.issueService.getAllSupport({'userId':this.userdata.getId(),'is_issue_solved':false,is_astrologer:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Solved Issue
   */
  solvedIssue(){
    this.spinner.show()
    this.issueService.getAllSupport({is_astrologer:true,'userId':this.userdata.getId(),'is_issue_solved':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }
  messageChatClick(id:any,isSystem:Boolean){
    if(isSystem){
      this._route.navigate(['/admin/supports/message',id])
    }else{
      this._route.navigate(['/admin/supports/astrologer/reply',id])
    }
  }

}
