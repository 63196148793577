<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <!-- <div class="page-header">
        <h4 class="page-title">Add Astrologer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="admin/customersList">Customer</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Astrologer</li>
        </ol>
    </div> -->
    <!-- PAGE-HEADER END -->

    <!-- ROW-1 OPEN -->
    <form [formGroup]="addCustomerForm" (submit)="onAddCustomerSubmit()" >
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Customer</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputname">Full Name *</label>
                                    <input type="text" class="form-control" form
                                        placeholder="Full Name" formControlName="name">
                                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputname1">Password *</label>
                                    <input type="password" class="form-control"
                                        placeholder="Password" formControlName="password">
                                    <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger mt-1">
                                        Required. Password should contains minimum 1 special symbol, 1
                                        uppercase letter, 1 lowercase letter, 1 number and length not less than 8
                                        characters and should not contain space.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email *</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                        placeholder="Email address" formControlName="email">
                                    <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="categoryname">Country Code</label>
                                    <ng-select [searchFn]="customSearchFn" formControlName="country_code"
                                      class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
                                      bindValue="phone_code" [ngModelOptions]="{standalone: true}"  (change) = "getCountry($event)">
                                      <ng-template ng-option-tmp let-item="item">
                                        <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                        item.name
                                        }}&nbsp;&nbsp;(+{{ item.phone_code }})
                                      </ng-template>
                                    </ng-select>
                                  </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputnumber">Contact Number *</label>
                                    <input type="number" class="form-control" id="exampleInputnumber"
                                        placeholder="Phone number" formControlName="phone" required>
                                    <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="alert alert-danger mt-1">
                                        Required, 10 digits are allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="col-sm-4 btn btn-success mt-1" [disabled]="addCustomerForm.invalid">Save</button>
                        <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                    </div>
                </div>

            </div>

        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->
