import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getCustomerUrl() {
  return "https://customer.astroriver.com/#/"
}

export function getBaseUrl() {
  // return "http://192.168.1.12:8085/admin"
  return "https://apiv3.astroriver.com/admin"
}

export function getBaseImageUrl() {
  // return "http://192.168.1.12:8085/"
  return "https://apiv3.astroriver.com/"
}

export function getOpenBaseUrl() {
  // return "http://192.168.1.12:8085/astro"
  return "https://apiv3.astroriver.com/astro"
}

export function getOpenApiBaseUrl() {
  //  return "http://192.168.1.12:8085/api"
  return "https://apiv3.astroriver.com/astro"
}

const providers = [
  { provide: 'CUSTOMER_URL', useFactory: getCustomerUrl, deps: [] },
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'OPEN_BASE_URL', useFactory: getOpenBaseUrl, deps: [] },
  { provide: 'BASE_IMAGE_URL', useFactory: getBaseImageUrl, deps: [] },
  { provide: 'BASE_API_URL', useFactory: getOpenApiBaseUrl, deps: [] },
]

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
