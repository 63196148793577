import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-work-history',
  templateUrl: './work-history.component.html',
  styleUrls: ['./work-history.component.scss'],
})
export class WorkHistoryComponent implements OnInit {
  BaseUrl = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private astrService: AstroServiceService,
    private usersevice: UserdataService,
    private toast: ToastrService,
    private route: Router,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  updateHistoryForm: any = new FormGroup({
    report_taken: new FormControl(0,[Validators.required]),
    query_taken: new FormControl(0,[Validators.required]),
    call_taken: new FormControl(0,[Validators.required]),
    call_duration: new FormControl(0,[Validators.required]),
    chat_taken: new FormControl(0,[Validators.required]),
    chat_duration: new FormControl(0,[Validators.required]),
    astroId: new FormControl('',[Validators.required]),
    addedBy: new FormControl('',[Validators.required]),
    addedId: new FormControl('',[Validators.required])
  });

  logs = new Array()
  astroId = '';
  isEditing=false
  ngOnInit(): void {
    this.astroId = this.router.snapshot.paramMap.get('id') || '';
    this.getAstroDetail()
  }
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  get f(){
    return this.updateHistoryForm.controls
  }
  getAstroDetail(){
    this.spinner.show()
    this.astrService.getAstrologerDataById({_id:this.astroId}).subscribe(
      (res:any)=>{
        this.logs = res.data.counters_logs.reverse()
        this.updateHistoryForm.patchValue({ report_taken: res.data.total_report_taken });
        this.updateHistoryForm.patchValue({ query_taken:res.data.total_query_taken });
        this.updateHistoryForm.patchValue({ call_taken:res.data.total_call_taken });
        this.updateHistoryForm.patchValue({ call_duration:(Number(res.data.total_call_duration)/60).toFixed(2) });
        this.updateHistoryForm.patchValue({ chat_duration:(Number(res.data.total_chat_duration)/60).toFixed(2) });
        this.updateHistoryForm.patchValue({ chat_taken:res.data.total_chat_taken });
        this.spinner.hide()
      },err=>{
        this.toast.error(err)
        this.spinner.hide()
      }
    )
  }
  updateHistory() {
    this.spinner.show()
    var call_duration = this.updateHistoryForm.get('call_duration').value
    var chat_duration = this.updateHistoryForm.get('chat_duration').value
    this.updateHistoryForm.patchValue({ call_duration: Number(call_duration)*60 });
    this.updateHistoryForm.patchValue({ chat_duration: Number(chat_duration)*60 });
    this.updateHistoryForm.patchValue({ astroId: this.astroId });
    this.updateHistoryForm.patchValue({ addedBy: this.usersevice.getName() });
    this.updateHistoryForm.patchValue({ addedId: this.usersevice.getId() });
    this.astrService.updateHistory(this.updateHistoryForm.value).subscribe((res:any)=>{
      if(res.success){
        this.toast.success(res.message)
        this.getAstroDetail()
      }else{
        this.toast.error(res.message)
      }
      this.spinner.hide()
    },err=>{
      this.toast.error(err)
      this.spinner.hide()
    })
  }
  getType(type:any){
    switch(type){
      case 1:
        return 'Call Taken'
        case 2:
        return 'Chat Taken'
        case 3:
        return 'Query Taken'
        case 4:
        return 'Report Taken'
        case 5:
        return 'Call Duration'
        case 6:
        return 'Chat Duration'
        default:
          return 'Unknown'
    }
  }
}
