import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-store-orders',
  templateUrl: './list-store-orders.component.html',
  styleUrls: ['./list-store-orders.component.scss']
})
export class ListStoreOrdersComponent implements OnInit {

  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  storeList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getStoreOrderList()
  }

  /* ***********
  list Store Orders
  *********** */

  getStoreOrderList() {
    this.spinner.show()
    this.order.getStoreOrderList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.storeList = res.data;
        // console.log(this.storeList)
        this.temp = this.storeList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * List Store order according to status
   */
  getStoreOrderByStatus(event: any) {
    var data;
    data = { orderStatus: event }
    if (event == 7) {
      data = { orderStatus: 7, isOrderCompleted: false }
    }
    if (event == 8) {
      data = { isOrderCompleted: true }
    }
    if (event == 6) {
      data = { orderStatus: 6, refundStatus: 1 }
    }
    if (event == 9) {
      data = { orderStatus: 6, refundStatus: 2 }
    }
    if (event == 10) {
      data = { orderStatus: 6, refundStatus: 3 }
    }
    this.order.getStoreOrderList(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.storeList = res.data;
        //console.log(this.storeList)
        this.temp = this.storeList
      },
      (err) => {
        this.spinner.hide()
        // console.log(err);
      }
    );
  }
  /**
   * Search Order
   */
   searchOrders(data:any) {
    this.spinner.show()
    this.order.getStoreOrderList(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.storeList = res.data;
        //console.log(this.storeList)
        this.temp = this.storeList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }
  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.orderId.toString().indexOf(val) !== -1  || !val
    });

    // update the rows
    this.storeList = temp;
    // console.log(this.storeList)
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
