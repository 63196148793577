import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-chatorders',
  templateUrl: './list-chatorders.component.html',
  styleUrls: ['./list-chatorders.component.scss']
})
export class ListChatordersComponent implements OnInit {

  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  chatList = [];
  id: any = '';
  temp: any = [];
  getChatData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getChatOrderList()
    this.chatList = this.getChatData;
  }

  /* ***********
  list Chat Orders
  *********** */

  getChatOrderList() {
    this.spinner.show()
    this.order.getChatOrderList({'is_promotional_chat':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        // console.log(this.chatList)
        this.temp = this.chatList;
        this.getChatData = this.chatList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.ochatId.toString().indexOf(val) !== -1 ||
        d.astroId?.name.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.chatList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  public convertSecondstoTime(val:any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString = hours.toString().padStart(2, '0')
                + ':' + minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0');
    return timeString
  }

  noCustomerReview(){
    this.spinner.show()
    this.order.getChatOrderList({'userId':this.userdata.getId(),'is_order_reviewed':false,'is_order_completed':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        //console.log(this.chatList)
        this.temp = this.chatList;
        this.getChatData = this.chatList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  noAstrologerReviewReply(){
    this.spinner.show()
    this.order.getChatOrderList({'userId':this.userdata.getId(),'is_astro_replied_review':false,'is_order_completed':true,'is_order_reviewed':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        //console.log(this.chatList)
        this.temp = this.chatList;
        this.getChatData = this.chatList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  promotionalChat(){
    this.spinner.show()
    this.order.getChatOrderList({'userId':this.userdata.getId(),'is_promotional_chat':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        //console.log(this.chatList)
        this.temp = this.chatList;
        this.getChatData = this.chatList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  completedChatOrders(){
    this.spinner.show()
    this.order.getChatOrderList({'userId':this.userdata.getId(),'is_order_completed':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        //console.log(this.chatList)
        this.temp = this.chatList;
        this.getChatData = this.chatList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  OnGoingChatOrders(){
    this.spinner.show()
    this.order.getChatOrderList({'userId':this.userdata.getId(),is_astrologer_accept:true ,is_astrologer_acceptance_used :true ,is_customer_accept:true, is_customer_acceptance_used:true,is_order_completed:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        //console.log(this.chatList)
        this.temp = this.chatList;
        this.getChatData = this.chatList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
