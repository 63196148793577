import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { WalletRechargeService } from 'src/app/admin/services/wallet-recharge/wallet-recharge.service';

@Component({
  selector: 'app-edit-recharge',
  templateUrl: './edit-recharge.component.html',
  styleUrls: ['./edit-recharge.component.scss']
})
export class EditRechargeComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private rechargeService: WalletRechargeService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
  ) { }

  data: any = '';
  id: any = '';


  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ _id: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    amount: new FormControl('', [Validators.required,Validators.pattern("^[0-9]{1,15}$")]),
    userId: new FormControl('', Validators.required),
    percentge: new FormControl('', [Validators.required,Validators.pattern("^[0-9]{1,4}$")]),
    walletamount_description: new FormControl('', Validators.required),
  });


  getDataById() {
    this.spinner.show()
    this.rechargeService.detailWalletRecharge({ _id: this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.formGroup.patchValue({ amount: res.data.amount });
        this.formGroup.patchValue({ percentge: res.data.percentge });
        this.formGroup.patchValue({ walletamount_description: res.data.walletamount_description });
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Update Recharge
 *********** */

  public updateRecharge() {
    // if(this.formGroup.get('remedy_link')?.value=='' && this.formGroup.get('astroId')?.value==''){
    //   this.toast.error('Select Remedy Link or Suggested Astrologer')
    //   return
    // }
    this.spinner.show();
    this.rechargeService.updateWalletRecharge(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success');
        this.route.navigateByUrl('/admin/recharge/all')
        //this.route.navigateByUrl('/astrologer/remedy')
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
      })
  }

}
