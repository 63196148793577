import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-common-single-customer-page',
  templateUrl: './common-single-customer-page.component.html',
  styleUrls: ['./common-single-customer-page.component.scss']
})
export class CommonSingleCustomerPageComponent implements OnInit {

  id:any = ''
  data: any;
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private _location:Location,
  ) { }
  goBack(){
    this._location.back()
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('data')
    this.getDataById()
  }

  getDataById() {
    this.spinner.show()
    this.customerService.getCustomerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

}
