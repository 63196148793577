import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';

@Component({
  selector: 'app-show-signle-customer-detail',
  templateUrl: './show-signle-customer-detail.component.html',
  styleUrls: ['./show-signle-customer-detail.component.scss']
})
export class ShowSignleCustomerDetailComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private customerService: CustomerService
  ) { }

  data: any = '';
  id: any = '';

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.customerService.getIndividualCustomerDataById({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log('single',this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

}
