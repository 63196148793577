import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ProductMallService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }

  public addMallProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addproduct", data,{ headers: header_object});
  }

  public listMallProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/adminShow", data,{ headers: header_object});
  }

  public updateMallProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateproduct", data,{ headers: header_object});
  }

  public detailMallProduct(data: any){//_id
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchproductbyid", data,{ headers: header_object});
  }

  public deleteMallProductImage(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deleteProductImage", data,{ headers: header_object});
  }

  public checkMallProductUnique(data: any){//slug
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchproductbyslug", data,{ headers: header_object});
  }

  /* Associative astrologer */

  public deleteAssociativeAstrologerFromProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deleteastrologerfromproduct", data,{ headers: header_object});
  }

  public addAssociativeAstrologersInProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addastrologerinproduct", data,{ headers: header_object});
  }

  public updateAstroProductPrice(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateastroproductprice", data,{ headers: header_object});
  }

  /** Addons */
  public deleteAssociativeAddonsFromProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deleteaddon", data,{ headers: header_object});
  }

  public addNewProductAddons(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/productaddons", data,{ headers: header_object});
  }

  public updateProductAddons(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateproductaddon", data,{ headers: header_object});
  }

  /** Product requests */

  public getProductRequests(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getProductRequests", data,{ headers: header_object});
  }

  public approveRejectRequest(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/approveRequest", data,{ headers: header_object});
  }

  public singleProductRequest(id: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singleproductrequest", id,{ headers: header_object});
  }

  public setFeaturedProduct(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/setFeaturedProduct", data,{ headers: header_object});
  }

}
