import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-product-requests',
  templateUrl: './list-product-requests.component.html',
  styleUrls: ['./list-product-requests.component.scss']
})
export class ListProductRequestsComponent implements OnInit {

  constructor(
    private productMallService: ProductMallService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private route: Router
  ) { }
  title = 'angular-datatables';

  dataList = [];
  data: any = '';
  id: any = '';
  userId: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];
  action: boolean = true;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getProductList()
    this.formGroup.patchValue({ "userId": this.userdata.getId() })
    // console.log(this.userdata.getName())
    this.formGroup.patchValue({ "updateBy": this.userdata.getName() })
  }

  /*
  list products
   */

  getProductList() {
    this.action = true;
    this.spinner.show()
    this.productMallService.getProductRequests({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        //console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        //console.log(err);
      }
    );
  }

   /*
  list approved product requests
   */
  getApprovedProductRequestList() {
  this.action = false;
    this.spinner.show()
    this.productMallService.getProductRequests({is_used:true, isApproved:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        //console.log(err);
      }
    );
  }

  /*
  list Rejected product requests
   */

  getRejectedProductRequestList() {
    this.action = false;
    this.spinner.show()
    this.productMallService.getProductRequests({is_used:true, isApproved:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        //console.log(err);
      }
    );
  }

  /*
  list pending product requests
   */

  getPendingProductRequestList() {
    this.action = true;
    this.spinner.show()
    this.productMallService.getProductRequests({is_used:false, isApproved:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
        this.toast.error(err.message, "Error")
      }
    );
  }

  /**
 ******************************************
 * Approve/Reject product Request
 *******************************************
 */

  /** form */
  formGroup: FormGroup = new FormGroup({
    isApproved: new FormControl(true),
    admin_comment: new FormControl('', Validators.required),
    userId: new FormControl(''),
    _id: new FormControl(''),
    updateBy: new FormControl(''),
    astroprice: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    finalprice: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")])
  });

  /* ***********
Modal Popup functions
*********** */
  closeResult = '';
  open(content: any, id: any) {
    this.formGroup.patchValue({ '_id': id })
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /* ***********
  Modal Popup functions
  *********** */

  OnIsApproveHandler(event: any) {
    //console.log(event.target.value)
    if (event.target.value == 'true') {
      this.formGroup.patchValue({ 'isApproved': true })
      //console.log('yyy',this.formGroup.get('isApproved')?.value)
    }
    if (event.target.value == 'false') {
      this.formGroup.patchValue({ 'isApproved': false })
    }
  }

  /** validation check */
  get f() {
    return this.formGroup.controls
  }

  /** Update approve reject product request function */
  public approveRejectAstrologer() {
    //console.log(this.formGroup.value)
    this.spinner.show()
    this.productMallService.approveRejectRequest(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'success')
        this.modalService.dismissAll();
        this.route.navigateByUrl('/admin/mall-product/list-requests')
        this.getProductList();
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'error')
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.productId?.productname.toLowerCase().indexOf(val) !== -1 ||
        d.price.toString().indexOf(val) !== -1 ||
        d?.astroId?.name?.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }


   /**
 ******************************************
 * Show single product request model popup
 *******************************************
 */
  closeResultShow = '';
  productId: any;
  openShow(content: any, id: any) {
    this.productId = id;
    this.getDataById()
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg'})
    .result.then(
      (result) => {
        this.closeResultShow = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResultShow = `Dismissed ${this.getDismissReasonShow(reason)}`;
      }
    );
  }

  private getDismissReasonShow(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getDataById() {
    this.spinner.show()
    this.productMallService.singleProductRequest({ _id: this.productId }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
      }
    )
  }
}


