<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Festival</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/festival/list']">Festival List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateFestival()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="festivalName">Name *</label>
                                        <input type="text" class="form-control" placeholder="Festival Name"
                                            formControlName="festivalName">
                                        <small
                                            *ngIf="f.festivalName.invalid && (f.festivalName.dirty || f.festivalName.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="festivalImage">Image * (Image should be 960 X 540)</label>
                                        <input type="file" class="form-control" placeholder="Festival Image"
                                            (change)="onBannerUpload($event)">
                                        <small
                                            *ngIf="f.festivalImage.invalid && (f.festivalImage.dirty || f.festivalImage.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                        <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                                            {{imageError}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="metaDescription">Meta Description</label>
                                        <textarea class="form-control" formControlName="metaDescription"></textarea>
                                        <small
                                            *ngIf="f.metaDescription.invalid && (f.metaDescription.dirty || f.metaDescription.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="metaTags">Meta Tags</label>
                                        <input type="text" class="form-control" placeholder="Meta Tags"
                                            formControlName="metaTags">
                                        <small *ngIf="f.metaTags.invalid && (f.metaTags.dirty || f.metaTags.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="metaKeywords">Meta Keywords</label>
                                        <input type="text" class="form-control" placeholder="Meta Keywords"
                                            formControlName="metaKeywords">
                                        <small
                                            *ngIf="f.metaKeywords.invalid && (f.metaKeywords.dirty || f.metaKeywords.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <editor apiKey="kb1shdcv4s1c8mg7s4jo6ebmrmwqkkkx5hfwwpofj87i778i" [init]="{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      'advlist autolink lists print preview',
                                      'searchreplace code',
                                      'insertdatetime table paste code help wordcount'
                                    ],
                                    toolbar:
                                      'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help'
                                  }" formControlName="festivalDescription" style="width:100% !important;"></editor>
                                  <small *ngIf="f.festivalDescription.invalid && (f.festivalDescription.dirty || f.festivalDescription.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required.
                                    </small>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <button type="submit" class="col-sm-4 btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->