<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <!-- <div class="page-header">
        <h4 class="page-title">Add Astrologer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="admin/customersList">Customer</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Astrologer</li>
        </ol>
    </div> -->
    <!-- PAGE-HEADER END -->

    <!-- ROW-1 OPEN -->
    <form [formGroup]="CreditDebitWalletForm" (submit)="onDebitSubmit()" >
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Debit Wallet Amount</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputname">Amount</label>
                                    <input type="text" class="form-control" form
                                        placeholder="Amount" formControlName="amount">
                                    <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>    
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputname1">Transaction ID</label>
                                    <input type="text" class="form-control" 
                                        placeholder="Transaction ID" formControlName="txnId">
                                    <div *ngIf="f.txnId.invalid && (f.txnId.dirty || f.txnId.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>   
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputname">Wallet Comments</label>
                                    <input type="text" class="form-control" form
                                        placeholder="Wallet Comments" formControlName="wallet_comments">
                                    <div *ngIf="f.wallet_comments.invalid && (f.wallet_comments.dirty || f.wallet_comments.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>    
                                </div>
                            </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="col-sm-4 btn btn-success mt-1" [disabled]="CreditDebitWalletForm.invalid">Save</button>
                        <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                    </div>
                </div>
               
            </div>

        </div>
    </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->