import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-astrologer-notice-board',
  templateUrl: './add-astrologer-notice-board.component.html',
  styleUrls: ['./add-astrologer-notice-board.component.scss']
})
export class AddAstrologerNoticeBoardComponent implements OnInit {

  constructor(
    private customizeService: CustomizeServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private route: Router
  ) { }

  noticeBoardForm: any = new FormGroup({
    customization_name: new FormControl('Astrologer Notice Board', Validators.required),
    customization_type: new FormControl(6, Validators.required),
    customization_value: new FormControl('', Validators.required),
    addedBy: new FormControl(''),
    userId: new FormControl('')
  })

  ngOnInit(): void {
    this.noticeBoardForm.patchValue({userId:this.userdata.getId()})
    this.noticeBoardForm.patchValue({addedBy:this.userdata.getName()})
  }

  get f() {
    return this.noticeBoardForm.controls
  }

  addNotice(){
    this.spinner.show()
    this.customizeService.addCustomizationArray(this.noticeBoardForm.value).subscribe(
      (res:any)=>{
        this.spinner.hide()
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/push-notification/notice-board')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
