
<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <!-- <div class="page-header">
        <h4 class="page-title">Languages</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="admin/languages">Languages</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div> -->
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- <div class="user-wrap">
                        <button type="submit()" class="btn btn-primary mt-1 mb-2"><i class="fa fa-rss"></i> Add Language</button>
                    </div> -->
                    <div>
                        <form [formGroup]="updateLanguageForm" (submit)="onUpdateLanguage()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="language">Language</label>
                                    <input type="text" class="form-control" name="name" formControlName="name"
                                        placeholder="New Language" required>
                                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                                <div class="user-wrap d-flex justify-content-center">
                                    <button type="submit()" class="btn-form-custom col-4 btn btn-success mt-1 mb-2" [disabled]="!updateLanguageForm.valid"> Update Language</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
