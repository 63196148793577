import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FestivalServiceService } from 'src/app/admin/services/festival/festival-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-yearly-festival',
  templateUrl: './edit-yearly-festival.component.html',
  styleUrls: ['./edit-yearly-festival.component.scss']
})
export class EditYearlyFestivalComponent implements OnInit {

  festivalList:any = []
  dataList:any = {}
  id:any =''
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private festivalService: FestivalServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) { }

  getSingleYearlyFestivalList() {
    this.spinner.show()
    this.festivalService.detailYearlyFestival({_id:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.formGroup.patchValue({_id:this.dataList._id})
        this.formGroup.patchValue({festivalId:this.dataList.festivalId._id})
        this.formGroup.patchValue({festivalDate:moment(this.dataList.festivalDate,'YYYY-MM-DD[T00:00:00.000Z]').format('YYYY-MM-DD')})
        this.formGroup.patchValue({updatedId:this.userdata.getId()})
      },
      (err) => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/festival/list')
      }
    );
  }

  getFestivalList(){
    this.festivalService.listFestival({}).subscribe(
      (res:any)=>{
        this.festivalList = res.data
      }
    )
  }
  
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    year: new FormControl(''),
    festivalId: new FormControl('', Validators.required),
    festivalDate: new FormControl('',Validators.required),
    updatedId: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getSingleYearlyFestivalList()
    this.getFestivalList()
  }

  get f() {
    return this.formGroup.controls
  }

   updateFestival(){
    var date = this.formGroup.get('festivalDate')?.value
    this.formGroup.patchValue({year: moment(date,'YYYY-MM-DD').year()})
    this.festivalService.updateYearlyFestival(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/festival/list-yearly')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
