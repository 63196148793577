import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { BannerService } from 'src/app/admin/services/banner/banner.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import * as _ from 'lodash';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {

  addBannerForm: any = new FormGroup({
    customization_name: new FormControl('Banner/Slider', [Validators.required]),
    customization_type: new FormControl(1, [Validators.required]),
    banner_image: new FormControl(''),
    mobile_banner_image: new FormControl(''),
    userId: new FormControl('', [Validators.required]),
    addedBy: new FormControl('', [Validators.required]),
    is_desktop_banner: new FormControl(true, [Validators.required]),
    is_mobile_banner: new FormControl(false, [Validators.required]),
    is_astro: new FormControl(''),
    astroId: new FormControl(''),
    is_link: new FormControl(''),
    link_value: new FormControl(''),
    is_astro_banner: new FormControl(true),
    is_product: new FormControl(false),
    productId: new FormControl(''),
    bannerType: new FormControl(''),
  })
  isAstroLink = 1
  isLinkSelected = false
  isAstroLinkBanner: Boolean | any;
  isOtherLinkBanner: Boolean | any;
  isWebBanner:boolean = true

  isProductLinkBanner:Boolean | any;
  isProductLink = 1
  constructor(
    private spinner: NgxSpinnerService,
    private bannerService: BannerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private astro: AstroServiceService,
    private route: Router,
    private productMallService: ProductMallService
  ) { }

  ngOnInit(): void {
    this.addBannerForm.patchValue({ addedBy: this.userdata.getName() });
    this.addBannerForm.patchValue({ userId: this.userdata.getId() })
    this.getAstrologerList()
  }

  /**
   * Web Banner Image
   */
  imageError: string = ''
  imageSrc = ''
  public onBannerUpload(event: any){
    this.imageError = ''
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 728;
            const max_width = 1366;
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const [file] = event.target.files;

      reader.readAsDataURL(file);    

      reader.onload = (e:any) => {
        this.imageSrc = reader.result as string;    
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs:any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
              this.imageError =
                  'Banner dimentions allowed ' +
                  max_width +
                  ' X ' +
                  max_height +
                  'px';
              return;
          } else {
            this.addBannerForm.patchValue({
              banner_image: event.target.files[0]
            })
          }
        }
      };
    }
  }

  /**
   * App Banner Image
   */
  appImageError: string = ''
  appImageSrc = ''
  public onAppBannerUpload(event: any){
    this.appImageError = ''
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 280;
            const max_width = 540;
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.appImageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = (e:any) => {
        this.appImageSrc = reader.result as string;    
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs:any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
              this.appImageError =
                  'Banner dimentions allowed ' +
                  max_width +
                  ' X ' +
                  max_height +
                  'px';
              return;
          } else {
            this.addBannerForm.patchValue({
              mobile_banner_image: event.target.files[0]
            })
          }
        }
      };
    }
  }

  commonBannerImageUpload(event:any){
    if(this.isWebBanner){
      this.onBannerUpload(event)
    }
    if(!this.isWebBanner){
      this.onAppBannerUpload(event)
    }
  }

  get f() {
    return this.addBannerForm.controls
  }

  astroList = []
  getAstrologerList() {
    this.astro.getAstrologerList({}).subscribe(
      (res: any) => {
        this.astroList = res.data
      }
    )
  }

  bannerLink(event: any) {
    if (event.target.value == 'astro') {
      this.isLinkSelected = true
      this.isAstroLink = 1
      this.isAstroLinkBanner = true
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:''})
      // this.addBannerForm.patchValue({'is_astro':true})
      // this.addBannerForm.patchValue({'is_link':false})
    }
    if (event.target.value == 'other') {
      this.isLinkSelected = true
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = true
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:''})
      // this.addBannerForm.patchValue({'is_astro':false})
      // this.addBannerForm.patchValue({'is_link':true})
    }
    if (event.target.value == 'product') {
      this.getProductList()
      this.isLinkSelected = true
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = true
      this.isProductLink = 1
      this.addBannerForm.patchValue({bannerType:''})
    }
    if (event.target.value == 'call') {
      this.isLinkSelected = false
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:4})
    }
    if (event.target.value == 'chat') {
      this.isLinkSelected = false
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:5})
    }
    if (event.target.value == 'query') {
      this.isLinkSelected = false
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:6})
    }
    if (event.target.value == 'report') {
      this.isLinkSelected = false
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:7})
    }
    if (event.target.value == 'wallet') {
      this.isLinkSelected = false
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:8})
    }
    if (event.target.value == 'recharge') {
      this.isLinkSelected = false
      this.isAstroLink = 0
      this.isAstroLinkBanner = false
      this.isOtherLinkBanner = false
      this.isProductLinkBanner = false
      this.isProductLink = 0
      this.addBannerForm.patchValue({bannerType:9})
    }
  }

  bannerType(event: any) {
    //console.log(event.target.value)
    if (event.target.value == 'true') {
      this.addBannerForm.patchValue({ 'is_astro_banner': true })
    }
    if (event.target.value == 'false') {
      this.addBannerForm.patchValue({ 'is_astro_banner': false })
    }
    //console.log(this.addBannerForm.get("is_astro_banner").value)
  }

  bannerImageType(event: any) {
    if (event.target.value == 'true') {
      this.isWebBanner = true
      this.addBannerForm.patchValue({ 'is_desktop_banner': true })
      this.addBannerForm.patchValue({ 'is_mobile_banner': false })
    }
    if (event.target.value == 'false') {
      this.isWebBanner = false
      this.addBannerForm.patchValue({ 'is_mobile_banner': true })
      this.addBannerForm.patchValue({ 'is_desktop_banner': false })
    }
  }
  public onAddBannerSubmit() {
    if (this.addBannerForm.get('is_astro').value === true) {
      if (this.addBannerForm.get('astroId').value == '') {
        this.toast.error('Select Astrologer', 'error')
        return
      }
    }
    if (this.addBannerForm.get('is_astro').value === false) {
      if (this.addBannerForm.get('link_value').value == '') {
        this.toast.error('Enter Banner Link', 'error')
        return
      }
    }
    this.spinner.show()
    const data = new FormData()
    data.append("customization_name", this.addBannerForm.get("customization_name").value)
    data.append("customization_type", this.addBannerForm.get("customization_type").value)
    data.append("userId", this.addBannerForm.get("userId").value)
    data.append("addedBy", this.addBannerForm.get("addedBy").value)
    data.append("is_astro", this.isAstroLinkBanner)
    if(this.addBannerForm.get("astroId").value == (undefined || null || '')){
      data.append("astroId", 'undefined')
    }else{
      data.append("astroId", this.addBannerForm.get("astroId").value)
    }
    data.append("is_link", this.isOtherLinkBanner)
    data.append("link_value", this.addBannerForm.get("link_value").value)
    data.append("is_astro_banner", this.addBannerForm.get("is_astro_banner").value)
    data.append("is_mobile_banner", this.addBannerForm.get("is_mobile_banner").value)
    data.append("is_desktop_banner", this.addBannerForm.get("is_desktop_banner").value)
    data.append("banner_image", this.addBannerForm.get("banner_image").value)
    data.append("bannerType", this.addBannerForm.get("bannerType").value)
    data.append("mobile_banner_image", this.addBannerForm.get("mobile_banner_image").value)
    data.append("is_product", this.isProductLinkBanner)
    if(this.addBannerForm.get("productId").value == (undefined || null || '')){
      data.append("productId", 'undefined')
    }else{
      data.append("productId", this.addBannerForm.get("productId").value)
    }
    // console.log(data,this.addBannerForm.value)
    // return
    this.bannerService.addNewBanner(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success('Banner added!', 'Sucess')
        this.reloadCurrentRoute()
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error')
        //console.log(err)
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  /*
  list products
   */
  dataList:any
  getProductList() {
    this.spinner.show()
    this.productMallService.listMallProduct({isCreatedByAstroRemedy:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
