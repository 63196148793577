import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { SubcategoryMallService } from 'src/app/admin/services/subcategory-mall/subcategory-mall.service';

@Component({
  selector: 'app-detail-mall-subcategory',
  templateUrl: './detail-mall-subcategory.component.html',
  styleUrls: ['./detail-mall-subcategory.component.scss']
})
export class DetailMallSubcategoryComponent implements OnInit {

  BaseUrl: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private subcategoryMallService: SubcategoryMallService,
    private userdata : UserdataService,
    private route: Router,
    private toast : ToastrService,private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.subcategoryMallService.detailMallSubcategory({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-subcategory/list')
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

}
