import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.scss']
})
export class ListOrdersComponent implements OnInit {
  
  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService
  ) { } 
  title = 'angular-datatables';

  orderList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getAllOrderList()
    this.getCustomerList()
  }

  /* ***********
  list All Orders
  *********** */

  getAllOrderList() {
    this.spinner.show()
    this.order.getAllOrderList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderList = res.data;
        //console.log(this.orderList)
        this.temp = this.orderList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 || !val ||
        d.oserviceId.toString().indexOf(val) !== -1 || !val ||
        d.astroId?.name.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.orderList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  customerList:any = []
  getCustomerList(){
    this.customerService.getCustomersList({}).subscribe(
      (res:any) =>{
        this.customerList = res.data
      }
    )
  }

  getOrderByCustomerId(event:any){
    //console.log(event)
    if(event == ""){
      this.getAllOrderList()
    }else{
      this.spinner.show()
      this.order.getAllOrderList({customerId:event}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.orderList = res.data;
          //console.log(this.orderList)
          this.temp = this.orderList;
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
        }
      );
    }
  }
  
  searchOrders(data:any) {
    this.spinner.show()
    this.order.getAllOrderList(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderList = res.data;
        //console.log(this.orderList)
        this.temp = this.orderList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
