import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueServiceService } from '../../services/issues/issue-service.service';
import { UserdataService } from '../../services/shared/userdata/userdata.service';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AstroServiceService } from '../../services/astroservice/astro-service.service';
@Component({
  selector: 'app-issue-message',
  templateUrl: './issue-message.component.html',
  styleUrls: ['./issue-message.component.scss'],
})
export class IssueMessageComponent implements OnInit {
  constructor(
    private userdata: UserdataService,
    private _route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private issueService: IssueServiceService,
    private toast: ToastrService,
    private _location: Location,
    private modalService: NgbModal,
    private _router: Router,
    private astroService: AstroServiceService
  ) {}
  id: any;
  chatList = new Array();
  userId: any;
  type: any;
  data: any;
  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.type = this._route.snapshot.paramMap.get('type') == 'astro';
    this.userId = this.userdata.getId();
    this.formGroup.patchValue({ is_reply_by_system: true });
    this.formGroup.patchValue({ userId: this.userId });
    this.formGroup.patchValue({ addedBy: this.userdata.getName() });
    this.formGroup.patchValue({ _id: this.id });
    this.getSupportMessages();
  }
  getSupportMessages() {
    this.issueService
      .getDetailCustomerIssues({
        _id: this.id,
        userId: this.userId,
        isAstro: this.type,
      })
      .subscribe(
        (res: any) => {
          this.data = res.data;
          this.chatList = res.data.replylogs;
        },
        (err) => {
          //console.log(err);
          this.toast.error('Server Issue');
        }
      );
  }
  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', Validators.required),
    _id: new FormControl('', Validators.required),
    reply_by_system: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
    is_issue_solved: new FormControl(false),
    is_reply_by_system: new FormControl(true),
  });

  get f() {
    return this.formGroup.controls;
  }

  sendMsg() {
    this.spinner.show();
    const data = this.formGroup.value;
    this.issueService.replyCustomerIssues(data, this.type).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status == 200 && res.success) {
          if (data.is_issue_solved) {
            this._location.back();
          } else {
            this.getSupportMessages();
          }
          this.formGroup.patchValue({reply_by_system:''})
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }
  checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      this.sendMsg();
    }
  }
  isIssueSolved(event: any) {
    if (event.target.checked) {
      //console.log('checked')
      this.formGroup.patchValue({ is_issue_solved: true });
    } else {
      //console.log('unchecked')
      this.formGroup.patchValue({ is_issue_solved: false });
    }
  }
  closeResult = '';
  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get r() {
    return this.refundGroup.controls;
  }
  refundGroup: FormGroup = new FormGroup({
    userId: new FormControl(''),
    _id: new FormControl(''),
    addedBy: new FormControl(''),
    customerId: new FormControl(''),
    is_issue_refunded: new FormControl(true),
    is_amount_refunded: new FormControl(true),
    amount_refunded: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    is_promo_refunded: new FormControl(false),
    astroId: new FormControl(''),
    is_call: new FormControl(''),
    is_chat: new FormControl(''),
    duration: new FormControl('', Validators.pattern('[0-9]{1,}')),
  });
  isAmountRefund = true;
  isPromoRefund = false;
  isCall = false;
  isChat = false;
  refundIssue() {
    //console.log('here');
    if (this.isAmountRefund) {
      if (this.refundGroup.get('amount_refunded')?.value == '') {
        Swal.fire(
          'Invalid Values',
          'Please Enter Amount to be Refunded',
          'error'
        );
        return;
      }
      if (this.refundGroup.get('amount_refunded')?.value > this.data.amount) {
        Swal.fire(
          'Invalid Values',
          "Refund Amount cann't be greater than Issue Amount.",
          'error'
        );
        return;
      }
    }
    if (this.isPromoRefund) {
      if (!this.isCall && !this.isChat) {
        Swal.fire('Invalid Values', 'Please Select Promo Type', 'error');
        return;
      }
      if (this.refundGroup.get('duration')?.value == '') {
        Swal.fire('Invalid Values', 'Please Enter duration for Promo', 'error');
        return;
      }
      if (this.refundGroup.get('astroId')?.value == '') {
        Swal.fire('Invalid Values', 'Please Select An Astrologer', 'error');
        return;
      }
    }
    this.spinner.show();
    var minutes = this.refundGroup.get('duration')?.value;
    this.refundGroup.patchValue({ duration: minutes * 60 });
    this.refundGroup.patchValue({ userId: this.userdata.getId() });
    this.refundGroup.patchValue({ _id: this.id });
    this.refundGroup.patchValue({ addedBy: this.userdata.getName() });
    this.refundGroup.patchValue({ customerId: this.data.customerId._id });
    //this.refundGroup.patchValue({is_issue_refunded:this.data.customerId})
    this.refundGroup.patchValue({ is_amount_refunded: this.isAmountRefund });
    this.refundGroup.patchValue({ is_promo_refunded: this.isPromoRefund });
    this.refundGroup.patchValue({ is_call: this.isCall });
    this.refundGroup.patchValue({ is_chat: this.isChat });

    //console.log('refund', this.refundGroup.value);

    this.issueService.refundCustomerIssues(this.refundGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toast.success(res.message, 'Success');
        this._router.navigateByUrl('/admin/issue/customer');
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Error');
      }
    );
  }
  promoType(event: any) {
    this.isCall = false;
    this.isChat = false;
    if (event.target.value == 'call') {
      this.isCall = true;
    }
    if (event.target.value == 'chat') {
      this.isChat = true;
    }
  }
  refundType(event: any) {
    this.isAmountRefund = false;
    this.isPromoRefund = false;
    if (event.target.value == 'Wallet Recharge') {
      this.isAmountRefund = true;
    } else {
      this.isPromoRefund = true;
      this.getAstrologerList();
    }
  }

  astroList: any = [];
  getAstrologerList() {
    this.astroService
      .getAstrologerList({ is_approved_used: true, is_approved: true })
      .subscribe(
        (res: any) => {
          this.astroList = res.data;
        },
        (err) => {}
      );
  }
}
