<br>
<div style="display: flex; justify-content: space-between;" class="pr-2 pl-2">
    <input type="text"   placeholder="Type to filter the name column..."  class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)" />
        <div>
            <button class="btn btn-info btn-sm mr-2" (click)="searchOrders({is_call_order:true})">Call Orders</button>
            <button class="btn btn-primary btn-sm mr-2" (click)="searchOrders({is_chat_order:true})">Chat Orders</button>
            <button class="btn btn-warning btn-sm mr-2" (click)="searchOrders({is_query_order:true})">Query Orders</button>
            <button class="btn btn-success btn-sm mr-2" (click)="searchOrders({is_report_order:true})">Report Orders</button>
        </div>
    <div>
        Search By Customer
        <ng-select appendTo="body" [searchable]="true" [clearable]="true" (change)="getOrderByCustomerId($event)">
            <ng-option selected>All</ng-option>
            <ng-option [value]="customer._id" *ngFor="let customer of customerList">
                #{{customer.customerAutoId}} -
                {{customer.name}}
            </ng-option>
        </ng-select>
        <!-- <select class="form-control d-inline-block" style="width: 55% !important;"
            (change)="getOrderByCustomerId($event)">
            <option [selected]="true">All</option>
            <option [value]="customer._id" *ngFor="let customer of customerList">#{{customer.customerAutoId}} -
                {{customer.name}}</option>
        </select> -->
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="orderList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="oserviceId" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.oserviceId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Order Type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_chat_order" class="badge badge-primary">Chat</span>
            <span *ngIf="row.is_call_order" class="badge badge-info">Call</span>
            <span *ngIf="row.is_report_order" class="badge badge-success">Report</span>
            <span *ngIf="row.is_query_order" class="badge badge-warning">Query</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount"> 
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_chat_order">{{row.chatId?.customerCurrencySymbol}}{{row.chatId?.customer_amount |number : '.2-2'}}</span>
            <span *ngIf="row.is_call_order">{{row.callId?.customerCurrencySymbol}}{{row.callId?.customer_amount |number : '.2-2'}}</span>
            <span *ngIf="row.is_report_order">{{row.reportId?.customerCurrencySymbol}}{{row.reportId?.customer_amount |number : '.2-2'}}</span>
            <span *ngIf="row.is_query_order">{{row.queryId?.customerCurrencySymbol}}{{row.queryId?.customer_amount |number : '.2-2'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/astrologers/viewAstrologer',row.astroId?._id]"><span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/customers/viewCustomer',row.customerId?._id,'detail']">#{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a *ngIf="row.is_query_order" [routerLink]="['/admin/orders/query/', row.queryId?._id]"
                title="View Details"><i class="fa fa-eye"></i></a>
            <a *ngIf="row.is_call_order" title="View Details" [routerLink]="['/admin/orders/call/', row.callId?._id]"><i
                    class="fa fa-eye"></i></a>
            <a *ngIf="row.is_chat_order" title="View Details" [routerLink]="['/admin/orders/chat/', row.chatId?._id]"><i
                    class="fa fa-eye"></i></a>

            <a *ngIf="row.is_report_order" [routerLink]="['/admin/orders/report/', row.reportId?._id]"
                title="View Details"><i class="fa fa-eye"></i></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
