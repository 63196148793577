import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-astrologer-notice-board',
  templateUrl: './astrologer-notice-board.component.html',
  styleUrls: ['./astrologer-notice-board.component.scss'],
})
export class AstrologerNoticeBoardComponent implements OnInit {
  currentPage = '';
  constructor(
    private customizeService: CustomizeServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  dataList: any = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  page = 1;
  pageSize = 10;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  searchKey = ''
  ngOnInit() {
    this.listNoticeBoard();
    this.searchKey =
      localStorage.getItem(`noti_search_${this.currentPage}`) || '';
  }

  /* ***********
  list Astrologer Notice Board
  *********** */

  listNoticeBoard() {
    //this.spinner.show()
    this.customizeService
      .singleCustomizationByType({ customization_type: 6 })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.dataList = res.data.customization_array.reverse();
          this.originalList = this.dataList;

          if (this.searchKey.length > 0) {
            this.searchByKey(this.searchKey);
          }
          let offset =
            localStorage.getItem(`noti_offset_${this.currentPage}`) || 0;
          if (offset > 0) {
            this.myFilterTable.offset = offset;
          }
        },
        (err) => {
          //console.log(err);
          this.spinner.hide();
        }
      );
  }

  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.dataList = this.originalList;
      localStorage.removeItem(`noti_search_${this.currentPage}`);
    } else {
      this.dataList = this.originalList.filter(function (d: any) {
        return d?.value.toLowerCase().includes(key);
      });
      localStorage.setItem(`noti_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`noti_offset_${this.currentPage}`, event.offset);
  }
  /**
   * Delete Notice
   */
  deleteNotice(data: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.customizeService
          .deleteCustomizationArrayById({
            _id: data._id,
            customization_type: 6,
            userId: this.userdata.getId(),
          })
          .subscribe(
            (res: any) => {
              this.spinner.hide();
              this.listNoticeBoard();
              this.toast.success(res.message, 'Success');
            },
            (err) => {
              this.spinner.hide();
              this.toast.error(err.error.message, 'Error');
            }
          );
      }
    });
  }

  /**
   * Edit Noticeboard
   */
   closeResult: any;
  editNotice(content: any, data: any) {
    this.formGroup.patchValue({ noticeId: data?._id })
    this.formGroup.patchValue({ value: data?.value })
    this.formGroup.patchValue({updatedBy: this.userdata.getName()})
    this.formGroup.patchValue({userId: this.userdata.getId()})
    this.modalService.open(content, { size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  formGroup: FormGroup = new FormGroup({
    customization_type: new FormControl(6),
    noticeId: new FormControl('', [Validators.required]),
    value: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    updatedBy: new FormControl('', [Validators.required]),
  });

  editAstroNoticeBoard() {
    this.spinner.show();
    this.customizeService
      .updateObjectCustomization(this.formGroup.value)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.listNoticeBoard();
          this.modalService.dismissAll();
          this.toast.success(res.message, 'Success');
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Error');
        }
      );
  }
}
