import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { NotificationServiceService } from 'src/app/admin/services/notification/notification-service.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';
import { Clipboard } from '@angular/cdk/clipboard';
import * as _ from 'lodash';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss'],
})
export class SendNotificationComponent implements OnInit {
  BaseUrl: any;
  constructor(
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private astro: AstroServiceService,
    private customer: CustomerService,
    private service: OrderServiceService,
    private userdata: UserdataService,
    private route: Router,
    private productMallService: ProductMallService,
    private blogService: BlogServiceService,
    private clipboard: Clipboard,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  isAllCustomer: Boolean = true;
  isAllAstrologer: Boolean = false;
  isOneCustomer: Boolean = false;
  isOneAstrologer: Boolean = false;

  isGeneralService: Boolean = true;
  isCustomerOrderService: Boolean = false;
  isCustomerWalletService: Boolean = false;
  isAstrologerOrderService: Boolean = false;
  isAstrologerWalletService: Boolean = false;

  isGeneralNotification: Boolean = true;
  isProductNotification: Boolean = false;
  isAstrologerNotification: Boolean = false;
  isBlogNotification: Boolean = false;
  isInternational: Boolean = false;

  customerId: string = '';
  astroId: string = '';
  productId: string = '';
  astroNotificationId: string = '';
  blogId: string = '';
  textArea: string = '';
  noti_description: string = '';
  notificationForm: any = new FormGroup({
    notification_title: new FormControl('', [Validators.required]),
    notification_description: new FormControl('', [Validators.required]),
    // notification_image: new FormControl('',[Validators.required]),
    // notification_sound: new FormControl('',[Validators.required]),
    is_customer: new FormControl(''),
    customerId: new FormControl(''),
    is_astrologer: new FormControl(''),
    astroId: new FormControl(''),
    // is_admin: new FormControl(''),
    // userId: new FormControl(''),
    is_service_involve: new FormControl(''),
    orderserviceId: new FormControl(''),
    cwalletId: new FormControl(''),
    is_cwallet_involve: new FormControl(''),
    is_awallet_involve: new FormControl(''),
    awalletId: new FormControl(''),
    notification_link: new FormControl(''),
    isForInternational: new FormControl(''),
    notification_image: new FormControl(''),
    isForBoth: new FormControl(''),
    isForDomestic: new FormControl(true),
  });

  ngOnInit(): void {
    //this.notificationForm.isForInternational = this.isInternational;
  }

  get f() {
    return this.notificationForm.controls;
  }

  notificationFor(event: any) {
    this.isAllCustomer = false;
    this.isAllAstrologer = false;
    this.isOneCustomer = false;
    this.isOneAstrologer = false;
    var value = event.target.value;
    if (value == 'allCustomer') {
      this.isAllCustomer = true;
    }
    if (value == 'allAstrologer') {
      this.isAllAstrologer = true;
    }
    if (value == 'oneCustomer') {
      this.isOneCustomer = true;
      this.getCustomerList();
    }
    if (value == 'oneAstrologer') {
      this.isOneAstrologer = true;
      this.getAstrologerList();
    }
  }
  isEmojiPickerVisible = false;
  addEmoji(event: any) {
    this.isEmojiPickerVisible = false;
    let content = this.notificationForm.get("notification_description").value
    content += event.emoji.native
    this.notificationForm.patchValue({ notification_description: content });
  }

  internationalChange() {
    this.isInternational = !this.isInternational;
  }
  customerList = [];
  getCustomerList() {
    this.customer.getCustomersList({}).subscribe(
      (res: any) => {
        this.customerList = res.data;
      },
      (err) => { }
    );
  }

  astrologerList = [];
  getAstrologerList() {
    this.astro.getAstrologerList({}).subscribe(
      (res: any) => {
        this.astrologerList = res.data;
      },
      (err) => { }
    );
  }

  notificationType(event: any) {
    this.isGeneralService = false;
    this.isCustomerOrderService = false;
    this.isCustomerWalletService = false;
    this.isAstrologerOrderService = false;
    this.isAstrologerWalletService = false;
    this.isProductNotification = false;
    this.isAstrologerNotification = false;
    this.isBlogNotification = false;
    this.isGeneralNotification = false;
    var value = event.target.value;
    if (value == 'general') {
      this.isGeneralService = true;
    }
    if (value == 'ordercustomer') {
      this.isCustomerOrderService = true;
    }
    if (value == 'orderastrologer') {
      this.isAstrologerOrderService = true;
    }
    if (value == 'walletcustomer') {
      this.isCustomerWalletService = true;
    }
    if (value == 'walletastrologer') {
      this.isAstrologerWalletService = true;
    }
    if (value == 'product') {
      this.isProductNotification = true;
      this.getProductList();
    }
    if (value == 'astrologer') {
      this.isAstrologerNotification = true;
      this.getAstrologerList();
    }
    if (value == 'blog') {
      this.isBlogNotification = true;
      this.getBlogList();
    }
    if (value == 'generalCustomer') {
      this.isGeneralNotification = true;
    }
  }

  isDomestic = true
  isBoth = false
  notificationRadius(event: any) {
    this.isInternational = false
    this.isDomestic = false
    this.isBoth = false
    if (event.target.value == 1) {
      this.isDomestic = true
    }
    if (event.target.value == 2) {
      this.isInternational = true
    }
    if (event.target.value == 3) {
      this.isBoth = true
    }
  }
  customerWalletList = [];
  getCustomerWalletList() {
    this.customerWalletList = [];
    if (this.customerId != '' && this.customerId != null) {
      //console.log('thissssss')
      this.customer
        .getCustomerWalletHistory({ customerId: this.customerId })
        .subscribe((res: any) => {
          this.customerWalletList = res.data;
        });
    }
  }

  astrologerWalletList = [];
  getAstrologerWalletList() {
    this.astrologerWalletList = [];
    if (this.astroId != '' && this.astroId != null) {
      this.astro
        .getAstroWalletHistory({ astroId: this.astroId })
        .subscribe((res: any) => {
          this.astrologerWalletList = res.data;
        });
    }
  }

  getCustomerId(event: any) {
    this.customerId = event;
    this.getCustomerWalletList();
    this.getCustomerServiceOrder();
  }

  getAstrologerId(event: any) {
    this.astroId = event;
    this.getAstrologerWalletList();
    this.getAstroServiceOrder();
  }

  customerOrderList = [];
  getCustomerServiceOrder() {
    this.customerOrderList = [];
    if (this.customerId != '' && this.customerId != null) {
      this.service
        .getAllOrderList({
          userId: this.userdata.getId(),
          customerId: this.customerId,
        })
        .subscribe((res: any) => {
          this.customerOrderList = res.data;
        });
    }
  }

  astroOrderList = [];
  getAstroServiceOrder() {
    //console.log('thissssss')
    this.astroOrderList = [];
    if (this.astroId != '' && this.astroId != null) {
      this.service
        .getAllOrderList({
          userId: this.userdata.getId(),
          astroId: this.astroId,
        })
        .subscribe((res: any) => {
          //console.log(res.data)
          this.astroOrderList = res.data;
        });
    }
  }
  sendNotification() {
    this.spinner.show()
    this.notificationForm.patchValue({ is_customer: this.isOneCustomer });
    this.notificationForm.patchValue({
      isForInternational: this.isInternational,
    });

    this.notificationForm.patchValue({ customerId: this.customerId });
    this.notificationForm.patchValue({ is_astrologer: this.isOneAstrologer });
    if (this.isCustomerOrderService || this.isAstrologerOrderService) {
      this.notificationForm.patchValue({ is_service_involve: true });
    } else {
      this.notificationForm.patchValue({ is_service_involve: false });
    }
    this.notificationForm.patchValue({
      is_cwallet_involve: this.isCustomerWalletService,
    });
    this.notificationForm.patchValue({
      is_awallet_involve: this.isAstrologerWalletService,
    });
    //return
    if (this.isAllCustomer) {
      var data: any = {};
      if (this.isGeneralNotification) {
        data = this.notificationService.addNotificationAllCustomer({
          notification_title:
            this.notificationForm.get('notification_title').value,
          notification_description: this.notificationForm.get(
            'notification_description'
          ).value,
          notification_image:
            this.notificationForm.get('notification_image').value,
          notification_link:
            this.notificationForm.get('notification_link').value,
          isForInternational: this.isInternational,
          isForDomestic: this.isDomestic,
          isForBoth: this.isBoth,
        });
      }
      if (this.isProductNotification) {
        if (this.productId == '') {
          Swal.fire('Incomplete Data.', 'Select Product', 'error');
          return;
        }
        data = this.notificationService.addNotificationAllCustomer({
          notification_title:
            this.notificationForm.get('notification_title').value,
          notification_description: this.notificationForm.get(
            'notification_description'
          ).value,
          productId: this.productId,
          notification_image:
            this.notificationForm.get('notification_image').value,
          notification_link:
            this.notificationForm.get('notification_link').value,
          isForInternational: this.isInternational,
          isForDomestic: this.isDomestic,
          isForBoth: this.isBoth,
        });
      }
      if (this.isAstrologerNotification) {
        if (this.astroNotificationId == '') {
          Swal.fire('Incomplete Data.', 'Select Astrologer', 'error');
          return;
        }
        data = this.notificationService.addNotificationAllCustomer({
          notification_title:
            this.notificationForm.get('notification_title').value,
          notification_description: this.notificationForm.get(
            'notification_description'
          ).value,
          astroId: this.astroNotificationId,
          notification_image:
            this.notificationForm.get('notification_image').value,
          notification_link:
            this.notificationForm.get('notification_link').value,
          isForInternational: this.isInternational,
          isForDomestic: this.isDomestic,
          isForBoth: this.isBoth,
        });
      }
      if (this.isBlogNotification) {
        if (this.blogId == '') {
          Swal.fire('Incomplete Data.', 'Select Blog', 'error');
          return;
        }
        data = this.notificationService.addNotificationAllCustomer({
          notification_title:
            this.notificationForm.get('notification_title').value,
          notification_description: this.notificationForm.get(
            'notification_description'
          ).value,
          blogId: this.blogId,
          notification_image:
            this.notificationForm.get('notification_image').value,
          notification_link:
            this.notificationForm.get('notification_link').value,
          isForInternational: this.isInternational,
          isForDomestic: this.isDomestic,
          isForBoth: this.isBoth,
        });
      }
    } else if (this.isAllAstrologer) {
      data = this.notificationService.addNotificationAllAstrologer({
        notification_title:
          this.notificationForm.get('notification_title').value,
        notification_description: this.notificationForm.get(
          'notification_description'
        ).value,
        notification_image:
          this.notificationForm.get('notification_image').value,
        notification_link: this.notificationForm.get('notification_link').value,
      });
    } else {
      if (this.isOneCustomer && this.customerId == '') {
        Swal.fire(
          'Incomplete Data.',
          'Select Customer to send notification',
          'error'
        );
        return;
      }
      if (this.isOneAstrologer && this.astroId == '') {
        Swal.fire(
          'Incomplete Data.',
          'Select Astrologer to send notification',
          'error'
        );
        return;
      }
      data = this.notificationService.addNotification(
        this.notificationForm.value
      );
    }
    //console.log(data);
    data.subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success("Push Notification Sent", 'Success')
        this.reloadCurrentRoute()
      
      }, (err: any) => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  /**
   * Notification to All Customer
   */
  getAstrologerNotificationId(event: any) {
    this.astroNotificationId = event;
  }

  getProductNotificationId(event: any) {
    this.productId = event;
  }

  getBlogNotificationId(event: any) {
    this.blogId = event;
  }

  productList = [];
  getProductList() {
    this.productMallService.listMallProduct({}).subscribe(
      (res: any) => {
        this.productList = res.data;
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }

  blogList = [];
  getBlogList() {
    this.blogService.getBlog({ isApproved: true, is_used: true }).subscribe(
      (res: any) => {
        this.blogList = res.data;
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  /**
   * Direct Image Upload for blog
   */
  imageGroup: FormGroup = new FormGroup({
    image: new FormControl('', Validators.required),
  });
  imageDirectError: string = '';
  imageDirectSrc = '';
  directPath: String = '';
  public onDirectUpload(event: any) {
    this.imageDirectError = '';
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageDirectError = 'Only Images are allowed ( JPG | PNG )';
        return;
      } else {
        this.imageGroup.patchValue({
          image: event.target.files[0],
        });
        // console.log(this.BaseUrl)
      }
    }
  }

  addDirectImage() {
    const data = new FormData();
    data.append('image', this.imageGroup.get('image')?.value);
    // console.log(this.imageGroup.get("image")?.value)
    // return
    this.notificationService.getNotificationImage(data).subscribe(
      (res: any) => {
        // console.log(res)
        this.toast.success(res.message, 'Success');
        this.imageGroup.patchValue({
          image: '',
        });
        this.directPath = this.BaseUrl + res.result;
      },
      (err) => {
        this.toast.error(err.error.message, 'Error');
      }
    );
  }

  copyImageName() {
    this.clipboard.copy(String(this.directPath));
    this.toast.success('Path Copied!');
  }
}
