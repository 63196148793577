import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { moveSyntheticComments } from 'typescript';

@Component({
  selector: 'app-order-month-wise',
  templateUrl: './order-month-wise.component.html',
  styleUrls: ['./order-month-wise.component.scss']
})
export class OrderMonthWiseComponent implements OnInit {

  constructor(
    private astroService: AstroServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  orderList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  year:any = ''
  month:any = ''
  type:any = 'all'

  yearList = [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030];
  monthList = [{'id':1,'name':'January'},{'id':2,'name':'February'},{'id':3,'name':'March'},{'id':4,'name':'April'},{'id':5,'name':'May'},{'id':6,'name':'June'},{'id':7,'name':'July'},{'id':8,'name':'August'},{'id':9,'name':'September'},{'id':10,'name':'October'},{'id':11,'name':'November'},{'id':12,'name':'December'}]

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    var date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth()+1
    this.getWalletMonthWise()
  }

  /* ***********
  list Astrologer month wise order
  *********** */

  getWalletMonthWise() {
    this.spinner.show()
    this.astroService.getMonthWiseAstroOrders({ 'year': this.year, 'month': this.month }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderList = res.data;
        this.temp = this.orderList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * list astrologer orders according to month and type
   */
   getWalletMonthTypeWise() {
    this.spinner.show()
    this.astroService.getCountOfOrderType({ 'year': this.year, 'month': this.month,'ordertype':this.type }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderList = res.data;
        this.temp = this.orderList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }
  /**
 * Update filter
 */
  updateFilter(event: any) {
    // console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.astroAutoId.toString().indexOf(val) !== -1 ||
        d.displayname.toLowerCase().indexOf(val) !== -1 ||
        d.email.toLowerCase().indexOf(val) !== -1 ||
        d.totalorders.toString().indexOf(val) !== -1 || !val

    });

    // update the rows
    this.orderList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  search(){
    if(this.type == 'all'){
      this.getWalletMonthWise()
    }else{
      this.getWalletMonthTypeWise()
    }
  }

}
