<div class="pr-2 pl-2 d-flex justify-content-between">
  <input type="text"  placeholder="Search ID, Name, Email..." class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"   />
  <div class="col-4">
    <div class="row mr-2">
      <select class="form-control col-3" [(ngModel)]="year">
        <option *ngFor="let year of yearList">{{year}}</option>
      </select>
  
      <select class="form-control col-4" [(ngModel)]="month">
        <option *ngFor="let month of monthList" [value]="month.id">{{month.name}}</option>
      </select>
      <button class="col-3 btn btn-sm btn-primary" (click)="search()">Search</button>
    </div>
  </div>
</div>
<br>
<ngx-datatable class="material" [rows]="walletList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
  <ngx-datatable-column name="Astro ID" prop="astroAutoId" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      #{{row.astroAutoId}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Name" prop="displayname" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.name">{{(row.displayname == '' || row.displayname == undefined || row.displayname == null) ? row.name : row.displayname}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Email" prop="email" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.email}}
    </ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column name="Total" prop="current_wallet" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.current_wallet | currency :'INR':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column> -->
  <ngx-datatable-column name="Current Month" prop="monthearning" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.monthearning | currency :'INR':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
