<ngx-spinner></ngx-spinner>
<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->
    <form [formGroup]="formGroup" (submit)="addRechargeSubmit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Recharge</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="amount">Amount (INR)</label>
                                    <input type="text" class="form-control" form placeholder="amount"
                                        formControlName="amount">
                                    <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)"
                                        class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="percentge">Extra Percentage</label>
                                    <input type="text" class="form-control" placeholder="Extra Percentage"
                                        formControlName="percentge">
                                    <div *ngIf="f.percentge.invalid && (f.percentge.dirty || f.percentge.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="walletamount_description">Description</label>
                                    <input type="Description" class="form-control" id="walletamount_description"
                                        placeholder="Description" formControlName="walletamount_description">
                                    <div *ngIf="f.walletamount_description.invalid && (f.walletamount_description.dirty || f.walletamount_description.touched)"
                                        class="alert alert-danger mt-1">
                                        Required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1" [disabled]="!formGroup.valid">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->