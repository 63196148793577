<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Blog Category</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/blog-category/list']">Blog Category</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateBlogCategory()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="categoryname">Name</label>
                                        <input type="text" class="form-control" form placeholder="Blog Category Name"
                                            formControlName="categoryname">
                                        <small
                                            *ngIf="f.categoryname.invalid && (f.categoryname.dirty || f.categoryname.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="categorydescription">Meta Description</label>
                                        <input type="text" class="form-control" form placeholder="Description"
                                            formControlName="categorydescription">
                                        <small
                                            *ngIf="f.categorydescription.invalid && (f.categorydescription.dirty || f.categorydescription.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">                            
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="category_slug">Slug (URL)</label>
                                        <input type="text" class="form-control" id="category_slug"
                                            placeholder="Eg: best-way, best" formControlName="category_slug">
                                        <small
                                            *ngIf="f.category_slug.invalid"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Slug can only contain '-' and no space or any special symbol.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="category_keyword">Meta Keywords</label>
                                        <input type="text" class="form-control" id="category_keyword"
                                            placeholder="Separate Keywords by ','" formControlName="category_keyword">
                                        <small
                                            *ngIf="f.category_keyword.invalid && (f.category_keyword.dirty || f.category_keyword.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Only Number Allowed.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <button type="submit" class="btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->