<div class="pr-2 pl-2 d-flex justify-content-between">
    <!-- <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
    <div>
        <button class="btn btn-secondary btn-sm mr-2" (click)="getQueryOrders()">All</button>
        <button class="btn btn-primary btn-sm mr-2" (click)="notAnswered()">Not Answered</button>
        <button class="btn btn-info btn-sm mr-2" (click)="noAstrologerFeedbackReply()">No Feedback Reply</button>
    </div> -->
  </div>
  <br>
  <ngx-datatable class="material" [rows]="rechargeList" [columnMode]="'force'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="walletamountId">
      <ng-template let-row="row" ngx-datatable-cell-template>
        #{{row.walletamountId}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="amount">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.amount | currency:'INR':'symbol-narrow'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Amount(USD)">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{getInternationalPrice(row.amount) | currency:'USD'}}
      </ng-template>
  </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Extra %" prop="percentge">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.percentge}} %
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Amount" prop="walletamount_description">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.walletamount_description}}
        </ng-template>
      </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
      <ng-template let-row="row" ngx-datatable-cell-template>
       {{row.created_at |date :'medium'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Enable">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <!-- Material switch -->
        <ng-template #enable>
          <mat-slide-toggle color="success" (change)="toggle( row._id, row.status)"
            [checked]="true"></mat-slide-toggle>
        </ng-template>
        <ng-template #disable>
          <mat-slide-toggle color="success" (change)="toggle( row._id, row.status)"
            [checked]="false"></mat-slide-toggle>
        </ng-template>
        <ng-container *ngIf="
            row?.status == true;
            then enable;
            else disable
          ">
        </ng-container>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a class="btn btn-primary btn-sm" [routerLink]="['/admin/wallet-recharge/edit',row._id]">Edit</a>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
