<form [formGroup]="updateChargesForm" (submit)="onUpdateCharges()" >
    <div class="row">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <h3 class="card-title">Update Astrologer Charges</h3>
                    <select (change)="getValue($event)">
                        <option *ngFor="let price of dataList" [value]="price._id">{{ price.countryName }} ({{price.countryCode}})</option>
                    </select>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Call Charges ({{internationalCallPrice}})</label>
                                <input type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Call Charges" formControlName="call_charges">
                                <div *ngIf="f.call_charges.invalid && (f.call_charges.dirty || f.call_charges.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Chat Charges ({{internationalChatPrice}})</label>
                                <input type="number" class="form-control" id="exampleInputnumber"
                                    placeholder="Chat Charges" formControlName="chat_charges">
                                <div *ngIf="f.chat_charges.invalid && (f.chat_charges.dirty || f.chat_charges.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Query Charges ({{internationalQueryPrice}})</label>
                                <input type="number" class="form-control" id="exampleInputEmail1"
                                    placeholder="Query Charges" formControlName="query_charges">
                                <div *ngIf="f.query_charges.invalid && (f.query_charges.dirty || f.query_charges.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Report Charges ({{internationalReportPrice}})</label>
                                <input type="number" class="form-control" id="exampleInputnumber"
                                    placeholder="Report Charges" formControlName="report_charges">
                                <div *ngIf="f.report_charges.invalid && (f.report_charges.dirty || f.report_charges.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                
                                <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                                    <input type="checkbox" name="" id="" class="mr-2" formControlName="is_promotional_allow" (change)="togglePermotionalDeatails($event)"> 
                                    <label>Allow Permotional Calls</label>
                                </div>
                                <div *ngIf="f.is_promotional_allow.invalid && (f.is_promotional_allow.dirty || f.is_promotional_allow.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>    
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" *ngIf="permotionalAllow">
                            <div class="form-group">
                                <label >Permotional Call Charges</label>
                                <input type="number" class="form-control" 
                                    placeholder="Permotional Call Charges" formControlName="promocall_totake">
                                <div *ngIf="f.promocall_totake.invalid && (f.promocall_totake.dirty || f.promocall_totake.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>   
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-center">
                    <button type="submit" class="col-sm-4 btn btn-success mt-1">Save</button>
                    <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                </div>
            </div>           
        </div>
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Charges Update Logs</h3>
                </div>
                <div class="card-body">
                    <ngx-datatable class="material" [rows]="chargeLogs" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                        <ngx-datatable-column [flexGrow]="1" name="Charges" prop="last_charge">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.last_charge | currency:'INR':'symbol-narrow'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Updated Charges" prop="new_charge">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.new_charge | currency:'INR':'symbol-narrow'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Type" prop="charge_type">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="text-uppercase badge badge-primary">{{row.charge_type}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Status">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="badge badge-warning" *ngIf="row.is_new_request">Pending</span>
                                <span class="badge badge-success" *ngIf="!row.is_new_request && row.change_approved">Approved</span>
                                <span class="badge badge-primary" *ngIf="!row.is_new_request && !row.change_approved">Rejected</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="3" name="Astro Remarks">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.change_comment}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Updated At">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.updatedDate | date:'medium'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Action At">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.approvalDate | date:'medium'}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>

    </div>
</form>