import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferServiceService } from 'src/app/admin/services/offers/offer-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-offers',
  templateUrl: './edit-offers.component.html',
  styleUrls: ['./edit-offers.component.scss']
})
export class EditOffersComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private offerService: OfferServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
  ) { }

  data: any = '';
  id: any = '';


  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ updatedId: this.userdata.getId() })
    this.formGroup.patchValue({ updatedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    updatedId: new FormControl('', Validators.required),
    updatedBy: new FormControl('', Validators.required),
    offer_display_name: new FormControl('', Validators.required),
    user_type: new FormControl('', [Validators.required]),
    astrorivershare_indian: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    astrorivershare_foreign: new FormControl('0', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    offer_on: new FormControl('', Validators.required),
    offer_discount: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    offer_name: new FormControl('', Validators.required),
  });


  getDataById() {
    this.spinner.show()
    this.offerService.detailOffers({ _id: this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.formGroup.patchValue({ offer_display_name: res.data.offer_display_name });
        this.formGroup.patchValue({ user_type: res.data.user_type });
        this.formGroup.patchValue({ astrorivershare_indian: res.data.astrorivershare_indian });
        this.formGroup.patchValue({ offer_on: res.data.offer_on });
        this.formGroup.patchValue({ offer_discount: res.data.offer_discount });
        this.formGroup.patchValue({ offer_name: res.data.offer_name });
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/offers/all')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Update Offers
 *********** */

  public updateOffers() {
    this.spinner.show();
    this.offerService.updateOffers(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success');
        this.route.navigateByUrl('/admin/offers/all')
        //this.route.navigateByUrl('/astrologer/remedy')
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
      })
  }
 
}
