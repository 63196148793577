import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class FestivalServiceService {

  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }

  /**
   * Festival
   */

  public addFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addFestival", data,{ headers: header_object});
  }

  public listFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allFestivals", data,{ headers: header_object});
  }

  public detailFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getSingleFestival", data,{ headers: header_object});
  }

  public updateFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateFestival", data,{ headers: header_object});
  }

  /**
   * Yearly Festival
   */
   public addYearlyFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addYearlyFestival", data,{ headers: header_object});
  }

  public listYearlyFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allYearlyFestivals", data,{ headers: header_object});
  }

  public detailYearlyFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getSingleYearlyFestival", data,{ headers: header_object});
  }

  public deleteYearlyFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deleteYearlyFestival", data,{ headers: header_object});
  }

  public updateYearlyFestival(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateYearlyFestival", data,{ headers: header_object});
  }

}
