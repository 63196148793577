import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ForgotPasswordService } from '../../services/forgot-password/forgot-password.service';
import { ManagerService } from '../../services/manager/manager.service';
import { UserdataService } from '../../services/shared/userdata/userdata.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: any = ''
  requestOtp = true
  checkOtp = false
  changePassword = false

  constructor(
    private toast: ToastrService,
    private ngxSpinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
  ) { }

  requestOtpForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });

  changePasswordForm = new FormGroup({
    email: new FormControl(''),
    password:new FormControl('', [Validators.required])
  });

  ngOnInit(): void {
    if (this.userdata.getData() != null) {
      this.router.navigateByUrl('/admin/dashboard')
    }
  }

  requestOtpSubmit() {
    this.ngxSpinner.show()
    this.email = this.requestOtpForm.get('email')?.value
    this.forgotPasswordService.requestOtpForgot(this.requestOtpForm.value).subscribe(
      (res: any) => {
        this.ngxSpinner.hide()
        this.checkOtp = true
        this.requestOtp = false
        this.changePassword = false
        this.toast.success(res.message, 'Success')
      }, err => {
        this.ngxSpinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  onOtpChange(event: any) {
    if (event.length == 4) {
      this.ngxSpinner.show()
      this.forgotPasswordService.checkOtpForgot({ email: this.email, otp: event }).subscribe(
        (res: any) => {
          this.ngxSpinner.hide()
          this.toast.success(res.message, 'Success')
          this.checkOtp = false
          this.requestOtp = false
          this.changePassword = true
        }, err => {
          this.ngxSpinner.hide()
          this.toast.error(err.error.message, 'Error')
        }
      )
    }
  }

  changePasswordSubmit() {
    this.ngxSpinner.show()
    this.changePasswordForm.patchValue({email:this.email})
    this.forgotPasswordService.changeForgotPassword(this.changePasswordForm.value).subscribe(
      (res: any) => {
        this.ngxSpinner.hide()
        this.toast.success(res.message, 'Success')
        this.router.navigateByUrl('/')
      }, err => {
        this.ngxSpinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

}
