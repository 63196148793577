<div class="pr-2 pl-2 d-flex justify-content-between">
  <input
    type="text"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
  <div>
    <button class="btn btn-warning btn-sm mr-2" (click)="pendingIssue()">
      Pending Issues
    </button>
    <button class="btn btn-success btn-sm mr-2" (click)="solvedIssue()">
      Solved Issues
    </button>
  </div>
</div>
<br />
<ngx-datatable
  class="material"
  [rows]="issueList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  [sorts]="[{ dir: 'desc' }]"
  style="position: relative !important"
>
  <ngx-datatable-column name="Support ID" prop="supportId" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      #{{ row.supportId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Astrologer"
    prop="astroId.displayname"
    [flexGrow]="2"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.astroId?.name"
        >#{{ row.astroId?.astroAutoId }}-{{
          row.astroId?.displayname == "" ||
          row.astroId?.displayname == undefined ||
          row.astroId?.displayname == null
            ? row.astroId?.name
            : row.astroId?.displayname
        }}</span
      >
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Issue" prop="support_title" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.support_title }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="badge badge-success" *ngIf="row.is_issue_solved"
        >SOLVED</span
      >
      <span class="badge badge-danger" *ngIf="!row.is_issue_solved"
        >PENDING</span
      >
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Actions" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a
        [routerLink]="['/admin/supports/astrologer/', row._id]"
        title="View Details"
        class="mr-2"
        ><i class="fa fa-eye"></i
      ></a>
      <button
        class="btn btn-primary btn-sm"
        (click)="messageChatClick(row._id, row.is_reply_by_system)"
        *ngIf="!row.is_issue_solved"
      >
        {{ row.is_reply_by_system ? "Chat" : "Reply" }}
      </button>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date" [flexGrow]="2" prop="created_at">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.created_at | date: "medium" }}
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
