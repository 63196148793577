import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-callorders',
  templateUrl: './list-callorders.component.html',
  styleUrls: ['./list-callorders.component.scss']
})
export class ListCallordersComponent implements OnInit {

  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  callList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];
  getCallList: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getCallOrderList()
    this.callList = this.getCallList;
  }

  /* ***********
  list Call Orders
  *********** */

  getCallOrderList() {
    this.spinner.show()
    this.order.getCallOrderList({'is_promotional_call':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        // console.log(this.callList)
        this.temp = this.callList;
        this.getCallList = this.callList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.ocallId.toString().indexOf(val) !== -1 ||
        d.astroId?.name.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.callList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  public convertSecondstoTime(val: any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString = hours.toString().padStart(2, '0')
      + ':' + minutes.toString().padStart(2, '0')
      + ':' + seconds.toString().padStart(2, '0');
    return timeString
  }

  noCustomerReview() {
    this.spinner.show()
    this.order.getCallOrderList({ 'userId': this.userdata.getId(), 'is_order_reviewed': false, 'is_order_completed':true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        //console.log(this.callList)
        this.temp = this.callList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  noAstrologerReviewReply() {
    this.spinner.show()
    this.order.getCallOrderList({ 'userId': this.userdata.getId(), 'is_astro_replied_review': false ,'is_order_completed':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        //console.log(this.callList)
        this.temp = this.callList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  promotionalCall() {
    this.spinner.show()
    this.order.getCallOrderList({ 'userId': this.userdata.getId(), 'is_promotional_call': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        //console.log(this.callList)
        this.temp = this.callList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  completedCallOrder() {
    this.spinner.show()
    this.order.getCallOrderList({ 'userId': this.userdata.getId(), 'is_order_completed': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        //console.log(this.callList)
        this.temp = this.callList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  onGoingCallOrder() {
    this.spinner.show()
    this.order.getOngoingCallOrderList({ 'userId': this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        //console.log(this.callList)
        this.temp = this.callList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
