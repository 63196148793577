import { Component, OnInit } from '@angular/core';
import {Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicPagesServiceService } from 'src/app/admin/services/dynamic-pages/dynamic-pages-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { HttpClient, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';

@Component({
  selector: 'app-add-dynamic-page',
  templateUrl: './add-dynamic-page.component.html',
  styleUrls: ['./add-dynamic-page.component.scss']
})
export class AddDynamicPageComponent implements OnInit {

  constructor(private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private dynamicPageService: DynamicPagesServiceService ,
    private _location:Location,
    private route: Router) { }

  formGroup: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    metaKeywords: new FormControl(''),
    metaDescription: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
   });
   goBack(){
      this._location.back()
   }
  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
  }

  get f() {
    return this.formGroup.controls
  }

  addDynamicPageSubmit(){
    // console.log(this.formGroup.value)
    // return
    this.dynamicPageService.addDynamicPage(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.reloadCurrentRoute()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }
  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

  example_image_upload_handler(blobInfo: any, success: any, failure: any, progress: any) {

    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    var token = sessionStorage.getItem('token')??""
    var header_object = new HttpHeaders().set("Authorization", token);
    const data = new FormData()
    data.append("image",blobInfo.blob());
    let request = httpClient.post<any>("https://apiv2.astroriver.com/admin/addblogimages",data,{headers:header_object});
    request.subscribe(res=>{
      //console.log("res",res)
      if(res.success){
        success(`https://apiv2.astroriver.com/${res.result}`)
      }else{
        failure("not uploaded")
      }

    },err=> {
      failure(err);
    })
  }
}

