<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Astro Shop - Products</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/mall-product/list']">Product List</a></li>
      <li class="breadcrumb-item active" aria-current="page">Add Astro</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!-- ----------------------------------- -->
            <!-- ----------------------------------- -->
            <form [formGroup]="formGroup" (submit)="addAssociativeAstros()">
              <table class="table table-bordered">
                <tr>
                  <th>Product ID</th>
                  <th>#{{data?.productId}}</th>
                </tr>
                <tr>
                  <th>Product Code</th>
                  <th>{{data?.productCode}}</th>
                </tr>
                <tr>
                  <th>Product Name</th>
                  <th>{{data?.productname}}</th>
                </tr>
                <tr>
                  <th>Product Description</th>
                  <th>{{data?.productdescription}}</th>
                </tr>
              </table>
              <table class="table table-bordered" formArrayName="astrosList">
                <tr>
                  <th colspan="4">Add Multiple Astrologers:</th>
                  <th width="150px"><button type="button" (click)="addAstrologersList()"
                      class="btn-user-custom btn btn-outline-primary btn-sm">Add
                      More</button></th>
                </tr>
                <tr *ngFor="let list of asrologersList().controls; let i=index" [formGroupName]="i">
                  <td>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="astroId">Astrologer</label>
                        <ng-select [items]="astroList" placeholder="Select Astrologer" formControlName="astroId"
                          bindLabel="name" bindValue="_id">
                          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div style="margin: 10px 0;" class="card">
                              <div class="card-body">
                                <h5 class="card-title" [ngOptionHighlight]="search">{{item.displayname}}</h5>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
                                <p class="card-text d-flex">
                                  <span>
                                    <img height="50" width="50" [src]="getSanitizeUrl(item.profile_image)" />
                                    <br />
                                    <ng-template #t let-fill="fill">
                                      <span class="star" [class.full]="fill === 100">
                                        <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                      </span>
                                    </ng-template>
                                    <ngb-rating [(rate)]="item.astrologer_rating" [starTemplate]="t"
                                      [readonly]="true" [max]="5"></ngb-rating>
                                  </span>
                                  <span>{{item.name}}<br />
                                    {{item.price | currency:'INR':'symbol-narrow'}}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </ng-template>
                        </ng-select>
                        <!-- <div *ngIf="f.astroId.invalid && (f.astroId.dirty || f.astroId.touched)"
                          class="alert alert-danger mt-1">
                          Required
                        </div> -->
                      </div>
                    </div>
                  </td>
                  <td style="width: 20%;">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Astrologer Price (In Rupees) *(only numbers are allowed)</label>
                        <input type="text" formControlName="astroprice" class="form-control"
                          placeholder="Enter Astro Price in Rupees">
                        <!-- <div *ngIf="f.astroprice.invalid && (f.astroprice.dirty || f.astroprice.touched)"
                          class="alert alert-danger mt-1">
                          Required. Only Numbers are allowed.
                        </div> -->
                      </div>
                    </div>
                  </td>
                  <td style="width: 20%;">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Final Price (In Rupees) *(only numbers are allowed)</label>
                        <input type="text" formControlName="finalprice" class="form-control"
                          placeholder="Enter Final Price in Rupees">
                        <!-- <div *ngIf="f.finalprice.invalid && (f.finalprice.dirty || f.finalprice.touched)"
                          class="alert alert-danger mt-1">
                          Required. Only Numbers are allowed.
                        </div> -->
                      </div>
                    </div>
                  </td>
                  <td style="width: 20%;">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Final Price (In USD) *(only numbers are allowed)</label>
                        <input type="text" formControlName="usd_price" class="form-control"
                          placeholder="Enter Final Price in USD">
                        <!-- <div *ngIf="f.finalprice.invalid && (f.finalprice.dirty || f.finalprice.touched)"
                          class="alert alert-danger mt-1">
                          Required. Only Numbers are allowed.
                        </div> -->
                      </div>
                    </div>
                  </td>
                  <td style="width: 10%;">
                    <div class="col-md-12">
                      <div class="form-group">
                        <button (click)="removeAstrologersList(i)"
                          class="btn-user-custom btn btn-outline-danger btn-sm">Remove</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <button type="submit()" class="btn btn-primary mt-1 mb-2" [disabled]="!formGroup.valid"><i
                  class="fa fa-rss"></i> Submit</button>
            </form>
            <!-- ----------------------------------- -->
            <!-- ----------------------------------- -->
          </div>
        </div>
      </div>
    </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->