import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { ExcelServiceService } from 'src/app/admin/services/excel-service/excel-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-astro-payout',
  templateUrl: './astro-payout.component.html',
  styleUrls: ['./astro-payout.component.scss']
})
export class AstroPayoutComponent implements OnInit {

  constructor(
    private astroService: AstroServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private excelService: ExcelServiceService,
    private datePipe: DatePipe
  ) { }
  title = 'angular-datatables';

  payoutList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];
  year:any = ''
  month:any = ''

  yearList = [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030];
  monthList = [{'id':1,'name':'January'},{'id':2,'name':'February'},{'id':3,'name':'March'},{'id':4,'name':'April'},{'id':5,'name':'May'},{'id':6,'name':'June'},{'id':7,'name':'July'},{'id':8,'name':'August'},{'id':9,'name':'September'},{'id':10,'name':'October'},{'id':11,'name':'November'},{'id':12,'name':'December'}]

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    var date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth()
    this.getMonthWisePayout()
  }

  /* ***********
  list payout according to month
  *********** */

  getMonthWisePayout() {
    this.spinner.show()
    this.astroService.getMonthWisePayout({ 'payoutyear': this.year, 'payoutmonth': this.month ,'isZeroPayouts':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        // console.log(res.data)
        this.payoutList = res.data;
        this.temp = this.payoutList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.astroId?.displayname.toLowerCase().indexOf(val) !== -1 ||
      d.astroId?.astroAutoId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.payoutList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  search(){
    this.getMonthWisePayout()
  }

  /**
   * Check TDS amount
   */
   checkTdsAmount(data:any){
    var amount = (10/100)*data.totalpayout
    return amount.toFixed(2)
   }

   /**
    * Get final payout of astrologer after tds
    */
    checkFinalWithTds(data:any){
      var amount = data.finalpayout - ((10/100)*data.totalpayout)
      return amount.toFixed(2)
    }

    /***
     * Search zero and non-zero payout
     ***/
     searchPayout(data:any) {
      this.spinner.show()
      this.astroService.getMonthWisePayout(data).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.payoutList = res.data;
          this.temp = this.payoutList
          // console.log(res.data)
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
        }
      );
    }

    transformDate(date:any){
      return this.datePipe.transform( date,'MM-dd-yyyy');
    }
  
    generateExcel() {
      if(this.payoutList.length == 0){
        return
      }
      const header = ['ID','Name','Month','Year','Total Payout','TDS Amount','PG Amount','Payout','Payment Status']
      const excelData:any = []
      this.payoutList.forEach((row:any)=>{
        var obj = []
        obj.push(row.payoutId)
        obj.push(row.astroId.displayname)
        if(row.payoutmonth == 1 || row.payoutmonth == Number('01')){
          obj.push('January')
        }else if(row.payoutmonth == 2 || row.payoutmonth == Number('02')){
          obj.push('February')
        }else if(row.payoutmonth == 3 || row.payoutmonth == Number('03')){
          obj.push('March')
        }else if(row.payoutmonth == 4 || row.payoutmonth == Number('04')){
          obj.push('April')
        }else if(row.payoutmonth == 5 || row.payoutmonth == Number('05')){
          obj.push('May')
        }else if(row.payoutmonth == 6 || row.payoutmonth == Number('06')){
          obj.push('June')
        }else if(row.payoutmonth == 7 || row.payoutmonth == Number('07')){
          obj.push('July')
        }else if(row.payoutmonth == 8 || row.payoutmonth == Number('08')){
          obj.push('August')
        }else if(row.payoutmonth == 9 || row.payoutmonth == Number('09')){
          obj.push('September')
        }else if(row.payoutmonth == 10 || row.payoutmonth == 10){
          obj.push('October')
        }else if(row.payoutmonth == 11 || row.payoutmonth == 11){
          obj.push('November')
        }else{
          obj.push('December')
        }
        obj.push(row.payoutyear)
        obj.push('₹'+row.totalpayout)
        if(row.tdsamount == 0){
          obj.push('₹'+this.checkTdsAmount(row)+(row.tdspercentage+'%'))
        }else{
          obj.push('₹'+row.tdsamount+(row.tdspercentage+'%'))
        }
        obj.push(row.pgamount+(- row.pgchargeper+'%'))
        if(row.tdsamount == 0){
          obj.push('₹'+this.checkFinalWithTds(row))
        }else{
          obj.push('₹'+row.finalpayout)
        }
        if(row.is_paid){
          obj.push('PAID')
        }else{
          obj.push('PENDING')
        }
        excelData.push(obj)
      })
      this.excelService.generateExcel(excelData,'Payout History',header);
    }
}
