<div class="pr-2 pl-2 d-flex justify-content-between">
  <input
  value="{{searchKey}}"
    type="text"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
</div>
<br />
<ngx-datatable
  class="material"
  [rows]="dataList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  (page)="onPageChange($event)"
  [sorts]="[{ dir: 'desc' }]"
  style="position: relative !important"
>
  <ngx-datatable-column [flexGrow]="2" name="Name" prop="countryName">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.countryName">{{ row.countryName }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Code" prop="countryCode">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.countryCode">{{ row.countryCode }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Symbol" prop="currencySymbol">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.currencySymbol">{{ row.currencySymbol }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="1"
    name="Currency Value"
    prop="currencyUnitInRupees"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.currencyUnitInRupees"
        ><label class="price">{{
          row.currencyUnitInRupees | currency: "INR":"symbol-narrow"
        }}</label></span
      >
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="1"
    name="Call Price"
    prop="callPricePercent"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.callPricePercent }}%
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="1"
    name="Chat Price"
    prop="chatPricePercent"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.chatPricePercent }}%
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="1"
    name="Query Price"
    prop="queryPricePercent"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.queryPricePercent }}%
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="1"
    name="Report Price"
    prop="reportPricePercent"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.reportPricePercent }}%
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Edit">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a
        [routerLink]="['/admin/internationals/', row._id]"
        class="btn-table-custom btn btn-sm btn-outline-primary"
        >Edit</a
      >
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
