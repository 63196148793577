import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';

@Component({
  selector: 'app-wallet-history',
  templateUrl: './wallet-history.component.html',
  styleUrls: ['./wallet-history.component.scss']
})
export class WalletHistoryComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private customerService: CustomerService
  ) { }

  data: any = '';
  temp: any = '';
  id: any = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    //this.id = this.router.snapshot.paramMap.get('id')
    this.router.parent?.params.subscribe(
      (params) => {
        this.id = params.data
      });
    this.getCustomerWalletHistoryDataById()
  }

  getCustomerWalletHistoryDataById(){
    this.spinner.show()
    this.customerService.getCustomerWalletHistory({customerId: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  public getCustomerCreditedWalletHistory(){
    this.spinner.show()
    this.customerService.getCustomerWalletTransactionHistoryByOperation({customerId: this.id, txn_type: 1}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  public getCustomerDebitedWalletHistory(){
    this.spinner.show()
    this.customerService.getCustomerWalletTransactionHistoryByOperation({customerId: this.id, txn_type: 2}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  public getCustomerWalletTransactionsByAdmin(){
    this.spinner.show()
    this.customerService.getCustomerWalletTransactionHistoryByOperation({customerId: this.id, is_admin_generate: true}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  /**
 * Update filter
 */
updateFilter(event: any) {
  const val = event.target.value.toLowerCase();

  const temp = this.temp.filter(function (d: any) {
    
    return d.cwalletId.toString().indexOf(val) !== -1 || !val
  });

  // update the rows
  this.data = temp;
  // Whenever the filter changes, always go back to the first page
  this.myFilterTable.offset = 0;
}
}
