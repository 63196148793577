import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicPagesServiceService } from 'src/app/admin/services/dynamic-pages/dynamic-pages-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-dynamic-page',
  templateUrl: './list-dynamic-page.component.html',
  styleUrls: ['./list-dynamic-page.component.scss'],
})
export class ListDynamicPageComponent implements OnInit {
  BaseUrl: any;
  searchKey = '';
  currentPage = '';
  constructor(
    private dynamicpageService: DynamicPagesServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: ActivatedRoute,
    @Inject('CUSTOMER_URL') _customerurl: any
  ) {
    this.BaseUrl = _customerurl+'page/';
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  dynamicPageList = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];
  url: any = '';
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getDynamicPageList();
    this.BaseUrl;
    this.searchKey =
      localStorage.getItem(`page_search_${this.currentPage}`) || '';
  }

  /* ***********
  list Dynamic pages
  *********** */

  getDynamicPageList() {
    this.spinner.show();
    this.dynamicpageService
      .allDynamicPage({ userId: this.userdata.getId() })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.dynamicPageList = res.result;
          this.originalList = res.result;
          let offset =
            localStorage.getItem(`page_offset_${this.currentPage}`) || 0;
          if (offset > 0) {
            this.myFilterTable.offset = offset;
          }
          if (this.searchKey.length > 0) {
            this.searchByKey(this.searchKey);
          }
        },
        (err) => {
          this.spinner.hide();
          // console.log(err);
        }
      );
  }

  onPageChange(event: any) {
    localStorage.setItem(`page_offset_${this.currentPage}`, event.offset);
  }
  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.dynamicPageList = this.originalList;
      localStorage.removeItem(`page_search_${this.currentPage}`);
    } else {
      this.dynamicPageList = this.originalList.filter(function (d: any) {
        return (
          d.title.toLowerCase().includes(key) ||
          d.metaDescription.toLowerCase().includes(key) ||
          d.metaKeywords.toLowerCase().includes(key) ||
          d.pageSlug.toLowerCase().includes(key)
        );
      });
      localStorage.setItem(`page_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }


  public toggle($event: any, id: any, isValid: any) {
    this.dynamicpageService
      .updateDynamicPage({
        _id: id,
        status: !isValid,
        updatedById: this.userdata.getId(),
        updatedBy: this.userdata.getName(),
      })
      .subscribe(
        (res: any) => {
          // console.log(res)
          this.spinner.hide();
          this.toast.success(`Dynamic Page ${isValid? 'Enabled' : 'Disabled'}`, 'Success');
          this.getDynamicPageList();
        },
        (err) => {
          this.spinner.hide();
          // console.log(err);
          this.toast.error('Something went wrong', 'Error');
        }
      );
  }
}
