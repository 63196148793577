<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Issue Messages</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item"><a>Issue Chat</a></li>
      <li class="breadcrumb-item active" aria-current="page">Chat</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- MODAL VIEW ISSUE-->

  <!-- MODAL VIEW ISSUE END-->
  <!-- ROW-1 OPEN -->
  <div class="row chatbox">
    <div class="col-md-12 col-lg-12 col-xl-12 chat">
      <div class="card">
        <div>
          <ng-template #content let-modal>
            <form [formGroup]="refundGroup" (submit)="refundIssue()">
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                  Customer Details
                </h4>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body row">
                <div class="msb-reply d-flex">
                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <p class="lead">
                        Total Amount:
                        {{ data.amount | currency: "INR":"symbol-narrow" }}
                      </p>
                      <div class="alert alert-info">
                        <label for="">Note:</label>
                        <small
                          >For <b>Full Refund</b> Max amount =
                          {{ data.amount | currency: "INR":"symbol-narrow" }}
                          and for <b>Partial Refund</b> amount should be less
                          than total amount.</small
                        >
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="reply">Refund Type</label>
                        <select
                          class="form-control"
                          (change)="refundType($event)"
                        >
                          <option selected>Wallet Recharge</option>
                          <option>Assign Promo</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="isAmountRefund">
                      <div class="form-group">
                        <label for="reply">Amount</label>
                        <input
                          type="text"
                          class="form-control"
                          name="amount_refunded"
                          formControlName="amount_refunded"
                          placeholder="Only Numbers Allowed"
                        />
                        <div
                          *ngIf="
                            r.amount_refunded.invalid &&
                            (r.amount_refunded.dirty ||
                              r.amount_refunded.touched)
                          "
                          class="alert alert-danger mt-1"
                        >
                          Required. Only Numbers Allowed.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" *ngIf="isPromoRefund">
                      <div class="form-group">
                        <label for="reply">Duration (In Minutes)</label>
                        <input
                          type="text"
                          class="form-control"
                          name="duration"
                          formControlName="duration"
                          placeholder="Only Numbers Allowed"
                        />
                        <div
                          *ngIf="
                            r.duration.invalid &&
                            (r.duration.dirty || r.duration.touched)
                          "
                          class="alert alert-danger mt-1"
                        >
                          Required. Only Numbers Allowed.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" *ngIf="isPromoRefund">
                      <div class="form-group">
                        <label for="">Promo Type</label>
                        <select
                          class="form-control"
                          (change)="promoType($event)"
                        >
                          <option value="" selected disabled>
                            Select Type
                          </option>
                          <option value="call">Promo Call</option>
                          <option value="chat">Promo Chat</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4" *ngIf="isPromoRefund">
                      <div class="form-group">
                        <label for="">Astrologer</label>
                        <select class="form-control" formControlName="astroId">
                          <option value="" selected disabled>
                            Select Astrologer
                          </option>
                          <option
                            [value]="astro._id"
                            *ngFor="let astro of astroList"
                          >
                            {{ astro.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="msb-reply d-flex" *ngIf="">
                  <div class="w-100 text-center alert alert-danger">
                    Order Has Been Completed
                  </div>
                  <!-- CARD FOOTER END -->
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-dark"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  Close
                </button>
                <button
                  type="submit()"
                  class="btn btn-primary mt-1 mb-2"
                  [disabled]="!refundGroup.valid"
                >
                  <i class="fa fa-rss"></i> Submit
                </button>
              </div>
            </form>
          </ng-template>
        </div>
        <!-- ACTION HEDAER OPEN -->
        <div class="action-header clearfix">
          <div class="float-left hidden-xs d-flex ml-2">
            <div class="align-items-center mt-2">
              <h4 class="mb-0 font-weight-semibold">Issue Chat</h4>
            </div>
          </div>
          <ul *ngIf="!type" class="ah-actions actions align-items-center">
            <li class="call-icon">
              <button
                class="btn btn-lg btn-outline-primary"
                (click)="open(content)"
              >
                Refund
              </button>
            </li>
          </ul>
        </div>
        <!-- ACTION HEADER END -->

        <!-- MSG CARD-BODY OPEN -->
        <div
          class="card-body msg_card_body"
          style="overflow-y: scroll !important; height: 400px !important"
          #scrollMe
          [scrollTop]="chatList.length === 0 ? 0 : scrollMe.scrollHeight"
        >
          <ng-container *ngFor="let chat of chatList">
            <div
              *ngIf="chat.isCustomerReply || chat.isAstroReply"
              class="media media-chat"
            >
              <img
              style="border-radius: 50%;"
                class="avatar"
                src="https://img.icons8.com/color/36/000000/administrator-male.png"
                alt="..."
              />
              <div class="media-body admin">
                <p class="inline-block">{{ chat.new_reply }}</p>
                <p class="meta">
                  <time datetime="2018">{{
                    chat.replyDate | date: "medium"
                  }}</time>
                </p>
              </div>
            </div>
            <div
              *ngIf="chat.isSystemReply"
              class="media media-chat media-chat-reverse"
            >
              <div class="media-body reply">
                <p class="float-right">{{ chat.new_reply }}</p>
                <br />
                <p class="meta float-right">
                  <time datetime="2018">{{
                    chat.replyDate | date: "medium"
                  }}</time>
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="publisher bt-1 border-light">
          <form
            class="w-100"
            [formGroup]="formGroup"
            (submit)="sendMsg()"
            (keydown)="checkEnterPressed($event)"
          >
            <div class="row">
              <div class="form-group col-2">
                <label for="reply">Issue Status</label><br />
                <input
                  type="checkbox"
                  name="issue_solved"
                  (change)="isIssueSolved($event)"
                />
                <label class="ml-2">Is Issue Solved?</label>
              </div>
              <textarea
                style="margin-left: -50px !important"
                class="form-control col-10"
                name="reply_by_system"
                formControlName="reply_by_system"
                placeholder="Reply"
              ></textarea>
              <div
                *ngIf="
                  f.reply_by_system.invalid &&
                  (f.reply_by_system.dirty || f.reply_by_system.touched)
                "
                class="alert alert-danger mt-1"
              >
                Required
              </div>

              <button
                [disabled]="!formGroup.valid"
                type="submit"
                title="Send Message"
              >
                <i class="fa fa-paper-plane-o"></i>
              </button>
            </div>
          </form>
        </div>
        <!-- MSG CARD-BODY END -->
      </div>
    </div>
    <!-- COL END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
