<br />
<div style="display: flex; justify-content: space-between" class="pl-2 pr-2">
  <input
  value="{{searchKey}}"
    type="text"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
  <button class="btn btn-primary btn-sm" (click)="generateExcel()"> Generate Excel</button>
</div>
<br />
<div class="card mb-0">
  <div class="card-body ctm-table p-0">
    <ngx-datatable
      class="material"
      [rows]="customersList"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [scrollbarV]="false"
      [limit]="10"
      [sorts]="[{ dir: 'desc' }]"
      style="position: relative !important"
      (page)="onPageChange($event)"
    >
      <ngx-datatable-column [flexGrow]="0" name="ID" prop="customerAutoId">
        <ng-template let-row="row" ngx-datatable-cell-template>
          #{{ row.customerAutoId }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="1" name="Name" prop="name">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="1" name="Phone" prop="phone">
        <ng-template let-row="row" ngx-datatable-cell-template>
          +{{ row.country_code }}-{{ row.phone }}
          <span class="badge badge-pill badge-primary">{{
            row.regCountryName
          }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="email" prop="email">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.email }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="1" name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a
            [routerLink]="['/admin/customers/viewCustomer/', row._id, 'detail']"
            ><i class="fa fa-eye"></i
          ></a>
          &nbsp;
          <a *ngIf="currentPage=='list'" [routerLink]="['/admin/users/changePassword/', row.userId?._id]">
            <!-- <button class="btn btn-primary btn-sm mt-1 mb-2 ml-3"> -->
            <i class="fa fa-key" title="Change Password"></i>
            <!-- </button> -->
          </a>
          <span *ngIf="row.register_otp != 0 && currentPage=='list'">
            &nbsp;({{ row.register_otp }})</span
          >
          <br/>
          <span class="badge badge-primary" *ngIf="row?.is_social_login">
             {{row?.social_type == 1 ? 'Google':row?.social_type == 2?'Facebook':'Apple'}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="1" name="Block Customer">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <!-- Material switch -->
          <ng-template #blocked>
              <mat-slide-toggle color="warn" (change)="toggle($event, row._id, row.userId.is_blocked)" [checked]="true"></mat-slide-toggle>
          </ng-template>
          <ng-template #notBlocked>
              <mat-slide-toggle color="warn" (change)="toggle($event, row._id, row.userId.is_blocked)" [checked]="false"></mat-slide-toggle>
          </ng-template>
          <ng-container *ngIf="row?.is_blocked === true; then blocked; else notBlocked">
          </ng-container>
      </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
      *ngIf="currentPage=='list'"
        [flexGrow]="2"
        name="Registered On"
        prop="created_at"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.created_at | date: "medium" }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
