import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { RemedyServiceService } from 'src/app/admin/services/remedy/remedy-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-suggest-remedy',
  templateUrl: './suggest-remedy.component.html',
  styleUrls: ['./suggest-remedy.component.scss']
})
export class SuggestRemedyComponent implements OnInit {
  orderData: any;
  isRemedySuggested: boolean = false;
  isDirectProduct = false
  suggestNewRemedy = false
  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private remedyService: RemedyServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private orderService: OrderServiceService,
    private astroService: AstroServiceService,
    private route: Router,
    private productMallService: ProductMallService,
    private categoryMallService: CategoryMallService,
    private customizeService: CustomizeServiceService
  ) { }

  data: any = '';
  id: any = '';
  astroList: any = []

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ orderserviceId: this.id })
    this.getCategoriesList()
  }

  formGroup: FormGroup = new FormGroup({
    orderserviceId: new FormControl('', Validators.required),
    remedy_name: new FormControl(''),
    customerId: new FormControl('', Validators.required),
    is_admin_suggest_remedy: new FormControl(true, Validators.required),
    userId: new FormControl('', Validators.required),
    // remedy_link: new FormControl(''),
    // astroId: new FormControl(''),
    isPaid: new FormControl(true, Validators.required),
    suggestedProductId: new FormControl(''),
    suggestedastroId: new FormControl(''),
    remedy_description: new FormControl('', Validators.required)
  });


  getDataById() {
    this.spinner.show()
    this.orderService.getOrderDetail({ _id: this.id, userId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderData = res.data
        this.formGroup.patchValue({ customerId: res.data.customerId?._id });
        if (!this.orderData.is_remedy_suggested) {
          this.isRemedySuggested = false
          this.getRemedyInstruction()
        } else {
          this.isRemedySuggested = true
        }
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  getAstrologerList() {
    this.astroService.getAstrologerList({ is_approved_used: true, is_approved: true }).subscribe(
      (res: any) => {
        this.data = res.data
      }, err => {
        //console.log(err)
      }
    )
  }

  /* ***********
  Add Remedy
 *********** */

  public suggestRemedy() {
    this.spinner.show();
    if (this.isPaid) {
      if (this.selectedCategory == '' || this.selectedCategory == null || this.selectedCategory == undefined) {
        Swal.fire('Invalid Values', 'Please select category first!.', 'error')
        this.spinner.hide();
        return;
      } else
        // alert(this.productsList)
        if (this.productsList.is_astrologer_associate == false) {
          Swal.fire('Invalid Values', 'This product is not associated with any astrologer!', 'error')
          this.spinner.hide();
          return;
        } else if (this.productsList.length > 0 && this.productsList == "" || this.productsList == "" || this.productsList.length > 0 && this.productsList == undefined || this.productsList == undefined || this.productsList.length > 0 && this.productsList == null || this.productsList == null) {
          Swal.fire('Invalid Values', 'Please choose the valid product!.', 'error')
          this.spinner.hide();
          return;
        }
        else if (!this.isDirectProduct && this.selectedAstroId == '') {
          Swal.fire('Invalid Values', 'Please choose the valid astrologer.', 'error')
          this.spinner.hide();
          return;
        }
      // else if (this.productsList.astrologerList == "" || this.productsList.astrologerList == undefined || this.productsList.astrologerList == null) {
      //   Swal.fire('Invalid Values', 'Please choose the valid astrologer.', 'error')
      //   this.spinner.hide();
      //   return;
      // }
    }
    this.remedyService.suggestRemedy(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        // console.log(res)
        this.toast.success('Remedy Suggested!', 'Success');
        this.route.navigateByUrl('admin/remedy')
      },
      err => {
        this.spinner.hide()
        // console.log(err)
        this.toast.error('Something went wrong!', 'Error');
      })
  }

  /**
 * Check if remedy is free of paid
 */
  isPaid = true
  isPaidChcek() {
    this.isPaid = !this.isPaid
    this.formGroup.patchValue({ "isPaid": this.isPaid })
  }


  /** fetch categories list */
  categoriesList: any = []
  getCategoriesList() {
    this.categoryMallService.listMallCategory({}).subscribe(
      (res: any) => {
        this.categoriesList = res.data
      }, err => {
        // console.log(err)
        this.toast.error(err.message, "Error")
      }
    )
  }

  selectedCategory = ''
  suggestedProductId = ''
  selectedAstroId = ''
  getCategory(selectedCategory: any) {
    this.suggestedProductId = ''
    this.selectedAstroId = ''
    this.productsList = []
    this.astroList = []
    this.selectedCategory = selectedCategory
    this.getProductsList()
  }

  onProductChange(selectedProductId: any) {
    console.log("selectedProductId", selectedProductId)
    let index = this.productsList.findIndex((x: any) => x._id == selectedProductId)
    console.log("index", index)
    this.isDirectProduct = this.productsList[index].is_direct_price
    this.astroList = []
    this.astroList = this.productsList[index].astrologerList
    console.log('this.astroList', this.astroList)
  }
  /** fetch products list */
  productsList: any = []
  getProductsList() {
    this.productMallService.listMallProduct({ productcategoryId: this.selectedCategory }).subscribe(
      (res: any) => {
        this.productsList = res.data
        console.log('products----', this.productsList)

        // this.getAstrologerList()
      }, err => {
        // console.log(err)
        this.toast.error(err.message, "Error")
      }
    )
  }

  /** Get Remedy Instruction */
  instruction: any = '';
  getRemedyInstruction() {
    this.customizeService.singleCustomizationByType({ customization_type: 10 }).subscribe(
      (res: any) => {
        this.instruction = res.data.customization_value
      }, err => {
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

  deleteRemedy(id: any) {
    Swal.fire({
      title: 'Delete Suggested Remedy',
      text: 'Are you sure you want to delete suggested remedy?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No.',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.remedyService.deleteRemedy({ _id: id }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success('Suggested Remedy Deleted!', 'Success');
            this.getDataById()
          },
          err => {
            this.spinner.hide()
            this.toast.error('Something went wrong!', 'Error');
          })
      }
    });
  }

}
