import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-astrologer-issue',
  templateUrl: './astrologer-issue.component.html',
  styleUrls: ['./astrologer-issue.component.scss']
})
export class AstrologerIssueComponent implements OnInit {
  searchKey = ''
  currentPage = ''
  constructor(
    private issueService: IssueServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private _route:Router,
    private router: ActivatedRoute
  ) { 
this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  issueList = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];
  walletCount:Number = 0
  serviceCount:Number = 0
  pendingCount:Number = 0
  solvedCount:Number = 0

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    // this.walletIssue()
    // this.serviceIssue()
    // this.solvedIssue()
    // this.pendingIssue()
    this.removeSearch()
    this.getAllAstrologerIssues({ 'userId': this.userdata.getId() })
  }
  removeSearch() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key.includes('issue_search_')) {
        if (key != `issue_search_${this.currentPage}`) {
          localStorage.removeItem(key);
        } else {
          this.searchKey = localStorage.getItem(key) || '';
        }
      }
    }
  }
  /* ***********
  list All Astrologer Issues
  *********** */

  getAllAstrologerIssues(data:any) {
    this.spinner.show()
    this.issueService.getAllAstrologerIssues(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        //console.log(res)
        this.issueList = res.data;
        this.originalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset =
          localStorage.getItem(`issue_offset_${this.currentPage}`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }


  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.issueList = this.originalList;
      localStorage.removeItem(`issue_search_${this.currentPage}`);
    } else {
      this.issueList = this.originalList.filter(function (d: any) {
        return (
           d.astroId?.name.toLowerCase().includes(key) ||
        d.astroId?.displayname.toLowerCase().includes(key) ||
        d.astroId?.astroAutoId.toString().includes(key) ||
        d.issueId.toString().includes(key)
        );
      });
      localStorage.setItem(`issue_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`issue_offset_${this.currentPage}`, event.offset);
  }
  /**
   * Wallet Issues
   */
  walletIssue() {
    this.getAllAstrologerIssues({ 'userId': this.userdata.getId(), 'is_wallet_issue': true })
  }

  /**
   * Service Issue
   */
  serviceIssue() {
    this.getAllAstrologerIssues({ 'userId': this.userdata.getId(), 'is_service_issue': true })
  }

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue() {
    this.getAllAstrologerIssues({ 'userId': this.userdata.getId(), 'is_issue_solved': false })
  }

  /**
   * Solved Issue
   */
  solvedIssue() {
    this.getAllAstrologerIssues({ 'userId': this.userdata.getId(), 'is_issue_solved': true })
  }

  messageChatClick(id:any,isSystem:Boolean){
    if(isSystem){
      this._route.navigate(['/admin/issue/message',id, "astro"])
    }else{
      this._route.navigate(['/admin/issues/astrologer/reply',id])
    }
  }
}
