<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Banner</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Banner</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>Banner List</a>
                    <ng-template ngbNavContent>
                      <br>
                      <div class="row pr-2 pl-2">
                        <div class="col-md-12 col-xl-12 mb-2">
                          <button class="btn btn-primary mr-2" (click)="astrologerBannerWeb()">Filter Astrologer Web Banner</button>
                          <button class="btn btn-warning mr-2" (click)="astrologerBannerMobile()">Filter Astrologer App Banner</button>
                          <button class="btn btn-info mr-2" (click)="customerBannerWeb()">Filter Customer Web Banner</button>
                          <button class="btn btn-success mr-2" (click)="customerBannerMobile()">Filter Customer App Banner</button>
                        </div>
                        <div class="col-md-4 col-xl-4" *ngFor="let banner of data">
                          <div class="card ">
                            <div class="card-body text-left">
                              <img *ngIf="banner?.bannerInfo?.is_desktop_banner" style="width:100%;height:150px;" [src]=getSanitizeUrl(banner?.bannerInfo.banner_image)
                                alt="Astro Image" class="img img-fluid">
                                <img *ngIf="banner?.bannerInfo?.is_mobile_banner" style="width:100%;height:150px;" [src]=getSanitizeUrl(banner?.bannerInfo.mobile_banner_image)
                                alt="Astro Image" class="img img-fluid">
                              <!-- <div class="d-flex justify-content-between">
                                <img height="150px" [src]=getSanitizeUrl(banner.bannerInfo.banner_image)
                                alt="Astro Image" class="col-6 mb-2">
                                <img height="150px" [src]=getSanitizeUrl(banner.bannerInfo.mobile_banner_image)
                                alt="Astro Image" class="col-6 mb-2">
                              </div> -->
                              <div class="d-flex justify-content-between">
                                <div>
                                  Status
                                  <!-- Material switch -->
                                  <ng-template #blocked>
                                    <mat-slide-toggle color="warn"
                                      (change)="toggle($event, banner.bannerInfo._id, banner.bannerInfo.banner_status)"
                                      [checked]="true"></mat-slide-toggle>
                                  </ng-template>
                                  <ng-template #notBlocked>
                                    <mat-slide-toggle color="warn" [checked]="false"
                                      (change)="toggle($event, banner.bannerInfo._id, banner.bannerInfo.banner_status)">
                                    </mat-slide-toggle>
                                  </ng-template>
                                  <ng-container
                                    *ngIf="banner?.bannerInfo?.banner_status === true; then blocked; else notBlocked">
                                  </ng-container>
                                </div>
                                <small class="text-muted">
                                  <a (click)="deleteBanner($event, banner.bannerInfo._id)" role="button"><i
                                      class="fa fa-trash"></i></a>
                                </small>
                              </div>
                              <div class="quote">
                                <p><strong class="mr-2">Banner Type :</strong>
                                  <ng-template #astro><span class="badge badge-primary">Astrologer Banner</span>
                                  </ng-template>
                                  <ng-template #customer><span class="badge badge-info">Customer Banner</span>
                                  </ng-template>
                                  <ng-container
                                    *ngIf="banner?.bannerInfo?.is_astro_banner === true; then astro; else customer">
                                  </ng-container>
                                </p>
                                <p><strong class="mr-2">Banner Image Type :</strong>
                                  <ng-template #desktop><span class="badge badge-primary">Web</span>
                                  </ng-template>
                                  <ng-template #mobile><span class="badge badge-info">App</span>
                                  </ng-template>
                                  <ng-container
                                    *ngIf="banner?.bannerInfo?.is_desktop_banner === true; then desktop; else mobile">
                                  </ng-container>
                                </p>
                                <p *ngIf="banner?.bannerInfo?.is_link || banner?.bannerInfo?.is_astro || banner?.bannerInfo?.is_product">
                                  <strong class="mr-2">Link :</strong> <span
                                    *ngIf="banner?.bannerInfo?.is_astro">{{banner.astrodetail?.name}}</span><span
                                    *ngIf="banner?.bannerInfo?.is_link">{{banner.bannerInfo.link_value}}</span>
                                    <span
                                    *ngIf="banner?.bannerInfo?.is_product">{{banner.productdetail?.productname}}</span>
                                  </p>
                                  <p *ngIf="(!banner?.bannerInfo?.is_link && !banner?.bannerInfo?.is_astro && !banner?.bannerInfo?.is_product) && banner?.bannerInfo?.bannerType != ''">
                                    <strong class="mr-2">Link :</strong> <span>{{banner?.bannerInfo?.bannerType == 4 ? 'Call': banner?.bannerInfo?.bannerType == 5 ? 'Chat' : banner?.bannerInfo?.bannerType == 6 ? 'Query' : banner?.bannerInfo?.bannerType == 7 ? 'Report' :banner?.bannerInfo?.bannerType == 8 ? 'Wallet' : 'Recharge'}}</span>
                                    </p>
                              </div>
                            </div>
                          </div>
                        </div><!-- COL END -->
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Add Banner</a>
                    <ng-template ngbNavContent>
                      <app-add-banner></app-add-banner>
                    </ng-template>
                  </li>
                  <!-- <li ngbNavItem>
                    <a ngbNavLink>Astrologer Banner</a>
                    <ng-template ngbNavContent>
                      <br>
                      <div class="wideget-user-followers mb-0">
                        <app-add-edit-banner></app-add-edit-banner>
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Customer Banner</a>
                    <ng-template ngbNavContent>
                      <br>
                      <div class="wideget-user-followers mb-0">
                        <app-add-edit-banner></app-add-edit-banner>
                      </div>
                    </ng-template>
                  </li> -->
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->