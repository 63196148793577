<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Customer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item" aria-current="page"><a (click)="goBack()">Customer List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Detail</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="wideget-user">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="p-4">Customer - <span class="text-uppercase">{{data?.name}}</span><span class="text-uppercase badge badge-primary">{{data?.regCountryName}}</span></h4>
                        </div>
                    </div>
                </div>
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul class="nav-tabs nav mb-4">
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'detail']" class="nav-link"
                                            routerLinkActive="active">Details</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'wallet']" class="nav-link"
                                            routerLinkActive="active">Wallet</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'add-amount']" class="nav-link"
                                            routerLinkActive="active">Add Amount</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'deduct-amount']" class="nav-link"
                                            routerLinkActive="active">Deduct Amount</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'all-order']" class="nav-link"
                                            routerLinkActive="active">All Orders</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'call']" class="nav-link"
                                            routerLinkActive="active">Call</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'chat']" class="nav-link"
                                            routerLinkActive="active">Chat</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'query']" class="nav-link"
                                            routerLinkActive="active">Query</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'report']" class="nav-link"
                                            routerLinkActive="active">Report</a>
                                    </li>                                    
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'recharge']" class="nav-link"
                                            routerLinkActive="active">Recharges</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'support']" class="nav-link"
                                            routerLinkActive="active">Support</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'issue']" class="nav-link"
                                            routerLinkActive="active">Order Issues</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/customers/viewCustomer/',id,'log']" class="nav-link"
                                            routerLinkActive="active">Logs</a>
                                    </li>
                                </ul>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
