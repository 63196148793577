import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-common-blog',
  templateUrl: './edit-common-blog.component.html',
  styleUrls: ['./edit-common-blog.component.scss']
})
export class EditCommonBlogComponent implements OnInit {

  dataList:any = []
  blogList:any = {}
  id:any =''
  BaseUrl: any;
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private blogService: BlogServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private clipboard: Clipboard,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
    }

  getBlogCategoryList() {
    this.spinner.show()
    this.blogService.getBlogCategory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  getSingleBlogList() {
    this.spinner.show()
    this.blogService.getSingleBlog({_id:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.blogList = res.data;
        this.formGroup.patchValue({_id:this.blogList._id})
        this.formGroup.patchValue({blogTitle:this.blogList.blogTitle})
        this.formGroup.patchValue({blogDescription:this.blogList.blogDescription})
        this.formGroup.patchValue({blogsmallDescription:this.blogList.blogsmallDescription})
        this.formGroup.patchValue({slug:this.blogList.slug})
        this.formGroup.patchValue({categoryId:this.blogList.categoryId._id})
        this.formGroup.patchValue({astroId:this.userdata.getId()})
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }
  
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    blogTitle: new FormControl('', Validators.required),
    blogDescription: new FormControl('', Validators.required),
    blogsmallDescription: new FormControl('', Validators.required),
    blogImage: new FormControl(''),
    astroId: new FormControl('', [Validators.required]),
    categoryId: new FormControl('', Validators.required),
    slug: new FormControl('', [Validators.required,Validators.pattern('^[a-z0-9A-Z]+(?:-[a-z0-9A-Z]+)*$')]),
  });

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getBlogCategoryList()
    this.getSingleBlogList()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Blog Image
   */
   imageError: string = ''
   imageSrc = ''
   public onBannerUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;
 
       reader.readAsDataURL(file);    
 
       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;    
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Banner dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              blogImage: event.target.files[0]
             })
           }
         }
       };
     }
   }

   updateBlog(){
    const data = new FormData()
    data.append("blogTitle", this.formGroup.get("blogTitle")?.value)
    data.append("blogDescription", this.formGroup.get("blogDescription")?.value)
    data.append("blogsmallDescription", this.formGroup.get("blogsmallDescription")?.value)
    data.append("blogImage", this.formGroup.get("blogImage")?.value)
    data.append("astroId", this.formGroup.get("astroId")?.value)
    data.append("categoryId", this.formGroup.get("categoryId")?.value)
    data.append("slug", this.formGroup.get("slug")?.value)
    data.append("_id", this.formGroup.get("_id")?.value)
    this.blogService.updateBlog(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/blog/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  /**
   * Direct Image Upload for blog
   */
   imageGroup: FormGroup = new FormGroup({
    image: new FormControl('')
  });
  imageDirectError: string = ''
  imageDirectSrc = ''
  directPath: String = ''
  public onDirectUpload(event: any) {
    this.imageDirectError = ''
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageDirectError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }else{
        this.imageGroup.patchValue({
          image: event.target.files[0]
        })
        //console.log(this.BaseUrl)
      }
    }
  }

  addDirectImage() {
    const data = new FormData()
    data.append("image", this.imageGroup.get("image")?.value)
    this.blogService.addBlogImage(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.imageGroup.patchValue({
          image: ''
        })
        this.directPath = this.BaseUrl + res.result
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  copyImageName() {
    this.clipboard.copy(String(this.directPath));
    this.toast.success('Path Copied!')
  }
  example_image_upload_handler(blobInfo: any, success: any, failure: any, progress: any) {

    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    var token = sessionStorage.getItem('token')??""
    var header_object = new HttpHeaders().set("Authorization", token);
    const data = new FormData()
    data.append("image",blobInfo.blob());
    let request = httpClient.post<any>("https://apistagging.astroriver.com/admin/addblogimages",data,{headers:header_object});
    request.subscribe(res=>{
      //console.log("res",res)
      if(res.success){
        success(`https://apistagging.astroriver.com/${res.result}`)
      }else{
        failure("not uploaded")
      }
      
    },err=> {
      failure(err);
    })
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }
}
