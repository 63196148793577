import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';

@Component({
  selector: 'app-astro-charges-update',
  templateUrl: './astro-charges-update.component.html',
  styleUrls: ['./astro-charges-update.component.scss']
})
export class AstroChargesUpdateComponent implements OnInit {

  id: any = ''
  data: any = ''
  permotionalAllow: any = false
  checkedPromotional: any
  chargeLogs: any = []
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  updateChargesForm: any = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    is_promotional_allow: new FormControl(''),
    promocall_totake: new FormControl(''),
    call_charges: new FormControl('', [Validators.required]),
    chat_charges: new FormControl('', [Validators.required]),
    query_charges: new FormControl('', [Validators.required]),
    report_charges: new FormControl('', [Validators.required]),
  })

  constructor(
    private spinner: NgxSpinnerService,
    private astroService: AstroServiceService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private internationalService: InternationalServiceService
  ) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.updateChargesForm.patchValue({ _id: this.id })
    this.getDataById()
  }

  get f() {
    return this.updateChargesForm.controls
  }

  togglePermotionalDeatails(e: any) {
    this.permotionalAllow = false
    if (e.srcElement.checked == true) {
      this.permotionalAllow = true
    }
  }

  public onUpdateCharges() {
    this.spinner.show()
    this.astroService.updateAstrologerCharges(this.updateChargesForm.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Sucess')
        this.reloadCurrentRoute()
        //console.log(res)
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Something went wrong!', 'Error')
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }

  getDataById() {
    this.spinner.show()
    this.astroService.getAstrologerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.chargeLogs = res.data.charges_logs.reverse()
        this.permotionalAllow = this.data.is_promotional_allow;
        this.updateChargesForm.patchValue({ is_promotional_allow: this.data.is_promotional_allow })
        this.updateChargesForm.patchValue({ promocall_totake: this.data.promocall_totake })
        this.updateChargesForm.patchValue({ call_charges: this.data.call_charges })
        this.updateChargesForm.patchValue({ chat_charges: this.data.chat_charges })
        this.updateChargesForm.patchValue({ query_charges: this.data.query_charges })
        this.updateChargesForm.patchValue({ report_charges: this.data.report_charges })
        this.getInternationalPricing()
      },
      err => {
        this.spinner.hide()
        //console.log(err)

      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  dataList: any;
  internationalCallPrice: any; internationalChatPrice: any; internationalQueryPrice: any; internationalReportPrice: any;
  getInternationalPricing() {
    this.spinner.show();
    this.internationalService.listInternationalPrice({}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.dataList = res.data;
        this.getInternationalPrice(this.dataList[0]._id)
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  getValue(event: any) {
    var data = event.target.value
    this.getInternationalPrice(data)
  }

  getInternationalPrice(data: any) {
    var obj = this.dataList.find((d: any) => d._id == data)
    //console.log(obj)
    var chatpricePercent = 0, callpricePercent = 0, queryPricePercent = 0, reportPricePercent = 0
    chatpricePercent = obj.chatPricePercent
    callpricePercent = obj.callPricePercent
    queryPricePercent = obj.queryPricePercent
    reportPricePercent = obj.reportPricePercent
    this.internationalChatPrice = obj.currencySymbol+((chatpricePercent*this.data.chat_charges*2) / obj.currencyUnitInRupees).toFixed(2)
    this.internationalCallPrice = obj.currencySymbol+((callpricePercent*this.data.call_charges*2) / obj.currencyUnitInRupees).toFixed(2)
    this.internationalQueryPrice = obj.currencySymbol+((queryPricePercent*this.data.query_charges*2) / obj.currencyUnitInRupees).toFixed(2)
    this.internationalReportPrice = obj.currencySymbol+((reportPricePercent*this.data.report_charges*2) / obj.currencyUnitInRupees).toFixed(2)
  }
}
