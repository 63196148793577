<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Dynamic Page</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Dynamic Page</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>List</a>
                    <ng-template ngbNavContent>
                      <div class="pr-2 pl-2 d-flex justify-content-between">
                        <input
                          value="{{ searchKey }}"
                          type="text"
                          placeholder="Type to filter the name column..."
                          class="form-control col-lg-4 mt-1"
                          (keyup)="updateFilter($event)"
                        />
                      </div>
                      <br />
                      <ngx-datatable
                        (page)="onPageChange($event)"
                        class="material"
                        [rows]="dynamicPageList"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [rowHeight]="'auto'"
                        [scrollbarV]="false"
                        [limit]="10"
                        [sorts]="[{ dir: 'desc' }]"
                        style="position: relative !important"
                      >
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Title"
                          prop="title"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.title }}
                          </ng-template>
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-column
                          [flexGrow]="2"
                          name="Meta Description"
                          prop="metaDescription"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.metaDescription }}
                          </ng-template>
                        </ngx-datatable-column> -->
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Meta Keywords"
                          prop="metaKeywords"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.metaKeywords }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Page Slug"
                          prop="pageSlug"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.pageSlug }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="URL"
                          prop="url"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ BaseUrl }}{{ row.pageSlug }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Disable Page"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <span *ngIf="row.status">
                              <mat-slide-toggle
                                color="warn"
                                (change)="toggle($event, row._id, false)"
                                [checked]="true"
                              ></mat-slide-toggle>
                            </span>
                            <span *ngIf="!row.status">
                              <mat-slide-toggle
                                color="warn"
                                (change)="toggle($event, row._id, true)"
                                [checked]="false"
                              ></mat-slide-toggle>
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Action">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <a
                              [routerLink]="['/admin/dynamic-page/', row._id]"
                              class="fa fa-eye text-primary mr-1"
                            ></a>
                            <a
                              [routerLink]="[
                                '/admin/dynamic-page/edit/',
                                row._id
                              ]"
                              class="
                                btn-table-custom btn btn-sm btn-outline-primary
                              "
                              >Edit</a
                            >
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Add</a>
                    <ng-template ngbNavContent>
                      <br />
                      <div class="wideget-user-followers mb-0">
                        <app-add-dynamic-page></app-add-dynamic-page>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
