import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

  id: any = ''
  data: any = ''

  editCustomerForm: any = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{7,10}')]),
    customer_id: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    is_social_login: new FormControl(false),
    country_code : new FormControl('',[Validators.required])
  })

  constructor(
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private route: Router,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.editCustomerForm.patchValue({ _id: this.id })
  }

  get f() {
    return this.editCustomerForm.controls
  }

  getDataById() {
    this.spinner.show()
    this.customerService.getCustomerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        // console.log(res.data)
        this.data = res.data
        this.editCustomerForm.patchValue({ customer_id: this.data.customerAutoId})
        this.editCustomerForm.patchValue({ name: this.data.name })
        this.editCustomerForm.patchValue({ email: this.data.email })
        this.editCustomerForm.patchValue({ phone: this.data.phone })
        this.editCustomerForm.patchValue({ country_code: this.data.country_code })
        this.editCustomerForm.patchValue({ is_social_login: this.data.is_social_login })

      },
      err => {
        this.spinner.hide()
        // console.log(err)
      }
    )
  }

  public onEditCustomerSubmit() {
    this.spinner.show()
    this.customerService.updateCustomer(this.editCustomerForm.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success('Customer Updated!', 'Success')
        this.route.navigateByUrl('/admin/customers/viewCustomer/' + this.id +'/detail')
      },
      err => {
        this.spinner.hide()
        // console.log(err)
        // this.toast.error('Something went wrong!', 'Error')
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

}
