<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->

    <div class="row">
        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
            <form [formGroup]="formGroup" (submit)="addPriceSubmit()">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add International Pricing</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="categoryname">Country Name</label>
                                    <ng-select placeholder="Select Country" appendTo="body" [searchable]="true"
                                        [clearable]="true" formControlName="countryName"
                                        (change)="selectedCountry($event)">
                                        <ng-option selected disabled value=''>Select Country</ng-option>
                                        <ng-option [value]="country" *ngFor="let country of countryData">
                                            {{country.emoji}}&nbsp;{{country.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="countryCode">Country Code</label>
                                    <input type="text" class="form-control" readonly="readonly" formControlName="countryCode" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="currencySymbol">Currency Symbol</label>
                                    <input type="text" class="form-control" formControlName="currencySymbol" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="currencySymbol">Currency Code</label>
                                    <input type="text" class="form-control" formControlName="currencyCode" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="currencyUnitInRupees">Currency Value In Rupees</label>
                                    <input type="text" class="form-control" formControlName="currencyUnitInRupees" />
                                    <small
                                        *ngIf="f.currencyUnitInRupees.invalid && (f.currencyUnitInRupees.dirty || f.currencyUnitInRupees.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Only Numbers
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="callPricePercent">Call Price Change (%)</label>
                                    <input type="text" class="form-control" formControlName="callPricePercent" />
                                    <small
                                        *ngIf="f.callPricePercent.invalid && (f.callPricePercent.dirty || f.callPricePercent.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Only Numbers
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="chatPricePercent">Chat Price Change (%)</label>
                                    <input type="text" class="form-control" formControlName="chatPricePercent" />
                                    <small
                                        *ngIf="f.chatPricePercent.invalid && (f.chatPricePercent.dirty || f.chatPricePercent.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Only Numbers
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="queryPricePercent">Query Price Change (%)</label>
                                    <input type="text" class="form-control" formControlName="queryPricePercent" />
                                    <small
                                        *ngIf="f.queryPricePercent.invalid && (f.queryPricePercent.dirty || f.queryPricePercent.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Only Numbers
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="reportPricePercent">Report Price Change (%)</label>
                                    <input type="text" class="form-control" formControlName="reportPricePercent" />
                                    <small
                                        *ngIf="f.reportPricePercent.invalid && (f.reportPricePercent.dirty || f.reportPricePercent.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Only Numbers
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1"
                            [disabled]="!formGroup.valid">Save</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
            <form [formGroup]="calculateFormGroup" (submit)="calculatePrice()">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Calculate International Pricing</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="currencyUnitInRupees">Currency Value In Rupees</label>
                                <input type="text" class="form-control" formControlName="currencyUnitInRupees" />
                                <small
                                    *ngIf="f.currencyUnitInRupees.invalid && (f.currencyUnitInRupees.dirty || f.currencyUnitInRupees.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="callPricePercent">Call Price Change (%)</label>
                                <input type="text" class="form-control" formControlName="callPricePercent" />
                                <small
                                    *ngIf="f.callPricePercent.invalid && (f.callPricePercent.dirty || f.callPricePercent.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="chatPricePercent">Chat Price Change (%)</label>
                                <input type="text" class="form-control" formControlName="chatPricePercent" />
                                <small
                                    *ngIf="f.chatPricePercent.invalid && (f.chatPricePercent.dirty || f.chatPricePercent.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="queryPricePercent">Query Price Change (%)</label>
                                <input type="text" class="form-control" formControlName="queryPricePercent" />
                                <small
                                    *ngIf="f.queryPricePercent.invalid && (f.queryPricePercent.dirty || f.queryPricePercent.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="reportPricePercent">Report Price Change (%)</label>
                                <input type="text" class="form-control" formControlName="reportPricePercent" />
                                <small
                                    *ngIf="f.reportPricePercent.invalid && (f.reportPricePercent.dirty || f.reportPricePercent.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="astroPrice">Astro Price</label>
                                <input type="text" class="form-control" formControlName="astroPrice" />
                                <small
                                    *ngIf="c.astroPrice.invalid && (c.astroPrice.dirty || c.astroPrice.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="isExclusiveAstro">Exclusive Astro</label>
                                <input type="checkbox"  class="ml-2" formControlName="isExclusiveAstro" />
                                <small
                                    *ngIf="c.isExclusiveAstro.invalid && (c.isExclusiveAstro.dirty || c.isExclusiveAstro.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required. Only Numbers
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1" [disabled]="calculateFormGroup.invalid">Calculate</button>
                    </div>
                </div>
                <div class="card-footer" *ngIf="convertedPrice">
                    <table class="table table-bordered">
                        <tr>
                            <th>Type</th>
                            <th>Foreign Display Price</th>
                            <th>Astro Share</th>
                            <th>AR Share</th>
                        </tr>
                        <tr>
                            <th>Call</th>
                            <td>{{convertedData.convertedCallPrice}}</td>
                            <td>{{convertedData.astroShareCall | currency:'INR':'symbol-narrow'}}</td>
                            <td>{{convertedData.adminShareCall | currency:'INR':'symbol-narrow'}}</td>
                        </tr>
                        <tr>
                            <th>Chat</th>
                            <td>{{convertedData.convertedChatPrice}}</td>
                            <td>{{convertedData.astroShareChat | currency:'INR':'symbol-narrow'}}</td>
                            <td>{{convertedData.adminShareChat | currency:'INR':'symbol-narrow'}}</td>
                        </tr>
                        <tr>
                            <th>Query</th>
                            <td>{{convertedData.convertedQueryPrice }}</td>
                            <td>{{convertedData.astroShareQuery | currency:'INR':'symbol-narrow'}}</td>
                            <td>{{convertedData.adminShareQuery | currency:'INR':'symbol-narrow'}}</td>
                        </tr>
                        <tr>
                            <th>Report</th>
                            <td>{{convertedData.convertedReportPrice}}</td>
                            <td>{{convertedData.astroShareReport | currency:'INR':'symbol-narrow'}}</td>
                            <td>{{convertedData.adminShareReport | currency:'INR':'symbol-narrow'}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            </form>
        </div>
    </div>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->
