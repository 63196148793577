<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- ROW-1 OPEN -->
    <form [formGroup]="addManagerForm" (submit)="onAddManagerSubmit()" >
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Manager</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname">Full Name *</label>
                                    <input type="text" class="form-control" form
                                        placeholder="Full Name" formControlName="name">
                                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email *</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                        placeholder="Email address" formControlName="email">
                                    <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname1">Password *</label>
                                    <input type="password" class="form-control"
                                        placeholder="Password" formControlName="password">
                                    <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger mt-1">
                                        Required. Password should contains minimum 1 special symbol, 1 uppercase letter, 1 lowercase letter, 1 number and length not less than 8 characters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputnumber">Contact Number *</label>
                                    <input type="text" class="form-control" id="exampleInputnumber"
                                        placeholder="Phone number" formControlName="phone">
                                    <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="alert alert-danger mt-1">
                                        Required and Invalid Phone Number
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <label for="exampleInputname1">Gender *</label>
                                <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                                    <select class="form-control" formControlName="gender">
                                        <option selected disabled value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label class="">Date Of Birth *</label>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-12 col-xl-4 col-sm-12">
                                            <select class="form-control" formControlName="birth_date">
                                                <option selected disabled value="">Date</option>
                                                <option value="1">01</option>
                                                <option value="2">02</option>
                                                <option value="3">03</option>
                                                <option value="4">04</option>
                                                <option value="5">05</option>
                                                <option value="6">06</option>
                                                <option value="7">07</option>
                                                <option value="8">08</option>
                                                <option value="9">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-xl-4 col-sm-12">
                                            <select class="form-control" formControlName="birth_month">
                                                <option selected disabled value="">Month</option>
                                                <option value="01">Jan</option>
                                                <option value="02">Feb</option>
                                                <option value="03">Mar</option>
                                                <option value="04">Apr</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">Aug</option>
                                                <option value="09">Sep</option>
                                                <option value="10">Oct</option>
                                                <option value="11">Nov</option>
                                                <option value="12">Dec</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-xl-4 col-sm-12">
                                            <select class="form-control" formControlName="birth_year">
                                                <option selected disabled value="">Year</option>
                                                <option>Year</option>
                                                <option>2021</option>
                                                <option>2020</option>
                                                <option>2019</option>
                                                <option>2018</option>
                                                <option>2017</option>
                                                <option>2016</option>
                                                <option>2015</option>
                                                <option>2014</option>
                                                <option>2013</option>
                                                <option>2012</option>
                                                <option>2012</option>
                                                <option>2011</option>
                                                <option>2010</option>
                                                <option>2009</option>
                                                <option>2008</option>
                                                <option>2007</option>
                                                <option>2006</option>
                                                <option>2005</option>
                                                <option>2004</option>
                                                <option>2003</option>
                                                <option>2002</option>
                                                <option>2001</option>
                                                <option>2000</option>
                                                <option>1999</option>
                                                <option>1998</option>
                                                <option>1997</option>
                                                <option>1996</option>
                                                <option>1995</option>
                                                <option>1994</option>
                                                <option>1993</option>
                                                <option>1992</option>
                                                <option>1991</option>
                                                <option>1990</option>
                                                <option>1989</option>
                                                <option>1988</option>
                                                <option>1987</option>
                                                <option>1986</option>
                                                <option>1985</option>
                                                <option>1984</option>
                                                <option>1983</option>
                                                <option>1982</option>
                                                <option>1981</option>
                                                <option>1980</option>
                                                <option>1979</option>
                                                <option>1978</option>
                                                <option>1977</option>
                                                <option>1976</option>
                                                <option>1975</option>
                                                <option>1974</option>
                                                <option>1973</option>
                                                <option>1972</option>
                                                <option>1971</option>
                                                <option>1970</option>
                                                <option>1969</option>
                                                <option>1968</option>
                                                <option>1967</option>
                                                <option>1966</option>
                                                <option>1965</option>
                                                <option>1964</option>
                                                <option>1963</option>
                                                <option>1962</option>
                                                <option>1961</option>
                                                <option>1960</option>
                                                <option>1959</option>
                                                <option>1958</option>
                                                <option>1957</option>
                                                <option>1956</option>
                                                <option>1955</option>
                                                <option>1954</option>
                                                <option>1953</option>
                                                <option>1952</option>
                                                <option>1951</option>
                                                <option>1950</option>
                                                <option>1949</option>
                                                <option>1948</option>
                                                <option>1947</option>
                                                <option>1946</option>
                                                <option>1945</option>
                                                <option>1944</option>
                                                <option>1943</option>
                                                <option>1942</option>
                                                <option>1941</option>
                                                <option>1940</option>
                                                <option>1939</option>
                                                <option>1938</option>
                                                <option>1937</option>
                                                <option>1936</option>
                                                <option>1935</option>
                                                <option>1934</option>
                                                <option>1933</option>
                                                <option>1932</option>
                                                <option>1931</option>
                                                <option>1930</option>
                                                <option>1929</option>
                                                <option>1928</option>
                                                <option>1927</option>
                                                <option>1926</option>
                                                <option>1925</option>
                                                <option>1924</option>
                                                <option>1923</option>
                                                <option>1922</option>
                                                <option>1921</option>
                                                <option>1920</option>
                                                <option>1919</option>
                                                <option>1918</option>
                                                <option>1917</option>
                                                <option>1916</option>
                                                <option>1915</option>
                                                <option>1914</option>
                                                <option>1913</option>
                                                <option>1912</option>
                                                <option>1911</option>
                                                <option>1910</option>
                                                <option>1909</option>
                                                <option>1908</option>
                                                <option>1907</option>
                                                <option>1906</option>
                                                <option>1905</option>
                                                <option>1904</option>
                                                <option>1903</option>
                                                <option>1902</option>
                                                <option>1901</option>
                                                <option>1900</option>
                                                <option>1899</option>
                                                <option>1898</option>
                                                <option>1897</option>
                                                <option>1896</option>
                                                <option>1895</option>
                                                <option>1894</option>
                                                <option>1893</option>
                                                <option>1892</option>
                                                <option>1891</option>
                                                <option>1890</option>
                                                <option>1889</option>
                                                <option>1888</option>
                                                <option>1887</option>
                                                <option>1886</option>
                                                <option>1885</option>
                                                <option>1884</option>
                                                <option>1883</option>
                                                <option>1882</option>
                                                <option>1881</option>
                                                <option>1880</option>
                                                <option>1879</option>
                                                <option>1878</option>
                                                <option>1877</option>
                                                <option>1876</option>
                                                <option>1875</option>
                                                <option>1874</option>
                                                <option>1873</option>
                                                <option>1872</option>
                                                <option>1871</option>
                                                <option>1870</option>
                                                <option>1869</option>
                                                <option>1868</option>
                                                <option>1867</option>
                                                <option>1866</option>
                                                <option>1865</option>
                                                <option>1864</option>
                                                <option>1863</option>
                                                <option>1862</option>
                                                <option>1861</option>
                                                <option>1860</option>
                                                <option>1859</option>
                                                <option>1858</option>
                                                <option>1857</option>
                                                <option>1856</option>
                                                <option>1855</option>
                                                <option>1854</option>
                                                <option>1853</option>
                                                <option>1852</option>
                                                <option>1851</option>
                                                <option>1850</option>
                                                <option>1849</option>
                                                <option>1848</option>
                                                <option>1847</option>
                                                <option>1846</option>
                                                <option>1845</option>
                                                <option>1844</option>
                                                <option>1843</option>
                                                <option>1842</option>
                                                <option>1841</option>
                                                <option>1840</option>
                                                <option>1839</option>
                                                <option>1838</option>
                                                <option>1837</option>
                                                <option>1836</option>
                                                <option>1835</option>
                                                <option>1834</option>
                                                <option>1833</option>
                                                <option>1832</option>
                                                <option>1831</option>
                                                <option>1830</option>
                                                <option>1829</option>
                                                <option>1828</option>
                                                <option>1827</option>
                                                <option>1826</option>
                                                <option>1825</option>
                                                <option>1824</option>
                                                <option>1823</option>
                                                <option>1822</option>
                                                <option>1821</option>
                                                <option>1820</option>
                                                <option>1819</option>
                                                <option>1818</option>
                                                <option>1817</option>
                                                <option>1816</option>
                                                <option>1815</option>
                                                <option>1814</option>
                                                <option>1813</option>
                                                <option>1812</option>
                                                <option>1811</option>
                                                <option>1810</option>
                                                <option>1809</option>
                                                <option>1808</option>
                                                <option>1807</option>
                                                <option>1806</option>
                                                <option>1805</option>
                                                <option>1804</option>
                                                <option>1803</option>
                                                <option>1802</option>
                                                <option>1801</option>
                                                <option>1800</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="complete_address">Complete Address *</label>
                                    <textarea class="form-control" placeholder="Address" formControlName="complete_address"></textarea>
                                    <div *ngIf="f.complete_address.invalid && (f.complete_address.dirty || f.complete_address.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="roleId">Assign Role *</label>
                                    <select class="form-control" formControlName="roleId">
                                        <option selected disabled value="">Select Role</option>
                                        <option [value]="role._id" *ngFor="let role of roleList">{{role.roles_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="profile_image">Profile Image *</label>
                                    <input type="file" class="form-control" (change)="profileImage($event)"/>
                                    <div *ngIf="profileError != null && profileError != ''" class="alert alert-danger mt-1">
                                        {{profileError}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="proof_image">Proof Image *</label>
                                    <input type="file" class="form-control" (change)="proofImage($event)"/>
                                    <div *ngIf="proofError != null && proofError != ''" class="alert alert-danger mt-1">
                                        {{proofError}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1" [disabled]="!addManagerForm.valid">Save</button>
                        <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                    </div>
                </div>

            </div>

        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->
