import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { ExcelServiceService } from 'src/app/admin/services/excel-service/excel-service.service';

@Component({
  selector: 'app-astrologers-list',
  templateUrl: './astrologers-list.component.html',
  styleUrls: ['./astrologers-list.component.scss'],
})
export class AstrologersListComponent implements OnInit {
  currentPage = '';
  BaseUrl=''
  constructor(
    private astroService: AstroServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private trusturl: DomSanitizer,
    private excelService: ExcelServiceService,
    private datePipe: DatePipe,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';
  originalList = new Array();
  astrosList = new Array();
  id: any = '';
  getAstroData: any = [];
  ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  searchKey = '';
  ngOnInit() {
    this.getAstrologersList();
    this.removeSearch();
    this.astrosList = this.getAstroData;
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  removeSearch() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key.includes('ast_search_')) {
        if (key != `ast_search_${this.currentPage}`) {
          localStorage.removeItem(key);
        } else {
          this.searchKey = localStorage.getItem(key) || '';
        }
      }
    }
  }
  /* ***********
  list Astrologer
  *********** */
  data: any = {};
  getAstrologersList() {
    this.spinner.show();
    switch (this.currentPage) {
      case 'request':
        this.data['is_approved_used'] = false;
        break;
      case 'approved':
        this.data['is_approved_used'] = true;
        this.data['is_approved'] = true;
        this.data['is_blocked'] = false;
        break;
      case 'rejected':
        this.data['is_approved'] = false;
        this.data['is_approved_used'] = true;
        break;
      case 'active':
        this.data['is_approved_used'] = true;
        this.data['is_current_active'] = true;
        this.data['is_blocked'] = false;
        this.data['is_delete'] = false;
        break;
      case 'blocked':
        this.data['is_blocked'] = true;
        break;
      case 'onoff':
        this.data['is_approved'] = true;
        this.data['is_blocked'] = false;
        this.data['sortby'] = 'is_current_active';
        this.data['is_blocked'] = false;
        this.data['is_delete'] = false;
        break;
      case 'call-active':
        this.data['is_approved'] = true;
        this.data['is_call_allow'] = true;
        this.data['is_call_active'] = true;
        this.data['is_blocked'] = false;
        this.data['is_delete'] = false;

        break;
      case 'query-active':
        this.data['is_approved'] = true;
        this.data['is_query_allow'] = true;
        this.data['is_query_active'] = true;
        this.data['is_blocked'] = false;
        this.data['is_delete'] = false;
        break;
      case 'chat-active':
        this.data['is_approved'] = true;
        this.data['is_chat_allow'] = true;
        this.data['is_chat_active'] = true;
        this.data['is_blocked'] = false;
        this.data['is_delete'] = false;
        break;
      case 'report-active':
        this.data['is_approved'] = true;
        this.data['is_report_allow'] = true;
        this.data['is_report_active'] = true;
        this.data['is_blocked'] = false;
        this.data['is_delete'] = false;
        break;
      case 'ongoing-call-chat':
        this.data['is_approved'] = true;
        this.data['is_blocked'] = false;
        this.data['sortby'] = 'currentrunning';
        this.data['is_delete'] = false;
        break;
    }
    this.astroService.getAstrologerList(this.data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.astrosList = res.data;
        this.originalList = res.data;
        let offset =
          localStorage.getItem(`ast_offset_${this.currentPage}`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        this.getAstroData = this.astrosList;
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }
  getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }
  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.astrosList = this.originalList;
      localStorage.removeItem(`ast_search_${this.currentPage}`);
    } else {
      this.astrosList = this.originalList.filter(function (d: any) {
        return (
          d?.name.toLowerCase().indexOf(key) !== -1 ||
          d?.displayname.toLowerCase().indexOf(key) !== -1 ||
          d?.phone.toString().indexOf(key) !== -1 ||
          d?.astroAutoId.toString().indexOf(key) !== -1 ||
          d?.email.toString().toLowerCase().indexOf(key) !== -1 ||
          !key
        );
      });

      localStorage.setItem(`ast_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`ast_offset_${this.currentPage}`, event.offset);
  }

  /** Block/unblock astrologer*/
  public toggle($event: any, id: any, isBlocked: any) {
    this.astroService.blockAstrologer({ _id: id, block: !isBlocked }).subscribe(
      (res) => {
        this.spinner.hide();
        this.toast.success('Asrologer Unblocked', 'Success');
        this.astrosList = [];
        this.getAstrologersList();
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
        this.toast.error(err.error.message, 'Error');
      }
    );
  }

  transformDate(date:any){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  generateExcel() {
    if(this.astrosList.length == 0){
      return
    }
    const header = ['ID','Name','Display','Email','Phone','Country','State','City','Pincode','Email OTP','Phone OTP','Wallet Amount','Registered On','Register Type','Status','Bank Account No.','Account Type','IFSC','Account Holder Name','Pan Number','Aadhar Number','Working With Other','Other Company Name','Short Bio','Long Bio']
    const excelData:any = []
    this.astrosList.forEach((row:any)=>{
      var obj = []
      obj.push(row.astroAutoId)
      obj.push(row.name)
      obj.push(row.displayname)
      obj.push(row.email)
      obj.push('+'+row.country_code+'-'+row.phone)
      obj.push(row.country)
      obj.push(row.state)
      obj.push(row.city)
      obj.push(row.pincode)
      obj.push(row.email_otp)
      obj.push(row.phone_otp)
      obj.push(row.currencySymbol+row.current_wallet+'('+row.currencyCode+')')
      obj.push(this.transformDate(row.created_at))
      if(row.is_social_login){
        obj.push('Social Login')
      }else{
        obj.push('Email')
      }
      if(row?.is_delete){
        obj.push('Deleted')
      }else if(row?.is_blocked){
        obj.push('Blocked')
      }else{
        obj.push('Active')
      }
      obj.push(row.bank_account_num)
      obj.push(row.account_type)
      obj.push(row.ifsc)
      obj.push(row.account_holder_name)
      obj.push(row.pan_number)
      obj.push(row.addhar_number)
      if(row.working_with_other){
        obj.push('Yes')
      }else{
        obj.push('No')
      }
      obj.push(row.working_company)
      obj.push(row.short_bio)
      obj.push(row.long_bio)
      excelData.push(obj)
    })
    this.excelService.generateExcel(excelData,'Astrologer List',header);
  }
}
