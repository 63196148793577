<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
      <h4 class="page-title">Orders</h4>
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
          <!-- <li class="breadcrumb-item"><a [routerLink]="['/admin/order/store']">Orders</a></li> -->
          <li class="breadcrumb-item active" aria-current="page">Cancel And Reassign Astrologer</li>
      </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
      <div class="col-lg-12">
          <div class="card">
              <div class="card-body">
                  <div>
                      <form [formGroup]="formGroup" (submit)="cancelAndReassign()">
                          <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="penality">Order ID: </label>
                                    <label for=""># {{orderId}}</label>
                                </div>
                            </div>
                              <div class="col-lg-4 col-md-4">
                                  <div class="form-group">
                                      <label for="">Order Amount: </label>
                                      <label for="">{{data?.customer_amount}}</label>
                                  </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label >Current Astrologer: </label>
                                    <label for="">{{data?.astroId?.name}}</label>
                                </div>
                            </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-4 col-md-4">
                                  <div class="form-group">

                                      <label for="orderStatus">Select New Astrologer</label>
                                      <ng-select placeholder="Select Astrologer" appendTo="body"
                                            [searchable]="true" [clearable]="true" formControlName="astroId" (change)="selectedAstro($event)">
                                            <ng-option selected disabled value=''>Select Astrologer</ng-option>
                                            <ng-option [value]="astro._id" *ngFor="let astro of astroList">
                                                #{{astro.astroAutoId}} - {{astro.name}}
                                            </ng-option>
                                        </ng-select>
                                  </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                  <div class="form-group">
                                      <label for="penality">Penality</label>
                                      <input type="text" class="form-control" formControlName="penality">
                                  </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                  <div class="form-group">
                                      <label for="extraCharges">Extra Charges</label>
                                      <input type="text" class="form-control" formControlName="extraCharges">
                                  </div>
                              </div>
                          </div>
                          <div class="row d-flex justify-content-center">
                              <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1"
                                  [disabled]="!formGroup.valid">Save</button>
                              <!-- <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1">Save</button> -->
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
