<div class="pr-2 pl-2 d-flex justify-content-between">
  <input
  value="{{ searchKey }}"
    type="text"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
  <div>
    <button class="btn btn-primary mr-2" (click)="walletIssue()">
      Wallet Issues 
      <!-- <span class="badge badge-light">{{ walletCount }}</span> -->
    </button>
    <button class="btn btn-info mr-2" (click)="serviceIssue()">
      Service Issues 
      <!-- <span class="badge badge-light">{{ serviceCount }}</span> -->
    </button>
    <button class="btn btn-warning mr-2" (click)="pendingIssue()">
      Pending Issues 
      <!-- <span class="badge badge-light">{{ pendingCount }}</span> -->
    </button>
    <button class="btn btn-success mr-2" (click)="solvedIssue()">
      Solved Issues 
      <!-- <span class="badge badge-light">{{ solvedCount }}</span> -->
    </button>
  </div>
</div>
<br />
<ngx-datatable
(page)="onPageChange($event)"
  class="material"
  [rows]="issueList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  [sorts]="[{ dir: 'desc' }]"
  style="position: relative !important"
>
  <ngx-datatable-column name="Issue ID" prop="issueId" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      #{{ row.issueId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Type" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="badge badge-primary" *ngIf="row.is_wallet_issue"
        >WALLET</span
      >
      <span class="badge badge-info" *ngIf="row.is_service_issue">SERVICE</span>
      <span class="badge badge-warning" *ngIf="row.is_order_issue">ORDER</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Customer" prop="customerId.name" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      #{{ row.customerId?.customerAutoId }}-{{ row.customerId?.name }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Amount" prop="amount" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.amount | currency: "INR":"symbol-narrow" }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Issue" prop="issue_title" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.issue_title }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="badge badge-success" *ngIf="row.is_issue_solved"
        >SOLVED</span
      >
      <span class="badge badge-danger" *ngIf="!row.is_issue_solved"
        >PENDING</span
      >
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Replies" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="badge badge-success" *ngIf="row.is_reply_by_system">{{
        row.replylogs.length + 1
      }}</span>
      <span class="badge badge-danger" *ngIf="!row.is_reply_by_system">0</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Actions" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a
        [routerLink]="['/admin/issues/customer/', row._id]"
        title="View Details"
        class="mr-2"
        ><i class="fa fa-eye"></i
      ></a>
      <button
        class="btn btn-primary btn-sm"
        (click)="messageChatClick(row._id, row.is_reply_by_system)"
        *ngIf="!row.is_issue_solved"
      >
        {{ row.is_reply_by_system ? "Chat" : "Reply" }}
      </button>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date" [flexGrow]="2" prop="created_at">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.created_at | date: "medium" }}
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
