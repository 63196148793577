import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class WalletRechargeService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
  }

  public addWalletRecharge(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addwalletamount", data, { headers: header_object });
  }

  public getWalletRecharge(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allamounts", data, { headers: header_object });
  }

  public detailWalletRecharge(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlewalletamountbyid", data, { headers: header_object });
  }

  public updateWalletRecharge(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updatewalletamount", data, { headers: header_object });
  }
}
