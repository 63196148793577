import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CouponServiceService } from 'src/app/admin/services/coupon/coupon-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent implements OnInit {
  todayDate:any;
  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private couponService: CouponServiceService,
    private route: Router
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    code: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    amount: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    minimumAmount: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    maximumDiscount: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    couponDescription: new FormControl('', Validators.required),
    isApplicableOnForeignOrders: new FormControl(false, [Validators.required]),
    couponUsdAmount: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    minimumUsdOrder: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    maximumUsdDiscount: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    applicableOn: new FormControl('', Validators.required),
    usageLimitPerUser: new FormControl('', Validators.required),
    usageLimitPerCoupon: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    couponType: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.todayDate = moment().format('YYYY/MM/DD')
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
  }

  get f() {
    return this.formGroup.controls
  } 

  addCouponSubmit(){
    // console.log(this.formGroup.value)
    // return 
    var code = (this.formGroup.get('code')?.value).toUpperCase()
    this.formGroup.patchValue({code:code})
    this.couponService.addCoupon(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.reloadCurrentRoute()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

  /**
   * International Payment
   */
   isForeignOrder: boolean = false
   isApplicableOnForeignOrders() {
     this.isForeignOrder = this.formGroup.get('isApplicableOnForeignOrders')?.value
     //console.log(this.formGroup.get('isApplicableOnForeignOrders')?.value)
   }
}
