import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { SubcategoryMallService } from 'src/app/admin/services/subcategory-mall/subcategory-mall.service';

@Component({
  selector: 'app-list-mall-subcategory',
  templateUrl: './list-mall-subcategory.component.html',
  styleUrls: ['./list-mall-subcategory.component.scss']
})
export class ListMallSubcategoryComponent implements OnInit {

  constructor(
    private subcategoryMallService: SubcategoryMallService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getCategoryList()
  }

  /* ***********
  list Category
  *********** */

  getCategoryList() {
    this.spinner.show()
    this.subcategoryMallService.listMallSubcategory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.subcategoryname.toLowerCase().indexOf(val) !== -1 ||
      d.subcategoryId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /** Enable/disable category*/
  public toggle($event: any, data: any) {
    this.subcategoryMallService.updateMallSubcategory({ '_id': data._id, 'status': !data.status, updatedId: this.userdata.getId(), updatedBy: this.userdata.getName() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.getCategoryList()
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
        this.toast.error(err.error.message, 'Error')
      }
    );
  }

}
