import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
  }

  public addRoles(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addRolesandpermissionsarray", data, { headers: header_object });
  }

  public listRoles(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allrolesandpermission", data, { headers: header_object });
  }

  public singleRolesById(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlerole", data, { headers: header_object });
  }

  public updateRoles(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updatRolesandpermissionsarray",data, {headers: header_object});
  }
}
