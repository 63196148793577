<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Roles</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/roles']">Roles</a></li>
            <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>List</a>
                                        <ng-template ngbNavContent>
                                            <div class="row ml-2 mt-4 mr-2">
                                                <div class="col-md-12" *ngFor="let role of roleList">
                                                  <div class="card">
                                                    <div class="card-body text-center">
                                                      <!-- <blockquote class="quote"> -->
                                                      <p> <strong>Role Name : </strong> {{role['roles_name']}}</p>
                                                      <span *ngFor="let per of role['permissions']" class="badge badge-primary mr-2">{{per}}</span>
                                                      <br>
                                                      <small class="text-muted">
                                                        <a [routerLink]="['/admin/roles', role['_id']]">Edit</a>
                                                      </small>
                                                      <!-- </blockquote> -->
                                                    </div>
                                                  </div>
                                                </div><!-- COL END -->
                                              </div>
                                            <!-- <br> -->
                                            <!-- <div style="display: flex; justify-content: space-between;"
                                                class="pl-2 pr-2">
                                                <input type="text"  placeholder="Type to filter the name column..." class="form-control
                                                    col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
                                            </div> -->
                                            <!-- <br> -->
                                            <!-- <ngx-datatable class="material" [rows]="roleList" [columnMode]="'force'"
                                                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                                [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                                                <ngx-datatable-column [flexGrow]="1" name="Role Name" prop="roles_name">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.roles_name}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="4" name="Permission" prop="permissions">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <span *ngFor="let per of row.permissions" class="badge badge-primary mr-2">{{per}}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Action">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <a [routerLink]="['/admin/roles',row._id]" class="btn btn-primary btn-sm text-white">Edit</a>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable> -->
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Add New</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-add-roles></app-add-roles>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->