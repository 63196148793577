import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
  }

  public requestOtpForgot(data: any) {
    return this.http.post(this.BaseUrl + "/forgetpassword", data);
  }

  public checkOtpForgot(data: any) {
    return this.http.post(this.BaseUrl + "/checkotpforget", data);
  }

  public changeForgotPassword(data: any) {
    return this.http.post(this.BaseUrl + "/changepasswordforget", data);
  }

}
