<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Manager</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/managers/list']">Manager</a></li>
            <li class="breadcrumb-item active" aria-current="page">Detail</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="wideget-user">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12">
                                                <div class="wideget-user-desc d-flex">
                                                    <div class="wideget-user-img">
                                                        <img class="" height="135px"
                                                            [src]=getSanitizeUrl(data.profile_image) alt="Astro Image">
                                                    </div>
                                                    <div class="user-wrap">
                                                        <h4>{{data.name}} <span class="badge badge-info ml-2">{{roledata.roles_name}}</span></h4>
                                                        <ng-container
                                                            *ngIf="data.is_current_active === true && data.is_blocked === false; then active; else Inactive">
                                                        </ng-container>
                                                        <ng-template #active>
                                                            <span class="badge badge-success  mr-1">Online</span>
                                                        </ng-template>
                                                        <ng-template #Inactive>
                                                            <span class="badge badge-danger  mr-1">Offline</span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="">
                                    <div class="wideget-user-tab">
                                        <div class="tab-menu-heading">
                                            <div class="tabs-menu1">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="border-0">
                                                            <div id="profile-log-switch">
                                                                <div class="media-heading">
                                                                    <h5 class="text-uppercase"><strong>Personal
                                                                            Information</strong></h5>
                                                                </div>
                                                                <hr class="m-0">
                                                                <div class="table-responsive ">
                                                                    <table class="table row table-borderless">
                                                                        <tbody class="col-lg-12 col-xl-6 p-0">
                                                                            <tr>
                                                                                <td><strong>Full Name :</strong>
                                                                                    {{data.name}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>Email :</strong>
                                                                                    {{data.email}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>Phone :</strong>
                                                                                    +{{data.phone}} </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody class="col-lg-12 col-xl-6 p-0">
                                                                            <tr>
                                                                                <td><strong>Date Of Birth :</strong>
                                                                                    {{data.birth_date}}-{{data.birth_month}}-{{data.birth_year}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>Gender :</strong>
                                                                                    {{data.gender}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>Complete Address :</strong>
                                                                                    {{data?.complete_address}}</td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="media-heading">
                                                                    <h5 class="text-uppercase"><strong>Proof
                                                                            Image</strong></h5>
                                                                </div>
                                                                <hr class="m-0">
                                                                <div class="table-responsive ">
                                                                    <table class="table row table-borderless">
                                                                        <tbody class="col-lg-12 col-xl-6 p-0">
                                                                            <tr>
                                                                                <td><img class="" height="200px"
                                                                                        [src]=getSanitizeUrl(data.proof_image)
                                                                                        alt="Astro Image"></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->