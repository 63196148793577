import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-singlecustomer-support-list',
  templateUrl: './singlecustomer-support-list.component.html',
  styleUrls: ['./singlecustomer-support-list.component.scss']
})
export class SinglecustomerSupportListComponent implements OnInit {

  constructor(
    private issueService: IssueServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private route: ActivatedRoute
  ) { }
  title = 'angular-datatables';

  issueList = [];
  id: any = '';
  temp: any = [];
  getIssueData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.route.parent?.params.subscribe(
      (params) => {
        this.id = params.data
      });
    this.getSingleCustomerSupport()
    this.issueList = this.getIssueData;
  }

  /* ***********
  list All Customer Support
  *********** */

  getSingleCustomerSupport() {
    this.spinner.show()
    this.issueService.getAllSupport({ 'customerId': this.id, is_customer: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList
        this.getIssueData = this.issueList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.supportId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.issueList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue() {
    this.spinner.show()
    this.issueService.getAllSupport({ 'customerId': this.id, 'is_issue_solved': false, is_customer: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Solved Issue
   */
  solvedIssue() {
    this.spinner.show()
    this.issueService.getAllSupport({ is_customer: true, 'customerId': this.id,'is_issue_solved': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
