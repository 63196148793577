import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { UpdateRequestServiceService } from 'src/app/admin/services/update-request/update-request-service.service';

@Component({
  selector: 'app-approve-reject-charges',
  templateUrl: './approve-reject-charges.component.html',
  styleUrls: ['./approve-reject-charges.component.scss']
})
export class ApproveRejectChargesComponent implements OnInit {

  id:any = ''
  updateChargesForm: any = new FormGroup({
    userId: new FormControl('',[Validators.required]),
    requestId: new FormControl('',[Validators.required]),
    updateBy: new FormControl('',[Validators.required]),
    is_approved: new FormControl(true,[Validators.required]),
    admin_comment: new FormControl(''),
  })
  dataList: any;

  constructor(
    private spinner: NgxSpinnerService,
    private updateService: UpdateRequestServiceService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private userdata: UserdataService,
    private chargeService: UpdateRequestServiceService
  ) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.updateChargesForm.patchValue({ requestId: this.id })
    this.updateChargesForm.patchValue({ updateBy: this.userdata.getName() })
    this.updateChargesForm.patchValue({ userId: this.userdata.getId() })
    this.getDataById()
  }

  get f(){
    return this.updateChargesForm.controls
  }

  public onUpdateCharges(){
    this.spinner.show()
    this.updateService.approveRejectChargeRequest(this.updateChargesForm.value).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Sucess')
        this.route.navigateByUrl('/admin/request/charges')
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error')
      }
    )
  }

  statusOfRequest(event:any){
    if(event.target.value == 'true'){
      this.updateChargesForm.patchValue({is_approved:true})
    }
    if(event.target.value == 'false'){
      this.updateChargesForm.patchValue({is_approved:false})
    }
    //console.log(this.updateChargesForm.get('is_approved').value)
  }

  chargeLogs:any = []
  getDataById(){
    this.spinner.show()
    this.chargeService.getSingleChargeRequest({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.dataList = res.data
        this.chargeLogs = res.data.charges_logs.reverse()
        //console.log(res)
      },
      err => {
        this.spinner.hide()
        //console.log(err)         
      }
    )
  }
}
