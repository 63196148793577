<div class="default-header">

    <!-- BACKGROUND-IMAGE -->
    <div class="login-img">

        <!-- GLOABAL LOADER -->
        <!-- <div id="global-loader">
				<img src="../../assets/images/svgs/loader.svg" class="loader-img" alt="Loader">
            </div> -->


        <ngx-spinner></ngx-spinner>
        <div class="page">
            <div class="fix-to-center">
                <!-- CONTAINER OPEN -->
                <div class="col col-login mx-auto">
                    <div class="text-center">
                        <img src="../../assets/images/brand/astro.png" class="header-brand-img" alt="">
                    </div>
                </div>
                <div class="container-login100">
                    <div class="wrap-login100 p-6">
                        <form class="login100-form validate-form" [formGroup]="loginForm" (submit)="onLogin()">
                            <span class="login100-form-title">
                                Admin Login
                            </span>
                            <div class="wrap-input100 validate-input"
                                data-validate="Valid email is required: ex@abc.xyz">
                                <input class="input100" type="text" formControlName="email" placeholder="Email"
                                    required>
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i class="zmdi zmdi-email" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
                                class="alert alert-danger mt-1">
                                <!-- <div *ngIf="f.email.errors.required">Required</div> -->
                                Required
                            </div>

                            <div class="wrap-input100 validate-input" data-validate="Password is required">
                                <input class="input100" type="password" formControlName="password"
                                    placeholder="Password" required>
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i class="zmdi zmdi-lock" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                                class="alert alert-danger mt-1">
                                <!-- <div *ngIf="f.password.errors.required">Required</div> -->
                                Required
                            </div>
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (load)="handleLoad()"
                                (success)="handleSuccess($event)" [useGlobalDomain]="false" formControlName="recaptcha">
                            </ngx-recaptcha2>
                            <div class="text-right pt-1">
                                <p class="mb-0"><a [routerLink]="['/forgot-password']" class="text-primary ml-1">Forgot
                                        Password?</a></p>
                            </div>
                            <div class="container-login100-form-btn">
                                <button type="submit" class="login100-form-btn btn-primary"
                                    [disabled]="!loginForm.valid">
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- CONTAINER CLOSED -->
            </div>
        </div>
    </div>
    <!-- BACKGROUND-IMAGE CLOSED -->


</div>