import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-customer-issue',
  templateUrl: './customer-issue.component.html',
  styleUrls: ['./customer-issue.component.scss'],
})
export class CustomerIssueComponent implements OnInit {
  currentPage = '';
  constructor(
    private issueService: IssueServiceService,
    private spinner: NgxSpinnerService,
    private _route: Router,
    private userdata: UserdataService,
    private router: ActivatedRoute
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  issueList = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];
  walletCount: Number = 0;
  serviceCount: Number = 0;
  pendingCount: Number = 0;
  solvedCount: Number = 0;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  searchKey = '';
  ngOnInit() {
    this.searchKey =
      localStorage.getItem(`issue_search_${this.currentPage}`) || '';
    this.getAllCustomerIssues({ userId: this.userdata.getId() });
    this.removeSearch()
  }
  removeSearch() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key.includes('issue_search_')) {
        if (key != `issue_search_${this.currentPage}`) {
          localStorage.removeItem(key);
        } else {
          this.searchKey = localStorage.getItem(key) || '';
        }
      }
    }
  }
  /* ***********
  list All Customer Issues
  *********** */

  getAllCustomerIssues(data: any) {
    this.spinner.show();
    this.issueService.getAllCustomerIssues(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.issueList = res.data;
        this.originalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset =
          localStorage.getItem(`issue_offset_${this.currentPage}`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.issueList = this.originalList;
      localStorage.removeItem(`issue_search_${this.currentPage}`);
    } else {
      this.issueList = this.originalList.filter(function (d: any) {
        return (
          d.customerId?.name.toLowerCase().includes(key) ||
          d.issueId.toString().includes(key)
        );
      });
      localStorage.setItem(`issue_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`issue_offset_${this.currentPage}`, event.offset);
  }
  /**
   * Wallet Issues
   */
  walletIssue() {
    this.getAllCustomerIssues({
      userId: this.userdata.getId(),
      is_wallet_issue: true,
    });
  }

  /**
   * Service Issue
   */
  serviceIssue() {
    this.getAllCustomerIssues({
      userId: this.userdata.getId(),
      is_service_issue: true,
    });
  }

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue() {
    this.getAllCustomerIssues({
      userId: this.userdata.getId(),
      is_issue_solved: false,
    });
  }

  /**
   * Solved Issue
   */
  solvedIssue() {
    this.getAllCustomerIssues({
      userId: this.userdata.getId(),
      is_issue_solved: true,
    });
  }
  messageChatClick(id: any, isSystem: Boolean) {
    if (isSystem) {
      this._route.navigate(['/admin/issue/message', id, 'cust']);
    } else {
      this._route.navigate(['/admin/issues/customer/reply', id]);
    }
  }
}
