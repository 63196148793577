<div class="p-2">
    <h4 class="mt-2">
        Service Logs
    </h4>
    <input type="text"  placeholder="Type to filter the service name..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
  </div>
  <br>
  <ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Service Name" prop="service_name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="text-uppercase">{{row.service_name}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Status">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.is_online == true ? 'Online':'Offline'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Date" prop="service_time">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.service_time | date:'medium'}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
