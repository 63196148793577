import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {

  addCustomerForm: any = new FormGroup({
    name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()_+-]).{8,}$')]),
    phone: new FormControl('',[Validators.required, Validators.pattern('[0-9]{10,}')]),
    is_social_login: new FormControl(false),
    country_code: new FormControl(''),
    is_foreign: new FormControl(''),
    regCountryCode: new FormControl(''),
    currencySymbol: new FormControl(''),
    currencyCode: new FormControl(''),
    regCountryName: new FormControl('')
  })

  constructor(
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private toast: ToastrService,
    private route: Router,
    private internationalService: InternationalServiceService,
    private customize: CustomizeServiceService
  ) { }

  ngOnInit(): void {
    // this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getCountryData()
    this.getCountries()
  }

  get f(){
    return this.addCustomerForm.controls
  }

  public onAddCustomerSubmit(){
    this.spinner.show()
    this.customerService.addNewCustomer(this.addCustomerForm.value).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.toast.success('Customer added!', 'Sucess')
        //this.reloadCurrentRoute()
        this.route.navigateByUrl('/admin/customer/list')
        //console.log(res)
      },
      err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
}

customSearchFn(term: string, item: any) {
  term = term.toLocaleLowerCase();
  return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
    item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
}

getFlag(emoji: any) {
  var emojiU = emoji.toString().toLocaleLowerCase()
  return `https://flagcdn.com/24x18/${emojiU}.png`
}

countryData:any = [];
countryCode=''
getCountryData(){
  this.internationalService.getCountryData().subscribe(
    (res:any)=>{
      // console.log("Country Code is",res.data)
      this.countryData = res.data
    },err=>{
      //console.log(err)
    }
  )
}

countryList = new Array();
getCountries() {
  this.customize.getCountryList().subscribe({
    next: (v) => {
      this.countryList = v.data;
    },
    error: (e) => {
      //console.log(e.error);
    },
    complete: () => { },
  });
}


getCountry(event:any){
  this.addCustomerForm.patchValue({regCountryCode: event.iso2, currencySymbol: event.currency_symbol, currencyCode: event.currency, regCountryName: event.name, is_foreign: (event.iso2 == 'IN' ? false : true)})
}

}
