<!-- HORIZONTAL-MENU -->
<div class="sticky">
    <div class="horizontal-main hor-menu clearfix">
        <div class="horizontal-mainwrapper container clearfix">
            <nav class="horizontalMenu clearfix">
                <ul class="horizontalMenu-list d-flex justify-content-center">
                    <li aria-haspopup="true"><a [routerLink]="['/admin/dashboard']" class="sub-icon"><i
                                class="fe fe-airplay"></i>
                            Dashboard</a>
                    </li>
                    <li aria-haspopup="true"
                        *appHasRole="['Manage-Users','Manage-Astrologers','Manage-Customer','Manage-Managers']"><a
                            href="javascript:void(0)" class="sub-icon"><i class="fe fe-database"></i> User Type <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true" class="" *appHasRole="['Manage-Users']"><a
                                    [routerLink]="['/admin/usersList']"> All Users</a>
                            </li>
                            <!-- <li aria-haspopup="true" class="sub-menu-sub"><a href="javascript:void(0)">Astrologer</a> -->
                            <li aria-haspopup="true" *appHasRole="['Manage-Astrologers']"><a
                                    [routerLink]="['/admin/astro/approved']">Astrologer</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Customer']"><a
                                    [routerLink]="['/admin/customer/list']">Customer</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Managers']"><a
                                    [routerLink]="['/admin/managers/list']">Manager</a>
                            </li>
                        </ul>
                    </li>
                    <li aria-haspopup="true"
                        *appHasRole="['Manage-Skills','Manage-Language','Manage-Report','Manage-Banner','Manage-Coupon','Manage-Roles','Manage-Customization']">
                        <a href="javascript:void(0)" class="sub-icon"><i class="fe fe-pie-chart"></i> Add on <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true" *appHasRole="['Manage-Skills']"><a
                                    [routerLink]="['/admin/skills']">Skills</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Language']"><a
                                    [routerLink]="['/admin/languages']">Languages</a>
                            </li>
                            <li aria-haspopup="true"><a
                                    [routerLink]="['/admin/international/list']">International Pricing</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Report']"><a
                                    [routerLink]="['/admin/reports']">Report</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Banner']"><a
                                    [routerLink]="['/admin/banner']">Banner</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Coupon']"><a
                                    [routerLink]="['/admin/coupon']">Coupon</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Roles']"><a
                                    [routerLink]="['/admin/roles']">Roles</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Customization']"><a
                                    [routerLink]="['/admin/customize']">Customization</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Push-Notifications']"><a
                                    [routerLink]="['/admin/push-notification/send']">Send Notification</a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Push-Notifications']"><a
                                [routerLink]="['/admin/push-notification/send']">Astrologer Notice-Board</a>
                            </li>
                            <li aria-haspopup="true"><a
                                [routerLink]="['/admin/dynamic-page']">Dynamic Pages</a>
                            </li>
                        </ul>
                    </li>
                    <li aria-haspopup="true"
                        *appHasRole="['Waiting-List','Reviews','Manage-Issues','Manage-Remedy','Admin-Wallet','Manage-Orders','Manage-Request','Payout']">
                        <a href="javascript:void(0)" class="sub-icon"><i class="fe fe-star"></i> Extra <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true" *appHasRole="['Waiting-List']">
                                <a [routerLink]="['/admin/waiting-list']" class="sub-icon">
                                    <!-- <i class="fe fe-clock"></i> -->
                                    Waiting List
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Reviews']">
                                <a [routerLink]="['/admin/review/list']" class="sub-icon">
                                    <!-- <i class="fe fe-star"></i> -->
                                    Review
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Issues']">
                                <a [routerLink]="['/admin/issue/customer']" class="sub-icon">
                                    <!-- <i class="fe fe-help-circle"></i> -->
                                    Issues
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Support']">
                                <a [routerLink]="['/admin/support/customer']" class="sub-icon">
                                    <!-- <i class="fe fe-help-circle"></i> -->
                                    Support
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Manage-Remedy']">
                                <a [routerLink]="['/admin/remedy']" class="sub-icon">
                                    <!-- <i class="fe fe-check-circle"></i> -->
                                    Remedy
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Admin-Wallet']">
                                <a [routerLink]="['/admin/wallet/list']" class="sub-icon">
                                    <!-- <i class="fe fe-check-circle"></i> -->
                                    Admin Wallet
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Payout']">
                                <a [routerLink]="['/admin/payout/history']" class="sub-icon">
                                    <!-- <i class="fe fe-check-circle"></i> -->
                                    Astrologer Payout
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Notes']">
                                <a [routerLink]="['/admin/notes']" class="sub-icon">
                                    <!-- <i class="fe fe-check-circle"></i> -->
                                    Notes
                                </a>
                            </li>
                            <li aria-haspopup="true" *appHasRole="['Waiting-List']">
                                <a [routerLink]="['/admin/recharge/all']" class="sub-icon">
                                    <!-- <i class="fe fe-clock"></i> -->
                                    Customer Wallet Recharge
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li aria-haspopup="true" *appHasRole="['Manage-Orders']"><a [routerLink]="['/admin/order/call']"
                            class="sub-icon"><i class="fe fe-box"></i>
                            Orders</a>
                    </li>

                    <li aria-haspopup="true" *appHasRole="['Manage-Request']"><a href="javascript:void(0)"
                            class="sub-icon"><i class="fe fe-clipboard"></i>Requests <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true"><a [routerLink]="['/admin/request/charges']">Charges Update</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/request/profile']">Profile Update</a>
                            </li>
                        </ul>
                    </li>
                    <li aria-haspopup="true" *appHasRole="['Manage-Offers']"><a [routerLink]="['/admin/offers/all']"
                            class="sub-icon"><i class="fe fe-box"></i>
                            Offers</a>
                    </li>

                    <li aria-haspopup="true" *appHasRole="['Manage-Blog']"><a href="javascript:void(0)"
                            class="sub-icon"><i class="fe fe-clipboard"></i>Blogs <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true"><a [routerLink]="['/admin/blog-category/list']">Blog Category</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/blog/new']">Blogs</a>
                            </li>
                        </ul>
                    </li>

                    <li aria-haspopup="true" *appHasRole="['Manage-Festivals']"><a href="javascript:void(0)"
                            class="sub-icon"><i class="fe fe-clipboard"></i>Festivals <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true"><a [routerLink]="['/admin/festival/list']">List</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/festival/list-yearly']">List Yearly
                                    Festivals</a>
                            </li>
                        </ul>
                    </li>

                    <li aria-haspopup="true" *appHasRole="['Manage-Mall']"><a href="javascript:void(0)"
                            class="sub-icon"><i class="fe fe-clipboard"></i>Astro Shop <i
                                class="fa fa-angle-down horizontal-icon"></i></a>
                        <ul class="sub-menu">
                            <li aria-haspopup="true"><a [routerLink]="['/admin/order/store']">Orders</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/mall-category/list']">Category</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/mall-subcategory/list']">Sub-Category</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/mall-product/list']">Product</a>
                            </li>
                            <li aria-haspopup="true"><a [routerLink]="['/admin/mall-coupon/list']">Coupon</a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </nav>
            <!-- NAV END -->
        </div>
    </div>
</div>
<!-- HORIZONTAL-MENU END -->