import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { UserServiceService } from 'src/app/admin/services/user/userService/user-service.service';

@Component({
  selector: 'app-manager-login',
  templateUrl: './manager-login.component.html',
  styleUrls: ['./manager-login.component.scss']
})
export class ManagerLoginComponent implements OnInit {

  constructor(
    private toast: ToastrService, 
    private ngxSpinner: NgxSpinnerService, 
    private userdata: UserdataService,
    private router: Router,
    private managerService: ManagerService,
    private userservice: UserServiceService
    ) {}

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    is_social_login: new FormControl(false, [Validators.required]),
    recaptcha: new FormControl('',[Validators.required])
  });

  ngOnInit(): void {
    if(this.userdata.getData() != null){
      this.router.navigateByUrl('/admin/dashboard')
    }
  }

  get f(){
    return this.loginForm.controls
  }

  public onLogin(){
    this.ngxSpinner.show()
    this.userservice.deleteFcm({ token: this.userdata.getFcm() }).subscribe(
      (res: any) => {
        this.managerService.managerLogin(this.loginForm.value).subscribe(
          (res: any) => {
            this.ngxSpinner.hide()
            this.toast.success('Login Successfully','Success')
            this.userdata.setData(res);
            this.router.navigateByUrl('/admin/dashboard')
          },
          err => {
            this.ngxSpinner.hide()
            this.toast.error(err.error.message,'Error')
            //console.log(err)
          }
        )
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  handleLoad(){
    //console.log('ok')
  }

  handleSuccess(event:any){
    //console.log(event, this.loginForm.get('recaptcha')?.value)
  }

  siteKey:string = '6Le3m2waAAAAAC1xu4U8248Sw6tWmJwweoyMUzu7'

}
