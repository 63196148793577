import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { ReviewServiceService } from 'src/app/admin/services/review/review-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-direct-review',
  templateUrl: './add-direct-review.component.html',
  styleUrls: ['./add-direct-review.component.scss']
})
export class AddDirectReviewComponent implements OnInit {
  astroList: any;

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private reviewService: ReviewServiceService,
    private route: Router,
    private astroService: AstroServiceService
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    astroId: new FormControl('', Validators.required),
    customerName: new FormControl('', Validators.required),
    customer_review: new FormControl(''),
    customer_review_stars: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    this.getAstrologerList()
  }

  get f() {
    return this.formGroup.controls
  } 

  addReviewSubmit(){
    this.reviewService.addDirectReview(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/review/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

  getAstrologerList(){
    this.astroService.getAstrologerList({is_approved_used:true,is_approved:true,is_delete: false}).subscribe(
      (res:any)=>{
        this.astroList = res.data
      },err=>{
        //console.log(err)
      }
    )
  }

}
