import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class IssueServiceService {

  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }
  
  /**
   * 
   * Customer Issues
   */
  public getAllCustomerIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allcustomerissue", data, { headers: header_object});
  }

  public getDetailCustomerIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    if(data.isAstro){
      return this.http.post(this.BaseUrl + "/singleissuebyidastro", {_id:data._id,userId:data.userId}, { headers: header_object});
    }else{
      return this.http.post(this.BaseUrl + "/singleissuebyid",  {_id:data._id,userId:data.userId}, { headers: header_object});
    }


  }
  public getSingleIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlesupportbyid", data, { headers: header_object});
  }

  public replyCustomerIssues(data: any,isAtro:boolean){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    if(isAtro){
      return this.http.post(this.BaseUrl + "/replytoastroissue", data, { headers: header_object});
    }else{
      return this.http.post(this.BaseUrl + "/replytocustomerissue", data, { headers: header_object});
    }
  }
  public replyToSupport(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/replytosupport", data, { headers: header_object});
  }
  
  public refundCustomerIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/raiserefund", data, { headers: header_object});
  }

  /**
   * 
   * Astrologer Issues
   */
  public getAllAstrologerIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allastroissue", data, { headers: header_object});
  }

  public getDetailAstrologerIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singleissuebyidastro", data, { headers: header_object});
  }

  public replyAstrologerIssues(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/replytoastroissue", data, { headers: header_object});
  }

  /**
   * Support
   */
   public getAllSupport(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allsupport", data, { headers: header_object});
  }

   public getSingleSupport(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/singlesupportbyid", data, { headers: header_object});
  }

  public replySupport(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/replytosupport", data, { headers: header_object});
  }

  /**
   * Customer Support
   */

  /**
   * Astrologer Support
   */
}
