<div class="pr-2 pl-2 d-flex justify-content-center">
  <ng-select style="width: 100%;" [items]="astroList" placeholder="Select Astrologer" [(ngModel)]="astroSelected" name="astro" (change)="getAstroOfferPrice()"
  bindLabel="name" bindValue="_id">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div style="margin: 10px 0;" class="card">
      <div class="card-body">
        <h5 class="card-title" [ngOptionHighlight]="search">{{item.displayname}}</h5>
        <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
        <p class="card-text d-flex">
          <span>
            <img height="50" width="50" [src]="getSanitizeUrl(item.profile_image)" />
            <br />
            <ng-template #t let-fill="fill">
              <span class="star" [class.full]="fill === 100">
                <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>
            <ngb-rating [(rate)]="item.astrologer_rating" [starTemplate]="t"
              [readonly]="true" [max]="5"></ngb-rating>
          </span>
          <span>{{item.name}}<br />
            {{item.price | currency:'INR':'symbol-narrow'}}
          </span>
        </p>
      </div>
    </div>
  </ng-template>
</ng-select>
    <!-- <select class="form-control" [(ngModel)]="astroSelected" name="astro" (change)="getAstroOfferPrice()">
        <option value="" >Select Astrologer</option>
        <option [value]="astro._id" *ngFor="let astro of astroList">#{{astro.astroAutoId}} - {{astro.name}}</option>
    </select> -->
</div>
<br/>
<ngx-datatable class="material" [rows]="astroOfferPriceList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
  <ngx-datatable-column [flexGrow]="1" name="ID" prop="offerId">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.offerId}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Offer Name" prop="offer_name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.offer_name}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Display Name" prop="offer_display_name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.offer_display_name}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Type" prop="offer_on">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="badge badge-warning text-uppercase">{{row.offer_on}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Discount" prop="offer_discount">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.offer_discount}}%
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Customer Pay" prop="charges.customer_pays">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.charges.customer_pays | currency:'INR':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Astro Share(INR)" prop="charges.astro_share">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.charges.astro_share | currency:'INR':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="AR Share(INR)" prop="charges.ar_share">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.charges.ar_share | currency:'INR':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Astro Share(Foreign)" prop="charges.astro_share_foreign">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.charges.astro_share_foreign | currency:'USD':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="AR Share(Foreign)" prop="charges.ar_share_foreign">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.charges.ar_share_foreign | currency:'USD':'symbol-narrow'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <ng-container *ngIf="checkIfOfferIsActive(row._id); then thenBlock; else elseBlock">
      </ng-container>
      <ng-template #thenBlock><span class="badge badge-success">ACTIVE</span></ng-template>
      <ng-template #elseBlock><span class="badge badge-danger">INACTIVE</span></ng-template>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>