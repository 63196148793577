<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Filter ID, Name..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />

</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="subcategoryId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row?.subcategoryId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Category Name" prop="categoryId.categoryname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row?.categoryId?.categoryname">{{row?.categoryId?.categoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Name" prop="subcategoryname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row?.subcategoryname">{{row?.subcategoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Slug" prop="subcategory_slug">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row?.subcategory_slug">{{row?.subcategory_slug}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Keywords" prop="subcategory_keyword">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row?.subcategory_keyword">{{row?.subcategory_keyword}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Enable">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- Material switch -->
            <ng-template #enabled>
                <mat-slide-toggle (change)="toggle($event, row)" [checked]="true"></mat-slide-toggle>
            </ng-template>
            <ng-template #disabled>
                <mat-slide-toggle (change)="toggle($event, row)" [checked]="false"></mat-slide-toggle>
            </ng-template>
            <ng-container *ngIf="row?.status === true; then enabled; else disabled">
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row?.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Edit">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/malls-subcategory/',row?._id]" class="fa fa-eye text-primary mr-1"></a>
            <a [routerLink]="['/admin/malls-subcategory/edit/',row?._id]" class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>