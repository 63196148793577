<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Customer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Customer Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="border-0">
                        <div id="profile-log-switch">
                            <div class="media-heading">
                                <h5 class="text-uppercase"><strong>Personal Information</strong> <span class="badge badge-danger badge-pill" *ngIf="data.is_hide">Deleted</span></h5>
                            </div>
                            <hr class="m-0">
                            <div class="table-responsive ">
                                <table class="table row table-borderless">
                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                        <tr>
                                            <td><strong>Full Name :</strong> {{data.name}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Gender :</strong> {{data.gender}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>PID :</strong> {{data.pId}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Mobile :</strong> +{{data.country_code}}-{{data.mobile}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Birth Place :</strong> {{data.city}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Birth Time :</strong> {{data.tob}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Date of Birth :</strong> {{data.dob}} </td>
                                        </tr>

                                    </tbody>
                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                        <tr>
                                            <td><strong>Employed In :</strong> {{data.employed_in}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Marital Status :</strong> {{data.marital_status}} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>City :</strong> {{data?.city}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>State :</strong> {{data?.state}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Country :</strong> {{data?.country}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="media-heading" *ngIf="data.is_partner_details">
                                <h5 class="text-uppercase"><strong>partner's details</strong></h5>
                            </div>
                            <hr class="m-0" *ngIf="data.is_partner_details">
                            <div class="table-responsive" *ngIf="data.is_partner_details">
                                <table class="table row table-borderless">
                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                        <tr>
                                            <td><strong>Full Name :</strong> {{data.partner_name}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Birth Place :</strong> {{data.partner_city}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Birth Time :</strong> {{data.partner_tob}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Date of Birth :</strong> {{data.partner_dob}} </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                        <tr>
                                            <td><strong>City :</strong> {{data?.partner_city}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>State :</strong> {{data?.partner_state}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Country :</strong> {{data?.partner_country}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
