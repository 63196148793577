import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-detail-customer-support',
  templateUrl: './detail-customer-support.component.html',
  styleUrls: ['./detail-customer-support.component.scss'],
})
export class DetailCustomerSupportComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private issueService: IssueServiceService,
    private userdata: UserdataService,
    private orderService: OrderServiceService
  ) {}

  data: any = '';
  id: any = '';
  chatList: any;
  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getDataById();
  }

  getDataById() {
    this.spinner.show();
    this.issueService
      .getSingleSupport({ _id: this.id, userId: this.userdata.getId() })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.data = res.data;
          this.chatList = this.data.replylogs;
        },
        (err) => {
          this.spinner.hide();
          //console.log(err);
        }
      );
  }
}
