import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-customer-issue-list',
  templateUrl: './customer-issue-list.component.html',
  styleUrls: ['./customer-issue-list.component.scss']
})
export class CustomerIssueListComponent implements OnInit {

  constructor(
    private issueService: IssueServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private route: ActivatedRoute
  ) { } 
  title = 'angular-datatables';

  issueList = [];
  id: any = '';
  temp: any = [];
  getIssueData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.route.parent?.params.subscribe(
      (params) => {
        this.id = params.data
      });
    this.pendingIssue()
    this.issueList = this.getIssueData;
  }

  /* ***********
  list All Customer Issues
  *********** */

  getAllCustomerIssues() {
    this.spinner.show()
    this.issueService.getAllCustomerIssues({customerId:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList
        this.getIssueData =this.issueList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
updateFilter(event: any) {
  //console.log(this.temp)
  const val = event.target.value.toLowerCase();

  const temp = this.temp.filter(function (d: any) {
    return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.issueId.toString().indexOf(val) !== -1 || !val
  });

  // update the rows
  this.issueList = temp;
  // Whenever the filter changes, always go back to the first page
  this.myFilterTable.offset = 0;
}
  /**
   * Wallet Issues
   */
  walletIssue(){
    this.spinner.show()
    this.issueService.getAllCustomerIssues({customerId:this.id,'is_wallet_issue':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //err);
      }
    );
  }

  /**
   * Service Issue
   */
  serviceIssue(){
    this.spinner.show()
    this.issueService.getAllCustomerIssues({customerId:this.id,'is_service_issue':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue(){
    this.spinner.show()
    this.issueService.getAllCustomerIssues({customerId:this.id,'is_issue_solved':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Solved Issue
   */
  solvedIssue(){
    this.spinner.show()
    this.issueService.getAllCustomerIssues({customerId:this.id,'is_issue_solved':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
