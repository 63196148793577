import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-customer-support-list',
  templateUrl: './customer-support-list.component.html',
  styleUrls: ['./customer-support-list.component.scss'],
})
export class CustomerSupportListComponent implements OnInit {
  currentPage = '';
  searchKey = '';
  constructor(
    private issueService: IssueServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private _route: Router,
    private router: ActivatedRoute
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  supportList = [];
  id: any = '';
  orginalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  data: any = {};
  ngOnInit() {
    this.data['userId'] = this.userdata.getId();
    if (this.currentPage == 'customer') {
      this.data['is_customer'] = true;
    } else {
      this.data['is_astrologer'] = true;
    }
    this.getAllCustomerSupport();
  }

  /* ***********
  list All Customer Support
  *********** */

  getAllCustomerSupport() {
    this.spinner.show();
    this.issueService.getAllSupport(this.data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.supportList = res.data;
        this.orginalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset =
          localStorage.getItem(`support_offset_${this.currentPage}`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  /**
   * Update filter
   */
  updateFilter(event: any) {
    //console.log(this.orginalList)
    const val = event.target.value.toLowerCase();
    this.searchByKey(val)
  }
  searchByKey(key: string) {
    let page = this.currentPage;
    //console.log(this.currentPage,key)
    if (key.length < 1) {
      this.supportList = this.orginalList;
      localStorage.removeItem(`support_offset_${this.currentPage}`);
    } else {
      this.supportList = this.orginalList.filter(function (d: any) {
        if (page == 'customer') {
          return (
            d.customerId?.name.toLowerCase().includes(key) ||
            d.supportId.toString().includes(key)
          );
        } else {
          return (
            d.astroId?.name.toLowerCase().include(key) ||
            d.astroId?.displayname.toLowerCase().include(key) ||
            d.astroId?.astroAutoId.toString().include(key) ||
            d.supportId.toString().include(key)
          );
        }
      });
      localStorage.setItem(`support_search_${this.currentPage}`, key);
      this.myFilterTable.offset = 0;
    }
  }

  onPageChange(event: any) {
    localStorage.setItem(`support_offset_${this.currentPage}`, event.target);
  }
  /**
   * Pending(Not replied) Issue
   */
  pendingIssue() {
    this.data['is_issue_solved'] = false;
    this.getAllCustomerSupport();
  }

  /**
   * Solved Issue
   */
  solvedIssue() {
    this.data['is_issue_solved'] = true;
    this.getAllCustomerSupport();
  }

  messageChatClick(id: any, isSystem: Boolean) {
    if (isSystem) {
      this._route.navigate(['/admin/supports/message', id]);
    } else {
      this._route.navigate([`/admin/supports/${this.currentPage}/reply`, id]);
    }
  }
}
