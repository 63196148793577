import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { SubcategoryMallService } from 'src/app/admin/services/subcategory-mall/subcategory-mall.service';

@Component({
  selector: 'app-add-mall-subcategory',
  templateUrl: './add-mall-subcategory.component.html',
  styleUrls: ['./add-mall-subcategory.component.scss']
})
export class AddMallSubcategoryComponent implements OnInit {
  dataList: any;

  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private subcategoryMallService: SubcategoryMallService,
    private route: Router,
    private categoryMallService: CategoryMallService,
    private spinner: NgxSpinnerService
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    categoryId: new FormControl('', [Validators.required]),
    subcategoryname: new FormControl('', Validators.required),
    subcategorydescription: new FormControl('', Validators.required),
    addedBy: new FormControl('', [Validators.required]),
    subcategory_slug: new FormControl('',[Validators.required,Validators.pattern('^[a-z0-9A-Z]+(?:-[a-z0-9A-Z]+)*$')]),
    subcategory_keyword: new FormControl(''),
    subcategory_image: new FormControl('', Validators.required),
    is_front_subcategory: new FormControl(false),
  });

  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
    this.getCategoryList()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Get category List
   */
   getCategoryList() {
    this.spinner.show()
    this.categoryMallService.listMallCategory({is_product_associated:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Category Image
   */
   imageError: string = ''
   imageSrc = ''
   public onBannerUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;
 
       reader.readAsDataURL(file);    
 
       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;    
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Image dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              subcategory_image: event.target.files[0]
             })
           }
         }
       };
     }
   }

   submit(){
     this.spinner.show()
    const data = new FormData()
    data.append("is_front_subcategory", this.formGroup.get("is_front_subcategory")?.value)
    data.append("userId", this.formGroup.get("userId")?.value)
    data.append("categoryId", this.formGroup.get("categoryId")?.value)
    data.append("subcategoryname", this.formGroup.get("subcategoryname")?.value)
    data.append("subcategorydescription", this.formGroup.get("subcategorydescription")?.value)
    data.append("addedBy", this.formGroup.get("addedBy")?.value)
    data.append("subcategory_slug", this.formGroup.get("subcategory_slug")?.value)
    data.append("subcategory_keyword", this.formGroup.get("subcategory_keyword")?.value)
    data.append("subcategory_image", this.formGroup.get("subcategory_image")?.value)
    this.subcategoryMallService.addMallSubcategory(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-subcategory/list')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  isUnique:boolean = true

  checkSlugUnique(event:any){
    this.subcategoryMallService.checkMallSubcategoryUnique({slug:event.target.value}).subscribe(
      (res:any)=>{
        if(res.success){
          this.isUnique = true
        }else{
          this.isUnique = false
        }
      }
    )
  }

}
