import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-reportorders',
  templateUrl: './list-reportorders.component.html',
  styleUrls: ['./list-reportorders.component.scss']
})
export class ListReportordersComponent implements OnInit {

  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private modalService: NgbModal,
    private toast: ToastrService
  ) { } 
  title = 'angular-datatables';

  reportList = [];
  id: any = '';
  temp: any = [];
  getReportData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getReportOrderList()
    this.reportList = this.getReportData;
  }

  /* ***********
  list All Report Orders
  *********** */

  getReportOrderList() {
    this.spinner.show()
    this.order.getReportOrderList({'userId':this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList
        this.getReportData =this.reportList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
updateFilter(event: any) {
  //console.log(this.temp)
  const val = event.target.value.toLowerCase();

  const temp = this.temp.filter(function (d: any) {
    return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.oreportId.toString().indexOf(val) !== -1 ||
        d.astroId?.name.toLowerCase().indexOf(val) !== -1 || !val
  });

  // update the rows
  this.reportList = temp;
  // Whenever the filter changes, always go back to the first page
  this.myFilterTable.offset = 0;
}
  /**
   * Not Answered Reports
   */
  notAnswered(){
    this.spinner.show()
    this.order.getReportOrderList({'userId':this.userdata.getId(),'is_astro_answer':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList;
        this.getReportData = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Not Reviewed Reports
   */
  notReviewed(){
    this.spinner.show()
    this.order.getReportOrderList({'userId':this.userdata.getId(),'is_order_reviewed':false,'is_astro_answer':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList;
        this.getReportData = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * No Customer feedback Reports
   */
  noCustomerFeedback(){
    this.spinner.show()
    this.order.getReportOrderList({'userId':this.userdata.getId(),'is_feedback_given':false,'is_astro_answer':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList;
        this.getReportData = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * No Astrologer feedback reply Reports
   */
  noAstrologerFeedbackReply(){
    this.spinner.show()
    this.order.getReportOrderList({'userId':this.userdata.getId(),'is_astro_replied_feedback':false,'is_astro_answer':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList;
        this.getReportData = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Mark Report as completed
   */
  markAsComplete(id:any){
    this.spinner.show()
    this.order.updateReportStatus({'reportId':id,'is_order_completed':true,'userId':this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.getReportOrderList()
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Completed Order
   */
   completedReportOrder(){
    this.spinner.show()
    this.order.getReportOrderList({'userId':this.userdata.getId(),'is_astro_answer':true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList;
        this.getReportData = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /** Answer Report */
  closeResult = '';
  singleData:any = ''
  open(content:any,data:any) {
    this.singleData = data
    this.formGroup.patchValue({ astro_answer: '' })
    this.characterCount = 0
    this.modalService.open(content, { size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  characterCount = 0
  countError = true
  countCharacters(event: any) {
    this.characterCount = event.target.value.replace(/\s/g, "").length
    if (this.characterCount >= 2000) {
      this.countError = false
    }
  }

  reportAnswer() {
    var msg = (this.formGroup.get('astro_answer')?.value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply', "This message cannot be sent as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.formGroup.patchValue({ astro_answer: msg })
    this.spinner.show()
    this.formGroup.patchValue({ reportId: this.singleData?._id })
    this.order.answerReportAdmin(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.getReportOrderList()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  formGroup: FormGroup = new FormGroup({
    reportId: new FormControl(''),
    astro_answer: new FormControl('', [Validators.required]),
  });
}
