<div class="pr-2 pl-2 d-flex justify-content-between">
  <input value="{{ searchKey }}" type="text" placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
  <div class="mt-3">
    <button class="btn btn-primary btn-sm mr-2" (click)="getReviewList()">
      All
    </button>
    <button class="btn btn-warning btn-sm mr-2" (click)="autoFlagged()">
      Auto Flagged
    </button>
    <button class="btn btn-info btn-sm mr-2" (click)="flagged()">
      Flagged
    </button>
  </div>
</div>
<br />
<ngx-datatable (page)="onPageChange($event)" class="material" [rows]="reviewList" [columnMode]="'force'"
  [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
  [sorts]="[{ dir: 'desc' }]" style="position: relative !important">
  <ngx-datatable-column [flexGrow]="1" [width]="70" name="ID" prop="reviewId">
    <ng-template let-row="row" ngx-datatable-cell-template>
      #{{ row.reviewId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Review" prop="customer_review">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.customer_review">{{ row.customer_review }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Stars" prop="customer_review_stars">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- {{row.customer_review_stars}} -->
      <ng-template #t let-fill="fill">
        <span class="review_star" [class.full]="fill === 100">
          <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
        </span>
      </ng-template>

      <ngb-rating [(rate)]="row.customer_review_stars" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.astroId?.name">#{{ row.astroId?.astroAutoId }}-{{
        row.astroId?.displayname == "" ||
        row.astroId?.displayname == undefined ||
        row.astroId?.displayname == null
        ? row.astroId?.name
        : row.astroId?.displayname
        }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="!row.isDirectReview">#{{ row.customerId?.customerAutoId }}-{{ row.customerId?.name }}</span>
      <span *ngIf="row.isDirectReview">{{ row.customerName }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Type">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.isDirectReview" class="badge badge-info">Direct</span>
      <span role="button" [routerLink]="['/admin/orders/chat/', row.orderserviceId?.chatId]"
        *ngIf="row.orderserviceId?.is_chat_order" class="badge badge-primary">Chat</span>
      <span role="button" [routerLink]="['/admin/orders/call/', row.orderserviceId?.callId]"
        *ngIf="row.orderserviceId?.is_call_order" class="badge badge-info">Call</span>
      <span role="button" [routerLink]="['/admin/orders/report/', row.orderserviceId?.reportId]"
        *ngIf="row.orderserviceId?.is_report_order" class="badge badge-success">Report</span>
      <span role="button" [routerLink]="['/admin/orders/query/', row.orderserviceId?.queryId]"
        *ngIf="row.orderserviceId?.is_query_order" class="badge badge-warning">Query</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Date" prop="review_given_date">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.review_given_date | date: "medium" }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="2" name="Action" prop="action">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button class="btn-user-custom btn btn-outline-primary btn-sm" (click)="deleteReview(row._id)">
        Delete
      </button>
      <span *ngIf="row.is_review_flagged" class="ml-2"><i class="fa fa-flag text-danger"></i></span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>