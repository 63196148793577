<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
      <h4 class="page-title">Reports</h4>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Reports</li>
      </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
      <div class="col-lg-12">
        <div class="card">
          <div class="border-top">
            <div class="wideget-user-tab">
              <div class="tab-menu-heading">
                <div class="tabs-menu1">
                  <ul ngbNav #nav="ngbNav" class="nav-tabs">
                    <li ngbNavItem>
                      <a ngbNavLink>Reports List</a>
                      <ng-template ngbNavContent>
                        <br>
                        <div class="row ">
                            <div class="col-md-4 col-xl-4" *ngFor="let report of data">
                                <div class="card ">
                                    <div class="card-body text-left">
                                        <img class="" [src]=getSanitizeUrl(report.report_image) alt="Astro Image" style="width: 100%;height: 200px;">
                                        <br/>
                                        <br/>
                                        <div class="d-flex justify-content-between">
                                          <h3>
                                            {{report.reportname}}
                                          </h3>
                                          <small class="text-muted">
                                            <a [routerLink]="['/admin/reports/editreports/', report._id]"><i class="fa fa-edit"></i></a>
                                          </small>
                                        </div>
                                         
                                        <div class="quote">
                                          <p>{{report.reportdescription}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- COL END -->
                        </div>
                      </ng-template>
                    </li>
                    <li ngbNavItem>
                      <a ngbNavLink>Add Report</a>
                      <ng-template ngbNavContent>
                        <br>
                        <div class="wideget-user-followers mb-0">
                          <app-add-edit-report></app-add-edit-report>
                        </div>
                      </ng-template>
                    </li>
                    <!-- <li ngbNavItem>
                      <a ngbNavLink>Blocked Customers</a>
                      <ng-template ngbNavContent>
                        <br>
                        <div class="wideget-user-followers mb-0">
                          <app-list-blocked-customers></app-list-blocked-customers>
                        </div>
                      </ng-template>
                    </li> -->
                  </ul>
                  <div [ngbNavOutlet]="nav"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
  </div>
  <!-- CONTAINER CLOSED -->









