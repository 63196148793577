import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-last-month-astro-payout',
  templateUrl: './last-month-astro-payout.component.html',
  styleUrls: ['./last-month-astro-payout.component.scss']
})
export class LastMonthAstroPayoutComponent implements OnInit {

  constructor(
    private astroService: AstroServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router
  ) { }
  title = 'angular-datatables';

  payoutList: any = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  year: any = ''
  month: any = ''
  getPayoutsData: any = []

  /**
   * -------
   */
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any = [];
  /**----------- */

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.masterSelected = false;
    var date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth()
    if (this.month == 0) {
      this.year = date.getFullYear() - 1
      this.month = 12
    }
    this.getMonthWisePayout()
    this.getCheckedItemList();

    // console.log("Current month => ",this.month)
    // console.log("Current Year => ",this.year)
  }

  /* ***********
  list payout according to month
  *********** */

  getMonthWisePayout() {
    this.spinner.show()
    this.astroService.getMonthWisePayout({ payoutyear: (this.year), payoutmonth: this.month, is_paid: false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        // console.log(res.data)
        this.getPayoutsData = res.data
        this.payoutList = res.data.map((x: any) => ({ ...x, isSelected: false }));;
        this.temp = this.payoutList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    // console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.astroId.name.toLowerCase().indexOf(val) !== -1 ||
        d.astroId.displayname.toLowerCase().indexOf(val) !== -1 ||
        d.astroId.astroAutoId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.payoutList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  astroIds: any = []
  getAstroId: any
  storeAstroId(evnt: any, id: any) {
    //console.log(evnt.target.checked,id)
    if (evnt.target.checked == true) {
      //console.log('push')
      this.astroIds.push(id)
    }
    if (evnt.target.checked == false) {
      //console.log('pop')
      this.astroIds.forEach((value: any, index: any) => {
        if (value == id) this.astroIds.splice(index, 1);
      });
    }
    //console.log(this.astroIds)
  }

  releasePayment() {
    //console.log(JSON.parse(this.checkedList))
    if (JSON.parse(this.checkedList).length == 0 || JSON.parse(this.checkedList) == null || JSON.parse(this.checkedList) == []) {
      this.toast.error('Please select atleast one astrologer')
      return
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to release payment?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.astroService.releasePayment({ is_paid: true, payoutIds: JSON.parse(this.checkedList) }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, "Success")
            location.reload();
            //this.router.navigateByUrl('/admin/payout/history')
          },
          (err) => {
            this.spinner.hide()
            this.toast.error(err.error.message, "Error")
          }
        );
      }
    })

  }

  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    for (var i = 0; i < this.payoutList.length; i++) {
      this.payoutList[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  // Check All Checkbox Checked
  isAllSelected() {
    this.masterSelected = this.payoutList.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  // Get List of Checked Items
  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.payoutList.length; i++) {
      if (this.payoutList[i].isSelected)
        this.checkedList.push(this.payoutList[i]._id);
    }
    this.checkedList = JSON.stringify(this.checkedList);
    //console.log(this.checkedList)
  }


}
