import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReviewServiceService } from 'src/app/admin/services/review/review-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-all-review',
  templateUrl: './list-all-review.component.html',
  styleUrls: ['./list-all-review.component.scss'],
})
export class ListAllReviewComponent implements OnInit {
  currentPage = '';
  searchKey = '';
  constructor(
    private reviewService: ReviewServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private router: ActivatedRoute
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';

  reviewList = [];
  id: any = '';
  originalList: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getReviewList();
    this.searchKey =
      localStorage.getItem(`review_search_${this.currentPage}`) || '';
  }

  /* ***********
  list Reviews
  *********** */

  getReviewList() {
    this.spinner.show();
    this.reviewService.getAllReview({}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.reviewList = res.data;
        this.originalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset =
          localStorage.getItem(`review_offset_${this.currentPage}`) || 0;
        if (offset > 1) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  /**
   * Update filter
   */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.reviewList = this.originalList;
      localStorage.removeItem(`review_search_${this.currentPage}`);
    } else {
      this.reviewList = this.originalList.filter(function (d: any) {
        return (
          d.customer_review.toLowerCase().includes(key) ||
          d.reviewId.toString().includes(key) ||
          d.customer_review_stars.toString().includes(key) ||
          d.astroId?.name.toLowerCase().includes(key) ||
          d.customerId?.name.toLowerCase().includes(key)
        );
      });
      localStorage.setItem(`review_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`review_offset_${this.currentPage}`, event.offset);
  }
  /**
   * Auto Flagged
   */
  autoFlagged() {
    this.spinner.show();
    this.reviewService.getAutoFlaggedReview({}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.reviewList = res.data;
        this.originalList = this.reviewList;
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  /**
  *  Flagged
  */
  flagged() {
    this.spinner.show();
    this.reviewService.getAllReview({ is_review_flagged: true }).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.reviewList = res.data;
        this.originalList = this.reviewList;
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }
  /**
   * delete review
   */
  deleteReview(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.reviewService.deleteReview({ _id: id }).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.toast.success(res.message, 'Success');
            this.getReviewList();
          },
          (err) => {
            this.spinner.hide();
            this.toast.error(err.message, 'Error');
          }
        );
      }
    });
  }
}
