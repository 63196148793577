import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminWalletService } from 'src/app/admin/services/admin-wallet/admin-wallet.service';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-admin-wallet',
  templateUrl: './add-admin-wallet.component.html',
  styleUrls: ['./add-admin-wallet.component.scss']
})
export class AddAdminWalletComponent implements OnInit {

  credit: any = true

  addWalletForm: any = new FormGroup({
    userId: new FormControl(''),
    is_payment_added_txn: new FormControl(true),
    amount: new FormControl('',[Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    txnId: new FormControl('',[Validators.required]),
    payment_gateway: new FormControl('',[Validators.required]),
    wallet_comments: new FormControl('',[Validators.required]),
    entry_by: new FormControl(''),
    is_admin_generate: new FormControl(true),
    is_manager_generate: new FormControl(false),
    txn_type: new FormControl('1'),
  })
    
  constructor(
    private spinner: NgxSpinnerService,
    private wallet: AdminWalletService,
    private toast: ToastrService,
    private route: Router,
    private userdata: UserdataService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.addWalletForm.patchValue({entry_by: this.userdata.getName()})
    this.addWalletForm.patchValue({userId: this.userdata.getId()
    })
  }

  get f(){
    return this.addWalletForm.controls
  }

  public onCreditSubmit(){
    this.credit = true
    this.spinner.show()
    this.wallet.addAdminWallet(this.addWalletForm.value).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.toast.success("Wallet Credited!", 'Sucess')
        this.reloadCurrentRoute()

        //console.log(res)
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Something went wrong!', 'Error')
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
}

}
