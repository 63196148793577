<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Remedy</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Suggest Remedy</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body row">
          <div class="col-12" *ngIf="!isRemedySuggested">
            <h3>Instructions: </h3>
            <table class="table table-bordered">
              <tr>
                <td [innerHTML]="instruction"></td>
              </tr>
            </table>
          </div>
          <div class="col-12">
            <div class="col-10">
              <h3>Order Details: </h3>
            </div>
            <div class="col-12">
              <table class="table table-bordered">
                <tr>
                  <td><strong>Order Type: </strong> <span *ngIf="orderData?.is_call_order">Call</span><span
                      *ngIf="orderData?.is_chat_order">Chat</span><span
                      *ngIf="orderData?.is_query_order">Query</span><span
                      *ngIf="orderData?.is_report_order">Report</span>
                  </td>
                  <td><strong>Order ID: </strong> #<span
                      *ngIf="orderData?.is_call_order">{{orderData?.callId?.ocallId}}</span><span
                      *ngIf="orderData?.is_chat_order">{{orderData?.chatId?.ochatId}}</span><span
                      *ngIf="orderData?.is_query_order">{{orderData?.queryId?.oquestionId}}</span><span
                      *ngIf="orderData?.is_report_order">{{orderData?.reportId?.oreportId}}</span></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-12" *ngIf="isRemedySuggested">
            <table class="table table-bordered">
              <tr>
                <th colspan="8" class="font-weight-bold">Remedy Details</th>
              </tr>
              <tr>
                <th>Sno.</th>
                <th>Type</th>
                <th>Product</th>
                <th>Description</th>
                <th>Suggested Astro</th>
                <th>Status</th>
                <th>Action</th>
                <th>Date</th>
              </tr>
              <ng-container *ngFor="let remedy of orderData?.remedysuggestIds; let i = index">
                <tr [ngClass]="{'bg-success text-white':remedy?.remedyId?.isSuggestedProductBought}">
                  <td>{{remedy?.remedyId?.rsId}}</td>
                  <td>{{remedy?.remedyId?.isPaid ? 'PAID':'FREE'}} <span class="badge badge-danger"
                      *ngIf="remedy?.remedyId?.isPaid">{{remedy?.remedyId?.isPaid &&
                      remedy?.remedyId?.is_created_by_astro_remedy ? 'Astro Suggested Product':'Astro Mall
                      Product'}}</span></td>
                  <td>
                    <span *ngIf="remedy?.remedyId?.isPaid; else noProduct">
                      {{remedy?.remedyId?.product_name == '' ? remedy?.remedyId?.suggestedProductId?.productname :
                      remedy?.remedyId?.product_name}}
                    </span>
                    <ng-template #noProduct>-</ng-template>
                  </td>
                  <td>{{remedy?.remedyId?.remedy_description == '' || remedy?.remedyId?.remedy_description == undefined
                    || remedy?.remedyId?.remedy_description == null ? '-': remedy?.remedyId?.remedy_description}}</td>
                  <td>{{remedy?.remedyId?.suggestedastroId == '' || remedy?.remedyId?.suggestedastroId == undefined ||
                    remedy?.remedyId?.suggestedastroId == null ? '-':remedy?.remedyId?.suggestedastroId?.name}}</td>
                  <td>{{remedy?.remedyId?.isSuggestedProductBought ? 'BOOKED':'NOT BOOKED'}}</td>
                  <td>
                    <button *ngIf="!remedy?.remedyId?.isSuggestedProductBought" class="btn btn-sm btn-primary"
                      (click)="deleteRemedy(remedy?.remedyId?._id)">Delete</button>
                  </td>
                  <td>{{remedy?.remedyId?.created_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
        <div class="col-12"><button class="btn btn-sm btn-primary mb-5" *ngIf="!suggestNewRemedy"
            (click)="suggestNewRemedy = !suggestNewRemedy">Suggest Remedy</button></div>
        <div class="card-body" *ngIf="suggestNewRemedy">
          <div>
            <form [formGroup]="formGroup" (submit)="suggestRemedy()">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <div>
                      <label for="is_addon_associate">Remedy Type *</label>
                    </div>
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                      formControlName="isPaid" (change)="isPaidChcek()">
                      <mat-radio-button class="example-radio-button" [value]="true" class="mr-2">
                        Paid
                      </mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="false">
                        Free
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="isPaid">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="categoryId">Select Category Name*</label>
                    <ng-select placeholder="Select Category" appendTo="body" [searchable]="true" [clearable]="true"
                      name="suggestedCategoryId" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCategory"
                      (change)="getCategory(selectedCategory)">
                      <ng-option selected disabled value=''>Select Category Name</ng-option>
                      <ng-option [value]="category['_id']" *ngFor="let category of categoriesList">
                        #{{category['categoryId']}} - {{category['categoryname']}}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="productId">Select Product Name*</label>
                    <ng-select placeholder="Select Product" appendTo="body" [searchable]="true" [clearable]="true"
                      formControlName="suggestedProductId" [(ngModel)]="suggestedProductId"
                      (change)="onProductChange(suggestedProductId)">
                      <ng-option selected disabled value=''>Select Product Name</ng-option>
                      <ng-option [value]="product._id" *ngFor="let product of productsList">
                        #{{product.productId}} - {{product.productname}}
                      </ng-option>
                    </ng-select>
                    <!-- <div
                      *ngIf="f.suggestedProductId.invalid && (f.suggestedProductId.dirty || f.suggestedProductId.touched)"
                      class="alert alert-danger mt-1">
                      Required
                    </div> -->
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group" *ngIf="!isDirectProduct">
                    <label for="productId"> Select Astrologer Name*</label>
                    <ng-select placeholder="Select Astrologer" appendTo="body" [searchable]="true" [clearable]="true"
                      formControlName="suggestedastroId" [(ngModel)]="selectedAstroId">
                      <ng-option selected disabled value=''>Select Astrologer</ng-option>
                      <ng-option [value]="astro.astroId._id" *ngFor="let astro of astroList">
                        #{{astro.astroId.astroAutoId}} - {{astro.astroId.name}}
                      </ng-option>
                    </ng-select>
                    <!-- <div *ngIf="f.suggestedAstroId.invalid && (f.suggestedAstroId.dirty || f.suggestedAstroId.touched)"
                      class="alert alert-danger mt-1">
                      Required
                    </div> -->
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="remedy_name">Remedy Name</label>
                    <input type="text" class="form-control " name="remedy_name" formControlName="remedy_name"
                      placeholder="Remedy Name">
                    <!-- <div *ngIf="f.remedy_name.invalid && (f.remedy_name.dirty || f.remedy_name.touched)" class="alert alert-danger mt-1">
                                            Required
                                        </div> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="remedy_description">Description</label>
                    <textarea name="" id="" cols="30" rows="3" class="form-control " name="remedy_description"
                      formControlName="remedy_description" placeholder="Description"></textarea>
                    <div
                      *ngIf="f.remedy_description.invalid && (f.remedy_description.dirty || f.remedy_description.touched)"
                      class="alert alert-danger mt-1">
                      Required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="user-wrap">
                    <!-- <button type="submit()" class="btn btn-primary mt-1 mb-2"><i
                        class="fa fa-rss"></i> Submit</button> -->
                    <button type="submit()" class="btn btn-primary mt-1 mb-2" [disabled]="!formGroup.valid"><i
                        class="fa fa-rss"></i> Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->