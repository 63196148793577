<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Offers</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/offers/all']">Offers</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateOffers()">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="offer_name">Offer Name</label>
                                        <input type="text" class="form-control" form placeholder="Offer Name"
                                            formControlName="offer_name">
                                        <div *ngIf="f.offer_name.invalid && (f.offer_name.dirty || f.offer_name.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="offer_display_name">Offer Display Name</label>
                                        <input type="text" class="form-control" form placeholder="Offer Display Name"
                                            formControlName="offer_display_name">
                                        <div *ngIf="f.offer_display_name.invalid && (f.offer_display_name.dirty || f.offer_display_name.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="offer_discount">Offer Amount (%)</label>
                                        <input type="text" class="form-control" id="offer_discount"
                                            placeholder="Offer Amount" formControlName="offer_discount">
                                        <div *ngIf="f.offer_discount.invalid && (f.offer_discount.dirty || f.offer_discount.touched)"
                                            class="alert alert-danger mt-1">
                                            Required. Only Number Allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="user_type">Offer For</label>
                                        <select formControlName="user_type" class="form-control">
                                            <option value="" selected disabled>Select Offer For</option>
                                            <option value="1">All</option>
                                            <option value="2">Repeat User</option>
                                            <option value="3">New User</option>
                                        </select>
                                        <div *ngIf="f.user_type.invalid && (f.user_type.dirty || f.user_type.touched)"
                                            class="alert alert-danger mt-1">
                                            Required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="offer_on">Offer On</label>
                                        <select formControlName="offer_on" class="form-control">
                                            <option value="" selected disabled>Select Offer On</option>
                                            <option value="call">Call</option>
                                            <option value="chat">Chat</option>
                                            <option value="query">Query</option>
                                            <option value="report">Report</option>
                                        </select>
                                        <div *ngIf="f.offer_on.invalid && (f.offer_on.dirty || f.offer_on.touched)"
                                            class="alert alert-danger mt-1">
                                            Required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="astrorivershare_indian">Astro River Share (%)</label>
                                        <input type="text" class="form-control" id="astrorivershare_indian"
                                            placeholder="Astro River Share (%)"
                                            formControlName="astrorivershare_indian">
                                        <div *ngIf="f.astrorivershare_indian.invalid && (f.astrorivershare_indian.dirty || f.astrorivershare_indian.touched)"
                                            class="alert alert-danger mt-1">
                                            Required. Only Number Allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->