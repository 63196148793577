import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-update-store-order',
  templateUrl: './update-store-order.component.html',
  styleUrls: ['./update-store-order.component.scss']
})
export class UpdateStoreOrderComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private orderService: OrderServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
  ) { }

  data: any = '';
  id: any = '';
  orderStatusList: any = []
  refundStatusList: any = []
  isShipped:boolean = false
  isCancelled = false
  isDefaultCancelled = false
  isCompleted = false
  isAutoPay:boolean = true

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ updatedId: this.userdata.getId() })
    //this.formGroup.patchValue({ updatedBy: this.userdata.getName() })
    this.formGroup.patchValue({ updatedBy: 'admin' })
    this.formGroup.patchValue({ _id: this.id })
    // this.formGroup.patchValue({ isAutoPayout: this.isAutoPay })
    this.isAutoPay
  }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    updatedId: new FormControl('', Validators.required),
    updatedBy: new FormControl('', Validators.required),
    isOrderCompleted: new FormControl(false),
    orderStatus: new FormControl('', Validators.required),
    astroComments: new FormControl(''),
    refundStatus: new FormControl(''),
    refundedAmount: new FormControl('',Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    shipmentCompany: new FormControl(''),
    trackingId: new FormControl(''),
    trackingUrl: new FormControl(''),
    payableAmountWithoutGst: new FormControl(''),
    astroAmount: new FormControl('',Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    consultantAmount: new FormControl('',Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    adminAmount: new FormControl('',Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    isAutoPayout: new FormControl(true)
  });

  getDataById() {
    this.spinner.show()
    this.orderService.getStoreOrderDetail({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data 
        // console.log(this.data)
        if(this.data.isAutoPayout)   {

          this.formGroup.patchValue({ payableAmountWithoutGst: this.data.payableAmountWithoutGst })
          this.formGroup.patchValue({ adminAmount: this.data.adminAmount })
          this.formGroup.patchValue({ astroAmount: this.data.astroAmount })
          this.formGroup.patchValue({ consultantAmount: this.data.consultantAmount })
        }  
        if(!this.data.isAutoPayout)   {
          this.formGroup.patchValue({ payableAmountWithoutGst: this.data.payableAmountWithoutGst })
          this.formGroup.patchValue({ adminAmount: '' })
          this.formGroup.patchValue({ astroAmount: '' })
          this.formGroup.patchValue({ consultantAmount: '' })
        }  

        if(this.data.isOrderCompleted)   {
          this.toast.error("You cann't update completed order",'Error')
          return
        }  
        this.formGroup.patchValue({ refundedAmount: this.data.refundedAmount })
        if (this.data.orderStatus != 6) {
          this.orderStatusList = [
            { 'value': 2, 'name': 'Confirmed' },
            { 'value': 3, 'name': 'Shipped' },
            { 'value': 4, 'name': 'Out For Delivery' },
            { 'value': 5, 'name': 'Delivered' },
            { 'value': 6, 'name': 'Cancelled' },
            { 'value': 8, 'name': 'Completed' },
          ]
        } else {
          this.formGroup.patchValue({ orderStatus: this.data.orderStatus })
          this.isDefaultCancelled = true
          this.refundStatusList = [
            { 'value': '2', 'name': 'Refund Approved' },
            { 'value': '3', 'name': 'Refund Rejected' },
          ]
        }
        // console.log("form value in single is ", this.formGroup.value)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/order/store')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Update Order STatus
 *********** */

  public updateOrderStatus() {    
    if(!this.isShipped){
      this.formGroup.patchValue({shipmentCompany:''})
      this.formGroup.patchValue({trackingId:''})
      this.formGroup.patchValue({trackingUrl:''})
    }
    if(!this.isCancelled && !this.isDefaultCancelled){
      this.formGroup.patchValue({refundStatus:0})
      this.formGroup.patchValue({refundedAmount:0})
      this.formGroup.patchValue({astroComments:''})
    }
    if((this.isCancelled || this.isDefaultCancelled) && this.formGroup.get('astroComments')?.value == ''){
      this.toast.error('Please Provide Reason','Error')
      return
    }
    if((this.isCancelled || this.isDefaultCancelled) && this.formGroup.get('refundStatus')?.value == 0){
      this.toast.error('Please Select Refund Status','Error')
      return
    }
    if((this.isCancelled || this.isDefaultCancelled) && this.formGroup.get('refundedAmount')?.value < 0){
      this.toast.error('Please Provide Refund Amount','Error')
      return
    }
    if(this.isCompleted){
      //console.log(this.formGroup.value)
      // this.formGroup.patchValue({adminAmount:0})
      // this.formGroup.patchValue({astroAmount:0})
      // this.formGroup.patchValue({consultantAmount:0})
    }

    // return
    this.spinner.show();
    this.orderService.updateStoreOrder(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success');
        this.route.navigateByUrl('/admin/orders/store/' + this.id)
        //this.route.navigateByUrl('/astrologer/remedy')
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Something went wrong!', 'Error');
        this.toast.error(err.error.message, 'Error')
      })
  }

  /**
   * Check Order status
   */
  checkOrderStatus(event:any) {
    this.formGroup.patchValue({isOrderCompleted:false})
    this.isShipped = false
    this.isCancelled = false
    this.isDefaultCancelled = false
    this.isCompleted = false
    var val = event.target.value
    if(val == 3){
      this.isShipped = true
    }
    if(val == 6){
      this.isCancelled = true
      this.refundStatusList = [
        { 'value': '2', 'name': 'Refund Approved' },
        { 'value': '3', 'name': 'Refund Rejected' },
      ]
    }
    if(val == 8){
      this.isCompleted = true
      this.formGroup.patchValue({isOrderCompleted:true})
      this.formGroup.patchValue({orderStatus:8})
    }

  }
  /**
   * changeAutoPay
   */
   changeAutoPay()
   {
    //  console.log("before",this.isAutoPay)
     this.isAutoPay = !this.isAutoPay
     this.formGroup.patchValue({ isAutoPayout: this.isAutoPay})
    //  console.log("after",this.isAutoPay)
    if(this.isAutoPay)
    {
      this.formGroup.patchValue({ payableAmountWithoutGst: this.data.payableAmountWithoutGst })

      this.formGroup.patchValue({ adminAmount: this.data.adminAmount })
      this.formGroup.patchValue({ astroAmount: this.data.astroAmount })
      this.formGroup.patchValue({ consultantAmount: this.data.consultantAmount })
    }
    else{
      this.formGroup.patchValue({ payableAmountWithoutGst: this.data.payableAmountWithoutGst })

      this.formGroup.patchValue({ adminAmount: '0' })
      this.formGroup.patchValue({ astroAmount: '0' })
      this.formGroup.patchValue({ consultantAmount: '0' })
    }
   }
}
