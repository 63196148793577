<div class="pr-2 pl-2 d-flex justify-content-between">
  <input type="text"  placeholder="Search ID, Display Name, Name" class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"   />
  <div class="row mr-2">
    <button class="btn-user-custom btn btn-outline-primary btn-sm" (click)="releasePayment()">Release payment</button>
  </div>
</div>
<br>
<div class="col-12 pr-2">
  <input type="checkbox" class="mr-2" [(ngModel)]="masterSelected" name="list_name" value="m1"
    (change)="checkUncheckAll()" /> <strong>Select/ Unselect All</strong>
</div>
<ngx-datatable class="material" [rows]="payoutList" [columnMode]="'force'"
[headerHeight]="50" [footerHeight]="50" [rowHeight]="60"
[scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
<ngx-datatable-column name="Pay">
  <ng-template let-row="row" ngx-datatable-cell-template>
    <input type="checkbox" [(ngModel)]="row.isSelected" name="list_name" value="{{row.id}}"
              (change)="isAllSelected()" />
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="ID" prop="astroId?.astroAutoId">
    <ng-template let-row="row" ngx-datatable-cell-template>
        #{{row.astroId?.astroAutoId}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Display Name" prop="astroId?.displayname">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Name" prop="astroId?.name">
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.astroId?.name}}
    </ng-template>
</ngx-datatable-column>
<!-- <ngx-datatable-column name="Wallet Amount"
    prop="current_wallet">
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.current_wallet | currency:'INR' : 'symbol-narrow'}}
    </ng-template>
</ngx-datatable-column> -->
<ngx-datatable-column name="Earning" prop="finalpayout">
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.finalpayout | currency:'INR' : 'symbol-narrow'}}
    </ng-template>
</ngx-datatable-column>
</ngx-datatable>
