import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-associate-astro',
  templateUrl: './add-associate-astro.component.html',
  styleUrls: ['./add-associate-astro.component.scss']
})
export class AddAssociateAstroComponent implements OnInit {

  id:any;
  astroList:any = [];
  data: any;
  BaseUrl: string;

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private astroService: AstroServiceService,
    private productMallService: ProductMallService,
    private route: Router,
    private fb:FormBuilder,
    private activatedRoute: ActivatedRoute,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngOnInit(): void {
    this.getAstrologerList()
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({'userId':this.userdata.getId()})
    this.formGroup.patchValue({'_id':this.id})
  }

  /**
   * Get Product Details
   */
  getDataById() {
    this.spinner.show()
    this.productMallService.detailMallProduct({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-product/list')
      }
    )
  }

  formGroup: any = this.fb.group({
    _id: new FormControl(''),
    astrosList: this.fb.array([]),
    astrologerList: new FormControl(''),
    userId: new FormControl('',Validators.required),
  });

  asrologersList() : FormArray {
    return this.formGroup.get("astrosList") as FormArray
  }

  newAstrologersList(): FormGroup {
    return this.fb.group({
      // astroId: [,Validators.required],
      // astroprice: ['',Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")],
      // finalprice: ['',Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")],
      astroId: null,
      astroprice: '',
      finalprice: '',
      usd_price: '',
    })
  }

  public addAstrologersList() {
    this.asrologersList().push(this.newAstrologersList());
  }

  public removeAstrologersList(i:number) {
    this.asrologersList().removeAt(i);
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  get Astrologers List
 *********** */

  getAstrologerList(){
    this.astroService.getAstrologerList({is_approved_used:true,is_approved:true,is_delete: false}).subscribe(
      (res:any)=>{
        this.astroList = res.data
      },err=>{
        // console.log(err)
        this.toast.error("Something went wrong","Error")
      }
    )
  }

  /* ***********
  Add Associative Astrologers
 *********** */

  public addAssociativeAstros() {
    this.spinner.show()
    if(this.formGroup.value.astrosList.length <=0) {
      this.spinner.hide()
      Swal.fire('Please add atleast one astrologer.','Error')
      return
    }
    for(var i=0; i<this.formGroup.value.astrosList.length; i++) {
      if(this.formGroup.value.astrosList[i].astroId == ""
      || this.formGroup.value.astrosList[i].astroprice == ""
      || this.formGroup.value.astrosList[i].finalprice == ""
      || this.formGroup.value.astrosList[i].usd_price == "") {
        this.spinner.hide()
        Swal.fire('Please fill the complete details.','Error')
        return
      }


      if(!this.formGroup.value.astrosList[i].astroprice.match(/^[1-9]\d*(\.\d+)?$/)){
        this.spinner.hide()
        Swal.fire('Only numbers are allowed for astro price value.','Error')
        return
      }

      if(!this.formGroup.value.astrosList[i].finalprice.match(/^[1-9]\d*(\.\d+)?$/)){
        this.spinner.hide()
        Swal.fire('Only numbers are allowed for final price value.','Error')
        return
      }

      if(!this.formGroup.value.astrosList[i].usd_price.match(/^[1-9]\d*(\.\d+)?$/)){
        this.spinner.hide()
        Swal.fire('Only numbers are allowed for final USD price value.','Error')
        return
      }
    }
    var data = JSON.stringify(this.formGroup.get("astrosList").value)
    this.formGroup.patchValue({'astrologerList': data})
    this.productMallService.addAssociativeAstrologersInProduct(this.formGroup.value).subscribe(
      (res:any) =>{
        this.spinner.hide()
        this.toast.success(res.message,'success')
        this.route.navigateByUrl('/admin/mall-product/list')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

}

