<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Sub-Category</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="categoryId">Category *</label>
                                    <ng-select placeholder="Select Category" appendTo="body"
                                            [searchable]="true" [clearable]="true" formControlName="categoryId">
                                            <ng-option selected disabled value=''>Select Category</ng-option>
                                            <ng-option [value]="category._id" *ngFor="let category of dataList">{{category.categoryname}}
                                            </ng-option>
                                        </ng-select>
                                    <small
                                        *ngIf="f.categoryId.invalid && (f.categoryId.dirty || f.categoryId.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="subcategoryname">Name *</label>
                                    <input type="text" class="form-control" placeholder="Sub-Category Name"
                                        formControlName="subcategoryname">
                                    <small
                                        *ngIf="f.subcategoryname.invalid && (f.subcategoryname.dirty || f.subcategoryname.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="subcategory_image">Image * (Image should be 960 X 540)</label>
                                    <input type="file" class="form-control" (change)="onBannerUpload($event)">
                                    <small
                                        *ngIf="f.subcategory_image.invalid && (f.subcategory_image.dirty || f.subcategory_image.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required.
                                    </small>
                                    <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                                        {{imageError}}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="subcategorydescription">Description*</label>
                                    <textarea class="form-control" formControlName="subcategorydescription"></textarea>
                                    <small
                                        *ngIf="f.subcategorydescription.invalid && (f.subcategorydescription.dirty || f.subcategorydescription.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required.
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="subcategory_slug">Sub-Category Slug*</label>
                                    <input type="text" class="form-control" placeholder="Sub-Category Slug"
                                        formControlName="subcategory_slug" (change)="checkSlugUnique($event)">
                                    <small
                                        *ngIf="f.subcategory_slug.invalid && (f.subcategory_slug.dirty || f.subcategory_slug.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Slug can only contain '-' and no space or any special symbol.
                                    </small>
                                    <small *ngIf="!isUnique" class="d-block alert alert-danger mt-1">
                                        Slug should be unique and this slug has been already used.
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="subcategory_keyword">Keywords</label>
                                    <input type="text" class="form-control" placeholder="Meta Keywords"
                                        formControlName="subcategory_keyword">
                                    <small
                                        *ngIf="f.subcategory_keyword.invalid && (f.subcategory_keyword.dirty || f.subcategory_keyword.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="checkbox" class="mr-2" formControlName="is_front_subcategory"
                                        [value]="true">
                                    <label for="is_front_subcategory">Highlight Subcategory on Customer Frontpage *</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="col-sm-4 btn btn-success mt-1"
                            [disabled]="!formGroup.valid || !isUnique">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->
