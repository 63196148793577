import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AstroServiceService } from '../admin/services/astroservice/astro-service.service';
import { LanguageDataService } from '../admin/services/language/language-data.service';
import { UserdataService } from '../admin/services/shared/userdata/userdata.service';
import { SkillsServiceService } from '../admin/services/skills/skills-service.service';

@Component({
  selector: 'app-add-astrologer-open',
  templateUrl: './add-astrologer-open.component.html',
  styleUrls: ['./add-astrologer-open.component.scss']
})
export class AddAstrologerOpenComponent implements OnInit {

  addAstrologerForm: any = new FormGroup({ 
    name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required]),
    phone: new FormControl('',[Validators.required, Validators.pattern('[6-9]{1}[0-9]{9}')]),
    gender: new FormControl('',[Validators.required]),
    alternate_num: new FormControl('',[Validators.pattern('[6-9]{1}[0-9]{9}')]),
    profile_image: new FormControl('',[Validators.required]),
    birth_date: new FormControl('',[Validators.required]),
    birth_month: new FormControl('',[Validators.required]),
    birth_year: new FormControl('',[Validators.required]),
    consultant_type: new FormControl('Astrologer'),
    experience: new FormControl('',[Validators.required,Validators.pattern('[0-9]{1,2}')]),
    lang: new FormControl('',[Validators.required]),
    skill: new FormControl('',[Validators.required]),
    complete_address: new FormControl('',[Validators.required]),
    city: new FormControl('',[Validators.required]),
    state: new FormControl('',[Validators.required]),
    country: new FormControl('',[Validators.required]),
    pincode: new FormControl('',[Validators.required]),
    bank_account_num: new FormControl('',[Validators.required]),
    account_type: new FormControl('',[Validators.required]),
    ifsc: new FormControl('',[Validators.required]),
    account_holder_name: new FormControl('',[Validators.required]),
    pan_number: new FormControl('',[Validators.required]),
    addhar_number: new FormControl('',[Validators.required, Validators.pattern('[0-9]{12}')]),
    working_with_other: new FormControl('',[Validators.required]),
    short_bio: new FormControl('',[Validators.required]),
    long_bio: new FormControl('',[Validators.required]),
    proof_image: new FormControl('',[Validators.required])
  })

    rows = [];
    skillsData: any = [];
    languageData: any = [];
    public skills:Array<any>= [];
    public languages:Array<any>= [];
    i: any;
    day: any =[];
    month: any =[];
    year: any =[];
    selectedLanguage:any = [];
    selectedLanguageJson: any =[];
    selectedSkills:any = [];
    selectedSkillsJson: any =[];
    getWorkingStatus: Boolean | any;

    
  constructor(
    private spinner: NgxSpinnerService,
    private skillsService: SkillsServiceService,
    private userdata: UserdataService,
    private language: LanguageDataService,
    private astroService: AstroServiceService,
    private toast: ToastrService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.getSkillsList();
    this.getLaguangeList();
  }

  proofError: string ='';
  public onProofImageUpload(evnt: any){
    if(evnt.target.files.length > 0)
    {
      this.proofError = ''
      if(evnt.target.files.length > 0)
      {
        if (evnt.target.files && evnt.target.files[0]) {
          const allowed_types = ['image/png', 'image/jpeg'];
          if (!_.includes(allowed_types, evnt.target.files[0].type)) {
            this.proofError = 'Only Images are allowed ( JPG | PNG )';
            return;
          }else{
            this.addAstrologerForm.patchValue({
              proof_image: evnt.target.files[0]
            })
          }
        }
      }
    }
  }

  profileError: string ='';
  public onProfileImageUpload(evnt: any){
    this.profileError = ''
    if(evnt.target.files.length > 0)
    {
      if (evnt.target.files && evnt.target.files[0]) {
        const allowed_types = ['image/png', 'image/jpeg'];
        if (!_.includes(allowed_types, evnt.target.files[0].type)) {
          this.profileError = 'Only Images are allowed ( JPG | PNG )';
          return;
        }else{
          this.addAstrologerForm.patchValue({
            profile_image: evnt.target.files[0]
          })
        }
      }
    }
  }

  getLaguangeList() {
    this.spinner.show()
    this.language.getLaguangeList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.languageData = res.data;
        for( this.i = 0; this.i<this.languageData.length; this.i++) {
          this.languages.push({'display': this.languageData[this.i].name, 'value': this.languageData[this.i]._id});
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getSkillsList() {
    this.spinner.show()
    this.skillsService.getSkillsList({}).subscribe(
     (res: any) => {
       this.spinner.hide()
       this.skillsData = res.data;
       for( this.i = 0; this.i<this.skillsData.length; this.i++) {
         this.skills.push({'display': this.skillsData[this.i].skillname, 'value': this.skillsData[this.i]._id});
       }
     },
     (err) => {
       this.spinner.hide()
       //console.log(err);
     }
   );
 }

  get f(){
    return this.addAstrologerForm.controls
  }

  //-------tags json------//

  public onAddAstrologerSubmit(){
    this.spinner.show()
    var choosenLangues = this.addAstrologerForm.get("lang").value
    choosenLangues.forEach((element: { value: any; }) => {
      this.selectedLanguage.push(element.value)
    });
    this.selectedLanguage.forEach((element: any) => {
      this.selectedLanguageJson.push({"langId":element})
    });

    var choosenSkills = this.addAstrologerForm.get("skill").value
    choosenSkills.forEach((element: { value: any; }) => {
      this.selectedSkills.push(element.value)
    });
    this.selectedSkills.forEach((element: any) => {
      this.selectedSkillsJson.push({"skillId":element})
    });
    //-------/tags json-----//

    var convertBoolean = this.addAstrologerForm.get("working_with_other").value
     
     if(convertBoolean == "yes"){
       this.getWorkingStatus = true;
     } else {
      this.getWorkingStatus = false;
     }

    const data = new FormData()
    data.append("name",this.addAstrologerForm.get("name").value)
    data.append("email",this.addAstrologerForm.get("email").value)
    data.append("password",this.addAstrologerForm.get("password").value)
    data.append("phone",this.addAstrologerForm.get("phone").value)
    data.append("gender",this.addAstrologerForm.get("gender").value)
    data.append("alternate_num",this.addAstrologerForm.get("alternate_num").value)
    data.append("profile_image",this.addAstrologerForm.get("profile_image").value)
    data.append("birth_date",this.addAstrologerForm.get("birth_date").value)
    data.append("birth_month",this.addAstrologerForm.get("birth_month").value)
    data.append("birth_year",this.addAstrologerForm.get("birth_year").value)
    data.append("consultant_type",this.addAstrologerForm.get("consultant_type").value)
    data.append("experience",this.addAstrologerForm.get("experience").value)
    data.append("lang", JSON.stringify(this.selectedLanguageJson))
    data.append("skill", JSON.stringify(this.selectedSkillsJson))
    data.append("complete_address",this.addAstrologerForm.get("complete_address").value)
    data.append("city",this.addAstrologerForm.get("city").value)
    data.append("state",this.addAstrologerForm.get("state").value)
    data.append("country",this.addAstrologerForm.get("country").value)
    data.append("pincode",this.addAstrologerForm.get("pincode").value)
    data.append("bank_account_num",this.addAstrologerForm.get("bank_account_num").value)
    data.append("account_type",this.addAstrologerForm.get("account_type").value)
    data.append("ifsc",this.addAstrologerForm.get("ifsc").value)
    data.append("account_holder_name",this.addAstrologerForm.get("account_holder_name").value)
    data.append("pan_number",this.addAstrologerForm.get("pan_number").value)
    data.append("addhar_number",this.addAstrologerForm.get("addhar_number").value)
    data.append("working_with_other",this.getWorkingStatus)
    data.append("short_bio",this.addAstrologerForm.get("short_bio").value)
    data.append("long_bio",this.addAstrologerForm.get("long_bio").value)
    data.append("proof_image",this.addAstrologerForm.get("proof_image").value)   

    this.astroService.addAstrologerOpen(data).subscribe(
      (res:any) => {
        this.spinner.hide()
        Swal.fire('Astrologer Registered Successfully!',"We'll contact you shortly","success")
        window.location.href = 'https://astroriver.com';
        //this.toast.success('Astrologer added!', 'Sucess')
        //this.reloadCurrentRoute()
      },
      err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

}
