<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">User</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">User</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->

    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="border-0">
                        <div class="tab-content">
                            <div class="tab-pane active show" id="tab-51">
                                <div id="profile-log-switch">
                                    <div class="media-heading">
                                        <h4>{{data.name}}</h4>
                                    </div>
                                    <hr class="m-0">
                                    <div class="table-responsive ">
                                        <table class="table row table-borderless">
                                            <tbody class="col-lg-12 col-xl-6 p-0">
                                                <tr>
                                                    <td><strong>Full Name :</strong> {{data?.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Email :</strong> {{data?.email}}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Phone :</strong> +{{data?.phone}}</td>
                                                </tr>
                                                
                                            </tbody>
                                            <tbody class="col-lg-12 col-xl-6 p-0">
                                                <tr>
                                                    <td><strong>Role :</strong> {{data?.role}}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Blocked :</strong> {{!data.is_blocked ?'No': 'YES'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <div class="user-wrap">
                                            <button class="btn btn-primary mt-1 mb-2" routerLink="/admin/usersList">Go to List of Users</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->