import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  BASEURI: string = ""
  OPENURI: string = ""
  constructor(private http: HttpClient, @Inject('BASE_URL') _baseUrl:  string,@Inject('OPEN_BASE_URL') _openBaseUrl:  string) {
    this.BASEURI = _baseUrl
    this.OPENURI = _openBaseUrl
   }

   login(form: any){
    return this.http.post(this.BASEURI + '/adminlogin', form)
  }

  /**
   * Delete FCM Token
   */
   deleteFcm(form: any){
    return this.http.post(this.OPENURI + '/deletefcm', form)
  }
}
