<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Astrologer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Astrologer</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul class="nav-tabs nav mb-4">
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/list']" class="nav-link px-3"
                                            routerLinkActive="active">List</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/request']" class="nav-link px-3"
                                            routerLinkActive="active">New Request</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/approved']" class="nav-link px-3"
                                            routerLinkActive="active">Approved</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/rejected']" class="nav-link px-3"
                                            routerLinkActive="active">Rejected</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/active']" class="nav-link px-3"
                                            routerLinkActive="active">Active</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/blocked']" class="nav-link px-3"
                                            routerLinkActive="active">Blocked</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/onoff']" class="nav-link px-3"
                                            routerLinkActive="active">Online</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/call-active']" class="nav-link px-3"
                                            routerLinkActive="active">Call </a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/chat-active']" class="nav-link px-3"
                                            routerLinkActive="active">Chat </a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/query-active']" class="nav-link px-3"
                                            routerLinkActive="active">Query </a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/report-active']" class="nav-link px-3"
                                            routerLinkActive="active">Report </a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/ongoing-call-chat']" class="nav-link px-3"
                                            routerLinkActive="active">OnGoing Call/Chat</a>
                                    </li>
                                    <li class=" nav-item mr-1">
                                        <a [routerLink]="['/admin/astro/new']" class="nav-link px-3"
                                            routerLinkActive="active">New</a>
                                    </li>
                                </ul>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->