import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddAstrologerOpenComponent } from './add-astrologer-open/add-astrologer-open.component';
import { AuthGuard } from './admin/auth/authguard';
import { ListAdminWalletComponent } from './admin/component/admin-wallet/list-admin-wallet/list-admin-wallet.component';
import { AssignPromoComponent } from './admin/component/assign-promo/assign-promo.component';
import { AddEditAstrologerProfileComponent } from './admin/component/astrologer/add-edit-astrologer-profile/add-edit-astrologer-profile.component';
import { AstrologersListComponent } from './admin/component/astrologer/astrologers-list/astrologers-list.component';
import { CommonAstroPageComponent } from './admin/component/astrologer/common-astro-page/common-astro-page.component';
import { EditAstrologerComponent } from './admin/component/astrologer/edit-astrologer/edit-astrologer.component';
import { OrderMonthWiseComponent } from './admin/component/astrologer/payout/order-month-wise/order-month-wise.component';
import { PayoutComponent } from './admin/component/astrologer/payout/payout/payout.component';
import { WalletMonthWiseComponent } from './admin/component/astrologer/payout/wallet-month-wise/wallet-month-wise.component';
import { ViewAstrologerProfileComponent } from './admin/component/astrologer/view-astrologer-profile/view-astrologer-profile.component';
import { ListBannerComponent } from './admin/component/banner/list-banner/list-banner.component';
import { AddCommonBlogComponent } from './admin/component/blog/add-common-blog/add-common-blog.component';
import { ApproveRejectBlogComponent } from './admin/component/blog/approve-reject-blog/approve-reject-blog.component';
import { AddBlogCategoryComponent } from './admin/component/blog/category/add-blog-category/add-blog-category.component';
import { CommonBlogCategoryComponent } from './admin/component/blog/category/common-blog-category/common-blog-category.component';
import { EditBlogCategoryComponent } from './admin/component/blog/category/edit-blog-category/edit-blog-category.component';
import { ListBlogCategoryComponent } from './admin/component/blog/category/list-blog-category/list-blog-category.component';
import { CommonBlogComponent } from './admin/component/blog/common-blog/common-blog.component';
import { DetailBlogComponent } from './admin/component/blog/detail-blog/detail-blog.component';
import { EditCommonBlogComponent } from './admin/component/blog/edit-common-blog/edit-common-blog.component';
import { ListCommonBlogComponent } from './admin/component/blog/list-common-blog/list-common-blog.component';
import { ListNewBlogComponent } from './admin/component/blog/list-new-blog/list-new-blog.component';
import { ListRejectBlogComponent } from './admin/component/blog/list-reject-blog/list-reject-blog.component';
import { ListCouponComponent } from './admin/component/coupon/list-coupon/list-coupon.component';
import { AddRechargeComponent } from './admin/component/customer-wallet-recharge/add-recharge/add-recharge.component';
import { CustomerWalletRechargeComponent } from './admin/component/customer-wallet-recharge/customer-wallet-recharge.component';
import { EditRechargeComponent } from './admin/component/customer-wallet-recharge/edit-recharge/edit-recharge.component';
import { ListRechargeComponent } from './admin/component/customer-wallet-recharge/list-recharge/list-recharge.component';
import { AddCustomerComponent } from './admin/component/customer/add-customer/add-customer.component';
import { CommonCustomerPageComponent } from './admin/component/customer/common-customer-page/common-customer-page.component';
import { EditCustomerComponent } from './admin/component/customer/edit-customer/edit-customer.component';
import { ListCustomerComponent } from './admin/component/customer/list-customer/list-customer.component';
import { ShowSignleCustomerDetailComponent } from './admin/component/customer/show-signle-customer-detail/show-signle-customer-detail.component';
import { ViewCustomerComponent } from './admin/component/customer/view-customer/view-customer.component';
import { CustomizeComponent } from './admin/component/customize/customize/customize.component';
import { DashboardComponent } from './admin/component/dashboard/dashboard.component';
import { AddFestivalComponent } from './admin/component/festival/add-festival/add-festival.component';
import { AddYearlyFestivalComponent } from './admin/component/festival/add-yearly-festival/add-yearly-festival.component';
import { CommonFestivalComponent } from './admin/component/festival/common-festival/common-festival.component';
import { DetailFestivalComponent } from './admin/component/festival/detail-festival/detail-festival.component';
import { EditFestivalComponent } from './admin/component/festival/edit-festival/edit-festival.component';
import { EditYearlyFestivalComponent } from './admin/component/festival/edit-yearly-festival/edit-yearly-festival.component';
import { ListFestivalComponent } from './admin/component/festival/list-festival/list-festival.component';
import { ListYearlyFestivalComponent } from './admin/component/festival/list-yearly-festival/list-yearly-festival.component';
import { ForgotPasswordComponent } from './admin/component/forgot-password/forgot-password.component';
import { AllIssueComponent } from './admin/component/issues/all-issue/all-issue.component';
import { AstrologerIssueComponent } from './admin/component/issues/astrologer-issue/astrologer-issue.component';
import { CustomerIssueComponent } from './admin/component/issues/customer-issue/customer-issue.component';
import { DetailAstrologerIssueComponent } from './admin/component/issues/detail-astrologer-issue/detail-astrologer-issue.component';
import { DetailCustomerIssueComponent } from './admin/component/issues/detail-customer-issue/detail-customer-issue.component';
import { ReplyAstrologerIssueComponent } from './admin/component/issues/reply-astrologer-issue/reply-astrologer-issue.component';
import { ReplyCustomerIssueComponent } from './admin/component/issues/reply-customer-issue/reply-customer-issue.component';
import { EditLanguageComponent } from './admin/component/language/edit-language/edit-language.component';
import { LanguagesListComponent } from './admin/component/language/languages-list/languages-list.component';
import { LoginComponent } from './admin/component/login/login.component';
import { MainDashboardComponent } from './admin/component/main-dashboard/main-dashboard.component';
import { AddMallCategoryComponent } from './admin/component/mall/category/add-mall-category/add-mall-category.component';
import { CommonMallCategoryComponent } from './admin/component/mall/category/common-mall-category/common-mall-category.component';
import { DetailMallCategoryComponent } from './admin/component/mall/category/detail-mall-category/detail-mall-category.component';
import { EditMallCategoryComponent } from './admin/component/mall/category/edit-mall-category/edit-mall-category.component';
import { ListMallCategoryComponent } from './admin/component/mall/category/list-mall-category/list-mall-category.component';
import { AddAssociateAstroComponent } from './admin/component/mall/product/add-associate-astro/add-associate-astro.component';
import { AddMallProductComponent } from './admin/component/mall/product/add-mall-product/add-mall-product.component';
import { AddAssociateAddonComponent } from './admin/component/mall/product/add-associate-addon/add-associate-addon.component';
import { CommonMallProductComponent } from './admin/component/mall/product/common-mall-product/common-mall-product.component';
import { DetailMallProductComponent } from './admin/component/mall/product/detail-mall-product/detail-mall-product.component';
import { ListMallProductComponent } from './admin/component/mall/product/list-mall-product/list-mall-product.component';
import { AddManagerComponent } from './admin/component/manager/add-manager/add-manager.component';
import { DetailManagerComponent } from './admin/component/manager/detail-manager/detail-manager.component';
import { EditManagerComponent } from './admin/component/manager/edit-manager/edit-manager.component';
import { ListManagerComponent } from './admin/component/manager/list-manager/list-manager.component';
import { ManagerLoginComponent } from './admin/component/manager/manager-login/manager-login.component';
import { ViewManagerComponent } from './admin/component/manager/view-manager/view-manager.component';
import { NotesComponent } from './admin/component/notes/notes.component';
import { NotificationComponent } from './admin/component/notification/notification.component';
import { SendNotificationComponent } from './admin/component/notification/send-notification/send-notification.component';
import { ViewNotificationComponent } from './admin/component/notification/view-notification/view-notification.component';
import { AddOffersComponent } from './admin/component/offers/add-offers/add-offers.component';
import { AstroOffersPriceComponent } from './admin/component/offers/astro-offers-price/astro-offers-price.component';
import { EditOffersComponent } from './admin/component/offers/edit-offers/edit-offers.component';
import { ListOffersComponent } from './admin/component/offers/list-offers/list-offers.component';
import { OffersComponent } from './admin/component/offers/offers.component';
import { CallDetailsComponent } from './admin/component/orders/call-details/call-details.component';
import { ChatDetailsComponent } from './admin/component/orders/chat-details/chat-details.component';
import { CommonOrderPageComponent } from './admin/component/orders/common-order-page/common-order-page.component';
import { ListCallordersComponent } from './admin/component/orders/list-callorders/list-callorders.component';
import { ListChatordersComponent } from './admin/component/orders/list-chatorders/list-chatorders.component';
import { ListOrdersComponent } from './admin/component/orders/list-orders/list-orders.component';
import { ListPromoOrdersComponent } from './admin/component/orders/list-promo-orders/list-promo-orders.component';
import { ListQueryordersComponent } from './admin/component/orders/list-queryorders/list-queryorders.component';
import { ListReportordersComponent } from './admin/component/orders/list-reportorders/list-reportorders.component';
import { QueryDetailComponent } from './admin/component/orders/query-detail/query-detail.component';
import { ReportDetailComponent } from './admin/component/orders/report-detail/report-detail.component';
import { AllRemedyComponent } from './admin/component/remedy/all-remedy/all-remedy.component';
import { SuggestRemedyComponent } from './admin/component/remedy/suggest-remedy/suggest-remedy.component';
import { AddEditReportComponent } from './admin/component/report/add-edit-report/add-edit-report.component';
import { ListReportsComponent } from './admin/component/report/list-reports/list-reports.component';
import { ListAllReviewComponent } from './admin/component/review/list-all-review/list-all-review.component';
import { EditRolesComponent } from './admin/component/roles/edit-roles/edit-roles.component';
import { ListRolesComponent } from './admin/component/roles/list-roles/list-roles.component';
import { ChangePasswordComponent } from './admin/component/sharedComponents/change-password/change-password.component';
import { EditSkillsComponent } from './admin/component/skills/edit-skills/edit-skills.component';
import { SkillsListComponent } from './admin/component/skills/skills-list/skills-list.component';
import { AstrologerSupportListComponent } from './admin/component/support/astrologer-support-list/astrologer-support-list.component';
import { CommonSupportComponent } from './admin/component/support/common-support/common-support.component';
import { CustomerSupportListComponent } from './admin/component/support/customer-support-list/customer-support-list.component';
import { DetailAstrologerSupportComponent } from './admin/component/support/detail-astrologer-support/detail-astrologer-support.component';
import { DetailCustomerSupportComponent } from './admin/component/support/detail-customer-support/detail-customer-support.component';
import { ReplyAstrologerSupportComponent } from './admin/component/support/reply-astrologer-support/reply-astrologer-support.component';
import { ReplyCustomerSupportComponent } from './admin/component/support/reply-customer-support/reply-customer-support.component';
import { ApproveRejectChargesComponent } from './admin/component/update-values/approve-reject-charges/approve-reject-charges.component';
import { ApproveRejectProfileComponent } from './admin/component/update-values/approve-reject-profile/approve-reject-profile.component';
import { ChargesUpdateListComponent } from './admin/component/update-values/charges-update-list/charges-update-list.component';
import { ProfileUpdateListComponent } from './admin/component/update-values/profile-update-list/profile-update-list.component';
import { ListUsersComponent } from './admin/component/user/list-users/list-users.component';
import { WaitingListComponent } from './admin/component/waiting-list/waiting-list/waiting-list.component';
import { ListProductRequestsComponent } from './admin/component/mall/product/list-product-requests/list-product-requests.component';
import { UpdateMallProductComponent } from './admin/component/mall/product/update-mall-product/update-mall-product.component';
import { AddMallCouponComponent } from './admin/component/mall/coupon/add-mall-coupon/add-mall-coupon.component';
import { ListMallCouponComponent } from './admin/component/mall/coupon/list-mall-coupon/list-mall-coupon.component';
import { CommonMallCouponComponent } from './admin/component/mall/coupon/common-mall-coupon/common-mall-coupon.component';
import { ListStoreOrdersComponent } from './admin/component/orders/list-store-orders/list-store-orders.component';
import { StoreDetailComponent } from './admin/component/orders/store-detail/store-detail.component';
import { UpdateStoreOrderComponent } from './admin/component/orders/update-store-order/update-store-order.component';
import { EditMallCouponComponent } from './admin/component/mall/coupon/edit-mall-coupon/edit-mall-coupon.component';
import { DetailMallCouponComponent } from './admin/component/mall/coupon/detail-mall-coupon/detail-mall-coupon.component';
import { ListReportOrderNotRepliedComponent } from './admin/component/orders/list-report-order-not-replied/list-report-order-not-replied.component';
import { CommonInternationalPriceComponent } from './admin/component/international-price/common-international-price/common-international-price.component';
import { AddInternationalComponent } from './admin/component/international-price/add-international/add-international.component';
import { ListInternationalComponent } from './admin/component/international-price/list-international/list-international.component';
import { EditInternationalComponent } from './admin/component/international-price/edit-international/edit-international.component';
import { ListReportQueryNotRepliedComponent } from './admin/component/orders/list-report-query-not-replied/list-report-query-not-replied.component';
import { CancelAndReassignReportOrderQueryNotRepliedComponent } from './admin/component/orders/cancel-and-reassign-report-order-query-not-replied/cancel-and-reassign-report-order-query-not-replied.component';
import { GroupchatOngoingComponent } from './admin/component/orders/groupchat-ongoing/groupchat-ongoing.component';
import { AstroPayoutComponent } from './admin/component/astrologer/payout/astro-payout/astro-payout.component';
import { LastMonthAstroPayoutComponent } from './admin/component/astrologer/payout/last-month-astro-payout/last-month-astro-payout.component';
import { CommonMallSubcategoryComponent } from './admin/component/mall/subcategory/common-mall-subcategory/common-mall-subcategory.component';
import { AddMallSubcategoryComponent } from './admin/component/mall/subcategory/add-mall-subcategory/add-mall-subcategory.component';
import { ListMallSubcategoryComponent } from './admin/component/mall/subcategory/list-mall-subcategory/list-mall-subcategory.component';
import { EditMallSubcategoryComponent } from './admin/component/mall/subcategory/edit-mall-subcategory/edit-mall-subcategory.component';
import { DetailMallSubcategoryComponent } from './admin/component/mall/subcategory/detail-mall-subcategory/detail-mall-subcategory.component';
import { CommonReviewComponent } from './admin/component/review/common-review/common-review.component';
import { AddDirectReviewComponent } from './admin/component/review/add-direct-review/add-direct-review.component';
import { AstrologerNoticeBoardComponent } from './admin/component/customize/astrologer-notice-board/astrologer-notice-board.component';
import { AddAstrologerNoticeBoardComponent } from './admin/component/customize/add-astrologer-notice-board/add-astrologer-notice-board.component';
import { CommonSingleCustomerPageComponent } from './admin/component/customer/common-single-customer-page/common-single-customer-page.component';
import { WalletHistoryComponent } from './admin/component/customer/wallet-history/wallet-history.component';
import { CreditDebitWalletAmountComponent } from './admin/component/customer/credit-debit-wallet-amount/credit-debit-wallet-amount.component';
import { DebitWalletAmountComponent } from './admin/component/customer/debit-wallet-amount/debit-wallet-amount.component';
import { CustomerAllOrderComponent } from './admin/component/customer/customer-all-order/customer-all-order.component';
import { CustomerCallOrderComponent } from './admin/component/customer/customer-call-order/customer-call-order.component';
import { CustomerChatOrderComponent } from './admin/component/customer/customer-chat-order/customer-chat-order.component';
import { CustomerReportOrderComponent } from './admin/component/customer/customer-report-order/customer-report-order.component';
import { CustomerQueryOrderComponent } from './admin/component/customer/customer-query-order/customer-query-order.component';
import { CustomerWalletRechargeOrderComponent } from './admin/component/customer/customer-wallet-recharge-order/customer-wallet-recharge-order.component';
import { CustomerLoginLogComponent } from './admin/component/customer/customer-login-log/customer-login-log.component';
import { CommonAdminWalletComponent } from './admin/component/admin-wallet/common-admin-wallet/common-admin-wallet.component';
import { AddAdminWalletComponent } from './admin/component/admin-wallet/add-admin-wallet/add-admin-wallet.component';
import { RazorpayRechargeListComponent } from './admin/component/admin-wallet/razorpay-recharge-list/razorpay-recharge-list.component';
import { CustomerIssueListComponent } from './admin/component/customer/customer-issue-list/customer-issue-list.component';
import { SinglecustomerSupportListComponent } from './admin/component/customer/singlecustomer-support-list/singlecustomer-support-list.component';
import { RazorpayRechargeInternationalListComponent } from './admin/component/admin-wallet/razorpay-recharge-international-list/razorpay-recharge-international-list.component';
import { SuportMessageComponent } from './admin/component/suport-message/suport-message.component';
import { IssueMessageComponent } from './admin/component/issue-message/issue-message.component';
import { DetailCouponComponent } from './admin/component/coupon/detail-coupon/detail-coupon.component';
import { AddCustomerNoticeBoardComponent } from './admin/component/customize/add-customer-notice-board/add-customer-notice-board.component';
import { CustomerNoticeBoardComponent } from './admin/component/customize/customer-notice-board/customer-notice-board.component';
import { EditCouponComponent } from './admin/component/coupon/edit-coupon/edit-coupon.component';
import { ListDynamicPageComponent } from './admin/component/dynamic-pages/list-dynamic-page/list-dynamic-page.component';
import { DetailDynamicPageComponent } from './admin/component/dynamic-pages/detail-dynamic-page/detail-dynamic-page.component';
import { EditDynamicpageComponent } from './admin/component/dynamic-pages/edit-dynamicpage/edit-dynamicpage.component';
import { ListRemedyProductComponent } from './admin/component/mall/product/list-remedy-product/list-remedy-product.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ManagerLoginComponent,
  },
  /** FORGOT PASSWORD */
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
  },
  {
    path: 'manager/login',
    pathMatch: 'full',
    component: ManagerLoginComponent,
  },
  {
    path: 'admin/login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'add-astrologer',
    pathMatch: 'full',
    component: AddAstrologerOpenComponent,
  },
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: MainDashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'usersList',
        component: ListUsersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'astro',
        component: CommonAstroPageComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'request',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'approved',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'rejected',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'blocked',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'active',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'onoff',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'new',
            component: AddEditAstrologerProfileComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'call-active',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'chat-active',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'query-active',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'report-active',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
          {
            path: 'ongoing-call-chat',
            component: AstrologersListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Astrologers'] },
          },
        ],
      },
      {
        path: 'astrologer/edit/:id',
        component: EditAstrologerComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Astrologers'] },
      },
      {
        path: 'astrologers/viewAstrologer/:id',
        component: ViewAstrologerProfileComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Astrologers'] },
      },
      //------Change Password for user----------//
      {
        path: 'users/changePassword/:id',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customer',
        component: CommonCustomerPageComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Customer'] },
        children: [
          {
            path: 'list',
            component: ListCustomerComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'new',
            component: AddCustomerComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'blocked',
            component: ListCustomerComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'online',
            component: ListCustomerComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'active',
            component: ListCustomerComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
        ],
      },
      {
        path: 'customers/viewCustomer/:data',
        component: CommonSingleCustomerPageComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Customer'] },
        children: [
          {
            path: 'detail',
            component: ViewCustomerComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'wallet',
            component: WalletHistoryComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'add-amount',
            component: CreditDebitWalletAmountComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'deduct-amount',
            component: DebitWalletAmountComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'all-order',
            component: CustomerAllOrderComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'call',
            component: CustomerCallOrderComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'chat',
            component: CustomerChatOrderComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'query',
            component: CustomerQueryOrderComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'report',
            component: CustomerReportOrderComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'recharge',
            component: CustomerWalletRechargeOrderComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'log',
            component: CustomerLoginLogComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'support',
            component: SinglecustomerSupportListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
          {
            path: 'issue',
            component: CustomerIssueListComponent,
            canActivate: [AuthGuard],
            data: { permission: ['Manage-Customer'] },
          },
        ],
      },
      {
        path: 'customers/editCustomer/:id',
        component: EditCustomerComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Customer'] },
      },
      {
        path: 'customers/showIndividualCustomerDetail/:id',
        component: ShowSignleCustomerDetailComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Customer'] },
      },
      //---------Manager
      {
        path: 'managers',
        component: ListManagerComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ViewManagerComponent },
          { path: 'new', component: AddManagerComponent },
          { path: 'blocked', component: ViewManagerComponent },
          { path: 'onoff', component: ViewManagerComponent },
        ],
      },
      {
        path: 'manager/viewManager/:id',
        component: DetailManagerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'manager/edit/:id',
        component: EditManagerComponent,
        canActivate: [AuthGuard],
      },
      //------------------Skills------------------//
      {
        path: 'skills',
        component: SkillsListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'skills/editSkills/:id',
        component: EditSkillsComponent,
        canActivate: [AuthGuard],
      },
      //-----------------Language----------------//
      {
        path: 'languages',
        component: LanguagesListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'languages/editlanguage/:id',
        canActivate: [AuthGuard],
        component: EditLanguageComponent,
      },
      //-----------------Reports--------------//
      {
        path: 'reports',
        component: ListReportsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reports/editreports/:id',
        component: AddEditReportComponent,
        canActivate: [AuthGuard],
      },
      //----Banners
      {
        path: 'banner',
        component: ListBannerComponent,
        canActivate: [AuthGuard],
      },
      //----------Coupon
      {
        path: 'coupon',
        component: ListCouponComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'coupon/:id',
        component: DetailCouponComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'coupon/edit/:id',
        component: EditCouponComponent,
        canActivate: [AuthGuard],
      },
      //----------Dynamic Pages
      {
        path: 'dynamic-page',
        component: ListDynamicPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dynamic-page/:id',
        component: DetailDynamicPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dynamic-page/edit/:id',
        component: EditDynamicpageComponent,
        canActivate: [AuthGuard],
      },
      //----------Reviews
      {
        path: 'review',
        component: CommonReviewComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ListAllReviewComponent },
          { path: 'add', component: AddDirectReviewComponent },
        ],
      },
      //----------Waiting List
      {
        path: 'waiting-list',
        component: WaitingListComponent,
        canActivate: [AuthGuard],
      },
      //----------Roles
      {
        path: 'roles',
        component: ListRolesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'roles/:id',
        component: EditRolesComponent,
        canActivate: [AuthGuard],
      },
      //----------Customize
      {
        path: 'customize',
        component: CustomizeComponent,
        canActivate: [AuthGuard],
      },
      //----Issues
      {
        path: 'issue',
        component: AllIssueComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'customer', component: CustomerIssueComponent },
          { path: 'astrologer', component: AstrologerIssueComponent },
        ],
      },
      //----Reply customer Issues
      {
        path: 'issues/customer/reply/:id',
        component: ReplyCustomerIssueComponent,
        canActivate: [AuthGuard],
      },
      //----Reply astrologer Issues
      {
        path: 'issues/astrologer/reply/:id',
        component: ReplyAstrologerIssueComponent,
        canActivate: [AuthGuard],
      },
      //----Detail customer Issues
      {
        path: 'issues/customer/:id',
        component: DetailCustomerIssueComponent,
        canActivate: [AuthGuard],
      },
      //----Detail astrologer Issues
      {
        path: 'issues/astrologer/:id',
        component: DetailAstrologerIssueComponent,
        canActivate: [AuthGuard],
      },
      //---- Issue Chat
      {
        path: 'issue/message/:id/:type',
        component: IssueMessageComponent,
        canActivate: [AuthGuard],
      },
      //----List all remedy
      {
        path: 'remedy',
        component: AllRemedyComponent,
        canActivate: [AuthGuard],
      },
      //----Suggest remedy
      {
        path: 'remedy/suggest/:id',
        component: SuggestRemedyComponent,
        canActivate: [AuthGuard],
      },
      //----------Admin Wallet
      {
        path: 'wallet',
        component: CommonAdminWalletComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ListAdminWalletComponent },
          { path: 'add', component: AddAdminWalletComponent },
          { path: 'razor-pay', component: RazorpayRechargeListComponent },
          {
            path: 'razor-pay-international',
            component: RazorpayRechargeInternationalListComponent,
          },
        ],
      },
      //----Orders
      {
        path: 'order',
        component: CommonOrderPageComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'all', component: ListOrdersComponent },
          { path: 'query', component: ListQueryordersComponent },
          { path: 'report', component: ListReportordersComponent },
          { path: 'call', component: ListCallordersComponent },
          { path: 'chat', component: ListChatordersComponent },
          { path: 'promo', component: ListPromoOrdersComponent },
          { path: 'addpromo', component: AssignPromoComponent },
          {
            path: 'report-not-replied-in-24-hours',
            component: ListReportOrderNotRepliedComponent,
          },
          {
            path: 'query-not-replied-in-24-hours',
            component: ListReportQueryNotRepliedComponent,
          },
          /**
           * M3
           */
          { path: 'store', component: ListStoreOrdersComponent },
        ],
      },
      {
        path: 'orders/groupchat-ongoing/:id',
        component: GroupchatOngoingComponent,
      },
      {
        path: 'orders/cancel-and-re-assign/:id',
        component: CancelAndReassignReportOrderQueryNotRepliedComponent,
      },
      //----Query Detail Page
      {
        path: 'orders/query/:id',
        component: QueryDetailComponent,
        canActivate: [AuthGuard],
      },
      //----Report Detail Page
      {
        path: 'orders/report/:id',
        component: ReportDetailComponent,
        canActivate: [AuthGuard],
      },
      //----Call Detail Page
      {
        path: 'orders/call/:id',
        component: CallDetailsComponent,
        canActivate: [AuthGuard],
      },
      //----Chat Detail Page
      {
        path: 'orders/chat/:id',
        component: ChatDetailsComponent,
        canActivate: [AuthGuard],
      },
      //----Store Detail Page
      {
        path: 'orders/store/:id',
        component: StoreDetailComponent,
        canActivate: [AuthGuard],
      },
      //----Update Store Order
      {
        path: 'orders/store/update/:id',
        component: UpdateStoreOrderComponent,
        canActivate: [AuthGuard],
      },
      //-------Charges update
      {
        path: 'request/charges',
        component: ChargesUpdateListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'update/charges/:id',
        component: ApproveRejectChargesComponent,
        canActivate: [AuthGuard],
      },
      //-------Profile update
      {
        path: 'request/profile',
        component: ProfileUpdateListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'update/profile/:id',
        component: ApproveRejectProfileComponent,
        canActivate: [AuthGuard],
      },
      //------------M2 starts from here
      //-----------Notification
      {
        path: 'push-notification',
        component: NotificationComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'send',
            component: SendNotificationComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ViewNotificationComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'notice-board',
            component: AstrologerNoticeBoardComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'add-notice-board',
            component: AddAstrologerNoticeBoardComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'customer-notice-board',
            component: CustomerNoticeBoardComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'add-customer-notice-board',
            component: AddCustomerNoticeBoardComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'payout',
        component: PayoutComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'wallet', component: WalletMonthWiseComponent },
          { path: 'orders', component: OrderMonthWiseComponent },
          { path: 'history', component: AstroPayoutComponent },
          { path: 'release', component: LastMonthAstroPayoutComponent },
        ],
      },
      //-----------Notes
      {
        path: 'notes',
        component: NotesComponent,
        canActivate: [AuthGuard],
      },
      //-----------Assign Promo Call
      {
        path: 'assignPromo',
        component: AssignPromoComponent,
        canActivate: [AuthGuard],
      },
      //-------------Offers
      {
        path: 'offers',
        component: OffersComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'all', component: ListOffersComponent },
          { path: 'add', component: AddOffersComponent },
          { path: 'astro-price', component: AstroOffersPriceComponent },
        ],
      },
      {
        path: 'astro-offers/edit/:id',
        component: EditOffersComponent,
        canActivate: [AuthGuard],
      },
      //-------------Wallet recharge
      {
        path: 'recharge',
        component: CustomerWalletRechargeComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'all', component: ListRechargeComponent },
          { path: 'add', component: AddRechargeComponent },
        ],
      },
      {
        path: 'wallet-recharge/edit/:id',
        component: EditRechargeComponent,
        canActivate: [AuthGuard],
      },
      //------------Support
      {
        path: 'support',
        component: CommonSupportComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Support'] },
        children: [
          { path: 'customer', component: CustomerSupportListComponent },
          { path: 'astrologer', component: CustomerSupportListComponent },
        ],
      },
      //----Reply customer Support
      {
        path: 'supports/customer/reply/:id',
        component: ReplyCustomerSupportComponent,
        canActivate: [AuthGuard],
      },
      //----Reply astrologer Support
      {
        path: 'supports/astrologer/reply/:id',
        component: ReplyAstrologerSupportComponent,
        canActivate: [AuthGuard],
      },
      //---- Support Chat
      {
        path: 'supports/message/:id',
        component: SuportMessageComponent,
        canActivate: [AuthGuard],
      },
      //----Detail customer Support
      {
        path: 'supports/customer/:id',
        component: DetailCustomerSupportComponent,
        canActivate: [AuthGuard],
      },
      //----Detail astrologer Support
      {
        path: 'supports/astrologer/:id',
        component: DetailAstrologerSupportComponent,
        canActivate: [AuthGuard],
      },
      //------------M3 starts from here
      //-----------Blog Category
      {
        path: 'blogs-category/:id',
        component: EditBlogCategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Blog'] },
      },
      {
        path: 'blog-category',
        component: CommonBlogCategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Blog'] },
        children: [
          { path: 'add', component: AddBlogCategoryComponent },
          { path: 'list', component: ListBlogCategoryComponent },
        ],
      },
      //-----------Blog
      {
        path: 'blog',
        component: CommonBlogComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Blog'] },
        children: [
          { path: 'add', component: AddCommonBlogComponent },
          { path: 'list', component: ListCommonBlogComponent },
          { path: 'new', component: ListNewBlogComponent },
          { path: 'reject', component: ListRejectBlogComponent },
        ],
      },
      {
        path: 'blogs/:id',
        component: EditCommonBlogComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Blog'] },
      },
      {
        path: 'blogs/view/:id',
        component: DetailBlogComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Blog'] },
      },
      {
        path: 'blogs/action/:id',
        component: ApproveRejectBlogComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Blog'] },
      },
      //--------Festival
      {
        path: 'festival',
        component: CommonFestivalComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Festivals'] },
        children: [
          { path: 'add', component: AddFestivalComponent },
          { path: 'list', component: ListFestivalComponent },
          { path: 'add-yearly', component: AddYearlyFestivalComponent },
          { path: 'list-yearly', component: ListYearlyFestivalComponent },
        ],
      },
      {
        path: 'festivals/:id',
        component: EditFestivalComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Festivals'] },
      },
      {
        path: 'festivals/view/:id',
        component: DetailFestivalComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Festivals'] },
      },
      {
        path: 'festival-yearly/:id',
        component: EditYearlyFestivalComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Festivals'] },
      },
      {
        path: 'mall-category',
        component: CommonMallCategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
        children: [
          { path: 'add', component: AddMallCategoryComponent },
          { path: 'list', component: ListMallCategoryComponent },
        ],
      },
      {
        path: 'malls-category/edit/:id',
        component: EditMallCategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'malls-category/:id',
        component: DetailMallCategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'mall-subcategory',
        component: CommonMallSubcategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
        children: [
          { path: 'add', component: AddMallSubcategoryComponent },
          { path: 'list', component: ListMallSubcategoryComponent },
        ],
      },
      {
        path: 'malls-subcategory/edit/:id',
        component: EditMallSubcategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'malls-subcategory/:id',
        component: DetailMallSubcategoryComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'mall-product',
        component: CommonMallProductComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
        children: [
          { path: 'add', component: AddMallProductComponent },
          { path: 'list', component: ListMallProductComponent },
          { path: 'list-requests', component: ListProductRequestsComponent },
          { path: 'list-remedy-products', component: ListRemedyProductComponent },
        ],
      },
      {
        path: 'malls-product/edit/:id',
        component: UpdateMallProductComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'malls-product/:id',
        component: DetailMallProductComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'malls-product/add-astrologer/:id',
        component: AddAssociateAstroComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'malls-product/add-addons/:id',
        component: AddAssociateAddonComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'mall-coupon',
        component: CommonMallCouponComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
        children: [
          { path: 'add', component: AddMallCouponComponent },
          { path: 'list', component: ListMallCouponComponent },
        ],
      },
      {
        path: 'malls-coupon/edit/:id',
        component: EditMallCouponComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      {
        path: 'malls-coupon/:id',
        component: DetailMallCouponComponent,
        canActivate: [AuthGuard],
        data: { permission: ['Manage-Mall'] },
      },
      /**
       * International Pricing
       */
      {
        path: 'international',
        component: CommonInternationalPriceComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'add', component: AddInternationalComponent },
          { path: 'list', component: ListInternationalComponent },
        ],
      },
      {
        path: 'internationals/:id',
        component: EditInternationalComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'admin/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
