<div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
    <input type="text"  placeholder="Type to filter the wallet ID..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
      <!-- <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->
        <div>
            <button class="btn btn-success mr-2" (click)="getAstroCreditedWalletHistory()">Recharged Transactions</button>
            <button class="btn btn-primary mr-2" (click)="getAstroDebitedWalletHistory()">Deducted Transactions</button>
            <button class="btn btn-info" (click)="getAstroWalletTransactionsByAdmin()">Admin Transactions</button>
        </div>
  </div>
  <br>
  <ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Wallet Id" prop="awalletId">
      <ng-template let-row="row" ngx-datatable-cell-template>
        #{{row.awalletId}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Type">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.txn_type == 1" class="badge badge-success">Recharged</span>
        <span *ngIf="row.txn_type == 2" class="badge badge-primary">Deducted</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="amount">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.amount | currency:'INR':'symbol-narrow'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Balance" prop="balance">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.balance | currency:'INR':'symbol-narrow'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Wallet Remarks" prop="wallet_comments">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.wallet_comments}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Txn ID" prop="txnId">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.txnId}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
