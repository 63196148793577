import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
@Component({
  selector: 'app-add-international',
  templateUrl: './add-international.component.html',
  styleUrls: ['./add-international.component.scss']
})
export class AddInternationalComponent implements OnInit {

  countryData:any = []
  countryName:string = ''
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private internationalService: InternationalServiceService,
    private route: Router
  ) { }

  formGroup: FormGroup = new FormGroup({
    countryCode: new FormControl('', Validators.required),
    currencyCode: new FormControl('', Validators.required),
    countryName: new FormControl('', Validators.required),
    currencyUnitInRupees: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    callPricePercent: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    chatPricePercent: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    reportPricePercent: new FormControl('',[Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    queryPricePercent: new FormControl('',[Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    currencySymbol: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.getCountryData()
    this.formGroup.valueChanges.subscribe(selectedValue => {
      this.calculateFormGroup.patchValue({currencyUnitInRupees:this.formGroup.get('currencyUnitInRupees')?.value})
      this.calculateFormGroup.patchValue({callPricePercent:this.formGroup.get('callPricePercent')?.value})
      this.calculateFormGroup.patchValue({chatPricePercent:this.formGroup.get('chatPricePercent')?.value})
      this.calculateFormGroup.patchValue({reportPricePercent:this.formGroup.get('reportPricePercent')?.value})
      this.calculateFormGroup.patchValue({queryPricePercent:this.formGroup.get('queryPricePercent')?.value})
    })

    // this.calculateFormGroup.valueChanges.subscribe(selectedValue => {
    //   this.formGroup.patchValue({currencyUnitInRupees:this.calculateFormGroup.get('currencyUnitInRupees')?.value})
    //   this.formGroup.patchValue({callPricePercent:this.calculateFormGroup.get('callPricePercent')?.value})
    //   this.formGroup.patchValue({chatPricePercent:this.calculateFormGroup.get('chatPricePercent')?.value})
    //   this.formGroup.patchValue({reportPricePercent:this.calculateFormGroup.get('reportPricePercent')?.value})
    //   this.formGroup.patchValue({queryPricePercent:this.calculateFormGroup.get('queryPricePercent')?.value})
    // })
  }

  get f() {
    return this.formGroup.controls
  }

  addPriceSubmit(){
    this.formGroup.patchValue({countryName:this.countryName})
    this.internationalService.addInternationalPrice(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/international/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getCountryData(){
    this.internationalService.getCountryData().subscribe(
      (res:any)=>{
        //console.log('country',res.data)
        this.countryData = res.data
      },err=>{
        //console.log(err)
      }
    )
  }

  selectedCountry(event:any){
    this.countryName = event.name
    this.formGroup.patchValue({countryCode:event.iso2})
    this.formGroup.patchValue({currencySymbol:event.currency_symbol})
    this.formGroup.patchValue({currencyCode:event.currency})
  }

  calculateFormGroup: FormGroup = new FormGroup({
    currencyUnitInRupees: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    callPricePercent: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    chatPricePercent: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    reportPricePercent: new FormControl('',[Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    queryPricePercent: new FormControl('',[Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    astroPrice: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    isExclusiveAstro: new FormControl(false),
  });

  get c(){
    return this.calculateFormGroup.controls
  }

  convertedPrice:boolean = false
  convertedData:any = ''

  calculatePrice(){
    if(this.calculateFormGroup.get('currencyUnitInRupees')?.value == ''){
      this.toast.error('Please Enter Currency Units in Rupees','Error')
      return
    }
    if(this.calculateFormGroup.get('callPricePercent')?.value == ''){
      this.toast.error('Please Enter Call Price Percent','Error')
      return
    }
    if(this.calculateFormGroup.get('chatPricePercent')?.value == ''){
      this.toast.error('Please Enter Chat Price Percent','Error')
      return
    }
    if(this.calculateFormGroup.get('reportPricePercent')?.value == ''){
      this.toast.error('Please Enter Report Price Percent','Error')
      return
    }
    if(this.calculateFormGroup.get('queryPricePercent')?.value == ''){
      this.toast.error('Please Enter Query Price Percent','Error')
      return
    }
    if(this.calculateFormGroup.get('astroPrice')?.value == ''){
      this.toast.error('Please Enter Astrologer Price','Error')
      return
    }
    this.internationalService.convertIndianToForeign(this.calculateFormGroup.value).subscribe(
      (res:any) =>{
        //this.toast.success(res.message,'Success')
        this.convertedPrice = true
        this.convertedData = res.data
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
