<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text" placeholder="Search Order ID..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"/>
        <div class="p-2 d-flex justify-content-center mt-1">
            <button class="btn btn-primary btn-sm mr-3" (click)="searchOrders({isForeignOrder:true})">International</button>
            <button class="btn btn-info btn-sm mr-3" (click)="searchOrders({isForeignOrder:false})">Local</button>
            <button class="btn btn-warning btn-sm mr-3" (click)="searchOrders({isDirectProduct:false})">Expert Based</button>
            <button class="btn btn-success btn-sm mr-3" (click)="searchOrders({isDirectProduct:true})">Direct</button>
            <!-- <button class="btn btn-primary btn-sm mr-2" (click)="noCustomerReview()">No Customer Review</button>
            <button class="btn btn-info btn-sm mr-2" (click)="noAstrologerReviewReply()">No Review Reply</button>
            <button class="btn btn-warning btn-sm mr-2" (click)="promotionalCall()">Promotional Call</button> -->
        </div>
    <div class="col-3">
        <ng-select placeholder="Search By Order Status" appendTo="body" [searchable]="true" [clearable]="true" (change)="getStoreOrderByStatus($event)">
            <ng-option selected disabled value=''>Select Astrologer</ng-option>
            <ng-option value="1">Placed</ng-option>
            <ng-option value="2">Confirmed</ng-option>
            <ng-option value="3">Shipped</ng-option>
            <ng-option value="4">Out For Delivery</ng-option>
            <ng-option value="5">Delivered</ng-option>
            <ng-option value="7">Astro Completed</ng-option>
            <ng-option value="8">Completed</ng-option>
            <ng-option value="6">Cancelled & Refund Requested </ng-option>
            <ng-option value="9">Cancelled & Refund Approved</ng-option>
            <ng-option value="10">Cancelled & Refund Rejected</ng-option>
        </ng-select>
    </div>
</div>

<br>
<ngx-datatable class="material" [rows]="storeList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="orderId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/store/', row._id]"> #{{row.orderId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/astrologers/viewAstrologer',row.astroId?._id]"><span [title]="row.astroId?.name" *ngIf="row.astroId != null">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/customers/viewCustomer',row.customerId?._id,'detail']">#{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="grandTotal">
        <ng-template let-row="row" ngx-datatable-cell-template>

            <span *ngIf="!row.isForeignOrder">₹</span>
            <span *ngIf="row.isForeignOrder">$</span>
            <span class="price">{{row.grandTotal | number : '.2-2' }}</span> <span class="ml-1 badge badge-pill badge-primary" *ngIf="row.isForeignOrder">{{row?.customerId?.regCountryName}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Status" prop="orderStatus">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-primary" *ngIf="row.orderStatus == 1">Placed</span>
            <span class="badge badge-warning" *ngIf="row.orderStatus == 2">Confirmed</span>
            <span class="badge badge-info" *ngIf="row.orderStatus == 3">Shipped</span>
            <span class="badge badge-warning" *ngIf="row.orderStatus == 4">Out For Delivery</span>
            <span class="badge badge-success" *ngIf="row.orderStatus == 5">Delivered</span>
            <span class="badge badge-primary mr-1" *ngIf="row.orderStatus == 6">Cancelled</span><span class="badge badge-warning" *ngIf="row.orderStatus == 6 && row.refundStatus == 0">Not Refunded</span><span class="badge badge-info" *ngIf="row.orderStatus == 6 && row.refundStatus == 1">Refund Requested</span><span class="badge badge-success" *ngIf="row.orderStatus == 6 && row.refundStatus == 2">Refund Approved</span><span class="badge badge-primary" *ngIf="row.orderStatus == 6 && row.refundStatus == 3">Refund Rejected</span>
            <span class="badge badge-info" *ngIf="row.orderStatus == 7 && !row.isOrderCompleted">Astro Compelete</span>
            <span class="badge badge-success" *ngIf="row.orderStatus == 8 && row.isOrderCompleted">Complete</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/store/', row._id]" class="mr-3"><i class="fa fa-eye"></i></a>
            <button *ngIf="!row.isOrderCompleted && row.refundStatus == 0" [routerLink]="['/admin/orders/store/update',row._id]" class="btn-table-custom btn btn-outline-primary btn-sm">Update</button>
            <!-- <button class="ml-2 btn btn-primary btn-sm" (click)="markAsComplete(row._id)" *ngIf="!row.is_order_completed">Mark Complete</button> -->
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="createdAt">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.createdAt |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
