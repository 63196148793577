<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- ROW-1 OPEN -->
  <div class="page-header">
    <h4 class="page-title">Dynamic Page</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item" aria-current="page">
        <a (click)="goBack()" style="cursor: pointer">Dynamic Page</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Add Page</li>
    </ol>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="formGroup" (submit)="addDynamicPageSubmit()">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Add Dynamic Page</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input
                    type="text"
                    class="form-control"
                    form
                    placeholder="Page Title"
                    formControlName="title"
                  />
                  <div
                    *ngIf="
                      f.title.invalid && (f.title.dirty || f.title.touched)
                    "
                    class="alert alert-danger mt-1"
                  >
                    Required
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="metaKeywords"
                    >Meta Keywords (Seprate keywords by ,)</label
                  >
                  <input
                    type="text"
                    placeholder="Page Keywords"
                    class="form-control"
                    formControlName="metaKeywords"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label for="description">Description*</label>
                  <!-- <textarea placeholder="Product Description" class="form-control"
                            formControlName="description"></textarea> -->
                  <editor
                    apiKey="kb1shdcv4s1c8mg7s4jo6ebmrmwqkkkx5hfwwpofj87i778i"
                    [init]="{
                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help',
                      images_upload_handler: example_image_upload_handler
                    }"
                    formControlName="description"
                    style="width: 100% !important"
                  ></editor>
                  <small
                    *ngIf="
                      f.description.invalid &&
                      (f.description.dirty || f.description.touched)
                    "
                    class="d-block alert alert-danger mt-1"
                  >
                    Required.
                  </small>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label for="metaDescription">Meta Description*</label>
                  <textarea
                    placeholder="Product metaDescription"
                    class="form-control"
                    formControlName="metaDescription"
                    style="height: 300px"
                  ></textarea>

                  <small
                    *ngIf="
                      f.metaDescription.invalid &&
                      (f.metaDescription.dirty || f.metaDescription.touched)
                    "
                    class="d-block alert alert-danger mt-1"
                  >
                    Required.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-success mt-1"
              [disabled]="!formGroup.valid"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!--CONTAINER CLOSED -->
