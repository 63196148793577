<div style="display: flex; justify-content: space-between" class="pl-2 pr-2">
  <input
    type="text"
    value="{{searchKey}}"
    placeholder="Search Name,Email,ID.."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
  <button class="btn btn-primary btn-sm" (click)="generateExcel()"> Generate Excel</button>
</div>
<br />
<ngx-datatable
  (page)="onPageChange($event)"
  class="material"
  [rows]="astrosList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  [sorts]="[{ dir: 'desc' }]"
  style="position: relative !important"
>
  <ngx-datatable-column [width]="70" name="ID" prop="astroAutoId">
    <ng-template let-row="row" ngx-datatable-cell-template>
      #{{ row?.astroAutoId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [width]="80"  name="Profile" prop="profile">
    <ng-template let-row="row"  ngx-datatable-cell-template>
      <img style="border: #b0b0b0 solid 2px;width: 66px;height: 66px;" class="rounded-circle"[src]="getSanitizeUrl(row.thumbnail_image)"/>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage!='rejected'&&currentPage!='request'" name="Display Name" prop="displayname">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.displayname }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Name" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.name }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [width]="200" *ngIf="currentPage!='approved' && currentPage!='rejected'"  name="Email" prop="email">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.email }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column  name="Phone" prop="phone">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row?.phone">{{ row?.phone }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage=='onoff'" name="Status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.is_current_active" class="badge badge-success">Online</span>
      <span *ngIf="!row.is_current_active" class="badge badge-danger">Offline</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage=='approved'" name="Today Promo">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.current_promocall_taken}}/{{row.promocall_totake}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage=='rejected'" [flexGrow]="3" name="Reason">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.approvedComment">{{row.approvedComment}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage=='request'"  name="Experience" prop="experience">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row?.experience}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="currentPage=='request'"  name="Date" prop="created_at">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row?.created_at | date:'medium'}}
      </ng-template>
    </ngx-datatable-column>
  <ngx-datatable-column  [width]="200" name="Actions">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a [routerLink]="['/admin/astrologers/viewAstrologer/', row?._id]"
        ><i class="fa fa-eye"></i
      ></a> &nbsp;
      <a *ngIf="currentPage!='request' && currentPage!='rejected' && currentPage!='ongoing-call-chat'" [routerLink]="['/admin/users/changePassword/', row.userId?._id]">
        <!-- <button class="btn btn-primary btn-sm mt-1 mb-2 ml-3"> -->
        <i class="fa fa-key" title="Change Password"></i>
        <!-- </button> -->
      </a>
      <span *ngIf="row.register_otp != 0">
        &nbsp;({{ row.register_otp }})</span
      ><br/>
      <span class="badge badge-primary" *ngIf="row?.is_social_login">
         {{row?.social_type == 0 ? 'Google':'Facebook'}}
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage=='list'||currentPage=='active'||currentPage=='blocked'" name="Block Astrologer">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- Material switch -->
      <ng-template #blocked>
        <mat-slide-toggle
          color="warn"
          (change)="toggle($event, row?._id, row?.userId.is_blocked)"
          [checked]="true"
        ></mat-slide-toggle>
      </ng-template>
      <ng-template #notBlocked>
        <mat-slide-toggle
          color="warn"
          (change)="toggle($event, row?._id, row?.userId.is_blocked)"
          [checked]="false"
        ></mat-slide-toggle>
      </ng-template>
      <ng-container
        *ngIf="row?.is_blocked === true; then blocked; else notBlocked"
      >
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="currentPage=='ongoing-call-chat'" [flexGrow]="3" name="Reason">
    <ng-template let-row="row" ngx-datatable-cell-template>

      <span *ngIf="row.is_current_call_going" class="badge bg-success text-white fw-500">Call</span>
      <span *ngIf="row.is_current_chat_going" class="badge bg-success text-white fw-500">Chat</span>

    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [width]="70" *ngIf="currentPage=='list'||currentPage=='query-active'||currentPage=='report-active'||currentPage=='chat-active'||currentPage=='call-active'"  name="Edit">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button
        class="btn btn-primary btn-sm"
        [routerLink]="['/admin/astrologer/edit', row?._id]"
      >
        Edit
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
