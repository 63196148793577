<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Dashboard</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <!-- ROW-1 OPEN -->
    <div class="row">
        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/blog/new']">
                    <h6 class="mb-3">Pending Blog Request</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-file-text text-primary mr-2"></i>{{data?.pendingBlogRequest}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/request/charges']">
                    <h6 class="mb-3">Pending Charge Update Request</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-secondary mr-2"></i>{{data?.priceChangeRequests}}</h2>
                </div>
            </div>
        </div>

        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/request/profile']">
                    <h6 class="mb-3">Pending Profile Update Request</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-user-check text-secondary mr-2"></i>{{data?.pendingProfileRequest}}</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-1 END -->

    <!-- ROW-1 OPEN -->
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/astro/request']">
                    <h6 class="mb-3">Today's Astro Request</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-user-plus text-primary mr-2"></i>{{data?.todayastro}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/customer/list']">
                    <h6 class="mb-3">Today's New User</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-users text-secondary mr-2"></i>{{data?.todaycustomer}}</h2>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/astro/onoff']">
                    <h6 class="mb-3">Online Astro</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-user-check text-secondary mr-2"></i>{{data?.currentactiveastro}}</h2>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/order/store']">
                    <h6 class="mb-3">Today's Store Orders</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-phone-call text-danger mr-2"></i>{{data?.totalorders}}</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-1 END -->

    <!-- ROW-1 OPEN -->
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/order/call']">
                    <h6 class="mb-3">Today's Calls</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-phone-call text-danger mr-2"></i>{{data?.totalcall}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/order/chat']">
                    <h6 class="mb-3">Today's Chat</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-message-circle text-warning mr-2"></i>{{data?.totalchats}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/order/query']">
                    <h6 class="mb-3">Today's Queries</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-help-circle text-primary mr-2"></i>{{data?.totalquestions}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-3">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/order/report']">
                    <h6 class="mb-3">Today's Reports</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fe fe-clipboard text-warning mr-2"></i>{{data?.totalreports}}</h2>
                </div>
            </div>
        </div>

    </div>
    <!-- ROW-1 END -->

    <!-- ROW-1 OPEN -->
    <div class="row">
        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/wallet/list']">
                    <h6 class="mb-3">Today's Overall Earning</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-danger mr-2"></i>{{data?.todaysearning}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/wallet/list']">
                    <h6 class="mb-3">Today's Service Earning</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-danger mr-2"></i>{{data?.todayearningservice}}</h2>
                </div>
            </div>
        </div>

        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/wallet/list']">
                    <h6 class="mb-3">Today's Store Earning</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-danger mr-2"></i>{{data?.todayearningorder}}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/wallet/list']">
                    <h6 class="mb-3">Today's Overall Earning (Foreign)</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-danger mr-2"></i>{{data?.overalltodayearningforgien}}</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/wallet/list']">
                    <h6 class="mb-3">Today's Service Earning (Foreign)</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-danger mr-2"></i>{{data?.todayforiearningservice}}</h2>
                </div>
            </div>
        </div>

        <div class="col-sm-4 col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body cursor-hand" [routerLink]="['/admin/wallet/list']">
                    <h6 class="mb-3">Today's Store Earning (Foreign)</h6>
                    <h2 class="mb-2 text-dark display-4 font-weight-bold"><i
                            class="fa fa-inr text-danger mr-2"></i>{{data?.todayforiearningproduct}}</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-1 END -->

    <!-- offline astrologers Final Code-->
    <div class="row">
      <div class="col-md-12 col-xl-12 col-lg-12">
          <div class="card">
              <div class="card-header d-flex justify-content-between">
                  <div class="card-title">Offline The Active Services of All Astrologers</div>
              </div>
              <div class="card-body">
                  <div class="">
                    <div class="d-flex justify-content-between">
                      <div class="card card-body col-md-2">
                        <div class="text-center">
                          <div class="col">
                            <h6 class="">Call</h6>
                          </div>
                          <div class="col">
                            <button type="submit" (click)="callActiveStatus()" class="btn btn-success mt-1 mb-2" [disabled]="!callActive">Offline</button>
                          </div>
                        </div>
                      </div>
                      <div class="card card-body col-md-2">
                        <div class="text-center">
                          <div class="col">
                            <h6 class="">Chat</h6>
                          </div>
                          <div class="col">
                            <button type="submit" (click)="chatActiveStatus()" class="btn btn-success mt-1 mb-2" [disabled]="!chatActive">Offline</button>
                          </div>
                        </div>
                      </div>
                      <div class="card card-body col-md-2">
                        <div class="text-center">
                          <div class="col">
                            <h6 class="">Query</h6>
                          </div>
                          <div class="col">
                            <button type="submit" (click)="queryActiveStatus()" class="btn btn-success mt-1 mb-2" [disabled]="!queryActive">Offline</button>
                          </div>
                        </div>
                      </div>
                      <div class="card card-body col-md-2">
                        <div class="text-center">
                          <div class="col">
                            <h6 class="">Report</h6>
                          </div>
                          <div class="col">
                            <button type="submit" (click)="reportActiveStatus()" class="btn btn-success mt-1 mb-2" [disabled]="!reportActive">Offline</button>
                          </div>
                        </div>
                      </div>
                      <div class="card card-body col-md-2">
                        <div class="text-center">
                          <div class="col">
                            <h6 class="">Promo</h6>
                          </div>
                          <div class="col">
                            <button type="submit" (click)="promoActiveStatus()" class="btn btn-success mt-1 mb-2" [disabled]="!promoActive">Offline</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

    <!-- ROW-4 END -->
    <!-- offline astrologers end -->

    <!-- ROW-4 -->
    <!--<div class="row">
      <div class="col-md-12 col-xl-12 col-lg-12">
          <div class="card">
              <div class="card-header d-flex justify-content-between">
                  <div class="card-title">Active/Inactive All Astrologers Status</div>
              </div>
              <div class="card-body">-->
    <!-- ------ -->
    <!--<ng-template #call_active>
                  <div>
                      <mat-slide-toggle color="success" (change)="callActiveStatus($event)" [checked]="true" [disabled]="data?.call_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>
              <ng-template #call_inactive>
                  <div>
                      <mat-slide-toggle color="success" (change)="callActiveStatus($event)" [checked]="false" [disabled]="data?.call_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>-->
    <!-- chat active check -->
    <!--<ng-template #chat_active>
                  <div>
                      <mat-slide-toggle color="success" (change)="chatActiveStatus($event)" [checked]="true" [disabled]="data?.chat_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>
              <ng-template #chat_inactive>
                  <div>
                      <mat-slide-toggle color="success" (change)="chatActiveStatus($event)" [checked]="false" [disabled]="data?.chat_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>-->
    <!-- query active check -->
    <!-- <ng-template #query_active>
                  <div>
                      <mat-slide-toggle color="success" (change)="queryActiveStatus($event)" [checked]="true" [disabled]="data?.query_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>
              <ng-template #query_inactive>
                  <div>
                      <mat-slide-toggle color="success" (change)="queryActiveStatus($event)" [checked]="false" [disabled]="data?.query_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>-->
    <!-- report active check -->
    <!--<ng-template #report_active>
                  <div>
                      <mat-slide-toggle color="success" (change)="reportActiveStatus($event)" [checked]="true" [disabled]="data?.report_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>
              <ng-template #report_inactive>
                  <div>
                      <mat-slide-toggle color="success" (change)="reportActiveStatus($event)" [checked]="false" [disabled]="data?.report_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>-->
    <!-- report active check -->
    <!--<ng-template #promo_active>
                  <div>
                      <mat-slide-toggle color="success" (change)="promoActiveStatus($event)" [checked]="true" [disabled]="data?.promo_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>
              <ng-template #promo_inactive>
                  <div>
                      <mat-slide-toggle color="success" (change)="promoActiveStatus($event)" [checked]="false" [disabled]="data?.promo_charges <=0 ? true : null"></mat-slide-toggle>
                  </div>
              </ng-template>-->
    <!-- ------ -->
    <!--<div class="d-flex justify-content-between">
                <div class="card card-body col-md-2">
                  <div class="row">
                    <div class="col"><h6 class="">Call</h6></div>
                    <div class="col">
                      <ng-container *ngIf="data?.is_call_active === true; then call_active; else call_inactive">
                      </ng-container>
                      <span *ngIf="data?.call_charges <= 0" class="text-danger">Update Call Charges First.</span>
                    </div>
                  </div>
                </div>
                <div class="card card-body col-md-2">
                  <div class="row">
                    <div class="col"><h6 class="">Chat</h6></div>
                    <div class="col">
                      <ng-container *ngIf="data?.is_chat_active === true; then chat_active; else chat_inactive">
                      </ng-container>
                      <span *ngIf="data?.chat_charges <= 0" class="text-danger">Update Chat Charges First.</span>
                    </div>
                  </div>
                </div>
                <div class="card card-body col-md-2">
                  <div class="row">
                    <div class="col"><h6 class="">Query</h6></div>
                    <div class="col">
                      <ng-container *ngIf="data?.is_query_active === true; then query_active; else query_inactive">
                      </ng-container>
                      <span *ngIf="data?.query_charges <= 0" class="text-danger">Update Query Charges First.</span>
                    </div>
                  </div>
                </div>
                <div class="card card-body col-md-2">
                  <div class="row">
                    <div class="col"><h6 class="">Report</h6></div>
                    <div class="col">
                      <ng-container *ngIf="data?.is_report_active === true; then report_active; else report_inactive">
                      </ng-container>
                      <span *ngIf="data?.report_charges <= 0" class="text-danger">Update Report Charges First.</span>
                    </div>
                  </div>
                </div>
                <div class="card card-body col-md-2">
                  <div class="row">
                    <div class="col"><h6 class="">Promo</h6></div>
                    <div class="col">
                      <ng-container *ngIf="data?.is_promo_active === true; then promo_active; else promo_inactive">
                      </ng-container>
                      <span *ngIf="data?.promo_charges <= 0" class="text-danger">Update Promo Charges First.</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
      </div>
  </div>-->
    <!-- ROW-4 END -->

    <!-- ROW-3 -->
    <div class="row">
        <div class="col-md-6 col-xl-6 col-lg-6">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">Today's Support Questions of Astrologer</div>
                    <a class="btn btn-sm btn-primary" [routerLink]="['/admin/support/astrologer']"> View more</a>
                </div>
                <div class="card-body">
                    <div class="">
                        <div class="table-responsive">
                            <table
                                class="table table-sm table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                                <thead *ngIf="data?.supportastro?.length > 0">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let asupport of data?.supportastro">
                                        <td>{{asupport.astroId?.name}}</td>
                                        <td>{{asupport.astroId?.email}}</td>
                                        <td>{{asupport.support_title}}</td>
                                    </tr>
                                    <tr *ngIf="data?.supportastro?.length == 0">
                                        <td colspan="3">
                                            No Data Available!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-6 col-lg-6">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">Today's Support Questions of Customer</div>
                    <a class="btn btn-sm btn-primary" [routerLink]="['/admin/support/customer']"> View more</a>
                </div>
                <div class="card-body">
                    <div class="">
                        <div class="table-responsive">
                            <table
                                class="table table-sm table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                                <thead *ngIf="data?.supportcustomer?.length > 0">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let csupport of data?.supportcustomer">
                                        <td>{{csupport.customerId?.name}}</td>
                                        <td>{{csupport.customerId?.email}}</td>
                                        <td>{{csupport.support_title}}</td>
                                    </tr>
                                    <tr *ngIf="data?.supportcustomer?.length == 0">
                                        <td colspan="3">
                                            No Data Available!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-3 END -->

    <!-- ROW-4 -->
    <div class="row">
        <div class="col-md-12 col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">New Astrologer Request Lists(All)</div>
                    <a class="btn btn-sm btn-primary" [routerLink]="['/admin/astrologersList']"> View more</a>
                </div>
                <div class="">
                    <div class="table-responsive">
                        <table
                            class="table table-sm table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                            <thead *ngIf="data?.today_unapprove_astro?.length > 0">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Experience</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let astro of data?.today_unapprove_astro">
                                    <td>{{astro.name}}</td>
                                    <td>{{astro.email}}</td>
                                    <td>{{astro.phone}}</td>
                                    <td>{{astro.experience}}</td>
                                </tr>
                                <tr *ngIf="data?.today_unapprove_astro?.length <= 0">
                                    <td colspan="4">
                                        No Data Available!
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-4 END -->
    <!-- ROW-3 -->
    <div class="row">
        <div class="col-md-6 col-xl-6 col-lg-6">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">Today's Issues of Astrologer</div>
                    <a class="btn btn-sm btn-primary" [routerLink]="['/admin/issue/astrologer']"> View more</a>
                </div>
                <div class="card-body">
                    <div class="">
                        <div class="table-responsive">
                            <table
                                class="table table-sm table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                                <thead *ngIf="data?.astro_today_issue?.length > 0">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let aissue of data?.astro_today_issue">
                                        <td>{{aissue.astroId?.name}}</td>
                                        <td>{{aissue.astroId?.email}}</td>
                                        <td>
                                            <span class="badge badge-primary"
                                                *ngIf="aissue.is_wallet_issue">WALLET</span>
                                            <span class="badge badge-info"
                                                *ngIf="aissue.is_service_issue">SERVICE</span>
                                        </td>
                                        <td>{{aissue.issue_title}}</td>
                                    </tr>
                                    <tr *ngIf="data?.astro_today_issue?.length == 0">
                                        <td colspan="4">
                                            No Data Available!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-6 col-lg-6">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">Today's Issues of Customer</div>
                    <a class="btn btn-sm btn-primary" [routerLink]="['/admin/issue/customer']"> View more</a>
                </div>
                <div class="card-body">
                    <div class="">
                        <div class="table-responsive">
                            <table
                                class="table table-sm table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                                <thead *ngIf="data?.customer_today_issue?.length > 0">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cissue of data?.customer_today_issue">
                                        <td>{{cissue.customerId?.name}}</td>
                                        <td>{{cissue.customerId?.email}}</td>
                                        <td>
                                            <span class="badge badge-primary"
                                                *ngIf="cissue.is_wallet_issue">WALLET</span>
                                            <span class="badge badge-info"
                                                *ngIf="cissue.is_service_issue">SERVICE</span>
                                        </td>
                                        <td>{{cissue.issue_title}}</td>
                                    </tr>
                                    <tr *ngIf="data?.customer_today_issue?.length == 0">
                                        <td colspan="4">
                                            No Data Available!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-3 END -->

</div>
<!-- CONTAINER END -->
