<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Search ID, Name, Slug..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />

</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="categoryId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.categoryId}}
        </ng-template>
    </ngx-datatable-column> 
    <ngx-datatable-column [flexGrow]="3" name="Name" prop="categoryname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.categoryname">{{row.categoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column [flexGrow]="1" name="Slug" prop="category_slug">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.category_slug">{{row.category_slug}}</span>
        </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="1" name="Type" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-primary" *ngIf="!row.is_product_associated && !row.is_subcategory_associated">NEW</span>
            <span class="badge badge-info" *ngIf="row.is_product_associated && !row.is_subcategory_associated">PRODUCT ASSC.</span>
            <span class="badge badge-warning" *ngIf="!row.is_product_associated && row.is_subcategory_associated">SUBCAT. ASSC.</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Keywords" prop="category_keyword">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.category_keyword">{{row.category_keyword}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Category GST" prop="category_gst">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.category_gst">{{row.category_gst}}%</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Enable">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- Material switch -->
            <ng-template #enabled>
                <mat-slide-toggle (change)="toggle($event, row)" [checked]="true"></mat-slide-toggle>
            </ng-template>
            <ng-template #disabled>
                <mat-slide-toggle (change)="toggle($event, row)" [checked]="false"></mat-slide-toggle>
            </ng-template>
            <ng-container *ngIf="row?.status === true; then enabled; else disabled">
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Edit">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/malls-category/',row._id]" class="fa fa-eye text-primary mr-1"></a>
            <a [routerLink]="['/admin/malls-category/edit/',row._id]" class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
