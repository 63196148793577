import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FestivalServiceService } from 'src/app/admin/services/festival/festival-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-festival',
  templateUrl: './edit-festival.component.html',
  styleUrls: ['./edit-festival.component.scss']
})
export class EditFestivalComponent implements OnInit {

  dataList:any = {}
  id:any =''
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private festivalService: FestivalServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) { }

  getSingleFestivalList() {
    this.spinner.show()
    this.festivalService.detailFestival({_id:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.formGroup.patchValue({_id:this.dataList._id})
        this.formGroup.patchValue({festivalName:this.dataList.festivalName})
        this.formGroup.patchValue({festivalDescription:this.dataList.festivalDescription})
        this.formGroup.patchValue({metaDescription:this.dataList.metaDescription})
        this.formGroup.patchValue({metaTags:this.dataList.metaTags})
        this.formGroup.patchValue({metaKeywords:this.dataList.metaKeywords})
        this.formGroup.patchValue({updatedId:this.userdata.getId()})
      },
      (err) => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/festival/list')
      }
    );
  }
  
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    festivalName: new FormControl('', Validators.required),
    festivalDescription: new FormControl('', Validators.required),
    metaDescription: new FormControl(''),
    metaTags: new FormControl(''),
    metaKeywords: new FormControl(''),
    festivalImage: new FormControl(''),
    updatedId: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getSingleFestivalList()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Blog Image
   */
   imageError: string = ''
   imageSrc = ''
   public onBannerUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;
 
       reader.readAsDataURL(file);    
 
       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;    
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Image dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              festivalImage: event.target.files[0]
             })
           }
         }
       };
     }
   }

   updateFestival(){
    const data = new FormData()
    data.append("festivalName", this.formGroup.get("festivalName")?.value)
    data.append("festivalDescription", this.formGroup.get("festivalDescription")?.value)
    data.append("metaDescription", this.formGroup.get("metaDescription")?.value)
    data.append("metaTags", this.formGroup.get("metaTags")?.value)
    data.append("metaKeywords", this.formGroup.get("metaKeywords")?.value)
    data.append("festivalImage", this.formGroup.get("festivalImage")?.value)
    data.append("_id", this.formGroup.get("_id")?.value)
    data.append("updatedId", this.formGroup.get("updatedId")?.value)
    this.festivalService.updateFestival(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/festival/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
