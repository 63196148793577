import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FestivalServiceService } from 'src/app/admin/services/festival/festival-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-yearly-festival',
  templateUrl: './add-yearly-festival.component.html',
  styleUrls: ['./add-yearly-festival.component.scss']
})
export class AddYearlyFestivalComponent implements OnInit {

  festivalList:any = []
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private festivalService: FestivalServiceService,
    private route: Router
  ) { }

  formGroup: FormGroup = new FormGroup({
    userId: new FormControl(''),
    year: new FormControl(''),
    festivalId: new FormControl('', Validators.required),
    festivalDate: new FormControl('',Validators.required),
  });

  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.getFestivalList()
  }

  get f() {
    return this.formGroup.controls
  }

  getFestivalList(){
    this.festivalService.listFestival({}).subscribe(
      (res:any)=>{
        this.festivalList = res.data
      }
    )
  }

  addFestivalSubmit(){
    var date = this.formGroup.get('festivalDate')?.value
    this.formGroup.patchValue({userId:this.userdata.getId()})
    this.formGroup.patchValue({year: moment(date,'YYYY-MM-DD').year()})
    this.festivalService.addYearlyFestival(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/festival/list-yearly')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
