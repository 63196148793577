import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { DashboardServiceService } from '../../services/dashboard/dashboard-service.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {

  constructor(
    private dashboardService: DashboardServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.showDashboard()
    setInterval(() => { this.showDashboard() }, 900000);
    if (localStorage.getItem('queryActive') != null) {
      if (localStorage.getItem('queryActive') == 'false') {
        this.queryActive = false
      }
    }
    else {
      this.queryActive = true
    }
    if (localStorage.getItem('chatActive') != null) {
      if (localStorage.getItem('chatActive') == 'false') {
        this.chatActive = false
      }
    }
    else {
      this.chatActive = true
    }
    if (localStorage.getItem('callActive') != null) {
      if (localStorage.getItem('callActive') == 'false') {
        this.callActive = false
      }
    } else {
      this.callActive = true
    }
    if (localStorage.getItem('reportActive') != null) {
      if (localStorage.getItem('reportActive') == 'false') {
        this.reportActive = false
      }
    } else {
      this.reportActive = true
    }
    if (localStorage.getItem('promoActive') != null) {
      if (localStorage.getItem('promoActive') == 'false') {
        this.promoActive = false
      }
    } else {
      this.promoActive = true
    }
  }

  data: any = ''
  showDashboard() {
    this.dashboardService.showDashboard({}).subscribe(
      (res: any) => {
        this.data = res.data
      }, err => {
        //console.log(err)
      }
    )
  }

  /** Active/Inactive Report */
  reportActive: any = true
  reportActiveStatus() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to turn off all astrologers's report service.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.dashboardService.serviceOnOffAllAstro({ service_name: 'report', value: false }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, "Success")
            this.reportActive = false
            localStorage.setItem('reportActive', this.reportActive)
            this.showDashboard();
          },
          err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error');
          }
        )
      }
    })
  }

  /** Active/Inactive Chat */
  chatActive: any
  chatActiveStatus() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to turn off all astrologers's chat service.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.dashboardService.serviceOnOffAllAstro({ service_name: 'chat', value: false }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, "Success")
            this.chatActive = false
            localStorage.setItem('chatActive', this.chatActive)
            this.showDashboard();
          },
          err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error');
          }
        )
      }
    })
  }

  /** Active/Inactive Query */
  queryActive: any = true
  queryActiveStatus() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to turn off all astrologers's query service.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.dashboardService.serviceOnOffAllAstro({ service_name: 'query', value: false }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, "Success")
            this.queryActive = false
            localStorage.setItem('queryActive', this.queryActive)
            this.showDashboard();
          },
          err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error');
          }
        )
      }
    })

  }

  callActive: any = true;
  /** Active/Inactive Call */
  callActiveStatus() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to turn off all astrologers's call service. !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.dashboardService.serviceOnOffAllAstro({ service_name: 'call', value: false }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, "Success")
            this.callActive = false;
            localStorage.setItem('callActive', this.callActive)
            this.showDashboard();
          },
          err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error');
          }
        )
      }
    })
  }

  /** Active/Inactive Promo */
  promoActive: any = true
  promoActiveStatus() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to turn off all astrologers's promo service. !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.dashboardService.serviceOnOffAllAstro({ service_name: 'promo', value: false }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, "Success")
            this.promoActive = false
            localStorage.setItem('promoActive', this.promoActive)
            this.showDashboard();
          },
          err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error');
          }
        )
      }
    })
  }

}
