import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from '../../services/astroservice/astro-service.service';
import { CustomerService } from '../../services/customer/customer.service';
import { OrderServiceService } from '../../services/orders/order-service.service';
import { UserdataService } from '../../services/shared/userdata/userdata.service';

@Component({
  selector: 'app-assign-promo',
  templateUrl: './assign-promo.component.html',
  styleUrls: ['./assign-promo.component.scss'],
})
export class AssignPromoComponent implements OnInit {
  orderId = '';
  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private order: OrderServiceService,
    private toast: ToastrService,
    private orderService: OrderServiceService,
    private astroService: AstroServiceService,
    private route: Router
  ) {}

  astroList: any = [];
  customerList: any = [];
  personList: any = [];

  ngOnInit(): void {
    this.getAstrologerList();
    this.getCustomerList();
    this.formGroup.patchValue({ addedBy: this.userdata.getName() });
    this.formGroup.patchValue({ userId: this.userdata.getId() });
  }

  formGroup: FormGroup = new FormGroup({
    is_promo_refunded: new FormControl(false),
    customerId: new FormControl('', Validators.required),
    astroId: new FormControl('', Validators.required),
    is_call: new FormControl(true, Validators.required),
    is_chat: new FormControl(false, Validators.required),
    duration: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{1,}'),
    ]),
    addedBy: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    comments: new FormControl('', Validators.required),
    personId: new FormControl(''),
    isPaid: new FormControl(false),
  });

  getCustomerList() {
    this.customerService.getCustomersList({}).subscribe(
      (res: any) => {
        this.customerList = res.data;
        // console.log(this.customerList)
      },
      (err) => {
        // console.log(err)
      }
    );
  }
  connectPromo() {
    this.spinner.show();
    this.order.connectPromoOrders({ promoId: this.orderId }).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toast.info(res.message, 'Message');
        this.route.navigateByUrl('/admin/order/promo')
      },
      (err) => {
        this.spinner.hide();
        this.toast.info(err.error.message, 'Message');
      }
    );
  }
  getPerson(event: any) {
    // console.log(event)

    this.customerService.getPersonByCustomerId({ customerId: event }).subscribe(
      (res: any) => {
        this.personList = res.data;
        // console.log(this.personList)
      },
      (err) => {
        // console.log(err)
      }
    );
  }

  get f() {
    return this.formGroup.controls;
  }

  getAstrologerList() {
    this.astroService
      .getAstrologerList({
        is_approved_used: true,
        is_approved: true,
        is_delete: false,
      })
      .subscribe(
        (res: any) => {
          this.astroList = res.data;
        },
        (err) => {
          //console.log(err);
        }
      );
  }

  /* ***********
  Assign Promo
 *********** */

  public assignPromo() {
    // console.log(this.formGroup.get('customerId')?.value)
    // return
    this.spinner.show();
    var minutes = this.formGroup.get('duration')?.value;
    this.formGroup.patchValue({ duration: minutes * 60 });
    this.orderService.assignPromo(this.formGroup.value).subscribe(
      (res: any) => {
        //console.log(res);
        this.spinner.hide();
        this.toast.success('Promo Assigned', 'Success');
        this.formGroup.patchValue({ duration: minutes });
        this.orderId = res.result._id;
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Error');
      }
    );
  }

  selectPromoType(event: any) {
    if (event.target.value == 'chat') {
      this.formGroup.patchValue({ is_call: false });
      this.formGroup.patchValue({ is_chat: true });
    }
    if (event.target.value == 'call') {
      this.formGroup.patchValue({ is_call: true });
      this.formGroup.patchValue({ is_chat: false });
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  /**
   * Check if promo is free of paid
   */
  isPaid = false;
  isPaidChcek() {
    this.isPaid = !this.isPaid;
    this.formGroup.patchValue({ isPaid: this.isPaid });
  }
}
