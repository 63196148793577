import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cancel-and-reassign-report-order-query-not-replied',
  templateUrl: './cancel-and-reassign-report-order-query-not-replied.component.html',
  styleUrls: ['./cancel-and-reassign-report-order-query-not-replied.component.scss']
})
export class CancelAndReassignReportOrderQueryNotRepliedComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private orderService: OrderServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
    private astroService: AstroServiceService
  ) { }

  data: any = '';
  id: any = '';
  orderStatusList: any = []
  refundStatusList: any = []
  isShipped: boolean = false
  isCancelled = false
  isDefaultCancelled = false
  astroList: any = []

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  formGroup: FormGroup = new FormGroup({
    orderId: new FormControl('', Validators.required),
    isCancelled: new FormControl(true, Validators.required),
    penality: new FormControl(''),
    extraCharges: new FormControl(''),
    astroId: new FormControl('', Validators.required),
  });

  orderId: any
  getDataById() {
    this.spinner.show()
    this.orderService.getOrderDetail({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        if(this.data.is_query_order) {
          this.orderId = this.data.queryId?.oquestionId
        }
        if(this.data.is_report_order) {
          this.orderId = this.data.reportId?.oquestionId
        }

        this.formGroup.patchValue({orderId: this.orderId})
        // console.log('complete data::', this.data)
        // console.log('customer amount---',this.data.customer_amount)
        this.getAstrologerList();
        // console.log("astroId" , this.data.astroId)
        // console.log("data.order_id" , this.data.orderId)

      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/order/store')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /** astrologers list */
  getAstrologerList() {
    //console.log('order-data',this.data.is_query_order)
    if(this.data.is_query_order){
      this.astroService.getAstrologerList({ is_approved_used: true, is_approved: true, is_query_active: true, is_query_allow: true, is_current_active: true }).subscribe(
        (res: any) => {
          this.astroList = res.data
        }, err => {
          //console.log(err)
        }
      )
    }
    if(this.data.is_report_order) {
      this.astroService.getAstrologerList({ is_approved_used: true, is_approved: true, is_report_active: true, is_report_allow: true, is_current_active: true }).subscribe(
        (res: any) => {
          this.astroList = res.data
        }, err => {
          //console.log(err)
        }
      )
    }
  }

  /** Selected Astro*/
  singleAstro: any
  selectedAstro(evnt: any) {
    this.astroService.getAstrologerDataById({_id: evnt}).subscribe(
      (res: any) => {
        this.singleAstro = res.data;
        // console.log('new charges---', this.singleAstro.query_charges)
        // console.log('new report charges---', this.singleAstro.report_charges)
      }, err => {
        //console.log(err)
      }
    )
  }
  /* ***********
  cancel and reassign report/query Status
 *********** */
  previousAstroCharges: any
  currentAstroCharges: any
  penalityCharges: any
  extraCharges: any
  totalCharges: any
  differenceOfAstroCharges: any
  cancelAndReassign() {
    //console.log(this.formGroup.controls)
    // return
    if (this.data.is_query_order == true) {
      // this.formGroup.patchValue({ orderId: this.data.queryId._id })
      this.previousAstroCharges = this.data.queryId.astro_amount
      this.currentAstroCharges = this.singleAstro.query_charges
      this.penalityCharges = this.formGroup.get("penality")?.value
      this.extraCharges = this.formGroup.get("extraCharges")?.value
      this.totalCharges = this.penalityCharges + this.extraCharges
      if(this.currentAstroCharges > this.previousAstroCharges) {
        this.differenceOfAstroCharges = this.currentAstroCharges - this.previousAstroCharges
        if(this.totalCharges < this.differenceOfAstroCharges) {
          Swal.fire('Invalid Amount',"Astrologer charges are greater than sum of penality charges and extra charges.",'error')
          return;
        }
      }
      this.orderService.queryCancelAndReassign(this.formGroup.value).subscribe(
        (res: any) => {
          this.toast.success(res.message, "Success")
        },
        (err: any) => {
          this.toast.error(err.message, "Error")
          //console.log(err)
        }
      )
    }

    if (this.data.is_report_order == true) {
      // this.formGroup.patchValue({ orderId: this.data.reportId._id })
      this.previousAstroCharges = this.data.reportId.astro_amount
      this.currentAstroCharges = this.singleAstro.report_charges
      this.penalityCharges = this.formGroup.get("penality")?.value
      this.extraCharges = this.formGroup.get("extraCharges")?.value
      this.totalCharges = this.penalityCharges + this.extraCharges
      if(this.currentAstroCharges > this.previousAstroCharges) {
        this.differenceOfAstroCharges = this.currentAstroCharges - this.previousAstroCharges
        if(this.totalCharges < this.differenceOfAstroCharges) {
          Swal.fire('Invalid Amount',"Astrologer charges are greater than sum of penality charges and extra charges.",'error')
          return;
        }
      }
      this.orderService.reportCancelAndReassign(this.formGroup.value).subscribe(
        (res: any) => {
          this.toast.success(res.message, "Success")
        },
        (err: any) => {
          //console.log(err)
          this.toast.error(err.error.message, "Error")
        }
      )
    }
  }
}

