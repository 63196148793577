<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <!-- <div class="page-header">
        <h4 class="page-title">Assign Promo</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Assign Promo</li>
        </ol>
    </div> -->
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <form [formGroup]="formGroup" (submit)="assignPromo()">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group">
                    <div>
                      <label for="isPaid">Promo Type *</label>
                    </div>
                    <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      formControlName="isPaid"
                      (change)="isPaidChcek()"
                    >
                      <mat-radio-button
                        class="example-radio-button mr-4"
                        [value]="false"
                      >
                        Free
                      </mat-radio-button>
                      <mat-radio-button
                        class="example-radio-button"
                        [value]="true"
                        class="mr-2"
                      >
                        Paid
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="astroId">Astrologer</label>
                    <!-- <select formControlName="astroId" class="form-control">
                                            <option value="" [attr.selected]="true" disabled>Select An Astrologer
                                            </option>
                                            <option [value]="astro._id" *ngFor="let astro of astroList">
                                                #{{astro.astroAutoId}} - {{astro.name}}</option>
                                        </select> -->
                    <ng-select
                      placeholder="Select Astrologer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="astroId"
                    >
                      <ng-option selected disabled value=""
                        >Select Astrologer</ng-option
                      >
                      <ng-option
                        [value]="astro._id"
                        *ngFor="let astro of astroList"
                      >
                        #{{ astro.astroAutoId }} - {{ astro.displayname }}
                      </ng-option>
                    </ng-select>
                    <div
                      *ngIf="
                        f.astroId.invalid &&
                        (f.astroId.dirty || f.astroId.touched)
                      "
                      class="alert alert-danger mt-1"
                    >
                      Required
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="customerId">Customer</label>
                    <!-- <select formControlName="customerId" class="form-control">
                                        <option value="" [attr.selected]="true" disabled>Select A Customer</option>
                                        <option [value]="customer._id" *ngFor="let customer of customerList">#{{customer.customerAutoId}} - {{customer.name}}</option>
                                    </select> -->
                    <ng-select
                      placeholder="Select Customer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="customerId"
                      (change)="getPerson($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Customer</ng-option
                      >
                      <ng-option
                        [value]="customer._id"
                        *ngFor="let customer of customerList"
                      >
                        #{{ customer.customerAutoId }} - {{ customer.name }}
                      </ng-option>
                    </ng-select>
                    <div
                      *ngIf="
                        f.customerId.invalid &&
                        (f.customerId.dirty || f.customerId.touched)
                      "
                      class="alert alert-danger mt-1"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="customerId">Person </label>
                    <ng-select
                      placeholder="Select Customer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="personId"
                    >
                      <ng-option selected disabled value=""
                        >Select Person</ng-option
                      >
                      <ng-option
                        [value]="person._id"
                        *ngFor="let person of personList"
                      >
                        #{{ person.pId }} - {{ person.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Promo Type</label>
                    <select
                      (change)="selectPromoType($event)"
                      class="form-control"
                    >
                      <option value="chat">Chat</option>
                      <option value="call" [attr.selected]="true">Call</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Duration (in Minutes)<br> <small>Note*: 1 minute will be added to duration</small></label>
                    <input
                      type="text"
                      formControlName="duration"
                      class="form-control"
                      placeholder="Enter duration in Minutes"
                    />
                    <div
                      *ngIf="
                        f.duration.invalid &&
                        (f.duration.dirty || f.duration.touched)
                      "
                      class="alert alert-danger mt-1"
                    >
                      Required. Only Numbers are allowed.
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Comment(s)</label>
                    <input
                      type="text"
                      formControlName="comments"
                      class="form-control"
                      placeholder="Enter comment(s)"
                    />
                    <div
                      *ngIf="
                        f.comments.invalid &&
                        (f.comments.dirty || f.comments.touched)
                      "
                      class="alert alert-danger mt-1"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit()"
                    class="btn-form-custom btn btn-success w-75 float-right"
                    [disabled]="!formGroup.valid"
                  >
                    Submit
                  </button>
                </div>
                <div class="col-md-6">
                  <input type="button"
                    value="Connect"
                    (click)="connectPromo()"
                    class="btn-form-custom btn btn-success w-75"
                    [disabled]="!orderId"
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
