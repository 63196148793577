import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { UserdataService } from '../services/shared/userdata/userdata.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private userdata: UserdataService, 
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userdata.getToken() == null) {
            this.router.navigateByUrl('/login')
            return false
          }
      
        if (!route.data || !route.data['permission']) {
            return true;
        }
        const roles = route.data['permission'] as Array<string>
        if (roles) {
        const match = this.userdata.roleMatch(roles)
        if (match) {
            //console.log('match')
            return true;
        } else {
            //console.log('match')
            this.router.navigate([this.selectBestRoute()])
            return false;
        }
        }
        this.router.navigate([this.selectBestRoute()])
        return false;
        // if (this.userdata.getData() != null) {
        //     return true
        // } else {
        //     this.router.navigateByUrl('')
        //     return false
        // }
    }

    selectBestRoute(): string {
        if (this.userdata.getToken() == null) {
          return '/login';
        }
        return '/admin/dashboard'
    }

}