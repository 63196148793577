<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Customer Support</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/support/customer']">Customer Supports</a></li>
      <li class="breadcrumb-item active" aria-current="page">Support Detail</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-8 col-md-4">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <div class="card-body">
                  <div class="border-0">
                    <div class="tab-content">
                      <div class="tab-pane active show" id="tab-51">
                        <div id="profile-log-switch">
                          <div
                            class="media-heading d-flex justify-content-between"
                          >
                            <h4>Issue Details</h4>
                            <button
                              class="btn btn-primary btn-sm"
                              [routerLink]="[
                                '/admin/supports/customer/reply',
                                data._id
                              ]"
                              *ngIf="!data.is_issue_solved"
                            >
                              Reply
                            </button>
                          </div>
                          <hr class="m-0" />
                          <div class="table-responsive">
                            <table class="table row table-borderless">
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong>Support ID :</strong>
                                    {{ data.supportId }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1">Issue Title :</strong
                                    >{{ data.support_title }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-12 col-xl-12 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1"
                                      >Issue Description :</strong
                                    >{{ data.support_description }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1">Status :</strong
                                    ><span
                                      class="badge badge-success"
                                      *ngIf="data.is_issue_solved"
                                      >SOLVED</span
                                    ><span
                                      class="badge badge-danger"
                                      *ngIf="!data.is_issue_solved"
                                      >PENDING</span
                                    >
                                  </td>
                                </tr>
                                <tr *ngIf="data.is_reply_by_system">
                                  <td>
                                    <strong class="mr-1">Solution :</strong
                                    >{{ data.reply_by_system }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1">Created On :</strong
                                    >{{ data.created_at | date: "medium" }}
                                  </td>
                                </tr>
                                <tr *ngIf="data.is_reply_by_system">
                                  <td>
                                    <strong class="mr-1">Reply On :</strong
                                    >{{ data.replyDate | date: "medium" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="chatList?.length > 0">
        <div class="card-header">Reply History</div>
        <div
          class="card-body msg_card_body"
          style="overflow-y: scroll !important; height: 400px !important"
          #scrollMe
          [scrollTop]="chatList.length === 0 ? 0 : scrollMe.scrollHeight"
        >
          <ng-container *ngFor="let chat of chatList">
            <div
              *ngIf="chat.isCustomerReply || chat.isAstroReply"
              class="media media-chat"
            >
              <img
                style="border-radius: 50%"
                class="avatar"
                src="https://img.icons8.com/color/36/000000/administrator-male.png"
                alt="..."
              />
              <div class="media-body admin">
                <p class="inline-block">{{ chat.new_reply }}</p>
                <p class="meta">
                  <time datetime="2018">{{
                    chat.replyDate | date: "medium"
                  }}</time>
                </p>
              </div>
            </div>
            <div
              *ngIf="chat.isSystemReply"
              class="media media-chat media-chat-reverse"
            >
              <div class="media-body reply">
                <p class="float-right">{{ chat.new_reply }}</p>
                <br />
                <p class="meta float-right">
                  <time datetime="2018">{{
                    chat.replyDate | date: "medium"
                  }}</time>
                </p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- COL-END -->
    <div class="col-lg-4 col-md-4">
      <div class="card productdesc">
        <div class="card-body">
          <div class="panel panel-primary">
            <div class="tab-menu-heading">
              <div class="tabs-menu">
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                  <li class="custom-tab">
                    <a href="javascript:void(0)" class="active" data-toggle="tab"
                      >Customer Details</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="panel-body tabs-menu-body">
              <div class="tab-content">
                <div class="tab-pane active" id="tab1">
                  <!-- <h4 class="mb-5 mt-3">General</h4> -->
                  <ul class="list-unstyled mb-0">
                    <li class="row">
                      <div class="col-sm-3 text-muted">Customer ID</div>
                      <div class="col-sm-9">
                        #{{ data.customerId?.customerAutoId }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Name</div>
                      <div class="col-sm-9">{{ data.customerId?.name }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Email</div>
                      <div class="col-sm-9">{{ data.customerId?.email }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
