<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Languages</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Language</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <div class="user-wrap">
                      <button type="submit()" class="btn btn-primary mt-1 mb-2"><i class="fa fa-rss"></i> Add Language</button>
                  </div> -->
          <div>
            <ng-template #content let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="addLanguageForm" (submit)="onAddLanguage()">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="language">Language</label>
                        <input type="text" class="form-control " formControlName="name"
                          placeholder="New Language" required>
                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
                <button type="submit" class="btn btn-outline-dark" (click)="onAddLanguage()"
                  [disabled]="!addLanguageForm.valid">Save</button>
              </div>
            </ng-template>

            <div style="display: flex; justify-content: space-between;">
              <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
              <button class="btn btn-lg btn-outline-primary" (click)="open(content)">Add Language</button>
            </div>

            <hr>

            <!-- <pre>{{ closeResult }}</pre> -->
          </div>

          <div class="row d-flex ">
            <div class="mr-4" *ngFor="let language of languageData">
              <div class="card">
                <div class="card-body text-center">
                  <!-- <blockquote class="quote"> -->
                  <p>{{language.name}}</p>
                  <small class="text-muted">
                    <a [routerLink]="['/admin/languages/editlanguage/', language._id]">Edit</a>
                  </small>
                  <!-- </blockquote> -->
                </div>
              </div>
            </div><!-- COL END -->
          </div>
        </div>
      </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
  </div>
  <!-- CONTAINER CLOSED -->
