<div class="pr-2 pl-2 d-flex justify-content-between">
  <input type="text"  placeholder="Search ID, Name, Coupon Code..." class="form-control col-lg-4 mt-1"
      (keyup)="updateFilter($event)"   />
      <span>
        <button class="btn-user-custom btn btn-outline-success btn-sm mr-2" (click)="getCouponsList()">All</button>
        <button class="btn-user-custom btn btn-outline-primary btn-sm mr-2" (click)="getActiveCouponsList()">Active Coupon</button>
        <button class="btn-user-custom btn btn-outline-warning btn-sm" (click)="getInactiveCouponsList()">Inactive Coupons</button>
      </span>
</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
  <ngx-datatable-column [flexGrow]="1" name="ID" prop="couponId">
      <ng-template let-row="row" ngx-datatable-cell-template>
          #{{row.couponId}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Name" prop="couponName">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <span [title]="row.couponName">{{row.couponName}}</span>
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Coupon Code" prop="couponCode">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <span [title]="row.couponCode">{{row.couponCode | uppercase}}</span>
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Status" prop="status">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <!-- <span [title]="row.status">{{row.status}}</span> -->
          <span class="badge badge-success" *ngIf="row.status">Active</span>
          <span class="badge badge-primary" *ngIf="!row.status">Inactive</span>
      </ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column [flexGrow]="2" name="End Date" prop="endDate">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <span [title]="row.endDate">{{row.endDate | date:'medium'}}</span>
      </ng-template>
  </ngx-datatable-column> -->
  <ngx-datatable-column [flexGrow]="2" name="Date">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.created_at | date:'medium'}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="EndDate">
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.endDate | date:'mediumDate'}}
    </ng-template>
</ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Active Coupon">
    <ng-template let-row="row" ngx-datatable-cell-template>
       <!-- Material switch -->
       <ng-template #active>
        <mat-slide-toggle color="warn" (change)="statusUpdate($event, row)" [checked]="true"></mat-slide-toggle>
      </ng-template>
      <ng-template #inactive>
        <mat-slide-toggle color="warn" (change)="statusUpdate($event, row)" [checked]="false"></mat-slide-toggle>
      </ng-template>
      <ng-container *ngIf="row?.status == true; then active; else inactive">
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Action">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <a [routerLink]="['/admin/malls-coupon/',row._id]" class="fa fa-eye text-primary mr-1"></a>
          <a [routerLink]="['/admin/malls-coupon/edit/',row._id]" class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>       
          <!-- <a (click)="deleteCoupon($event,row._id)" class="ml-2 btn-table-custom btn btn-sm btn-outline-primary">Delete</a> -->
      </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
