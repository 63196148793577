import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';

@Component({
  selector: 'app-astro-wallet-history',
  templateUrl: './astro-wallet-history.component.html',
  styleUrls: ['./astro-wallet-history.component.scss']
})
export class AstroWalletHistoryComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private astro: AstroServiceService
  ) { }

  data: any = '';
  temp: any = '';
  id: any = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getAstroWalletHistoryDataById()
  }

  getAstroWalletHistoryDataById() {
    this.spinner.show()
    this.astro.getAstroWalletHistory({ astroId: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroCreditedWalletHistory() {
    this.spinner.show()
    this.astro.getAstroWalletTransactionHistoryByOperation({ astroId: this.id, txn_type: 1 }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroDebitedWalletHistory() {
    this.spinner.show()
    this.astro.getAstroWalletTransactionHistoryByOperation({ astroId: this.id, txn_type: 2 }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroWalletTransactionsByAdmin() {
    this.spinner.show()
    this.astro.getAstroWalletTransactionHistoryByOperation({ astroId: this.id, is_admin_generate: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.awalletId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
