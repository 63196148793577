import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-detail-customer-issue',
  templateUrl: './detail-customer-issue.component.html',
  styleUrls: ['./detail-customer-issue.component.scss']
})
export class DetailCustomerIssueComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private issueService: IssueServiceService,
    private userdata : UserdataService,
    private orderService : OrderServiceService,
    private _location:Location
  ) { }

  data: any = ''
  id: any = '' 
  chatList:any
  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }
  goBack(){
    this._location.back()
  }
  getDataById(){
    this.spinner.show()
    this.issueService.getDetailCustomerIssues({_id: this.id,userId:this.userdata.getId()}).subscribe(
      (res:any) => {
        this.chatList = res.data.replylogs
        this.spinner.hide()
        this.data = res.data
        if(res.data.is_service_issue){
          if(res.data.orderserviceId.is_query_order){
            this.getQueryData(res.data.orderserviceId.queryId)
          }
          if(res.data.orderserviceId.is_report_order){
            this.getReportData(res.data.orderserviceId.reportId)
          }
          if(res.data.orderserviceId.is_call_order){
            this.getCallData(res.data.orderserviceId.callId)
          }
          if(res.data.orderserviceId.is_chat_order){
            this.getChatData(res.data.orderserviceId.chatId)
          }
        }
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  serviceData:any = {}
  serviceType:string = ''
  getQueryData(id:string){
    this.orderService.getQueryOrderDetail({userId:this.userdata.getId(),_id:id}).subscribe(
      (res:any) =>{
        this.serviceType = 'Query'
        this.serviceData = res.data
      }
    )
  }

  getReportData(id:string){
    this.orderService.getReportOrderDetail({userId:this.userdata.getId(),_id:id}).subscribe(
      (res:any) => {
        this.serviceData = res.data
        this.serviceType = 'Report'
      },err =>{
        //console.log(err)
      }
    )
  }

  getCallData(id:string){
    this.orderService.getCallOrderDetail({userId:this.userdata.getId(),_id:id}).subscribe(
      (res:any) => {
        this.serviceData = res.data
        this.serviceType = 'Call'
      },err =>{
        //console.log(err)
      }
    )
  }

  getChatData(id:string){
    this.orderService.getChatOrderDetail({userId:this.userdata.getId(),_id:id}).subscribe(
      (res:any) => {
        this.serviceData = res.data
        this.serviceType = 'Chat'
      },err =>{
        //console.log(err)
      }
    )
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
  
    var timeString = hours.toString().padStart(2, '0') 
                + ':' + minutes.toString().padStart(2, '0') 
                + ':' + seconds.toString().padStart(2, '0'); 
    return timeString
  } 

}
