<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Group Chat</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/orders/store',dataList.orderId?._id]">Order
                    Details</a></li>
            <li class="breadcrumb-item active" aria-current="page">Chat</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- MODAL VIEW ISSUE-->
    <ng-template #viewCustomerDetails let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Customer Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body row">
            <div [innerHTML]="initialMsg" class="m-auto"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="d('Cross click')">
                Close
            </button>
        </div>
    </ng-template>
    <!-- MODAL VIEW ISSUE END-->
    <!-- ROW-1 OPEN -->
    <div class="row chatbox">
        <div class="col-md-12 col-lg-12 col-xl-12 chat">
            <div class="card">
                <!-- ACTION HEDAER OPEN -->
                <div class="action-header clearfix">
                    <div class="float-left hidden-xs d-flex ml-2">
                        <div class="align-items-center mt-2">
                            <h4 class="mb-0 font-weight-semibold">Group Chat</h4>
                        </div>
                    </div>
                    <ul class="ah-actions actions align-items-center">
                        <li class="call-icon">
                            <a class="fe fe-help-circle text-primary"
                                (click)="openViewCustomerDetails(viewCustomerDetails)"></a>
                        </li>
                    </ul>
                </div>
                <!-- ACTION HEADER END -->

                <!-- MSG CARD-BODY OPEN -->
                <div class="card-body msg_card_body" style="overflow-y: scroll !important; height:400px !important;"
                    #scrollMe [scrollTop]="chatList.length === 0 ? 0 :scrollMe.scrollHeight">
                    <ng-container *ngFor="let chat of chatList">
                        <div class="d-flex justify-content-start" *ngIf="chat.key == '- Initial'">
                            <div class="msg_cotainer" [innerHtml]="chat.message"></div>
                        </div>
                        <div class="mb-4"
                            *ngIf="chat.key != '- Initial' && (chat.type == 3 || chat.type == 2) ">
                            <span class="msg_time">{{chat.name}}</span><br />
                            <ng-container *ngIf="chat.isImage">
                                <span class="d-flex justify-content-start msg_cotainer bg-white m-0">
                                    <span class="bg-primary text-white p-2"
                                        *ngIf="checkExtenstion(chat.message) == 0"><img
                                            [src]="getSanitizeUrl(chat.message)" class="img-chat"
                                            (click)="open(chat.message)" /></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/doc.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/excel.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/pdf-2.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/unkown.png"
                                                class="img-chat-doc" /></a></span>
                                </span>
                                <span class="msg_time">{{chat.date | date:'medium'}}</span><br />
                            </ng-container>
                            <ng-container *ngIf="!chat.isImage">
                                <span class="d-flex justify-content-start msg_cotainer bg-white m-0">
                                    <span class="p-2">{{chat.message}}</span>
                                </span>
                                <span class="msg_time text-danger" *ngIf="!chat.is_to_show">This message will only
                                    appear to you.</span><br *ngIf="!chat.is_to_show" />
                                <span class="msg_time">{{chat.date | date:'medium'}}</span><br />
                            </ng-container>
                        </div>
                        <div class="mb-4" *ngIf="chat.key != '- Initial' && chat.type == 1">
                            <span class="msg_time_send">{{chat.name}}</span><br />
                            <ng-container *ngIf="chat.isImage">
                                <span class="msg_cotainer_send d-flex justify-content-end p-0 m-0 bg-white">
                                    <span class="bg-primary text-white p-2"
                                        *ngIf="checkExtenstion(chat.message) == 0"><img
                                            [src]="getSanitizeUrl(chat.message)" class="img-chat"
                                            (click)="open(chat.message)" /></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/doc.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/excel.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/pdf-2.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/unkown.png"
                                                class="img-chat-doc" /></a></span>
                                    <!-- <span class="bg-primary text-white p-2" *ngIf="checkExtenstion(chat.message) == 1"><a [href]="getSanitizeUrl(chat.message)" target="_blank"><img src="../../../../../assets/icons/doc.png"  class="img-chat-doc"/></a></span>
                                    <span class="bg-primary text-white p-2" *ngIf="checkExtenstion(chat.message) == 2"><a [href]="getSanitizeUrl(chat.message)" target="_blank"><img src="../../../../../assets/icons/pdf-2.png"  class="img-chat-doc"/></a></span> -->
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!chat.isImage">
                                <span class="msg_cotainer_send d-flex justify-content-end p-0 m-0 bg-white">
                                    <span class="bg-primary text-white p-2">{{chat.message}}</span>
                                </span>
                            </ng-container>
                            <span class="msg_time_send text-danger" *ngIf="!chat.is_to_show">This message will only
                                appear to you.</span><br *ngIf="!chat.is_to_show" />
                            <span class="msg_time_send">{{chat.date | date:'medium'}} <img
                                    *ngIf="chat.is_read_astrologer && chat.is_read_customer; else notSeen"
                                    src="../../../../../assets/images/svgs/seen.svg" style="width: 20px;">
                                <ng-template #notSeen>
                                    <img src="../../../../../assets/images/svgs/not-seen.svg" style="width: 20px;">
                                </ng-template>
                            </span><br />
                        </div>
                    </ng-container>
                </div>
                <!-- MSG CARD-BODY END -->

                <!-- CARD-FOOTER OPEN -->
                <div class="card-footer">
                    <div class="msb-reply m-0 typing-div" *ngIf="!isChatComplete && (customerTyping || astrologerTyping)">
                        <span *ngIf="customerTyping">{{dataList.customerId.name}} is Typing....</span><br/>
                        <span *ngIf="astrologerTyping">{{dataList.astroId.displayname}} is Typing....</span>
                    </div>


                    <div class="msb-reply d-flex" *ngIf="!isChatComplete">
                        <form class="w-100" [formGroup]="sendMsgForm" (submit)="sendMsg()"
                            (keydown)="checkEnterPressed($event)">
                            <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
                            <span class="d-inline-block input-group-text attach_btn text-primary"
                                (click)="fileUpload.click()">
                                <i class="fa fa-paperclip"></i>
                            </span>
                            <textarea (keyup)="onKeyUpInput($event)" (blur)="onBlurInput($event)" placeholder="What's on your mind..." class="ml-0" formControlName="message"></textarea>
                            <button [disabled]="!sendMsgForm.valid" type="submit" title="Send Message"><i
                                    class="fa fa-paper-plane-o"></i></button>
                        </form>
                    </div>
                    <div class="msb-reply d-flex" *ngIf="isChatComplete">
                        <div class="w-100 text-center alert alert-danger">Order Has Been Completed</div>
                    </div>
                </div><!-- CARD FOOTER END -->
            </div>
        </div><!-- COL END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->