<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Type to filter the name column..."  class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
    <div>
        <button class="btn btn-primary btn-sm mr-2" (click)="notAnswered()">Not Answered</button>
        <button class="btn btn-info btn-sm mr-2" (click)="notReviewed()">Not Reviewed</button>
        <button class="btn btn-warning btn-sm mr-2" (click)="noCustomerFeedback()">Not Customer Feedback</button>
        <button class="btn btn-success btn-sm mr-2" (click)="noAstrologerFeedbackReply()">No Feedback Reply</button>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="queryList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="oquestionId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.oquestionId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
              <span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' || row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name : row.astroId?.displayname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Customer Amount" prop="customer_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.customerCurrencySymbol}}{{row.customer_amount | number: '.2-2'}}({{row.customerCurrencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Status" prop="order_current_status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.order_current_status}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/query/', row._id]" title="View Details"><i class="fa fa-eye"></i></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
