<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Astro Shop - Products</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/mall-product/list']">Product List</a></li>
      <li class="breadcrumb-item active" aria-current="page">Details</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <div class="card-body">
                  <div class="border-0">
                    <div class="tab-content">
                      <div class="tab-pane active show" id="tab-51">
                        <div id="profile-log-switch">
                          <div class="media-heading d-flex justify-content-between">
                            <h4>Product Details </h4>
                            <a [routerLink]="['/admin/malls-product/edit/',data._id]"
                              class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>
                          </div>
                          <hr class="m-0">
                          <div class="table-responsive">
                            <table class="user-table-custom table row table-bordered">
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0">
                                <tr>
                                  <td><strong>ID :</strong> #{{data?.productId != "" ? data?.productId :'NA'}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Name :</strong> {{data?.productname !="" ? data?.productname : 'NA'}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Slug :</strong> {{data?.product_slug !=""? data?.product_slug : 'NA'}}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0">
                                <tr>
                                  <td>
                                    <strong>Price:</strong>
                                    <!-- Is is valid to show? -->
                                    <span *ngIf="data?.is_astrologer_associate">
                                      {{data.min_price | currency:'INR':'symbol-narrow'}}
                                    </span>
                                    <ng-container *ngIf="data.is_direct_price && data.is_offer_applied">
                                      <span>
                                        <span class="line-through"> {{data?.original_amount |
                                          currency:'INR':'symbol-narrow'}}</span> {{data?.price |
                                        currency:'INR':'symbol-narrow'}} (- {{data?.offer_percentage}} %)
                                      </span>
                                      <br/>
                                      <span>
                                        <span class="line-through"> {{data?.usd_original_amount |
                                          currency:'USD':'symbol-narrow'}}</span> {{data?.usd_price |
                                        currency:'USD':'symbol-narrow'}} (- {{data?.offer_percentage}} %)
                                      </span>
                                    </ng-container>
                                    <ng-container *ngIf="data.is_direct_price && !data.is_offer_applied">
                                      <span>
                                        {{data?.price | currency:'INR':'symbol-narrow'}}
                                      </span>
                                      <span>
                                        {{data?.usd_price | currency:'USD':'symbol-narrow'}}
                                      </span>
                                    </ng-container>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>GST Percentage :</strong> {{data?.gstPercent != "" ? data?.gstPercent :
                                    'NA'}}</td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                <tr>
                                  <td><strong>Delivery Time :</strong> {{data?.delivery_time != "" ? data?.delivery_time
                                    : 'NA'}}</td>
                                </tr>
                              </tbody>
                              <tbody >
                              <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                <tr>
                                  <td>
                                    <strong>Product Code :</strong>
                                    {{data?.productCode != "" ? data?.productCode
                                    : 'NA'}}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0"
                                *ngIf="data?.is_astrologer_associate || data?.is_addon_associate">
                                <tr>
                                  <td class="">
                                    <!-- associative astrologers list -->
                                    <span *ngIf="data?.is_astrologer_associate">
                                      <span class="d-flex justify-content-between pb-3">
                                        <strong class="pb-2">Associative Astrologers List :</strong>
                                        <a class="btn-table-custom btn btn-outline-primary btn-sm col-3"
                                          [routerLink]="['/admin/malls-product/add-astrologer/', data?._id]">Add
                                          Astrologer</a>
                                      </span>
                                      <div class="table-responsive" *ngIf="data?.astrologerList.length>0">
                                        <table class="table table-bordered user-table-cutom-tr-td-d-flex">
                                          <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 p-0">
                                            <tr class="user-table-cutom-tr-td-d-flex">
                                              <td class=" break-word user-table-cutom-tr-td-d-flex"><strong>ID</strong>
                                              </td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">
                                                <strong>Name</strong></td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex"><strong>Display
                                                  Name</strong></td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex"><strong>Display
                                                  Price</strong>
                                              </td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex"><strong>Astro
                                                  Price</strong>
                                              </td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex"><strong>Astro river
                                                  Share</strong>
                                              </td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex extra-td-width">
                                                <strong>Action</strong></td>
                                            </tr>
                                            <tr class="user-table-cutom-tr-td-d-flex"
                                              *ngFor="let astrosList of data.astrologerList">
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">
                                                #{{astrosList.astroId.astroAutoId}}</td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">
                                                {{astrosList.astroId.name}}</td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">
                                                {{astrosList.astroId.displayname}}</td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">{{astrosList.price |
                                                currency:'INR':'symbol-narrow'}} / {{astrosList.usd_price |
                                                  currency:'USD':'symbol-narrow'}} </td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">
                                                {{astrosList.astroprice | currency:'INR':'symbol-narrow'}}</td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">{{astrosList.price -
                                                astrosList.astroprice | currency:'INR':'symbol-narrow'}}</td>
                                              <td class=" break-word user-table-cutom-tr-td-d-flex">
                                                <button class="btn-table-custom btn btn-sm btn-outline-primary mr-2"
                                                  (click)="deleteAssociateAstro(data, astrosList.astroId._id, astrosList._id )">Delete</button>
                                                <button class="btn-table-custom btn btn-sm btn-outline-primary ml-2"
                                                  (click)="open(content, astrosList)">Edit Product Price</button>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <!-- Model popup to add astrologers template start -->
                                        <ng-template #content let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Update Astrologer Product
                                              Price</h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <form [formGroup]="formGroup" (submit)="updateAstroProductPrice()">
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div class="form-group">
                                                    <label for="astroprice">Astro Price (In Rupees) *(only numbers are
                                                      allowed)</label>
                                                    <input type="text" class="form-control " formControlName="astroprice"
                                                      placeholder="Enter Astro Price" required>
                                                    <div *ngIf="f.astroprice.invalid && (f.astroprice.dirty || f.astroprice.touched)"
                                                      class="alert alert-danger mt-1">
                                                      Required
                                                    </div>
                                                  </div>
                                                  <div class="form-group">
                                                    <label for="price">Display Price (In Rupees) *(only numbers are
                                                      allowed)</label>
                                                    <input type="text" class="form-control " formControlName="price"
                                                      placeholder="Enter Price" required>
                                                    <div *ngIf="f.price.invalid && (f.price.dirty || f.price.touched)"
                                                      class="alert alert-danger mt-1">
                                                      Required
                                                    </div>
                                                  </div>
                                                  <div class="form-group">
                                                    <label for="price">Display Price (In USD) *(only numbers are
                                                      allowed)</label>
                                                    <input type="text" class="form-control " formControlName="usd_price"
                                                      placeholder="Enter USD Price" required>
                                                    <div *ngIf="f.usd_price.invalid && (f.usd_price.dirty || f.usd_price.touched)"
                                                      class="alert alert-danger mt-1">
                                                      Required
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="modal.close('Save click')">Cancel</button>
                                            <button type="submit" class="btn btn-outline-dark"
                                              (click)="updateAstroProductPrice()"
                                              [disabled]="!formGroup.valid">Save</button>
                                          </div>
                                        </ng-template>
                                        <!-- Model popup to add astrologers template end -->

                                      </div>
                                    </span>
                                    <!-- associative Addon list -->
                                    <span *ngIf="data?.is_addon_associate">
                                      <span class="d-flex justify-content-between pb-3">
                                        <strong class="pb-2">Addon List :</strong>
                                        <!-- commented for future usage while adding multiple addons -->
                                        <!-- <a class="btn-table-custom btn btn-outline-primary btn-sm" [routerLink]="['/admin/malls-product/add-addons/', data?._id]">Add Addon</a> -->
                                        <button class="btn-table-custom btn btn-sm btn-outline-primary col-3"
                                          (click)="openAddon(contentAddon)">Add Addon</button>
                                      </span>
                                      <div class="table-responsive" *ngIf="data?.addon_detail?.length>0">
                                        <table class="table table-bordered user-table-cutom-tr-td-d-flex">
                                          <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 p-0">
                                            <tr class="user-table-cutom-tr-td-d-flex">
                                              <td class="user-table-cutom-tr-td-d-flex"><strong>Name</strong></td>
                                              <td class="user-table-cutom-tr-td-d-flex"><strong>Price (INR)</strong></td>
                                              <td class="user-table-cutom-tr-td-d-flex"><strong>Price (USD)</strong></td>
                                              <td class="user-table-cutom-tr-td-d-flex"><strong>Image</strong></td>
                                              <td class="user-table-cutom-tr-td-d-flex"><strong>Action</strong></td>
                                            </tr>
                                            <tr class="user-table-cutom-tr-td-d-flex"
                                              *ngFor="let addon of data.addon_detail">
                                              <td class="user-table-cutom-tr-td-d-flex">
                                                <div>
                                                  {{addon.addon_name}}
                                                  <ng-container
                                                    *ngIf="addon.addon_type == 1 || addon.is_price_may_vary">
                                                    <br />
                                                    Note: <span *ngIf="addon.addon_type">Ring Size is Required.
                                                    </span><span *ngIf="addon.is_price_may_vary">Price may also
                                                      vary.</span>
                                                  </ng-container>
                                                </div>
                                              </td>
                                              <td class="user-table-cutom-tr-td-d-flex">{{addon.addon_price |
                                                currency:'INR':'symbol-narrow'}}</td>
                                                <td class="user-table-cutom-tr-td-d-flex">{{addon.addon_usd_price |
                                                  currency:'USD':'symbol-narrow'}}</td>
                                              <td class="user-table-cutom-tr-td-d-flex">
                                                <img [src]="getSanitizeUrl(addon.addon_image)" class="img img-fluid"
                                                  height="80px" width="80px" />
                                              </td>
                                              <td class="">
                                                <button class="btn-table-custom btn btn-sm btn-outline-primary mr-2"
                                                  (click)="deleteAssociateAddons(data, addon._id )">Delete</button>
                                                  <button class="btn-table-custom btn btn-sm btn-outline-info"
                                                  (click)="editAssociateAddons(contentEditAddon, addon)">Edit</button>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <!-- Model popup to add addons template start -->
                                      <ng-template #contentAddon let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Add New Addon</h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <form [formGroup]="formGroupAddon" (submit)="addNewAddonsInTheProduct()">

                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_name">Name *</label>
                                                <input type="text" class="form-control " formControlName="addon_name"
                                                  placeholder="Enter Addon Name" required>
                                                <div
                                                  *ngIf="checkAddon.addon_name.invalid && (checkAddon.addon_name.dirty || checkAddon.addon_name.touched)"
                                                  class="alert alert-danger mt-1">
                                                  Required
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_price">Price (In Rupees) *(only numbers are
                                                  allowed)</label>
                                                <input type="text" class="form-control " formControlName="addon_price"
                                                  placeholder="Enter Price" required>
                                                <div
                                                  *ngIf="checkAddon.addon_price.invalid && (checkAddon.addon_price.dirty || checkAddon.addon_price.touched)"
                                                  class="alert alert-danger mt-1">
                                                  Only numbers are allowed
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_usd_price">Price (In USD) *(only numbers are
                                                  allowed)</label>
                                                <input type="text" class="form-control " formControlName="addon_usd_price"
                                                  placeholder="Enter USD Price" required>
                                                <div
                                                  *ngIf="checkAddon.addon_usd_price.invalid && (checkAddon.addon_usd_price.dirty || checkAddon.addon_usd_price.touched)"
                                                  class="alert alert-danger mt-1">
                                                  Only numbers are allowed
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_image">Image * (Image should be 960 X 540)</label>
                                                <input type="file" class="form-control" placeholder="Addon Image"
                                                  (change)="onAddonImageUpload($event)">
                                                <small
                                                  *ngIf="checkAddon.addon_image.invalid && (checkAddon.addon_image.dirty || checkAddon.addon_image.touched)"
                                                  class="d-block alert alert-danger mt-1">
                                                  Required.
                                                </small>
                                                <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                                                  {{imageError}}
                                                </small>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <input type="checkbox" class="mr-2" formControlName="addon_type_check"
                                                  [value]="true">
                                                <label for="addon_type">Ring size required?</label>
                                                <!-- <div
                                                    *ngIf="checkAddon.addon_type.invalid && (checkAddon.addon_type.dirty || checkAddon.addon_type.touched)"
                                                    class="alert alert-danger mt-1">
                                                    Required
                                                  </div> -->
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <input type="checkbox" class="mr-2" formControlName="is_price_may_vary"
                                                  [value]="true">
                                                <label for="is_price_may_vary">Price May Vary <small>(<strong>Note:
                                                    </strong>Check if addon is of type
                                                    <strong>GOLD</strong>)</small></label>
                                                <!-- <div
                                                    *ngIf="checkAddon.addon_type.invalid && (checkAddon.addon_type.dirty || checkAddon.addon_type.touched)"
                                                    class="alert alert-danger mt-1">
                                                    Required
                                                  </div> -->
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="modal.close('Save click')">Cancel</button>
                                          <button type="submit" class="btn btn-outline-dark"
                                            (click)="addNewAddonsInTheProduct()"
                                            [disabled]="!formGroupAddon.valid">Save</button>
                                          <!-- <button type="submit" class="btn btn-outline-dark"
                                            (click)="addNewAddonsInTheProduct()"
                                            [disabled]="!formGroupAddon.valid">Save</button> -->
                                        </div>
                                      </ng-template>
                                      <!-- Model popup to add addons template end -->
                                      <!-- Model popup to edit addons template start -->
                                      <ng-template #contentEditAddon let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Edit New Addon</h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <form [formGroup]="formGroupEditAddon" (submit)="editAddonsInTheProduct()">

                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_name">Name *</label>
                                                <input type="text" class="form-control " formControlName="addon_name"
                                                  placeholder="Enter Addon Name" required>
                                                <div
                                                  *ngIf="checkAddon.addon_name.invalid && (checkAddon.addon_name.dirty || checkAddon.addon_name.touched)"
                                                  class="alert alert-danger mt-1">
                                                  Required
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_price">Price (In Rupees) *(only numbers are
                                                  allowed)</label>
                                                <input type="text" class="form-control " formControlName="addon_price"
                                                  placeholder="Enter Price" required>
                                                <div
                                                  *ngIf="formGroupEditAddon.controls.addon_price.invalid && (formGroupEditAddon.controls.addon_price.dirty || formGroupEditAddon.controls.addon_price.touched)"
                                                  class="alert alert-danger mt-1">
                                                  Only numbers are allowed
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_usd_price">Price (In USD) *(only numbers are
                                                  allowed)</label>
                                                <input type="text" class="form-control " formControlName="addon_usd_price"
                                                  placeholder="Enter USD Price" required>
                                                <div
                                                  *ngIf="formGroupEditAddon.controls.addon_usd_price.invalid && (formGroupEditAddon.controls.addon_usd_price.dirty || formGroupEditAddon.controls.addon_usd_price.touched)"
                                                  class="alert alert-danger mt-1">
                                                  Only numbers are allowed
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label for="addon_image">Image * (Image should be 960 X 540)</label>
                                                <input type="file" class="form-control p-0" placeholder="Addon Image"
                                                  (change)="onEditAddonImageUpload($event)">
                                                <small
                                                  *ngIf="formGroupEditAddon.controls.addon_image.invalid && (formGroupEditAddon.controls.addon_image.dirty || formGroupEditAddon.controls.addon_image.touched)"
                                                  class="d-block alert alert-danger mt-1">
                                                  Required.
                                                </small>
                                                <small *ngIf="editImageError != ''" class="d-block alert alert-danger mt-1">
                                                  {{editImageError}}
                                                </small>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <input type="checkbox" class="mr-2" formControlName="addon_type"
                                                  [value]="true">
                                                <label for="addon_type">Ring size required?</label>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <input type="checkbox" class="mr-2" formControlName="is_price_may_vary"
                                                  [value]="true">
                                                <label for="is_price_may_vary">Price May Vary <small>(<strong>Note:
                                                    </strong>Check if addon is of type
                                                    <strong>GOLD</strong>)</small></label>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="modal.close('Save click')">Cancel</button>
                                          <button type="submit" class="btn btn-outline-dark"
                                            (click)="editAddonsInTheProduct()"
                                            [disabled]="!formGroupEditAddon.valid || editImageError !=''">Save</button>
                                        </div>
                                      </ng-template>
                                      <!-- Model popup to add addons template end -->
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0" *ngIf="data.productdescription">
                                <tr>
                                  <td><strong>Description :</strong>
                                    <div [innerHTML]="data.productdescription"></div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0" *ngIf="data.welcome_msg">
                                <tr>
                                  <td><strong>First Message for Customer in Group chat :</strong>
                                    <div [innerHTML]="data.welcome_msg"></div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td><strong>Keywords :</strong>
                                    <div [innerHTML]="data.product_keyword"></div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td><strong>Video Link:</strong>
                                    <div>{{data?.videolink != ""? data?.videolink : 'NA'}}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                *ngIf="data?.productcategoryId?.categoryname && !data?.productsubcategoryId?.subcategoryname">
                                <tr>
                                  <td><strong>Product Category Name :</strong>
                                    <div
                                      [innerHTML]="data?.productcategoryId?.categoryname != ''? data?.productcategoryId?.categoryname : 'NA'">
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0"
                                *ngIf="data?.productcategoryId?.categoryname && data?.productsubcategoryId?.subcategoryname">
                                <tr>
                                  <td><strong>Product Category Name :</strong>
                                    <div
                                      [innerHTML]="data?.productcategoryId?.categoryname != ''? data?.productcategoryId?.categoryname : 'NA'">
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0"
                                *ngIf="data?.productsubcategoryId?.subcategoryname">
                                <tr>
                                  <td><strong>Product Sub-Category Name :</strong>
                                    <div [innerHTML]="data?.productsubcategoryId?.subcategoryname"></div>
                                    <!-- <div>{{data?.productsubcategoryId?.subcategoryname != 'null' ? data?.productsubcategoryId?.subcategoryname : 'NA'}}</div> -->
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0" *ngIf="data?.product_image?.length>0">
                                <tr>
                                  <td>
                                    <div class="col-12 d-flex">
                                      <a [href]="getSanitizeUrl(img)" target="_blank"
                                        *ngFor="let img of data?.product_image"><img [src]="getSanitizeUrl(img)"
                                          class="img img-fluid img-thumbnail" style="width:100px;height:100px;" /></a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                <tr>
                                  <td><strong>Fields Applied :</strong>
                                    <div>
                                      {{data?.fields_applied?.isNameOn == true ? 'Name' : ''}}
                                      {{data?.fields_applied?.isPhoneOn == true ? '| Phone' : ''}}
                                      {{data?.fields_applied?.isEmailOn == true ? '| Email' : ''}}
                                      {{data?.fields_applied?.isGenderOn == true ? '| Gender' : ''}}
                                      {{data?.fields_applied?.isAddressOn == true ? '| Address' : ''}}
                                      {{data?.fields_applied?.isCommentsOn == true ? '| Comments' : ''}}
                                      {{data?.fields_applied?.isMaritalStatusOn == true ? '| Marital Status' : ''}}
                                      {{data?.fields_applied?.isQuantityOn == true ? '| Quantity' : ''}}
                                      {{data?.fields_applied?.isRingSizeOn == true ? '| Ring Size' : ''}}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                <tr class="">
                                  <td class="">
                                    <strong class="pb-2 pl-2">Reviews :</strong>
                                    <div class="table-responsive">
                                      <div class="pr-2 pl-2 d-flex justify-content-between">
                                        <input type="text"  placeholder="Type to filter the name column..." class="form-control
                                          col-lg-4 mt-1" (keyup)="updateFilter($event)" />
                                      </div>
                                      <br>
                                      <ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'"
                                        [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                        [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]"
                                        style="position: relative !important;">
                                        <ngx-datatable-column [flexGrow]="1" name="ID" prop="reviewId">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                            #{{row.reviewId}}
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [flexGrow]="3" name="Customer Review Comments"
                                          prop="customerReviewComments">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span
                                              [title]="row.customerReviewComments">{{row.customerReviewComments}}</span>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [flexGrow]="1" name="Review Stars"
                                          prop="customerReviewStars">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span [title]="row.customerReviewStars">{{row.customerReviewStars}}</span>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [flexGrow]="2" name="Review replay" prop="reviewReply">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span [title]="row.reviewReply">{{row.reviewReply}}</span>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [flexGrow]="2" name="Date">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.createdAt | date:'medium'}}
                                          </ng-template>
                                        </ngx-datatable-column>
                                      </ngx-datatable>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->