import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/admin/services/report/report.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-edit-report',
  templateUrl: './add-edit-report.component.html',
  styleUrls: ['./add-edit-report.component.scss']
})
export class AddEditReportComponent implements OnInit {

  id: any = ''
  data: any = []
  BaseUrl = ''
  path: any = ''
  isPath: boolean = false

  addReportForm: any = new FormGroup({
    reportname: new FormControl('',[Validators.required]),
    reportdescription: new FormControl('',[Validators.required]),
    report_image: new FormControl(''),
    userId: new FormControl(),
    addedBy: new FormControl(),
    updatedId: new FormControl(),
    updatedBy: new FormControl(),
    _id: new FormControl()
  })
    
  constructor(
    private spinner: NgxSpinnerService,
    private reportService: ReportService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private userdata: UserdataService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.path = 'reports/editreports/:id'
    if(this.router.snapshot.routeConfig?.path === this.path){
      this.getDataById()
      this.isPath = true
    }
    this.addReportForm.patchValue({userId: this.userdata.getId()})
    this.addReportForm.patchValue({addedBy: this.userdata.getName()})
    this.addReportForm.patchValue({updatedBy: this.userdata.getName()})
    this.addReportForm.patchValue({updatedId: this.userdata.getId()})
    this.addReportForm.patchValue({_id: this.id})
    
  }

  getDataById(){
    this.spinner.show()
    this.reportService.getReportsDataById({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
        this.addReportForm.patchValue({reportname: this.data.reportname})
        this.addReportForm.patchValue({reportdescription: this.data.reportdescription})
        this.addReportForm.patchValue({report_image: this.data.report_image})
      },
      err => {
        //console.log(err) 
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  // public onReportImageUpload(evnt: any){
  //   if(evnt.target.files.length > 0)
  //   {
  //     //console.log("correct", evnt.target.files[0])
  //     this.addReportForm.patchValue({
  //       report_image: evnt.target.files[0]
  //     })
  //   }
  // }
  imageError:any = ''
  public onReportImageUpload(evnt: any){
    this.imageError = null;
    if (evnt.target.files && evnt.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 200;
      const max_width = 200; 

      if (evnt.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';
          this.addReportForm.patchValue({
            report_image: ''
          })
        return;
      }

      if (!_.includes(allowed_types, evnt.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        this.addReportForm.patchValue({
          report_image: ''
        })
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(evnt.target.files[0]);
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs:any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
            this.imageError =
              'Banner dimentions allowed ' +
              max_width +
              'px X ' +
              max_height +
              'px';
              this.addReportForm.patchValue({
                report_image: ''
              })
            return;
          }
          else {
            //console.log('ok')
            this.addReportForm.patchValue({
              report_image: evnt.target.files[0]
            })
          }
        };
      };
    }
  }

  get f(){
    return this.addReportForm.controls
  }

  public onAddReportSubmit(){
    if(!this.isPath) {
      if(this.addReportForm.get('report_image').value == ''){
        Swal.fire('Image Required!','Upload valid report image','error')
        return
      }
      const data = new FormData()
      data.append("reportname",this.addReportForm.get("reportname").value)
      data.append("reportdescription",this.addReportForm.get("reportdescription").value)
      data.append("report_image",this.addReportForm.get("report_image").value)
      data.append("userId",this.addReportForm.get("userId").value)
      data.append("addedBy",this.addReportForm.get("addedBy").value)
      this.spinner.show()
      this.reportService.addNewReport(data).subscribe(
        (res:any) => {
          this.spinner.hide()
          this.toast.success(res.message, 'Sucess')
          this.reloadCurrentRoute()
          //this.route.navigateByUrl('/admin/reports')  
          // console.log(res)
        },
        err => {
          this.spinner.hide()
          this.toast.error('Something went wrong!', 'Error')
          //console.log(err)
        }
      )
    }
    else {
      const data = new FormData()
      data.append("reportname",this.addReportForm.get("reportname").value)
      data.append("reportdescription",this.addReportForm.get("reportdescription").value)
      data.append("report_image",this.addReportForm.get("report_image").value)
      data.append("updatedId",this.addReportForm.get("updatedId").value),
      data.append("updatedBy",this.addReportForm.get("updatedBy").value),
      data.append("_id", this.addReportForm.get("_id").value)
      this.spinner.show()
      this.reportService.updateReport(data).subscribe(
        (res:any) => {
          this.spinner.hide()
          this.toast.success(res.message, 'Sucess')
          //this.reloadCurrentRoute()
          this.route.navigateByUrl('/admin/reports')  
          // console.log(res)
        },
        err => {
          this.spinner.hide()
          this.toast.error('Something went wrong!', 'Error')
          //console.log(err)
        }
      )
    }
   
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate([currentUrl]);
    });
}

}
