<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Coupons</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a style="cursor:pointer" (click)="goBack()">Coupon List</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Details</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <div class="card-body">
                  <div class="border-0">
                    <div class="tab-content">
                      <div class="tab-pane active show" id="tab-51">
                        <div id="profile-log-switch">
                          <div
                            class="media-heading d-flex justify-content-between"
                          >
                            <h4>Coupon Details</h4>
                            <!-- <a [routerLink]="['/admin/malls-coupon/edit/',data._id]" class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a> -->
                          </div>
                          <hr class="m-0" />
                          <div class="table-responsive">
                            <table
                              class="user-table-custom table row table-bordered"
                            >
                              <tbody
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0"
                              >
                                <tr>
                                  <td>
                                    <strong>ID :</strong> #{{ data.couponId }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Coupon Code :</strong>
                                    {{ data.code }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Coupon Type :</strong>
                                    <span *ngIf="data?.couponType == 2">
                                      Fixed
                                    </span>
                                    <span *ngIf="data?.couponType == 1">
                                      Percentage
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Coupon Amount:</strong>
                                    <span *ngIf="data?.couponType == 2">
                                      {{
                                        data.amount
                                          | currency: "INR":"symbol-narrow"
                                      }}
                                    </span>
                                    <span *ngIf="data?.couponType == 1">
                                      {{
                                        data.amount
                                      }}%
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Minimum Amount :</strong>
                                    {{
                                      data.minimumAmount
                                        | currency: "INR":"symbol-narrow"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Maximum Discount :</strong
                                    >{{
                                      data?.maximumDiscount
                                        | currency: "INR":"symbol-narrow"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                *ngIf="data.isApplicableOnForeignOrders"
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Coupon Amount (USD):</strong>
                                    {{
                                      data.couponUsdAmount
                                        | currency: "USD":"symbol-narrow"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                *ngIf="data.isApplicableOnForeignOrders"
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Minimum Amount (USD):</strong>
                                    {{
                                      data.minimumUsdOrder
                                        | currency: "USD":"symbol-narrow"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody
                                *ngIf="data.isApplicableOnForeignOrders"
                                class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0"
                              >
                                <tr>
                                  <td>
                                    <strong>Maximum Discount (USD):</strong
                                    >{{
                                      data?.maximumUsdDiscount
                                        | currency: "USD":"symbol-narrow"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td>
                                    <strong>Applicable On :</strong>
                                    <span *ngIf="data?.applicableOn == 1">
                                      <div>New User</div>
                                    </span>
                                    <span *ngIf="data?.applicableOn == 2">
                                      <div>All User</div>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong>Status :</strong><br />
                                    <span *ngIf="data?.status">
                                      <div
                                        class="badge badge-success badge-small"
                                      >
                                        Active
                                      </div>
                                    </span>
                                    <span *ngIf="!data?.status">
                                      <div
                                        class="badge badge-primary badge-small"
                                      >
                                        Inactive
                                      </div>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td>
                                    <strong>Coupon Description :</strong>
                                    <div>{{ data.couponDescription }}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong>Is Valid Coupon :</strong><br />
                                    <span *ngIf="data?.status">
                                      <div
                                        class="badge badge-success badge-small"
                                      >
                                        Valid
                                      </div>
                                    </span>
                                    <span *ngIf="!data?.status">
                                      <div
                                        class="badge badge-primary badge-small"
                                      >
                                        Invalid
                                      </div>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td>
                                    <strong>Usage Limit Per Coupon :</strong>
                                    <div>{{ data.usageLimitPerCoupon }}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong>Usage Limit Per User :</strong>
                                    <div>{{ data.usageLimitPerUser }}</div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 pr-0">
                                <tr>
                                  <td>
                                    <strong>Start Date :</strong>
                                    <div>
                                      {{
                                        data?.startDate | date: "MMM dd,yyyy"
                                      }}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="border-0 col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong>End Date :</strong>
                                    <div>
                                      {{ data?.endDate | date: "MMM dd,yyyy" }}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
