<div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
    <input type="text"  placeholder="Search ID..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
    <!-- <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->
    <div>
        <mat-form-field appearance="fill">
            <mat-label class="mr-2">Search By Date:</mat-label>
            <mat-date-range-input class="d-inline-block" style="width: 35%;" [formGroup]="range" [rangePicker]="picker">
                <input matStartDate readonly formControlName="start" placeholder="Start date">
                <input matEndDate readonly formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <button class="btn btn-sm btn-outline-primary" (click)="showDate()">Search</button>
        <!-- <button class="btn btn-success btn-sm mr-2" (click)="getAstroCreditedWalletHistory()">Recharged Transactions</button>
            <button class="btn btn-primary btn-sm mr-2" (click)="getAstroDebitedWalletHistory()">Deducted Transactions</button>
            <button class="btn btn-info btn-sm" (click)="getAstroWalletTransactionsByAdmin()">Admin Transactions</button> -->
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]"
    style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Wallet Id" prop="oId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.oId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Customer">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.customerId?.name}} - #{{row.customerId?.customerAutoId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}} {{row.amount | number: '.2-2'}} ({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="GST" prop="gst_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}} {{row.gst_amount | number: '.2-2'}} ({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Total Amount" prop="final_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}} {{row.final_amount | number: '.2-2'}} ({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Txn. ID" prop="txnId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.txnId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Invoice" prop="invoiceUrl">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a *ngIf="row.invoiceUrl != ''" [href]=getSanitizeUrl(row.invoiceUrl) target="_blank">Invoice</a>
            <span *ngIf="row.invoiceUrl == ''" class="text-dangers">Invoice</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>