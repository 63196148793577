import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-common-astro-page',
  templateUrl: './common-astro-page.component.html',
  styleUrls: ['./common-astro-page.component.scss']
})
export class CommonAstroPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
