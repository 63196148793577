<div class="row pr-2 pl-2">
    <!-- CONTAINER -->
    <div class="container content-area relative">
        <ngx-spinner></ngx-spinner>
        <!-- ROW-1 OPEN -->
        <form [formGroup]="noticeBoardForm" (submit)="addNotice()">
            <div class="row">
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                    <br/>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Add Notice For Customer</h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="exampleInputname"
                                            class="mb-4">Notice</label><br />
                                        <textarea class="form-control" placeholder="Notice" formControlName="customization_value"></textarea>
                                        <div *ngIf="f.customization_value.invalid && (f.customization_value.dirty || f.customization_value.touched)" class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-center">
                            <button type="submit"
                                class="btn-form-custom col-4 btn btn-success mt-1"
                                [disabled]="!noticeBoardForm.valid">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- ROW-1 CLOSED -->
    </div>
    <!--CONTAINER CLOSED -->
</div>