import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-mall-product',
  templateUrl: './detail-mall-product.component.html',
  styleUrls: ['./detail-mall-product.component.scss']
})
export class DetailMallProductComponent implements OnInit {

  BaseUrl: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private productMallService: ProductMallService,
    private userdata: UserdataService,
    private route: Router,
    private toast: ToastrService, private trusturl: DomSanitizer,
    private modalService: NgbModal,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  data: any = '';
  id: any = '';
  dataList = [];
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.formGroupAddon.patchValue({ _id: this.id })
    this.getDataById();
    this.formGroupAddon.patchValue({ userId: this.userdata.getId() })
  }

  getDataById() {
    this.spinner.show()
    this.productMallService.detailMallProduct({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.dataList = res.reviews;
        this.temp = this.dataList;
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-product/list')
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  /**
* Update filter
*/
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.customerReviewComments.toLowerCase().indexOf(val) !== -1 ||
        d.customerReviewStars.toString().indexOf(val) !== -1 ||
        d.customerId.toString().indexOf(val) !== -1 ||
        d.reviewReply.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }


  /**
   * Delete associate astrologer
   */
  deleteAssociateAstro(data: any, astroId: any, astroInnerId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.productMallService.deleteAssociativeAstrologerFromProduct({ _id: data._id, userId: this.userdata.getId(), astroinnerId: astroInnerId, astroId: astroId }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, 'Success')
            location.reload()
          }, err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error')
          }
        )
      }
    })
  }


  /**
   * Delete associate Addons
   */
  deleteAssociateAddons(data: any, addonId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.productMallService.deleteAssociativeAddonsFromProduct({ _id: data._id, userId: this.userdata.getId(), addonId: addonId }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success(res.message, 'Success')
            location.reload()
          }, err => {
            this.spinner.hide()
            this.toast.error(err.error.message, 'Error')
          }
        )
      }
    })
  }

  /**
   ******************************************
   *
   * Update astrologer price start
   *
   *******************************************
   */

  /** Update atro product price form */
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl(''),
    astroId: new FormControl(''),
    astroprice: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    price: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    usd_price: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")])
  });


  /* ***********
Modal Popup functions
*********** */
  astroPriceData: any = ''
  closeResult = '';
  open(content: any, astroPriceData: any) {
    this.formGroup.patchValue({ 'astroprice': astroPriceData.astroprice })
    this.formGroup.patchValue({ 'price': astroPriceData.price })
    this.formGroup.patchValue({ 'usd_price': astroPriceData.usd_price })
    this.astroPriceData = astroPriceData;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /** Update atro product price function */
  public updateAstroProductPrice() {
    //console.log(this.astroPriceData.astroId._id,'------',this.astroPriceData)
    this.formGroup.patchValue({ 'astroId': this.astroPriceData.astroId._id })
    this.formGroup.patchValue({ '_id': this.id })
    var astroprice = this.formGroup.get('usd_price')?.value
    this.formGroup.patchValue({ 'usd_price': Number(astroprice) })
    this.spinner.show()
    this.productMallService.updateAstroProductPrice(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'success')
        //this.route.navigateByUrl('/admin/mall-product/list')
        this.modalService.dismissAll();
        this.getDataById();
        location.reload()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'error')
      }
    )
  }

  /** validation check */
  get f() {
    return this.formGroup.controls
  }

  /**
  ******************************************
  *
  * Update astrologer price end
  *
  *******************************************
  */

  /**
 ******************************************
 *
 * Add new addons start
 *
 *******************************************
 */

  /** add new addon form */
  formGroupAddon: FormGroup = new FormGroup({
    _id: new FormControl(''),
    userId: new FormControl(''),
    addon_name: new FormControl('', Validators.required),
    addon_image: new FormControl('', Validators.required),
    addon_type_check: new FormControl(''),
    addon_type: new FormControl(''),
    is_price_may_vary: new FormControl(false),
    addon_price: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    addon_usd_price: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")])//usd price for add-ons
  });

  /**
   *Addon image
   */
  imageError: string = ''
  imageSrc = ''
  public onAddonImageUpload(event: any) {
    this.imageError = ''
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 540;
      const max_width = 960;
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.imageSrc = reader.result as string;
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
            this.imageError =
              'Image dimentions allowed ' +
              max_width +
              ' X ' +
              max_height +
              'px';
            return;
          } else {
            this.formGroupAddon.patchValue({
              addon_image: event.target.files[0]
            })
          }
        }
      };
    }
  }


  /* ***********
Modal Popup functions
*********** */
  closeAddonResult = '';
  openAddon(content: any) {
    // console.log("----------")
    // return
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeAddonResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeAddonResult = `Dismissed ${this.getDismissReasonAddon(reason)}`;
        }
      );
  }

  private getDismissReasonAddon(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /** addNewAddonsInTheProduct */
  public addNewAddonsInTheProduct() {
    if (this.formGroupAddon.get('addon_type_check')?.value) {
      //console.log('1')
      this.formGroupAddon.patchValue({ "addon_type": 1 })
    } else {
      //console.log('0')
      this.formGroupAddon.patchValue({ "addon_type": 0 })
    }
    //console.log(this.formGroupAddon.get('is_price_may_vary')?.value,this.formGroupAddon.get('addon_type')?.value)
    //return
    const data = new FormData;
    data.append("addon_name", this.formGroupAddon.get("addon_name")?.value)
    data.append("addon_image", this.formGroupAddon.get("addon_image")?.value)
    data.append("addon_type", this.formGroupAddon.get("addon_type")?.value)
    data.append("is_price_may_vary", this.formGroupAddon.get("is_price_may_vary")?.value)
    data.append("addon_price", this.formGroupAddon.get("addon_price")?.value)
    data.append("addon_usd_price", this.formGroupAddon.get("addon_usd_price")?.value)
    data.append("_id", this.formGroupAddon.get("_id")?.value)
    data.append("userId", this.formGroupAddon.get("userId")?.value)
    this.spinner.show()
    this.productMallService.addNewProductAddons(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'success')
        this.modalService.dismissAll();
        location.reload()

      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'error')
      }
    )
  }

  /** validation check */
  get checkAddon() {
    return this.formGroupAddon.controls
  }


  /**
 ******************************************
 *
 * Add new addons end
 *
 *******************************************
 */

  /**
******************************************
*
* Edit addons
*
*******************************************
*/
  editAssociateAddons(content: any, addOndata: any) {
    this.formGroupEditAddon.patchValue({productId: this.id})
    this.formGroupEditAddon.patchValue({addOnId: addOndata._id})
    this.formGroupEditAddon.patchValue({ userId: this.userdata.getId() })
    this.formGroupEditAddon.patchValue({addon_name: addOndata.addon_name})
    this.formGroupEditAddon.patchValue({addon_price: addOndata.addon_price})
    if(addOndata.addon_type == 0){
      this.formGroupEditAddon.patchValue({addon_type: false})
    }else{
      this.formGroupEditAddon.patchValue({addon_type: true})
    }
    this.formGroupEditAddon.patchValue({addon_usd_price: addOndata.addon_usd_price})
    this.formGroupEditAddon.patchValue({is_price_may_vary: addOndata.is_price_may_vary})
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeAddonResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeAddonResult = `Dismissed ${this.getDismissReasonAddon(reason)}`;
        }
      );
  }

  /** add new addon form */
  formGroupEditAddon: FormGroup = new FormGroup({
    productId: new FormControl(''),
    addOnId: new FormControl(''),
    userId: new FormControl(''),
    addon_name: new FormControl('', Validators.required),
    addon_price: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    addon_image: new FormControl(''),
    addon_type: new FormControl(''),
    is_price_may_vary: new FormControl(false),
    addon_usd_price: new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")])//usd price for add-ons
  });

  /**
   *Addon Edit image
   */
  editImageError: string = ''
  editImageSrc = ''
  public onEditAddonImageUpload(event: any) {
    this.editImageError = ''
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 540;
      const max_width = 960;
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.editImageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.editImageSrc = reader.result as string;
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
            this.editImageError =
              'Image dimentions allowed ' +
              max_width +
              ' X ' +
              max_height +
              'px';
            return;
          } else {
            this.formGroupEditAddon.patchValue({
              addon_image: event.target.files[0]
            })
          }
        }
      };
    }
  }

  editAddonsInTheProduct(){
    if (this.formGroupEditAddon.get('addon_type')?.value) {
      //console.log('1')
      this.formGroupEditAddon.patchValue({ "addon_type": 1 })
    } else {
      //console.log('0')
      this.formGroupEditAddon.patchValue({ "addon_type": 0 })
    }
    const data = new FormData;
    data.append("addon_name", this.formGroupEditAddon.get("addon_name")?.value)
    data.append("addon_image", this.formGroupEditAddon.get("addon_image")?.value)
    data.append("addon_type", this.formGroupEditAddon.get("addon_type")?.value)
    data.append("is_price_may_vary", this.formGroupEditAddon.get("is_price_may_vary")?.value)
    data.append("addon_price", this.formGroupEditAddon.get("addon_price")?.value)
    data.append("addon_usd_price", this.formGroupEditAddon.get("addon_usd_price")?.value)
    data.append("addOnId", this.formGroupEditAddon.get("addOnId")?.value)
    data.append("productId", this.formGroupEditAddon.get("productId")?.value)
    data.append("userId", this.formGroupEditAddon.get("userId")?.value)
    this.spinner.show()
    this.productMallService.updateProductAddons(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'success')
        this.modalService.dismissAll();
        this.getDataById();
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'error')
      }
    )
  }
  /**
******************************************
*
* Edit addons end
*
*******************************************
*/

}

