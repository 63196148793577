import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-debit-wallet-amount',
  templateUrl: './debit-wallet-amount.component.html',
  styleUrls: ['./debit-wallet-amount.component.scss']
})
export class DebitWalletAmountComponent implements OnInit {

  id: any = ''

  CreditDebitWalletForm: any = new FormGroup({
    customerId: new FormControl(''),
    txn_type: new FormControl(2),
    amount: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    entry_by: new FormControl(''),
    userId: new FormControl(''),
    txnId: new FormControl('', [Validators.required]),
    wallet_comments: new FormControl('', [Validators.required]),
    is_admin_generate: new FormControl(true),
  })
  data: any;

  constructor(
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private toast: ToastrService,
    private route: Router,
    private userdata: UserdataService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    //this.id = this.router.snapshot.paramMap.get('id')
    this.router.parent?.params.subscribe(
      (params) => {
        this.id = params.data
        this.getDataById()
      });
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.CreditDebitWalletForm.patchValue({ entry_by: this.userdata.getName() })
    this.CreditDebitWalletForm.patchValue({
      userId: this.userdata.getId()
    })
    this.CreditDebitWalletForm.patchValue({ is_admin_generate: true })
    this.CreditDebitWalletForm.patchValue({ customerId: this.id })
  }
  
  getDataById() {
    this.spinner.show()
    this.customerService.getCustomerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  get f() {
    return this.CreditDebitWalletForm.controls
  }

  public onDebitSubmit() {
    this.spinner.show()
    this.customerService.CreditDebitCustomerWalletByAdmin(this.CreditDebitWalletForm.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success("Wallet Debited!", 'Sucess')
        this.reloadCurrentRoute()

        //console.log(res)
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error')
        //console.log(err)
      } 
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

}
