<div style="display: flex; justify-content: space-between;align-items: center;" class="pl-2 pr-2">
  <input type="text" placeholder="Search ID..." class="form-control col-lg-3 mt-1" (keyup)="updateFilter($event)" />
    <!-- <input matInput [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker> -->
      <div class="col-lg-4 ">
          <button class="btn btn-success btn-sm mr-1" (click)="getAstroCreditedWalletHistory()">Recharged Transc.</button>
          <button class="btn btn-primary btn-sm mr-1" (click)="getAstroDebitedWalletHistory()">Deducted Transc.</button>
          <button class="btn btn-info btn-sm" (click)="getAstroWalletTransactionsByAdmin()">Admin Transc.</button>
      </div>
      <div class="col-lg-4 ">
        <mat-form-field appearance="fill">
            <mat-label class="mr-2">Search By Date:</mat-label>
            <mat-date-range-input class="d-inline-block" style="width: 35%;" [formGroup]="range" [rangePicker]="picker">
                <input matStartDate readonly formControlName="start" placeholder="Start date">
                <input matEndDate readonly formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <button class="btn btn-sm btn-outline-primary" (click)="showDate()">Search</button>
    </div>
    <button class="col-lg-1 btn btn-warning btn-sm" (click)="generateExcel()">Export</button>
</div>
<br>
<ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50"
[footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
<ngx-datatable-column [flexGrow]="1" name="Wallet Id" prop="adminwalletId">
  <ng-template let-row="row" ngx-datatable-cell-template>
    #{{row.adminwalletId}}
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column [flexGrow]="1" name="Type">
  <ng-template let-row="row" ngx-datatable-cell-template>
    <span *ngIf="row.txn_type == 1" class="badge badge-success">Recharged</span>
    <span *ngIf="row.txn_type == 2" class="badge badge-primary">Deducted</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column [flexGrow]="1" name="Amount" prop="amount">
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.currencySymbol}}{{row.amount | number: '.2-2'}} ({{row.currencyCode}})
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column [flexGrow]="1" name="Balance" prop="balance">
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.currencySymbol}}<span *ngIf="!row.isForeignOrder">{{row.balance | number: '.2-2'}}</span>
    <span *ngIf="row.isForeignOrder">{{row.foreignBalance | number: '.2-2'}}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column [flexGrow]="3" name="Wallet Remarks" prop="wallet_comments">
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.wallet_comments}}
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column [flexGrow]="1" name="Txn. ID" prop="txnId">
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.txnId}}
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.created_at | date:'medium'}}
    </ng-template>
</ngx-datatable-column>
</ngx-datatable>