import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SkillsServiceService } from 'src/app/admin/services/skills/skills-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-skills-list',
  templateUrl: './skills-list.component.html',
  styleUrls: ['./skills-list.component.scss']
})
export class SkillsListComponent implements OnInit {

  constructor(
    private skillsService: SkillsServiceService,
    private userdata: UserdataService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) { }
  title = 'angular-datatables';

  skillsData: any = [];
  id: any ='';
  temp:any= [];
  getSkillsData:any= [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getSkillsList()
    this.skillsData = this.getSkillsData;
    this.addSKillsForm.patchValue({userId: this.userdata.getId()})
    this.addSKillsForm.patchValue({addedBy: this.userdata.getName()})
  }

  /* ***********
  list SKills
  *********** */

 getSkillsList() {
    this.spinner.show()
    this.skillsService.getSkillsList(this.userdata.getData()).subscribe(
    (res: any) => {
      this.spinner.hide()
      this.skillsData = res.data;
      this.temp = this.skillsData;
      this.getSkillsData = this.skillsData;
    },
    (err) => {
      this.spinner.hide()
      //console.log(err);
    }
  );
}

/* ***********
Modal Popup add SKillss start
*********** */
closeResult = '';

open(content: any) {
  this.modalService
    .open(content, { ariaLabelledBy: 'modal-basic-title' })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

/* ***********
 Add new SKills
*********** */
addSKillsForm: FormGroup = new FormGroup({
  skillname: new FormControl('', Validators.required),
  userId: new FormControl(),
  addedBy: new FormControl(),
});

get f(){
  return this.addSKillsForm.controls
}

public onAddSkills() {
  this.spinner.show();
  this.skillsService.addNewSkill(this.addSKillsForm.value).subscribe(
    (res: any) => {
      this.spinner.hide()
      this.toast.success('Skill added!', 'Success');
      this.modalService.dismissAll();
      this.getSkillsList();
    },
    (err) => {
      this.spinner.hide()
      this.toast.error('Something went wrong!', 'Error');
      //console.log(err);
    }
  );
}

/**
 * Update filter
 */
  updateFilter(event: any) {
    //this.spinner.show()
    this.skillsService.searchSkillByName({name:event.target.value}).subscribe(
      (res: any) => {
        //this.spinner.hide()
        this.skillsData = res.data;
        this.temp = this.skillsData;
        this.getSkillsData=this.skillsData;
      },
      (err) => {
        //this.spinner.hide()
        //console.log(err);
      }
    );
  }


}
