<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Type to filter the name column..."  class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />
</div>
<br>
<ngx-datatable class="material" [rows]="orderList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="oserviceId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.oserviceId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="ID">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.is_chat_order">
                <span class="">#{{row.chatId.ochatId}}</span>
            </ng-container>
            <ng-container *ngIf="row.is_call_order">
                <span class="">#{{row.callId.ocallId}}</span>
            </ng-container>
            <ng-container *ngIf="row.is_report_order">
                <span class="">#{{row.reportId.oreportId}}</span>
            </ng-container>
            <ng-container *ngIf="row.is_query_order">
                <span class="">#{{row.queryId.oquestionId}}</span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Order Type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.is_chat_order">
                <span class="badge badge-primary mr-1">Chat</span>
                <span
                    *ngIf="row.chatId?.is_promotional_chat && !row.chatId?.is_report_promo_chat && !row.chatId?.is_direct_promo_chat"
                    class="badge badge-success mr-2">FIRST PROMO</span>
                <span *ngIf="row.chatId?.is_promotional_chat && row.chatId?.is_report_promo_chat"
                    class="badge badge-success">REPORT
                    PROMO</span>
                <span
                    *ngIf="row.chatId?.is_promotional_chat && row.chatId?.is_direct_promo_chat && !row.chatId?.is_paid_promotional_chat"
                    class="badge badge-success">FREE
                    PROMO</span>
                <span
                    *ngIf="row.chatId?.is_promotional_chat && row.chatId?.is_direct_promo_chat && row.chatId?.is_paid_promotional_chat"
                    class="badge badge-success">PAID
                    PROMO</span>
            </ng-container>
            <ng-container *ngIf="row.is_call_order">
                <span class="badge badge-info mr-1">Call</span>
                <span
                    *ngIf="row.callId?.is_promotional_call && !row.callId?.is_report_promo_call && !row.callId?.is_direct_promo_call"
                    class="badge badge-success mr-2">FIRST PROMO</span>
                <span *ngIf="row.callId?.is_promotional_call && row.callId?.is_report_promo_call"
                    class="badge badge-success">REPORT
                    PROMO</span>
                <span
                    *ngIf="row.callId?.is_promotional_call && row.callId?.is_direct_promo_call && !row.callId?.is_paid_promotional_call"
                    class="badge badge-success">FREE
                    PROMO</span>
                <span
                    *ngIf="row.callId?.is_promotional_call && row.callId?.is_direct_promo_call && row.callId?.is_paid_promotional_call"
                    class="badge badge-success">PAID
                    PROMO</span>
            </ng-container>
            <ng-container *ngIf="row.is_report_order">
                <span class="badge badge-success">Report</span>
            </ng-container>
            <ng-container *ngIf="row.is_query_order">
                <span class="badge badge-warning">Query</span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.customerCurrencySymbol}}
            <span *ngIf="row.is_chat_order">{{row.chatId?.customer_amount | number: '.2-2'}}</span>
            <span *ngIf="row.is_call_order">{{row.callId?.customer_amount | number: '.2-2'}}</span>
            <span *ngIf="row.is_report_order">{{row.reportId?.customer_amount | number: '.2-2'}}</span>
            <span *ngIf="row.is_query_order">{{row.queryId?.customer_amount | number: '.2-2'}}</span>
            ({{row.customerCurrencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' ||
                row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name :
                row.astroId?.displayname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="text-capitalize" *ngIf="row.is_chat_order">{{row.chatId?.order_current_status}}</span>
            <span class="text-capitalize" *ngIf="row.is_call_order">
                {{row.callId?.order_current_status}}
            </span>
            <ng-container *ngIf="row.is_report_order"><span class="text-capitalize" *ngIf="row.reportId?.isCancelled">
                    Cancelled
                </span>
                <span class="text-capitalize" *ngIf="!row.reportId?.isCancelled">
                    {{row.reportId?.order_current_status}}
                </span>
            </ng-container>
            <ng-container *ngIf="row.is_query_order">
                <span class="text-capitalize" *ngIf="row.queryId?.isCancelled">
                    Cancelled
                </span>
                <span class="text-capitalize" *ngIf="!row.queryId?.isCancelled">
                    {{row.queryId?.order_current_status}}
                </span>
                </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Is Issue?">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.is_customer_issue_created">
                <a [routerLink]="['/admin/issues/customer/', row.customer_issueId?._id]" title="View Details" class="mr-2">View Details</a>
            </ng-container>
            <ng-container *ngIf="!row.is_customer_issue_created">
                NO
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a *ngIf="row.is_query_order" [routerLink]="['/admin/orders/query/', row.queryId?._id]"
                title="View Details"><i class="fa fa-eye"></i></a>
            <a *ngIf="row.is_call_order" title="View Details" [routerLink]="['/admin/orders/call/', row.callId?._id]"><i
                    class="fa fa-eye"></i></a>
            <a *ngIf="row.is_chat_order" title="View Details" [routerLink]="['/admin/orders/chat/', row.chatId?._id]"><i
                    class="fa fa-eye"></i></a>

            <a *ngIf="row.is_report_order" [routerLink]="['/admin/orders/report/', row.reportId?._id]"
                title="View Details"><i class="fa fa-eye"></i></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>