import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-customer-query-order',
  templateUrl: './customer-query-order.component.html',
  styleUrls: ['./customer-query-order.component.scss']
})
export class CustomerQueryOrderComponent implements OnInit {

  constructor(
    private order: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: ActivatedRoute
  ) { } 
  title = 'angular-datatables';

  queryList = [];
  id: any = '';
  temp: any = [];
  getQueryData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    //this.id = this.router.snapshot.paramMap.get('id')
    this.router.parent?.params.subscribe(
      (params) => {
        this.id = params.data
      });
    this.getQueryOrderList()
    this.queryList = this.getQueryData;
  }

  /* ***********
  list All Orders
  *********** */

  getQueryOrderList() {
    this.spinner.show()
    this.order.getQueryOrderList({'userId':this.userdata.getId(),customerId:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        //console.log(this.queryList)
        this.temp = this.queryList;
        this.getQueryData = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
updateFilter(event: any) {
  //console.log(this.temp)
  const val = event.target.value.toLowerCase();

  const temp = this.temp.filter(function (d: any) {
    return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.oquestionId.toString().indexOf(val) !== -1 ||
        d.astroId?.name.toLowerCase().indexOf(val) !== -1 || !val
  });

  // update the rows
  this.queryList = temp;
  // Whenever the filter changes, always go back to the first page
  this.myFilterTable.offset = 0;
}
  /**
   * Not Answered Queries
   */
  notAnswered(){
    this.spinner.show()
    this.order.getQueryOrderList({'userId':this.userdata.getId(),'is_astro_answer':false,customerId:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        //console.log(this.queryList)
        this.temp = this.queryList;
        this.getQueryData = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Not Reviewed Queries
   */
  notReviewed(){
    this.spinner.show()
    this.order.getQueryOrderList({'userId':this.userdata.getId(),'is_order_reviewed':false,customerId:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        //console.log(this.queryList)
        this.temp = this.queryList;
        this.getQueryData = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * No Customer feedback Reports
   */
  noCustomerFeedback(){
    this.spinner.show()
    this.order.getQueryOrderList({'userId':this.userdata.getId(),'is_feedback_given':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        //console.log(this.queryList)
        this.temp = this.queryList;
        this.getQueryData = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * No Astrologer feedback reply Reports
   */
  noAstrologerFeedbackReply(){
    this.spinner.show()
    this.order.getQueryOrderList({'userId':this.userdata.getId(),'is_astro_replied_feedback':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        //console.log(this.queryList)
        this.temp = this.queryList;
        this.getQueryData = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
