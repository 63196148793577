import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'astro-river';
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        let url = e.url;
        let segments = url.split('/');
        if (!segments[2]?.includes('customer')) {
          this.removeCusOffset();
        }
        if (!segments[2]?.includes('astro')) {
          this.removeAstroOffset();
        }
        if (!segments[2]?.includes('manager')) {
          this.removeManagerOffset();
        }
        if (
          !segments[2]?.includes('users') &&
          !segments[2]?.includes('astrologers') &&
          !segments[2]?.includes('customers') &&
          !segments[2]?.includes('manager')
        ) {
          this.removeUserOffSet();
        }
        if (!segments[2]?.includes('international')) {
          this.removePricing();
        }
        if (!segments[2]?.includes('notification')) {
          this.removeNotification();
        }
        if (!segments[2]?.includes('page')) {
          this.removePage();
        }
        if (!segments[2]?.includes('waiting')) {
          this.removeWaiting();
        }
        if (!segments[2]?.includes('review')) {
          this.removeReview();
        }
        if (!segments[2]?.includes('issue')) {
          this.removeIssue();
        }
        if (!segments[2]?.includes('support')) {
          this.removeSupport();
        }
        if (!segments[2]?.includes('remedy')) {
          this.removeRemedy();
        }
        if (!segments[2]?.includes('support')) {
          this.removeSupport();
        }
      }
    });
  }
  removeIssue(){
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key?.includes('issue_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('issue_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeSupport(){
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key?.includes('support_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('support_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeRemedy(){
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key?.includes('remedy_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('remedy_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeReview() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key?.includes('review_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('review_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeWaiting() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key?.includes('waiting_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('waiting_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removePage() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key?.includes('page_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('page_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeNotification() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';

      if (key?.includes('noti_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('noti_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removePricing() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';

      if (key?.includes('in_search_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('in_pricing_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeUserOffSet() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';

      if (key?.includes('allus_offset_')) {
        localStorage.removeItem(key);
      }
      if (key?.includes('allus_search_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeCusOffset() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';

      if (key?.includes('cus_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeManagerOffset() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';

      if (key?.includes('mng_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
  removeAstroOffset() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';

      if (key?.includes('ast_offset_')) {
        localStorage.removeItem(key);
      }
    }
  }
}
