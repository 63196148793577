<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Astrologer Support</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/support/astrologer']">Astrologer Support</a></li>
            <li class="breadcrumb-item active" aria-current="page">Reply</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card-header bg-white">
                <h3 class="card-title">Reply</h3>
            </div>
            <div class="card">
                <div class="card-body">
                    <!-- <div class="user-wrap">
                        <button type="submit()" class="btn btn-primary mt-1 mb-2"><i class="fa fa-rss"></i> Add Skills</button>
                    </div> -->
                    <div>
                        <form [formGroup]="formGroup" (submit)="replyIssue()">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="issueId">Support ID</label>
                                        <input type="text" class="form-control " name="issueId" [value]="supportId" readonly>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input type="text" class="form-control " name="title" [value]="title" readonly>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="reply">Description</label>
                                        <textarea class="form-control " name="description" readonly>{{description}}</textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="reply">Issue Status</label><br/>
                                        <input type="checkbox" name="issue_solved" (change)="isIssueSolved($event)" />
                                        <label class="ml-2">Is Issue Solved?</label>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-group">
                                        <label for="reply">Reply</label>
                                        <textarea class="form-control " name="reply_by_system" formControlName="reply_by_system" placeholder="Reply"></textarea>
                                        <div *ngIf="f.reply_by_system.invalid && (f.reply_by_system.dirty || f.reply_by_system.touched)" class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="user-wrap d-flex justify-content-center">
                                        <button type="submit()" class="btn-form-custom col-4 btn btn-success mt-1 mb-2" [disabled]="!formGroup.valid"> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
