<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Type to filter the name column..."  class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />
    <!-- <div class="mt-3">
        <button class="btn btn-primary btn-sm mr-2" (click)="noCustomerReview()">No Customer Review</button>
        <button class="btn btn-info btn-sm mr-2" (click)="noAstrologerReviewReply()">No Review Reply</button>
        <button class="btn btn-warning btn-sm mr-2" (click)="promotionalCall()">Promotional Call</button>
    </div> -->
</div>
<br/>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [sortable]="false" [flexGrow]="3" name="Notice" prop="value">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.value">{{row.value}}</span>
        </ng-template>
    </ngx-datatable-column>
    
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Date" prop="added_date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.added_date |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <button (click)="deleteNotice(row)" class="btn-table-custom btn btn-outline-primary btn-sm">Delete</button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>