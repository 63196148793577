<div style="display: flex; justify-content: space-between" class="pl-2 pr-2">
  <input
    type="text"
    value="{{searchKey}}"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
</div>
<br />
<ngx-datatable
(page)="onPageChange($event)"
  class="material"
  [rows]="managerList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  [sorts]="[{ dir: 'desc' }]"
  style="position: relative !important"
>
  <ngx-datatable-column name="Name" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.name }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Phone" prop="phone">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.phone }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="email" prop="email">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.email }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Actions">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a [routerLink]="['/admin/manager/viewManager/', row._id]"
        ><i class="fa fa-eye"></i
      ></a>
      <a [routerLink]="['/admin/users/changePassword/', row.userId]">
        <button
          class="btn-table-custom btn btn-outline-primary btn-sm mt-1 mb-2 ml-3"
        >
          Change Password
        </button>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Block Customer">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- Material switch -->
      <ng-template #blocked>
        <mat-slide-toggle
          color="warn"
          (change)="toggle($event, row._id, row.is_blocked)"
          [checked]="true"
        ></mat-slide-toggle>
      </ng-template>
      <ng-template #notBlocked>
        <mat-slide-toggle
          color="warn"
          (change)="toggle($event, row._id, row.is_blocked)"
          [checked]="false"
        ></mat-slide-toggle>
      </ng-template>
      <ng-container
        *ngIf="row?.is_blocked === true; then blocked; else notBlocked"
      >
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Edit">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a
        [routerLink]="['/admin/manager/edit/', row._id]"
        class="btn-table-custom btn btn-outline-primary btn-sm mr-1"
        >Edit</a
      >
      <button
        (click)="deleteManager(row._id)"
        class="btn-table-custom btn btn-outline-primary btn-sm"
      >
        Delete
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
