import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CouponMallService } from 'src/app/admin/services/coupon-mall/coupon-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-mall-coupon',
  templateUrl: './list-mall-coupon.component.html',
  styleUrls: ['./list-mall-coupon.component.scss']
})
export class ListMallCouponComponent implements OnInit {

  constructor(
    private couponMallService: CouponMallService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  userId: any;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getCouponsList()
    this.userId = this.userdata.getId();
  }

  /*
  list coupons
   */

  getCouponsList() {
    this.spinner.show()
    this.couponMallService.listMallCoupon({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        // console.log(err);
      }
    );
  }
  /*
  list coupons with active status
  */

  getActiveCouponsList() {
    this.spinner.show()
    this.couponMallService.listMallCoupon({status: true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        // console.log(err);
      }
    );
  }

  /*
  list coupons without active status
   */

  getInactiveCouponsList() {
    this.spinner.show()
    this.couponMallService.listMallCoupon({status: false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
        // console.log(this.dataList)
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        // console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.couponName.toLowerCase().indexOf(val) !== -1 ||
        d.couponId.toString().indexOf(val) !== -1 ||
        d.couponCode.toString().indexOf(val) !== -1 ||
        d.couponCode.toLowerCase().indexOf(val) !== -1 || !val
        // d.status.toString().indexOf(val) !== -1 ||
        // d.Date.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /** update coupon code (active/inactive)*/
  public statusUpdate($event: any, data: any) {
    if(data?.status == true){
      this.couponMallService.updateMallCoupon({status: false, _id:data?._id, updatedId: this.userId}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success(res.message,'Success')
          this.getCouponsList()
        },
        (err) => {
          this.spinner.hide()
          // console.log(err);
          this.toast.error(err.message,'Error')
        }
      );
    } else {
      this.couponMallService.updateMallCoupon({status: true, _id:data?._id, updatedId: this.userId}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success(res.message,'Success')
          this.getCouponsList()
        },
        (err) => {
          this.spinner.hide()
          // console.log(err);
          this.toast.error(err.message,'Error')
        }
      );
    }
  }
  

  /**
   * 
   * Delete Coupon
   */
   deleteCoupon($event: any, id: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.couponMallService.deleteCoupon({'_id': id}).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success('Coupon Deleted','Success')
            this.getCouponsList()
          },
          (err) => {
            this.spinner.hide()
            //console.log(err);
            this.toast.error('Something went wrong','Error')
          }
        );
      }
    })
  }
}

