<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Coupon</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Coupon</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>List</a>
                    <ng-template ngbNavContent>
                      <div class="pr-2 pl-2 d-flex justify-content-between align-items-baseline">
                        <input type="text" value="{{searchKey}}" placeholder="Type to filter the name column..."
                          class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
                          <span>
                            <button class="btn-user-custom btn btn-outline-success btn-sm mr-2" (click)="getCouponList()">All</button>
                            <button class="btn-user-custom btn btn-outline-primary btn-sm mr-2" (click)="getActiveCouponsList()">Active Coupon</button>
                            <button class="btn-user-custom btn btn-outline-warning btn-sm" (click)="getInactiveCouponsList()">Inactive Coupons</button>
                          </span>
                      </div>
                      <br />
                      <ngx-datatable (page)="onPageChange($event)" class="material" [rows]="couponList"
                        [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                        [scrollbarV]="false" [limit]="10" [sorts]="[{ dir: 'desc' }]"
                        style="position: relative !important">
                        <ngx-datatable-column [flexGrow]="1" name="ID" prop="couponId">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.couponId }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Code" prop="code">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="text-uppercase">{{ row.code }}</span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Desc." prop="couponDescription">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.couponDescription }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Coupon Type" prop="couponType">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="row.couponType == 1">Percentage</span>
                            <span *ngIf="row.couponType == 2">Fixed</span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Enable Coupon">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <!-- Material switch -->
                            <ng-template #enable>
                              <mat-slide-toggle color="success" (change)="toggle( row._id, row.is_valid)"
                                [checked]="true"></mat-slide-toggle>
                            </ng-template>
                            <ng-template #disable>
                              <mat-slide-toggle color="success" (change)="toggle( row._id, row.is_valid)"
                                [checked]="false"></mat-slide-toggle>
                            </ng-template>
                            <ng-container *ngIf="
                                row?.is_valid == true;
                                then enable;
                                else disable
                              ">
                            </ng-container>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Action">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/coupon/', row._id]" class="fa fa-eye text-primary mr-1"></a>
                            <a [routerLink]="['/admin/coupon/edit/', row._id]" class="
                                btn-table-custom btn btn-sm btn-outline-primary
                              ">Edit</a>
                              <!-- <a (click)="deleteCoupon($event,row._id)" class="ml-2 btn-table-custom btn btn-sm btn-outline-primary">Delete</a> -->
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Add</a>
                    <ng-template ngbNavContent>
                      <br />
                      <div class="wideget-user-followers mb-0">
                        <app-add-coupon></app-add-coupon>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->