<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Category</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/mall-category/list']">Mall Category List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="update()">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="categoryname">Name *</label>
                                        <input type="text" class="form-control" placeholder="Category Name"
                                            formControlName="categoryname">
                                        <small
                                            *ngIf="f.categoryname.invalid && (f.categoryname.dirty || f.categoryname.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="category_image">Image  (Image should be 960 X 540)</label>
                                        <input type="file" class="form-control" placeholder="Festival Image"
                                            (change)="onBannerUpload($event)">
                                        <small
                                            *ngIf="f.category_image.invalid && (f.category_image.dirty || f.category_image.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                        <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                                            {{imageError}}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4" *ngIf="!(!dataList.is_subcategory_associated && !dataList.is_product_associated)">
                                    <div class="form-group">
                                        <label for="categoryname">Associated With *</label>
                                        <select class="form-control" formControlName="associateType">
                                            <option value="subcat">Subcategory</option>
                                            <option value="product">Product</option>
                                        </select>
                                        <small
                                            *ngIf="f.categoryname.invalid && (f.categoryname.dirty || f.categoryname.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="category_gst">Category GST*</label>
                                        <input type="text" class="form-control" formControlName="category_gst" placeholder="Category GST">
                                        <small
                                            *ngIf="f.category_gst.invalid && (f.category_gst.dirty || f.category_gst.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="category_slug">Category Slug*</label>
                                        <input type="text" class="form-control" placeholder="Meta Tags"
                                            formControlName="category_slug" (change)="checkSlugUnique($event)">
                                        <small
                                            *ngIf="f.category_slug.invalid && (f.category_slug.dirty || f.category_slug.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Slug can only contain '-' and no space or any special symbol.
                                        </small>
                                        <small *ngIf="!isUnique" class="d-block alert alert-danger mt-1">
                                            Slug should be unique and this slug has been already used.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="category_keyword">Keywords</label>
                                        <input type="text" class="form-control" placeholder="Meta Keywords"
                                            formControlName="category_keyword">
                                        <small
                                            *ngIf="f.category_keyword.invalid && (f.category_keyword.dirty || f.category_keyword.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="categorydescription">Description*</label>
                                        <textarea class="form-control" formControlName="categorydescription"></textarea>
                                        <small
                                            *ngIf="f.categorydescription.invalid && (f.categorydescription.dirty || f.categorydescription.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input (change)="checkIfHighlight($event)" type="checkbox" class="mr-2" formControlName="is_front_category"
                                            [value]="true">
                                        <label for="is_front_category">Highlight Category on Customer Frontpage *</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <button type="submit" class="col-sm-4 btn btn-success mt-1"
                                    [disabled]="!formGroup.valid || !isUnique">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->