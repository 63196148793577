import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';
import { RolesService } from 'src/app/admin/services/roles/roles.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-manager',
  templateUrl: './add-manager.component.html',
  styleUrls: ['./add-manager.component.scss']
})
export class AddManagerComponent implements OnInit {

  addManagerForm: any = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[6-9]\\d{9}')]),
    gender: new FormControl('', [Validators.required]),
    birth_date: new FormControl('', [Validators.required]),
    birth_month: new FormControl('', [Validators.required]),
    birth_year: new FormControl('', [Validators.required]),
    complete_address: new FormControl('', [Validators.required]),
    profile_image: new FormControl('', [Validators.required]),
    proof_image: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    approvedBy: new FormControl('', [Validators.required]),
    approvedId: new FormControl('', [Validators.required]),
  })

  constructor(
    private spinner: NgxSpinnerService,
    private managerService: ManagerService,
    private toast: ToastrService,
    private route: Router,
    private roleService: RolesService,
    private userdata: UserdataService
  ) { }

  ngOnInit(): void {
    this.getRoleList()
    this.addManagerForm.patchValue({approvedId:this.userdata.getId()})
    this.addManagerForm.patchValue({approvedBy:this.userdata.getName()})
  }

  get f() {
    return this.addManagerForm.controls
  }

  public onAddManagerSubmit() {
    this.spinner.show()
    const data = new FormData()
    data.append("name", this.addManagerForm.get("name").value)
    data.append("email", this.addManagerForm.get("email").value)
    data.append("password", this.addManagerForm.get("password").value)
    data.append("phone", this.addManagerForm.get("phone").value)
    data.append("gender", this.addManagerForm.get("gender").value)
    data.append("birth_date", this.addManagerForm.get("birth_date").value)
    data.append("birth_month", this.addManagerForm.get("birth_month").value)
    data.append("birth_year", this.addManagerForm.get("birth_year").value)
    data.append("complete_address", this.addManagerForm.get("complete_address").value)
    data.append("profile_image", this.addManagerForm.get("profile_image").value)
    data.append("proof_image", this.addManagerForm.get("proof_image").value)
    data.append("roleId", this.addManagerForm.get("roleId").value)
    this.managerService.addNewManager(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success('Manager added!', 'Sucess')
        this.reloadCurrentRoute()
      },
      err => {
        this.spinner.hide()
        if(err.status == 500){
          this.toast.error('Phone Number or Email Already Exist', 'Error')
        }else{
          this.toast.error(err.error.message, 'Error')
        }
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  profileError: any = '';
  profileImage(fileInput: any) {
    this.profileError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.profileError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      this.addManagerForm.patchValue({
        profile_image: fileInput.target.files[0]
      })
    }
  }

  proofError: any = '';
  proofImage(fileInput: any) {
    this.proofError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.proofError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      this.addManagerForm.patchValue({
        proof_image: fileInput.target.files[0]
      })
    }
  }

  roleList:any = []
  getRoleList(){
    this.roleService.listRoles({}).subscribe(
      (res:any) =>{
        this.roleList = res.data
      }, err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
