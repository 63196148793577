<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- ROW-1 OPEN -->
  <form [formGroup]="formGroup" (submit)="addNewCoupon()">
    <div class="row">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Add Coupon</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="couponName">Name *</label>
                  <input type="text" class="form-control" placeholder="Coupon Name" formControlName="couponName">
                  <small *ngIf="f.couponName.invalid && (f.couponName.dirty || f.couponName.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="couponType">Coupon Type *</label>
                  <select placeholder="Select Coupon Type" class="form-control"
                    formControlName="couponType">
                    <option selected disabled value=''>Select Coupon Type</option>
                    <option value="1">
                      Fixed
                    </option>
                    <option value="2">
                      Percentage
                    </option>
                  </select>
                  <small *ngIf="f.couponType.invalid && (f.couponType.dirty || f.couponType.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="couponCode">Coupon Code *</label>
                  <input type="text" class="form-control text-uppercase" placeholder="Coupon Code" formControlName="couponCode"
                    (change)="CheckIsCouponUnique($event)">
                  <small *ngIf="f.couponCode.invalid && (f.couponCode.dirty || f.couponCode.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required.
                  </small>
                  <small *ngIf="!isUnique" class="d-block alert alert-danger mt-1">
                    Coupon code should be unique and this coupon code has been already used.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="couponDesc">Coupon Desc*</label>
                  <textarea placeholder="Coupon Desc" class="form-control"
                    formControlName="couponDesc"></textarea>
                  <small *ngIf="f.couponDesc.invalid && (f.couponDesc.dirty || f.couponDesc.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required.
                  </small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="couponImage">Image (Image should be 960 X 540)</label>
                  <input type="file" class="form-control upload-image-custom" placeholder="Coupon Image"
                    (change)="onCouponImageUpload($event)" accept="image/*">
                  <small *ngIf="f.couponImage.invalid && (f.couponImage.dirty || f.couponImage.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required.
                  </small>
                  <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                    {{imageError}}
                  </small>
                  <!-- <small *ngIf="f.couponType.invalid && (f.couponType.dirty || f.couponType.touched)"
                      class="d-block alert alert-danger mt-1">
                      Required
                    </small> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="minimumOrder">Minimum Order *</label>
                  <input type="text" class="form-control" placeholder="Minimum Order"
                    formControlName="minimumOrder">
                  <small *ngIf="f.minimumOrder.invalid && (f.minimumOrder.dirty || f.minimumOrder.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>


              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="maximumDiscount">Maximum Discount *</label>
                  <input type="text" class="form-control" placeholder="Maximum Discount"
                    formControlName="maximumDiscount">
                  <small *ngIf="f.maximumDiscount.invalid && (f.maximumDiscount.dirty || f.maximumDiscount.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="couponAmount">{{formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 1 || formGroup?.value?.couponType == '1' ? 'Coupon Amount':'Percentage'}} *</label>
                  <input type="text" class="form-control" [placeholder]="formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 1 || formGroup?.value?.couponType == '1' ? 'Coupon Amount':'Percentage'" formControlName="couponAmount">
                  <small *ngIf="f.couponAmount.invalid && (f.couponAmount.dirty || f.couponAmount.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="applicableOn">Applicable On *</label>
                  <select placeholder="Select applicable on" class="form-control" (change)="saveApplicableOnOption($event)"
                    formControlName="applicableOn">
                    <option selected disabled value=''>Applicable On</option>
                    <option value="1">
                      All
                    </option>
                    <option value="2">
                      Product
                    </option>
                    <option value="3">
                      Category
                    </option>
                  </select>
                  <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="applicableOn" (change)="checkAddonAssociated()">

                    <mat-radio-button class="example-radio-button" value="true">
                      All
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="false">
                      Product
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="false">
                      Category
                    </mat-radio-button>
                  </mat-radio-group> -->
                  <small *ngIf="f.applicableOn.invalid && (f.applicableOn.dirty || f.applicableOn.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="endDate">End Date *</label>
                  <input type="date" class="form-control" formControlName="endDate" [min]="today">
                  <small *ngIf="f.endDate.invalid && (f.endDate.dirty || f.endDate.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <label for="usageLimit">Coupon Usage Limit *</label>
                  <input type="text" class="form-control" formControlName="usageLimit">
                  <small *ngIf="f.usageLimit.invalid && (f.usageLimit.dirty || f.usageLimit.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4" *ngIf="isProductApplicable">
                <div class="form-group">
                  <label for="productId">Product Name*</label>
                  <ng-select placeholder="Select Product" appendTo="body"
                      [searchable]="true" [clearable]="true" formControlName="productId">
                      <ng-option selected disabled value=''>Select Product Name</ng-option>
                      <ng-option [value]="product._id" *ngFor="let product of productsList">
                          #{{product.productId}} - {{product.productname}}
                      </ng-option>
                  </ng-select>
                  <!-- <small *ngIf="f.productId.invalid && (f.productId.dirty || f.productId.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small> -->
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4" *ngIf="isCategoryApplicable">
                <div class="form-group">
                  <label for="categoryId">Category Name*</label>
                  <ng-select placeholder="Select Category" appendTo="body"
                      [searchable]="true" [clearable]="true" formControlName="categoryId">
                      <ng-option selected disabled value=''>Select Category Name</ng-option>
                      <ng-option [value]="category['_id']" *ngFor="let category of categoriesList">
                          #{{category['categoryId']}} - {{category['categoryname']}}
                      </ng-option>
                  </ng-select>
                  <!-- <small *ngIf="f.categoryId.invalid && (f.categoryId.dirty || f.categoryId.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="form-group">
                  <label for="isApplicableOnForeignOrders">Is Applicable on Foreign Orders? *</label>
                  <input type="checkbox" class="ml-2" formControlName="isApplicableOnForeignOrders" (change)="isApplicableOnForeignOrders()">
                  <small *ngIf="f.isApplicableOnForeignOrders.invalid && (f.isApplicableOnForeignOrders.dirty || f.isApplicableOnForeignOrders.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isForeignOrder">
                <div class="form-group">
                  <label for="couponUsdAmount">{{formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 1 || formGroup?.value?.couponType == '1' ? 'Coupon Amount (USD)':'Percentage'}} *</label>
                  <input type="text" class="form-control" [placeholder]="formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 1 || formGroup?.value?.couponType == '1' ? 'Coupon Amount (USD)':'Percentage'" formControlName="couponUsdAmount">
                  <small *ngIf="f.couponUsdAmount.invalid && (f.couponUsdAmount.dirty || f.couponUsdAmount.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isForeignOrder">
                <div class="form-group">
                  <label for="minimumUsdOrder">Minimum Order (USD) *</label>
                  <input type="text" class="form-control" placeholder="Minimum Order (USD)" formControlName="minimumUsdOrder">
                  <small *ngIf="f.minimumUsdOrder.invalid && (f.minimumUsdOrder.dirty || f.minimumUsdOrder.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isForeignOrder">
                <div class="form-group">
                  <label for="maximumUsdDiscount">Maximum Discount (USD) *</label>
                  <input type="text" class="form-control" placeholder="Maximum Order (USD)" formControlName="maximumUsdDiscount">
                  <small *ngIf="f.maximumUsdDiscount.invalid && (f.maximumUsdDiscount.dirty || f.maximumUsdDiscount.touched)"
                    class="d-block alert alert-danger mt-1">
                    Required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
             <button type="submit" class="col-sm-4 btn btn-success mt-1"
               [disabled]="!formGroup.valid || !isUnique">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- ROW-1 CLOSED -->
</div>
<!--CONTAINER CLOSED -->
