<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Customer Wallet Recharge</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/recharge/all']">Recharge</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateRecharge()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="amount">Amount</label>
                                        <input type="text" class="form-control" form placeholder="amount"
                                            formControlName="amount">
                                        <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="percentge">Extra Percentage</label>
                                        <input type="text" class="form-control" placeholder="Extra Percentage"
                                            formControlName="percentge">
                                        <div *ngIf="f.percentge.invalid && (f.percentge.dirty || f.percentge.touched)"
                                            class="alert alert-danger mt-1">
                                            Required. Only Number Allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="walletamount_description">Description</label>
                                        <input type="Description" class="form-control" id="walletamount_description"
                                            placeholder="Description" formControlName="walletamount_description">
                                        <div *ngIf="f.walletamount_description.invalid && (f.walletamount_description.dirty || f.walletamount_description.touched)"
                                            class="alert alert-danger mt-1">
                                            Required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-success mt-1" [disabled]="!formGroup.valid">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
