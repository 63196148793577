import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';
import { RolesService } from 'src/app/admin/services/roles/roles.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-manager',
  templateUrl: './edit-manager.component.html',
  styleUrls: ['./edit-manager.component.scss']
})
export class EditManagerComponent implements OnInit {

  id:any = ''
  editManagerForm: any = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[6-9]\\d{9}')]),
    gender: new FormControl('', [Validators.required]),
    birth_date: new FormControl('', [Validators.required]),
    birth_month: new FormControl('', [Validators.required]),
    birth_year: new FormControl('', [Validators.required]),
    complete_address: new FormControl('', [Validators.required]),
    profile_image: new FormControl(''),
    proof_image: new FormControl(''),
    roleId: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  })

  constructor(
    private spinner: NgxSpinnerService,
    private managerService: ManagerService,
    private toast: ToastrService,
    private route: Router,
    private roleService: RolesService,
    private userdata: UserdataService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getRoleList()
    this.getManagerById()
    this.editManagerForm.patchValue({_id:this.id})
    // this.editManagerForm.patchValue({approvedId:this.userdata.getId()})
    // this.editManagerForm.patchValue({approvedBy:this.userdata.getName()})
  }

  get f() {
    return this.editManagerForm.controls
  }

  public onEditManagerSubmit() {
    this.spinner.show()
    const data = new FormData()
    data.append("name", this.editManagerForm.get("name").value)
    data.append("email", this.editManagerForm.get("email").value)
    data.append("phone", this.editManagerForm.get("phone").value)
    data.append("gender", this.editManagerForm.get("gender").value)
    data.append("birth_date", this.editManagerForm.get("birth_date").value)
    data.append("birth_month", this.editManagerForm.get("birth_month").value)
    data.append("birth_year", this.editManagerForm.get("birth_year").value)
    data.append("complete_address", this.editManagerForm.get("complete_address").value)
    data.append("profile_image", this.editManagerForm.get("profile_image").value)
    data.append("proof_image", this.editManagerForm.get("proof_image").value)
    data.append("roleId", this.editManagerForm.get("roleId").value)
    this.managerService.updateManager(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Sucess')
        this.route.navigateByUrl('/admin/managers/list')
        //console.log(res)
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error')
        //console.log(err)
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  profileError: any = '';
  profileImage(fileInput: any) {
    this.profileError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.profileError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      this.editManagerForm.patchValue({
        profile_image: fileInput.target.files[0]
      })
    }
  }

  proofError: any = '';
  proofImage(fileInput: any) {
    this.proofError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.proofError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      this.editManagerForm.patchValue({
        proof_image: fileInput.target.files[0]
      })
    }
  }

  roleList:any = []
  getRoleList(){
    this.roleService.listRoles({}).subscribe(
      (res:any) =>{
        this.roleList = res.data
      }, err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getManagerById(){
    this.managerService.getManagerDataById({_id:this.id}).subscribe(
      (res:any) =>{
        this.editManagerForm.patchValue({name:res.data.name})
        this.editManagerForm.patchValue({email:res.data.email})
        this.editManagerForm.patchValue({phone:res.data.phone})
        this.editManagerForm.patchValue({gender:res.data.gender})
        this.editManagerForm.patchValue({birth_date:res.data.birth_date})
        this.editManagerForm.patchValue({birth_month:res.data.birth_month})
        this.editManagerForm.patchValue({birth_year:res.data.birth_year})
        this.editManagerForm.patchValue({complete_address:res.data.complete_address})
        this.editManagerForm.patchValue({profile_image:res.data.profile_image})
        this.editManagerForm.patchValue({proof_image:res.data.proof_image})
        this.editManagerForm.patchValue({roleId:res.data.userId.roleId})
        //console.log(res)
      },err=>{
        //console.log(err)
      }
    )
  }
}
