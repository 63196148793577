import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { WalletRechargeService } from 'src/app/admin/services/wallet-recharge/wallet-recharge.service';

@Component({
  selector: 'app-add-recharge',
  templateUrl: './add-recharge.component.html',
  styleUrls: ['./add-recharge.component.scss']
})
export class AddRechargeComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private rechargeService: WalletRechargeService,
    private route: Router
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    amount: new FormControl('', [Validators.required,Validators.pattern("^[1-9]{1}[0-9]{0,}$")]),
    userId: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
    percentge: new FormControl('', [Validators.required,Validators.pattern("^[0-9]{1,3}$")]),
    walletamount_description: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
  }

  get f() {
    return this.formGroup.controls
  } 

  addRechargeSubmit(){
    this.rechargeService.addWalletRecharge(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/recharge/all')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
