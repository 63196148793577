import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { LanguageDataService } from 'src/app/admin/services/language/language-data.service';
import { SkillsServiceService } from 'src/app/admin/services/skills/skills-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';

@Component({
  selector: 'app-add-edit-astrologer-profile',
  templateUrl: './add-edit-astrologer-profile.component.html',
  styleUrls: ['./add-edit-astrologer-profile.component.scss']
})
export class AddEditAstrologerProfileComponent implements OnInit {

  addAstrologerForm: any = new FormGroup({
    displayname: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z]{1,}')]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    // password: new FormControl('', [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[6-9]{1}[0-9]{9}')]),
    gender: new FormControl('', [Validators.required]),
    alternate_num: new FormControl('', [Validators.pattern('[6-9]{1}[0-9]{9}')]),
    consultant_type: new FormControl('Astrologer', [Validators.required]),
    lang: new FormControl('', [Validators.required]),
    working_with_other: new FormControl('', [Validators.required]),
    working_company: new FormControl(''),
    birth_date: new FormControl('', [Validators.required]),
    birth_month: new FormControl('', [Validators.required]),
    birth_year: new FormControl('', [Validators.required]),
    experience: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,2}')]),
    short_bio: new FormControl('', [Validators.required]),
    profile_image: new FormControl(''),
    skill: new FormControl(''),
    complete_address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    country_code: new FormControl(''),
    pincode: new FormControl(''),
    bank_account_num: new FormControl('', Validators.pattern('[0-9]{1,}')),
    account_type: new FormControl(''),
    ifsc: new FormControl(''),
    account_holder_name: new FormControl(''),
    pan_number: new FormControl(''),
    addhar_number: new FormControl('', [Validators.pattern('[0-9]{12}')]),
    long_bio: new FormControl(''),
    proof_image: new FormControl(''),
    approvedBy: new FormControl(''),
    approvedId: new FormControl(''),
    is_social_login: new FormControl(false)
  }, { validators: identityRevealedValidator })

  rows = [];
  skillsData: any = [];
  languageData: any = [];
  public skills: Array<any> = [];
  public languages: Array<any> = [];
  i: any;
  day: any = [];
  month: any = [];
  year: any = [];
  selectedLanguage: any = [];
  selectedLanguageJson: any = [];
  selectedSkills: any = [];
  selectedSkillsJson: any = [];
  getWorkingStatus: Boolean | any;


  constructor(
    private spinner: NgxSpinnerService,
    private skillsService: SkillsServiceService,
    private userdata: UserdataService,
    private language: LanguageDataService,
    private astroService: AstroServiceService,
    private toast: ToastrService,
    private internationalService: InternationalServiceService,
    private route: Router,
    private customize: CustomizeServiceService
  ) { }

  ngOnInit(): void {
    this.getSkillsList();
    this.getLaguangeList();
    this.addAstrologerForm.patchValue({ approvedBy: this.userdata.getName() });
    this.addAstrologerForm.patchValue({ approvedId: this.userdata.getId() })
    this.getCountryData()
    this.getCountries()
  }
  countryData:any = [];
  countryCode=''
  getCountryData(){
    this.internationalService.getCountryData().subscribe(
      (res:any)=>{
        console.log("Country Code is",res.data)
        this.countryData = res.data
      },err=>{
        //console.log(err)
      }
    )
  }
  proofError: string = '';
  public onProofImageUpload(evnt: any) {
    if (evnt.target.files.length > 0) {
      this.proofError = ''
      if (evnt.target.files.length > 0) {
        if (evnt.target.files && evnt.target.files[0]) {
          const allowed_types = ['image/png', 'image/jpeg'];
          if (!_.includes(allowed_types, evnt.target.files[0].type)) {
            this.proofError = 'Only Images are allowed ( JPG | PNG )';
            return;
          } else {
            this.addAstrologerForm.patchValue({
              proof_image: evnt.target.files[0]
            })
          }
        }
      }
    }
  }

  profileError: string = '';
  public onProfileImageUpload(evnt: any) {
    this.profileError = ''
    if (evnt.target.files.length > 0) {
      if (evnt.target.files && evnt.target.files[0]) {
        const allowed_types = ['image/png', 'image/jpeg'];
        if (!_.includes(allowed_types, evnt.target.files[0].type)) {
          this.profileError = 'Only Images are allowed ( JPG | PNG )';
          return;
        } else {
          this.addAstrologerForm.patchValue({
            profile_image: evnt.target.files[0]
          })
        }
      }
    }
  }

  getLaguangeList() {
    this.spinner.show()
    this.language.getLaguangeList(this.userdata.getData()).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.languageData = res.data;
        for (this.i = 0; this.i < this.languageData.length; this.i++) {
          this.languages.push({ 'display': this.languageData[this.i].name, 'value': this.languageData[this.i]._id });
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getSkillsList() {
    this.spinner.show()
    this.skillsService.getSkillsList(this.userdata.getData()).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.skillsData = res.data;
        for (this.i = 0; this.i < this.skillsData.length; this.i++) {
          this.skills.push({ 'display': this.skillsData[this.i].skillname, 'value': this.skillsData[this.i]._id });
        }
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  get f() {
    return this.addAstrologerForm.controls
  }

  /**
   * Check Working with others
   */
  isWorkingWithOther = false
  checkWorkingWith(event: any) {
    this.isWorkingWithOther = false
    if (event.target.value == 'yes') {
      this.isWorkingWithOther = true
    }
  }

  selectedCountry(event:any){
   this.countryCode = event.phone_code
   this.addAstrologerForm.patchValue({country_code:event})
   //console.log(this.addAstrologerForm.value.country_code)
  }
  public onAddAstrologerSubmit() {
    var choosenLangues = this.addAstrologerForm.get("lang").value
    choosenLangues.forEach((element: { value: any; }) => {
      this.selectedLanguage.push(element.value)
    });
    this.selectedLanguage.forEach((element: any) => {
      this.selectedLanguageJson.push({ "langId": element })
    });

    if (this.addAstrologerForm.get("skill").value != '' && this.addAstrologerForm.get("skill").value != null) {
      var choosenSkills = this.addAstrologerForm.get("skill").value
      choosenSkills.forEach((element: { value: any; }) => {
        this.selectedSkills.push(element.value)
      });
      this.selectedSkills.forEach((element: any) => {
        this.selectedSkillsJson.push({ "skillId": element })
      });
    }

    //-------/tags json-----//

    var convertBoolean = this.addAstrologerForm.get("working_with_other").value

    if (convertBoolean == "yes") {
      this.getWorkingStatus = true;
    } else {
      this.getWorkingStatus = false;
    }

    if(this.getWorkingStatus && (this.addAstrologerForm.get("working_company").value == '' || this.addAstrologerForm.get("working_with_other").value == null || this.addAstrologerForm.get("working_with_other").value == undefined)){
      this.toast.error('Please Enter Current Work Details','Error')
      return
    }
    this.spinner.show()
    const data = new FormData()
    data.append("displayname", this.addAstrologerForm.get("displayname").value)
    data.append("name", this.addAstrologerForm.get("name").value)
    data.append("email", this.addAstrologerForm.get("email").value)
    data.append("phone", this.addAstrologerForm.get("phone").value)
    data.append("country_code",this.addAstrologerForm.get("country_code").value)
    data.append("gender", this.addAstrologerForm.get("gender").value)
    data.append("alternate_num", this.addAstrologerForm.get("alternate_num").value)
    data.append("profile_image", this.addAstrologerForm.get("profile_image").value)
    data.append("birth_date", this.addAstrologerForm.get("birth_date").value)
    data.append("birth_month", this.addAstrologerForm.get("birth_month").value)
    data.append("birth_year", this.addAstrologerForm.get("birth_year").value)
    data.append("consultant_type", this.addAstrologerForm.get("consultant_type").value)
    data.append("experience", this.addAstrologerForm.get("experience").value)
    data.append("lang", JSON.stringify(this.selectedLanguageJson))
    data.append("skill", JSON.stringify(this.selectedSkillsJson))
    data.append("complete_address", this.addAstrologerForm.get("complete_address").value)
    data.append("city", this.addAstrologerForm.get("city").value)
    data.append("state", this.addAstrologerForm.get("state").value)
    data.append("country", this.addAstrologerForm.get("country").value)
    data.append("pincode", this.addAstrologerForm.get("pincode").value)
    data.append("bank_account_num", this.addAstrologerForm.get("bank_account_num").value)
    data.append("account_type", this.addAstrologerForm.get("account_type").value)
    data.append("ifsc", this.addAstrologerForm.get("ifsc").value)
    data.append("account_holder_name", this.addAstrologerForm.get("account_holder_name").value)
    data.append("pan_number", this.addAstrologerForm.get("pan_number").value)
    data.append("addhar_number", this.addAstrologerForm.get("addhar_number").value)
    data.append("working_with_other", this.getWorkingStatus)
    data.append("working_company", this.addAstrologerForm.get("working_company").value)
    data.append("short_bio", this.addAstrologerForm.get("short_bio").value)
    data.append("long_bio", this.addAstrologerForm.get("long_bio").value)
    data.append("proof_image", this.addAstrologerForm.get("proof_image").value)
    data.append("approvedBy", this.addAstrologerForm.get("approvedBy").value)
    data.append("approvedId", this.addAstrologerForm.get("approvedId").value)
    data.append("is_social_login", this.addAstrologerForm.get("is_social_login").value)

    this.astroService.addNewAstrologer(data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success('Astrologer added!', 'Sucess')
        this.route.navigateByUrl('/admin/astro/list')
      },
      (err:any) => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji: any) {
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }

  countryList = new Array();
  getCountries() {
    this.customize.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }

}

const identityRevealedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const phone = control.get('phone');
  const alternate_num = control.get('alternate_num');

  return phone && alternate_num && phone.value === alternate_num.value ? { identityRevealed: true } : null;
};
