import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-query-detail',
  templateUrl: './query-detail.component.html',
  styleUrls: ['./query-detail.component.scss']
})
export class QueryDetailComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata : UserdataService,
    private toast : ToastrService
  ) { }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.orderService.getQueryOrderDetail({_id: this.id,userId:this.userdata.getId()}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  /**
   * Mark Query as completed
   */
  markAsComplete(id:any){
    if(!this.data.is_order_completed && this.data.is_astro_answer){
      if(this.data.is_feedback_given && !this.data.is_astro_replied_feedback){
        this.toast.error("Astrologer hasn't replied to feedback yet","Can't Mark as Complete!")
        return;
      }
    }
    this.spinner.show()
    this.orderService.updateQueryStatus({'questionId':id,'is_order_completed':true,'userId':this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.getDataById()
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
