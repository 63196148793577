import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicPagesServiceService } from 'src/app/admin/services/dynamic-pages/dynamic-pages-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { HttpClient, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';

@Component({
  selector: 'app-edit-dynamicpage',
  templateUrl: './edit-dynamicpage.component.html',
  styleUrls: ['./edit-dynamicpage.component.scss']
})
export class EditDynamicpageComponent implements OnInit {

  data: any;
  constructor(private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private dynamicPageService: DynamicPagesServiceService ,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private _location:Location
    ) { }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    metaKeywords: new FormControl(''),
    metaDescription: new FormControl('', Validators.required),
    updatedById: new FormControl('', Validators.required),
    updatedBy: new FormControl('', Validators.required),
   });
   id: any
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.formGroup.patchValue({ updatedById: this.userdata.getId() })
    this.formGroup.patchValue({ updatedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
    this.getDataById()
  }
  get f() {
    return this.formGroup.controls
  }
  getDataById() {
    this.spinner.show()
    this.dynamicPageService.getDynamicPageById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.result
        this.formGroup.patchValue({ _id: this.id })
        this.formGroup.patchValue({ title: this.data.title })
        this.formGroup.patchValue({ metaKeywords: this.data.metaKeywords })
        this.formGroup.patchValue({ metaDescription: this.data.metaDescription })
        this.formGroup.patchValue({ description: this.data.description })
      },
      err => {
        this.spinner.hide()
        // this.route.navigateByUrl('/admin/mall-product/list')
      }
    )
  }
  goBack(){
    this._location.back()
  }
  updateDynamicPageSubmit(){
    // console.log(this.formGroup.value)
    // return
    this.dynamicPageService.updateDynamicPage(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.goBack()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  example_image_upload_handler(blobInfo: any, success: any, failure: any, progress: any) {

    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    var token = sessionStorage.getItem('token')??""
    var header_object = new HttpHeaders().set("Authorization", token);
    const data = new FormData()
    data.append("image",blobInfo.blob());
    let request = httpClient.post<any>("https://apiv2.astroriver.com/admin/addblogimages",data,{headers:header_object});
    request.subscribe(res=>{
      //console.log("res",res)
      if(res.success){
        success(`https://apiv2.astroriver.com/${res.result}`)
      }else{
        failure("not uploaded")
      }

    },err=> {
      failure(err);
    })
  }

}
