<div class="row pr-2 pl-2">
  <!-- CONTAINER -->
  <div class="container content-area relative">
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <form [formGroup]="notificationForm" (submit)="sendNotification()">
      <div class="row">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <br />
          <div class="card">
            <!-- <div class="card-header">
                            <h3 class="card-title">Add Banner</h3>
                        </div> -->
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="exampleInputname" class="mb-4">Title</label
                    ><br />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Notification Title"
                      formControlName="notification_title"
                    />
                    <div
                      *ngIf="
                        f.notification_title.invalid &&
                        (f.notification_title.dirty ||
                          f.notification_title.touched)
                      "
                      class="alert alert-danger mt-1"
                    >
                      Required
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label for="exampleInputname" class="mb-4"
                      >Notification Link</label
                    ><br />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Notification Link"
                      formControlName="notification_link"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label for="exampleInputname" class="mb-4"
                      >Notification Image</label
                    ><br />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Notification Image"
                      formControlName="notification_image"
                    />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="exampleInputname" class="mb-4"
                      >Description</label
                    ><br />
                    <textarea
                        formControlName="notification_description"
                      placeholder="Notification Description"
                      class="form-control"></textarea>
                      <small>Note: Open Emoji selector - Windows: Press Windows + . (dot)   Mac : Press Ctrl + CMD + Space</small>
                    <!-- <button
                    type="button"
                      (click)="isEmojiPickerVisible = !isEmojiPickerVisible"
                      class="mt-2 btn btn-primary"
                    >
                      Add Emoji
                    </button> -->
                    <!-- <br />
                    <div class="col-12 p-0 mt-2" *ngIf="isEmojiPickerVisible">
                      <div class="card">
                        <div class="card-body">
                          <emoji-mart
                            class="emoji-mart"
                            (emojiSelect)="addEmoji($event)"
                            title="Choose your emoji"
                          ></emoji-mart>
                        </div>
                      </div>
                    </div> -->
                    <div
                      *ngIf="
                        f.notification_description.invalid &&
                        (f.notification_description.dirty ||
                          f.notification_description.touched)
                      "
                      class="alert alert-danger mt-1"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Notification For</label><br />
                    <input
                      type="radio"
                      (change)="notificationFor($event)"
                      name="for"
                      value="allCustomer"
                      [checked]="true"
                    />
                    <span class="mx-1">All Customer</span>
                    <input
                      type="radio"
                      (change)="notificationFor($event)"
                      name="for"
                      value="allAstrologer"
                    />
                    <span class="mx-1">All Astrologer</span>
                    <input
                      type="radio"
                      (change)="notificationFor($event)"
                      name="for"
                      value="oneCustomer"
                    />
                    <span class="mx-1">Specific Customer</span>
                    <input
                      type="radio"
                      (change)="notificationFor($event)"
                      name="for"
                      value="oneAstrologer"
                    />
                    <span class="mx-1">Specific Astrologer</span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6" *ngIf="this.isOneCustomer">
                  <div class="form-group">
                    <label>Select Customer</label>
                    <ng-select
                      placeholder="Select Astrologer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="customerId"
                      (change)="getCustomerId($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Customer</ng-option
                      >
                      <ng-option
                        [value]="customer['_id']"
                        *ngFor="let customer of customerList"
                      >
                        #{{ customer["customerAutoId"] }} -
                        {{ customer["name"] }}
                      </ng-option>
                    </ng-select>
                    <!-- <select formControlName="customerId" class="form-control" (change)="getCustomerId($event)">
                                            <option value="" selected disabled>Select Customer</option>
                                            <option [value]="customer['_id']" *ngFor="let customer of customerList" >#{{customer['customerAutoId']}} - {{customer['name']}}</option>
                                        </select> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6" *ngIf="this.isOneAstrologer">
                  <div class="form-group">
                    <label>Select Astrologer</label>
                    <ng-select
                      placeholder="Select Astrologer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="astroId"
                      (change)="getAstrologerId($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Astrologer</ng-option
                      >
                      <ng-option
                        [value]="astro['_id']"
                        *ngFor="let astro of astrologerList"
                      >
                        #{{ astro["astroAutoId"] }} -
                        {{ astro["displayname"] }}({{ astro["name"] }})
                      </ng-option>
                    </ng-select>
                    <!-- <select formControlName="astroId" class="form-control" (change)="getAstrologerId($event)">
                                                <option value="" selected disabled >Select Astrologer</option>
                                            <option [value]="astro['_id']" *ngFor="let astro of astrologerList">#{{astro['astroAutoId']}} - {{astro['name']}}</option>
                                        </select> -->
                  </div>
                </div>
              </div>
              <div
                class="row"
                *ngIf="this.isOneCustomer || this.isOneAstrologer"
              >
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label for="">Notification Type</label><br />
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="general"
                      [checked]="true"
                    />
                    <span class="mx-1">General</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="ordercustomer"
                      *ngIf="isOneCustomer"
                    />
                    <span class="mx-1" *ngIf="isOneCustomer">Order</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="orderastrologer"
                      *ngIf="isOneAstrologer"
                    />
                    <span class="mx-1" *ngIf="isOneAstrologer">Order</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="walletcustomer"
                      *ngIf="isOneCustomer"
                    />
                    <span class="mx-1" *ngIf="isOneCustomer">Wallet</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="walletastrologer"
                      *ngIf="isOneAstrologer"
                    />
                    <span class="mx-1" *ngIf="isOneAstrologer">Wallet</span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label
                      *ngIf="
                        isCustomerOrderService ||
                        isAstrologerWalletService ||
                        isCustomerWalletService
                      "
                      >Select Option</label
                    >
                    <select
                      formControlName="orderserviceId"
                      class="form-control"
                      *ngIf="isOneCustomer && isCustomerOrderService"
                    >
                      <option value="" selected disabled>Select Order</option>
                      <ng-container *ngFor="let corder of customerOrderList">
                        <option
                          *ngIf="corder['is_query_order']"
                          [value]="corder['_id']"
                        >
                          Query - #{{ corder["queryId"]["oquestionId"] }}
                        </option>
                        <option
                          *ngIf="corder['is_call_order']"
                          [value]="corder['_id']"
                        >
                          Call - #{{ corder["callId"]["ocallId"] }}
                        </option>
                        <option
                          *ngIf="corder['is_chat_order']"
                          [value]="corder['_id']"
                        >
                          Chat - #{{ corder["chatId"]["ochatId"] }}
                        </option>
                        <option
                          *ngIf="corder['is_report_order']"
                          [value]="corder['_id']"
                        >
                          Report - #{{ corder["reportId"]["oreportId"] }}
                        </option>
                      </ng-container>
                    </select>
                    <select
                      formControlName="orderserviceId"
                      class="form-control"
                      *ngIf="isOneAstrologer && isAstrologerOrderService"
                    >
                      <option value="" selected disabled>Select Order</option>
                      <ng-container *ngFor="let aorder of astroOrderList">
                        <option
                          *ngIf="aorder['is_query_order']"
                          [value]="aorder['_id']"
                        >
                          Query - #{{ aorder["queryId"]["oquestionId"] }}
                        </option>
                        <option
                          *ngIf="aorder['is_call_order']"
                          [value]="aorder['_id']"
                        >
                          Call - #{{ aorder["callId"]["ocallId"] }}
                        </option>
                        <option
                          *ngIf="aorder['is_chat_order']"
                          [value]="aorder['_id']"
                        >
                          Chat - #{{ aorder["chatId"]["ochatId"] }}
                        </option>
                        <option
                          *ngIf="aorder['is_report_order']"
                          [value]="aorder['_id']"
                        >
                          Report - #{{ aorder["reportId"]["oreportId"] }}
                        </option>
                      </ng-container>
                    </select>
                    <select
                      formControlName="awalletId"
                      class="form-control"
                      *ngIf="isOneAstrologer && isAstrologerWalletService"
                    >
                      <option value="" selected disabled>
                        Select Astrologer Wallet
                      </option>
                      <option
                        [value]="awallet['_id']"
                        *ngFor="let awallet of astrologerWalletList"
                      >
                        #{{ awallet["awalletId"] }}
                      </option>
                    </select>
                    <select
                      formControlName="cwalletId"
                      class="form-control"
                      *ngIf="isOneCustomer && isCustomerWalletService"
                    >
                      <option value="" selected disabled>
                        Select Customer Wallet
                      </option>
                      <option
                        [value]="cwallet['_id']"
                        *ngFor="let cwallet of customerWalletList"
                      >
                        #{{ cwallet["cwalletId"] }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="this.isAllCustomer">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label for="">Notification Type</label><br />
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="generalCustomer"
                      [checked]="true"
                    />
                    <span class="mx-1">General</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="product"
                    />
                    <span class="mx-1">Product</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="astrologer"
                    />
                    <span class="mx-1">Astrologer</span>
                    <input
                      type="radio"
                      (change)="notificationType($event)"
                      name="type"
                      value="blog"
                    />
                    <span class="mx-1">Blog</span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label
                      *ngIf="
                        isProductNotification ||
                        isAstrologerNotification ||
                        isBlogNotification
                      "
                      >Select Option</label
                    >
                    <ng-select
                      *ngIf="isAstrologerNotification"
                      placeholder="Select Astrologer"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      (change)="getAstrologerNotificationId($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Astrologer</ng-option
                      >
                      <ng-option
                        [value]="astro['_id']"
                        *ngFor="let astro of astrologerList"
                      >
                        #{{ astro["astroAutoId"] }} -
                        {{ astro["displayname"] }}({{ astro["name"] }})
                      </ng-option>
                    </ng-select>

                    <ng-select
                      *ngIf="isProductNotification"
                      placeholder="Select Product"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      (change)="getProductNotificationId($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Product</ng-option
                      >
                      <ng-option
                        [value]="product['_id']"
                        *ngFor="let product of productList"
                      >
                        #{{ product["productId"] }} -
                        {{ product["productname"] }}
                      </ng-option>
                    </ng-select>

                    <ng-select
                      *ngIf="isBlogNotification"
                      placeholder="Select Blog"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="true"
                      (change)="getBlogNotificationId($event)"
                    >
                      <ng-option selected disabled value=""
                        >Select Blog</ng-option
                      >
                      <ng-option
                        [value]="blog['_id']"
                        *ngFor="let blog of blogList"
                      >
                        {{ blog["blogTitle"] }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.isAllCustomer">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="">Notification Radius</label><br />

                    <input
                      type="radio"
                      (change)="notificationRadius($event)"
                      name="area"
                      value="1" checked
                    />
                    <span class="mx-1">Domestic</span>

                    <input
                      type="radio"
                      (change)="notificationRadius($event)"
                      name="area"
                      value="2"
                    />
                    <span class="mx-1">International</span>

                    <input
                      type="radio"
                      (change)="notificationRadius($event)"
                      name="area"
                      value="3"
                    />
                    <span class="mx-1">Both</span>
                    <div
                      *ngIf="
                        f.isForInternational.invalid &&
                        (f.isForInternational.dirty ||
                          f.isForInternational.touched)
                      "
                      class="alert alert-danger"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
              <button
                type="submit"
                class="btn-form-custom col-4 btn btn-success mt-1"
                [disabled]="!notificationForm.valid"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <form [formGroup]="imageGroup" (submit)="addDirectImage()">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Add Images for Notification</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="getNotificationImageLink"
                        >Upload Image to get image link for Notification</label
                      >
                      <input
                        type="file"
                        class="form-control"
                        placeholder="Banner Image"
                        (change)="onDirectUpload($event)"
                      />
                      <small class="text-danger">{{ imageDirectError }}</small>
                    </div>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 border"
                    *ngIf="directPath != ''"
                  >
                    <div class="form-group">
                      <label for="blogTitle"
                        >Paste this path in source of image insert</label
                      >
                      <small>{{ directPath }}</small>
                      <button
                        (click)="copyImageName()"
                        class="btn btn-info"
                        type="button"
                      >
                        Copy to clipboard
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn-form-custom col-sm-4 btn btn-success mt-1 mb-2"
                  [disabled]="!imageGroup.valid"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </form>
    <!-- ROW-1 CLOSED -->
  </div>
  <!--CONTAINER CLOSED -->
</div>
