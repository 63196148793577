import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-customer-users',
  templateUrl: './list-customer-users.component.html',
  styleUrls: ['./list-customer-users.component.scss']
})
export class ListCustomerUsersComponent implements OnInit {

  constructor(
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) {}
  title = 'angular-datatables';

  customersList: any = [];
  id: any ='';
  temp:any = [];
  getCustomerData: any = [];
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getCustomerlogersList()
  }

  getCustomerlogersList() {
    this.spinner.show()
    this.customerService.getCustomersList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.customersList = res.data;
        this.temp = this.customersList;
        this.getCustomerData = this.customersList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

   /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      
      return d.name.toLowerCase().indexOf(val) !== -1 || !val || 
              d.phone.toString().indexOf(val) !== -1 || !val ||
              d.consultant_type.toLowerCase().indexOf(val) !== -1 || !val ||
              d.experience.toString().indexOf(val) !== -1 || !val 
    });

    // update the rows
    this.customersList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**Block/unblock customer */

  public toggle($event: any, id: any, isBlocked: any) {
    if(isBlocked == true){
      this.customerService.blockCustomer({'_id': id, 'block':false}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Customer Unblocked','Success')
          this.getCustomerlogersList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      )
    } else {
      this.customerService.blockCustomer({'_id': id, 'block':true}).subscribe(
        (res: any) => {
          this.spinner.hide()
          isBlocked = true;
          this.toast.success('Customer blocked','Success')
          this.getCustomerlogersList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      )
    }
  }


}
