import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';
@Injectable({
  providedIn: 'root'
})
export class InternationalServiceService {

  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }

  public addInternationalPrice(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addpricing", data,{ headers: header_object});
  }

  public listInternationalPrice(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getAllPricings", data,{ headers: header_object});
  }

  public singleInternationalPrice(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getSinglePricing", data,{ headers: header_object});
  }

  public updateInternationalPrice(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updatePricing", data,{ headers: header_object});
  }
  
  public getCountryData(){
    return this.http.get("https://apistagging.astroriver.com/api/country");
  }

  public convertIndianToForeign(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/convertIndianToForeign ", data,{ headers: header_object});
  }
}

