<!-- CONTAINER -->
<div class="container content-area relative">
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <form [formGroup]="addRoleForm" (submit)="onAddRoleSubmit()" >
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Role</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="exampleInputname">Role Name</label><br/>
                                    <input type="text" formControlName="roles_name" class="form-control" placeholder="Role Name">
                                    <div *ngIf="f.roles_name.invalid && (f.roles_name.dirty || f.roles_name.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputname1">Permissions</label>
                                    <div>
                                        <tag-input secondaryPlaceholder="Enter Permission" formControlName="permission_name" placeholder="+Permissions">
                                            <tag-input-dropdown [autocompleteItems]="items" [showDropdownIfEmpty]="true" [dynamicUpdate]="false">
                                            </tag-input-dropdown>
                                         </tag-input>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                              <label for="">Select Permissions</label>
                            </div>
                          </div>
                          <div class="col-md-3 row mb-2" *ngFor="let per of items">
                            <input type="checkbox" [value]="per" (change)="storePermissions($event)"
                              class="form-control col-md-3">
                            <label class="col-md-9">{{per}}</label>
                          </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="btn btn-success mt-1 btn-form-custom col-4" [disabled]="!addRoleForm.valid">Save</button>
                        <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->
