<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Users</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>All Users List</a>
                    <ng-template ngbNavContent>
                      <br />
                      <div
                        style="display: flex; justify-content: space-between"
                        class="pl-2 pr-2"
                      >
                        <input
                          type="text"
                          value="{{searchKey}}"
                          placeholder="Type to filter the name column..."
                          class="form-control col-lg-4 mt-1"
                          (keyup)="updateFilter($event)"
                        />
                      </div>
                      <br />
                      <ngx-datatable
                      (page)="onPageChange($event)"
                        class="material custom-head"
                        [rows]="usersList"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [rowHeight]="'auto'"
                        [scrollbarV]="false"
                        [limit]="10"
                        [sorts]="[{ dir: 'desc' }]"
                        style="position: relative !important"
                      >
                        <ngx-datatable-column name="Name" prop="name">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.name }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Type">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <span
                              class="badge badge-primary"
                              *ngIf="row.is_astrologer"
                              >ASTROLOGER</span
                            >
                            <span
                              class="badge badge-info"
                              *ngIf="row.is_customer"
                              >CUSTOMER</span
                            >
                            <span
                              class="badge badge-warning"
                              *ngIf="row.is_admin"
                              >ADMIN</span
                            >
                            <span
                              class="badge badge-success"
                              *ngIf="row.is_manager"
                              >MANAGER</span
                            >
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Phone" prop="phone">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.phone }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="email" prop="email">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.email }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Actions">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <a
                              [routerLink]="[
                                '/admin/users/changePassword',
                                row._id
                              ]"
                            >
                              <button
                                class="
                                  btn btn-outline-primary
                                  mt-1
                                  mb-2
                                  ml-3
                                  btn-sm
                                  mr-2
                                "
                              >
                                Change Password
                              </button>
                            </a>
                            <!-- <a [routerLink]="['/admin/users/viewUser', row._id]" *ngIf="row.is_admin"><i
                                class="fa fa-eye"></i></a> -->
                            <a
                              (click)="getCustomerDataByEmail(row.email)"
                              *ngIf="row.is_customer"
                              role="button"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <a
                              (click)="getAstrologerDataByEmail(row.email)"
                              *ngIf="row.is_astrologer"
                              role="button"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <a
                              (click)="getManagerDataByEmail(row.email)"
                              *ngIf="row.is_manager"
                              role="button"
                              ><i class="fa fa-eye"></i
                            ></a>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </ng-template>
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/astro/approved']" class="nav-link"
                      >Astrologers</a
                    >
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/customer/list']" class="nav-link"
                      >Customers</a
                    >
                  </li>
                  <li class="nav-item">
                    <a [routerLink]="['/admin/managers/list']" class="nav-link"
                      >Managers</a
                    >
                  </li>
                  <!-- <li ngbNavItem>
                    <a ngbNavLink>Customers List</a>
                    <ng-template ngbNavContent>
                      <br>
                      <div class="wideget-user-followers mb-0">
                        <app-list-customer-users></app-list-customer-users>
                      </div>
                    </ng-template>
                  </li> -->
                  <!-- <li ngbNavItem>
                    <a ngbNavLink>Astrologers List</a>
                    <ng-template ngbNavContent>
                      <br>
                      <div class="wideget-user-followers mb-0">
                       <app-list-astro-users></app-list-astro-users>
                     </div>
                    </ng-template>
                  </li> -->
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
