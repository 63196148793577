<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Profile</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>Profile List</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div style="display: flex; justify-content: space-between;"
                                                class="pr-2 pl-2">
                                                <input type="text"  placeholder="Search ID, Name, Email, Phone..." class="form-control
                                                    col-lg-4 mt-1" (keyup)="updateFilter($event)" />
                                                <div>
                                                    <button class="btn btn-primary btn-sm mr-2"
                                                        (click)="allProfileUpdateList()">All Request</button>
                                                    <button class="btn btn-info btn-sm mr-2"
                                                        (click)="getProfileUpdateList()">Pending Request</button>
                                                    <button class="btn btn-warning btn-sm mr-2"
                                                        (click)="closedProfileUpdateList()">Closed Request</button>
                                                </div>


                                            </div>
                                            <br>
                                            <ngx-datatable class="material" [rows]="profileList" [columnMode]="'force'"
                                                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                                [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                                                <ngx-datatable-column [flexGrow]="1" name="Astrologer"
                                                    prop="astrologerId.name">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <span [title]="row.name">#{{row?.astrologerId.astroAutoId}}-{{(row.astrologerId?.displayname == '' || row.astrologerId?.displayname == undefined || row.astrologerId?.displayname == null) ? row.astrologerId?.name : row.astrologerId?.displayname}}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Email"
                                                    prop="astrologerId.email">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.astrologerId.email}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Phone"
                                                    prop="astrologerId.phone">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.astrologerId.phone}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Status"
                                                    prop="is_request_closed">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <span class="badge badge-danger" *ngIf="!row.is_request_closed">
                                                            PENDING
                                                        </span>
                                                        <span class="badge badge-success" *ngIf="row.is_request_closed">
                                                            CLOSED
                                                        </span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Action">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <a class="btn btn-primary btn-sm text-white"
                                                            [routerLink]="['/admin/update/profile',row._id]">Action</a>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Date"
                                                    prop="created_at">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.created_at | date:'medium'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
