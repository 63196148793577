<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text" placeholder="Search ID, Name, Slug..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"/>
    <div class="mt-3">
        <button class="btn-filter-custom btn btn-secondary btn-sm mr-2" (click)="getProductList()">All</button>
        <button class="btn-filter-custom btn btn-warning btn-sm mr-2"
            (click)="getFeturedProduct({ isFeaturedProduct: true,isCreatedByAstroRemedy:true })">Featured Product</button>
        <button class="btn-filter-custom btn btn-success btn-sm mr-2"
            (click)="getFeturedProduct({ isFeaturedProduct: false,isCreatedByAstroRemedy:true })">Non-Featured Product </button>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]"
    style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="productId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.productId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Product Code" prop="productCode">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.productCode">{{row.productCode}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Name" prop="productname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.productname">{{row.productname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Category" prop="categoryname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.productcategoryId?.categoryname">{{row.productcategoryId?.categoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Slug" prop="product_slug">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.product_slug">{{row.product_slug}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Enable">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row?.isAllFieldsAdded">
                <!-- Material switch -->
                <ng-template #enabled>
                    <mat-slide-toggle (change)="toggle($event, row)" [checked]="true"></mat-slide-toggle>
                </ng-template>
                <ng-template #disabled>
                    <mat-slide-toggle (change)="toggle($event, row)" [checked]="false"></mat-slide-toggle>
                </ng-template>
                <ng-container *ngIf="row?.status === true; then enabled; else disabled">
                </ng-container>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Edit">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/malls-product/',row._id]" class="fa fa-eye text-primary mr-1"></a>
            <a [routerLink]="['/admin/malls-product/edit/',row._id]"
                class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Set Featured">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row?.isAllFieldsAdded">
                <button *ngIf="!row.isFeaturedProduct" type="button" class="btn btn-sm btn-info"
                    (click)="featuredProduct({_id:row._id})">Set Featured</button>
                <button *ngIf="row.isFeaturedProduct" type="button" class="btn btn-sm btn-success">Featured</button>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>