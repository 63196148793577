<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Order</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/order/store']">Store Orders</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateOrderStatus()">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="orderStatus">Order ID</label>
                                        <input type="text" readonly class="form-control" [value]="data.orderId">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="orderStatus">Order Amount (INR)</label>
                                        <input type="text" readonly class="form-control" [value]="data.grandTotal">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="orderStatus">Current Order Status</label>
                                        <span class="badge-form badge badge-primary" *ngIf="data.orderStatus == 1">Placed</span>
                                        <span class="badge-form badge badge-warning" *ngIf="data.orderStatus == 2">Confirmed</span>
                                        <span class="badge-form badge badge-info" *ngIf="data.orderStatus == 3">Shipped</span>
                                        <span class="badge-form badge badge-warning" *ngIf="data.orderStatus == 4">Out For
                                            Delivery</span>
                                        <span class="badge-form badge badge-success" *ngIf="data.orderStatus == 5">Delivered</span>
                                        <span class="badge-form badge badge-primary mr-1"
                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 0">Cancelled & Not Refunded</span>
                                            <!-- <span
                                            class="badge badge-warning"
                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 0">Not
                                            Refunded</span> -->
                                            <span class="badge-form badge badge-info"
                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 1">Refund
                                            Requested</span><span class="badge-form badge badge-success"
                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 2">Refund
                                            Approved</span><span class="badge-form badge badge-primary"
                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 3">Refund
                                            Rejected</span>
                                        <span class="badge-form badge badge-info"
                                            *ngIf="data.orderStatus == 7 && !data.isOrderCompleted">Astro
                                            Compelete</span>
                                        <span class="badge-form badge badge-success"
                                            *ngIf="data.orderStatus == 8 && data.isOrderCompleted">Complete</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isDefaultCancelled">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="orderStatus">Order Status</label>
                                        <select class="form-control" formControlName="orderStatus"
                                            (change)="checkOrderStatus($event)">
                                            <option value="" selected disabled>Select Order Status</option>
                                            <option [value]="status.value" *ngFor="let status of orderStatusList">
                                                {{status.name}}</option>
                                        </select>
                                        <div *ngIf="f.orderStatus.invalid && (f.orderStatus.dirty || f.orderStatus.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isCompleted">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="checkbox" checked (click)="changeAutoPay()"> Is Auto Pay ?
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="payableAmountWithoutGst">Payable Amount<small>(Without Gst)</small></label>
                                        <input type="text" class="form-control" formControlName="payableAmountWithoutGst" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isCompleted">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="adminAmount">Admin Amount</label>
                                        <input type="text" class="form-control" formControlName="adminAmount" readonly *ngIf="isAutoPay">
                                        <input type="text" class="form-control" formControlName="adminAmount" *ngIf="!isAutoPay">
                                        <div *ngIf="f.adminAmount.invalid && (f.adminAmount.dirty || f.adminAmount.touched)"
                                            class="alert alert-danger mt-1">
                                            Only Numbers allowed
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="astroAmount">Astro Amount</label>
                                        <input type="text" class="form-control" formControlName="astroAmount" readonly *ngIf="isAutoPay">
                                        <input type="text" class="form-control" formControlName="astroAmount" *ngIf="!isAutoPay">
                                        <div *ngIf="f.astroAmount.invalid && (f.astroAmount.dirty || f.astroAmount.touched)"
                                            class="alert alert-danger mt-1">
                                            Only Numbers allowed
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="consultantAmount">Consultant Amount</label>
                                        <input type="text" class="form-control" formControlName="consultantAmount" readonly *ngIf="isAutoPay">
                                        <input type="text" class="form-control" formControlName="consultantAmount" *ngIf="!isAutoPay">
                                        <div *ngIf="f.consultantAmount.invalid && (f.consultantAmount.dirty || f.consultantAmount.touched)"
                                            class="alert alert-danger mt-1">
                                            Only Numbers allowed
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row" *ngIf="isDefaultCancelled || isCancelled">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="refundStatus">Refund Status</label>
                                        <select class="form-control" formControlName="refundStatus">
                                            <option value="" selected disabled>Select Refund Status</option>
                                            <option [value]="status.value" *ngFor="let status of refundStatusList">
                                                {{status.name}}</option>
                                        </select>
                                        <div *ngIf="f.refundStatus.invalid && (f.refundStatus.dirty || f.refundStatus.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="refundedAmount">Refund Amount</label>
                                        <input type="text" class="form-control" formControlName="refundedAmount">
                                        <div *ngIf="f.refundedAmount.invalid && (f.refundedAmount.dirty || f.refundedAmount.touched)"
                                            class="alert alert-danger mt-1">
                                            Only Numbers allowed
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="astroComments">Reason</label>
                                        <input type="text" class="form-control" formControlName="astroComments">
                                        <div *ngIf="f.astroComments.invalid && (f.astroComments.dirty || f.astroComments.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isShipped">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="shipmentCompany">Shipment Company</label>
                                        <input type="text" class="form-control" formControlName="shipmentCompany">
                                        <div *ngIf="f.shipmentCompany.invalid && (f.shipmentCompany.dirty || f.shipmentCompany.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="trackingId">Tracking ID</label>
                                        <input type="text" class="form-control" formControlName="trackingId">
                                        <div *ngIf="f.trackingId.invalid && (f.trackingId.dirty || f.trackingId.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="trackingUrl">Tracking URL</label>
                                        <input type="text" class="form-control" formControlName="trackingUrl">
                                        <div *ngIf="f.trackingUrl.invalid && (f.trackingUrl.dirty || f.trackingUrl.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
