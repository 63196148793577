import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-associate-addon',
  templateUrl: './add-associate-addon.component.html',
  styleUrls: ['./add-associate-addon.component.scss']
})
export class AddAssociateAddonComponent implements OnInit {

  id:any;

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private astroService: AstroServiceService,
    private productMallService: ProductMallService,
    private route: Router,
    private fb:FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.formGroup.patchValue({'userId':this.userdata.getId()})
    this.formGroup.patchValue({'_id':this.id})
  }

  formGroup: any = this.fb.group({
    _id: new FormControl(''),
    addonsList: this.fb.array([]),
    addonList: new FormControl(''),
    userId: new FormControl('',Validators.required),
  });

  addonsList() : FormArray {
    return this.formGroup.get("addonsList") as FormArray
  }

  newAddonsList(): FormGroup {
    return this.fb.group({
      addon_name: '',
      addon_image: '',
      addon_price: '',
      addon_type: '',
    })
  }

  public addAddonsList() {
    this.addonsList().push(this.newAddonsList());
  }

  public removeAddonsList(i:number) {
    this.addonsList().removeAt(i);
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   *Addon image
   */
   imageError: string = ''
   imageSrc = ''
   public onAddonImageUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;

       reader.readAsDataURL(file);

       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Image dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              addon_image: event.target.files[0]
             })
           }
         }
       };
     }
   }

  /* ***********
  Add Addons in product
 *********** */

  public addProductAddon() {
    var data = JSON.stringify(this.formGroup.get("astrosList").value)
    this.formGroup.patchValue({'astrologerList': data})
    this.spinner.show()
    this.productMallService.addAssociativeAstrologersInProduct(this.formGroup.value).subscribe(
      (res:any) =>{
        this.spinner.hide()
        this.toast.success(res.message,'success')
        this.route.navigateByUrl('/admin/mall-product/list')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

}


