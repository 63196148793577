import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AstroServiceService } from '../../services/astroservice/astro-service.service';
import { CustomerService } from '../../services/customer/customer.service';
import { NotesServiceService } from '../../services/notes/notes-service.service';
import { UserdataService } from '../../services/shared/userdata/userdata.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  constructor(
    private astroService: AstroServiceService,
    private customerService: CustomerService,
    private noteService: NotesServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }

  noteList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  astroList = [];astro = 'all'
  customerList = []; customer = 'all'
  data:any = {}

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getAllNotes()
    this.getAstrologerList()
    this.getCustomerList()
  }

  /* ***********
  list all notes
  *********** */

  getAllNotes() {
    this.spinner.show()
    this.noteService.getAllNotes(this.data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.noteList = res.data;
        this.temp = this.noteList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.astroId?.displayname.toLowerCase().indexOf(val) !== -1 ||
        // d.astroId?.astroAutoId.toString().indexOf(val) !== -1 ||
        // d.customerId?.customerAutoId.toString().indexOf(val) !== -1 ||
        d.personId?.name.toLowerCase().indexOf(val) !== -1 ||
        d.noteId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.noteList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  getAstrologerList(){
    this.astroService.getAstrologerList({}).subscribe(
      (res:any) =>{
        this.astroList = res.data
      }
    )
  }

  getCustomerList(){
    this.customerService.getCustomersList({}).subscribe(
      (res:any) =>{
        this.customerList = res.data
      }
    )
  }

  search(){
    if(this.astro != 'all' && this.customer !='all'){
      this.data = {astroId:this.astro,customerId:this.customer}
    }
    if(this.astro != 'all' && this.customer == 'all'){
      this.data = {astroId:this.astro}
    }
    if(this.astro == 'all' && this.customer != 'all'){
      this.data = {customerId:this.customer}
    }
    if(this.astro == 'all' && this.customer =='all'){
      this.data = {}
    }
    this.getAllNotes()
  }
}
