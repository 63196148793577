import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-list-blog-category',
  templateUrl: './list-blog-category.component.html',
  styleUrls: ['./list-blog-category.component.scss']
})
export class ListBlogCategoryComponent implements OnInit {

  constructor(
    private blogService: BlogServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getBlogList()
  }

  /* ***********
  list Blog
  *********** */

  getBlogList() {
    this.spinner.show()
    this.blogService.getBlogCategory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.categoryname.toLowerCase().indexOf(val) !== -1 ||
        d.categoryId.toString().indexOf(val) !== -1 ||
        d.category_slug.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
