import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { CouponMallService } from 'src/app/admin/services/coupon-mall/coupon-mall.service';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-mall-coupon',
  templateUrl: './add-mall-coupon.component.html',
  styleUrls: ['./add-mall-coupon.component.scss']
})
export class AddMallCouponComponent implements OnInit {
  today: any;

  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private productMallService: ProductMallService,
    private categoryMallService: CategoryMallService,
    private route: Router,
    private couponMallService: CouponMallService,
    private spinner: NgxSpinnerService,
  ) { }

  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    couponName: new FormControl('', [Validators.required]),
    couponCode: new FormControl('', [Validators.required]),
    couponDesc: new FormControl('', [Validators.required]),
    usageLimit : new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    minimumOrder: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    maximumDiscount: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    couponAmount: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    applicableOn: new FormControl('', [Validators.required]),
    couponType: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    productId: new FormControl(''),
    categoryId: new FormControl(''),
    couponImage: new FormControl(''),
    isApplicableOnForeignOrders: new FormControl(false, [Validators.required]),
    couponUsdAmount: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    minimumUsdOrder: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    maximumUsdDiscount: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
  });

  ngOnInit(): void {
    this.today = moment().format('YYYY-MM-DD');
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ status: true })
    this.getCategoriesList()
    this.getProductsList()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Product Image
   */
  imageError: string = ''
  imageSrc = ''
  public onCouponImageUpload(event: any) {
    this.imageError = ''
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 540;
      const max_width = 960;
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.imageSrc = reader.result as string;
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height != max_height || img_width != max_width) {
            this.imageError =
              'Image dimentions allowed ' +
              max_width +
              ' X ' +
              max_height +
              'px';
            return;
          } else {
            //console.log("image", event.target.files[0])
            this.formGroup.patchValue({ couponImage: event.target.files[0] })
          }
        }
      };
    }
  }

  /**Check if coupon code is unique */

  isUnique: boolean = true

  CheckIsCouponUnique(event: any) {
    this.couponMallService.CheckIsCouponUnique({ couponCode: event.target.value }).subscribe(
      (res: any) => {
        if (res.success) {
          this.isUnique = true
        } else {
          this.isUnique = false
        }
      }
    )
  }

  /** Applicable on  */
  isCategoryApplicable: boolean = false
  isProductApplicable: boolean = false
  saveApplicableOnOption(event: any) {
    // console.log(event.target.value)
    if (event.target.value == 1) {
      this.isCategoryApplicable = false
      this.isProductApplicable = false
      this.formGroup.patchValue({ applicableOn: 1 })
      // console.log('all')
    }
    if (event.target.value == 2) {
      this.isCategoryApplicable = false
      this.isProductApplicable = true
      // console.log('product')
      this.formGroup.patchValue({ applicableOn: 2 })
    }
    if (event.target.value == 3) {
      this.isCategoryApplicable = true
      this.isProductApplicable = false
      // console.log('category')
      this.formGroup.patchValue({ applicableOn: 3 })
    }
  }

  /** fetch categories list */
  categoriesList: any = []
  getCategoriesList() {
    this.categoryMallService.listMallCategory({}).subscribe(
      (res: any) => {
        this.categoriesList = res.data
      }, err => {
        // console.log(err)
        this.toast.error(err.message, "Error")
      }
    )
  }

  /** fetch products list */
  productsList: any = []
  getProductsList() {
    this.productMallService.listMallProduct({status:true}).subscribe(
      (res: any) => {
        this.productsList = res.data
      }, err => {
        // console.log(err)
        this.toast.error(err.message, "Error")
      }
    )
  }
  /** Add new coupon */
  couponAmount: any
  minimumOrder: any
  addNewCoupon() {
    /** Check offer coupon % validation */
    if (this.formGroup.get("couponType")?.value == 1) {
      this.couponAmount = this.formGroup.get("couponAmount")?.value
      this.minimumOrder = this.formGroup.get("minimumOrder")?.value
      if (Number(this.minimumOrder) <= Number(this.couponAmount)) {
        // console.log('min order',this.minimumOrder)
        // console.log('coupon amount',this.couponAmount)
        Swal.fire(
          'Validation message!',
          'Minimum order(INR) should be more than coupon amount(INR)!',
          // 'question'
        )
        return
      }

      if (this.formGroup.get("isApplicableOnForeignOrders")?.value) {
        this.couponAmount = this.formGroup.get("couponUsdAmount")?.value
        this.minimumOrder = this.formGroup.get("minimumUsdOrder")?.value
        if (Number(this.minimumOrder) <= Number(this.couponAmount)) {
          // console.log('min order',this.minimumOrder)
          // console.log('coupon amount',this.couponAmount)
          Swal.fire(
            'Validation message!',
            'Minimum order(USD) should be more than coupon amount(USD)!',
            // 'question'
          )
          return
        }
      }
    }
    // console.log(this.formGroup.get("couponType")?.value,this.formGroup.get("couponAmount")?.value,this.formGroup.get("minimumOrder")?.value)
    // return
    /** Check productId validation */

    if (this.formGroup.get("applicableOn")?.value == 2) {
      if (this.formGroup.get("productId")?.value == null || this.formGroup.get("productId")?.value == "") {
        Swal.fire(
          'Validation message!',
          'Product name is required!',
          // 'question'
        )
        return
      }
    }

    if (this.formGroup.get("applicableOn")?.value == 3) {
      if (this.formGroup.get("categoryId")?.value == null || this.formGroup.get("categoryId")?.value == "") {
        Swal.fire(
          'Validation message!',
          'Category name is required!',
          // 'question'
        )
        return
      }
    }
    const data = new FormData()
    data.append("userId", this.formGroup.get("userId")?.value)
    data.append("couponName", this.formGroup.get("couponName")?.value)
    data.append("usageLimit", this.formGroup.get("usageLimit")?.value)
    data.append("couponCode", this.formGroup.get("couponCode")?.value.toUpperCase())
    data.append("couponDesc", this.formGroup.get("couponDesc")?.value)
    data.append("minimumOrder", this.formGroup.get("minimumOrder")?.value)
    data.append("maximumDiscount", this.formGroup.get("maximumDiscount")?.value)
    data.append("couponAmount", this.formGroup.get("couponAmount")?.value)
    data.append("applicableOn", this.formGroup.get("applicableOn")?.value)
    data.append("couponType", this.formGroup.get("couponType")?.value)
    data.append("endDate", this.formGroup.get("endDate")?.value)
    data.append("status", this.formGroup.get("status")?.value)
    data.append("productId", this.formGroup.get("productId")?.value)
    data.append("categoryId", this.formGroup.get("categoryId")?.value)
    data.append("couponImage", this.formGroup.get("couponImage")?.value)
    data.append("isApplicableOnForeignOrders", this.formGroup.get("isApplicableOnForeignOrders")?.value)
    data.append("couponUsdAmount", this.formGroup.get("couponUsdAmount")?.value)
    data.append("minimumUsdOrder", this.formGroup.get("minimumUsdOrder")?.value)
    data.append("maximumUsdDiscount", this.formGroup.get("maximumUsdDiscount")?.value)
    this.couponMallService.addMallCoupon(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.route.navigateByUrl('/admin/mall-coupon/list')
      }, err => {
        this.toast.error(err.message, 'Error')
        this.reloadCurrentRoute()
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  /**
   * International Payment
   */
  isForeignOrder: boolean = false
  isApplicableOnForeignOrders() {
    this.isForeignOrder = this.formGroup.get('isApplicableOnForeignOrders')?.value
    //console.log(this.formGroup.get('isApplicableOnForeignOrders')?.value)
  }

}
