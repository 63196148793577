import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';

@Component({
  selector: 'app-list-international',
  templateUrl: './list-international.component.html',
  styleUrls: ['./list-international.component.scss'],
})
export class ListInternationalComponent implements OnInit {
  currentPage = '';
  searchKey = '';
  constructor(
    private internationalService: InternationalServiceService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
    this.searchKey = localStorage.getItem(`in_search_${this.currentPage}`)||''
  }
  title = 'angular-datatables';
  originalList = new Array();
  dataList = new Array();
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getPriceList();
  }

  /* ***********
  list International Price
  *********** */

  getPriceList() {
    this.spinner.show();
    this.internationalService.listInternationalPrice({}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.dataList = res.data;
        this.originalList = res.data;
        let offset =
          localStorage.getItem(`in_pricing_${this.currentPage}`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  /**
   * Update filter
   */
  searchByKey(key: string) {
    if (key.length < 1) {
      this.dataList = this.originalList;
      localStorage.removeItem(`in_search_${this.currentPage}`);
    } else {
      this.dataList = this.originalList.filter(function (d: any) {
        return d.countryName.toLowerCase().indexOf(key) !== -1 || !key;
      });
      localStorage.setItem(`in_search_${this.currentPage}`, key);
    }
    this.myFilterTable.offset = 0;
  }
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  onPageChange(event: any) {
    localStorage.setItem(`in_pricing_${this.currentPage}`, event.offset);
  }
}
