<div class="pr-2 pl-2 d-flex justify-content-between">
  <input
  value="{{searchKey}}"
    type="text"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
</div>
<br />
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Notice</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form [formGroup]="formGroup" (submit)="editAstroNoticeBoard()">
      <div class="mb-3">
        <label for="dateOfBirth">Notice</label>
        <textarea class="form-control" formControlName="value"></textarea>
      </div>
      <div class="mb-3"><button type="submit" class="btn btn-outline-primary">Save</button></div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
<ngx-datatable
(page)="onPageChange($event)"
  class="material"
  [rows]="dataList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  style="position: relative !important"
>
  <ngx-datatable-column
    [sortable]="false"
    [flexGrow]="3"
    name="Notice"
    prop="value"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.value">{{ row.value }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="true"
    [flexGrow]="1"
    name="Date"
    prop="added_date"
  >
    <ng-template let-row="row" ngx-datatable-cell-template >
      {{ row.added_date | date: "medium" }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Action">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button
        (click)="deleteNotice(row)"
        class="btn-table-custom btn btn-outline-primary btn-sm"
      >
        Delete
      </button>

      <button
        (click)="editNotice(content,row)"
        class="btn-table-custom btn btn-outline-info btn-sm ml-2"
      >
        Edit
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
