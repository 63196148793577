import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-blog-category',
  templateUrl: './common-blog-category.component.html',
  styleUrls: ['./common-blog-category.component.scss']
})
export class CommonBlogCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
