import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';
import { LanguageDataService } from 'src/app/admin/services/language/language-data.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { SkillsServiceService } from 'src/app/admin/services/skills/skills-service.service';

@Component({
  selector: 'app-edit-astrologer',
  templateUrl: './edit-astrologer.component.html',
  styleUrls: ['./edit-astrologer.component.scss']
})
export class EditAstrologerComponent implements OnInit {

  id: any = ''
  data: any = ''
  skillsData: any = [];
  languageData: any = [];
  public skills:Array<any>= [];
  public languages:Array<any>= [];
  i: any;
  getWorkingStatus: Boolean | any;
  selectedLanguage:any = [];
  selectedLanguageJson: any =[];
  selectedSkills:any = [];
  selectedSkillsJson: any =[];
  defaultSkill:any = []
  defaultLanguage:any = []

  addAstrologerForm: any = new FormGroup({
    _id: new FormControl('',[Validators.required]),
    displayname: new FormControl('',[Validators.required,Validators.pattern('[A-Za-z]{1,}')]),
    name: new FormControl('',[Validators.required]),
    phone: new FormControl('',[Validators.required, Validators.pattern('[6-9]{1}[0-9]{9}')]),
    gender: new FormControl('',[Validators.required]),
    consultant_type: new FormControl('',[Validators.required]),
    lang: new FormControl(''),
    working_with_other: new FormControl('',[Validators.required]),
    birth_date: new FormControl('',[Validators.required]),
    birth_month: new FormControl('',[Validators.required]),
    birth_year: new FormControl('',[Validators.required]),
    experience: new FormControl('',[Validators.required, Validators.pattern('[0-9]{1,2}')]),
    short_bio: new FormControl('',[Validators.required]),
    alternate_num: new FormControl('',[Validators.pattern('[6-9]{1}[0-9]{9}')]),
    profile_image: new FormControl(''),
    skill: new FormControl(''),
    complete_address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    country_code: new FormControl(''),
    pincode: new FormControl(''),
    bank_account_num: new FormControl('',[Validators.pattern('[0-9]{1,}')]),
    promocall_totake: new FormControl('',[Validators.required,Validators.pattern('[0-9]{1,}')]),
    account_type: new FormControl(''),
    ifsc: new FormControl(''),
    account_holder_name: new FormControl(''),
    pan_number: new FormControl(''),
    addhar_number: new FormControl('',[Validators.pattern('[0-9]{12}')]),
    long_bio: new FormControl(''),
    proof_image: new FormControl(''),
  }, { validators: identityRevealedValidator })

  constructor(
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private skillsService: SkillsServiceService,
    private userdata: UserdataService,
    private language: LanguageDataService,
    private astroService: AstroServiceService,   
    private internationalService: InternationalServiceService,
    private toast: ToastrService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.addAstrologerForm.patchValue({_id:this.id})
    this.getSkillsList();
    this.getLaguangeList();
    this.getAstrologerDataById()
    this.getCountryData()
  }
  countryData:any = [];
  countryCode=''
  getCountryData(){
    this.internationalService.getCountryData().subscribe(
      (res:any)=>{
        this.countryData = res.data
        //console.log(this.countryData)
      },err=>{
        //console.log(err)
      }
    )
  }
  selectedCountry(event:any){
    this.countryCode = event.phone_code  
    //this.addAstrologerForm.patchValue({country:event.country})
    this.addAstrologerForm.patchValue({country_code:event.phone_code})
    //console.log(this.addAstrologerForm.value.country_code)
   }
  getAstrologerDataById(){
    this.astroService.getAstrologerDataById({_id:this.id}).subscribe(
      (res:any) =>{
        this.data = res.data
        //console.log(this.data)
        this.addAstrologerForm.patchValue({displayname:this.data.displayname})
        this.addAstrologerForm.patchValue({name:this.data.name})
        this.addAstrologerForm.patchValue({phone:this.data.phone})
        this.addAstrologerForm.patchValue({gender:this.data.gender})
        this.addAstrologerForm.patchValue({alternate_num:this.data.alternate_num})
        this.addAstrologerForm.patchValue({birth_date:this.data.birth_date})
        this.addAstrologerForm.patchValue({birth_month:this.data.birth_month})
        this.addAstrologerForm.patchValue({birth_year:this.data.birth_year})
        this.addAstrologerForm.patchValue({consultant_type:this.data.consultant_type})
        this.addAstrologerForm.patchValue({experience:this.data.experience})
        this.addAstrologerForm.patchValue({country_code:this.data.country_code})
        //this.addAstrologerForm.patchValue({lang:this.data.lang})
        //this.addAstrologerForm.patchValue({skill:this.data.skill})
        this.addAstrologerForm.patchValue({complete_address:this.data.complete_address})
        this.addAstrologerForm.patchValue({city:this.data.city})
        this.addAstrologerForm.patchValue({state:this.data.state})
        this.addAstrologerForm.patchValue({country:this.data.country})
        this.addAstrologerForm.patchValue({pincode:this.data.pincode})
        this.addAstrologerForm.patchValue({bank_account_num:this.data.bank_account_num})
        this.addAstrologerForm.patchValue({account_type:this.data.account_type})
        this.addAstrologerForm.patchValue({ifsc:this.data.ifsc})
        this.addAstrologerForm.patchValue({account_holder_name:this.data.account_holder_name})
        this.addAstrologerForm.patchValue({pan_number:this.data.pan_number})
        this.addAstrologerForm.patchValue({addhar_number:this.data.addhar_number})
        this.addAstrologerForm.patchValue({short_bio:this.data.short_bio})
        this.addAstrologerForm.patchValue({long_bio:this.data.long_bio})
        this.addAstrologerForm.patchValue({promocall_totake:this.data.promocall_totake})
        res.data.skill.forEach((element:any) => {
          this.defaultSkill.push({'value':element.skillId._id,'display':element.skillId.skillname})
          //console.log(this.defaultTag)
        });
        res.data.lang.forEach((element:any) => {
          this.defaultLanguage.push({'value':element.langId._id,'display':element.langId.name})
          //console.log(this.defaultTag)
        });
        if(this.data.working_with_other){
          this.addAstrologerForm.patchValue({working_with_other:'yes'})
        }else{
          this.addAstrologerForm.patchValue({working_with_other:'no'})
        }
      }
    )
  }
  
  proofError: string ='';
  public onProofImageUpload(evnt: any){
    if(evnt.target.files.length > 0)
    {
      this.proofError = ''
      if(evnt.target.files.length > 0)
      {
        if (evnt.target.files && evnt.target.files[0]) {
          const allowed_types = ['image/png', 'image/jpeg'];
          if (!_.includes(allowed_types, evnt.target.files[0].type)) {
            this.proofError = 'Only Images are allowed ( JPG | PNG )';
            return;
          }else{
            this.addAstrologerForm.patchValue({
              proof_image: evnt.target.files[0]
            })
          }
        }
      }
    }
  }

  profileError: string ='';
  public onProfileImageUpload(evnt: any){
    this.profileError = ''
    if(evnt.target.files.length > 0)
    {
      if (evnt.target.files && evnt.target.files[0]) {
        const allowed_types = ['image/png', 'image/jpeg'];
        if (!_.includes(allowed_types, evnt.target.files[0].type)) {
          this.profileError = 'Only Images are allowed ( JPG | PNG )';
          return;
        }else{
          this.addAstrologerForm.patchValue({
            profile_image: evnt.target.files[0]
          })
        }
      }
    }
  }

  getLaguangeList() {
    this.spinner.show()
    this.language.getLaguangeList(this.userdata.getData()).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.languageData = res.data;
        for( this.i = 0; this.i<this.languageData.length; this.i++) {
          this.languages.push({'display': this.languageData[this.i].name, 'value': this.languageData[this.i]._id});
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getSkillsList() {
    this.spinner.show()
    this.skillsService.getSkillsList(this.userdata.getData()).subscribe(
     (res: any) => {
       this.spinner.hide()
       this.skillsData = res.data;
       for( this.i = 0; this.i<this.skillsData.length; this.i++) {
         this.skills.push({'display': this.skillsData[this.i].skillname, 'value': this.skillsData[this.i]._id});
       }
     },
     (err) => {
       this.spinner.hide()
      //console.log(err);
     }
   );
 }

  get f(){
    return this.addAstrologerForm.controls
  }

  public onAddAstrologerSubmit(){
    this.spinner.show()
    var choosenLangues = this.addAstrologerForm.get("lang").value
    choosenLangues.forEach((element: { value: any; }) => {
      this.selectedLanguage.push(element.value)
    });
    this.selectedLanguage.forEach((element: any) => {
      this.selectedLanguageJson.push({"langId":element})
    });

    var choosenSkills = this.addAstrologerForm.get("skill").value
    choosenSkills.forEach((element: { value: any; }) => {
      this.selectedSkills.push(element.value)
    });
    this.selectedSkills.forEach((element: any) => {
      this.selectedSkillsJson.push({"skillId":element})
    });
    //-------/tags json-----//

    var convertBoolean = this.addAstrologerForm.get("working_with_other").value

     if(convertBoolean == "yes"){
       this.getWorkingStatus = true;
     } else {
      this.getWorkingStatus = false;
     }
    //console.log(JSON.stringify(this.selectedLanguageJson),JSON.stringify(this.selectedSkillsJson))
    //return;
    const data = new FormData()
    data.append("displayname",this.addAstrologerForm.get("displayname").value)
    data.append("name",this.addAstrologerForm.get("name").value)
    data.append("phone",this.addAstrologerForm.get("phone").value)
    data.append("gender",this.addAstrologerForm.get("gender").value)
    data.append("alternate_num",this.addAstrologerForm.get("alternate_num").value)
    data.append("profile_image",this.addAstrologerForm.get("profile_image").value)
    data.append("birth_date",this.addAstrologerForm.get("birth_date").value)
    data.append("birth_month",this.addAstrologerForm.get("birth_month").value)
    data.append("birth_year",this.addAstrologerForm.get("birth_year").value)
    data.append("consultant_type",this.addAstrologerForm.get("consultant_type").value)
    data.append("experience",this.addAstrologerForm.get("experience").value)
    data.append("lang", JSON.stringify(this.selectedLanguageJson))
    data.append("skill", JSON.stringify(this.selectedSkillsJson))
    data.append("complete_address",this.addAstrologerForm.get("complete_address").value)
    data.append("city",this.addAstrologerForm.get("city").value)
    data.append("state",this.addAstrologerForm.get("state").value)
    data.append("country",this.addAstrologerForm.get("country").value)
    data.append("country_code",this.addAstrologerForm.get("country_code").value)
    data.append("pincode",this.addAstrologerForm.get("pincode").value)
    data.append("bank_account_num",this.addAstrologerForm.get("bank_account_num").value)
    data.append("account_type",this.addAstrologerForm.get("account_type").value)
    data.append("ifsc",this.addAstrologerForm.get("ifsc").value)
    data.append("account_holder_name",this.addAstrologerForm.get("account_holder_name").value)
    data.append("pan_number",this.addAstrologerForm.get("pan_number").value)
    data.append("addhar_number",this.addAstrologerForm.get("addhar_number").value)
    data.append("working_with_other",this.getWorkingStatus)
    data.append("short_bio",this.addAstrologerForm.get("short_bio").value)
    data.append("long_bio",this.addAstrologerForm.get("long_bio").value)
    data.append("proof_image",this.addAstrologerForm.get("proof_image").value)
    data.append("promocall_totake",this.addAstrologerForm.get("promocall_totake").value)
    data.append("_id",this.addAstrologerForm.get("_id").value)

    this.astroService.updateAstrologer(data).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.toast.success('Astrologer Updated!', 'Sucess')
        this.route.navigateByUrl('/admin/astro/list')
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Something went wrong!', 'Error')
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }
}
const identityRevealedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const phone = control.get('phone');
  const alternate_num = control.get('alternate_num');

  return phone && alternate_num && phone.value === alternate_num.value ? { identityRevealed: true } : null;
};
