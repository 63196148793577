<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- PAGE-HEADER -->
    <!-- <div class="page-header">
        <h4 class="page-title">Banner</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
            <li class="breadcrumb-item"><a href="/admin/banner">Banner</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Banner</li>
        </ol>
    </div> -->
    <br>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <form [formGroup]="addBannerForm" (submit)="onAddBannerSubmit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Banner</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname" class="mb-4">Banner Type</label><br />
                                    <input type="radio" class="mr-2" (change)="bannerType($event)" name="bannerType"
                                        value="true" checked="true">
                                    <label class="mr-2">Astrologer Banner</label>
                                    <input type="radio" class="mr-2" (change)="bannerType($event)" name="bannerType"
                                        value="false">
                                    <label>Customer Banner</label>
                                    <div *ngIf="f.is_astro_banner.invalid && (f.is_astro_banner.dirty || f.is_astro_banner.touched)"
                                        class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname" class="mb-4">Banner Image Type</label><br />
                                    <input type="radio" class="mr-2" (change)="bannerImageType($event)"
                                        name="bannerImageType" value="true" checked="true">
                                    <label class="mr-2">Web Banner</label>
                                    <input type="radio" class="mr-2" (change)="bannerImageType($event)"
                                        name="bannerImageType" value="false">
                                    <label>App Banner</label>
                                    <div *ngIf="f.is_astro_banner.invalid && (f.is_astro_banner.dirty || f.is_astro_banner.touched)"
                                        class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname">Banner Image (Web Image should be 1366 X 728 , App
                                        Image should be 540 X 280)</label>
                                    <input type="file" class="form-control" placeholder="Banner Image"
                                        (change)="commonBannerImageUpload($event)">
                                    <!-- <div *ngIf="f.mobile_banner_image.invalid && (f.mobile_banner_image.dirty || f.mobile_banner_image.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>   -->
                                    <div class="alert alert-danger mt-1" *ngIf="imageError != ''">
                                        {{imageError}}
                                    </div>
                                    <div class="alert alert-danger mt-1" *ngIf="appImageError != ''">
                                        {{appImageError}}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname">Web Banner Image (Image should be 1920 X 500)</label>
                                    <input type="file" class="form-control" 
                                        placeholder="Banner Image" (change)="onBannerUpload($event)">
                                    <div *ngIf="f.banner_image.invalid && (f.banner_image.dirty || f.banner_image.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>  
                                    <div class="alert alert-danger mt-1" *ngIf="imageError != ''">
                                        {{imageError}}
                                    </div>  
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputname">App Banner Image (Image should be 540 X 280)</label>
                                    <input type="file" class="form-control" 
                                        placeholder="Banner Image" (change)="onAppBannerUpload($event)">
                                    <div *ngIf="f.mobile_banner_image.invalid && (f.mobile_banner_image.dirty || f.mobile_banner_image.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>  
                                    <div class="alert alert-danger mt-1" *ngIf="appImageError != ''">
                                        {{appImageError}}
                                    </div>  
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputname">Banner Link</label>
                                    <select class="form-control" (change)="bannerLink($event)">
                                        <option value="" selected disabled>Select One</option>
                                        <option *ngIf="!addBannerForm.get('is_astro_banner').value" value="astro">
                                            Astrologer</option>
                                        <option value="product">Product</option>
                                        <option
                                            *ngIf="(addBannerForm.get('is_mobile_banner').value || addBannerForm.get('is_desktop_banner').value )&& !addBannerForm.get('is_astro_banner').value"
                                            value="call">Call </option>
                                        <option
                                            *ngIf="(addBannerForm.get('is_mobile_banner').value || addBannerForm.get('is_desktop_banner').value )  && !addBannerForm.get('is_astro_banner').value"
                                            value="chat">Chat </option>
                                        <option
                                            *ngIf="(addBannerForm.get('is_mobile_banner').value || addBannerForm.get('is_desktop_banner').value ) && !addBannerForm.get('is_astro_banner').value"
                                            value="query">Query </option>
                                        <option
                                            *ngIf="(addBannerForm.get('is_mobile_banner').value || addBannerForm.get('is_desktop_banner').value  )  && !addBannerForm.get('is_astro_banner').value"
                                            value="report">Report </option>
                                        <option
                                            *ngIf="(addBannerForm.get('is_mobile_banner').value || addBannerForm.get('is_desktop_banner').value ) && !addBannerForm.get('is_astro_banner').value"
                                            value="recharge">Recharge </option>
                                        <option
                                            *ngIf="(addBannerForm.get('is_mobile_banner').value || addBannerForm.get('is_desktop_banner').value ) "
                                            value="wallet">Wallet </option>
                                        <!-- <option *ngIf="addBannerForm.get('is_mobile_banner').value && !addBannerForm.get('is_astro_banner').value" value="call">Call </option>
                                        <option *ngIf="addBannerForm.get('is_mobile_banner').value && !addBannerForm.get('is_astro_banner').value" value="chat">Chat </option>
                                        <option *ngIf="addBannerForm.get('is_mobile_banner').value && !addBannerForm.get('is_astro_banner').value" value="query">Query </option>
                                        <option *ngIf="addBannerForm.get('is_mobile_banner').value && !addBannerForm.get('is_astro_banner').value" value="report">Report </option>
                                        <option *ngIf="addBannerForm.get('is_mobile_banner').value && !addBannerForm.get('is_astro_banner').value" value="recharge">Recharge </option>
                                        <option *ngIf="addBannerForm.get('is_mobile_banner').value" value="wallet">Wallet </option> -->
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6" *ngIf="!addBannerForm.get('is_mobile_banner').value">
                                <div class="form-group">
                                    <label for="exampleInputname">Banner Link</label>
                                    <select class="form-control" (change)="bannerLink($event)">
                                        <option value="" selected disabled>Select One</option>
                                        <option value="astro">Astrologer</option>
                                        <option value="product">Product</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group" *ngIf="isLinkSelected">
                                    <label for="exampleInputname">Link</label>
                                    <select class="form-control" *ngIf="isAstroLink == 1" formControlName="astroId">
                                        <option value="" selected disabled>Select One</option>
                                        <option [value]="astro['_id']" *ngFor="let astro of astroList">{{astro['name']}}
                                        </option>
                                    </select>
                                    <select class="form-control" *ngIf="isProductLink == 1" formControlName="productId">
                                        <option value="" selected disabled>Select One</option>
                                        <option [value]="product['_id']" *ngFor="let product of dataList">
                                            #{{product['productId']}} - {{product['productname']}} </option>
                                    </select>
                                    <input type="text" formControlName="link_value" class="form-control"
                                        *ngIf="isAstroLink == 0 && isProductLink == 0" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1"
                            [disabled]="!addBannerForm.valid">Save</button>
                        <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->