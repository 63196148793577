import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CouponServiceService } from 'src/app/admin/services/coupon/coupon-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-coupon',
  templateUrl: './list-coupon.component.html',
  styleUrls: ['./list-coupon.component.scss'],
})
export class ListCouponComponent implements OnInit {
  currentPage = '';
  searchKey = '';
  constructor(
    private couponService: CouponServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: ActivatedRoute
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
    this.searchKey = localStorage.getItem(`${this.currentPage}_search`) || '';
  }
  title = 'angular-datatables';

  couponList = new Array();
  id: any = '';
  originalList = new Array();
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getCouponList();
  }

  /* ***********
  list Coupons
  *********** */

  getCouponList() {
    this.spinner.show();
    this.couponService.allCoupon({ userId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.couponList = res.data;
        this.originalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset = localStorage.getItem(`${this.currentPage}_offset`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide();
        //console.log(err);
      }
    );
  }

  /**
   * Update filter
   */
  searchByKey(key: string) {
    //console.log(key)
    if (key.length < 1) {
      this.couponList = this.originalList;
      localStorage.removeItem(`${this.currentPage}_search`);
    } else {
      this.couponList = this.originalList.filter(function (d: any) {
     
        return d.code.toLowerCase().includes(key) ||
          d.couponId.toString().includes(key) ||
          d.couponDescription.toLowerCase().includes(key)
     
      });
      localStorage.setItem(`${this.currentPage}_search`, key);
    }
    this.myFilterTable.offset = 0;
  }
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val);
  }
  onPageChange(event: any) {
    localStorage.setItem(`${this.currentPage}_offset`, event.offset);
  }
  /**Block/unblock Coupon */

  public toggle( id: any, isValid: any) {
 this.couponService
        .updateCoupon({
          _id: id,
          is_valid: !isValid,
          updatedId: this.userdata.getId(),
          updatedBy: this.userdata.getName(),
        })
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            this.toast.success('Coupon Updated Successfully', 'Success');
            //this.getCouponList();
            window.location.reload();
          },
          err => {
            this.spinner.hide();
            //console.log(err);
            this.toast.error('Something went wrong', 'Error');
          }
        );
  }

  /*
  list coupons with active status
  */

  getActiveCouponsList() {
    this.spinner.show()
    this.couponService.allCoupon({status: true}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.couponList = res.data;
        this.originalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset = localStorage.getItem(`${this.currentPage}_offset`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        // //console.log(err);
      }
    );
  }

  /*
  list coupons without active status
   */

  getInactiveCouponsList() {
    this.spinner.show()
    this.couponService.allCoupon({status: false}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.couponList = res.data;
        this.originalList = res.data;
        if (this.searchKey.length > 0) {
          this.searchByKey(this.searchKey);
        }
        let offset = localStorage.getItem(`${this.currentPage}_offset`) || 0;
        if (offset > 0) {
          this.myFilterTable.offset = offset;
        }
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.message, "Error")
        // //console.log(err);
      }
    );
  }

  /**
   * 
   * Delete Coupon
   */
   deleteCoupon($event: any, id: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.couponService.deleteCoupon({'_id': id}).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success('Coupon Deleted','Success')
            this.getCouponList();
          },
          (err) => {
            this.spinner.hide()
            //console.log(err);
            this.toast.error('Something went wrong','Error')
          }
        );
      }
    })
  }
}
