<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Search ID, Astro Name, Customer Name..."  class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
</div>
<div class="p-2 d-flex justify-content-between mt-1">
    <button class="btn btn-danger mr-2" (click)="getCallOrderList()">All Orders</button>
    <button class="btn btn-success mr-2" (click)="completedCallOrder()">Completed Orders</button>
    <button class="btn btn-primary mr-2" (click)="onGoingCallOrder()">On-Going Orders</button>
    <button class="btn btn-warning mr-2" (click)="noCustomerReview()">No Customer Review</button>
    <button class="btn btn-info mr-2" (click)="noAstrologerReviewReply()">No Review Reply</button>
    <button class="btn btn-danger mr-2" (click)="promotionalCall()">Promotional Call</button>
</div>
<br>
<ngx-datatable class="material" [rows]="callList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Call ID" prop="ocallId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/orders/call/', row._id]"> #{{row.ocallId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.displayname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/astrologers/viewAstrologer',row.astroId?._id]"><span [title]="row.astroId?.name">#{{row.astroId?.astroAutoId}}-{{(row.astroId?.displayname == '' ||
                row.astroId?.displayname == undefined || row.astroId?.displayname == null) ? row.astroId?.name :
                row.astroId?.displayname}}</span></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-dark" [routerLink]="['/admin/customers/viewCustomer',row.customerId?._id,'detail']">#{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- {{row.cost | currency :'INR':'symbol-narrow'}} -->
            {{row.orderserviceId?.customerCurrencySymbol}}{{row.cost | number : '.2-2'}} 
            <!-- <span class="badge badge-pill badge-primary" *ngIf="row.is_foreign_order">{{row?.customerId?.regCountryName}}</span> -->
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="International?" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.is_foreign_order ? 'Yes' : 'No'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Duration" prop="cost">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{convertSecondstoTime(row.callDuration)}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Is Promo Call ?">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span
                *ngIf="row.is_promotional_call && !row.is_report_promo_call && !row.is_direct_promo_call"
                class="badge badge-success mr-2">FIRST PROMO</span>
            <span *ngIf="row.is_promotional_call && row.is_report_promo_call"
                class="badge badge-danger">REPORT
                PROMO</span>
            <span
                *ngIf="row.is_promotional_call && row.is_direct_promo_call && !row.is_paid_promotional_call"
                class="badge badge-warning">FREE
                PROMO</span>
            <span
                *ngIf="row.is_promotional_call && row.is_direct_promo_call && row.is_paid_promotional_call"
                class="badge badge-success">PAID
                PROMO</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="text-capitalize">{{row.order_current_status}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/orders/call/', row._id]"><i class="fa fa-eye"></i></a>
            <!-- <button class="ml-2 btn btn-primary btn-sm" (click)="markAsComplete(row._id)" *ngIf="!row.is_order_completed">Mark Complete</button> -->
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>