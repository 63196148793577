import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-mall-subcategory',
  templateUrl: './common-mall-subcategory.component.html',
  styleUrls: ['./common-mall-subcategory.component.scss']
})
export class CommonMallSubcategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
