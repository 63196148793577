import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LanguageDataService } from 'src/app/admin/services/language/language-data.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-language',
  templateUrl: './edit-language.component.html',
  styleUrls: ['./edit-language.component.scss']
})
export class EditLanguageComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private language: LanguageDataService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router
  ) { }

  data = '';
  id: any = '';

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.updateLanguageForm.patchValue({updatedId: this.userdata.getId()})
    this.updateLanguageForm.patchValue({updatedBy: this.userdata.getName()})
    this.updateLanguageForm.patchValue({_id: this.id})
    this.getDataById()
  }

  updateLanguageForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    updatedId: new FormControl(),
    updatedBy: new FormControl(),
    _id: new FormControl()
  });

  get f(){
    return this.updateLanguageForm.controls
  }
  
  getDataById(){
    this.spinner.show()
    this.language.getLanguageDataById({_id: this.id}).subscribe(
      (res:any) => {
        //console.log(res)
        this.data = res.data
        this.updateLanguageForm.patchValue({name: res.data.name});
        this.spinner.hide()
      },
      err => {
        //console.log(err) 
      }
    )
  }

   /* ***********
   Update language
  *********** */

public onUpdateLanguage() {
  this.spinner.show();
  this.language.updateLanguage(this.updateLanguageForm.value).subscribe(
    (res: any)=> {
      //console.log(res)
      this.spinner.hide()
      this.toast.success('Language Updated!', 'Success')
      this.route.navigateByUrl('admin/languages')
     
    },
    err => {
      this.spinner.hide()
      //console.log(err)
      this.toast.error('Something went wrong!','Error')
    })

}

}
