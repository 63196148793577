<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Remedy</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Remedy</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>Remedy List</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div style="display: flex; justify-content: space-between;"
                                                class="pr-2 pl-2">
                                                <input type="text"   placeholder="Search ID..."  class="form-control
                                                    col-lg-4 mt-1" (keyup)="updateFilter($event)" />
                                                <div>
                                                    <button class="btn btn-primary btn-sm mr-2"
                                                        (click)="astrologerSuggested()">Suggested By Astro</button>
                                                    <button class="btn btn-info btn-sm mr-2"
                                                        (click)="adminSuggested()">Suggested By Admin</button>
                                                    <button class="btn btn-warning btn-sm mr-2"
                                                        (click)="customerInterestedRemedy()">Customer
                                                        Interested</button>
                                                </div>
                                            </div>
                                            <br>
                                            <ngx-datatable class="material" [rows]="remedyList" [columnMode]="'force'"
                                                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                                [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]"
                                                style="position: relative !important;" [rowClass]="getRowClass">
                                                <ngx-datatable-column [flexGrow]="1" name="Remedy ID" prop="rsId">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <a *ngIf="row.orderserviceId?.is_call_order"
                                                            [routerLink]="['/admin/remedy/suggest',row.orderserviceId?._id]">#{{row.rsId}}</a>
                                                        <a *ngIf="row.orderserviceId?.is_chat_order"
                                                            [routerLink]="['/admin/remedy/suggest',row.orderserviceId?._id]">#{{row.rsId}}</a>
                                                        <a *ngIf="row.orderserviceId?.is_query_order"
                                                            [routerLink]="['/admin/remedy/suggest',row.orderserviceId?._id]">#{{row.rsId}}</a>
                                                        <a *ngIf="row.orderserviceId?.is_report_order"
                                                            [routerLink]="['/admin/remedy/suggest',row.orderserviceId?._id]">#{{row.rsId}}</a>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Remedy Name"
                                                    prop="remedy_name">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.remedy_name}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Suggested By">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <span [title]="row.astroId?.name"
                                                            *ngIf="row.is_astro_suggest_remedy"
                                                            class="badge badge-primary">#{{row?.astroId?.astroAutoId}}-{{(row.astroId?.displayname
                                                            == '' || row.astroId?.displayname == undefined ||
                                                            row.astroId?.displayname == null) ? row.astroId?.name :
                                                            row.astroId?.displayname}}</span>
                                                        <span *ngIf="row.is_admin_suggest_remedy"
                                                            class="badge badge-info">Admin</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Remedy Type">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <span *ngIf="!row.isPaid"
                                                            class="badge badge-primary">Free</span>
                                                        <span *ngIf="row.isPaid" class="badge badge-info">Paid</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Suggested Product"
                                                    prop="suggestedProductId.productname">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row?.suggestedProductId?.productname}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Suggested Astro"
                                                    prop="suggestedastroId.displayname">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <div *ngIf="row?.suggestedastroId!=null">
                                                            #{{row?.suggestedastroId?.astroAutoId}} -
                                                            {{row?.suggestedastroId?.displayname}}
                                                        </div>
                                                        <div *ngIf="row?.suggestedastroId==null">
                                                            -
                                                        </div>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="1" name="Customer"
                                                    prop="customerId.name">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        #{{row.customerId?.customerAutoId}}-{{row.customerId?.name}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.created_at | date:'medium'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->