import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemedyServiceService } from 'src/app/admin/services/remedy/remedy-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { convertCompilerOptionsFromJson } from 'typescript';

@Component({
  selector: 'app-all-remedy',
  templateUrl: './all-remedy.component.html',
  styleUrls: ['./all-remedy.component.scss']
})
export class AllRemedyComponent implements OnInit {

  constructor(
    private remedyService: RemedyServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  remedyList = [];
  id: any = '';
  temp: any = [];
  getRemedyData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getAllremedyList()
    this.remedyList = this.getRemedyData;
  }

  /* ***********
  list All Remedy
  *********** */

  getAllremedyList() {
    this.spinner.show()
    this.remedyService.getAllRemedy({ userId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.remedyList = res.data;
        this.temp = this.remedyList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value;
    const temp = this.temp.filter(function (d: any) {
      return  d.rsId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.remedyList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Astrologer Suggested Remedy
   */
  astrologerSuggested() {
    this.spinner.show()
    this.remedyService.getAllRemedy({ 'userId': this.userdata.getId(), 'is_astro_suggest_remedy': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.remedyList = res.data;
        this.temp = this.remedyList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Admin Suggested Remedy
   */
  adminSuggested() {
    this.spinner.show()
    this.remedyService.getAllRemedy({ 'userId': this.userdata.getId(), 'is_admin_suggest_remedy': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.remedyList = res.data;
        this.temp = this.remedyList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Customer Interested Remedy
   */
  customerInterestedRemedy() {
    this.spinner.show()
    this.remedyService.getAllRemedy({ 'userId': this.userdata.getId(), 'is_user_interested': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.remedyList = res.data;
        this.temp = this.remedyList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  getRowClass(row:any) {
    return { 'bg-booked': row.isSuggestedProductBought };
 }
 

}
