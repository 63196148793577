<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Profile Update</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/request/profile']">Profile</a></li>
            <li class="breadcrumb-item active" aria-current="page">Update</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-transparent border-0 d-flex justify-content-between">
                    <h3 class="card-title mb-0">#{{astroData?.astroAutoId}} - {{astroData?.name}} ({{astroData?.displayname}})</h3>
                    <button class="btn btn-primary" (click)="closeProfileRequest()">CLOSE REQUEST</button>
                </div>
                <div class="">
                    <div class="grid-margin">
                        <div class="">
                            <div class="table-responsive">
                                <table class="table card-table table-vcenter align-items-center profile-table" >
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Name</th>
                                            <th>Previous</th>
                                            <th>Updated</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="data.is_experience_request && !data.is_experience_approved">
                                            <td>Experience</td>
                                            <td>{{astroData.experience}}</td>
                                            <td>{{data.experience}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('experience',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('experience',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_complete_address_request && !data.is_complete_address_approved">
                                            <td>Complete Address</td>
                                            <td>{{astroData.complete_address}}</td>
                                            <td>{{data.complete_address}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('complete_address',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('complete_address',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_city_request && !data.is_city_approved">
                                            <td>City</td>
                                            <td>{{astroData.city}}</td>
                                            <td>{{data.city}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('city',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('city',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_state_request && !data.is_state_approved">
                                            <td>State</td>
                                            <td>{{astroData.state}}</td>
                                            <td>{{data.state}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('state',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('state',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_country_request && !data.is_country_approved">
                                            <td>Country</td>
                                            <td>{{astroData.country}}</td>
                                            <td>{{data.country}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('country',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('country',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_pincode_request && !data.is_pincode_approved">
                                            <td>Pincode</td>
                                            <td>{{astroData.pincode}}</td>
                                            <td>{{data.pincode}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('pincode',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('pincode',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_working_with_other_request && !data.is_working_with_other_approved">
                                            <td>Is Working with other ?</td>
                                            <td>{{astroData.working_with_other ? 'Yes':'No'}}</td>
                                            <td>{{data.working_with_other ? 'Yes':'No'}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('working_with_other',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('working_with_other',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_short_bio_request && !data.is_short_bio_approved">
                                            <td>Short Bio</td>
                                            <td>{{astroData.short_bio}}</td>
                                            <td>{{data.short_bio}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('short_bio',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('short_bio',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_long_bio_request && !data.is_long_bio_approved">
                                            <td>Long Bio</td>
                                            <td>{{astroData.long_bio}}</td>
                                            <td>{{data.long_bio}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('long_bio',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('long_bio',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_lang_request && !data.is_lang_approved">
                                            <td>Language</td>
                                            <td><span *ngFor="let lang of astroData.lang" class="badge badge-primary text-capatilize mr-2">{{lang.langId.name}}</span></td>
                                            <td><span *ngFor="let lan of data.lang" class="badge badge-info text-capatilize mr-2">{{lan.langId.name}}</span></td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('lang',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('lang',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_skill_request && !data.is_skill_approved">
                                            <td>Skills</td>
                                            <td><span *ngFor="let skill of astroData.skill" class="badge badge-primary text-capatilize mr-2">{{skill.skillId.skillname}}</span></td>
                                            <td><span *ngFor="let skill of data.skill" class="badge badge-info text-capatilize mr-2">{{skill.skillId.skillname}}</span></td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('skill',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('skill',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_bank_account_num_request && !data.is_bank_account_num_approved">
                                            <td>Bank Account Number</td>
                                            <td>{{astroData.bank_account_num}}</td>
                                            <td>{{data.bank_account_num}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('bank_account_num',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('bank_account_num',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_account_type_request && !data.is_account_type_approved">
                                            <td>Account Type</td>
                                            <td>{{astroData.account_type}}</td>
                                            <td>{{data.account_type}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('account_type',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('account_type',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_account_holder_name_request && !data.is_account_holder_name_approved">
                                            <td>Account Holder Name</td>
                                            <td>{{astroData.account_holder_name}}</td>
                                            <td>{{data.account_holder_name}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('account_holder_name',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('account_holder_name',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_ifsc_request && !data.is_ifsc_approved">
                                            <td>IFSC</td>
                                            <td>{{astroData.ifsc}}</td>
                                            <td>{{data.ifsc}}</td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('ifsc',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('ifsc',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_profile_image_request && !data.is_profile_image_approved">
                                            <td>Profile Image</td>
                                            <td><img [src]="getSanitizeUrl(astroData.profile_image)" alt="" class="img img-fluid img-thumbnail"></td>
                                            <td><img [src]="getSanitizeUrl(data.profile_image)" alt="" class="img img-fluid img-thumbnail"></td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('profile_image',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('profile_image',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                        <tr *ngIf="data.is_proof_image_request && !data.is_proof_image_approved">
                                            <td>Proof Image</td>
                                            <td><img [src]="getSanitizeUrl(astroData.proof_image)" alt="" class="img img-fluid img-thumbnail"></td>
                                            <td><img [src]="getSanitizeUrl(data.proof_image)" alt="" class="img img-fluid img-thumbnail"></td>
                                            <td><a href="javascript:void(0)" (click)="actionOnProfileRequest('proof_image',true)" class="btn btn-sm btn-success mr-4">Approve</a><a href="javascript:void(0)" (click)="actionOnProfileRequest('proof_image',false)" class="btn btn-sm btn-danger">Reject</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
