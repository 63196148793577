import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { ProductMallService } from 'src/app/admin/services/product-mall/product-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { SubcategoryMallService } from 'src/app/admin/services/subcategory-mall/subcategory-mall.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-mall-product',
  templateUrl: './update-mall-product.component.html',
  styleUrls: ['./update-mall-product.component.scss']
})
export class UpdateMallProductComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private productMallService: ProductMallService,
    private route: Router,
    private categoryMallService: CategoryMallService,
    private subcategoryMallService: SubcategoryMallService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    productname: new FormControl('', [Validators.required]),
    productCode: new FormControl('', [Validators.required]),
    productdescription: new FormControl('', Validators.required),
    product_image: new FormControl(''),
    updatedId: new FormControl('', [Validators.required]),
    product_slug: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9A-Z]+(?:-[a-z0-9A-Z]+)*$')]),
    updatedBy: new FormControl('', Validators.required),
    product_keyword: new FormControl(''),
    videolink: new FormControl(''),
    is_direct_price: new FormControl(false, Validators.required),
    price: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    usd_price: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),//usd price for direct product
    min_price: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    productcategoryId: new FormControl('', Validators.required),
    productsubcategoryId: new FormControl(''),
    is_addon_associate: new FormControl(false, Validators.required),
    // isFeaturedProduct: new FormControl(true, Validators.required),
    is_astrologer_associate: new FormControl(true, Validators.required),
    is_offer_applied: new FormControl(false, Validators.required),
    offer_percentage: new FormControl(''),
    delivery_time: new FormControl(''),
    gstPercent: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    fields_applied: new FormControl(''),
    welcome_msg: new FormControl('', Validators.required)
  });

  id: any
  data: any
  BaseUrl: any
  productImages: any = []
  isImageAllow: boolean = true
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    // console.log()
    this.formGroup.patchValue({ updatedId: this.userdata.getId() })
    this.formGroup.patchValue({ updatedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
    this.getCategoryList()
    this.getDataById()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Validate if subcategory is required
   */
   isProductAssociate:boolean = false
   isSubcategoryAssociate:boolean = false

  /**
   * Get Product detail
   */
  getDataById() {
    this.spinner.show()
    this.productMallService.detailMallProduct({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
        //console.log('category id',this.data.productcategoryId.categoryId)
        this.images = this.data.product_image
        if (this.data.product_image.length >= 12) {
          this.isImageAllow = false
        }
        this.isProductAssociate = this.data?.productcategoryId?.is_product_associated
        this.isSubcategoryAssociate = this.data?.productcategoryId?.is_subcategory_associated
        //console.log(this.images)
        this.formGroup.patchValue({ productname: this.data.productname })
        this.formGroup.patchValue({ productCode: this.data.productCode })
        this.formGroup.patchValue({ productdescription: this.data.productdescription })
        this.formGroup.patchValue({ product_slug: this.data.product_slug })
        this.formGroup.patchValue({ product_keyword: this.data.product_keyword })
        this.formGroup.patchValue({ videolink: this.data.videolink })
        this.formGroup.patchValue({ is_direct_price: this.data.is_direct_price })
        // this.formGroup.patchValue({ price: this.data.price })
        // this.formGroup.patchValue({ usd_price: this.data.usd_price })
        this.formGroup.patchValue({ price: this.data.original_amount })
        this.formGroup.patchValue({ usd_price: this.data.usd_original_amount })
        this.formGroup.patchValue({ min_price: this.data.min_price })
        this.formGroup.patchValue({ productcategoryId: this.data.productcategoryId._id })
        this.formGroup.patchValue({ productsubcategoryId: this.data?.productsubcategoryId?._id })
        this.formGroup.patchValue({ is_addon_associate: this.data.is_addon_associate })
        // this.formGroup.patchValue({ isFeaturedProduct: this.data.isFeaturedProduct })
        this.formGroup.patchValue({ is_astrologer_associate: this.data.is_astrologer_associate })
        this.isAstro = this.data.is_astrologer_associate
        // this.isFeatured = this.data.isFeaturedProduct
        this.formGroup.patchValue({ is_offer_applied: this.data.is_offer_applied })
        this.formGroup.patchValue({ offer_percentage: this.data.offer_percentage })
        this.formGroup.patchValue({ delivery_time: this.data.delivery_time })
        this.formGroup.patchValue({ gstPercent: this.data.gstPercent })
        this.formGroup.patchValue({ welcome_msg: this.data.welcome_msg })
        this.emailOn = this.data.fields_applied.isEmailOn
        this.nameOn = this.data.fields_applied.isNameOn
        this.phoneOn = this.data.fields_applied.isPhoneOn
        this.addressOn = this.data.fields_applied.isAddressOn
        this.ringSizeOn = this.data.fields_applied.isRingSizeOn
        this.quantityOn = this.data.fields_applied.isQuantityOn
        this.maritalStatusOn = this.data.fields_applied.isMaritalStatusOn
        this.genderOn = this.data.fields_applied.isGenderOn
        this.commentsOn = this.data.fields_applied.isCommentsOn
        this.dobOn = this.data.fields_applied.isDobOn
        this.tobOn = this.data.fields_applied.isTobOn
        if(this.data.productsubcategoryId != undefined){
          this.subcategoryList.push({ _id: this.data.productsubcategoryId._id, subcategoryname: this.data.productsubcategoryId.subcategoryname })
        }
      },
      err => {
        this.spinner.hide()
        // console.log(err)
        // this.route.navigateByUrl('/admin/mall-product/list')
      }
    )
  }

  // for product images
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  /**
   * Get Category List
   */
  categoryList: any = []
  getCategoryList() {
    this.spinner.show()
    this.categoryMallService.listMallCategory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.categoryList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Get Subcategory List
   */
  subcategoryList: any = []
  getSubcategoryList(id: any) {
    this.subcategoryList = []
    this.formGroup.patchValue({ productsubcategoryId: '' })
    this.spinner.show()
    /** Get Single Category Detail */
    this.categoryMallService.detailMallCategory({_id:id}).subscribe(
      (res:any)=>{
        this.isProductAssociate = res.data.is_product_associated
        this.isSubcategoryAssociate = res.data.is_subcategory_associated
        //console.log(this.isSubcategoryAssociate,this.isProductAssociate)
        this.subcategoryMallService.listMallSubcategory({ categoryId: id }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.subcategoryList = res.data;
          },
          (err) => {
            this.spinner.hide()
            //console.log(err);
          }
        );
      },err=>{
        this.spinner.hide()
      }
    )
  }

  /** Pop image functionality */
  removeImage(image: any) {
    this.productMallService.deleteMallProductImage({ _id: this.id, product_image: image, updatedId: this.userdata.getId(), updatedBy: this.userdata.getName() }).subscribe(
      (res: any) => {
        this.images.forEach((value: any, index: any) => {
          if (value == image) this.images.splice(index, 1);
        });
        if (this.images.length < 12) {
          this.isImageAllow = true
        } else {
          this.isImageAllow = false
        }
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Product Image
   */
  imageError: string = ''
  imageSrc = ''
  images: any = [];
  public onProductImageUpload(event: any){
    this.imageError = ''
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 540;
            const max_width = 960;
            for (var j = 0; j < event.target.files.length; j++) {
               if (!_.includes(allowed_types, event.target.files[j].type)) {
                 this.imageError = 'Only Images are allowed ( JPG | PNG )';
                 return;
               }
               if(event.target.files.length>11) {
                 this.imageError = 'Maximum 12 images are allowed';
               }
            }

      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = (e:any) => {
        this.imageSrc = reader.result as string;
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs:any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
              this.imageError =
                  'Image dimentions allowed ' +
                  max_width +
                  ' X ' +
                  max_height +
                  'px';
              return;
          } else {
           for (var i = 0; i < event.target.files.length; i++) {
             this.productImages.push(event.target.files[i]);
             // console.log(this.images)
           }
           this.formGroup.patchValue({product_image:this.productImages})
          }
        }
      };
    }
  }

  isUnique: boolean = true

  checkSlugUnique(event: any) {
    this.productMallService.checkMallProductUnique({ slug: event.target.value }).subscribe(
      (res: any) => {
        if (res.success) {
          this.isUnique = true
        } else {
          this.isUnique = false
        }
      }
    )
  }

  /**
   * Check if Astrologer Associated or not
   */
  isAstro = true
  checkAstrologerAssociated() {
    this.isAstro = this.formGroup.get('is_astrologer_associate')?.value
    this.formGroup.patchValue({ is_direct_price: !this.isAstro })
    // if (!this.isAstro) {
    //   this.formGroup.patchValue({ is_offer_applied: true })
    // }
    //console.log('direct price',this.formGroup.get('is_direct_price')?.value)
  }
  
  /**
   * Check if Astrologer Associated or not
   */
  //  isFeatured:boolean = false
  // checkFeaturedProduct() {
  //   // this.isFeatured = this.formGroup.get('isFeaturedProduct')?.value
  //   // console.log("before isfeatured => ", this.isFeatured)
  //   // console.log("before isfeaturedProduct => ", this.formGroup.get('isFeaturedProduct')?.value)
  //   this.formGroup.patchValue({ isFeaturedProduct: !this.isFeatured })
  //   this.isFeatured = !this.isFeatured
  //   // console.log("after isfeatured => ", this.isFeatured)
  //   // console.log("after isfeaturedProduct => ", this.formGroup.get('isFeaturedProduct')?.value)
  // }
  /**
   * Check if Addon Associated or not
   */
  isAddon = false
  checkAddonAssociated() {
    this.isAddon = !this.isAddon
    this.isAddon = this.formGroup.get('is_addon_associate')?.value
    this.formGroup.patchValue({ "is_addon_associate": this.isAddon })
  }


  /** Field applied checkbox */
  nameOn: boolean = false;
  isNameOn(evt: any) {
    this.nameOn = !this.nameOn
    //console.log(this.nameOn)
  }

  emailOn: boolean = false;
  isEmailOn(evt: any) {
    // this.emailOn = !this.emailOn
    //console.log(this.emailOn)
  }

  phoneOn: boolean = false;
  isPhoneOn(evt: any) {
    // this.phoneOn = !this.phoneOn
  }

  addressOn: boolean = false;
  isAddressOn(evt: any) {
    // this.addressOn = !this.addressOn
  }

  ringSizeOn: boolean = false;
  isRingSizeOn(evt: any) {
    // this.ringSizeOn = !this.ringSizeOn
  }

  quantityOn: boolean = false;
  isQuantityOn(evt: any) {
    // this.quantityOn = !this.quantityOn
  }

  maritalStatusOn: boolean = false;
  isMaritalStatusOn(evt: any) {
    // this.maritalStatusOn = !this.maritalStatusOn
  }

  genderOn: boolean = false;
  isGenderOn(evt: any) {
    // this.genderOn = !this.genderOn
  }

  commentsOn: boolean = false;
  isCommentsOn(evt: any) {
    //this.commentsOn = !this.commentsOn
  }

  dobOn: boolean = false;
  tobOn: boolean = false;
  isBirthDetailsOn(evt: any) {
    // var previous = this.dobOn
    // this.dobOn = !previous
    // this.tobOn = !previous
  }

  /** update mall product */

  updateMallProduct() {
    /**
     * Validate Subcategory if required
     */
     if(this.isSubcategoryAssociate && this.formGroup.get('productsubcategoryId')?.value==''){
      Swal.fire(
        'Validation message!',
        'Please Select Subcategory!',
        // 'question'
      )
      return
    }
    //return
    /** Check minimum product price validation */
    if (this.isAstro) {
      if (this.formGroup.get("min_price")?.value == "") {
        Swal.fire(
          'Validation message!',
          'Minimum price is required!',
          // 'question'
        )
        return
      }

    }
    /** Check product price validation */
    if (!this.isAstro) {
      if (this.formGroup.get("price")?.value == "") {
        Swal.fire(
          'Validation message!',
          'Price is required!',
          // 'question'
        )
        return
      }
      /** Check offer product % validation */
      // if (this.formGroup.get("offer_percentage")?.value == "") {
      //   Swal.fire(
      //     'Validation message!',
      //     'Offer percentage is required!',
      //     // 'question'
      //   )
      //   return
      // }
    }
    var fields = {
      isNameOn: this.nameOn,
      isEmailOn: this.emailOn,
      isPhoneOn: this.phoneOn,
      isQuantityOn: this.quantityOn,
      isMaritalStatusOn: this.maritalStatusOn,
      isGenderOn: this.genderOn,
      isCommentsOn: this.commentsOn,
      isAddressOn: this.addressOn,
      isRingSizeOn: this.ringSizeOn,
      isDobOn: this.dobOn,
      isTobOn: this.dobOn,
    }
    this.formGroup.patchValue({ "fields_applied": JSON.stringify(fields) })
    this.formGroup.patchValue({ "is_direct_price": !this.isAstro })
    this.formGroup.patchValue({ "is_astrologer_associate": this.isAstro })

    // console.log(this.formGroup.controls)
    // return
    const data = new FormData()
    data.append("updatedId", this.formGroup.get("updatedId")?.value)
    data.append("productname", this.formGroup.get("productname")?.value)
    data.append("productdescription", this.formGroup.get("productdescription")?.value)
    // data.append("product_image", this.formGroup.get("product_image")?.value)
    for (var i = 0; i < this.productImages.length; i++) {
      if (this.productImages.length < 0) {
        alert("Upload at least one product image.")
        return
      } else {
        data.append("product_image", this.productImages[i])
        //data.append("product_image", this.formGroup.get("product_image")?.value)
      }

    }
    if(!this.isAstro && (Number(this.formGroup.get("offer_percentage")?.value) > 0 && this.formGroup.get("offer_percentage")?.value != '' && this.formGroup.get("offer_percentage")?.value != null && this.formGroup.get("offer_percentage")?.value != undefined )) {
      this.formGroup.patchValue({is_offer_applied: true})
    }else{
      this.formGroup.patchValue({is_offer_applied: false})
      this.formGroup.patchValue({offer_percentage: 0})
    }
    data.append("updatedBy", this.formGroup.get("updatedBy")?.value)
    data.append("_id", this.formGroup.get("_id")?.value)
    data.append("product_slug", this.formGroup.get("product_slug")?.value)
    data.append("product_keyword", this.formGroup.get("product_keyword")?.value.replace(/,/gi, " | "))
    data.append("videolink", this.formGroup.get("videolink")?.value)
    data.append("is_direct_price", this.formGroup.get("is_direct_price")?.value)
    data.append("price", this.formGroup.get("price")?.value)
    data.append("usd_price", this.formGroup.get("usd_price")?.value)
    data.append("min_price", this.formGroup.get("min_price")?.value)
    data.append("productcategoryId", this.formGroup.get("productcategoryId")?.value)
    data.append("productsubcategoryId", this.formGroup.get("productsubcategoryId")?.value)
    data.append("is_addon_associate", this.formGroup.get("is_addon_associate")?.value)
    data.append("is_astrologer_associate", this.formGroup.get("is_astrologer_associate")?.value)
    data.append("is_offer_applied", this.formGroup.get("is_offer_applied")?.value)
    data.append("offer_percentage", this.formGroup.get("offer_percentage")?.value)
    data.append("delivery_time", this.formGroup.get("delivery_time")?.value)
    data.append("fields_applied", this.formGroup.get("fields_applied")?.value)
    data.append("gstPercent", this.formGroup.get("gstPercent")?.value)
    data.append("welcome_msg", this.formGroup.get("welcome_msg")?.value)
    // data.append("isFeaturedProduct", this.formGroup.get("isFeaturedProduct")?.value)
    data.append("productCode", this.formGroup.get("productCode")?.value)
    this.productMallService.updateMallProduct(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        //console.log('test', this.id)
        this.route.navigateByUrl('/admin/malls-product/' + this.id)
      }, err => {
        this.toast.error(err.message, 'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

}

