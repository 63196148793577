import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicPagesServiceService {

 
  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }

  public addDynamicPage(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addDynamicPage", data,{ headers: header_object});
  }

  public allDynamicPage(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getAllDynamicPages", data,{ headers: header_object});
  }

  public getDynamicPageById(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getSingleDynamicPage", data,{ headers: header_object});
  }

  public updateDynamicPage(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateDynamicPage", data,{ headers: header_object});
  }
  
  public deleteDynamicPage(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/deleteDynamicPage", data,{ headers: header_object});
  }

}
