<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Orders</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Orders</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul class="nav-tabs nav mb-4">
                                    <!-- <li class="nav-item">
                                        <a [routerLink]="['/admin/order/all']" class="nav-link"
                                            routerLinkActive="active">All</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/call']" class="nav-link"
                                            routerLinkActive="active">Call</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/all']" class="nav-link"
                                            routerLinkActive="active">All Orders</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/chat']" class="nav-link"
                                            routerLinkActive="active">Chat</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/query']" class="nav-link"
                                            routerLinkActive="active">Query</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/query-not-replied-in-24-hours']" class="nav-link"
                                            routerLinkActive="active">Query Not Answered</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/report']" class="nav-link"
                                            routerLinkActive="active">Report</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/report-not-replied-in-24-hours']" class="nav-link"
                                            routerLinkActive="active">Report Not Answered</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/store']" class="nav-link"
                                            routerLinkActive="active">Store Order</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/promo']" class="nav-link"
                                            routerLinkActive="active">Promo Orders</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/admin/order/addpromo']" class="nav-link"
                                            routerLinkActive="active">Assign Promo</a>
                                    </li>
                                </ul>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
