<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->
    <form [formGroup]="formGroup" (submit)="addFestivalSubmit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Festival Date</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="festivalId">Festival Name *</label>
                                    <select class="form-control" formControlName="festivalId">
                                        <option value="" selected>Select Festival</option>
                                        <option [value]="festival._id" *ngFor="let festival of festivalList">{{festival.festivalName}}</option>
                                    </select>
                                    <small
                                        *ngIf="f.festivalId.invalid && (f.festivalId.dirty || f.festivalId.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="festivalDate">Festival Date *</label>
                                    <input type="date" class="form-control" formControlName="festivalDate">                                        
                                    <small
                                        *ngIf="f.festivalDate.invalid && (f.festivalDate.dirty || f.festivalDate.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="col-sm-4 btn btn-success mt-1" [disabled]="!formGroup.valid">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->