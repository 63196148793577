<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Yearly Festival</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/festival/list-yearly']">Yearly Festival List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateFestival()">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="festivalId">Festival Name *</label>
                                        <select class="form-control" formControlName="festivalId">
                                            <option value="" selected>Select Festival</option>
                                            <option [value]="festival._id" *ngFor="let festival of festivalList">{{festival.festivalName}}</option>
                                        </select>
                                        <small
                                            *ngIf="f.festivalId.invalid && (f.festivalId.dirty || f.festivalId.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="festivalDate">Festival Date *</label>
                                        <input type="date" class="form-control" formControlName="festivalDate">                                        
                                        <small
                                            *ngIf="f.festivalDate.invalid && (f.festivalDate.dirty || f.festivalDate.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <button type="submit" class="col-sm-4 btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->