import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { UpdateRequestServiceService } from 'src/app/admin/services/update-request/update-request-service.service';

@Component({
  selector: 'app-profile-update-list',
  templateUrl: './profile-update-list.component.html',
  styleUrls: ['./profile-update-list.component.scss']
})
export class ProfileUpdateListComponent implements OnInit {

  constructor(
    private updateService: UpdateRequestServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  profileList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getProfileUpdateList()
  }

  /* ***********
  list Pending Profile Update Request
  *********** */

  getProfileUpdateList() {
    this.spinner.show()
    this.updateService.getProfileRequest({ userId: this.userdata.getId(),is_request_closed:false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.profileList = res.data;
        //console.log(this.profileList)
        this.temp = this.profileList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /* ***********
  list All Profile Update Request
  *********** */

 allProfileUpdateList() {
  this.spinner.show()
  this.updateService.getProfileRequest({ userId: this.userdata.getId()}).subscribe(
    (res: any) => {
      this.spinner.hide()
      this.profileList = res.data;
      this.temp = this.profileList;
    },
    (err) => {
      this.spinner.hide()
      //console.log(err);
    }
  );
}
  /* ***********
  list Closed Profile Update Request
  *********** */

 closedProfileUpdateList() {
  this.spinner.show()
  this.updateService.getProfileRequest({ userId: this.userdata.getId(),is_request_closed:true}).subscribe(
    (res: any) => {
      this.spinner.hide()
      this.profileList = res.data;
      //console.log(this.profileList)
      this.temp = this.profileList;
    },
    (err) => {
      this.spinner.hide()
      //console.log(err);
    }
  );
}

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.astrologerId.astroAutoId.toString().indexOf(val) !== -1 ||
        d.astrologerId.displayname.toLowerCase().indexOf(val) !== -1 ||
        d.astrologerId.email.toLowerCase().indexOf(val) !== -1 ||
        d.astrologerId.phone.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.profileList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
