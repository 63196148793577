<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Waiting List</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Waiting List</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>List</a>
                    <ng-template ngbNavContent>
                      <div class="pr-2 pl-2 d-flex justify-content-between">
                        <input
                          value="{{ searchKey }}"
                          type="text"
                          placeholder="Type to filter the name column..."
                          class="form-control col-lg-4 mt-1"
                          (keyup)="updateFilter($event)"
                        />
                        <div class="mt-3">
                          <button
                            class="btn btn-primary mr-2"
                            (click)="getWaitingList({ is_user_notified: false})"
                          >
                            All
                          </button>
                          <button
                            class="btn btn-info mr-2"
                            (click)="getCallWaiting()"
                          >
                            Call Wait
                          </button>
                          <button
                            class="btn btn-success mr-2"
                            (click)="getChatWaiting()"
                          >
                            Chat Wait
                          </button>
                          <button
                            class="btn btn-warning mr-2"
                            (click)="getOnlineWaiting()"
                          >
                            Wait List of Online Astrologers
                          </button>
                        </div>
                      </div>
                      <br />
                      <ngx-datatable
                        (page)="onPageChange($event)"
                        class="material"
                        [rows]="waitList"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [rowHeight]="'auto'"
                        [scrollbarV]="false"
                        [limit]="10"
                        [sorts]="[{ dir: 'desc' }]"
                        style="position: relative !important"
                      >
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="ID"
                          prop="waitingId"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.waitingId }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Astrologer"
                          prop="astroId.name"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <span [title]="row.astroId?.name"
                              >#{{ row.astroId?.astroAutoId }}-{{
                                row.astroId?.displayname == "" ||
                                row.astroId?.displayname == undefined ||
                                row.astroId?.displayname == null
                                  ? row.astroId?.name
                                  : row.astroId?.displayname
                              }}</span
                            >
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Customer"
                          prop="customerId.name"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            #{{ row.customerId?.customerAutoId }}-{{
                              row.customerId?.name
                            }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Type">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <span
                              *ngIf="row.is_chat"
                              class="badge badge-primary"
                              >Chat</span
                            >
                            <span *ngIf="row.is_call" class="badge badge-info"
                              >Call</span
                            >
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="1"
                          name="Wait Time"
                          prop="last_wait_time"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ convertSecondstoTime(row.last_wait_time) }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [flexGrow]="2"
                          name="Date"
                          prop="created_at"
                        >
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            {{ row.created_at | date: "medium" }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Action">
                          <ng-template
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <button
                              class="btn btn-sm btn-outline-primary"
                              (click)="removeFromWaitingList(row._id)"
                            >
                              Remove
                            </button>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
