<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Skills</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/skills']">Skills</a></li>
      <li class="breadcrumb-item active" aria-current="page">List</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <ng-template #content let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Add Skill</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="addSKillsForm" (submit)="onAddSkills()">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="skills">Skills</label>
                        <input type="text" class="form-control " formControlName="skillname"
                          placeholder="Enter Skill" required>
                        <div *ngIf="f.skillname.invalid && (f.skillname.dirty || f.skillname.touched)"
                          class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
                <button type="submit" class="btn btn-outline-dark" (click)="onAddSkills()"
                  [disabled]="!addSKillsForm.valid">Save</button>
              </div>
            </ng-template>

            <div style="display: flex; justify-content: space-between;">
              <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
              <button class="btn btn-lg btn-outline-primary" (click)="open(content)">Add Skills</button>
            </div>
            <hr>
          </div>
          <div class="row d-flex">
            <div class="mr-4" *ngFor="let skill of skillsData">
              <div class="card">
                <div class="card-body text-center">
                  <!-- <blockquote class="quote"> -->
                  <p>{{skill.skillname}}</p>
                  <small class="text-muted">
                    <a [routerLink]="['/admin/skills/editSkills/', skill._id]">Edit</a>
                  </small>
                  <!-- </blockquote> -->
                </div>
              </div>
            </div><!-- COL END -->
          </div>
        </div>
      </div>
    </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
