import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FestivalServiceService } from 'src/app/admin/services/festival/festival-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-yearly-festival',
  templateUrl: './list-yearly-festival.component.html',
  styleUrls: ['./list-yearly-festival.component.scss']
})
export class ListYearlyFestivalComponent implements OnInit {

  constructor(
    private festivalService: FestivalServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getYearlyFestivalList()
  }

  /* ***********
  list Festival
  *********** */

  getYearlyFestivalList() {
    this.spinner.show()
    this.festivalService.listYearlyFestival({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.festivalId.festivalName.toLowerCase().indexOf(val) !== -1 ||d.year.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Delete Yearly festival
   */
  deleteYearlyFestival(key:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.festivalService.deleteYearlyFestival({_id:key._id,userId:this.userdata.getId()}).subscribe(
          (res:any)=>{
            this.toast.success(res.message,'Success')
            this.getYearlyFestivalList()
          },err=>{
            this.toast.error(err.error.message,'Error')
          }
        )
      }
    })
  }

}
