import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { UpdateRequestServiceService } from 'src/app/admin/services/update-request/update-request-service.service';

@Component({
  selector: 'app-approve-reject-profile',
  templateUrl: './approve-reject-profile.component.html',
  styleUrls: ['./approve-reject-profile.component.scss']
})
export class ApproveRejectProfileComponent implements OnInit {

  BaseUrl = '';
  id :any = ''
  data:any = ''
  constructor(
    private updateService: UpdateRequestServiceService,
    private route: ActivatedRoute,
    private userdata: UserdataService,
    private astro: AstroServiceService,
    private toast: ToastrService,
    private router: Router,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
    }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getSingleProfileRequest()
  }

  getSingleProfileRequest(){
    this.updateService.singleProfileRequest({_id:this.id}).subscribe(
      (res:any)=>{
        this.data = res.data
        var astroId = res.data.astrologerId._id
        this.getAstrologerById(astroId)
      },err=>{
        
      }
    )
  }

  actionOnProfileRequest(col:any,val:any){
    var data = {
      userId:this.userdata.getId(),keyname:col,is_approved:val,is_request_closed:false,backupId:this.id
    }
    this.updateService.approveRejectProfileRequest(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.getSingleProfileRequest()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  astroData:any = ""
  getAstrologerById(id:any){
    this.astro.getAstrologerDataById({_id:id}).subscribe(
      (res:any) =>{
        this.astroData = res.data
        //console.log(this.astroData.skill)
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  closeProfileRequest(){
    this.updateService.closeProfileRequest({userId:this.userdata.getId(),is_request_closed:true,backupId:this.id}).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/request/profile')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
