<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Store Order</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/order/store']">Store</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>

    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-8 col-md-4">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div
                                                        class="media-heading d-flex justify-content-between align-items-end">
                                                        <h4>Store Order Details
                                                            <span class="badge badge-primary"
                                                                *ngIf="data.orderStatus == 1">Placed</span>
                                                            <span class="badge badge-warning"
                                                                *ngIf="data.orderStatus == 2">Confirmed</span>
                                                            <span class="badge badge-info"
                                                                *ngIf="data.orderStatus == 3">Shipped</span>
                                                            <span class="badge badge-warning"
                                                                *ngIf="data.orderStatus == 4">Out For Delivery</span>
                                                            <span class="badge badge-success"
                                                                *ngIf="data.orderStatus == 5">Delivered</span>
                                                            <span class="badge badge-primary mr-1"
                                                                *ngIf="data.orderStatus == 6">Cancelled</span><span
                                                                class="badge badge-warning"
                                                                *ngIf="data.orderStatus == 6 && data.refundStatus == 0">Not
                                                                Refunded</span><span class="badge badge-info"
                                                                *ngIf="data.orderStatus == 6 && data.refundStatus == 1">Refund
                                                                Requested</span><span class="badge badge-success"
                                                                *ngIf="data.orderStatus == 6 && data.refundStatus == 2">Refund
                                                                Approved</span><span class="badge badge-primary"
                                                                *ngIf="data.orderStatus == 6 && data.refundStatus == 3">Refund
                                                                Rejected</span>
                                                            <span class="badge badge-info"
                                                                *ngIf="data.orderStatus == 7 && !data.isOrderCompleted">Astro
                                                                Compelete</span>
                                                            <span class="badge badge-success"
                                                                *ngIf="data.orderStatus == 8 && data.isOrderCompleted">Complete</span>
                                                        </h4>
                                                        <div>
                                                            <button
                                                                *ngIf="!data.isOrderCompleted && data.refundStatus <= 1"
                                                                class="mr-2 btn-user-custom btn btn-outline-primary"
                                                                [routerLink]="['/admin/orders/store/update',data._id]">Update
                                                                Order Status</button>
                                                        </div>
                                                    </div>
                                                    <!-- <hr class="m-0"> -->
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class="border-0 col-sm-4 col-md-4 col-lg-4 pr-0">
                                                                <tr>
                                                                    <td><strong>Order Id :</strong> #{{data.orderId}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-sm-4 col-md-4 col-lg-4 p-0">
                                                                <tr>
                                                                    <td><strong>Product Price :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span *ngIf="data.isForeignOrder">$</span>
                                                                        <span class="price">{{data.productPrice}}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-sm-4 col-md-4 col-lg-4 p-0">
                                                                <tr>
                                                                    <td><strong>Date :</strong> <span
                                                                            class="price">{{data.createdAt |
                                                                            date:'medium'}}</span> </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-sm-4 col-md-4 col-lg-4 pr-0">
                                                                <tr>
                                                                    <td><strong>Admin Amount :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span
                                                                            *ngIf="data.isForeignOrder">$</span>{{data.adminAmount}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-sm-4 col-md-4 col-lg-4 p-0">
                                                                <tr>
                                                                    <td><strong>Astrologer Amount :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span
                                                                            *ngIf="data.isForeignOrder">$</span>{{data.astroAmount}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-sm-4 col-md-4 col-lg-4 p-0">
                                                                <tr>
                                                                    <td><strong>Consultant Amount :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span
                                                                            *ngIf="data.isForeignOrder">$</span>{{data.consultantAmount}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr *ngIf="data.astroId != null">
                                                                    <td><strong>Astrologer :</strong>
                                                                        <a
                                                                            [routerLink]="['/admin/astrologers/viewAstrologer',data.astroId?._id]">#{{data.astroId.astroAutoId}}
                                                                            -
                                                                            {{data.astroId.name}}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.couponId != null">
                                                                    <td><strong>Coupon Code :</strong>
                                                                        {{data.couponCode}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Add-On Amount :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span *ngIf="data.isForeignOrder">$</span>
                                                                        <span class="price">{{data.addOnsTotal}}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>GST :</strong> <span
                                                                            class="price">{{data.gst |
                                                                            currency:'INR':'symbol-narrow'}}
                                                                            ({{data.gstPercent}}%)</span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.consultantId != null">
                                                                    <td><strong>Consultant ID :</strong>
                                                                        #{{data.consultantId?.astroAutoId}} -
                                                                        {{data.consultantId?.name}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr *ngIf="data.astroId != null">
                                                                    <td><strong>Astrologer Charges :</strong> <span
                                                                            class="price">{{data.astroamounttopay |
                                                                            currency:'INR':'symbol-narrow'}}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.couponId != null">
                                                                    <td><strong>Coupon Discount :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span *ngIf="data.isForeignOrder">$</span>
                                                                        <span
                                                                            class="price">{{data.couponDiscount}}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Sub Total :</strong>

                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span *ngIf="data.isForeignOrder">$</span>
                                                                        <span class="price">{{data.subTotal}}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Grand Total :</strong>
                                                                        <span *ngIf="!data.isForeignOrder">₹</span>
                                                                        <span *ngIf="data.isForeignOrder">$</span>
                                                                        <span class="price">{{data.grandTotal | number :
                                                                            '.2-2' }}</span>

                                                                        (<span>₹
                                                                            {{data.grandTotal*data.dollarCurrentPrice |
                                                                            number : '.2-2' }} </span>)
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.consultantId != null">
                                                                    <td><strong>Consultant Name :</strong>
                                                                        {{data.consultantId?.name}} </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div
                                                        class="media-heading d-flex justify-content-between align-items-end">
                                                        <h4>Product Details</h4>
                                                    </div>
                                                    <!-- <hr class="m-0"> -->
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong>Product Id :</strong>
                                                                        #{{data.productId?.productId}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Product Name :</strong>
                                                                        {{data.productId?.productname}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Product Description :</strong>
                                                                        {{data.productId?.productdescription}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="data.addOns?.length > 0"
                                                        class="media-heading d-flex justify-content-between align-items-end">
                                                        <h4>Add-On Purchased</h4>
                                                    </div>
                                                    <div *ngIf="data.addOns?.length > 0"
                                                        class="table-responsive  pl-4 ">
                                                        <table class="table row table-bordered d-table">
                                                            <tr class="w-100">
                                                                <td><strong># </strong></td>
                                                                <td><strong>Name </strong></td>
                                                                <td><strong>Price </strong></td>
                                                                <td><strong>Size </strong></td>
                                                            </tr>
                                                            <tr class="w-100"
                                                                *ngFor="let addon of data.addOns; let i=index">
                                                                <td>{{i+1}} </td>
                                                                <td>{{addon.addOnName}}</td>
                                                                <td><span class="price">{{addon.addOnPrice |
                                                                        currency:'INR':'symbol-narrow'}}</span>
                                                                </td>
                                                                <td><span class="price">{{addon.addOnSize == '' ? '-' :
                                                                        addon.addOnSize}}</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.isReassigned">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div
                                                        class="media-heading d-flex justify-content-between align-items-end">
                                                        <h4>Re-Assign Order Details</h4>
                                                    </div>
                                                    <!-- <hr class="m-0"> -->
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong>Previous Astro :</strong>
                                                                        #{{data.prevAstroId.astroAutoId}} -
                                                                        {{data.prevAstroId.name}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Re-Assigned By :</strong>
                                                                        {{data.reAssignedBy}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Reason :</strong>
                                                                        {{data.reAssignedReason}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.isOrderReviewed">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Review</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="table row table-borderless">
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong>Review :</strong>
                                                                        {{data.reviewId?.customerReviewComments}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <!-- <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong>Stars :</strong>
                                                                        <ng-template #t let-fill="fill">
                                                                            <span class="star" [class.full]="fill === 100">
                                                                          <span class="half" [style.width.%]="fill">&#x2605;</span>&#x2605;
                                                                            </span>
                                                                        </ng-template>

                                                                        <ngb-rating [(rate)]="data.reviewId?.customerReviewStars" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                                                                    </td>
                                                                </tr>
                                                            </tbody> -->
                                                            <tbody class="col-lg-12 col-xl-12 p-0"
                                                                *ngIf="data.reviewId?.reviewReply != ''">
                                                                <tr>
                                                                    <td><strong>Review Reply:</strong>
                                                                        {{data.reviewId?.reviewReply}} <button
                                                                            class="btn btn-link"
                                                                            (click)="updateReviewReply()"
                                                                            *ngIf="data.astroId == null && data.reviewId?.reviewReply != ''">Change</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Reviewed On :</strong>
                                                                        {{data.reviewId?.customerReviewDate |
                                                                        date:'medium'}}</td>
                                                                </tr>
                                                                <tr *ngIf="data.reviewId?.reviewReply != ''">
                                                                    <td><strong>Review Reply :</strong>
                                                                        {{data.reviewId?.reviewReplyDate |
                                                                        date:'medium'}}</td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="data.astroId == null && data.reviewId?.reviewReply == ''">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="reviewReply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.reviewReply.invalid && (g.reviewReply.dirty || g.reviewReply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn btn-success btn-form-custom mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="data.astroId == null && data.reviewId?.reviewReply != '' && changeReviewReply">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="reviewReply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.reviewReply.invalid && (g.reviewReply.dirty || g.reviewReply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn btn-success btn-form-custom mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" *ngIf="data.orderStatus == 6">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div
                                                        class="media-heading d-flex justify-content-between align-items-end">
                                                        <h4>Refund Details</h4>
                                                    </div>
                                                    <!-- <hr class="m-0"> -->
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong>Refund Status :</strong>
                                                                        <span class="badge badge-warning"
                                                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 0">Not
                                                                            Refunded</span><span
                                                                            class="badge badge-info"
                                                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 1">Refund
                                                                            Requested</span><span
                                                                            class="badge badge-success"
                                                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 2">Refund
                                                                            Approved</span><span
                                                                            class="badge badge-primary"
                                                                            *ngIf="data.orderStatus == 6 && data.refundStatus == 3">Refund
                                                                            Rejected</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Order Cancelled By :</strong>
                                                                        {{data.isCustomer ? 'User':'Astro River'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="data.refundStatus == 2"
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong>Amount :</strong>
                                                                        <span class="price">{{data.refundedAmount |
                                                                            currency:'INR':'symbol-narrow'}}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="data.refundStatus == 2"
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Wallet ID :</strong>
                                                                        #{{data.refundedWalletId}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  revirew history -->
            <div class="card" *ngIf="data.reviewId?.reviewReplyLogs?.length > 0">
                <!-- ACCORDION BEGIN -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <!-- <div class="card-header"> -->
                            <h4 class="m-0 p-0">Review Reply History</h4>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="card-body p-2">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th class="bg-primary text-white">#</th>
                                            <th class="bg-primary text-white w-50">Reply</th>
                                            <th class="bg-primary text-white">Reply By</th>
                                            <th class="bg-primary text-white">Update Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let reply of data.reviewId?.reviewReplyLogs;let i = index">
                                            <td>{{i+1}}</td>
                                            <td class="w-50">{{reply.newReviewReply}}</td>
                                            <td class="">{{reply.is_reply_admin == "true" ? 'Admin' : 'Astrologer'}}
                                            </td>
                                            <td>{{reply.updatedReplyDate | date:'medium'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
                <!-- //ACCORDION BEGIN -->
            </div>
            <!-- astrologer review history -->
        </div>
        <!-- COL-END -->
        <div class="col-lg-4 col-md-4">
            <div class="card productdesc" *ngIf="data.group_chat_id != null">
                <div class="card-body">
                    <div class="panel panel-info">
                        <div class="tab-menu-heading border-bottom">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-group-tab"
                                        [routerLink]="['/admin/orders/groupchat-ongoing/',data?.group_chat_id]"><a
                                            [routerLink]="['/admin/orders/groupchat-ongoing/',data?.group_chat_id]"
                                            class="bg-primary text-white" data-toggle="tab">Group Chat</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc" *ngIf="data.customerId != null"
                [routerLink]="['/admin/customers/viewCustomer/',data.customerId?._id,'detail']">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="javascript:void(0)" class="active"
                                            data-toggle="tab">Client
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row" *ngIf="data.customerId?.customerAutoId">
                                            <div class="col-sm-4 text-muted">Customer ID</div>
                                            <div class="col-sm-8"><a
                                                    [routerLink]="['/admin/customers/viewCustomer/',data.customerId?._id,'detail']">#
                                                    {{data.customerId?.customerAutoId}}</a></div>
                                        </li>
                                        <li class="row" *ngIf="data.customerId?.name">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.customerId?.name}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.name">
                                            <div class="col-sm-4 text-muted">Username In order</div>
                                            <div class="col-sm-8">{{data.userDetails?.name}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.maritalStatus">
                                            <div class="col-sm-4 text-muted">Marital Status</div>
                                            <div class="col-sm-8">{{data.userDetails?.maritalStatus}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.email">
                                            <div class="col-sm-4 text-muted">Email</div>
                                            <div class="col-sm-8">{{data.userDetails?.email}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.gender">
                                            <div class="col-sm-4 text-muted">Gender</div>
                                            <div class="col-sm-8">{{data.userDetails?.gender}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.phoneNumber > 0">
                                            <div class="col-sm-4 text-muted">Phone Number</div>
                                            <div class="col-sm-8"> <span
                                                    *ngIf="data.userDetails?.countryCode > 0">+{{data.customerId?.countryCode}}</span>
                                                {{data.userDetails?.phoneNumber}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.quantity > 0">
                                            <div class="col-sm-4 text-muted">Quantity</div>
                                            <div class="col-sm-8">{{data.userDetails?.quantity}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.userDetails?.comments">
                                            <div class="col-sm-4 text-muted">Comments</div>
                                            <div class="col-sm-8">{{data.userDetails?.comments}}</div>
                                        </li>
                                        <ng-container *ngIf="data.userDetails?.addressId != null">
                                            <h4 class="mb-5 mt-3">Address Details</h4>
                                            <ul class="list-unstyled mb-0">
                                                <li class="row">
                                                    <div class="col-sm-4 text-muted">Name</div>
                                                    <div class="col-sm-8">{{data.userDetails?.addressId?.name}}</div>
                                                </li>
                                                <li class="row" *ngIf="data.userDetails?.addressId?.phoneNum > 0">
                                                    <div class="col-sm-4 text-muted">Phone Number</div>
                                                    <div class="col-sm-8"><span
                                                            *ngIf="data.userDetails?.addressId?.countryCodePhoneNum > 0">+{{data.userDetails?.addressId?.countryCodePhoneNum}}
                                                            - </span>{{data.userDetails?.addressId?.phoneNum}}</div>
                                                </li>
                                                <li class="row"
                                                    *ngIf="data.userDetails?.addressId?.alternatePhoneNum > 0">
                                                    <div class="col-sm-4 text-muted">Phone Number</div>
                                                    <div class="col-sm-8"><span
                                                            *ngIf="data.userDetails?.addressId?.countryCodeAltPhoneNum > 0">+{{data.userDetails?.addressId?.countryCodeAltPhoneNum}}
                                                            - </span>{{data.userDetails?.addressId?.alternatePhoneNum}}
                                                    </div>
                                                </li>
                                                <li class="row" *ngIf="data.userDetails?.addressId?.phoneNum > 0">
                                                    <div class="col-sm-4 text-muted">Phone Number</div>
                                                    <div class="col-sm-8"><span
                                                            *ngIf="data.userDetails?.addressId?.countryCodePhoneNum > 0">+{{data.userDetails?.addressId?.countryCodePhoneNum}}
                                                            - </span>{{data.userDetails?.addressId?.phoneNum}}</div>
                                                </li>
                                                <li class="row">
                                                    <div class="col-sm-4 text-muted">Address</div>
                                                    <div class="col-sm-8">
                                                        <span
                                                            *ngIf="data.userDetails?.addressId?.flatNo">{{data.userDetails?.addressId?.flatNo}},
                                                        </span>
                                                        <span
                                                            *ngIf="data.userDetails?.addressId?.locality">{{data.userDetails?.addressId?.locality}},
                                                        </span>
                                                        <span
                                                            *ngIf="data.userDetails?.addressId?.landmark">{{data.userDetails?.addressId?.landmark}},
                                                        </span>
                                                        <span
                                                            *ngIf="data.userDetails?.addressId?.city">{{data.userDetails?.addressId?.city}},
                                                        </span>
                                                        <span
                                                            *ngIf="data.userDetails?.addressId?.state">{{data.userDetails?.addressId?.state}},
                                                        </span>
                                                        <span
                                                            *ngIf="data.userDetails?.addressId?.country">{{data.userDetails?.addressId?.country}}</span>
                                                    </div>
                                                </li>
                                                <li class="row" *ngIf="data.userDetails?.addressId?.pinCode">
                                                    <div class="col-sm-4 text-muted">Address</div>
                                                    <div class="col-sm-8">{{data.userDetails?.addressId?.pinCode}}</div>
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc" *ngIf="data.is_customer_issue_created">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#"
                                            [routerLink]="['/admin/issues/customer/',data.customer_issueId._id]"
                                            class="active" data-toggle="tab">Issue
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Issue Id</div>
                                            <div class="col-sm-8">{{data.customer_issueId?.issueId}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Amount</div>
                                            <div class="col-sm-8"><span class="price">{{data.customer_issueId?.amount |
                                                    currency:'INR':'symbol-narrow'}}</span></div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Title</div>
                                            <div class="col-sm-8">{{data.customer_issueId?.issue_title}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Description</div>
                                            <div class="col-sm-8">{{data.customer_issueId?.issue_description}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Status</div>
                                            <div class="col-sm-8">
                                                <span class="badge badge-success"
                                                    *ngIf="data.customer_issueId?.is_issue_solved">SOLVED</span>
                                                <span class="badge badge-danger"
                                                    *ngIf="!data.customer_issueId?.is_issue_solved">PENDING</span>
                                            </div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Created At</div>
                                            <div class="col-sm-8">{{data.customer_issueId?.created_at | date:'medium'}}
                                            </div>
                                        </li>
                                        <!-- <li class="row" *ngIf="data.customer_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.customer_issueId?.reply_by_system}}</div>
                                        </li>

                                        <li class="row" *ngIf="data.customer_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.customer_issueId?.replyupdatedBy | date:'medium'}}
                                            </div>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->