import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomizeServiceService } from 'src/app/admin/services/customize/customize-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer-notice-board',
  templateUrl: './customer-notice-board.component.html',
  styleUrls: ['./customer-notice-board.component.scss']
})
export class CustomerNoticeBoardComponent implements OnInit {
  constructor(
    private customizeService: CustomizeServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { } 
  title = 'angular-datatables';

  dataList:any = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  page = 1
  pageSize = 10

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.listNoticeBoard()
  }

  /* ***********
  list Astrologer Notice Board
  *********** */

  listNoticeBoard() {
    //this.spinner.show()
    this.customizeService.singleCustomizationByType({customization_type:8}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data.customization_array;
        //console.log(this.dataList)
        this.temp = this.dataList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    //console.log(this.temp)
    const temp = this.temp.filter(function (d: any) {
      return d.value.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Delete Notice
   */
  deleteNotice(data:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show()
        this.customizeService.deleteCustomizationArrayById({_id:data._id,customization_type:6,userId:this.userdata.getId()}).subscribe(
          (res:any)=>{
            this.spinner.hide()
            this.listNoticeBoard()
            this.toast.success(res.message,'Success')
          },err=>{
            this.spinner.hide()
            this.toast.error(err.error.message,'Error')
          }
        )
      }
    })
  }
}
