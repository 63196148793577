import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-manager',
  templateUrl: './view-manager.component.html',
  styleUrls: ['./view-manager.component.scss']
})
export class ViewManagerComponent implements OnInit {
  currentPage=''
  constructor(
    private managerService: ManagerService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute 
  ) {
    this.currentPage = this.router.snapshot.url[0].path;
  }
  
  title = 'angular-datatables';
  originalList = new Array();
  managerList = new Array();
  id: any ='';
  getManagerData: any = [];
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  searchKey = ''
  ngOnInit() {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getManagerList()
    this.removeSearch();
    this.managerList =  this.getManagerData;
  }
  removeSearch() {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i) || '';
      if (key.includes('mng_search_')) {
        if (key != `mng_search_${this.currentPage}`) {
          localStorage.removeItem(key);
        } else {
          this.searchKey = localStorage.getItem(key) || '';
        }
      }
    }
  }
  /* ***********
  list Managers
  *********** */
data:any={}
  getManagerList() {
    this.spinner.show()
    if(this.currentPage=='list'){
      this.data['is_delete'] =false
    }
    if(this.currentPage=='onoff'){

      
      this.data['is_delete'] =false
      this.data['is_blocked'] =false
      this.data['sortby'] ='is_current_active'
    }
    if(this.currentPage=='blocked'){
      this.data['is_blocked'] =true
      this.data['is_delete'] =false
    }
    this.managerService.getManagerList(this.data).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.managerList = res.data;
        this.originalList =  res.data;
        this.getManagerData = this.managerList;
        let offset =
        localStorage.getItem(`mng_offset_${this.currentPage}`) || 0;
      if (offset > 0) {
        this.myFilterTable.offset = offset;
      }
      if (this.searchKey.length > 0) {
        this.searchByKey(this.searchKey);
      }
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val)
  }
  searchByKey(key: string) {
    if (key.length < 1) {
      this.managerList = this.originalList;
      localStorage.removeItem(`mng_search_${this.currentPage}`);
    } else {
    this.managerList = this.originalList.filter(function (d: any) {
      
      return d.name.toLowerCase().indexOf(key) !== -1 || !key || 
              d.phone.toString().indexOf(key) !== -1 || !key ||
              d.email.toLowerCase().indexOf(key) !== -1 || !key 
    });
    localStorage.setItem(`mng_search_${this.currentPage}`, key);
  }
    this.myFilterTable.offset = 0;
  }
  onPageChange(event: any) {
    localStorage.setItem(`mng_offset_${this.currentPage}`, event.offset);
  }

  public toggle($event: any, id: any, isBlocked: any) {

      this.managerService.blockManager({'_id': id, 'block':!isBlocked}).subscribe(
        (res: any) => {
          this.spinner.hide()
          this.toast.success('Manager Unblocked','Success')
          this.getManagerList()
        },
        (err) => {
          this.spinner.hide()
          //console.log(err);
          this.toast.error('Something went wrong','Error')
        }
      )
 
  }

  deleteManager(id:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.managerService.deleteManager({_id:id,is_delete:true}).subscribe(
          (res:any)=>{
            this.toast.success(res.message,'Success')
            this.getManagerList()
          },err=>{
            this.toast.error(err.error.message,'Error')
          }
        )
      }
    })
  }

}
