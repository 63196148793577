import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlluserService } from 'src/app/admin/services/alluser/alluser.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private usrService: AlluserService
  ) { }

  data: any = '';
  id: any = '';

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.usrService.getUserDataById({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

}
