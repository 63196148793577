import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';

@Component({
  selector: 'app-razorpay-recharge-list',
  templateUrl: './razorpay-recharge-list.component.html',
  styleUrls: ['./razorpay-recharge-list.component.scss']
})
export class RazorpayRechargeListComponent implements OnInit {
  BaseUrl = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private toast: ToastrService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl
   }

  data: any = '';
  temp: any = '';
  id: any = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getAllWalletRechargeOrders()
  }

  getAllWalletRechargeOrders() {
    this.spinner.show()
    this.orderService.getAllWalletRechargeOrders({currentmonth:true,is_foreign_order:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
        // console.log(res.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }
  
  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId?.name.toLowerCase().indexOf(val) !== -1 || d.customerId?.customerAutoId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  showDate(){
    if(this.range.get('start')?.value == null || this.range.get('start')?.value == '' || this.range.get('start')?.value == undefined || this.range.get('end')?.value == null || this.range.get('end')?.value == '' || this.range.get('end')?.value == undefined){
      this.toast.error('Please Select Start and End Date','Error')
      return
    }
    this.spinner.show()
    var start = moment(this.range.get('start')?.value).format('YYYY-MM-DD')
    var end = moment(this.range.get('end')?.value).format('YYYY-MM-DD')
    this.orderService.getAllWalletRechargeOrders({startdate:start,enddate:end,is_foreign_order:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

}
