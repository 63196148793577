import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-issue',
  templateUrl: './all-issue.component.html',
  styleUrls: ['./all-issue.component.scss']
})
export class AllIssueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
