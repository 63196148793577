import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CouponServiceService } from 'src/app/admin/services/coupon/coupon-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.scss']
})
export class EditCouponComponent implements OnInit {

  data: any;
  todayDate: any;

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private couponService: CouponServiceService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private _location:Location
  ) { }
  goBack(){
    this._location.back()
  }
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    amount: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    minimumAmount: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    maximumDiscount: new FormControl('', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    couponDescription: new FormControl('', Validators.required),
    isApplicableOnForeignOrders: new FormControl(false, [Validators.required]),
    couponUsdAmount: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    minimumUsdOrder: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    maximumUsdDiscount: new FormControl('', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    applicableOn: new FormControl('', Validators.required),
    usageLimitPerUser: new FormControl('', Validators.required),
    usageLimitPerCoupon: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    couponType: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
  });
  
  id: any
  ngOnInit(): void {
    this.todayDate = moment().format('YYYY-MM-DD')
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
    this.getDataById()
  }

  get f() {
    return this.formGroup.controls
  } 

  /** fetch coupon details */
  isForeignOrder: boolean = false
  getDataById() {
    this.spinner.show()
    this.couponService.getCouponById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
        this.formGroup.patchValue({ _id: this.id })
        var date = this.data.endDate
        var startdate = this.data.startDate
        this.isForeignOrder = this.data.isApplicableOnForeignOrders
        this.formGroup.patchValue({ code: this.data.code })
        this.formGroup.patchValue({ amount: this.data.amount })
        this.formGroup.patchValue({ minimumAmount: this.data.minimumAmount })
        this.formGroup.patchValue({ maximumDiscount: this.data.maximumDiscount })
        this.formGroup.patchValue({ couponDescription: this.data.couponDescription })
        this.formGroup.patchValue({ isApplicableOnForeignOrders: this.data.isApplicableOnForeignOrders })
        this.formGroup.patchValue({ usageLimitPerUser: this.data.usageLimitPerUser })
        this.formGroup.patchValue({ couponUsdAmount: this.data.couponUsdAmount })
        this.formGroup.patchValue({ minimumUsdOrder: this.data.minimumUsdOrder })
        this.formGroup.patchValue({ maximumUsdDiscount: this.data.maximumUsdDiscount })
        this.formGroup.patchValue({ applicableOn: this.data.applicableOn })
        this.formGroup.patchValue({ usageLimitPerCoupon: this.data.usageLimitPerCoupon })
        this.formGroup.patchValue({ startDate: moment(startdate).format('YYYY-MM-DD')})
        this.formGroup.patchValue({ endDate: moment(date).format('YYYY-MM-DD')})
        this.formGroup.patchValue({ couponType: this.data.couponType })
      },
      err => {
        this.spinner.hide()
        // this.route.navigateByUrl('/admin/mall-product/list')
      }
    )
  }

  /**
   * Update Coupon
   */
  editCouponSubmit(){
    var code = (this.formGroup.get('code')?.value).toUpperCase()
    this.formGroup.patchValue({code:code})
    this.couponService.updateCoupon(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success('Coupon Updated','Success')
        this.goBack()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

  /**
   * International Payment
   */
   
   isApplicableOnForeignOrders() {
     this.isForeignOrder = this.formGroup.get('isApplicableOnForeignOrders')?.value
     //console.log(this.formGroup.get('isApplicableOnForeignOrders')?.value)
   }
}
