import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class AstroServiceService {

  BaseUrl: string = ''
  OpenBaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string, @Inject('OPEN_BASE_URL') _openbase: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.OpenBaseUrl = _openbase
    this.Token = userdata.getData()
   }
   public updateHistory(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateastrologerorders", data,{ headers: header_object});
  }
   public getAstrologerList(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allastrologers", data,{ headers: header_object});
  }

  public addNewAstrologer(data: any){
    //console.log('data',data)
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addastrologer", data, {headers: header_object});
  }

  public updateAstrologer(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateastrologerbyadmin", data, {headers: header_object});
  }

  public getAstrologerDataById(_id: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchastrologerbyid", _id, {headers: header_object});
  }

  public blockAstrologer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/blockastrologer", data, {headers: header_object});
  }

  public rejectedAstrologersList(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/showrejectedastro", data, {headers: header_object});
  }

  public approveAstrologer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/approveastro", data, {headers: header_object});
  }

  public updateApproveAstrologer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/approveastro", data, {headers: header_object});
  }

  public rejectAstrologer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/approveastro", data, {headers: header_object});
  }

  public updateAstrologerCharges(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/chargesupdation", data, {headers: header_object});
  }

  public onlineOfflineAstrologerList(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/onlineofflineastrologers", data, {headers: header_object});
  }

  /**
   * Add Astrologer Open Api
   */
  public addAstrologerOpen(data:any){
    //console.log(this.OpenBaseUrl + "/addastrologer",data)
    return this.http.post(this.OpenBaseUrl + "/addastrologer", data);
  }

  /**
   * AstroloGer Wallet
   */
  public getAstroWalletHistory(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/astrologerwallethistory", data, {headers: header_object});
  }

  public getAstroWalletTransactionHistoryByOperation(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/transactionbyoperationastro", data, {headers: header_object});
  }

  public CreditDebitAstroWalletByAdmin(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addastrowalletadmin", data, {headers: header_object});
  }

  /**
   * Astrologer Orders
   */
  public getMonthWiseAstroReport(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/monthwiseastrologerreport", data, {headers: header_object});
  }

  public getMonthWiseAstroOrders(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/monthwiseastroorders", data, {headers: header_object});
  }

  public getCountOfOrderType(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/countordertype", data, {headers: header_object});
  }

  /**
   * Astrologer Payout
   */
   public getMonthWisePayout(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/monthwisepayout", data, {headers: header_object});
  }

   public getMonthWiseAstrologerReport(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/monthwiseastrologerreport", data, {headers: header_object});
  }

   public releasePayment(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/releasepayment", data, {headers: header_object});
  }

  /** service on off for single astroologer */

  public serviceOnOff(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/serviceonoff", data, {headers: header_object});
  }
}
