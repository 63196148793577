<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Search ID, Name, Display Name, Discount, Offeron..." class="form-control col-lg-4 mt-1"
      (keyup)="updateFilter($event)"   />

  </div>
  <br>
  <ngx-datatable class="material" [rows]="offerList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="offerId">
      <ng-template let-row="row" ngx-datatable-cell-template>
        #{{row.offerId}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Offer Name" prop="offer_name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="row.offer_name">{{row.offer_name}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Display Name" prop="offer_display_name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="offer_display_name">{{row.offer_display_name}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Discount" prop="offer_discount">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.offer_discount}}%
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="User" prop="user_type">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.user_type == 1">All</span>
        <span *ngIf="row.user_type == 2">Repeat User</span>
        <span *ngIf="row.user_type == 3">New User</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Offer On" prop="offer_on">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="text-capitalize">{{row.offer_on}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="AR Share" prop="astrorivershare_indian">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.astrorivershare_indian}}%
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- Material switch -->
            <ng-template #active>
              <mat-slide-toggle color="warn" (change)="toggle($event, row._id, !row?.status)"
                [checked]="true"></mat-slide-toggle>
            </ng-template>
            <ng-template #notActive>
              <mat-slide-toggle color="warn" (change)="toggle($event, row._id, !row?.status)"></mat-slide-toggle>
            </ng-template>
            <ng-container *ngIf="row?.status == true; then active; else notActive">
            </ng-container>
          </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <a class="btn btn-primary btn-sm" [routerLink]="['/admin/astro-offers/edit',row._id]">Edit</a>
        </ng-template>
  </ngx-datatable-column>
  </ngx-datatable>
