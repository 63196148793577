import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CouponMallService } from 'src/app/admin/services/coupon-mall/coupon-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-detail-mall-coupon',
  templateUrl: './detail-mall-coupon.component.html',
  styleUrls: ['./detail-mall-coupon.component.scss']
})
export class DetailMallCouponComponent implements OnInit {

  BaseUrl: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private couponMallService: CouponMallService,
    private userdata: UserdataService,
    private route: Router,
    private toast: ToastrService, private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  data: any = '';
  id: any = '';
  dataList = [];
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById();
  }

  getDataById() {
    this.spinner.show()
    this.couponMallService.GetSingleMallCoupon({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.dataList = res.reviews;
        this.temp = this.dataList;
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-product/list')
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

}


