<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Search Title, Category, Added By..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />
        <div class="mt-3">
             <button class="btn-filter-custom btn btn-warning btn-sm mr-2" (click)="getFeturedBlog({ isApproved:true,is_used:true,isFeaturedBlog: true })">Featured Blog</button>
            <button class="btn-filter-custom btn btn-success btn-sm mr-2" (click)="getFeturedBlog({ isApproved:true,is_used:true,isFeaturedBlog: false })">Non-Featured Blog </button>
        </div>
</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="3" name="Title" prop="blogTitle">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.blogTitle">{{row.blogTitle}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Blog Category" prop="Category Name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.categoryId?.categoryname">{{row.categoryId?.categoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Added By" prop="userName">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.userName">{{row.userName}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/blogs/view/',row._id]" class="fa fa-eye text-primary mr-1" title="View"></a>
            <a [routerLink]="['/admin/blogs/',row._id]" class="btn btn-sm btn-primary">Edit</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Set Featured">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <button *ngIf="!row.isFeaturedBlog" type="button" class="btn btn-sm btn-info" (click)="featuredBlog({_id:row._id})">Set Featured</button>
            <button *ngIf="row.isFeaturedBlog" type="button" class="btn btn-sm btn-success">Featured</button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
