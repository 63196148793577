import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlluserService } from 'src/app/admin/services/alluser/alluser.service';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { CustomerService } from 'src/app/admin/services/customer/customer.service';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit {
  currentPage = ''
  constructor(
    private usrServices: AlluserService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private astroService: AstroServiceService,
    private managerService: ManagerService,
    private route : Router,private router: ActivatedRoute
  ) { 
    this.currentPage = this.router.snapshot.url[0].path;
  }
  title = 'angular-datatables';
    originalList=new Array()
  usersList =new Array()
  id: any = '';
  getUsersData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
    searchKey = ''
  ngOnInit() {
    this.getCustomersList()
    this.usersList = this.getUsersData;
  }

  /* ***********list Users *********** */

  getCustomersList() {
    this.spinner.show()
    this.usrServices.getUsersList({ 'is_blocked': false,is_delete:false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.usersList = res.data;
        //console.log(this.usersList)
        this.originalList = this.usersList;
        this.getUsersData = this.usersList;
        let offset =
        localStorage.getItem(`allus_offset_${this.currentPage}`) || 0;
      if (offset > 0) {
        this.myFilterTable.offset = offset;
      }
      if (this.searchKey.length > 0) {
        this.searchByKey(this.searchKey);
      }
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
onPageChange(event:any){
  localStorage.setItem(`allus_offset_${this.currentPage}`,event.offset)
}
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.searchByKey(val)
  }
  searchByKey(key: string){
  if(key.length<1 || key==null || key==undefined){
    localStorage.removeItem(`allus_search_${this.currentPage}`)
  }else{
  this.usersList  = this.originalList.filter(function (d: any) {
    return d.name.toLowerCase().indexOf(key) !== -1 || !key ||
      d.phone.toString().indexOf(key) !== -1 || !key ||
      d.email.toLowerCase().indexOf(key) !== -1 || !key
  });
  localStorage.setItem(`allus_search_${this.currentPage}`,key)
}
  this.myFilterTable.offset = 0;
}
  getCustomerDataByEmail(email: string) {
    this.customerService.getCustomerDataById({email: email}).subscribe(
      (res:any) => {
        this.spinner.hide()   
        this.route.navigateByUrl('/admin/customers/viewCustomer/'+res.data._id+'/detail')
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  getAstrologerDataByEmail(email: string) {
    this.astroService.getAstrologerDataById({email: email}).subscribe(
      (res:any) => {
        this.spinner.hide()   
        this.route.navigateByUrl('/admin/astrologers/viewAstrologer/'+res.data._id)
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  getManagerDataByEmail(email: string){
    this.managerService.getManagerDataById({email:email}).subscribe(
      (res:any) => {
        this.spinner.hide()   
        this.route.navigateByUrl('/admin/manager/viewManager/'+res.data._id)
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

}
