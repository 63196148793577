<div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
    <input type="text"   placeholder="Type to filter the name column..."  class="form-control col-lg-4 mt-1" />
</div>
<br>
<ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column name="Order Id" prop="oId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.oId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Amount" prop="amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}}{{row.amount | number: '.2-2'}}({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="GST" prop="gst_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}}{{row.gst_amount | number: '.2-2'}}({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Final Amount" prop="final_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}}{{row.final_amount | number: '.2-2'}}({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Invoice" prop="invoiceUrl">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a *ngIf="row.invoiceUrl != ''" [href]=getSanitizeUrl(row.invoiceUrl) target="_blank">Invoice</a>
            <span *ngIf="row.invoiceUrl == ''" class="text-dangers">Invoice</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>