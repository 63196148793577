import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferServiceService } from 'src/app/admin/services/offers/offer-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-add-offers',
  templateUrl: './add-offers.component.html',
  styleUrls: ['./add-offers.component.scss']
})
export class AddOffersComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private offerService: OfferServiceService,
    private route: Router
  ) { }
  
  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
    offer_display_name: new FormControl('', Validators.required),
    user_type: new FormControl('', [Validators.required]),
    astrorivershare_indian: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    astrorivershare_foreign: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    offer_on: new FormControl('', Validators.required),
    offer_discount: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    offer_name: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
  }

  get f() {
    return this.formGroup.controls
  }

  addOfferSubmit(){
    this.offerService.addOffers(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/offers/all')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }

}
