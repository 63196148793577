<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Internatiional Price</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a (click)="goBack()" style="    cursor: pointer;">Internatiional Price</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updatePrice()">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="countryName">Country Name</label>
                                        <input type="text" class="form-control" [value]="data.countryName" readonly/>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="countryCode">Country Code</label>
                                        <input type="text" class="form-control" readonly="readonly" formControlName="countryCode"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group">
                                        <label for="currencySymbol">Currency Symbol</label>
                                        <input type="text" class="form-control"formControlName="currencySymbol"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                  <div class="form-group">
                                      <label for="currencySymbol">Currency Code</label>
                                      <input type="text" class="form-control"  formControlName="currencyCode" />
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label for="currencyUnitInRupees">Currency Value In Rupees</label>
                                        <input type="text" class="form-control" formControlName="currencyUnitInRupees"/>
                                        <small
                                            *ngIf="f.currencyUnitInRupees.invalid && (f.currencyUnitInRupees.dirty || f.currencyUnitInRupees.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Only Numbers
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group">
                                        <label for="callPricePercent">Call Price Change (%)</label>
                                        <input type="text" class="form-control" formControlName="callPricePercent"/>
                                        <small
                                            *ngIf="f.callPricePercent.invalid && (f.callPricePercent.dirty || f.callPricePercent.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Only Numbers
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group">
                                        <label for="chatPricePercent">Chat Price Change (%)</label>
                                        <input type="text" class="form-control" formControlName="chatPricePercent"/>
                                        <small
                                            *ngIf="f.chatPricePercent.invalid && (f.chatPricePercent.dirty || f.chatPricePercent.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Only Numbers
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group">
                                        <label for="queryPricePercent">Query Price Change (%)</label>
                                        <input type="text" class="form-control" formControlName="queryPricePercent"/>
                                        <small
                                            *ngIf="f.queryPricePercent.invalid && (f.queryPricePercent.dirty || f.queryPricePercent.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Only Numbers
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group">
                                        <label for="reportPricePercent">Report Price Change (%)</label>
                                        <input type="text" class="form-control" formControlName="reportPricePercent"/>
                                        <small
                                            *ngIf="f.reportPricePercent.invalid && (f.reportPricePercent.dirty || f.reportPricePercent.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required. Only Numbers
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <button type="submit" class="btn-form-custom col-4 btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
