import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InternationalServiceService } from 'src/app/admin/services/international-service/international-service.service';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';

@Component({
  selector: 'app-customer-wallet-recharge-order',
  templateUrl: './customer-wallet-recharge-order.component.html',
  styleUrls: ['./customer-wallet-recharge-order.component.scss']
})
export class CustomerWalletRechargeOrderComponent implements OnInit {
  BaseUrl = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl
  }
  data: any = '';
  id: any = '';

  ngOnInit(): void {
    //this.id = this.router.snapshot.paramMap.get('id')
    this.router.parent?.params.subscribe(
      (params) => {
        this.id = params.data
        this.getCustomerWalletRecharge()
      });
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  getCustomerWalletRecharge() {
    this.spinner.show()
    this.orderService.getAllWalletRechargeOrders({ customerId: this.id, is_recharge_order: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(res.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  
}
