<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Skills</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/skills']">Skills</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- <div class="user-wrap">
                        <button type="submit()" class="btn btn-primary mt-1 mb-2"><i class="fa fa-rss"></i> Add Skills</button>
                    </div> -->
                    <div>
                        <form [formGroup]="updateSkillsForm" (submit)="onUpdateSkills()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="Skills">Skills</label>
                                    <input type="text" class="form-control" name="skillname" formControlName="skillname" 
                                        placeholder="New Skills" required>
                                        <div *ngIf="f.skillname.invalid && (f.skillname.dirty || f.skillname.touched)" class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                </div>
                                <div class="user-wrap d-flex justify-content-center">
                                    <button type="submit()" class="btn-form-custom col-4 btn btn-success mt-1 mb-2" [disabled]="!updateSkillsForm.valid"> Update Skill</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
