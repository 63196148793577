<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->
    <form [formGroup]="formGroup" (submit)="addCouponSubmit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Coupon</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="code">Code</label>
                                    <input type="text" class="form-control text-uppercase" form placeholder="Code" formControlName="code">
                                    <div *ngIf="f.code.invalid && (f.code.dirty || f.code.touched)"
                                        class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="form-group">
                                  <label for="addedBy">Added By *</label>
                                  <select placeholder="Select Added By" class="form-control" formControlName="addedBy">
                                    <option selected disabled value="">Select Added By</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Manager">Manager</option>
                                  </select>
                                  <small *ngIf="f.addedBy.invalid && (f.addedBy.dirty || f.addedBy.touched)" class="d-block alert alert-danger mt-1">
                                    Required
                                  </small>
                                </div>
                              </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="form-group">
                                  <label for="couponType">Coupon Type *</label>
                                  <select placeholder="Select Coupon Type" class="form-control"
                                    formControlName="couponType">
                                    <option selected disabled value=''>Select Coupon Type</option>
                                    <option value="2">
                                      Fixed
                                    </option>
                                    <option value="1">
                                      Percentage
                                    </option>
                                  </select>
                                  <small *ngIf="f.couponType.invalid && (f.couponType.dirty || f.couponType.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required
                                  </small>
                                </div>
                              </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="amount">{{formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 2 || formGroup?.value?.couponType == '2' ? 'Amount':'Percentage'}} *</label>
                                    <input type="text" class="form-control" [placeholder]="formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 2 || formGroup?.value?.couponType == '2' ? 'Amount':'Percentage'"
                                        formControlName="amount">
                                    <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="minimumAmount">Minimum Amount *</label>
                                    <input type="text" class="form-control" placeholder="Minimum Amount"
                                        formControlName="minimumAmount">
                                    <div *ngIf="f.minimumAmount.invalid && (f.minimumAmount.dirty || f.minimumAmount.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="maximumDiscount">Maximum Discount *</label>
                                    <input type="text" class="form-control" placeholder="Maximum Amount"
                                        formControlName="maximumDiscount">
                                    <div *ngIf="f.maximumDiscount.invalid && (f.maximumDiscount.dirty || f.maximumDiscount.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="couponDescription">Description *</label>
                                    <input type="text" class="form-control" id="couponDescription"
                                        placeholder="Description" formControlName="couponDescription">
                                    <div *ngIf="f.couponDescription.invalid && (f.couponDescription.dirty || f.couponDescription.touched)"
                                        class="alert alert-danger mt-1">
                                        Required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="usageLimitPerUser">Usage Limit Per User *</label>
                                    <input type="text" class="form-control" placeholder="Usage Limit Per User"
                                        formControlName="usageLimitPerUser">
                                    <div *ngIf="f.usageLimitPerUser.invalid && (f.usageLimitPerUser.dirty || f.usageLimitPerUser.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="usageLimitPerCoupon">Usage Limit Per Coupon *</label>
                                    <input type="text" class="form-control" placeholder="Usage Limit Per Coupon"
                                        formControlName="usageLimitPerCoupon">
                                    <div *ngIf="f.usageLimitPerCoupon.invalid && (f.usageLimitPerCoupon.dirty || f.usageLimitPerCoupon.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="form-group">
                                  <label for="applicableOn">Applicable On *</label>
                                  <select placeholder="Select applicable on" class="form-control"
                                    formControlName="applicableOn">
                                    <option selected disabled value=''>Applicable On</option>
                                    <option value="1">
                                      New User
                                    </option>
                                    <option value="2">
                                      All User
                                    </option>
                                  </select>
                                 <small *ngIf="f.applicableOn.invalid && (f.applicableOn.dirty || f.applicableOn.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required
                                  </small>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="form-group">
                                  <label for="startDate">Start Date *</label>
                                  <input type="date" class="form-control" formControlName="startDate" [min]="todayDate">
                                  <small *ngIf="f.startDate.invalid && (f.startDate.dirty || f.startDate.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required
                                  </small>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="form-group">
                                  <label for="endDate">End Date *</label>
                                  <input type="date" class="form-control" formControlName="endDate" [min]="todayDate">
                                  <small *ngIf="f.endDate.invalid && (f.endDate.dirty || f.endDate.touched)"
                                    class="d-block alert alert-danger mt-1">
                                    Required
                                  </small>
                                </div>
                              </div>

                            <!-- <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="percentge">Offer Amount (%)</label>
                                    <input type="text" class="form-control" id="percentge" placeholder="Offer Amount"
                                        formControlName="percentge">
                                    <div *ngIf="f.percentge.invalid && (f.percentge.dirty || f.percentge.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div> -->
                        </div>
                       
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3">
                              <div class="form-group">
                                <label for="isApplicableOnForeignOrders">Is Applicable on Foreign Orders? </label>
                                <input type="checkbox" class="ml-2" formControlName="isApplicableOnForeignOrders" (change)="isApplicableOnForeignOrders()">
                                <!-- <small *ngIf="f.isApplicableOnForeignOrders.invalid && (f.isApplicableOnForeignOrders.dirty || f.isApplicableOnForeignOrders.touched)"
                                  class="d-block alert alert-danger mt-1">
                                  Required
                                </small> -->
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isForeignOrder">
                              <div class="form-group">
                                <label for="couponUsdAmount">{{formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 2 || formGroup?.value?.couponType == '2' ? 'Coupon Amount (USD)':'Percentage'}}*</label>
                                <input type="text" class="form-control" [placeholder]="formGroup?.value?.couponType == '' || formGroup?.value?.couponType == 2 || formGroup?.value?.couponType == '2' ? 'Coupon Amount (USD)':'Percentage'" formControlName="couponUsdAmount">
                                <small *ngIf="f.couponUsdAmount.invalid && (f.couponUsdAmount.dirty || f.couponUsdAmount.touched)" class="d-block alert alert-danger mt-1">
                                  Required
                                </small>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isForeignOrder">
                              <div class="form-group">
                                <label for="minimumUsdOrder">Minimum Order (USD) *</label>
                                <input type="text" class="form-control" placeholder="Minimum Order (USD)" formControlName="minimumUsdOrder">
                                <small *ngIf="f.minimumUsdOrder.invalid && (f.minimumUsdOrder.dirty || f.minimumUsdOrder.touched)" class="d-block alert alert-danger mt-1">
                                  Required
                                </small>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="isForeignOrder">
                              <div class="form-group">
                                <label for="maximumUsdDiscount">Maximum Discount (USD) *</label>
                                <input type="text" class="form-control" placeholder="Maximum Order (USD)" formControlName="maximumUsdDiscount">
                                <small *ngIf="f.maximumUsdDiscount.invalid && (f.maximumUsdDiscount.dirty || f.maximumUsdDiscount.touched)"
                                  class="d-block alert alert-danger mt-1">
                                  Required
                                </small>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1" [disabled]="!formGroup.valid">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->