<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->
    <form [formGroup]="formGroup" (submit)="addBlogSubmit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Blog Category</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="categoryname">Name</label>
                                    <input type="text" class="form-control" form placeholder="Blog Category Name"
                                        formControlName="categoryname">
                                    <small
                                        *ngIf="f.categoryname.invalid && (f.categoryname.dirty || f.categoryname.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="categorydescription">Meta Description</label>
                                    <input type="text" class="form-control" form placeholder="Description"
                                        formControlName="categorydescription">
                                    <small
                                        *ngIf="f.categorydescription.invalid && (f.categorydescription.dirty || f.categorydescription.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="category_slug">Slug</label>
                                    <input type="text" class="form-control" id="category_slug"
                                        placeholder="Eg: best-way, best" formControlName="category_slug" (change)="checkSlugUnique($event)">
                                    <small
                                        *ngIf="f.category_slug.invalid && (f.category_slug.dirty || f.category_slug.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Slug can only contain '-' and no space or any special symbol.
                                    </small>
                                    <small
                                        *ngIf="!isUnique"
                                        class="d-block alert alert-danger mt-1">
                                        Slug should be unique and this slug has been already used.
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="category_keyword">Meta Keywords</label>
                                    <input type="text" class="form-control" id="category_keyword"
                                        placeholder="Separate Keywords by ','" formControlName="category_keyword">
                                    <small
                                        *ngIf="f.category_keyword.invalid && (f.category_keyword.dirty || f.category_keyword.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1" [disabled]="!formGroup.valid || !isUnique">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->