<div class="pr-2 pl-2 d-flex justify-content-between">
  <input
    value="{{ searchKey }}"
    type="text"
    placeholder="Type to filter the name column..."
    class="form-control col-lg-4 mt-1"
    (keyup)="updateFilter($event)"
  />
</div>
<br />
<ngx-datatable
  (page)="onPageChange($event)"
  class="material"
  [rows]="dataList"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [limit]="10"
  [sorts]="[{ dir: 'desc' }]"
  style="position: relative !important"
>
  <ngx-datatable-column  [width]="80" name="Image" prop="notification_image">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <img *ngIf="row.notification_image != ''" [src]="getSanitizeUrl(row.notification_image)" class="w-100" />
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [width]="80" name="Title" prop="notification_title">
    <ng-template let-row="row" ngx-datatable-cell-template>
    {{ row.notification_title }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Description"
    prop="notification_description"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
     {{ row.notification_description }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Link"
    prop="notification_link"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
     <a href="{{row.notification_link}}" target="_blank">{{ row.notification_link }}</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column  [width]="100" name="Sent To">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.is_customer">{{ row.customerId?.name }}</span>
      <span *ngIf="row.is_astrologer" [title]="row.astroId?.name">{{
        row.astroId?.displayname == "" ||
        row.astroId?.displayname == undefined ||
        row.astroId?.displayname == null
          ? row.astroId?.name
          : row.astroId?.displayname
      }}</span>
      <span *ngIf="row.is_all_astrologers">All Astrologer</span>
      <span *ngIf="row.is_all_customers">All Customer</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [width]="100" name="Type">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.is_service_involve"
        >Order ID -{{ row.orderserviceId?.oserviceId }}</span
      >
      <span *ngIf="row.is_cwallet_involve">Wallet ID -{{ row.cwalletId?.cwalletId }}</span>
      <span *ngIf="row.is_awallet_involve">Wallet ID -{{ row.awalletId?.awalletId }}</span>
      <span
        *ngIf="
          !row.is_service_involve &&
          !row.is_cwallet_involve &&
          !row.is_awallet_involve
        "
        >General</span
      >
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column  [width]="100" name="Date" prop="created_at">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.created_at | date: "medium" }}
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
