import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isShow=false;
  loginUserName: any = '';
  loginUserEmail: any = '';
  BaseUrl = '';
  isAdmin:any = 'false'
  userId:any = ''
  profileImage:any = ''
  constructor(
    private userdata: UserdataService,
    private route: Router,
    private manager: ManagerService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  ngOnInit(): void {
    this.loginUserName = this.userdata.getName();
    this.loginUserEmail = this.userdata.getEmail();
    this.userId = this.userdata.getId()
    this.isAdmin = this.userdata.getIsAdmin()
    if(this.isAdmin == 'false'){
      this.profileImage = this.userdata.getProfileImage()
    }
  }

  public onLogout(){
    //console.log('working')
    this.userdata.removeData();
    // this.userdata.removeEmail();
    // this.userdata.removeId();
    // this.userdata.removeName();
    this.route.navigateByUrl('/')
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

}
