<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- ROW-1 OPEN -->
    <form [formGroup]="formGroup" (submit)="addReviewSubmit()">
        <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Review</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="astroId">Astrologer</label>
                                    <ng-select placeholder="Select Astrologer" appendTo="body"
                                            [searchable]="true" [clearable]="true" formControlName="astroId">
                                            <ng-option selected disabled value=''>Select Astrologer</ng-option>
                                            <ng-option [value]="astro._id" *ngFor="let astro of astroList">
                                                #{{astro.astroAutoId}} - {{astro.name}}
                                            </ng-option>
                                        </ng-select>
                                    <div *ngIf="f.astroId.invalid && (f.astroId.dirty || f.astroId.touched)"
                                        class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="customerName">Customer Name</label>
                                    <input type="text" class="form-control" placeholder="Customer Name"
                                        formControlName="customerName">
                                    <div *ngIf="f.customerName.invalid && (f.customerName.dirty || f.customerName.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label for="customer_review_stars">Review Stars</label>
                                    <select class="form-control" id="customer_review_stars" formControlName="customer_review_stars">
                                        <option selected disabled value="">Select Stars</option>
                                        <option>1</option>
                                        <option>1.5</option>
                                        <option>2</option>
                                        <option>2.5</option>
                                        <option>3</option>
                                        <option>3.5</option>
                                        <option>4</option>
                                        <option>4.5</option>
                                        <option>5</option>
                                    </select>
                                    <div *ngIf="f.customer_review_stars.invalid && (f.customer_review_stars.dirty || f.customer_review_stars.touched)"
                                        class="alert alert-danger mt-1">
                                        Required. Only Number Allowed.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="customer_review">Review</label>
                                    <input type="Description" class="form-control" id="customer_review"
                                        placeholder="Description" formControlName="customer_review">
                                    <div *ngIf="f.customer_review.invalid && (f.customer_review.dirty || f.customer_review.touched)"
                                        class="alert alert-danger mt-1">
                                        Required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success mt-1" [disabled]="!formGroup.valid">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ROW-1 CLOSED -->


</div>
<!--CONTAINER CLOSED -->