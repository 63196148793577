<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Astrologer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item" aria-current="page"><a (click)="onBackPressed()">Astrologer List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Astrologer</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <ng-template #active>
        <span class="badge badge-success  mr-1">YES</span>
    </ng-template>
    <ng-template #Inactive>
        <span class="badge badge-danger  mr-1">No</span>
    </ng-template>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="wideget-user">
                        <div class="row">
                            <div class="col-md-4 row border-right">
                                    <div class="col-4">
                                        <img class="rounded-circle border p-0" style="height: 60%;" [src]=getSanitizeUrl(data.profile_image) alt="Astro Image">
                                    </div>
                                    <div class="col-8">
                                        <h4>{{(data.displayname == '' || data.displayname == undefined || data.displayname == null ) ? data.name : data.displayname}} <span *ngIf="!data.is_current_active" class="badge badge-danger ml-2">OFFLINE</span><span *ngIf="data.is_current_active" class="badge badge-success ml-2">ONLINE</span> <button class="btn btn-primary btn-sm ml-3"
                                                [routerLink]="['/admin/astrologer/edit',data._id]">Edit</button></h4>
                                        <h5 class="text-muted mb-3 d-inline-block mr-2">Exclusive Member</h5>
                                        <ng-container *ngIf="data.is_exclusive === true; then active; else Inactive">
                                        </ng-container>
                                        <h5 class="mt-3"> <i class="fe fe-dollar"></i>Wallet Balance - {{data.current_wallet | currency:'INR':'symbol-narrow'}}</h5>
                                        <p><span *ngIf="data.is_blocked" class="badge badge-danger mr-2">BLOCKED</span><span *ngIf="data.is_delete" class="badge badge-danger mr-2">DELETED</span><span *ngIf="data.is_approved" class="badge badge-success mr-2">APPROVED</span><span *ngIf="!data.is_approved && data.is_approved_used" class="badge badge-danger mr-2">REJECTED</span></p>
                                    </div>
                            </div>
                            <div class="col-md-4 border-right">
                                <h4 class="pl-3">Allowed By Admin</h4>
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <th class="text-right">Call</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_call_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_call_allow">ON</span></td>
                                            <th class="text-right">Chat</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_chat_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_chat_allow">ON</span></td>
                                        </tr>
                                        <!-- <tr>
                                            <th class="text-right">Chat</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_chat_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_chat_allow">ON</span></td>
                                        </tr> -->
                                        <tr>
                                            <th class="text-right">Query</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_query_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_query_allow">ON</span></td>
                                            <th class="text-right">Report</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_report_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_report_allow">ON</span></td>
                                        </tr>
                                        <!-- <tr>
                                            <th class="text-right">Report</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_report_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_report_allow">ON</span></td>
                                        </tr> -->
                                        <tr>
                                            <th class="text-right">Exclusive</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_exclusive">OFF</span><span class="badge badge-success" *ngIf="data.is_exclusive">ON</span></td>
                                            <th class="text-right">Promotional Call</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_promotional_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_promotional_allow">ON</span></td>
                                        </tr>
                                        <!-- <tr>
                                            <th class="text-right">Promotional Call</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_promotional_allow">OFF</span><span class="badge badge-success" *ngIf="data.is_promotional_allow">ON</span></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-4">
                                <h4 class="pl-3">Active/Inactive Status</h4>
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                      <!-- ---------------------------------------------------- -->
                                      <!-- current service active inactive status in badges view -->
                                        <!-- <tr>
                                            <th class="text-right">Call</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_call_active">OFF</span><span class="badge badge-success" *ngIf="data.is_call_active">ON</span></td>
                                            <th class="text-right">Chat</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_chat_active">OFF</span><span class="badge badge-success" *ngIf="data.is_chat_active">ON</span></td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Query</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_query_active">OFF</span><span class="badge badge-success" *ngIf="data.is_query_active">ON</span></td>
                                            <th class="text-right">Report</th>
                                            <td><span class="badge badge-primary" *ngIf="!data.is_report_active">OFF</span><span class="badge badge-success" *ngIf="data.is_report_active">ON</span></td>
                                        </tr> -->
                                        <!-- ---------------------------------------------------- -->
                                      <!-- current service active inactive status in badges view end -->

                                      <!-- -------------------update view with toggle button along with functionality------------- -->
                                      <!-- call active check -->
                                      <ng-template #call_active>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="callActiveStatus($event)" [checked]="true" [disabled]="data.call_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <ng-template #call_inactive>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="callActiveStatus($event)" [checked]="false" [disabled]="data.call_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <!-- chat active check -->
                                    <ng-template #chat_active>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="chatActiveStatus($event)" [checked]="true" [disabled]="data.chat_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <ng-template #chat_inactive>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="chatActiveStatus($event)" [checked]="false" [disabled]="data.chat_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <!-- query active check -->
                                    <ng-template #query_active>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="queryActiveStatus($event)" [checked]="true" [disabled]="data.query_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <ng-template #query_inactive>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="queryActiveStatus($event)" [checked]="false" [disabled]="data.query_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <!-- report active check -->
                                    <ng-template #report_active>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="reportActiveStatus($event)" [checked]="true" [disabled]="data.report_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <ng-template #report_inactive>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="reportActiveStatus($event)" [checked]="false" [disabled]="data.report_charges <=0 ? true : null"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <!-- report active check -->
                                    <ng-template #promo_active>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="promoActiveStatus($event)" [checked]="true" [disabled]="!data.is_approved || data.is_blocked"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                    <ng-template #promo_inactive>
                                        <div>
                                            <mat-slide-toggle color="success" (change)="promoActiveStatus($event)" [checked]="false" [disabled]="!data.is_approved || data.is_blocked"></mat-slide-toggle>
                                        </div>
                                    </ng-template>
                                      <tr>
                                        <th>Call</th>
                                        <td>
                                          <ng-container *ngIf="data?.is_call_active === true; then call_active; else call_inactive">
                                          </ng-container>
                                          <!-- <span *ngIf="data.call_charges <= 0" class="text-danger">Update Call Charges First.</span> -->
                                        </td>
                                        <th>Chat</th>
                                        <td>
                                          <ng-container *ngIf="data?.is_chat_active === true; then chat_active; else chat_inactive">
                                          </ng-container>
                                          <!-- <span *ngIf="data.chat_charges <= 0" class="text-danger">Update Chat Charges First.</span> -->
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Query</th>
                                        <td>
                                          <ng-container *ngIf="data?.is_query_active === true; then query_active; else query_inactive">
                                          </ng-container>
                                          <!-- <span *ngIf="data.query_charges <= 0" class="text-danger">Update Query Charges First.</span> -->
                                        </td>
                                        <th>Report</th>
                                        <td>
                                          <ng-container *ngIf="data?.is_report_active === true; then report_active; else report_inactive">
                                          </ng-container>
                                          <!-- <span *ngIf="data.report_charges <= 0" class="text-danger">Update Report Charges First.</span> -->
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Promotions</th>
                                        <td>
                                          <ng-container *ngIf="data?.is_promotional_active === true; then promo_active; else promo_inactive">
                                          </ng-container>
                                        </td>
                                      </tr>

                                      <!-- -------------------update view with toggle button along with functionality end------------- -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>Profile</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div id="profile-log-switch">
                                                            <div class="media-heading">
                                                                <h5 class="text-uppercase"><strong>Personal Information</strong></h5>
                                                            </div>
                                                            <hr class="m-0">
                                                            <div class="table-responsive ">
                                                                <table class="table row table-borderless">
                                                                    <tbody class="col-lg-6 col-md-6 col-xl-6 p-0">
                                                                        <tr>
                                                                            <td><strong>Display Name :</strong> {{data.displayname}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Full Name :</strong> {{data.name}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Languages :</strong><span *ngFor="let language of data.lang" class="text-capitalize"> {{language?.langId?.name}},</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Skills :</strong><span *ngFor="let skill of data.skill" class="text-capitalize"> {{skill?.skillId?.skillname}},</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Date Of Birth :</strong> {{data.birth_date}}-{{data.birth_month}}-{{data.birth_year}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Gender :</strong> {{data.gender}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Email :</strong> {{data.email}} <span class="badge badge-primary" *ngIf="data?.is_social_login">{{data?.social_type == 0 ? 'Google':'Facebook'}}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Phone :</strong> {{data.country_code}}-{{data.phone}} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="d-flex">
                                                                                <strong>Working With Other : </strong>
                                                                                <ng-container *ngIf="data.working_with_other === true; then active; else Inactive">
                                                                                </ng-container>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Registered At :</strong> {{data.created_at | date:'medium'}} </td>
                                                                        </tr>

                                                                    </tbody>
                                                                    <tbody class="col-lg-6 col-md-6 col-xl-6 p-0">
                                                                        <tr>
                                                                            <td><strong>Promotional Call to take :</strong> {{data?.promocall_totake}} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Alternative Number :</strong> +{{data?.alternate_num}} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Consultant Type :</strong> {{data?.consultant_type}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Experience :</strong> {{data?.experience}} Years</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Complete Address :</strong> {{data?.complete_address}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>City :</strong> {{data?.city}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>State :</strong> {{data?.state}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Country :</strong> {{data?.country}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="d-flex"><strong>Status :</strong><span *ngIf="data.is_blocked" class="badge badge-danger mr-2">BLOCKED</span><span *ngIf="data.is_delete" class="badge badge-danger mr-2">DELETED</span><span *ngIf="data.is_approved" class="badge badge-success mr-2">APPROVED</span><span *ngIf="!data.is_approved && data.is_approved_used" class="badge badge-danger mr-2">REJECTED</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr *ngIf="data.working_with_other === true">
                                                                            <td><strong>Employment Details :</strong> {{data?.working_company}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="d-flex"><strong>Joined On :</strong> <span *ngIf="data.created_at < data.approvedDate">{{data?.approvedDate | date:'medium'}} </span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="col-lg-12 p-0" *ngIf="data.is_approved_used">
                                                                        <tr *ngIf="data.is_approved_used">
                                                                            <td><strong>Admin Comment :</strong> {{data?.approvedComment}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="media-heading">
                                                                <h5 class="text-uppercase"><strong>Proof Image</strong></h5>
                                                            </div>
                                                            <hr class="m-0">
                                                            <div class="table-responsive ">
                                                                <table class="table row table-borderless">
                                                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                                                        <tr>
                                                                            <td><img class="" height="200px" [src]=getSanitizeUrl(data.proof_image) alt="Astro Image"></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="media-heading">
                                                                <h5 class="text-uppercase"><strong>Bank Details</strong></h5>
                                                            </div>
                                                            <hr class="m-0">
                                                            <div class="table-responsive ">
                                                                <table class="table row table-borderless">
                                                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                                                        <tr>
                                                                            <td><strong>Account Holder Name :</strong> {{data?.account_holder_name}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Bank Account Number :</strong> {{data?.bank_account_num}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Account Type :</strong> {{data?.account_type}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>IFSC :</strong> {{data?.ifsc}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="col-lg-12 col-xl-6 p-0">
                                                                        <tr>
                                                                            <td><strong>Addhar Number :</strong> {{data?.addhar_number}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Pan Number:</strong> {{data?.pan_number}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><strong>Phone :</strong> +{{data?.phone}} </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="row profie-img">
                                                                <div class="col-md-12">
                                                                    <div class="media-heading">
                                                                        <h5 class="text-uppercase"><strong>Short Biography</strong></h5>
                                                                    </div>
                                                                    <hr class="m-0 mb-3">
                                                                    <p>{{data?.short_bio}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row profie-img">
                                                                <div class="col-md-12">
                                                                    <div class="media-heading">
                                                                        <h5 class="text-uppercase"><strong>Biography</strong></h5>
                                                                    </div>
                                                                    <hr class="m-0 mb-3">
                                                                    <p>{{data?.long_bio}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="!data.is_approved_used || (data.is_approved_used && data.is_approved)">
                                        <a ngbNavLink>Approve/Reject</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <!-- -------on off service --------------------------- -->
                                            <!---<div class="card">
                                              <div class="card-body">
                                                  <div class="border-0">
                                                      <div class="wideget-user-followers mb-0">
                                                          <form [formGroup]="approveAstrologerForm" (submit)="onApproveAstrologerSubmit()">
                                                              <div class="row">
                                                                  <div class="col-xl-3 col-lg-4 col-md-6">
                                                                      <div class="card">
                                                                          <div class="card-body">
                                                                              <div class="media  m-0 mt-0">

                                                                                  <div class="media-body d-flex">

                                                                                      <ng-template #call_active>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="callActiveStatus($event)" [checked]="true" [disabled]="data.call_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <ng-template #call_inactive>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="callActiveStatus($event)" [checked]="false" [disabled]="data.call_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>

                                                                                      <ng-template #chat_active>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="chatActiveStatus($event)" [checked]="true" [disabled]="data.chat_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <ng-template #chat_inactive>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="chatActiveStatus($event)" [checked]="false" [disabled]="data.chat_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>

                                                                                      <ng-template #query_active>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="queryActiveStatus($event)" [checked]="true" [disabled]="data.query_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <ng-template #query_inactive>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="queryActiveStatus($event)" [checked]="false" [disabled]="data.query_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>

                                                                                      <ng-template #report_active>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="reportActiveStatus($event)" [checked]="true" [disabled]="data.report_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <ng-template #report_inactive>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="reportActiveStatus($event)" [checked]="false" [disabled]="data.report_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <ng-template #promo_active>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="promoActiveStatus($event)" [checked]="true" [disabled]="data.promo_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <ng-template #promo_inactive>
                                                                                          <div>
                                                                                              <mat-slide-toggle color="success" (change)="promoActiveStatus($event)" [checked]="false" [disabled]="data.promo_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                          </div>
                                                                                      </ng-template>
                                                                                      <div class="media-body">
                                                                                          <p class="text-muted mb-0">Call Active</p>
                                                                                      </div>
                                                                                      <ng-container *ngIf="data?.is_call_active === true; then call_active; else call_inactive">
                                                                                      </ng-container>


                                                                                  </div>
                                                                              </div>
                                                                              <span *ngIf="data.call_charges <= 0" class="text-danger">Update Call Charges First.</span>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div class="col-xl-3 col-lg-4 col-md-6">
                                                                      <div class="card">
                                                                          <div class="card-body">
                                                                              <div class="media m-0 mt-0">
                                                                                  <div class="media-body">
                                                                                      <p class="text-muted mb-0">Chat Active</p>
                                                                                  </div>
                                                                                  <ng-container *ngIf="data?.is_chat_active === true; then chat_active; else chat_inactive">
                                                                                  </ng-container>

                                                                              </div>
                                                                              <span *ngIf="data.chat_charges <= 0" class="text-danger">Update Chat Charges First.</span>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div class="col-xl-3 col-lg-4 col-md-6">
                                                                      <div class="card">
                                                                          <div class="card-body">
                                                                              <div class="media m-0 mt-0">
                                                                                  <div class="media-body">
                                                                                      <p class="text-muted mb-0">Query Active</p>
                                                                                  </div>
                                                                                  <ng-container *ngIf="data?.is_query_active === true; then query_active; else query_inactive">
                                                                                  </ng-container>
                                                                              </div>
                                                                              <span *ngIf="data.query_charges <= 0" class="text-danger">Update Query Charges First.</span>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div class="col-xl-3 col-lg-4 col-md-6">
                                                                      <div class="card">
                                                                          <div class="card-body">
                                                                              <div class="media m-0 mt-0">
                                                                                  <div class="media-body">
                                                                                      <p class="text-muted mb-0">Report Active</p>
                                                                                  </div>
                                                                                  <ng-container *ngIf="data?.is_report_active === true; then report_active; else report_inactive">
                                                                                  </ng-container>
                                                                              </div>
                                                                              <span *ngIf="data.report_charges <= 0" class="text-danger">Update Report Charges First.</span>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div class="col-xl-3 col-lg-4 col-md-6">
                                                                      <div class="card">
                                                                          <div class="card-body">
                                                                              <div class="media m-0 mt-0">
                                                                                  <div class="media-body">
                                                                                      <p class="text-muted mb-0">Promo Active</p>
                                                                                  </div>
                                                                                  <ng-container *ngIf="data?.is_promo_active === true; then promo_active; else promo_inactive">
                                                                                  </ng-container>
                                                                              </div>
                                                                              <span *ngIf="data.report_charges <= 0" class="text-danger">Update Report Charges First.</span>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </form>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div> -->
                                            <!-- ----------on off service end--------------------- -->
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div class="wideget-user-followers mb-0">
                                                            <form [formGroup]="approveAstrologerForm" (submit)="onApproveAstrologerSubmit()">
                                                                <div class="row">
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media  m-0 mt-0">
                                                                                    <div class="media-body d-flex">

                                                                                        <ng-template #allowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" [checked]="true"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #notAllowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" [checked]="false"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- call allow check -->
                                                                                        <ng-template #call_allowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="callStatus($event)" [checked]="true" [disabled]="data.call_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #call_notAllowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="callStatus($event)" [checked]="false" [disabled]="data.call_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- chat allow check -->
                                                                                        <ng-template #chat_allowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="chatStatus($event)" [checked]="true" [disabled]="data.chat_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #chat_notAllowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="chatStatus($event)" [checked]="false" [disabled]="data.chat_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- query allow check -->
                                                                                        <ng-template #query_allowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="queryStatus($event)" [checked]="true" [disabled]="data.query_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #query_notAllowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="queryStatus($event)" [checked]="false" [disabled]="data.query_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- report allow check -->
                                                                                        <ng-template #report_allowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="reportStatus($event)" [checked]="true" [disabled]="data.report_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #report_notAllowed>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="reportStatus($event)" [checked]="false" [disabled]="data.report_charges <=0 ? true : null"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- Is exclusive check -->
                                                                                        <ng-template #yes_exclusive>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="exclusiveStatus($event)" [checked]="true"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #not_exclusive>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="exclusiveStatus($event)" [checked]="false"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- Is approved check -->
                                                                                        <ng-template #yes_approved>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" [checked]="true"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #not_approved>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" [checked]="false"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <!-- Is Promotional check -->
                                                                                        <ng-template #yes_promotional>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="promotionalStatus($event)" [checked]="true"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #not_promotional>
                                                                                            <div>
                                                                                                <mat-slide-toggle color="success" (change)="promotionalStatus($event)" [checked]="false"></mat-slide-toggle>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <div class="media-body">
                                                                                            <p class="text-muted mb-0">Call Allow</p>
                                                                                        </div>
                                                                                        <ng-container formControlName="is_call_allow" *ngIf="data?.is_call_allow === true; then call_allowed; else call_notAllowed">
                                                                                        </ng-container>
                                                                                        <!-- <mat-slide-toggle color="success" (change)="approveAstrologer($event)" *ngIf="data?.is_call_allow === true; then allowed; else notAllowed" [checked]="false"></mat-slide-toggle> -->

                                                                                    </div>
                                                                                </div>
                                                                                <span *ngIf="data.call_charges <= 0" class="text-danger">Update Call Charges First.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media m-0 mt-0">
                                                                                    <div class="media-body">
                                                                                        <p class="text-muted mb-0">Chat Allow</p>
                                                                                    </div>
                                                                                    <ng-container formControlName="is_chat_allow" *ngIf="data?.is_chat_allow === true; then chat_allowed; else chat_notAllowed">
                                                                                    </ng-container>

                                                                                </div>
                                                                                <span *ngIf="data.chat_charges <= 0" class="text-danger">Update Chat Charges First.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media m-0 mt-0">
                                                                                    <div class="media-body">
                                                                                        <p class="text-muted mb-0">Query Allow</p>
                                                                                    </div>
                                                                                    <ng-container formControlName="is_query_allow" *ngIf="data?.is_query_allow === true; then query_allowed; else query_notAllowed">
                                                                                    </ng-container>
                                                                                </div>
                                                                                <span *ngIf="data.query_charges <= 0" class="text-danger">Update Query Charges First.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media m-0 mt-0">
                                                                                    <div class="media-body">
                                                                                        <p class="text-muted mb-0">Report Allow</p>
                                                                                    </div>
                                                                                    <ng-container formControlName="is_report_allow" *ngIf="data?.is_report_allow === true; then report_allowed; else report_notAllowed">
                                                                                    </ng-container>
                                                                                </div>
                                                                                <span *ngIf="data.report_charges <= 0" class="text-danger">Update Report Charges First.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media m-0 mt-0">
                                                                                    <div class="media-body">
                                                                                        <p class="text-muted mb-0">Exclusive</p>
                                                                                    </div>
                                                                                    <ng-container formControlName="is_exclusive" *ngIf="data?.is_exclusive === true; then yes_exclusive; else not_exclusive">
                                                                                    </ng-container>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media m-0 mt-0">
                                                                                    <div class="media-body">
                                                                                        <p class="text-muted mb-0">Promotional Calls</p>
                                                                                    </div>
                                                                                    <ng-container formControlName="is_promotional_allow" *ngIf="data?.is_promotional_allow === true; then yes_promotional; else not_promotional">
                                                                                    </ng-container>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-xl-3 col-lg-4 col-md-6">
                                                                    <div class="card">
                                                                        <div class="card-body">
                                                                            <div class="media m-0 mt-0">
                                                                                <div class="media-body">
                                                                                    <p class="text-muted mb-0"> Call Active</p>
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="data?.is_call_active === true; then allowed; else notAllowed">
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-3 col-lg-4 col-md-6">
                                                                    <div class="card">
                                                                        <div class="card-body">
                                                                            <div class="media m-0 mt-0">
                                                                                <div class="media-body">
                                                                                    <p class="text-muted mb-0"> Chat Active</p>
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="data?.is_chat_active === true; then allowed; else notAllowed">
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-3 col-lg-4 col-md-6">
                                                                    <div class="card">
                                                                        <div class="card-body">
                                                                            <div class="media m-0 mt-0">
                                                                                <div class="media-body">
                                                                                    <p class="text-muted mb-0"> Query Active</p>
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="data?.is_query_active === true; then allowed; else notAllowed">
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-3 col-lg-4 col-md-6">
                                                                    <div class="card">
                                                                        <div class="card-body">
                                                                            <div class="media m-0 mt-0">
                                                                                <div class="media-body">
                                                                                    <p class="text-muted mb-0"> Query Report Active</p>
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="data?.is_report_active === true; then allowed; else notAllowed">
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                                    <!-- <div class="col-xl-3 col-lg-4 col-md-6">
                                                                    <div class="card">
                                                                        <div class="card-body">
                                                                            <div class="media m-0 mt-0">
                                                                                <div class="media-body">
                                                                                    <p class="text-muted mb-0"> Blocked</p>
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="data?.is_blocked === true; then active; else Inactive">
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                                    <div class="col-xl-3 col-lg-4 col-md-6">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="media m-0 mt-0">
                                                                                    <div class="media-body">
                                                                                        <p class="text-muted mb-0"> Approved</p>
                                                                                    </div>
                                                                                    <ng-container *ngIf="data?.is_approved === true; then active; else Inactive">
                                                                                    </ng-container>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <div class="col-md-12" *ngIf="data?.displayname == ''">
                                                                                    <div class="form-group" id="apprRejReason">
                                                                                        <label class="form-label">Display Name</label>
                                                                                        <input type="text" class="form-control" placeholder="Display Name" formControlName="displayname" required>
                                                                                        <div *ngIf="f.displayname.invalid && (f.displayname.dirty || f.displayname.touched)" class="alert alert-danger mt-1">
                                                                                            Required
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-12">
                                                                                    <div class="form-group" id="apprRejReason">
                                                                                        <label class="form-label">Approval/Rejection Comments</label>
                                                                                        <textarea class="form-control" rows="6" formControlName="approvedComment" required>Approval comments.........</textarea>
                                                                                        <div *ngIf="f.approvedComment.invalid && (f.approvedComment.dirty || f.approvedComment.touched)" class="alert alert-danger mt-1">
                                                                                            Required
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="card-footer"> -->
                                                                    <div class="col-12 d-flex justify-content-center">
                                                                        <button type="submit" class="btn btn-success mt-1 mr-1">{{data?.is_approved === true ? 'Update Astrologer' : 'Approve Astrologer'}}</button>
                                                                        <button type="button" (click)="onRejectAstrologer()" class="btn btn-danger mt-1" >Reject Astrologer</button>
                                                                    </div>
                                                                    <!-- </div> -->
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Edit Charges</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div class="wideget-user-followers mb-0">
                                                            <app-astro-charges-update></app-astro-charges-update>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Wallet History</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div class="wideget-user-followers mb-0">
                                                            <app-astro-wallet-history></app-astro-wallet-history>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Add Amount</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div class="wideget-user-followers mb-0">
                                                            <app-credit-astro-wallet-amount></app-credit-astro-wallet-amount>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Deduct Amount</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div class="wideget-user-followers mb-0">
                                                            <app-debit-astro-wallet-amount></app-debit-astro-wallet-amount>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Login Logs</a>
                                        <ng-template ngbNavContent>
                                            <app-astro-login></app-astro-login>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Service Logs</a>
                                        <ng-template ngbNavContent>
                                            <app-astro-service-logs></app-astro-service-logs>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem *ngIf="data?.is_approved">
                                        <a ngbNavLink>Work History</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="border-0">
                                                        <div class="wideget-user-followers mb-0">
                                                            <app-work-history></app-work-history>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
