import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageDataService } from 'src/app/admin/services/language/language-data.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';

@Component({
  selector: 'app-languages-list',
  templateUrl: './languages-list.component.html',
  styleUrls: ['./languages-list.component.scss'],
})
export class LanguagesListComponent implements OnInit {
  constructor(
    private language: LanguageDataService,
    private userdata: UserdataService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private route: Router
  ) {}
  title = 'angular-datatables';

  languageData: any = [];
  id: any ='';
  temp:any= [];
  getLanguageData:any = [];
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
  this.getLaguangeList() 
  this.languageData = this.getLanguageData;
  this.addLanguageForm.patchValue({userId: this.userdata.getId()})
  this.addLanguageForm.patchValue({addedBy: this.userdata.getName()})
  }
  

  /* ***********
  list languages
  *********** */

  getLaguangeList() {
    this.spinner.show()
    this.language.getLaguangeList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.languageData = res.data;
        this.temp = this.languageData;
        this.getLanguageData = this.languageData;
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  /* ***********
  Modal Popup add languages start
  *********** */
  closeResult = '';

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /* ***********
   Add new language
  *********** */
  addLanguageForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    userId: new FormControl(),
    addedBy: new FormControl(),
  });

  get f(){
    return this.addLanguageForm.controls
  }

  public onAddLanguage() {
    this.spinner.show();
    this.language.addNewlanguage(this.addLanguageForm.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toast.success('Language added!', 'Success');
        this.modalService.dismissAll();
        this.route.navigateByUrl('admin/languages')
        this.getLaguangeList();
      },
      (err) => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    this.language.searchLanguageByName({name:event.target.value}).subscribe(
      (res: any) => {
        //this.spinner.hide()
        this.languageData = res.data;
        this.temp = this.languageData;
        this.getLanguageData=this.languageData;
      },
      (err) => {
        //this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
