import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/admin/services/orders/order-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-chat-details',
  templateUrl: './chat-details.component.html',
  styleUrls: ['./chat-details.component.scss']
})
export class ChatDetailsComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata : UserdataService,
    private toast : ToastrService,
    private _lightbox: Lightbox,
    private _lightboxConfig: Lightbox,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.orderService.getChatOrderDetail({_id: this.id,userId:this.userdata.getId()}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
  
    var timeString = hours.toString().padStart(2, '0') 
                + ':' + minutes.toString().padStart(2, '0') 
                + ':' + seconds.toString().padStart(2, '0'); 
    return timeString
  } 

  /**
   * Get attachment type
   */
   checkExtenstion(val: any) {
    var fileName, fileExtension;
    fileName = val
    fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toLowerCase();
    if (fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'jpeg') {
      return 0
    } else if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'txt') {
      return 1
    } else if (fileExtension == 'xlsx' || fileExtension == 'xls' || fileExtension == 'csv') {
      return 2
    } else if (fileExtension == 'pdf') {
      return 3
    } else {
      return 4
    }
  }

  BaseUrl: any;

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  public getOpenUrl(url: string) {
    //return this.trusturl.bypassSecurityTrustUrl('http://docs.google.com/viewer?url=' + this.BaseUrl + url)
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
  private _albums:any = [];
  open(val: any): void {
    this._albums = []
    const album = {
      src: this.BaseUrl + val,
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true, fitImageInViewPort: true, centerVertically: true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
