import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/app/admin/services/manager/manager.service';

@Component({
  selector: 'app-detail-manager',
  templateUrl: './detail-manager.component.html',
  styleUrls: ['./detail-manager.component.scss']
})
export class DetailManagerComponent implements OnInit {

  BaseUrl = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private managerService: ManagerService,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  data: any = '';
  roledata: any = '';
  id: any = '';

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById() {
    this.spinner.show()
    this.managerService.getManagerDataById({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.roledata = res.roledetail
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

}
