<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Change Password</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/usersList']">Users</a></li>
            <li class="breadcrumb-item active" aria-current="page">Change Password</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <span class="login100-form-title pt-4 mt-4">
                    Change Password for
                    <ng-container *ngIf="isAdmin">Admin</ng-container>
                    <ng-container *ngIf="isAstrologer">{{astrologerData.name}}, Astrologer ID #{{astrologerData.astroAutoId}} </ng-container>
                    <ng-container *ngIf="isCustomer">{{customerData.name}}, Customer ID #{{customerData.customerAutoId}} </ng-container>
                    <ng-container *ngIf="isManager">{{userData.name}}, Manager Email : {{userData.email}}</ng-container>
                </span>
                <div class="card-body d-flex justify-content-center">
                    <div>
                    </div>
                    <form class="login100-form validate-form" [formGroup]="changePasswordForm" (submit)="onUpdatePassword()">
                        <div class="wrap-input100 validate-input" data-validate="Password is required">
                            <input class="input100" type="password" formControlName="password" placeholder="New Password" required >
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <i class="zmdi zmdi-lock" aria-hidden="true"></i>
                            </span>
                        </div>                         
                        <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger mt-1">
                            Required. Password should contains minimum 1 special symbol, 1 uppercase letter, 1 lowercase letter, 1 number and length not less than 8 characters.
                        </div>
                        <div class="wrap-input100 validate-input" data-validate="Password is required">
                            <input class="input100" type="password" formControlName="confirmpassword" placeholder="Confirm Password" required >
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <i class="zmdi zmdi-lock" aria-hidden="true"></i>
                            </span>
                        </div>
                        <div *ngIf="changePasswordForm.errors?.passwordConfirm && (changePasswordForm.touched || changePasswordForm.dirty) || (f.confirmpassword.invalid && (f.confirmpassword.dirty || f.confirmpassword.touched))" class="alert alert-danger mt-1">
                            Required. Password and Confirm Password should match.
                        </div>
                        <!-- <div *ngIf="(f.confirmpassword.invalid || changePasswordForm.errors?.passwordConfirmValidator) && (f.confirmpassword.dirty || f.confirmpassword.touched)" class="alert alert-danger mt-1">
                            Required. Password and Confirm Password should match
                        </div>  -->
                        <!-- <div class="wrap-input100 validate-input" data-validate="Password is required">
                            <input class="input100" type="password" formControlName="password" placeholder="Confirm Password" required >
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <i class="zmdi zmdi-lock" aria-hidden="true"></i>
                            </span>
                        </div>
                        <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger mt-1">
                            Required
                        </div> -->
                        <div class="container-login100-form-btn">
                            <button type="submit" class="login100-form-btn btn-primary"  [disabled]="!changePasswordForm.valid">
                                Update Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
