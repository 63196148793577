<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Search Name..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />

</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="2" name="Name" prop="festivalName">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.festivalName">{{row.festivalName}}</span>
        </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column [flexGrow]="3" name="Description" prop="festivalDescription">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.festivalDescription">{{row.festivalDescription}}</span>
        </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin/festivals/view',row._id]" class="fa fa-eye text-primary mr-2"></a>
            <a [routerLink]="['/admin/festivals/',row._id]" class="btn-table-custom btn btn-sm btn-outline-primary">Edit</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
