<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Festival</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/festival/list']">Festival List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12 col-md-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Festival Details </h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class=" border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Title :</strong> {{data.festivalName}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class=" border-0 col-lg-12 col-xl-12 pr-0" *ngIf="data.metaDescription != ''">
                                                                <tr>
                                                                    <td><strong>Meta Description :</strong> {{data.metaDescription}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class=" border-0 col-lg-12 col-xl-12 pr-0" *ngIf="data.metaKeywords != ''">
                                                                <tr>
                                                                    <td><strong>Meta Keywords :</strong> {{data.metaKeywords}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class=" border-0 col-lg-12 col-xl-12 pr-0" *ngIf="data.metaTags != ''">
                                                                <tr>
                                                                    <td><strong>Meta Tags :</strong> {{data.metaTags}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class=" border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Description :</strong> <div [innerHTML]="data.festivalDescription"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class=" border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Image :</strong>
                                                                    <img [src]="getSanitizeUrl(data.festivalImage)" class="img img-fluid img-thumbnail img-festival"/></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->