import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-view-astrologer-profile',
  templateUrl: './view-astrologer-profile.component.html',
  styleUrls: ['./view-astrologer-profile.component.scss']
})
export class ViewAstrologerProfileComponent implements OnInit {
  BaseUrl = '';
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private astrService: AstroServiceService,
    private usersevice: UserdataService,
    private toast: ToastrService,
    private route: Router,
    private trusturl: DomSanitizer,
    private _location:Location,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  data: any = '';
  id: any = '';

  onBackPressed(){
    this._location.back()
  }
  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById();
    this.approveAstrologerForm.patchValue({ _id: this.id })
    this.approveAstrologerForm.patchValue({ approvedBy: this.usersevice.getName() })
    this.approveAstrologerForm.patchValue({ approvedId: this.usersevice.getId() })
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  approveAstrologerForm: any = new FormGroup({
    _id: new FormControl(''),
    is_call_allow: new FormControl(),
    is_chat_allow: new FormControl(),
    is_query_allow: new FormControl(),
    is_report_allow: new FormControl(),
    is_exclusive: new FormControl(),
    is_promotional_allow: new FormControl(),
    displayname: new FormControl('',[Validators.pattern('[A-Za-z]{1,}')]),
    is_approved:new FormControl(false),
    approvedBy: new FormControl(),
    approvedId: new FormControl(),
    approvedComment: new FormControl('', [Validators.required])
  })

  getDataById(){
    this.spinner.show()
    this.astrService.getAstrologerDataById({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        // console.log(this.data)
        this.approveAstrologerForm.patchValue({ is_call_allow: this.data.is_call_allow })
        this.approveAstrologerForm.patchValue({ is_chat_allow: this.data.is_chat_allow})
        this.approveAstrologerForm.patchValue({ is_report_allow: this.data.is_report_allow })
        this.approveAstrologerForm.patchValue({ is_query_allow: this.data.is_query_allow })
        this.approveAstrologerForm.patchValue({ is_exclusive: this.data.is_exclusive })
        this.approveAstrologerForm.patchValue({ is_promotional_allow: this.data.is_promotional_allow })
        this.approveAstrologerForm.patchValue({ displayname: this.data.displayname })
      },
      err => {
        this.spinner.hide()
        //console.log(err)

      }
    )
  }

  get f(){
    return this.approveAstrologerForm.controls
  }

  public onApproveAstrologerSubmit() {
    if(this.approveAstrologerForm.get('displayname').value == ''){
      Swal.fire('Invalid Data','Please Assign Display Name To Astrologer','error')
      return
    }
    this.approveAstrologerForm.patchValue({ is_approved: true })
    if(this.data.is_approved == true) {
      this.astrService.updateApproveAstrologer(this.approveAstrologerForm.value).subscribe(
        (res:any) => {
          this.spinner.hide()
          this.data = res.data;
          this.toast.success('Astologer Updated', 'Success')
          this.reloadCurrentRoute()
        },
        err => {
          this.spinner.hide()
          //this.toast.error('Something went wrong', 'Error')
          this.toast.error(err.error.message, 'Error')
          //console.log(err) 
        }
      )
    }
    else if(this.data.is_approved == false) {
        this.astrService.approveAstrologer(this.approveAstrologerForm.value).subscribe(
          (res:any) => {
            this.spinner.hide()
            this.data = res.data
            this.toast.success('Astologer Approved', 'Success')
            this.reloadCurrentRoute()
          },
          err => {
            this.spinner.hide()
            //this.toast.error('Something went wrong', 'Error')
            this.toast.error(err.error.message, 'Error')
            //console.log(err) 
          }
        )
    }
  }

  public onRejectAstrologer(){
    this.approveAstrologerForm.patchValue({ is_approved: false })
    this.approveAstrologerForm.patchValue({ is_call_allow: false })
    this.approveAstrologerForm.patchValue({ is_chat_allow: false })
    this.approveAstrologerForm.patchValue({ is_report_allow: false })
    this.approveAstrologerForm.patchValue({ is_query_allow: false })
    this.approveAstrologerForm.patchValue({ is_exclusive: false })
    this.astrService.rejectAstrologer(this.approveAstrologerForm.value).subscribe(
      (res:any) => {
        //console.log('---first', )
        this.spinner.hide()
        this.data = res.data
        this.toast.success('Astologer Rejected', 'Success')
        this.reloadCurrentRoute()
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Something went wrong', 'Error')
        this.toast.error(err.error.message, 'Error')
        //console.log(err) 
      }
    )
  }

  callStatus(e: any) {
    this.approveAstrologerForm.patchValue({ is_call_allow: e.checked})
  }

  chatStatus(e: any) {
    this.approveAstrologerForm.patchValue({ is_chat_allow: e.checked })
  }

  reportStatus(e: any) {
    this.approveAstrologerForm.patchValue({ is_report_allow: e.checked})
  }

  queryStatus(e: any) {
    this.approveAstrologerForm.patchValue({ is_query_allow: e.checked})
  }

  exclusiveStatus(e: any) {
    this.approveAstrologerForm.patchValue({ is_exclusive: e.checked})
  }

  promotionalStatus(e: any) {
    this.approveAstrologerForm.patchValue({ is_promotional_allow: e.checked})
  }

  /** service on off for single asstrologer */

  callActiveStatus(e: any) {
    if(!this.data.is_call_allow){
      this.toast.error('This Expert is not approved for Call')
      this.reloadCurrentRoute()
      return
    }
    this.spinner.show()
    this.astrService.serviceOnOff({service_name: 'call', value: e.checked, isoperationbyaddmin: true, _id: this.id}).subscribe(
      (res: any)=> {
        this.spinner.hide()
        this.toast.success(res.message,"Success")
        this.getDataById();
    },
    err => {
      this.spinner.hide()
      this.toast.error(err.error.message, 'Error');
    }
    )
  }
  chatActiveStatus(e: any) {
    if(!this.data.is_chat_allow){
      this.toast.error('This Expert is not approved for chat')
      this.reloadCurrentRoute()
      return
    }
    this.spinner.show()
    this.astrService.serviceOnOff({service_name: 'chat', value: e.checked, isoperationbyaddmin: true, _id: this.id}).subscribe(
      (res: any)=> {
        this.spinner.hide()
        this.toast.success(res.message,"Success")
        this.getDataById();
    },
    err => {
      this.spinner.hide()
      this.toast.error(err.error.message, 'Error');
    }
    )
  }
  queryActiveStatus(e: any) {
    if(!this.data.is_query_allow){
      this.toast.error('This Expert is not approved for query')
      this.reloadCurrentRoute()
      return
    }
    this.spinner.show()
    this.astrService.serviceOnOff({service_name: 'query', value: e.checked, isoperationbyaddmin: true, _id: this.id}).subscribe(
      (res: any)=> {
        this.spinner.hide()
        this.toast.success(res.message,"Success")
        this.getDataById();
    },
    err => {
      this.spinner.hide()
      this.toast.error(err.error.message, 'Error');
    }
    )
  }
  reportActiveStatus(e: any) {
    if(!this.data.is_report_allow){
      this.toast.error('This Expert is not approved for report')
      this.reloadCurrentRoute()
      return
    }
    this.spinner.show()
    this.astrService.serviceOnOff({service_name: 'report', value: e.checked, isoperationbyaddmin: true, _id: this.id}).subscribe(
      (res: any)=> {
        this.spinner.hide()
        this.toast.success(res.message,"Success")
        this.getDataById();
    },
    err => {
      this.spinner.hide()
      this.toast.error(err.error.message, 'Error');
    }
    )
  }
  promoActiveStatus(e: any) {
    if(!this.data.is_promotional_allow){
      this.toast.error('This Expert is not approved for Promotional Service')
      this.reloadCurrentRoute()
      return
    }
    this.spinner.show()
    this.astrService.serviceOnOff({service_name: 'promo', value: e.checked, isoperationbyaddmin: true, _id: this.id}).subscribe(
      (res: any)=> {
        this.spinner.hide()
        this.toast.success(res.message,"Success")
        this.getDataById();
    },
    err => {
      this.spinner.hide()
      this.toast.error(err.error.message, 'Error');
    }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate([currentUrl]);
    });
  }
}
