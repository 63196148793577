<!-- Model popup to approve/reject product request template start -->
<ng-template #contentShow let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Requested product's Details</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="border-top">
            <div class="wideget-user-tab">
              <div class="tab-menu-heading">
                <div class="tabs-menu1">
                  <div class="card-body">
                    <div class="border-0">
                      <div class="tab-content">
                        <div class="tab-pane active show" id="tab-51">
                          <div id="profile-log-switch">
                            <div class="media-heading d-flex justify-content-between">
                              <h4>Product Details </h4>
                            </div>
                            <hr class="m-0">
                            <div class="table-responsive">
                              <table class="user-table-custom table row table-bordered">
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>ID :</strong> #{{data?.productId?.productId}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>Product Name :</strong> {{data?.productId?.productname}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>Status :</strong>
                                        <span class="badge badge-success" *ngIf="data.isApproved && data.is_used">Approved</span>
                                        <span class="badge badge-primary" *ngIf="!data.isApproved && !data.is_used">Pending</span>
                                        <span class="badge badge-warning" *ngIf="!data.isApproved && data.is_used">Rejected</span>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>Product Name :</strong> {{data?.productId?.productname}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>Product Description :</strong> {{data?.productId?.productdescription}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td>
                                      <strong>Price:</strong>
                                      <span>
                                        {{data?.price}}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>Astrologer Name :</strong> {{data?.astroId?.name}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0" *ngIf="data?.astrologer_comment != ''">
                                  <tr>
                                    <td><strong>Astrologer Comment :</strong> {{data?.astrologer_comment}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0" *ngIf="data?.admin_comment != ''">
                                  <tr>
                                    <td><strong>Admin Comment :</strong> {{data?.admin_comment}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0">
                                  <tr>
                                    <td><strong>Created Date :</strong>{{data?.createdAt | date:'medium'}}</td>
                                  </tr>
                                </tbody>
                                <tbody class="border-0 col-lg-12 col-xl-12 col-sm-12 pr-0" *ngIf="data?.updatedDate != ''">
                                  <tr>
                                    <td><strong>Updated Date :</strong>{{data?.updatedDate | date:'medium'}}</td>
                                  </tr>
                                </tbody>

                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
<!-- Model popup to approve/reject product request template end -->



<!-- ------------------------------------------------------------- -->
<!-- Model popup to approve/reject product request template start -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Approve/Reject Product Request</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" (submit)="approveRejectAstrologer()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
              <label for="exampleInputname" class="mb-4">Approve/Reject Product request</label><br/>
              <input type="radio" class="mr-2" (change)="OnIsApproveHandler($event)" name="isApproved" value="true" checked="true">
              <label class="mr-2">Approve</label>
              <input type="radio" class="mr-2" (change)="OnIsApproveHandler($event)" name="isApproved" value="false">
              <label>Reject</label>
              <!-- <div *ngIf="f.isApproved.invalid && (f.isApproved.dirty || f.isApproved.touched)" class="alert alert-danger mt-1">
                  Required
              </div> -->
          </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="astroprice">Astro price (In Rupees) *(only numbers are
              allowed)</label>
            <input type="text" class="form-control "
              formControlName="astroprice" placeholder="Enter astroprice" required>
            <div *ngIf="f.astroprice.invalid && (f.astroprice.dirty || f.astroprice.touched)"
              class="alert alert-danger mt-1">
              Only numbers are allowed
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="finalprice">Final price (In Rupees) *(only numbers are
              allowed)</label>
            <input type="text" class="form-control "
              formControlName="finalprice" placeholder="Enter finalprice" required>
            <div *ngIf="f.finalprice.invalid && (f.finalprice.dirty || f.finalprice.touched)"
              class="alert alert-danger mt-1">
              Only numbers are allowed
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="admin_comment">Comment *</label>
            <textarea name="" id="" cols="30" rows="5" class="form-control "
            formControlName="admin_comment" placeholder="Enter admin comment" required>
            </textarea>
            <div *ngIf="f.admin_comment.invalid && (f.admin_comment.dirty || f.admin_comment.touched)"
              class="alert alert-danger mt-1">
              Required
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="modal.close('Save click')">Cancel</button>
    <button type="submit" class="btn btn-outline-dark"
      (click)="approveRejectAstrologer()"
      [disabled]="!formGroup.valid">Save</button>
  </div>
</ng-template>
<!-- Model popup to approve/reject product request template end -->
<!-- ------------------------------------------------------------ -->
<!-- datatable -->

<div class="pr-2 pl-2 d-flex justify-content-between">
  <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
      (keyup)="updateFilter($event)"   />
      <span>
        <button class="btn-user-custom btn btn-outline-success btn-sm mr-2" (click)="getProductList()">All</button>
        <button class="btn-user-custom btn btn-outline-primary btn-sm mr-2" (click)="getApprovedProductRequestList()">Approved</button>
        <button class="btn-user-custom btn btn-outline-warning btn-sm mr-2" (click)="getRejectedProductRequestList()">Rejected</button>
        <button class="btn-user-custom btn btn-outline-info btn-sm" (click)="getPendingProductRequestList()">Pending</button>
      </span>
</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
  <ngx-datatable-column [flexGrow]="3" name="Product ID" prop="productId.productId">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.productId.productId">#{{row.productId.productId}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Name" prop="productname">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.productId.productname">{{row.productId.productname}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Price" prop="price">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.price">{{row.price}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Status" prop="status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span [title]="row.isApproved" class="badge badge-success" *ngIf="row.isApproved && row.is_used">Approved</span>
      <span [title]="row.isApproved" class="badge badge-primary" *ngIf="!row.isApproved && !row.is_used">Pending</span>
      <span [title]="row.isApproved" class="badge badge-warning" *ngIf="!row.isApproved && row.is_used">Rejected</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Astro Name" prop="astroname">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.astroId.name}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Date" prop="createdAt">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.createdAt | date:'medium'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Action" *ngIf="action">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button class="btn-user-custom btn btn-sm btn-outline-primary" *ngIf="!row.isApproved && !row.is_used"
       (click)="open(content, row._id)">Action</button>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Edit" >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button class="btn-user-custom btn btn-sm btn-outline-primary"
       (click)="openShow(contentShow, row._id)"><i class="fa fa-eye"></i></button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>




