import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }
   public getCustomersList(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allcustomers ", data,{ headers: header_object});
  }

  public addNewCustomer(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addcustomer", data, {headers: header_object});
  }

  public getCustomerDataById(_id: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchcustomerbyid", _id, {headers: header_object});
  } 

  public getIndividualCustomerDataById(_id: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchpersonbyid", _id, {headers: header_object});
  } 
  public getPersonByCustomerId(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/mypeople", data, {headers: header_object});
  } 

  public blockCustomer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/blockCustomer", data, {headers: header_object});
  }

  public getAllCustomerOrders(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allorders", data, {headers: header_object});
  }

  public getCustomerWalletHistory(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/customerwallethistory", data, {headers: header_object});
  }

  public getCustomerWalletTransactionHistoryByOperation(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/transactionbyoperation", data, {headers: header_object});
  }

  public CreditDebitCustomerWalletByAdmin(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addcustomerwalletadmin", data, {headers: header_object});
  }

  public updateCustomer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateCustomer", data, {headers: header_object});
  }

  public hideCustomerProfile(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/hidecustomerprofile", data, {headers: header_object});
  }
}

