import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';

@Component({
  selector: 'app-add-common-blog',
  templateUrl: './add-common-blog.component.html',
  styleUrls: ['./add-common-blog.component.scss']
})
export class AddCommonBlogComponent implements OnInit {

  dataList: any = []
  BaseUrl: any;
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private blogService: BlogServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private clipboard: Clipboard,
    private httpClient: HttpClient,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  getBlogList() {
    this.spinner.show()
    this.blogService.getBlogCategory({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  formGroup: FormGroup = new FormGroup({
    blogTitle: new FormControl('', Validators.required),
    blogDescription: new FormControl('', Validators.required), 
    blogsmallDescription: new FormControl('', Validators.required),
    blogImage: new FormControl('', Validators.required),
    userId: new FormControl('', [Validators.required]),
    userName: new FormControl('', [Validators.required]),
    categoryId: new FormControl('', Validators.required),
    isApproved: new FormControl(true),
    is_used: new FormControl(true),
  });

  private unsubscribe = new Subject<void>()

  ngOnInit(): void {
    this.getBlogList()
    this.formGroup.patchValue({ blogDescription: localStorage.getItem('blog_data') })
    this.formGroup.patchValue({ blogsmallDescription: localStorage.getItem('blog_smalldata') })
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ userName: this.userdata.getName() })
    this.formGroup.valueChanges.pipe(
      switchMap(async (formValue) => {
        localStorage.setItem('blog_data', this.formGroup.get('blogDescription')?.value)
        localStorage.setItem('blog_smalldata', this.formGroup.get('blogsmallDescription')?.value)
      }),
      takeUntil(this.unsubscribe)
    ).subscribe()
  }
  ngOnDestroy() {
    this.unsubscribe.next()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Blog Image
   */
  imageError: string = ''
  imageSrc = ''
  public onBannerUpload(event: any) {
    this.imageError = ''
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 540;
      const max_width = 960;
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.imageSrc = reader.result as string;
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          //console.log(img_height, img_width);
          if (img_height != max_height || img_width != max_width) {
            this.imageError =
              'Image dimentions allowed ' +
              max_width +
              ' X ' +
              max_height +
              'px';
            return;
          } else {
            this.formGroup.patchValue({
              blogImage: event.target.files[0]
            })
          }
        }
      };
    }
  }

  addBlogSubmit() {
    const data = new FormData()
    data.append("blogTitle", this.formGroup.get("blogTitle")?.value)
    data.append("blogDescription", this.formGroup.get("blogDescription")?.value)
    data.append("blogsmallDescription", this.formGroup.get("blogsmallDescription")?.value)
    data.append("blogImage", this.formGroup.get("blogImage")?.value)
    data.append("userId", this.formGroup.get("userId")?.value)
    data.append("userName", this.formGroup.get("userName")?.value)
    data.append("categoryId", this.formGroup.get("categoryId")?.value)
    data.append("isApproved", this.formGroup.get("isApproved")?.value)
    data.append("is_used", this.formGroup.get("is_used")?.value)
    //console.log('blog', this.formGroup.value)
    this.blogService.addBlog(data).subscribe(
      (res:any) =>{
        // clearing local storage
        localStorage.removeItem('blog_data')
        localStorage.removeItem('blog_smalldata')
        
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/blog/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  /**
   * Direct Image Upload for blog
   */
  imageGroup: FormGroup = new FormGroup({
    image: new FormControl('', Validators.required)
  });
  imageDirectError: string = ''
  imageDirectSrc = ''
  directPath: String = ''
  public onDirectUpload(event: any) {
    this.imageDirectError = ''
    if (event.target.files && event.target.files.length) {
      const allowed_types = ['image/png', 'image/jpeg'];
      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.imageDirectError = 'Only Images are allowed ( JPG | PNG )';
        return;
      } else {
        this.imageGroup.patchValue({
          image: event.target.files[0]
        })
        //console.log(this.BaseUrl)
      }
    }
  }

  addDirectImage() {
    const data = new FormData()
    data.append("image", this.imageGroup.get("image")?.value)
    this.blogService.addBlogImage(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.imageGroup.patchValue({
          image: ''
        })
        this.directPath = this.BaseUrl + res.result
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  copyImageName() {
    this.clipboard.copy(String(this.directPath));
    this.toast.success('Path Copied!')
  }

  example_image_upload_handler(blobInfo: any, success: any, failure: any, progress: any) {

    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    var token = sessionStorage.getItem('token')??""
    var header_object = new HttpHeaders().set("Authorization", token);
    const data = new FormData()
    data.append("image",blobInfo.blob());
    let request = httpClient.post<any>("https://apiv2.astroriver.com/admin/addblogimages",data,{headers:header_object});
    request.subscribe(res=>{
      //console.log("res",res)
      if(res.success){
        success(`https://apiv2.astroriver.com/${res.result}`)
      }else{
        failure("not uploaded")
      }

    },err=> {
      failure(err);
    })
  }

}
