import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { convertToObject } from 'typescript';
import { UserdataService } from '../shared/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class BlogServiceService {

  BaseUrl: string = ''  
  Token: any = '';
  
  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getData()
   }

  /**
   * Blog Category 
   */

   public addBlogCategory(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addblogcategory", data,{ headers: header_object});
  }

   public getBlogCategory(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allblogcategory", data,{ headers: header_object});
  }

  public getSingleBlogCategory(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getsingleblogcategory", data,{ headers: header_object});
  }

  public updateBlogCategory(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateblogcategory", data,{ headers: header_object});
  }

  public checkUniqueBlogSlug(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/fetchblogcategorybyslug", data,{ headers: header_object});
  }

  /**
   * Blogs 
   */

   public addBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addBlog", data,{ headers: header_object});
  }

   public getBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allblogs", data,{ headers: header_object});
  }

  public getSingleBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getSingleBlog", data,{ headers: header_object});
  }

  public updateBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateblogadmin", data,{ headers: header_object});
  }

  public approveRejectBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/approverejectblog", data,{ headers: header_object});
  }
  
  public setFeaturedBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/setFeaturedBlog", data,{ headers: header_object});
  }

  /**
   * Add Blog Image
   */
   public addBlogImage(data: any){

    //console.log("dasdas",data)
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addblogimages", data,{ headers: header_object});
  }
}
