import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SkillsServiceService } from 'src/app/admin/services/skills/skills-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-skills',
  templateUrl: './edit-skills.component.html',
  styleUrls: ['./edit-skills.component.scss']
})
export class EditSkillsComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private skillsService: SkillsServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router
  ) { }

  data = '';
  id: any = '';
  

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.updateSkillsForm.patchValue({ updatedId: this.userdata.getId() })
    this.updateSkillsForm.patchValue({ updatedBy: this.userdata.getName() })
    this.updateSkillsForm.patchValue({ _id: this.id })
  }

  updateSkillsForm: FormGroup = new FormGroup({
    skillname: new FormControl('', Validators.required),
    updatedId: new FormControl(),
    updatedBy: new FormControl(),
    _id: new FormControl()
  });

  
  getDataById(){
    this.spinner.show()
    this.skillsService.getSkillsDataById({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        //console.log(res)
        this.data = res.data
        this.updateSkillsForm.patchValue({skillname: res.data.skillname});
      },
      err => {
        //console.log(err) 
      }
    )
  }

  get f(){
    return this.updateSkillsForm.controls
  }

   /* ***********
   Update Skills
  *********** */

public onUpdateSkills() {
  this.spinner.show();
  this.skillsService.updateSkill(this.updateSkillsForm.value).subscribe(
    (res: any)=> {
      this.spinner.hide()
      //console.log(res)
      this.toast.success('Skill Updated!', 'Success');
      this.route.navigateByUrl('admin/skills')

    },
    err => {
      this.spinner.hide()
      //console.log(err)
      this.toast.error('Something went wrong!', 'Error');
    })

}

}
