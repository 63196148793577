import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TagInputModule } from 'ngx-chips';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './admin/component/dashboard/dashboard.component';
import { MainDashboardComponent } from './admin/component/main-dashboard/main-dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './admin/component/login/login.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './admin/component/sharedComponents/header/header.component';
import { FooterComponent } from './admin/component/sharedComponents/footer/footer.component';
import { HorizontalMenuComponent } from './admin/component/sharedComponents/horizontal-menu/horizontal-menu.component';
import { SidebarComponent } from './admin/component/sharedComponents/sidebar/sidebar.component';
import { LanguagesListComponent } from './admin/component/language/languages-list/languages-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditLanguageComponent } from './admin/component/language/edit-language/edit-language.component';
import { SkillsListComponent } from './admin/component/skills/skills-list/skills-list.component';
import { EditSkillsComponent } from './admin/component/skills/edit-skills/edit-skills.component';
import { AstrologersListComponent } from './admin/component/astrologer/astrologers-list/astrologers-list.component';
import { AddEditAstrologerProfileComponent } from './admin/component/astrologer/add-edit-astrologer-profile/add-edit-astrologer-profile.component';
import { ViewAstrologerProfileComponent } from './admin/component/astrologer/view-astrologer-profile/view-astrologer-profile.component';
import { AddCustomerComponent } from './admin/component/customer/add-customer/add-customer.component';
import { ListCustomerComponent } from './admin/component/customer/list-customer/list-customer.component';
import { ViewCustomerComponent } from './admin/component/customer/view-customer/view-customer.component';
import { ListUsersComponent } from './admin/component/user/list-users/list-users.component';
import { ViewUserComponent } from './admin/component/user/view-user/view-user.component';
import { ChangePasswordComponent } from './admin/component/sharedComponents/change-password/change-password.component';
import { ListCustomerUsersComponent } from './admin/component/user/list-customer-users/list-customer-users.component';
import { ListAstroUsersComponent } from './admin/component/user/list-astro-users/list-astro-users.component';
import { AstroChargesUpdateComponent } from './admin/component/astrologer/astro-charges-update/astro-charges-update.component';
import { AddEditReportComponent } from './admin/component/report/add-edit-report/add-edit-report.component';
import { ListReportsComponent } from './admin/component/report/list-reports/list-reports.component';
import { ShowSignleCustomerDetailComponent } from './admin/component/customer/show-signle-customer-detail/show-signle-customer-detail.component';
import { WalletHistoryComponent } from './admin/component/customer/wallet-history/wallet-history.component';
import { CreditDebitWalletAmountComponent } from './admin/component/customer/credit-debit-wallet-amount/credit-debit-wallet-amount.component';
import { ListBannerComponent } from './admin/component/banner/list-banner/list-banner.component';
import { AddBannerComponent } from './admin/component/banner/add-banner/add-banner.component';
import { ListOrdersComponent } from './admin/component/orders/list-orders/list-orders.component';
import { ListChatordersComponent } from './admin/component/orders/list-chatorders/list-chatorders.component';
import { ListQueryordersComponent } from './admin/component/orders/list-queryorders/list-queryorders.component';
import { QueryDetailComponent } from './admin/component/orders/query-detail/query-detail.component';
import { ListReportordersComponent } from './admin/component/orders/list-reportorders/list-reportorders.component';
import { ReportDetailComponent } from './admin/component/orders/report-detail/report-detail.component';
import { AllIssueComponent } from './admin/component/issues/all-issue/all-issue.component';
import { CustomerIssueComponent } from './admin/component/issues/customer-issue/customer-issue.component';
import { AstrologerIssueComponent } from './admin/component/issues/astrologer-issue/astrologer-issue.component';
import { ReplyAstrologerIssueComponent } from './admin/component/issues/reply-astrologer-issue/reply-astrologer-issue.component';
import { ReplyCustomerIssueComponent } from './admin/component/issues/reply-customer-issue/reply-customer-issue.component';
import { DetailCustomerIssueComponent } from './admin/component/issues/detail-customer-issue/detail-customer-issue.component';
import { DetailAstrologerIssueComponent } from './admin/component/issues/detail-astrologer-issue/detail-astrologer-issue.component';
import { AllRemedyComponent } from './admin/component/remedy/all-remedy/all-remedy.component';
import { SuggestRemedyComponent } from './admin/component/remedy/suggest-remedy/suggest-remedy.component';
import { DebitWalletAmountComponent } from './admin/component/customer/debit-wallet-amount/debit-wallet-amount.component';
import { CustomerReportOrderComponent } from './admin/component/customer/customer-report-order/customer-report-order.component';
import { CustomerQueryOrderComponent } from './admin/component/customer/customer-query-order/customer-query-order.component';
import { CustomerWalletRechargeOrderComponent } from './admin/component/customer/customer-wallet-recharge-order/customer-wallet-recharge-order.component';
import { HasRoleDirective } from './admin/services/shared/directive/has-role.directive';
import { AddAstrologerOpenComponent } from './add-astrologer-open/add-astrologer-open.component';
import { ListCallordersComponent } from './admin/component/orders/list-callorders/list-callorders.component';
import { CallDetailsComponent } from './admin/component/orders/call-details/call-details.component';
import { ViewManagerComponent } from './admin/component/manager/view-manager/view-manager.component';
import { AddManagerComponent } from './admin/component/manager/add-manager/add-manager.component';
import { ListManagerComponent } from './admin/component/manager/list-manager/list-manager.component';
import { ListRolesComponent } from './admin/component/roles/list-roles/list-roles.component';
import { AddRolesComponent } from './admin/component/roles/add-roles/add-roles.component';
import { DetailManagerComponent } from './admin/component/manager/detail-manager/detail-manager.component';
import { ChargesUpdateListComponent } from './admin/component/update-values/charges-update-list/charges-update-list.component';
import { ApproveRejectChargesComponent } from './admin/component/update-values/approve-reject-charges/approve-reject-charges.component';
import { ProfileUpdateListComponent } from './admin/component/update-values/profile-update-list/profile-update-list.component';
import { ApproveRejectProfileComponent } from './admin/component/update-values/approve-reject-profile/approve-reject-profile.component';
import { AstroWalletHistoryComponent } from './admin/component/astrologer/astro-wallet-history/astro-wallet-history.component';
import { CreditAstroWalletAmountComponent } from './admin/component/astrologer/credit-astro-wallet-amount/credit-astro-wallet-amount.component';
import { DebitAstroWalletAmountComponent } from './admin/component/astrologer/debit-astro-wallet-amount/debit-astro-wallet-amount.component';

import { NgxCaptchaModule } from 'ngx-captcha';
import { EditRolesComponent } from './admin/component/roles/edit-roles/edit-roles.component';
import { EditManagerComponent } from './admin/component/manager/edit-manager/edit-manager.component';
import { ManagerLoginComponent } from './admin/component/manager/manager-login/manager-login.component';
import { AddAdminWalletComponent } from './admin/component/admin-wallet/add-admin-wallet/add-admin-wallet.component';
import { ListAdminWalletComponent } from './admin/component/admin-wallet/list-admin-wallet/list-admin-wallet.component';
import { AstroLoginComponent } from './admin/component/astrologer/astro-login/astro-login.component';
import { CustomerLoginLogComponent } from './admin/component/customer/customer-login-log/customer-login-log.component';
import { CustomerCallOrderComponent } from './admin/component/customer/customer-call-order/customer-call-order.component';
import { EditAstrologerComponent } from './admin/component/astrologer/edit-astrologer/edit-astrologer.component';
import { CustomizeComponent } from './admin/component/customize/customize/customize.component';
import { ListCouponComponent } from './admin/component/coupon/list-coupon/list-coupon.component';
import { AddCouponComponent } from './admin/component/coupon/add-coupon/add-coupon.component';
import { CustomerAllOrderComponent } from './admin/component/customer/customer-all-order/customer-all-order.component';
import { ListAllReviewComponent } from './admin/component/review/list-all-review/list-all-review.component';
import { WaitingListComponent } from './admin/component/waiting-list/waiting-list/waiting-list.component';
import { NotificationComponent } from './admin/component/notification/notification.component';
import { WalletMonthWiseComponent } from './admin/component/astrologer/payout/wallet-month-wise/wallet-month-wise.component';
import { OrderMonthWiseComponent } from './admin/component/astrologer/payout/order-month-wise/order-month-wise.component';
import { TypebasedOrderMonthWiseComponent } from './admin/component/astrologer/payout/typebased-order-month-wise/typebased-order-month-wise.component';
import { PayoutComponent } from './admin/component/astrologer/payout/payout/payout.component';
import { NotesComponent } from './admin/component/notes/notes.component';
import { AssignPromoComponent } from './admin/component/assign-promo/assign-promo.component';
import { ListPromoOrdersComponent } from './admin/component/orders/list-promo-orders/list-promo-orders.component';
import { CommonAstroPageComponent } from './admin/component/astrologer/common-astro-page/common-astro-page.component';
import { CommonCustomerPageComponent } from './admin/component/customer/common-customer-page/common-customer-page.component';
import { CommonOrderPageComponent } from './admin/component/orders/common-order-page/common-order-page.component';
import { OffersComponent } from './admin/component/offers/offers.component';
import { AddOffersComponent } from './admin/component/offers/add-offers/add-offers.component';
import { ListOffersComponent } from './admin/component/offers/list-offers/list-offers.component';
import { CustomerWalletRechargeComponent } from './admin/component/customer-wallet-recharge/customer-wallet-recharge.component';
import { AddRechargeComponent } from './admin/component/customer-wallet-recharge/add-recharge/add-recharge.component';
import { ListRechargeComponent } from './admin/component/customer-wallet-recharge/list-recharge/list-recharge.component';
import { EditRechargeComponent } from './admin/component/customer-wallet-recharge/edit-recharge/edit-recharge.component';
import { EditOffersComponent } from './admin/component/offers/edit-offers/edit-offers.component';
import { AstroPayoutComponent } from './admin/component/astrologer/payout/astro-payout/astro-payout.component';
import { AstroOffersPriceComponent } from './admin/component/offers/astro-offers-price/astro-offers-price.component';
import { ChatDetailsComponent } from './admin/component/orders/chat-details/chat-details.component';
import { EditCustomerComponent } from './admin/component/customer/edit-customer/edit-customer.component';
import { CommonSupportComponent } from './admin/component/support/common-support/common-support.component';
import { CustomerSupportListComponent } from './admin/component/support/customer-support-list/customer-support-list.component';
import { AstrologerSupportListComponent } from './admin/component/support/astrologer-support-list/astrologer-support-list.component';
import { DetailAstrologerSupportComponent } from './admin/component/support/detail-astrologer-support/detail-astrologer-support.component';
import { DetailCustomerSupportComponent } from './admin/component/support/detail-customer-support/detail-customer-support.component';
import { ReplyCustomerSupportComponent } from './admin/component/support/reply-customer-support/reply-customer-support.component';
import { ReplyAstrologerSupportComponent } from './admin/component/support/reply-astrologer-support/reply-astrologer-support.component';
import { ViewNotificationComponent } from './admin/component/notification/view-notification/view-notification.component';
import { SendNotificationComponent } from './admin/component/notification/send-notification/send-notification.component';
import { CommonBlogCategoryComponent } from './admin/component/blog/category/common-blog-category/common-blog-category.component';
import { AddBlogCategoryComponent } from './admin/component/blog/category/add-blog-category/add-blog-category.component';
import { ListBlogCategoryComponent } from './admin/component/blog/category/list-blog-category/list-blog-category.component';
import { EditBlogCategoryComponent } from './admin/component/blog/category/edit-blog-category/edit-blog-category.component';
import { CommonBlogComponent } from './admin/component/blog/common-blog/common-blog.component';
import { AddCommonBlogComponent } from './admin/component/blog/add-common-blog/add-common-blog.component';
import { ListCommonBlogComponent } from './admin/component/blog/list-common-blog/list-common-blog.component';
import { EditCommonBlogComponent } from './admin/component/blog/edit-common-blog/edit-common-blog.component';
import { ListRejectBlogComponent } from './admin/component/blog/list-reject-blog/list-reject-blog.component';
import { ListNewBlogComponent } from './admin/component/blog/list-new-blog/list-new-blog.component';
import { ApproveRejectBlogComponent } from './admin/component/blog/approve-reject-blog/approve-reject-blog.component';
import { DetailBlogComponent } from './admin/component/blog/detail-blog/detail-blog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { AddFestivalComponent } from './admin/component/festival/add-festival/add-festival.component';
import { CommonFestivalComponent } from './admin/component/festival/common-festival/common-festival.component';
import { ListFestivalComponent } from './admin/component/festival/list-festival/list-festival.component';
import { EditFestivalComponent } from './admin/component/festival/edit-festival/edit-festival.component';
import { DetailFestivalComponent } from './admin/component/festival/detail-festival/detail-festival.component';
import { AddYearlyFestivalComponent } from './admin/component/festival/add-yearly-festival/add-yearly-festival.component';
import { EditYearlyFestivalComponent } from './admin/component/festival/edit-yearly-festival/edit-yearly-festival.component';
import { ListYearlyFestivalComponent } from './admin/component/festival/list-yearly-festival/list-yearly-festival.component';
import { AddMallCategoryComponent } from './admin/component/mall/category/add-mall-category/add-mall-category.component';
import { ListMallCategoryComponent } from './admin/component/mall/category/list-mall-category/list-mall-category.component';
import { EditMallCategoryComponent } from './admin/component/mall/category/edit-mall-category/edit-mall-category.component';
import { CommonMallCategoryComponent } from './admin/component/mall/category/common-mall-category/common-mall-category.component';
import { DetailMallCategoryComponent } from './admin/component/mall/category/detail-mall-category/detail-mall-category.component';
import { AddMallProductComponent } from './admin/component/mall/product/add-mall-product/add-mall-product.component';
import { ListMallProductComponent } from './admin/component/mall/product/list-mall-product/list-mall-product.component';
import { DetailMallProductComponent } from './admin/component/mall/product/detail-mall-product/detail-mall-product.component';
import { CommonMallProductComponent } from './admin/component/mall/product/common-mall-product/common-mall-product.component';
import { MatRadioModule } from '@angular/material/radio';
import { ForgotPasswordComponent } from './admin/component/forgot-password/forgot-password.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AddAssociateAstroComponent } from './admin/component/mall/product/add-associate-astro/add-associate-astro.component';
import { AddAssociateAddonComponent } from './admin/component/mall/product/add-associate-addon/add-associate-addon.component';
import { ListProductRequestsComponent } from './admin/component/mall/product/list-product-requests/list-product-requests.component';
import { UpdateMallProductComponent } from './admin/component/mall/product/update-mall-product/update-mall-product.component';
import { AddMallCouponComponent } from './admin/component/mall/coupon/add-mall-coupon/add-mall-coupon.component';
import { ListMallCouponComponent } from './admin/component/mall/coupon/list-mall-coupon/list-mall-coupon.component';
import { CommonMallCouponComponent } from './admin/component/mall/coupon/common-mall-coupon/common-mall-coupon.component';
import { ListStoreOrdersComponent } from './admin/component/orders/list-store-orders/list-store-orders.component';
import { StoreDetailComponent } from './admin/component/orders/store-detail/store-detail.component';
import { UpdateStoreOrderComponent } from './admin/component/orders/update-store-order/update-store-order.component';
import { EditMallCouponComponent } from './admin/component/mall/coupon/edit-mall-coupon/edit-mall-coupon.component';
import { DetailMallCouponComponent } from './admin/component/mall/coupon/detail-mall-coupon/detail-mall-coupon.component';
import { ListReportOrderNotRepliedComponent } from './admin/component/orders/list-report-order-not-replied/list-report-order-not-replied.component';
import { CommonInternationalPriceComponent } from './admin/component/international-price/common-international-price/common-international-price.component';
import { ListInternationalComponent } from './admin/component/international-price/list-international/list-international.component';
import { AddInternationalComponent } from './admin/component/international-price/add-international/add-international.component';
import { EditInternationalComponent } from './admin/component/international-price/edit-international/edit-international.component';
import { CancelAndReassignReportOrderQueryNotRepliedComponent } from './admin/component/orders/cancel-and-reassign-report-order-query-not-replied/cancel-and-reassign-report-order-query-not-replied.component';
import { ListReportQueryNotRepliedComponent } from './admin/component/orders/list-report-query-not-replied/list-report-query-not-replied.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './admin/services/messaging/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe, DatePipe } from '../../node_modules/@angular/common';
import { GroupchatOngoingComponent } from './admin/component/orders/groupchat-ongoing/groupchat-ongoing.component';
import { LightboxModule } from 'ngx-lightbox';
import { ScrollToBottomDirective } from './admin/directive/scroll-to-bottom/scroll-to-bottom.directive';
import { LastMonthAstroPayoutComponent } from './admin/component/astrologer/payout/last-month-astro-payout/last-month-astro-payout.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonMallSubcategoryComponent } from './admin/component/mall/subcategory/common-mall-subcategory/common-mall-subcategory.component';
import { AddMallSubcategoryComponent } from './admin/component/mall/subcategory/add-mall-subcategory/add-mall-subcategory.component';
import { ListMallSubcategoryComponent } from './admin/component/mall/subcategory/list-mall-subcategory/list-mall-subcategory.component';
import { EditMallSubcategoryComponent } from './admin/component/mall/subcategory/edit-mall-subcategory/edit-mall-subcategory.component';
import { DetailMallSubcategoryComponent } from './admin/component/mall/subcategory/detail-mall-subcategory/detail-mall-subcategory.component';
import { AddDirectReviewComponent } from './admin/component/review/add-direct-review/add-direct-review.component';
import { CommonReviewComponent } from './admin/component/review/common-review/common-review.component';
import { AstrologerNoticeBoardComponent } from './admin/component/customize/astrologer-notice-board/astrologer-notice-board.component';
import { AddAstrologerNoticeBoardComponent } from './admin/component/customize/add-astrologer-notice-board/add-astrologer-notice-board.component';
import { CustomerChatOrderComponent } from './admin/component/customer/customer-chat-order/customer-chat-order.component';
import { CommonSingleCustomerPageComponent } from './admin/component/customer/common-single-customer-page/common-single-customer-page.component';
import { AstroServiceLogsComponent } from './admin/component/astrologer/astro-service-logs/astro-service-logs.component';
import { RazorpayRechargeListComponent } from './admin/component/admin-wallet/razorpay-recharge-list/razorpay-recharge-list.component';
import { CommonAdminWalletComponent } from './admin/component/admin-wallet/common-admin-wallet/common-admin-wallet.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomerIssueListComponent } from './admin/component/customer/customer-issue-list/customer-issue-list.component';
import { SinglecustomerSupportListComponent } from './admin/component/customer/singlecustomer-support-list/singlecustomer-support-list.component';
import { RazorpayRechargeInternationalListComponent } from './admin/component/admin-wallet/razorpay-recharge-international-list/razorpay-recharge-international-list.component';
import { SuportMessageComponent } from './admin/component/suport-message/suport-message.component';
import { IssueMessageComponent } from './admin/component/issue-message/issue-message.component';
import { DetailCouponComponent } from './admin/component/coupon/detail-coupon/detail-coupon.component';
import { AddCustomerNoticeBoardComponent } from './admin/component/customize/add-customer-notice-board/add-customer-notice-board.component';
import { CustomerNoticeBoardComponent } from './admin/component/customize/customer-notice-board/customer-notice-board.component';
import { EditCouponComponent } from './admin/component/coupon/edit-coupon/edit-coupon.component';
import { AddDynamicPageComponent } from './admin/component/dynamic-pages/add-dynamic-page/add-dynamic-page.component';
import { ListDynamicPageComponent } from './admin/component/dynamic-pages/list-dynamic-page/list-dynamic-page.component';
import { EditDynamicpageComponent } from './admin/component/dynamic-pages/edit-dynamicpage/edit-dynamicpage.component';
import { DetailDynamicPageComponent } from './admin/component/dynamic-pages/detail-dynamic-page/detail-dynamic-page.component';
import { WorkHistoryComponent } from './admin/component/astrologer/work-history/work-history.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ListRemedyProductComponent } from './admin/component/mall/product/list-remedy-product/list-remedy-product.component';
@NgModule({
  declarations: [
    WorkHistoryComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    HorizontalMenuComponent,
    MainDashboardComponent,
    SidebarComponent,
    AddEditAstrologerProfileComponent,
    ViewAstrologerProfileComponent,
    LoginComponent,
    LanguagesListComponent,
    EditLanguageComponent,
    SkillsListComponent,
    EditSkillsComponent,
    AstrologersListComponent,
    AddCustomerComponent,
    ListCustomerComponent,
    ViewCustomerComponent,
    ListUsersComponent,
    ViewUserComponent,
    ChangePasswordComponent,
    ListCustomerUsersComponent,
    ListAstroUsersComponent,
    AstroChargesUpdateComponent,
    AddEditReportComponent,
    ListReportsComponent,
    ShowSignleCustomerDetailComponent,
    WalletHistoryComponent,
    CreditDebitWalletAmountComponent,
    ListBannerComponent,
    AddBannerComponent,
    ListOrdersComponent,
    ListChatordersComponent,
    ListQueryordersComponent,
    QueryDetailComponent,
    ListReportordersComponent,
    ReportDetailComponent,
    AllIssueComponent,
    CustomerIssueComponent,
    AstrologerIssueComponent,
    ReplyAstrologerIssueComponent,
    ReplyCustomerIssueComponent,
    DetailCustomerIssueComponent,
    DetailAstrologerIssueComponent,
    AllRemedyComponent,
    SuggestRemedyComponent,
    DebitWalletAmountComponent,
    CustomerReportOrderComponent,
    CustomerQueryOrderComponent,
    CustomerWalletRechargeOrderComponent,
    HasRoleDirective,
    AddAstrologerOpenComponent,
    ListCallordersComponent,
    CallDetailsComponent,
    ViewManagerComponent,
    AddManagerComponent,
    ListManagerComponent,
    ListRolesComponent,
    AddRolesComponent,
    DetailManagerComponent,
    ChargesUpdateListComponent,
    ApproveRejectChargesComponent,
    ProfileUpdateListComponent,
    ApproveRejectProfileComponent,
    AstroWalletHistoryComponent,
    CreditAstroWalletAmountComponent,
    DebitAstroWalletAmountComponent,
    EditRolesComponent,
    EditManagerComponent,
    ManagerLoginComponent,
    AddAdminWalletComponent,
    ListAdminWalletComponent,
    AstroLoginComponent,
    CustomerLoginLogComponent,
    CustomerCallOrderComponent,
    EditAstrologerComponent,
    CustomizeComponent,
    ListCouponComponent,
    AddCouponComponent,
    CustomerAllOrderComponent,
    ListAllReviewComponent,
    WaitingListComponent,
    NotificationComponent,
    WalletMonthWiseComponent,
    OrderMonthWiseComponent,
    TypebasedOrderMonthWiseComponent,
    PayoutComponent,
    NotesComponent,
    AssignPromoComponent,
    ListPromoOrdersComponent,
    CommonAstroPageComponent,
    CommonCustomerPageComponent,
    CommonOrderPageComponent,
    OffersComponent,
    AddOffersComponent,
    ListOffersComponent,
    CustomerWalletRechargeComponent,
    AddRechargeComponent,
    ListRechargeComponent,
    EditRechargeComponent,
    EditOffersComponent,
    AstroPayoutComponent,
    AstroOffersPriceComponent,
    ChatDetailsComponent,
    EditCustomerComponent,
    CommonSupportComponent,
    CustomerSupportListComponent,
    AstrologerSupportListComponent,
    DetailAstrologerSupportComponent,
    DetailCustomerSupportComponent,
    ReplyCustomerSupportComponent,
    ReplyAstrologerSupportComponent,
    ViewNotificationComponent,
    SendNotificationComponent,
    CommonBlogCategoryComponent,
    AddBlogCategoryComponent,
    ListBlogCategoryComponent,
    EditBlogCategoryComponent,
    CommonBlogComponent,
    AddCommonBlogComponent,
    ListCommonBlogComponent,
    EditCommonBlogComponent,
    ListRejectBlogComponent,
    ListNewBlogComponent,
    ApproveRejectBlogComponent,
    DetailBlogComponent,
    AddFestivalComponent,
    CommonFestivalComponent,
    ListFestivalComponent,
    EditFestivalComponent,
    DetailFestivalComponent,
    AddYearlyFestivalComponent,
    EditYearlyFestivalComponent,
    ListYearlyFestivalComponent,
    AddMallCategoryComponent,
    ListMallCategoryComponent,
    EditMallCategoryComponent,
    CommonMallCategoryComponent,
    DetailMallCategoryComponent,
    AddMallProductComponent,
    ListMallProductComponent,
    DetailMallProductComponent,
    CommonMallProductComponent,
    ForgotPasswordComponent,
    AddAssociateAstroComponent,
    AddAssociateAddonComponent,
    ListProductRequestsComponent,
    UpdateMallProductComponent,
    AddMallCouponComponent,
    ListMallCouponComponent,
    CommonMallCouponComponent,
    ListStoreOrdersComponent,
    StoreDetailComponent,
    UpdateStoreOrderComponent,
    EditMallCouponComponent,
    DetailMallCouponComponent,
    ListReportOrderNotRepliedComponent,
    CommonInternationalPriceComponent,
    ListInternationalComponent,
    AddInternationalComponent,
    EditInternationalComponent,
    CancelAndReassignReportOrderQueryNotRepliedComponent,
    ListReportQueryNotRepliedComponent,
    GroupchatOngoingComponent,
    ScrollToBottomDirective,
    LastMonthAstroPayoutComponent,
    CommonMallSubcategoryComponent,
    AddMallSubcategoryComponent,
    ListMallSubcategoryComponent,
    EditMallSubcategoryComponent,
    DetailMallSubcategoryComponent,
    AddDirectReviewComponent,
    CommonReviewComponent,
    AstrologerNoticeBoardComponent,
    AddAstrologerNoticeBoardComponent,
    CustomerChatOrderComponent,
    CommonSingleCustomerPageComponent,
    AstroServiceLogsComponent,
    RazorpayRechargeListComponent,
    CommonAdminWalletComponent,
    CustomerIssueListComponent,
    SinglecustomerSupportListComponent,
    RazorpayRechargeInternationalListComponent,
    SuportMessageComponent,
    IssueMessageComponent,
    DetailCouponComponent,
    AddCustomerNoticeBoardComponent,
    CustomerNoticeBoardComponent,
    EditCouponComponent,
    AddDynamicPageComponent,
    ListDynamicPageComponent,
    EditDynamicpageComponent,
    DetailDynamicPageComponent,
    WorkHistoryComponent,
    ListRemedyProductComponent,
  ],
  imports: [
    PickerModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule,
    HttpClientModule,
    NgbModule,
    TagInputModule,
    MatSlideToggleModule,
    NgxCaptchaModule,
    EditorModule,
    MatAutocompleteModule,
    NgSelectModule,
    NgOptionHighlightModule,
    MatRadioModule,
    NgOtpInputModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    LightboxModule,
    SlickCarouselModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [MessagingService, AsyncPipe,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
