<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Charges</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/request/charges']">Charges</a></li>
            <li class="breadcrumb-item active" aria-current="page">Update Charges</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="updateChargesForm" (submit)="onUpdateCharges()">
                            <div class="row mb-2">
                                <div class="col-md-4"><strong>Astrologer:</strong> #{{dataList?.astroAutoId}}-{{(dataList.displayname == '' || dataList.displayname == undefined || dataList.displayname == null) ? dataList.astroId?.name : dataList.displayname}}</div>
                                <div class="col-md-4"><strong>Service Type:</strong> <span class="text-uppercase badge badge-primary">{{dataList?.charges_logs[0]?.charge_type}}</span></div>
                                <div class="col-md-4"><strong>Date:</strong> {{dataList?.charges_logs[0]?.updatedDate | date:'medium'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-md-4"><strong>Charges:</strong> {{dataList?.charges_logs[0]?.last_charge | currency:'INR':'symbol-narrow'}}</div>
                                <div class="col-md-4"><strong>Updated Charges: </strong> {{dataList?.charges_logs[0]?.new_charge | currency:'INR':'symbol-narrow'}}</div>
                                <div class="col-md-4"><strong>Comment:</strong> {{dataList?.charges_logs[0]?.change_comment}}</div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="remedy_name">Status</label>
                                        <select class="form-control" (change)="statusOfRequest($event)">
                                            <option value="true" selected>Approve</option>
                                            <option value="false">Reject</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="admin_comment">Comments</label>
                                        <input type="text" class="form-control " name="admin_comment"
                                            formControlName="admin_comment" placeholder="Comments">
                                        <div *ngIf="f.admin_comment.invalid && (f.admin_comment.dirty || f.admin_comment.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="user-wrap d-flex justify-content-center">
                                        <button type="submit()" class="btn-form-custom col-4 btn btn-success mt-1 mb-2"
                                            [disabled]="!updateChargesForm.valid">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Charges Update Logs</h3>
                </div>
                <div class="card-body">
                    <ngx-datatable class="material" [rows]="chargeLogs" [columnMode]="'force'" [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
                        [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                        <ngx-datatable-column [flexGrow]="1" name="Charges" prop="last_charge">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.last_charge | currency:'INR':'symbol-narrow'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Updated Charges" prop="new_charge">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.new_charge | currency:'INR':'symbol-narrow'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Type" prop="charge_type">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="text-uppercase badge badge-primary">{{row.charge_type}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Status">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="badge badge-warning" *ngIf="row.is_new_request">Pending</span>
                                <span class="badge badge-success"
                                    *ngIf="!row.is_new_request && row.change_approved">Approved</span>
                                <span class="badge badge-primary"
                                    *ngIf="!row.is_new_request && !row.change_approved">Rejected</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="3" name="Astro Remarks">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.change_comment}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Updated At">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.updatedDate | date:'medium'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Action At">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.approvalDate | date:'medium'}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->