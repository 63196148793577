import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { OfferServiceService } from 'src/app/admin/services/offers/offer-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-astro-offers-price',
  templateUrl: './astro-offers-price.component.html',
  styleUrls: ['./astro-offers-price.component.scss']
})
export class AstroOffersPriceComponent implements OnInit {

  astroList:any = ''
  astroOfferPriceList:any = ''
  astroData:any = ''
  astroSelected = null
  BaseUrl: string;
  constructor(
    private astroService:AstroServiceService,
    private toast: ToastrService,
    private offerService: OfferServiceService,
    private userdata: UserdataService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngOnInit(): void {
    this.getAstrologerList()
  }

  getAstrologerList(){
    this.astroService.getAstrologerList({is_approved:true}).subscribe(
      (res:any)=>{
        this.astroList = res.data
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getAstroOfferPrice(){
    //console.log(this.astroSelected)
    this.offerService.astroPriceOffer({userId:this.userdata.getId(),astroId:this.astroSelected}).subscribe(
      (res:any)=>{
        this.astroOfferPriceList = res.data
        this.astroData = res.astro
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }
  checkIfOfferIsActive(id: any){
    if(this.astroData?.astroofferId?.call_offerId === id){
      return true
    }else if(this.astroData?.astroofferId?.chat_offerId === id){
      return true
    }else if(this.astroData?.astroofferId?.query_offerId === id){
      return true
    }else if(this.astroData?.astroofferId?.report_offerId === id){
      return true
    }else{
      return false
    }
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
}
