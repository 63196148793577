<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Blog</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/blog/list']">Blogs</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12 col-md-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between align-items-baseline">
                                                        <h4>Blog Details </h4>
                                                        <span class="badge badge-success" *ngIf="data.isApproved && data.is_used">APPROVED</span>
                                                        <span class="badge badge-danger" *ngIf="!data.isApproved && data.is_used" [title]="'Admin Reason: '+data.adminComments">REJECTED</span>
                                                        <span class="badge badge-warning" *ngIf="!data.isApproved && !data.is_used">PENDING</span>
                                                        <a [routerLink]="['/admin/blogs/',data._id]" class="btn btn-sm btn-primary">Edit</a>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="table row table-borderless">
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong>Title :</strong> {{data.blogTitle}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Slug :</strong> {{data.slug}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>URL :</strong> {{data.blogUrl}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td>
                                                                        <!-- <strong>Image :</strong>  -->
                                                                        <img [src]="getSanitizeUrl(data.blogImage)" class="img img-fluid"/></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr class="d-block">
                                                                    <td class="d-block"><strong>Brief Description :</strong> <div [innerHTML]="data.blogsmallDescription" class="border"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr class="d-block">
                                                                    <td class="d-block"><strong>Description :</strong> <div [innerHTML]="data.blogDescription" class="border"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->