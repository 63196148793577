import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import { UpdateRequestServiceService } from 'src/app/admin/services/update-request/update-request-service.service';

@Component({
  selector: 'app-charges-update-list',
  templateUrl: './charges-update-list.component.html',
  styleUrls: ['./charges-update-list.component.scss']
})
export class ChargesUpdateListComponent implements OnInit {

  constructor(
    private updateService: UpdateRequestServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  chargeList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getChargeUpdateList()
  }

  /* ***********
  list All Charge Update Request
  *********** */

  getChargeUpdateList() {
    this.spinner.show()
    this.updateService.getChargeRequest({ userId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chargeList = res.data;
        //console.log(this.chargeList)
        this.temp = this.chargeList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.displayname.toLowerCase().indexOf(val) !== -1 ||
        d.charges_logs.last_charge.toString().indexOf(val) !== -1 ||
        d.charges_logs.new_charge.toString().indexOf(val) !== -1 ||
        d.charges_logs.charge_type.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.chargeList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }
}
