<div class="card-body">
    <div class="border-0">
        <div class="tab-content">
            <div class="tab-pane active show" id="tab-51">
                <div id="profile-log-switch">
                    <div class="media-heading">
                        <h4>Default Profile - ID #{{data.customerAutoId}}
                            <a [routerLink]="['/admin/customers/editCustomer', data._id]" class="btn btn-primary ml-3">Edit</a>
                        </h4>
                    </div>
                    <hr class="m-0">
                    <div class="table-responsive ">
                        <table class="table row table-borderless">
                            <tbody class="col-lg-3 col-xl-3 p-0">
                                <tr>
                                    <td><strong>Full Name :</strong> {{data.userId?.name}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Email :</strong> {{data.userId?.email}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Phone :</strong> +{{data.country_code}}-{{data?.phone}}<span class="badge badge-pill badge-primary"> {{data.regCountryName}}</span></td>
                                </tr>
                            </tbody>
                            <tbody class="col-lg-3 col-xl-3 p-0">
                                <tr>
                                    <td><strong>OTP Verified :</strong> {{!data.is_otp_verified ?'No': 'YES'}} <span *ngIf="!data.is_otp_verified && data.register_otp != 0"> &nbsp;({{data.register_otp}})</span></td>
                                </tr>
                                <tr>
                                    <td><strong>Email Verified :</strong> {{!data.is_email_verified ?'No': 'YES'}}<span *ngIf="!data.is_email_verified && data.register_otp != 0"> &nbsp;({{data.register_otp}})</span></td>
                                </tr>
                                <tr>
                                    <td><strong>Blocked :</strong> {{!data.is_blocked ?'No': 'YES'}}</td>
                                </tr>
                            </tbody>
                            <tbody class="col-lg-3 col-xl-3 p-0">
                                <tr>
                                    <td><strong>Promo Allowed :</strong> {{!data.is_allow_promotional ?'No': 'YES'}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Promotional Used  :</strong> {{!data.is_promotional_complete ?'No': 'YES'}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Current Waiting :</strong> {{!data.is_waiting ?'No': 'YES'}}</td>
                                </tr>
                            </tbody>
                            <tbody class="col-lg-3 col-xl-3 p-0">
                                <tr>
                                    <td><strong>Current Call :</strong> &nbsp; <span class="badge badge-info">  {{!data.is_current_call ?'No': 'YES'}}</span></td>
                                </tr>
                                <tr>
                                    <td><strong>Current Chat :</strong> &nbsp; <span class="badge badge-info">  {{!data.is_current_chat ?'No': 'YES'}}</span></td>
                                </tr>
                                <tr *ngIf="data.is_social_login">
                                    <td><strong>Social Login :</strong> &nbsp; <span class="badge badge-info">  {{data.social_type == 1 ?'Google': (data.social_type == 2 ? 'Facebook':'Apple')}}</span></td>
                                </tr>
                                <!-- <tr>
                                    <td><strong>Revoke Promotional :</strong> {{!data.is_blocked ?'No': 'YES'}}</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-xl-12">
            <div class="media-heading">
                <h4>Other Profile</h4>
            </div>
            <hr class="m-0 mb-4">
        </div>
        <div class="col-md-6  col-xl-6" *ngFor="let person of data.personIds">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">{{person.personId.detail_name}} <button (click)='hideCustomerProfile(person.personId._id)' class="btn btn-primary btn-sm ml-3" *ngIf="!person.personId.is_hide">Delete Profile</button>
                        <span class="badge badge-danger badge-pill ml-3" *ngIf="person.personId.is_hide">Deleted</span>
                    </h3>
                    <div class="card-options">
                        <a [routerLink]="['/admin/customers/showIndividualCustomerDetail/', person.personId._id]" class="btn btn-primary btn-sm">View More...</a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive ">
                        <table class="table row table-borderless">
                            <tbody class="col-lg-12 col-xl-6 p-0">
                                <tr>
                                    <td><strong>Name :</strong> {{person.personId.name}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Gender :</strong> {{person.personId.gender}}</td>
                                </tr>
                            </tbody>
                            <tbody class="col-lg-12 col-xl-6 p-0">
                                <tr>
                                    <td><strong>Date of Birth:</strong> {{person.personId.dob}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Time:</strong> {{person.personId.tob}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Birth Place:</strong> {{person.personId.city}}, {{person.personId.state}}, {{person.personId.country}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div class="user-wrap">
        <button class="btn btn-primary mt-1 mb-2" routerLink="/admin/customersList">Go to List of Customers</button>
    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="container content-area relative">


    <div class="page-header">
        <h4 class="page-title">Customer</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/customersList']">Customer</a></li>
            <li class="breadcrumb-item active" aria-current="page">View</li>
        </ol>
    </div>

    <ngx-spinner></ngx-spinner>

    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li ngbNavItem>
                                        <a ngbNavLink>Details</a>
                                        <ng-template ngbNavContent>
                                            <br>

                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Wallet</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-wallet-history></app-wallet-history>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Add Amount</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-credit-debit-wallet-amount></app-credit-debit-wallet-amount>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Deduct Amount</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-debit-wallet-amount></app-debit-wallet-amount>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>All Orders</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-customer-all-order></app-customer-all-order>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Calls</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-customer-call-order></app-customer-call-order>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Reports</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-customer-report-order></app-customer-report-order>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Queries</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-customer-query-order></app-customer-query-order>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Recharges</a>
                                        <ng-template ngbNavContent>
                                            <br>
                                            <div class="wideget-user-followers mb-0">
                                                <app-customer-wallet-recharge-order></app-customer-wallet-recharge-order>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink>Logs</a>
                                        <ng-template ngbNavContent>
                                            <app-customer-login-log></app-customer-login-log>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div> -->
