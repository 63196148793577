<!-- CONTAINER -->
<div class="container content-area relative">

  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Astro Shop - Products</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/mall-product/list']">Product List</a></li>
      <li class="breadcrumb-item active" aria-current="page">Add Addons</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!-- ----------------------------------- -->
            <!-- ----------------------------------- -->
            <form [formGroup]="formGroup" (submit)="addProductAddon()">
              <table class="table table-bordered" formArrayName="addonsList">
                <tr>
                  <th colspan="3">Add Multiple Addons:</th>
                  <th width="150px"><button type="button" (click)="addAddonsList()"
                      class="btn-user-custom btn btn-outline-primary btn-sm">Add
                      More</button></th>
                </tr>
                <tr *ngFor="let list of addonsList().controls; let i=index" [formGroupName]="i">
                  <td>
                    <div class="col-md-12">
                      <div>
                        <label for="">Is size required?</label>
                        <input type="checkbox" formControlName="addon_type" class="form-control"
                          placeholder="Enter Addon Name">
                        <!-- <div *ngIf="f.addon_type.invalid && (f.addon_type.dirty || f.addon_type.touched)"
                            class="alert alert-danger mt-1">
                            Required
                          </div> -->
                      </div>
                      <div class="form-group">
                        <label for="addon_name">Name</label>
                        <input type="text" formControlName="addon_name" class="form-control"
                          placeholder="Enter Addon Name">
                        <!-- <div *ngIf="f.addon_name.invalid && (f.addon_name.dirty || f.addon_name.touched)"
                          class="alert alert-danger mt-1">
                          Required
                        </div> -->
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Image * (Image should be 960 X 540)</label>
                        <input type="file" class="form-control" placeholder="Addon Image"
                          (change)="onAddonImageUpload($event)">
                        <!-- <small
                                        *ngIf="f.addon_image.invalid && (f.addon_image.dirty || f.addon_image.touched)"
                                        class="d-block alert alert-danger mt-1">
                                        Required.
                                    </small> -->
                        <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                          {{imageError}}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Price (In Rupees) *(only numbers are allowed)</label>
                        <input type="text" formControlName="addon_price" class="form-control"
                          placeholder="Enter Final Price in Rupees">
                        <!-- <div *ngIf="f.addon_price.invalid && (f.addon_price.dirty || f.addon_price.touched)"
                          class="alert alert-danger mt-1">
                          Required. Only Numbers are allowed.
                        </div> -->
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <div class="form-group">
                        <button (click)="removeAddonsList(i)"
                          class="btn-user-custom btn btn-outline-danger btn-sm">Remove</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <button type="submit()" class="btn btn-primary mt-1 mb-2" [disabled]="!formGroup.valid"><i
                  class="fa fa-rss"></i> Submit</button>
            </form>
            <!-- ----------------------------------- -->
            <!-- ----------------------------------- -->
          </div>
        </div>
      </div>
    </div><!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
