<!-- CONTAINER -->
<div class="container content-area relative">
  <!-- PAGE-HEADER -->
  <div class="page-header">
    <h4 class="page-title">Issue</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item" aria-current="page">
        <a (click)="goBack()" style="cursor: pointer">>Astrologer Issue</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Details</li>
    </ol>
  </div>
  <!-- PAGE-HEADER END -->
  <ngx-spinner></ngx-spinner>
  <!-- ROW-1 OPEN -->
  <div class="row" id="user-profile">
    <div class="col-lg-8 col-md-4">
      <div class="card">
        <div class="border-top">
          <div class="wideget-user-tab">
            <div class="tab-menu-heading">
              <div class="tabs-menu1">
                <div class="card-body">
                  <div class="border-0">
                    <div class="tab-content">
                      <div class="tab-pane active show" id="tab-51">
                        <div id="profile-log-switch">
                          <div
                            class="media-heading d-flex justify-content-between"
                          >
                            <h4>Issue Details</h4>
                            <button
                              class="btn btn-primary btn-sm"
                              [routerLink]="[
                                '/admin/issues/astrologer/reply',
                                data._id
                              ]"
                              *ngIf="!data.is_issue_solved"
                            >
                              Reply
                            </button>
                          </div>
                          <hr class="m-0" />
                          <div class="table-responsive">
                            <table class="table row table-borderless">
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong>Issue ID :</strong> #{{
                                      data.issueId
                                    }}
                                    <span
                                      class="badge badge-primary ml-2"
                                      *ngIf="data.is_wallet_issue"
                                      >WALLET ISSUE</span
                                    ><span
                                      class="badge badge-info ml-2"
                                      *ngIf="data.is_service_issue"
                                      >SERVICE ISSUE</span
                                    >
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1">Amount :</strong
                                    >{{
                                      data.amount
                                        | currency: "INR":"symbol-narrow"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1">Issue Title :</strong
                                    >{{ data.issue_title }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong class="mr-1"
                                      >Issue Description :</strong
                                    >{{ data.issue_description }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong class="mr-1">Created On :</strong
                                    >{{ data.created_at | date: "medium" }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="col-lg-6 col-xl-6 p-0">
                                <tr>
                                  <td>
                                    <strong class="mr-1">Status :</strong
                                    ><span
                                      class="badge badge-success"
                                      *ngIf="data.is_issue_solved"
                                      >SOLVED</span
                                    ><span
                                      class="badge badge-danger"
                                      *ngIf="!data.is_issue_solved"
                                      >PENDING</span
                                    >
                                  </td>
                                </tr>
                                <tr *ngIf="data.is_reply_by_system">
                                  <td>
                                    <strong class="mr-1">Solution :</strong
                                    >{{ data.reply_by_system }}
                                  </td>
                                </tr>
                                <tr *ngIf="data.is_reply_by_system">
                                  <td>
                                    <strong class="mr-1">Reply On :</strong
                                    >{{
                                      data.replyupdatedDate | date: "medium"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="chatList?.length > 0">
        <div class="card-header"><h4>Reply History</h4></div>
        <div
          class="card-body msg_card_body"
          style="overflow-y: scroll !important; height: 400px !important"
          #scrollMe
          [scrollTop]="chatList.length === 0 ? 0 : scrollMe.scrollHeight"
        >
          <ng-container *ngFor="let chat of chatList">
            <div
              *ngIf="chat.isCustomerReply || chat.isAstroReply"
              class="media media-chat"
            >
              <img
                style="border-radius: 50%"
                class="avatar"
                src="https://img.icons8.com/color/36/000000/administrator-male.png"
                alt="..."
              />
              <div class="media-body admin">
                <p class="inline-block">{{ chat.new_reply }}</p>
                <p class="meta">
                  <time datetime="2018">{{
                    chat.replyDate | date: "medium"
                  }}</time>
                </p>
              </div>
            </div>
            <div
              *ngIf="chat.isSystemReply"
              class="media media-chat media-chat-reverse"
            >
              <div class="media-body reply">
                <p class="float-right">{{ chat.new_reply }}</p>
                <br />
                <p class="meta float-right">
                  <time datetime="2018">{{
                    chat.replyDate | date: "medium"
                  }}</time>
                </p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- COL-END -->
    <div class="col-lg-4 col-md-4">
      <div class="card productdesc">
        <div class="card-body">
          <div class="panel panel-primary">
            <div class="tab-menu-heading">
              <div
                class="tabs-menu"
                [routerLink]="[
                  '/admin/astrologers/viewAstrologer',
                  data.astroId?._id
                ]"
              >
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                  <li class="custom-tab">
                    <a href="javascript:void(0)" class="active" data-toggle="tab"
                      >Astrologer Details</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="panel-body tabs-menu-body">
              <div class="tab-content">
                <div class="tab-pane active" id="tab1">
                  <!-- <h4 class="mb-5 mt-3">General</h4> -->
                  <ul class="list-unstyled mb-0">
                    <li class="row">
                      <div class="col-sm-3 text-muted">ID</div>
                      <div class="col-sm-9">
                        <a
                          [routerLink]="[
                            '/admin/astrologers/viewAstrologer',
                            data.astroId?._id
                          ]"
                          >#{{ data.astroId?.astroAutoId }}</a
                        >
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Display Name</div>
                      <div class="col-sm-9">
                        {{ data.astroId?.displayname }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Name</div>
                      <div class="col-sm-9">{{ data.astroId?.name }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Email</div>
                      <div class="col-sm-9">{{ data.astroId?.email }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Status</div>
                      <div class="col-sm-9">
                        <span
                          *ngIf="data.astroId?.is_current_active"
                          class="badge badge-success"
                          >Online</span
                        ><span
                          *ngIf="!data.astroId?.is_current_active"
                          class="badge badge-danger"
                          >Offline</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card productdesc" *ngIf="data.is_service_issue">
        <div class="card-body">
          <div class="panel panel-primary">
            <div class="tab-menu-heading">
              <div class="tabs-menu">
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                  <li class="custom-tab">
                    <a href="javascript:void(0)" class="active" data-toggle="tab"
                      >{{ serviceType }} Details</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="panel-body tabs-menu-body">
              <div class="tab-content">
                <div class="tab-pane active" id="tab1">
                  <!-- <h4 class="mb-5 mt-3">General</h4> -->
                  <ul
                    class="list-unstyled mb-0"
                    *ngIf="
                      data.orderserviceId.is_query_order ||
                      data.orderserviceId.is_report_order
                    "
                  >
                    <li class="row">
                      <div class="col-sm-3 text-muted">ID</div>
                      <div class="col-sm-9">
                        #{{
                          serviceData.oquestionId
                            ? serviceData.oquestionId
                            : serviceData.oreportId
                        }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Details</div>
                      <div class="col-sm-9">
                        {{
                          serviceData.question
                            ? serviceData.question
                            : serviceData.reportId?.reportname
                        }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Status</div>
                      <div class="col-sm-9">
                        <span
                          *ngIf="!serviceData.is_astro_answer"
                          class="badge badge-danger"
                          >Not Answered</span
                        ><span
                          *ngIf="serviceData.is_astro_answer"
                          class="badge badge-success"
                          >Answered</span
                        >
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Date</div>
                      <div class="col-sm-9">
                        {{ serviceData.created_at | date: "medium" }}
                      </div>
                    </li>
                  </ul>
                  <ul
                    class="list-unstyled mb-0"
                    *ngIf="data.orderserviceId.is_call_order"
                  >
                    <li class="row">
                      <div class="col-sm-3 text-muted">ID</div>
                      <div class="col-sm-9">#{{ serviceData.ocallId }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Details</div>
                      <div class="col-sm-9">
                        <span
                          class="badge badge-info"
                          *ngIf="serviceData.is_promotional_call"
                        >
                          PROMO
                        </span>
                        <span
                          class="badge badge-info"
                          *ngIf="serviceData.is_report_promo_call"
                          >REPORT PROMO</span
                        >
                        <span
                          class="badge badge-info"
                          *ngIf="serviceData.is_direct_promo_call"
                          >ADMIN PROMO</span
                        >
                        <table
                          class="table table-bordered"
                          *ngIf="
                            !serviceData.is_promotional_call &&
                            !serviceData.is_report_promo_call &&
                            !serviceData.is_direct_promo_call
                          "
                        >
                          <tr>
                            <td>Astro Amnt.</td>
                            <td>
                              {{
                                serviceData.astro_amount
                                  | currency: "INR":"symbol-narrow"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Customer Amnt.</td>
                            <td>
                              {{
                                serviceData.customer_amount
                                  | currency: "INR":"symbol-narrow"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>AR Amnt.</td>
                            <td>
                              {{
                                serviceData.company_amount
                                  | currency: "INR":"symbol-narrow"
                              }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Duration</div>
                      <div class="col-sm-9">
                        {{ convertSecondstoTime(serviceData.callDuration) }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Status</div>
                      <div class="col-sm-9">
                        <span class="text-uppercase badge badge-primary">{{
                          serviceData.order_current_status
                        }}</span>
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Date</div>
                      <div class="col-sm-9">
                        {{ serviceData.created_at | date: "medium" }}
                      </div>
                    </li>
                  </ul>
                  <ul
                    class="list-unstyled mb-0"
                    *ngIf="data.orderserviceId.is_chat_order"
                  >
                    <li class="row">
                      <div class="col-sm-3 text-muted">ID</div>
                      <div class="col-sm-9">#{{ serviceData.ochatId }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Details</div>
                      <div class="col-sm-9">
                        <span
                          class="badge badge-info"
                          *ngIf="serviceData.is_promotional_chat"
                        >
                          PROMO
                        </span>
                        <span
                          class="badge badge-info"
                          *ngIf="serviceData.is_report_promo_chat"
                          >REPORT PROMO</span
                        >
                        <span
                          class="badge badge-info"
                          *ngIf="serviceData.is_direct_promo_chat"
                          >ADMIN PROMO</span
                        >
                        <table
                          class="table table-bordered"
                          *ngIf="
                            !serviceData.is_promotional_chat &&
                            !serviceData.is_report_promo_chat &&
                            !serviceData.is_direct_promo_chat
                          "
                        >
                          <tr>
                            <td>Astro Amnt.</td>
                            <td>
                              {{
                                serviceData.astro_amount
                                  | currency: "INR":"symbol-narrow"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Customer Amnt.</td>
                            <td>
                              {{
                                serviceData.customer_amount
                                  | currency: "INR":"symbol-narrow"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>AR Amnt.</td>
                            <td>
                              {{
                                serviceData.company_amount
                                  | currency: "INR":"symbol-narrow"
                              }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Duration</div>
                      <div class="col-sm-9">
                        {{
                          convertSecondstoTime(serviceData.totalchatDuration)
                        }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Status</div>
                      <div class="col-sm-9">
                        <span class="text-uppercase badge badge-primary">{{
                          serviceData.order_current_status
                        }}</span>
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Date</div>
                      <div class="col-sm-9">
                        {{ serviceData.created_at | date: "medium" }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card productdesc" *ngIf="data.is_wallet_issue">
        <div class="card-body">
          <div class="panel panel-primary">
            <div class="tab-menu-heading">
              <div class="tabs-menu">
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                  <li class="custom-tab">
                    <a href="javascript:void(0)" class="active" data-toggle="tab"
                      >Wallet Details</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="panel-body tabs-menu-body">
              <div class="tab-content">
                <div class="tab-pane active" id="tab1">
                  <!-- <h4 class="mb-5 mt-3">General</h4> -->
                  <ul class="list-unstyled mb-0">
                    <li class="row">
                      <div class="col-sm-3 text-muted">ID</div>
                      <div class="col-sm-9">
                        #{{
                          data.walletId.cwalletId
                            ? data.walletId.cwalletId
                            : data.walletId.awalletId
                        }}
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Amount</div>
                      <div class="col-sm-9">{{ data.walletId.amount }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Status</div>
                      <div class="col-sm-9">
                        <span
                          *ngIf="data.walletId.txn_type == 2"
                          class="badge badge-danger"
                          >Deducted</span
                        ><span
                          *ngIf="data.walletId.txn_type == 1"
                          class="badge badge-success"
                          >Recharged</span
                        >
                      </div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Balance</div>
                      <div class="col-sm-9">{{ data.walletId.balance }}</div>
                    </li>
                    <li class="row">
                      <div class="col-sm-3 text-muted">Date</div>
                      <div class="col-sm-9">
                        {{ data.walletId.created_at | date: "medium" }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COL-END -->
  </div>
  <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
