import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/admin/services/blog/blog-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-approve-reject-blog',
  templateUrl: './approve-reject-blog.component.html',
  styleUrls: ['./approve-reject-blog.component.scss']
})
export class ApproveRejectBlogComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private blogService: BlogServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router,
  ) { }

  data: any = '';
  id: any = '';


  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.formGroup.patchValue({ _id: this.id });
    this.formGroup.patchValue({ updatedId: this.userdata.getId() });
    this.formGroup.patchValue({ updatedBy: this.userdata.getName() });
    this.getDataById()
  }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    updatedId: new FormControl('', Validators.required),
    updatedBy: new FormControl('', Validators.required),
    adminComments: new FormControl('', [Validators.required]),
    isApproved: new FormControl(true, [Validators.required]),
    is_used: new FormControl(true),
  });


  getDataById() {
    this.spinner.show()
    this.blogService.getSingleBlog({ _id: this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()        
        this.data = res.data
      },
      err => {
        this.spinner.hide()
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Approve Reject Blog
 *********** */

  public approveRejectBlog() {
    this.spinner.show();
    this.blogService.approveRejectBlog(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success');
        this.route.navigateByUrl('/admin/blog/new')
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
      })
  }

}
