<div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
    <input type="text"   placeholder="Type to filter the wallet ID..."  class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)" />
    <!-- <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->
    <div>
        <button class="btn btn-success btn-sm mr-2" (click)="getCustomerCreditedWalletHistory()">Recharged Transactions</button>
        <button class="btn btn-primary btn-sm mr-2" (click)="getCustomerDebitedWalletHistory()">Deducted Transactions</button>
        <button class="btn btn-info btn-sm" (click)="getCustomerWalletTransactionsByAdmin()">Admin Transactions</button>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Wallet Id" prop="cwalletId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.cwalletId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.txn_type == 1" class="badge badge-success">Recharged</span>
            <span *ngIf="row.txn_type == 2" class="badge badge-primary">Deducted</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="Amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
           {{row.currencySymbol}}{{row.amount | number: '.2-2'}} ({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Balance" prop="Balance">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.currencySymbol}}{{row.balance | number: '.2-2'}} ({{row.currencyCode}})
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Wallet Remarks" prop="Wallet Comments">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.wallet_comments}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Txn Id" prop="txnId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.txnId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>