import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AstroServiceService } from 'src/app/admin/services/astroservice/astro-service.service';
import { IssueServiceService } from 'src/app/admin/services/issues/issue-service.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reply-customer-support',
  templateUrl: './reply-customer-support.component.html',
  styleUrls: ['./reply-customer-support.component.scss']
})
export class ReplyCustomerSupportComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private issueService: IssueServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private route: Router
  ) { }

  data:any = '';
  id: any = '';
  supportId: any = ''
  title: any = ''
  description: any = ''
  issueSolved:boolean = false

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ userId: this.userdata.getId() })
    this.formGroup.patchValue({ addedBy: this.userdata.getName() })
    this.formGroup.patchValue({ _id: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    userId: new FormControl('', Validators.required),
    _id: new FormControl('', Validators.required),
    reply_by_system: new FormControl('', Validators.required),
    addedBy: new FormControl('', Validators.required),
    is_issue_solved: new FormControl(false)
  });


  getDataById() {
    this.spinner.show()
    this.issueService.getSingleSupport({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        //console.log(res)
        this.data = res.data
        this.supportId = res.data.supportId
        this.title = res.data.support_title
        this.description = res.data.support_description
      },
      err => {
        this.route.navigateByUrl('/admin/support/customer')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Reply Issue
 *********** */

  public replyIssue() {
    this.spinner.show();
    this.issueService.replySupport(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        //console.log(res)
        this.toast.success('Issue Resolved!', 'Success');
        this.route.navigateByUrl('/admin/support/customer')
      },
      err => {
        this.spinner.hide()
        //console.log(err)
        this.toast.error('Something went wrong!', 'Error');
      })

  }

  isIssueSolved(event:any){
    if(event.target.checked){
      //console.log('checked')
      this.formGroup.patchValue({is_issue_solved:true})
    }else{
      //console.log('unchecked')
      this.formGroup.patchValue({is_issue_solved:false})
    }
  }

}
