import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryMallService } from 'src/app/admin/services/category-mall/category-mall.service';
import { UserdataService } from 'src/app/admin/services/shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-mall-category',
  templateUrl: './edit-mall-category.component.html',
  styleUrls: ['./edit-mall-category.component.scss']
})
export class EditMallCategoryComponent implements OnInit {

  dataList:any = {}
  id:any =''
  isUnique: boolean = true;
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private categoryMallService: CategoryMallService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) { }

  getSingleCategoryList() {
    this.spinner.show()
    this.categoryMallService.detailMallCategory({_id:this.id}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.formGroup.patchValue({_id:this.dataList._id})
        this.formGroup.patchValue({categoryname:this.dataList.categoryname})
        this.formGroup.patchValue({category_gst:this.dataList.category_gst})
        this.formGroup.patchValue({categorydescription:this.dataList.categorydescription})
        this.formGroup.patchValue({updatedBy:this.userdata.getName()})
        this.formGroup.patchValue({category_slug:this.dataList.category_slug})
        this.formGroup.patchValue({category_keyword:this.dataList.category_keyword})
        this.formGroup.patchValue({is_front_category:this.dataList.is_front_category})
        this.formGroup.patchValue({updatedId:this.userdata.getId()})
        this.isHighlight = this.dataList.is_front_category
        if(this.dataList.is_subcategory_associated){
          this.formGroup.patchValue({associateType:'subcat'})
        }
        if(this.dataList.is_product_associated ){
          this.formGroup.patchValue({associateType:'product'})
        }
      },
      (err) => {
        this.spinner.hide()
        this.route.navigateByUrl('/admin/mall-category/list')
      }
    );
  }
  
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    categoryname: new FormControl('', Validators.required),
    categorydescription: new FormControl('', Validators.required),
    updatedBy: new FormControl(''),
    category_slug: new FormControl(''),
    category_gst: new FormControl(''),
    category_keyword: new FormControl(''),
    category_image: new FormControl(''),
    is_front_category: new FormControl(false),
    updatedId: new FormControl('', Validators.required),
    associateType: new FormControl(''),
  });

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getSingleCategoryList()
  }

  get f() {
    return this.formGroup.controls
  }

  /**
   * Category Image
   */
   imageError: string = ''
   imageSrc = ''
   public onBannerUpload(event: any){
     this.imageError = ''
     const reader = new FileReader();
     if(event.target.files && event.target.files.length) {
       const allowed_types = ['image/png', 'image/jpeg'];
             const max_height = 540;
             const max_width = 960;
       if (!_.includes(allowed_types, event.target.files[0].type)) {
         this.imageError = 'Only Images are allowed ( JPG | PNG )';
         return;
       }
       const [file] = event.target.files;
 
       reader.readAsDataURL(file);    
 
       reader.onload = (e:any) => {
         this.imageSrc = reader.result as string;    
         const image = new Image();
         image.src = e.target.result;
         image.onload = (rs:any) => {
           const img_height = rs.currentTarget['height'];
           const img_width = rs.currentTarget['width'];
           //console.log(img_height, img_width);
           if (img_height != max_height || img_width != max_width) {
               this.imageError =
                   'Image dimentions allowed ' +
                   max_width +
                   ' X ' +
                   max_height +
                   'px';
               return;
           } else {
             this.formGroup.patchValue({
              category_image: event.target.files[0]
             })
           }
         }
       };
     }
   }

   isHighlight = false
   checkIfHighlight(event:any){
    this.isHighlight = !this.isHighlight
   }

   update(){
    this.formGroup.patchValue({"is_front_category":this.isHighlight})
    const data = new FormData()
    data.append("categoryname", this.formGroup.get("categoryname")?.value)
    data.append("category_gst", this.formGroup.get("category_gst")?.value)
    data.append("categorydescription", this.formGroup.get("categorydescription")?.value)
    data.append("updatedBy", this.formGroup.get("updatedBy")?.value)
    data.append("category_slug", this.formGroup.get("category_slug")?.value)
    data.append("category_keyword", this.formGroup.get("category_keyword")?.value)
    data.append("category_image", this.formGroup.get("category_image")?.value)
    data.append("_id", this.formGroup.get("_id")?.value)
    data.append("updatedId", this.formGroup.get("updatedId")?.value)
    data.append("is_front_category", this.formGroup.get("is_front_category")?.value)
    data.append("associateType", this.formGroup.get("associateType")?.value)
    this.categoryMallService.updateMallCategory(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/admin/mall-category/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  checkSlugUnique(event:any){
    this.categoryMallService.checkMallCategoryUnique({slug:event.target.value}).subscribe(
      (res:any)=>{
        if(res.success){
          this.isUnique = true
        }else{
          this.isUnique = false
        }
      }
    )
  }

  changeAssociationType(event:any){
    this.formGroup.patchValue({associateType:event.target.value})
  }

}
